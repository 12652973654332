import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

import {
  Box,
  // Button,
  Typography
} from '@material-ui/core';
import { requestTotiaoList } from 'src/api';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const columns = [
  {
    field: '1',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    width: 100
  },
  {
    field: '2',
    headerName: 'Institutions',
    headerClassName: 'super-app-theme--header',
    width: 900
  },
  {
    field: '3',
    headerName: 'Topic',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    minWidth: 150,
  },
  {
    field: '4',
    headerName: 'Date',
    headerClassName: 'super-app-theme--header',
    width: 200
  },
];
// const mockData = [
//   {
//     id: 1,
//     Title: 'New Data Raise Questions About the Role of PD-L1 Inhibition and Chemotherapy in Advanced Stage Ovarian Cancer',
//     Institutions: 'Mass General',
//     Date: 'Aug 24, 2021'
//   },
//   {
//     id: 2,
//     Title: 'ROBUST Study Finds No PFS Benefit With Lenalidomide Plus R-CHOP in ABC-Type DLBCL',
//     Institutions: 'Johns Hopkins',
//     Date: 'Aug 10, 2021'
//   },
//   {
//     id: 3,
//     Title: 'Diffuse large B-cell lymphoma (DLBCL) have long-term control of their disease with the anti-CD20 antibody rituximab',
//     Institutions: 'MD Anderson',
//     Date: 'July 12, 2021'
//   },
//   {
//     id: 4,
//     Title: 'Addition of a PD-L1 inhibitor to combination bevacizumab and chemotherapy for women with newly diagnosed advanced stage ovarian cancer does not improve progression-free survival (PFS) ',
//     Institutions: 'ASCO',
//     Date: 'July 10, 2021'
//   },
//   {
//     id: 5,
//     Title: 'More than half of patients with diffuse large B-cell lymphoma DLBCL) have long-term control of their disease',
//     Institutions: 'MD Anderson',
//     Date: 'July 28, 2021'
//   }
// ];

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgba(30, 176, 188)',
      color: 'white',
      flexWrap: 'wrap',
      overView: 'scroll'
    },
  },
});

const Breakthroughs = () => {
  const dkw = useSelector((state) => state.dkw);
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const loadTotiaoList = async (kword) => {
    const result = await requestTotiaoList(kword);
    console.log(result.TotiaoList);
    const TotiaoListDisplay = [];
    result.trials.forEach((theTotiaoList, i) => {
      const rowIndex = i + 1;
      rows.push(
        {
          id: theTotiaoList.id,
          1: rowIndex,
          2: theTotiaoList.id,
          3: theTotiaoList.Title,
          4: theTotiaoList.Institutions,
          5: theTotiaoList.Date
        }
      );
    });
    setRows([...TotiaoListDisplay]);
  };
  useEffect(() => {
    loadTotiaoList(dkw);
  }, []);
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h3"
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            marginBottom: '-5px'
          }}
        >
          {rows.length}
          &nbsp;
          medical breakthroughs announced by major hospitals for Hippel-Lindau disease
        </Typography>
      </Box>
      <div style={{ height: 600, width: '100%' }} className={classes.root}>
        <DataGrid rows={rows} columns={columns} pageSize={10} />
      </div>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{
            marginLeft: '20px',
            marginTop: '30px',
            marginBottom: '5px'
          }}
        >
          The frontier of medicine progress is tracked by monitoring updates from major institution in the United States, The data are carefully mapped to specific disease and/or disease areas. Visit America&apos;s Newsroom for more updates.
        </Typography>
      </Box>
    </>
  );
};
export default Breakthroughs;
