import { useSelector } from 'react-redux';
import ReactECharts from 'echarts-for-react';

const TopHospitalsBubbleChart = () => {
  const topHospList = useSelector((state) => state.topHospitals);
  const topHospitalsData = [];
  topHospList.forEach((hosp) => {
    topHospitalsData.push([hosp.projectCount, hosp.nctCount, hosp.funding, hosp.name, 'Top Hospitals']);
  });
  const option = {
    legend: {
      right: '10%',
      top: '3%',
      data: ['Top Hospitals']
    },
    grid: {
      left: '8%',
      top: '10%'
    },
    xAxis: {
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      }
    },
    yAxis: {
      splitLine: {
        lineStyle: {
          type: 'dashed'
        }
      },
      scale: true
    },
    series: [{
      name: 'Top Hospitals',
      data: topHospitalsData,
      type: 'scatter',
      symbolSize: (d) => Math.sqrt(d[2]) / 2e2,
      emphasis: {
        focus: 'series',
        label: {
          show: true,
          formatter: (param) => param.data[3],
          position: 'top'
        }
      },
      itemStyle: {
        shadowBlur: 10,
        shadowColor: 'rgba(120, 36, 50, 0.5)',
        shadowOffsetY: 5,
        color: 'rgb(64, 214, 227, 0.8)'
      }
    }]
  };

  return (
    <ReactECharts
      option={option}
      style={{ height: 400 }}
      opts={{ renderer: 'svg' }}
    />
  );
};

export default TopHospitalsBubbleChart;
