import { useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';

const TopHospitalsHistogram = () => {
  console.log('TopHospitalsHistogram');
  const topHospList = useSelector((state) => state.topHospitals);
  const topHospitalsData = [['Hosipital', 'Score']];
  topHospList.forEach((hospital) => {
    topHospitalsData.push([hospital.name, ((hospital.projectCount / hospital.maxProjectCount) * 100).toFixed(2)]);
  });
  return (
    <Chart
      height="300px"
      chartType="Histogram"
      loader={<div>Loading Chart</div>}
      data={topHospitalsData}
      options={{
        title: 'Top Hospitals Frontier Research Score',
        legend: { position: 'none' },
        colors: ['#40d6e3'],
        dataOpacity: 0.8,
        backgroundColor: '#f4f6f8',
        vAxis: { scaleType: 'mirrorLog' },
      }}
      rootProps={{ 'data-testid': '3' }}
    />
  );
};

export default TopHospitalsHistogram;
