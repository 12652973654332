import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

const TopHospitalContent = (props) => {
  const {
    hospitalname, hospitalocation
  } = props;
  return (
    <Card
      style={{
        boxShadow: 'none'
      }}
      {...props}
    >
      <CardContent>
        <Grid item style={{ width: '100%', height: '100%' }}>
          <Grid
            item
            style={{
              position: 'relative'
            }}
          >
            <Typography
              color="textPrimary"
              variant="h4"
              textAlign="left"
              // height="60px"
            >
              {hospitalname}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              textAlign="left"
              // height="40px"
              marginTop="30px"
            >
              {hospitalocation}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'bottom',
                pt: 1,
                marginTop: '30px'
              }}
            >
              <Button
                color="primary"
                component="a"
                href="https://react-material-kit.devias.io"
                variant="contained"
              >
                Contact
              </Button>
              &nbsp;
              <Button
                color="primary"
                component="a"
                href="https://react-material-kit.devias.io"
                variant="outlined"
                sx={{ marginLeft: '20px' }}
              >
                Learn More
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default TopHospitalContent;
