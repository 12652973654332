import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import AboutTheDisease from './pages/AboutTheDisease';
import TopHospitals from './pages/TopHospitals';
import Overall from './pages/Overall';
import Advanced from './pages/Advanced';
import Venn from './pages/Venn';
import Bubble from './pages/Bubble';
import Histogram from './pages/Histogram';
import Line from './pages/Line';
import ViewMap from './pages/ViewMap';
import MostRecentUpdates from './pages/MostRecentUpdates';
import FindHospitalNearYou from './pages/FindHospitalNearYou';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'topHospitals', element: <TopHospitals /> },
      { path: 'aboutTheDisease', element: <AboutTheDisease /> },
      { path: 'overall', element: <Overall /> },
      { path: 'advanced', element: <Advanced /> },
      { path: 'advanced/Venn', element: <Venn /> },
      { path: 'advanced/Bubble', element: <Bubble /> },
      { path: 'advanced/Histogram', element: <Histogram /> },
      { path: 'advanced/Line', element: <Line /> },
      { path: 'viewMap', element: <ViewMap /> },
      { path: 'aboutTheDisease/mostRecentUpdates', element: <MostRecentUpdates /> },
      { path: 'findHospitalNearYou', element: <FindHospitalNearYou /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/app/topHospitals" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
