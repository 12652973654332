export default {
  categories: [
    { name: 'Bacterial Infections and Mycoses' },
    { name: 'Virus Diseases' },
    { name: 'Parasitic Diseases' },
    { name: 'Neoplasms' },
    { name: 'Musculoskeletal Diseases' },
    { name: 'Digestive System Diseases' },
    { name: 'Stomatognathic Diseases' },
    { name: 'Respiratory Tract Diseases' },
    { name: 'Otorhinolaryngologic Diseases' },
    { name: 'Nervous System Diseases' },
    { name: 'Eye Diseases' },
    { name: 'Male Urogenital Diseases' },
    { name: 'Female Urogenital Diseases and Pregnancy Complications' },
    { name: 'Cardiovascular Diseases' },
    { name: 'Hemic and Lymphatic Diseases' },
    { name: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities' },
    { name: 'Skin and Connective Tissue Diseases' },
    { name: 'Nutritional and Metabolic Diseases' },
    { name: 'Endocrine System Diseases' },
    { name: 'Immune System Diseases' },
    { name: 'Disorders of Environmental Origin' },
    { name: 'Animal Diseases' },
    { name: 'Pathological Conditions, Signs and Symptoms' },
    { name: 'Occupational Diseases' },
    { name: 'Chemically-Induced Disorders' },
    { name: 'Wounds and Injuries' },
    { name: 'Mental Disorders' }],
  nodes: [
    { name: 'Abortion, Septic', group: '1' },
    { name: 'Abscess', group: '1' },
    { name: 'Peritonsillar Abscess', group: '1' },
    { name: 'Waterborne Diseases', group: '1' },
    { name: 'Neonatal Sepsis', group: '1' },
    { name: 'Invasive Fungal Infections', group: '1' },
    { name: 'Acinetobacter Infections', group: '1' },
    { name: 'Actinobacillosis', group: '1' },
    { name: 'Actinobacillus Infections', group: '1' },
    { name: 'Actinomycetales Infections', group: '1' },
    { name: 'Actinomycosis', group: '1' },
    { name: 'Actinomycosis, Cervicofacial', group: '1' },
    { name: 'Pelvic Inflammatory Disease', group: '1' },
    { name: 'Anaplasmataceae Infections', group: '1' },
    { name: 'Anaplasmosis', group: '1' },
    { name: 'Aneurysm, Infected', group: '1' },
    { name: 'Anthrax', group: '1' },
    { name: 'Appendicitis', group: '1' },
    { name: 'Arthritis, Infectious', group: '1' },
    { name: 'Aspergillosis', group: '1' },
    { name: 'Aspergillosis, Allergic Bronchopulmonary', group: '1' },
    { name: 'Bacterial Infections and Mycoses', group: '1' },
    { name: 'Bacterial Infections', group: '1' },
    { name: 'Bacteriuria', group: '1' },
    { name: 'Bacteroides Infections', group: '1' },
    { name: 'Bartonella Infections', group: '1' },
    { name: 'Bartonellaceae Infections', group: '1' },
    { name: 'Blastomycosis', group: '1' },
    { name: 'Bone Diseases, Infectious', group: '1' },
    { name: 'Bordetella Infections', group: '1' },
    { name: 'Borrelia Infections', group: '1' },
    { name: 'Botulism', group: '1' },
    { name: 'Boutonneuse Fever', group: '1' },
    { name: 'Brain Abscess', group: '1' },
    { name: 'Brucellosis', group: '1' },
    { name: 'Brucellosis, Bovine', group: '1' },
    { name: 'Campylobacter Infections', group: '1' },
    { name: 'Candidiasis', group: '1' },
    { name: 'Candidiasis, Chronic Mucocutaneous', group: '1' },
    { name: 'Candidiasis, Cutaneous', group: '1' },
    { name: 'Candidiasis, Oral', group: '1' },
    { name: 'Candidiasis, Vulvovaginal', group: '1' },
    { name: 'Carbuncle', group: '1' },
    { name: 'Cat-Scratch Disease', group: '1' },
    { name: 'Cellulitis', group: '1' },
    { name: 'Central Nervous System Infections', group: '1' },
    { name: 'Chancre', group: '1' },
    { name: 'Chancroid', group: '1' },
    { name: 'Chlamydia Infections', group: '1' },
    { name: 'Chlamydiaceae Infections', group: '1' },
    { name: 'Cholera', group: '1' },
    { name: 'Chromoblastomycosis', group: '1' },
    { name: 'Clostridium Infections', group: '1' },
    { name: 'Coccidioidomycosis', group: '1' },
    { name: 'Communicable Diseases', group: '1' },
    { name: 'Conjunctivitis, Bacterial', group: '1' },
    { name: 'Conjunctivitis, Inclusion', group: '1' },
    { name: 'Corneal Ulcer', group: '1' },
    { name: 'Corynebacterium Infections', group: '1' },
    { name: 'Cross Infection', group: '1' },
    { name: 'Cryptococcosis', group: '1' },
    { name: 'Dermatomycoses', group: '1' },
    { name: 'Diphtheria', group: '1' },
    { name: 'Diverticulitis', group: '1' },
    { name: 'Dysentery, Bacillary', group: '1' },
    { name: 'Ecthyma', group: '1' },
    { name: 'Empyema', group: '1' },
    { name: 'Empyema, Tuberculous', group: '1' },
    { name: 'Endocarditis, Bacterial', group: '1' },
    { name: 'Endocarditis, Subacute Bacterial', group: '1' },
    { name: 'Enterobacteriaceae Infections', group: '1' },
    { name: 'Enterocolitis, Pseudomembranous', group: '1' },
    { name: 'Enterotoxemia', group: '1' },
    { name: 'Erysipelas', group: '1' },
    { name: 'Erysipeloid', group: '1' },
    { name: 'Erysipelothrix Infections', group: '1' },
    { name: 'Erythema Induratum', group: '1' },
    { name: 'Erythrasma', group: '1' },
    { name: 'Escherichia coli Infections', group: '1' },
    { name: 'Focal Infection', group: '1' },
    { name: 'Focal Infection, Dental', group: '1' },
    { name: 'Furunculosis', group: '1' },
    { name: 'Fusobacterium Infections', group: '1' },
    { name: 'Gas Gangrene', group: '1' },
    { name: 'Geotrichosis', group: '1' },
    { name: 'Gingivitis, Necrotizing Ulcerative', group: '1' },
    { name: 'Glanders', group: '1' },
    { name: 'Gonorrhea', group: '1' },
    { name: 'Granuloma Inguinale', group: '1' },
    { name: 'Haemophilus Infections', group: '1' },
    { name: 'Heartwater Disease', group: '1' },
    { name: 'Hemorrhagic Septicemia', group: '1' },
    { name: 'Histoplasmosis', group: '1' },
    { name: 'Hordeolum', group: '1' },
    { name: 'Impetigo', group: '1' },
    { name: 'Infection', group: '1' },
    { name: 'Keratoconjunctivitis, Infectious', group: '1' },
    { name: 'Klebsiella Infections', group: '1' },
    { name: 'Laboratory Infection', group: '1' },
    { name: 'Legionellosis', group: '1' },
    { name: 'Legionnaires\' Disease', group: '1' },
    { name: 'Leprosy', group: '1' },
    { name: 'Leptospirosis', group: '1' },
    { name: 'Whipple Disease', group: '1' },
    { name: 'Listeriosis', group: '1' },
    { name: 'Liver Abscess', group: '1' },
    { name: 'Liver Abscess, Amebic', group: '1' },
    { name: 'Ludwig\'s Angina', group: '1' },
    { name: 'Lung Abscess', group: '1' },
    { name: 'Lung Diseases, Fungal', group: '1' },
    { name: 'Lupus Vulgaris', group: '1' },
    { name: 'Lyme Disease', group: '1' },
    { name: 'Lymphogranuloma Venereum', group: '1' },
    { name: 'Mycetoma', group: '1' },
    { name: 'Mastoiditis', group: '1' },
    { name: 'Melioidosis', group: '1' },
    { name: 'Meningitis, Haemophilus', group: '1' },
    { name: 'Meningitis, Listeria', group: '1' },
    { name: 'Meningitis, Meningococcal', group: '1' },
    { name: 'Meningitis, Pneumococcal', group: '1' },
    { name: 'Meningococcal Infections', group: '1' },
    { name: 'Mucormycosis', group: '1' },
    { name: 'Mycobacterium Infections', group: '1' },
    { name: 'Mycobacterium Infections, Nontuberculous', group: '1' },
    { name: 'Mycoplasma Infections', group: '1' },
    { name: 'Mycoplasmatales Infections', group: '1' },
    { name: 'Mycoses', group: '1' },
    { name: 'Neurosyphilis', group: '1' },
    { name: 'Nocardia Infections', group: '1' },
    { name: 'Endophthalmitis', group: '1' },
    { name: 'Ophthalmia Neonatorum', group: '1' },
    { name: 'Opportunistic Infections', group: '1' },
    { name: 'Psittacosis', group: '1' },
    { name: 'Osteomyelitis', group: '1' },
    { name: 'Otitis Media, Suppurative', group: '1' },
    { name: 'Panophthalmitis', group: '1' },
    { name: 'Paracoccidioidomycosis', group: '1' },
    { name: 'Paratuberculosis', group: '1' },
    { name: 'Paratyphoid Fever', group: '1' },
    { name: 'Paronychia', group: '1' },
    { name: 'Pasteurella Infections', group: '1' },
    { name: 'Periapical Abscess', group: '1' },
    { name: 'Pericarditis, Tuberculous', group: '1' },
    { name: 'Periodontal Abscess', group: '1' },
    { name: 'Periostitis', group: '1' },
    { name: 'Peritonitis', group: '1' },
    { name: 'Piedra', group: '1' },
    { name: 'Pinta', group: '1' },
    { name: 'Plague', group: '1' },
    { name: 'Pleuropneumonia, Contagious', group: '1' },
    { name: 'Pneumococcal Infections', group: '1' },
    { name: 'Pneumonia, Pneumococcal', group: '1' },
    { name: 'Pneumonia, Mycoplasma', group: '1' },
    { name: 'Pneumonia, Pneumocystis', group: '1' },
    { name: 'Pneumonia, Rickettsial', group: '1' },
    { name: 'Pneumonia, Staphylococcal', group: '1' },
    { name: 'Pregnancy Complications, Infectious', group: '1' },
    { name: 'Proteus Infections', group: '1' },
    { name: 'Pseudomonas Infections', group: '1' },
    { name: 'Puerperal Infection', group: '1' },
    { name: 'Pyuria', group: '1' },
    { name: 'Q Fever', group: '1' },
    { name: 'Rat-Bite Fever', group: '1' },
    { name: 'Relapsing Fever', group: '1' },
    { name: 'Respiratory Tract Infections', group: '1' },
    { name: 'Rheumatic Fever', group: '1' },
    { name: 'Rheumatic Heart Disease', group: '1' },
    { name: 'Rhinoscleroma', group: '1' },
    { name: 'Rickettsia Infections', group: '1' },
    { name: 'Rickettsiaceae Infections', group: '1' },
    { name: 'Rocky Mountain Spotted Fever', group: '1' },
    { name: 'Salmonella Food Poisoning', group: '1' },
    { name: 'Salmonella Infections', group: '1' },
    { name: 'Salmonella Infections, Animal', group: '1' },
    { name: 'Scarlet Fever', group: '1' },
    { name: 'Schistosomiasis haematobia', group: '1' },
    { name: 'Scrub Typhus', group: '1' },
    { name: 'Sexually Transmitted Diseases', group: '1' },
    { name: 'Pasteurellosis, Pneumonic', group: '1' },
    { name: 'Shock, Septic', group: '1' },
    { name: 'Silicotuberculosis', group: '1' },
    { name: 'Skin Diseases, Infectious', group: '1' },
    { name: 'Spirochaetales Infections', group: '1' },
    { name: 'Spondylitis', group: '1' },
    { name: 'Sporotrichosis', group: '1' },
    { name: 'Staphylococcal Food Poisoning', group: '1' },
    { name: 'Staphylococcal Infections', group: '1' },
    { name: 'Staphylococcal Scalded Skin Syndrome', group: '1' },
    { name: 'Staphylococcal Skin Infections', group: '1' },
    { name: 'Streptococcal Infections', group: '1' },
    { name: 'Empyema, Subdural', group: '1' },
    { name: 'Subphrenic Abscess', group: '1' },
    { name: 'Suppuration', group: '1' },
    { name: 'Surgical Wound Infection', group: '1' },
    { name: 'Swine Erysipelas', group: '1' },
    { name: 'Syphilis', group: '1' },
    { name: 'Syphilis, Cardiovascular', group: '1' },
    { name: 'Syphilis, Congenital', group: '1' },
    { name: 'Syphilis, Cutaneous', group: '1' },
    { name: 'Syphilis, Latent', group: '1' },
    { name: 'Tabes Dorsalis', group: '1' },
    { name: 'Tetanus', group: '1' },
    { name: 'Thyroiditis, Suppurative', group: '1' },
    { name: 'Tinea', group: '1' },
    { name: 'Tinea Capitis', group: '1' },
    { name: 'Tinea Favosa', group: '1' },
    { name: 'Tinea Pedis', group: '1' },
    { name: 'Onychomycosis', group: '1' },
    { name: 'Tinea Versicolor', group: '1' },
    { name: 'Toxemia', group: '1' },
    { name: 'Trachoma', group: '1' },
    { name: 'Trench Fever', group: '1' },
    { name: 'Treponemal Infections', group: '1' },
    { name: 'Tuberculoma', group: '1' },
    { name: 'Tuberculosis', group: '1' },
    { name: 'Tuberculosis, Avian', group: '1' },
    { name: 'Tuberculosis, Bovine', group: '1' },
    { name: 'Tuberculosis, Cardiovascular', group: '1' },
    { name: 'Tuberculosis, Cutaneous', group: '1' },
    { name: 'Tuberculosis, Endocrine', group: '1' },
    { name: 'Tuberculosis, Female Genital', group: '1' },
    { name: 'Tuberculosis, Gastrointestinal', group: '1' },
    { name: 'Tuberculosis, Hepatic', group: '1' },
    { name: 'Tuberculosis, Laryngeal', group: '1' },
    { name: 'Tuberculosis, Lymph Node', group: '1' },
    { name: 'Tuberculosis, Male Genital', group: '1' },
    { name: 'Tuberculosis, Meningeal', group: '1' },
    { name: 'Tuberculosis, Miliary', group: '1' },
    { name: 'Tuberculosis, Ocular', group: '1' },
    { name: 'Tuberculosis, Oral', group: '1' },
    { name: 'Tuberculosis, Osteoarticular', group: '1' },
    { name: 'Peritonitis, Tuberculous', group: '1' },
    { name: 'Tuberculosis, Pleural', group: '1' },
    { name: 'Tuberculosis, Pulmonary', group: '1' },
    { name: 'Tuberculosis, Renal', group: '1' },
    { name: 'Tuberculosis, Spinal', group: '1' },
    { name: 'Tuberculosis, Splenic', group: '1' },
    { name: 'Tuberculosis, Urogenital', group: '1' },
    { name: 'Tularemia', group: '1' },
    { name: 'Typhoid Fever', group: '1' },
    { name: 'Typhus, Endemic Flea-Borne', group: '1' },
    { name: 'Typhus, Epidemic Louse-Borne', group: '1' },
    { name: 'Urinary Tract Infections', group: '1' },
    { name: 'Vibrio Infections', group: '1' },
    { name: 'Waterhouse-Friderichsen Syndrome', group: '1' },
    { name: 'Weil Disease', group: '1' },
    { name: 'Whooping Cough', group: '1' },
    { name: 'Wound Infection', group: '1' },
    { name: 'Yaws', group: '1' },
    { name: 'Yersinia Infections', group: '1' },
    { name: 'Yersinia pseudotuberculosis Infections', group: '1' },
    { name: 'Zoonoses', group: '1' },
    { name: 'Superinfection', group: '1' },
    { name: 'Sexually Transmitted Diseases, Bacterial', group: '1' },
    { name: 'Mycobacterium avium-intracellulare Infection', group: '1' },
    { name: 'Discitis', group: '1' },
    { name: 'Leprosy, Borderline', group: '1' },
    { name: 'Leprosy, Lepromatous', group: '1' },
    { name: 'Leprosy, Tuberculoid', group: '1' },
    { name: 'Erythema Chronicum Migrans', group: '1' },
    { name: 'Eye Infections', group: '1' },
    { name: 'Eye Infections, Bacterial', group: '1' },
    { name: 'Eye Infections, Fungal', group: '1' },
    { name: 'Uveitis, Suppurative', group: '1' },
    { name: 'Prosthesis-Related Infections', group: '1' },
    { name: 'Fungemia', group: '1' },
    { name: 'Bacteremia', group: '1' },
    { name: 'Helicobacter Infections', group: '1' },
    { name: 'Vaginosis, Bacterial', group: '1' },
    { name: 'Psoas Abscess', group: '1' },
    { name: 'Pneumocystis Infections', group: '1' },
    { name: 'Empyema, Pleural', group: '1' },
    { name: 'Toxoplasmosis, Cerebral', group: '1' },
    { name: 'Tuberculoma, Intracranial', group: '1' },
    { name: 'Bacillaceae Infections', group: '1' },
    { name: 'Bacteroidaceae Infections', group: '1' },
    { name: 'Serratia Infections', group: '1' },
    { name: 'Ureaplasma Infections', group: '1' },
    { name: 'Neisseriaceae Infections', group: '1' },
    { name: 'Pasteurellaceae Infections', group: '1' },
    { name: 'Ehrlichiosis', group: '1' },
    { name: 'Microsporidiosis', group: '1' },
    { name: 'Encephalitozoonosis', group: '1' },
    { name: 'Gram-Negative Bacterial Infections', group: '1' },
    { name: 'Gram-Positive Bacterial Infections', group: '1' },
    { name: 'Angiomatosis, Bacillary', group: '1' },
    { name: 'Arthritis, Reactive', group: '1' },
    { name: 'Meningitis, Cryptococcal', group: '1' },
    { name: 'Meningitis, Bacterial', group: '1' },
    { name: 'Meningitis, Fungal', group: '1' },
    { name: 'AIDS-Related Opportunistic Infections', group: '1' },
    { name: 'Skin Diseases, Bacterial', group: '1' },
    { name: 'Tick-Borne Diseases', group: '1' },
    { name: 'Hidradenitis Suppurativa', group: '1' },
    { name: 'Retropharyngeal Abscess', group: '1' },
    { name: 'Community-Acquired Infections', group: '1' },
    { name: 'Tuberculosis, Multidrug-Resistant', group: '1' },
    { name: 'Pneumonia, Bacterial', group: '1' },
    { name: 'Soft Tissue Infections', group: '1' },
    { name: 'King\'s Evil', group: '1' },
    { name: 'Abdominal Abscess', group: '1' },
    { name: 'Sepsis', group: '1' },
    { name: 'Fournier Gangrene', group: '1' },
    { name: 'Fasciitis, Necrotizing', group: '1' },
    { name: 'Burkholderia Infections', group: '1' },
    { name: 'Endotoxemia', group: '1' },
    { name: 'Zygomycosis', group: '1' },
    { name: 'Tuberculosis, Central Nervous System', group: '1' },
    { name: 'Central Nervous System Fungal Infections', group: '1' },
    { name: 'Epidural Abscess', group: '1' },
    { name: 'Central Nervous System Bacterial Infections', group: '1' },
    { name: 'Meningitis, Escherichia coli', group: '1' },
    { name: 'Lyme Neuroborreliosis', group: '1' },
    { name: 'Neuroaspergillosis', group: '1' },
    { name: 'Communicable Diseases, Emerging', group: '1' },
    { name: 'Chlamydophila Infections', group: '1' },
    { name: 'Pelvic Infection', group: '1' },
    { name: 'Bifidobacteriales Infections', group: '1' },
    { name: 'Piscirickettsiaceae Infections', group: '1' },
    { name: 'Pneumonia of Swine, Mycoplasmal', group: '1' },
    { name: 'Desulfovibrionaceae Infections', group: '1' },
    { name: 'Fusobacteriaceae Infections', group: '1' },
    { name: 'Flavobacteriaceae Infections', group: '1' },
    { name: 'Cytophagaceae Infections', group: '1' },
    { name: 'Moraxellaceae Infections', group: '1' },
    { name: 'Liver Abscess, Pyogenic', group: '1' },
    { name: 'Pneumonia of Calves, Enzootic', group: '1' },
    { name: 'Pyomyositis', group: '1' },
    { name: 'Typhlitis', group: '1' },
    { name: 'Pneumonia, Ventilator-Associated', group: '1' },
    { name: 'Cardiovascular Infections', group: '1' },
    { name: 'Buruli Ulcer', group: '1' },
    { name: 'Extensively Drug-Resistant Tuberculosis', group: '1' },
    { name: 'Catheter-Related Infections', group: '1' },
    { name: 'Pulmonary Aspergillosis', group: '1' },
    { name: 'Invasive Pulmonary Aspergillosis', group: '1' },
    { name: 'Latent Tuberculosis', group: '1' },
    { name: 'Leprosy, Paucibacillary', group: '1' },
    { name: 'Leprosy, Multibacillary', group: '1' },
    { name: 'Lemierre Syndrome', group: '1' },
    { name: 'Digital Dermatitis', group: '1' },
    { name: 'Candidiasis, Invasive', group: '1' },
    { name: 'Candidemia', group: '1' },
    { name: 'Otomycosis', group: '1' },
    { name: 'Petrositis', group: '1' },
    { name: 'Pott Puffy Tumor', group: '1' },
    { name: 'Intraabdominal Infections', group: '1' },
    { name: 'Aliivibrio Infections', group: '1' },
    { name: 'Coinfection', group: '1' },
    { name: 'Lobomycosis', group: '1' },
    { name: 'Cerebral Phaeohyphomycosis', group: '1' },
    { name: 'Phaeohyphomycosis', group: '1' },
    { name: 'Alternariosis', group: '1' },
    { name: 'Fusariosis', group: '1' },
    { name: 'Trichosporonosis', group: '1' },
    { name: 'Hyalohyphomycosis', group: '1' },
    { name: 'Reproductive Tract Infections', group: '1' },
    { name: 'Chlamydial Pneumonia', group: '1' },
    { name: 'Zika Virus Infection', group: '2' },
    { name: 'Acute Retroviral Syndrome', group: '2' },
    { name: 'Acquired Immunodeficiency Syndrome', group: '2' },
    { name: 'Adenoviridae Infections', group: '2' },
    { name: 'Adenovirus Infections, Human', group: '2' },
    { name: 'African Horse Sickness', group: '2' },
    { name: 'African Swine Fever', group: '2' },
    { name: 'AIDS-Related Complex', group: '2' },
    { name: 'Aleutian Mink Disease', group: '2' },
    { name: 'Arbovirus Infections', group: '2' },
    { name: 'Arenaviridae Infections', group: '2' },
    { name: 'Avian Leukosis', group: '2' },
    { name: 'Sarcoma, Avian', group: '2' },
    { name: 'Bluetongue', group: '2' },
    { name: 'Border Disease', group: '2' },
    { name: 'Borna Disease', group: '2' },
    { name: 'Bovine Virus Diarrhea-Mucosal Disease', group: '2' },
    { name: 'Bronchiolitis, Viral', group: '2' },
    { name: 'Bunyaviridae Infections', group: '2' },
    { name: 'Burkitt Lymphoma', group: '2' },
    { name: 'Chickenpox', group: '2' },
    { name: 'Colorado Tick Fever', group: '2' },
    { name: 'Common Cold', group: '2' },
    { name: 'Condylomata Acuminata', group: '2' },
    { name: 'Conjunctivitis, Acute Hemorrhagic', group: '2' },
    { name: 'Conjunctivitis, Viral', group: '2' },
    { name: 'Coronaviridae Infections', group: '2' },
    { name: 'Coxsackievirus Infections', group: '2' },
    { name: 'Cytomegalovirus Infections', group: '2' },
    { name: 'Hepatitis D', group: '2' },
    { name: 'Dengue', group: '2' },
    { name: 'Distemper', group: '2' },
    { name: 'DNA Virus Infections', group: '2' },
    { name: 'Echovirus Infections', group: '2' },
    { name: 'Ecthyma, Contagious', group: '2' },
    { name: 'Ectromelia, Infectious', group: '2' },
    { name: 'Encephalitis, California', group: '2' },
    { name: 'Encephalitis, Arbovirus', group: '2' },
    { name: 'Encephalitis, Japanese', group: '2' },
    { name: 'Encephalitis, St. Louis', group: '2' },
    { name: 'Encephalitis, Tick-Borne', group: '2' },
    { name: 'Encephalomyelitis, Enzootic Porcine', group: '2' },
    { name: 'Encephalomyelitis, Equine', group: '2' },
    { name: 'Encephalomyelitis, Venezuelan Equine', group: '2' },
    { name: 'Enteritis, Transmissible, of Turkeys', group: '2' },
    { name: 'Enterovirus Infections', group: '2' },
    { name: 'Ephemeral Fever', group: '2' },
    { name: 'Epidermodysplasia Verruciformis', group: '2' },
    { name: 'Equine Infectious Anemia', group: '2' },
    { name: 'Exanthema Subitum', group: '2' },
    { name: 'Feline Panleukopenia', group: '2' },
    { name: 'Foot-and-Mouth Disease', group: '2' },
    { name: 'Influenza in Birds', group: '2' },
    { name: 'Fowlpox', group: '2' },
    { name: 'Gastroenteritis, Transmissible, of Swine', group: '2' },
    { name: 'Hand, Foot and Mouth Disease', group: '2' },
    { name: 'Hemorrhagic Fever, American', group: '2' },
    { name: 'Hemorrhagic Fever, Crimean', group: '2' },
    { name: 'Hemorrhagic Fever with Renal Syndrome', group: '2' },
    { name: 'Hemorrhagic Fever, Omsk', group: '2' },
    { name: 'Hemorrhagic Fevers, Viral', group: '2' },
    { name: 'Hepatitis A', group: '2' },
    { name: 'Hepatitis B', group: '2' },
    { name: 'Hepatitis, Infectious Canine', group: '2' },
    { name: 'Hepatitis, Viral, Animal', group: '2' },
    { name: 'Hepatitis, Viral, Human', group: '2' },
    { name: 'Hepatitis C', group: '2' },
    { name: 'Herpangina', group: '2' },
    { name: 'Herpes Genitalis', group: '2' },
    { name: 'Herpes Labialis', group: '2' },
    { name: 'Herpes Simplex', group: '2' },
    { name: 'Herpes Zoster', group: '2' },
    { name: 'Herpes Zoster Ophthalmicus', group: '2' },
    { name: 'Herpesviridae Infections', group: '2' },
    { name: 'HIV Seropositivity', group: '2' },
    { name: 'Classical Swine Fever', group: '2' },
    { name: 'Deltaretrovirus Infections', group: '2' },
    { name: 'Infectious Bovine Rhinotracheitis', group: '2' },
    { name: 'Infectious Mononucleosis', group: '2' },
    { name: 'Influenza, Human', group: '2' },
    { name: 'Kaposi Varicelliform Eruption', group: '2' },
    { name: 'Keratitis, Dendritic', group: '2' },
    { name: 'Kyasanur Forest Disease', group: '2' },
    { name: 'Lassa Fever', group: '2' },
    { name: 'Leukoencephalopathy, Progressive Multifocal', group: '2' },
    { name: 'Louping Ill', group: '2' },
    { name: 'Lumpy Skin Disease', group: '2' },
    { name: 'Lymphocytic Choriomeningitis', group: '2' },
    { name: 'Malignant Catarrh', group: '2' },
    { name: 'Marburg Virus Disease', group: '2' },
    { name: 'Marek Disease', group: '2' },
    { name: 'Measles', group: '2' },
    { name: 'Meningitis, Viral', group: '2' },
    { name: 'Molluscum Contagiosum', group: '2' },
    { name: 'Mumps', group: '2' },
    { name: 'Myxomatosis, Infectious', group: '2' },
    { name: 'Nairobi Sheep Disease', group: '2' },
    { name: 'Newcastle Disease', group: '2' },
    { name: 'Orthomyxoviridae Infections', group: '2' },
    { name: 'Phlebotomus Fever', group: '2' },
    { name: 'Respirovirus Infections', group: '2' },
    { name: 'Parvoviridae Infections', group: '2' },
    { name: 'Picornaviridae Infections', group: '2' },
    { name: 'Pleurodynia, Epidemic', group: '2' },
    { name: 'Pneumonia, Progressive Interstitial, of Sheep', group: '2' },
    { name: 'Pneumonia, Viral', group: '2' },
    { name: 'Poliomyelitis', group: '2' },
    { name: 'Poliomyelitis, Bulbar', group: '2' },
    { name: 'Poxviridae Infections', group: '2' },
    { name: 'Pseudorabies', group: '2' },
    { name: 'Pulmonary Adenomatosis, Ovine', group: '2' },
    { name: 'Rabies', group: '2' },
    { name: 'Reoviridae Infections', group: '2' },
    { name: 'Retroviridae Infections', group: '2' },
    { name: 'Rift Valley Fever', group: '2' },
    { name: 'Rinderpest', group: '2' },
    { name: 'RNA Virus Infections', group: '2' },
    { name: 'Rotavirus Infections', group: '2' },
    { name: 'Rubella', group: '2' },
    { name: 'Rubella Syndrome, Congenital', group: '2' },
    { name: 'Sarcoma, Kaposi', group: '2' },
    { name: 'Slow Virus Diseases', group: '2' },
    { name: 'Smallpox', group: '2' },
    { name: 'Stomatitis, Herpetic', group: '2' },
    { name: 'Subacute Sclerosing Panencephalitis', group: '2' },
    { name: 'Swine Vesicular Disease', group: '2' },
    { name: 'Togaviridae Infections', group: '2' },
    { name: 'Tumor Virus Infections', group: '2' },
    { name: 'Vaccinia', group: '2' },
    { name: 'Vesicular Exanthema of Swine', group: '2' },
    { name: 'Viremia', group: '2' },
    { name: 'Virus Diseases', group: '2' },
    { name: 'Warts', group: '2' },
    { name: 'West Nile Fever', group: '2' },
    { name: 'Yellow Fever', group: '2' },
    { name: 'Sexually Transmitted Diseases, Viral', group: '2' },
    { name: 'Carcinoma, Merkel Cell', group: '2' },
    { name: 'HTLV-I Infections', group: '2' },
    { name: 'HTLV-II Infections', group: '2' },
    { name: 'Paraparesis, Tropical Spastic', group: '2' },
    { name: 'AIDS Dementia Complex', group: '2' },
    { name: 'Cowpox', group: '2' },
    { name: 'HIV Infections', group: '2' },
    { name: 'Fatigue Syndrome, Chronic', group: '2' },
    { name: 'Eye Infections, Viral', group: '2' },
    { name: 'Simian Acquired Immunodeficiency Syndrome', group: '2' },
    { name: 'Lentivirus Infections', group: '2' },
    { name: 'Feline Acquired Immunodeficiency Syndrome', group: '2' },
    { name: 'Visna', group: '2' },
    { name: 'Murine Acquired Immunodeficiency Syndrome', group: '2' },
    { name: 'Postpoliomyelitis Syndrome', group: '2' },
    { name: 'AIDS-Associated Nephropathy', group: '2' },
    { name: 'Leukemia, Feline', group: '2' },
    { name: 'Enzootic Bovine Leukosis', group: '2' },
    { name: 'Herpes Zoster Oticus', group: '2' },
    { name: 'Erythema Infectiosum', group: '2' },
    { name: 'Hepatitis E', group: '2' },
    { name: 'Feline Infectious Peritonitis', group: '2' },
    { name: 'Keratitis, Herpetic', group: '2' },
    { name: 'Skin Diseases, Viral', group: '2' },
    { name: 'Caliciviridae Infections', group: '2' },
    { name: 'Cytomegalovirus Retinitis', group: '2' },
    { name: 'Leukoplakia, Hairy', group: '2' },
    { name: 'Circoviridae Infections', group: '2' },
    { name: 'Arterivirus Infections', group: '2' },
    { name: 'Birnaviridae Infections', group: '2' },
    { name: 'Torovirus Infections', group: '2' },
    { name: 'Flavivirus Infections', group: '2' },
    { name: 'Flaviviridae Infections', group: '2' },
    { name: 'Pestivirus Infections', group: '2' },
    { name: 'Paramyxoviridae Infections', group: '2' },
    { name: 'Morbillivirus Infections', group: '2' },
    { name: 'Pneumovirus Infections', group: '2' },
    { name: 'Cardiovirus Infections', group: '2' },
    { name: 'Hepadnaviridae Infections', group: '2' },
    { name: 'Coronavirus Infections', group: '2' },
    { name: 'Rhabdoviridae Infections', group: '2' },
    { name: 'Alphavirus Infections', group: '2' },
    { name: 'Rubivirus Infections', group: '2' },
    { name: 'Respiratory Syncytial Virus Infections', group: '2' },
    { name: 'Mononegavirales Infections', group: '2' },
    { name: 'Filoviridae Infections', group: '2' },
    { name: 'Hantavirus Infections', group: '2' },
    { name: 'Encephalitis, Viral', group: '2' },
    { name: 'Hantavirus Pulmonary Syndrome', group: '2' },
    { name: 'HIV Enteropathy', group: '2' },
    { name: 'Hemorrhagic Fever, Ebola', group: '2' },
    { name: 'HIV Wasting Syndrome', group: '2' },
    { name: 'Porcine Reproductive and Respiratory Syndrome', group: '2' },
    { name: 'Roseolovirus Infections', group: '2' },
    { name: 'Astroviridae Infections', group: '2' },
    { name: 'Rubulavirus Infections', group: '2' },
    { name: 'Severe Dengue', group: '2' },
    { name: 'Hepatitis B, Chronic', group: '2' },
    { name: 'Hepatitis C, Chronic', group: '2' },
    { name: 'Hepatitis D, Chronic', group: '2' },
    { name: 'Epstein-Barr Virus Infections', group: '2' },
    { name: 'Encephalomyelitis, Western Equine', group: '2' },
    { name: 'Encephalomyelitis, Eastern Equine', group: '2' },
    { name: 'Bell Palsy', group: '2' },
    { name: 'Encephalitis, Herpes Simplex', group: '2' },
    { name: 'Encephalitis, Varicella Zoster', group: '2' },
    { name: 'Central Nervous System Viral Diseases', group: '2' },
    { name: 'AIDS Arteritis, Central Nervous System', group: '2' },
    { name: 'Polyomavirus Infections', group: '2' },
    { name: 'Peste-des-Petits-Ruminants', group: '2' },
    { name: 'Hemorrhagic Syndrome, Bovine', group: '2' },
    { name: 'Nidovirales Infections', group: '2' },
    { name: 'Papillomavirus Infections', group: '2' },
    { name: 'Zoster Sine Herpete', group: '2' },
    { name: 'Hemorrhagic Septicemia, Viral', group: '2' },
    { name: 'HIV-Associated Lipodystrophy Syndrome', group: '2' },
    { name: 'Severe Acute Respiratory Syndrome', group: '2' },
    { name: 'Avulavirus Infections', group: '2' },
    { name: 'Henipavirus Infections', group: '2' },
    { name: 'Monkeypox', group: '2' },
    { name: 'Mink Viral Enteritis', group: '2' },
    { name: 'Porcine Postweaning Multisystemic Wasting Syndrome', group: '2' },
    { name: 'Vesicular Stomatitis', group: '2' },
    { name: 'Buschke-Lowenstein Tumor', group: '2' },
    { name: 'Chikungunya Fever', group: '2' },
    { name: 'Amebiasis', group: '3' },
    { name: 'Ancylostomiasis', group: '3' },
    { name: 'Ascariasis', group: '3' },
    { name: 'Ascaridiasis', group: '3' },
    { name: 'Babesiosis', group: '3' },
    { name: 'Balantidiasis', group: '3' },
    { name: 'Blackwater Fever', group: '3' },
    { name: 'Cestode Infections', group: '3' },
    { name: 'Chagas Cardiomyopathy', group: '3' },
    { name: 'Clonorchiasis', group: '3' },
    { name: 'Coccidiosis', group: '3' },
    { name: 'Cryptosporidiosis', group: '3' },
    { name: 'Cysticercosis', group: '3' },
    { name: 'Dicrocoeliasis', group: '3' },
    { name: 'Dictyocaulus Infections', group: '3' },
    { name: 'Dientamoebiasis', group: '3' },
    { name: 'Dipetalonema Infections', group: '3' },
    { name: 'Diphyllobothriasis', group: '3' },
    { name: 'Dirofilariasis', group: '3' },
    { name: 'Dourine', group: '3' },
    { name: 'Dracunculiasis', group: '3' },
    { name: 'Dysentery, Amebic', group: '3' },
    { name: 'Echinococcosis', group: '3' },
    { name: 'Echinococcosis, Hepatic', group: '3' },
    { name: 'Echinococcosis, Pulmonary', group: '3' },
    { name: 'Echinostomiasis', group: '3' },
    { name: 'Ectoparasitic Infestations', group: '3' },
    { name: 'Elephantiasis, Filarial', group: '3' },
    { name: 'Entamoebiasis', group: '3' },
    { name: 'Fascioliasis', group: '3' },
    { name: 'Fascioloidiasis', group: '3' },
    { name: 'Filariasis', group: '3' },
    { name: 'Giardiasis', group: '3' },
    { name: 'Haemonchiasis', group: '3' },
    { name: 'Helminthiasis', group: '3' },
    { name: 'Helminthiasis, Animal', group: '3' },
    { name: 'Hookworm Infections', group: '3' },
    { name: 'Hymenolepiasis', group: '3' },
    { name: 'Hypodermyiasis', group: '3' },
    { name: 'Intestinal Diseases, Parasitic', group: '3' },
    { name: 'Larva Migrans', group: '3' },
    { name: 'Larva Migrans, Visceral', group: '3' },
    { name: 'Leishmaniasis', group: '3' },
    { name: 'Leishmaniasis, Mucocutaneous', group: '3' },
    { name: 'Leishmaniasis, Visceral', group: '3' },
    { name: 'Liver Diseases, Parasitic', group: '3' },
    { name: 'Loiasis', group: '3' },
    { name: 'Lung Diseases, Parasitic', group: '3' },
    { name: 'Malaria', group: '3' },
    { name: 'Malaria, Avian', group: '3' },
    { name: 'Mansonelliasis', group: '3' },
    { name: 'Mite Infestations', group: '3' },
    { name: 'Monieziasis', group: '3' },
    { name: 'Myiasis', group: '3' },
    { name: 'Necatoriasis', group: '3' },
    { name: 'Nematode Infections', group: '3' },
    { name: 'Oesophagostomiasis', group: '3' },
    { name: 'Onchocerciasis', group: '3' },
    { name: 'Opisthorchiasis', group: '3' },
    { name: 'Ostertagiasis', group: '3' },
    { name: 'Oxyuriasis', group: '3' },
    { name: 'Paragonimiasis', group: '3' },
    { name: 'Parasitic Diseases', group: '3' },
    { name: 'Parasitic Diseases, Animal', group: '3' },
    { name: 'Lice Infestations', group: '3' },
    { name: 'Protozoan Infections', group: '3' },
    { name: 'Protozoan Infections, Animal', group: '3' },
    { name: 'Rhinosporidiosis', group: '3' },
    { name: 'Sarcocystosis', group: '3' },
    { name: 'Scabies', group: '3' },
    { name: 'Schistosomiasis', group: '3' },
    { name: 'Schistosomiasis japonica', group: '3' },
    { name: 'Schistosomiasis mansoni', group: '3' },
    { name: 'Screw Worm Infection', group: '3' },
    { name: 'Setariasis', group: '3' },
    { name: 'Skin Diseases, Parasitic', group: '3' },
    { name: 'Sparganosis', group: '3' },
    { name: 'Strongyle Infections, Equine', group: '3' },
    { name: 'Strongyloidiasis', group: '3' },
    { name: 'Taeniasis', group: '3' },
    { name: 'Theileriasis', group: '3' },
    { name: 'Tick Infestations', group: '3' },
    { name: 'Toxocariasis', group: '3' },
    { name: 'Toxoplasmosis', group: '3' },
    { name: 'Toxoplasmosis, Animal', group: '3' },
    { name: 'Toxoplasmosis, Congenital', group: '3' },
    { name: 'Toxoplasmosis, Ocular', group: '3' },
    { name: 'Trematode Infections', group: '3' },
    { name: 'Trichinellosis', group: '3' },
    { name: 'Trichomonas Infections', group: '3' },
    { name: 'Trichomonas Vaginitis', group: '3' },
    { name: 'Trichostrongyloidiasis', group: '3' },
    { name: 'Trichostrongylosis', group: '3' },
    { name: 'Trichuriasis', group: '3' },
    { name: 'Trombiculiasis', group: '3' },
    { name: 'Trypanosomiasis', group: '3' },
    { name: 'Trypanosomiasis, African', group: '3' },
    { name: 'Trypanosomiasis, Bovine', group: '3' },
    { name: 'Chagas Disease', group: '3' },
    { name: 'Pregnancy Complications, Parasitic', group: '3' },
    { name: 'Eye Infections, Parasitic', group: '3' },
    { name: 'Acanthamoeba Keratitis', group: '3' },
    { name: 'Onchocerciasis, Ocular', group: '3' },
    { name: 'Ciliophora Infections', group: '3' },
    { name: 'Leishmaniasis, Cutaneous', group: '3' },
    { name: 'Leishmaniasis, Diffuse Cutaneous', group: '3' },
    { name: 'Blastocystis Infections', group: '3' },
    { name: 'Malaria, Falciparum', group: '3' },
    { name: 'Malaria, Cerebral', group: '3' },
    { name: 'Malaria, Vivax', group: '3' },
    { name: 'Anisakiasis', group: '3' },
    { name: 'Adenophorea Infections', group: '3' },
    { name: 'Enoplida Infections', group: '3' },
    { name: 'Secernentea Infections', group: '3' },
    { name: 'Ascaridida Infections', group: '3' },
    { name: 'Oxyurida Infections', group: '3' },
    { name: 'Rhabditida Infections', group: '3' },
    { name: 'Spirurida Infections', group: '3' },
    { name: 'Strongylida Infections', group: '3' },
    { name: 'Toxascariasis', group: '3' },
    { name: 'Enterobiasis', group: '3' },
    { name: 'Parasitemia', group: '3' },
    { name: 'Neurocysticercosis', group: '3' },
    { name: 'Central Nervous System Parasitic Infections', group: '3' },
    { name: 'Central Nervous System Protozoal Infections', group: '3' },
    { name: 'Central Nervous System Helminthiasis', group: '3' },
    { name: 'Neuroschistosomiasis', group: '3' },
    { name: 'Isosporiasis', group: '3' },
    { name: 'Cyclosporiasis', group: '3' },
    { name: 'Mesomycetozoea Infections', group: '3' },
    { name: 'Euglenozoa Infections', group: '3' },
    { name: 'Flea Infestations', group: '3' },
    { name: 'Tungiasis', group: '3' },
    { name: 'Gnathostomiasis', group: '3' },
    { name: 'Pythiosis', group: '3' },
    { name: 'Acanthocheilonemiasis', group: '3' },
    { name: 'Abdominal Neoplasms', group: '4' },
    { name: 'Plasmablastic Lymphoma', group: '4' },
    { name: 'Mammary Analogue Secretory Carcinoma', group: '4' },
    { name: 'Unilateral Breast Neoplasms', group: '4' },
    { name: 'Giant Cell Tumor of Tendon Sheath', group: '4' },
    { name: 'Fibromatosis, Plantar', group: '4' },
    { name: 'Breast Carcinoma In Situ', group: '4' },
    { name: 'ACTH Syndrome, Ectopic', group: '4' },
    { name: 'Adenocarcinoma', group: '4' },
    { name: 'Adenocarcinoma, Papillary', group: '4' },
    { name: 'Adenofibroma', group: '4' },
    { name: 'Adenolymphoma', group: '4' },
    { name: 'Adenoma', group: '4' },
    { name: 'Adenoma, Basophil', group: '4' },
    { name: 'Adenoma, Chromophobe', group: '4' },
    { name: 'Adenoma, Acidophil', group: '4' },
    { name: 'Adrenal Cortex Neoplasms', group: '4' },
    { name: 'Adrenal Gland Neoplasms', group: '4' },
    { name: 'Adrenal Rest Tumor', group: '4' },
    { name: 'Ameloblastoma', group: '4' },
    { name: 'Anal Gland Neoplasms', group: '4' },
    { name: 'Anaplasia', group: '4' },
    { name: 'Angiokeratoma', group: '4' },
    { name: 'Anus Neoplasms', group: '4' },
    { name: 'Appendiceal Neoplasms', group: '4' },
    { name: 'Apudoma', group: '4' },
    { name: 'Astrocytoma', group: '4' },
    { name: 'Basal Cell Nevus Syndrome', group: '4' },
    { name: 'Bile Duct Neoplasms', group: '4' },
    { name: 'Biliary Tract Neoplasms', group: '4' },
    { name: 'Urinary Bladder Neoplasms', group: '4' },
    { name: 'Blast Crisis', group: '4' },
    { name: 'Bone Cysts', group: '4' },
    { name: 'Bone Neoplasms', group: '4' },
    { name: 'Bowen\'s Disease', group: '4' },
    { name: 'Brain Neoplasms', group: '4' },
    { name: 'Branchioma', group: '4' },
    { name: 'Breast Neoplasms', group: '4' },
    { name: 'Brenner Tumor', group: '4' },
    { name: 'Bronchial Neoplasms', group: '4' },
    { name: 'Bronchogenic Cyst', group: '4' },
    { name: 'Carcinoid Heart Disease', group: '4' },
    { name: 'Carcinoid Tumor', group: '4' },
    { name: 'Carcinoma', group: '4' },
    { name: 'Carcinoma in Situ', group: '4' },
    { name: 'Carcinoma 256, Walker', group: '4' },
    { name: 'Carcinoma, Basal Cell', group: '4' },
    { name: 'Carcinoma, Basosquamous', group: '4' },
    { name: 'Adenocarcinoma, Bronchiolo-Alveolar', group: '4' },
    { name: 'Carcinoma, Bronchogenic', group: '4' },
    { name: 'Carcinoma, Brown-Pearce', group: '4' },
    { name: 'Carcinoma, Intraductal, Noninfiltrating', group: '4' },
    { name: 'Carcinoma, Ehrlich Tumor', group: '4' },
    { name: 'Carcinoma, Krebs 2', group: '4' },
    { name: 'Adenocarcinoma, Mucinous', group: '4' },
    { name: 'Carcinoma, Non-Small-Cell Lung', group: '4' },
    { name: 'Carcinoma, Papillary', group: '4' },
    { name: 'Carcinoma, Renal Cell', group: '4' },
    { name: 'Adenocarcinoma, Scirrhous', group: '4' },
    { name: 'Carcinoma, Squamous Cell', group: '4' },
    { name: 'Carcinoma, Transitional Cell', group: '4' },
    { name: 'Carcinosarcoma', group: '4' },
    { name: 'Carotid Body Tumor', group: '4' },
    { name: 'Cecal Neoplasms', group: '4' },
    { name: 'Cell Transformation, Neoplastic', group: '4' },
    { name: 'Cell Transformation, Viral', group: '4' },
    { name: 'Cementoma', group: '4' },
    { name: 'Cerebellar Neoplasms', group: '4' },
    { name: 'Cerebral Ventricle Neoplasms', group: '4' },
    { name: 'Uterine Cervical Dysplasia', group: '4' },
    { name: 'Uterine Cervical Neoplasms', group: '4' },
    { name: 'Adenoma, Bile Duct', group: '4' },
    { name: 'Chondroblastoma', group: '4' },
    { name: 'Chondroma', group: '4' },
    { name: 'Chondrosarcoma', group: '4' },
    { name: 'Chordoma', group: '4' },
    { name: 'Hydatidiform Mole, Invasive', group: '4' },
    { name: 'Choriocarcinoma', group: '4' },
    { name: 'Choroid Neoplasms', group: '4' },
    { name: 'Cocarcinogenesis', group: '4' },
    { name: 'Colonic Neoplasms', group: '4' },
    { name: 'Colorectal Neoplasms, Hereditary Nonpolyposis', group: '4' },
    { name: 'Common Bile Duct Neoplasms', group: '4' },
    { name: 'Conjunctival Neoplasms', group: '4' },
    { name: 'Cranial Nerve Neoplasms', group: '4' },
    { name: 'Craniopharyngioma', group: '4' },
    { name: 'Carcinoma, Adenoid Cystic', group: '4' },
    { name: 'Cystadenocarcinoma', group: '4' },
    { name: 'Cystadenoma', group: '4' },
    { name: 'Phyllodes Tumor', group: '4' },
    { name: 'Cysts', group: '4' },
    { name: 'Dentigerous Cyst', group: '4' },
    { name: 'Dermoid Cyst', group: '4' },
    { name: 'Vipoma', group: '4' },
    { name: 'Digestive System Neoplasms', group: '4' },
    { name: 'Duodenal Neoplasms', group: '4' },
    { name: 'Dupuytren Contracture', group: '4' },
    { name: 'Dysgerminoma', group: '4' },
    { name: 'Dysplastic Nevus Syndrome', group: '4' },
    { name: 'Ear Neoplasms', group: '4' },
    { name: 'Endocrine Gland Neoplasms', group: '4' },
    { name: 'Ependymoma', group: '4' },
    { name: 'Epidermal Cyst', group: '4' },
    { name: 'Leukemia, Erythroblastic, Acute', group: '4' },
    { name: 'Erythroplasia', group: '4' },
    { name: 'Esophageal Cyst', group: '4' },
    { name: 'Esophageal Neoplasms', group: '4' },
    { name: 'Exostoses, Multiple Hereditary', group: '4' },
    { name: 'Eye Neoplasms', group: '4' },
    { name: 'Eyelid Neoplasms', group: '4' },
    { name: 'Facial Neoplasms', group: '4' },
    { name: 'Fallopian Tube Neoplasms', group: '4' },
    { name: 'Femoral Neoplasms', group: '4' },
    { name: 'Fibroma', group: '4' },
    { name: 'Fibrosarcoma', group: '4' },
    { name: 'Follicular Cyst', group: '4' },
    { name: 'Gallbladder Neoplasms', group: '4' },
    { name: 'Ganglioneuroma', group: '4' },
    { name: 'Gardner Syndrome', group: '4' },
    { name: 'Gastrointestinal Neoplasms', group: '4' },
    { name: 'Genital Neoplasms, Female', group: '4' },
    { name: 'Genital Neoplasms, Male', group: '4' },
    { name: 'Giant Cell Tumors', group: '4' },
    { name: 'Gingival Neoplasms', group: '4' },
    { name: 'Glioblastoma', group: '4' },
    { name: 'Glioma', group: '4' },
    { name: 'Glomus Tumor', group: '4' },
    { name: 'Glomus Jugulare Tumor', group: '4' },
    { name: 'Glucagonoma', group: '4' },
    { name: 'Granulosa Cell Tumor', group: '4' },
    { name: 'Hamartoma', group: '4' },
    { name: 'Hamartoma Syndrome, Multiple', group: '4' },
    { name: 'Head and Neck Neoplasms', group: '4' },
    { name: 'Heart Neoplasms', group: '4' },
    { name: 'Hemangioendothelioma', group: '4' },
    { name: 'Hemangioma', group: '4' },
    { name: 'Hemangioma, Cavernous', group: '4' },
    { name: 'Hemangiopericytoma', group: '4' },
    { name: 'Hemangiosarcoma', group: '4' },
    { name: 'Carcinoma, Hepatocellular', group: '4' },
    { name: 'Adenoma, Sweat Gland', group: '4' },
    { name: 'Hodgkin Disease', group: '4' },
    { name: 'Hydatidiform Mole', group: '4' },
    { name: 'Hypopharyngeal Neoplasms', group: '4' },
    { name: 'Hypothalamic Neoplasms', group: '4' },
    { name: 'Ileal Neoplasms', group: '4' },
    { name: 'Immunoproliferative Small Intestinal Disease', group: '4' },
    { name: 'Insulinoma', group: '4' },
    { name: 'Intestinal Neoplasms', group: '4' },
    { name: 'Adenoma, Islet Cell', group: '4' },
    { name: 'Jaw Cysts', group: '4' },
    { name: 'Jaw Neoplasms', group: '4' },
    { name: 'Jejunal Neoplasms', group: '4' },
    { name: 'Kidney Neoplasms', group: '4' },
    { name: 'Krukenberg Tumor', group: '4' },
    { name: 'Laryngeal Neoplasms', group: '4' },
    { name: 'Leiomyoma', group: '4' },
    { name: 'Leiomyosarcoma', group: '4' },
    { name: 'Leukemia', group: '4' },
    { name: 'Leukemia L1210', group: '4' },
    { name: 'Leukemia L5178', group: '4' },
    { name: 'Leukemia P388', group: '4' },
    { name: 'Leukemia, Experimental', group: '4' },
    { name: 'Leukemia, Hairy Cell', group: '4' },
    { name: 'Leukemia, Lymphoid', group: '4' },
    { name: 'Leukemia, Mast-Cell', group: '4' },
    { name: 'Leukemia, Megakaryoblastic, Acute', group: '4' },
    { name: 'Leukemia, Monocytic, Acute', group: '4' },
    { name: 'Leukemia, Myeloid', group: '4' },
    { name: 'Leukemia, Plasma Cell', group: '4' },
    { name: 'Leukemia, Radiation-Induced', group: '4' },
    { name: 'Leukoplakia', group: '4' },
    { name: 'Leukoplakia, Oral', group: '4' },
    { name: 'Leydig Cell Tumor', group: '4' },
    { name: 'Linitis Plastica', group: '4' },
    { name: 'Lip Neoplasms', group: '4' },
    { name: 'Lipoma', group: '4' },
    { name: 'Liposarcoma', group: '4' },
    { name: 'Liver Neoplasms', group: '4' },
    { name: 'Liver Neoplasms, Experimental', group: '4' },
    { name: 'Lung Neoplasms', group: '4' },
    { name: 'Lymphangioma', group: '4' },
    { name: 'Lymphangiomyoma', group: '4' },
    { name: 'Lymphangiosarcoma', group: '4' },
    { name: 'Lymphatic Metastasis', group: '4' },
    { name: 'Lymphocele', group: '4' },
    { name: 'Lymphoma', group: '4' },
    { name: 'Lymphoma, Follicular', group: '4' },
    { name: 'Lymphoma, Non-Hodgkin', group: '4' },
    { name: 'Lymphomatoid Granulomatosis', group: '4' },
    { name: 'Waldenstrom Macroglobulinemia', group: '4' },
    { name: 'Malignant Carcinoid Syndrome', group: '4' },
    { name: 'Mammary Neoplasms, Experimental', group: '4' },
    { name: 'Mandibular Neoplasms', group: '4' },
    { name: 'Mastocytosis', group: '4' },
    { name: 'Maxillary Neoplasms', group: '4' },
    { name: 'Maxillary Sinus Neoplasms', group: '4' },
    { name: 'Mediastinal Cyst', group: '4' },
    { name: 'Mediastinal Neoplasms', group: '4' },
    { name: 'Medulloblastoma', group: '4' },
    { name: 'Meigs Syndrome', group: '4' },
    { name: 'Melanoma', group: '4' },
    { name: 'Melanoma, Experimental', group: '4' },
    { name: 'Meningeal Neoplasms', group: '4' },
    { name: 'Meningioma', group: '4' },
    { name: 'Mesenchymoma', group: '4' },
    { name: 'Mesenteric Cyst', group: '4' },
    { name: 'Mesonephroma', group: '4' },
    { name: 'Mesothelioma', group: '4' },
    { name: 'Adenoma, Pleomorphic', group: '4' },
    { name: 'Mouth Neoplasms', group: '4' },
    { name: 'Mucocele', group: '4' },
    { name: 'Multiple Myeloma', group: '4' },
    { name: 'Mycosis Fungoides', group: '4' },
    { name: 'Myelitis, Transverse', group: '4' },
    { name: 'Myoepithelioma', group: '4' },
    { name: 'Myoma', group: '4' },
    { name: 'Myosarcoma', group: '4' },
    { name: 'Myxoma', group: '4' },
    { name: 'Myxosarcoma', group: '4' },
    { name: 'Nasopharyngeal Neoplasms', group: '4' },
    { name: 'Nelson Syndrome', group: '4' },
    { name: 'Neoplastic Cells, Circulating', group: '4' },
    { name: 'Neoplasm Invasiveness', group: '4' },
    { name: 'Neoplasm Metastasis', group: '4' },
    { name: 'Neoplasm Recurrence, Local', group: '4' },
    { name: 'Neoplasm Regression, Spontaneous', group: '4' },
    { name: 'Neoplasm Seeding', group: '4' },
    { name: 'Neoplasms', group: '4' },
    { name: 'Neoplasms by Histologic Type', group: '4' },
    { name: 'Neoplasms by Site', group: '4' },
    { name: 'Neoplasms, Connective Tissue', group: '4' },
    { name: 'Neoplasms, Germ Cell and Embryonal', group: '4' },
    { name: 'Neoplasms, Experimental', group: '4' },
    { name: 'Neoplasms, Glandular and Epithelial', group: '4' },
    { name: 'Neoplasms, Hormone-Dependent', group: '4' },
    { name: 'Multiple Endocrine Neoplasia', group: '4' },
    { name: 'Neoplasms, Multiple Primary', group: '4' },
    { name: 'Neoplasms, Muscle Tissue', group: '4' },
    { name: 'Neoplasms, Nerve Tissue', group: '4' },
    { name: 'Neoplasms, Radiation-Induced', group: '4' },
    { name: 'Neoplasms, Unknown Primary', group: '4' },
    { name: 'Neoplasms, Vascular Tissue', group: '4' },
    { name: 'Paraneoplastic Endocrine Syndromes', group: '4' },
    { name: 'Neoplastic Processes', group: '4' },
    { name: 'Neoplastic Syndromes, Hereditary', group: '4' },
    { name: 'Wilms Tumor', group: '4' },
    { name: 'Nervous System Neoplasms', group: '4' },
    { name: 'Neurilemmoma', group: '4' },
    { name: 'Neuroblastoma', group: '4' },
    { name: 'Neurofibroma', group: '4' },
    { name: 'Neurofibromatosis 1', group: '4' },
    { name: 'Neuroma', group: '4' },
    { name: 'Neuroma, Acoustic', group: '4' },
    { name: 'Nevus', group: '4' },
    { name: 'Nevus of Ota', group: '4' },
    { name: 'Nevus, Pigmented', group: '4' },
    { name: 'Nonodontogenic Cysts', group: '4' },
    { name: 'Nose Neoplasms', group: '4' },
    { name: 'Odontogenic Cysts', group: '4' },
    { name: 'Odontogenic Tumors', group: '4' },
    { name: 'Odontoma', group: '4' },
    { name: 'Oligodendroglioma', group: '4' },
    { name: 'Orbital Neoplasms', group: '4' },
    { name: 'Oropharyngeal Neoplasms', group: '4' },
    { name: 'Osteoma', group: '4' },
    { name: 'Osteoma, Osteoid', group: '4' },
    { name: 'Otorhinolaryngologic Neoplasms', group: '4' },
    { name: 'Ovarian Cysts', group: '4' },
    { name: 'Ovarian Neoplasms', group: '4' },
    { name: 'Paget\'s Disease, Mammary', group: '4' },
    { name: 'Paget Disease, Extramammary', group: '4' },
    { name: 'Palatal Neoplasms', group: '4' },
    { name: 'Pancoast Syndrome', group: '4' },
    { name: 'Pancreatic Cyst', group: '4' },
    { name: 'Pancreatic Neoplasms', group: '4' },
    { name: 'Pancreatic Pseudocyst', group: '4' },
    { name: 'Papilloma', group: '4' },
    { name: 'Paraganglioma', group: '4' },
    { name: 'Paraganglioma, Extra-Adrenal', group: '4' },
    { name: 'Paranasal Sinus Neoplasms', group: '4' },
    { name: 'Paraneoplastic Syndromes', group: '4' },
    { name: 'Parathyroid Neoplasms', group: '4' },
    { name: 'Parotid Neoplasms', group: '4' },
    { name: 'Parovarian Cyst', group: '4' },
    { name: 'Pelvic Neoplasms', group: '4' },
    { name: 'Penile Neoplasms', group: '4' },
    { name: 'Periodontal Cyst', group: '4' },
    { name: 'Peripheral Nervous System Neoplasms', group: '4' },
    { name: 'Peritoneal Neoplasms', group: '4' },
    { name: 'Peutz-Jeghers Syndrome', group: '4' },
    { name: 'Pharyngeal Neoplasms', group: '4' },
    { name: 'Pheochromocytoma', group: '4' },
    { name: 'Pilonidal Sinus', group: '4' },
    { name: 'Pinealoma', group: '4' },
    { name: 'Pituitary Neoplasms', group: '4' },
    { name: 'Plasmacytoma', group: '4' },
    { name: 'Pleural Neoplasms', group: '4' },
    { name: 'Polycystic Ovary Syndrome', group: '4' },
    { name: 'Polycythemia Vera', group: '4' },
    { name: 'Adenomatous Polyposis Coli', group: '4' },
    { name: 'Popliteal Cyst', group: '4' },
    { name: 'Precancerous Conditions', group: '4' },
    { name: 'Pregnancy Complications, Neoplastic', group: '4' },
    { name: 'Preleukemia', group: '4' },
    { name: 'Prostatic Neoplasms', group: '4' },
    { name: 'Pseudomyxoma Peritonei', group: '4' },
    { name: 'Radicular Cyst', group: '4' },
    { name: 'Ranula', group: '4' },
    { name: 'Rectal Neoplasms', group: '4' },
    { name: 'Respiratory Tract Neoplasms', group: '4' },
    { name: 'Retinoblastoma', group: '4' },
    { name: 'Retroperitoneal Neoplasms', group: '4' },
    { name: 'Rhabdomyoma', group: '4' },
    { name: 'Rhabdomyosarcoma', group: '4' },
    { name: 'Salivary Gland Neoplasms', group: '4' },
    { name: 'Sarcoma', group: '4' },
    { name: 'Sarcoma 180', group: '4' },
    { name: 'Sarcoma 37', group: '4' },
    { name: 'Sarcoma, Ewing', group: '4' },
    { name: 'Sarcoma, Experimental', group: '4' },
    { name: 'Mast-Cell Sarcoma', group: '4' },
    { name: 'Osteosarcoma', group: '4' },
    { name: 'Sarcoma, Yoshida', group: '4' },
    { name: 'Sebaceous Gland Neoplasms', group: '4' },
    { name: 'Sertoli Cell Tumor', group: '4' },
    { name: 'Sezary Syndrome', group: '4' },
    { name: 'Sigmoid Neoplasms', group: '4' },
    { name: 'Skin Neoplasms', group: '4' },
    { name: 'Skull Neoplasms', group: '4' },
    { name: 'Soft Tissue Neoplasms', group: '4' },
    { name: 'Somatostatinoma', group: '4' },
    { name: 'Spinal Cord Neoplasms', group: '4' },
    { name: 'Spinal Neoplasms', group: '4' },
    { name: 'Splenic Neoplasms', group: '4' },
    { name: 'Stomach Neoplasms', group: '4' },
    { name: 'Struma Ovarii', group: '4' },
    { name: 'Sturge-Weber Syndrome', group: '4' },
    { name: 'Sublingual Gland Neoplasms', group: '4' },
    { name: 'Submandibular Gland Neoplasms', group: '4' },
    { name: 'Sweat Gland Neoplasms', group: '4' },
    { name: 'Synovial Cyst', group: '4' },
    { name: 'Sarcoma, Synovial', group: '4' },
    { name: 'Synovitis, Pigmented Villonodular', group: '4' },
    { name: 'Teratoma', group: '4' },
    { name: 'Testicular Neoplasms', group: '4' },
    { name: 'Thecoma', group: '4' },
    { name: 'Thoracic Neoplasms', group: '4' },
    { name: 'Thymoma', group: '4' },
    { name: 'Thymus Neoplasms', group: '4' },
    { name: 'Thyroglossal Cyst', group: '4' },
    { name: 'Thyroid Neoplasms', group: '4' },
    { name: 'Tongue Neoplasms', group: '4' },
    { name: 'Tonsillar Neoplasms', group: '4' },
    { name: 'Tracheal Neoplasms', group: '4' },
    { name: 'Trophoblastic Neoplasms', group: '4' },
    { name: 'Tuberous Sclerosis', group: '4' },
    { name: 'Urachal Cyst', group: '4' },
    { name: 'Ureteral Neoplasms', group: '4' },
    { name: 'Urethral Neoplasms', group: '4' },
    { name: 'Urogenital Neoplasms', group: '4' },
    { name: 'Urologic Neoplasms', group: '4' },
    { name: 'Urticaria Pigmentosa', group: '4' },
    { name: 'Uterine Neoplasms', group: '4' },
    { name: 'Uveal Neoplasms', group: '4' },
    { name: 'Vaginal Neoplasms', group: '4' },
    { name: 'Venereal Tumors, Veterinary', group: '4' },
    { name: 'Vulvar Neoplasms', group: '4' },
    { name: 'Xeroderma Pigmentosum', group: '4' },
    { name: 'Zollinger-Ellison Syndrome', group: '4' },
    { name: 'Supratentorial Neoplasms', group: '4' },
    { name: 'Epidural Neoplasms', group: '4' },
    { name: 'Prolactinoma', group: '4' },
    { name: 'Colorectal Neoplasms', group: '4' },
    { name: 'Infratentorial Neoplasms', group: '4' },
    { name: 'Gastrinoma', group: '4' },
    { name: 'Leukemia, B-Cell', group: '4' },
    { name: 'Leukemia, Lymphocytic, Chronic, B-Cell', group: '4' },
    { name: 'Precursor B-Cell Lymphoblastic Leukemia-Lymphoma', group: '4' },
    { name: 'Leukemia, Biphenotypic, Acute', group: '4' },
    { name: 'Leukemia, T-Cell', group: '4' },
    { name: 'Leukemia-Lymphoma, Adult T-Cell', group: '4' },
    { name: 'Leukemia, Prolymphocytic, T-Cell', group: '4' },
    { name: 'Leukemia, Prolymphocytic', group: '4' },
    { name: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', group: '4' },
    { name: 'Leukemia, Myeloid, Accelerated Phase', group: '4' },
    { name: 'Leukemia, Myeloid, Chronic-Phase', group: '4' },
    { name: 'Leukemia, Myeloid, Acute', group: '4' },
    { name: 'Leukemia, Basophilic, Acute', group: '4' },
    { name: 'Leukemia, Eosinophilic, Acute', group: '4' },
    { name: 'Leukemia, Promyelocytic, Acute', group: '4' },
    { name: 'Leukemia, Myelomonocytic, Chronic', group: '4' },
    { name: 'Leukemia, Myelomonocytic, Acute', group: '4' },
    { name: 'Choledochal Cyst', group: '4' },
    { name: 'Histiocytic Disorders, Malignant', group: '4' },
    { name: 'Lambert-Eaton Myasthenic Syndrome', group: '4' },
    { name: 'Mammary Neoplasms, Animal', group: '4' },
    { name: 'Iris Neoplasms', group: '4' },
    { name: 'Osteochondroma', group: '4' },
    { name: 'Pleural Effusion, Malignant', group: '4' },
    { name: 'Arachnoid Cysts', group: '4' },
    { name: 'Lymphoma, B-Cell', group: '4' },
    { name: 'Lymphoma, T-Cell', group: '4' },
    { name: 'Lymphoma, Large-Cell, Immunoblastic', group: '4' },
    { name: 'Lymphoma, Large B-Cell, Diffuse', group: '4' },
    { name: 'Lymphoma, T-Cell, Cutaneous', group: '4' },
    { name: 'Lymphoma, T-Cell, Peripheral', group: '4' },
    { name: 'Lymphoma, AIDS-Related', group: '4' },
    { name: 'Neurofibromatosis 2', group: '4' },
    { name: 'Central Nervous System Neoplasms', group: '4' },
    { name: 'Choroid Plexus Neoplasms', group: '4' },
    { name: 'Granular Cell Tumor', group: '4' },
    { name: 'Thyroid Nodule', group: '4' },
    { name: 'Neoplasms, Second Primary', group: '4' },
    { name: 'Proteus Syndrome', group: '4' },
    { name: 'Li-Fraumeni Syndrome', group: '4' },
    { name: 'Endometrial Neoplasms', group: '4' },
    { name: 'Chalazion', group: '4' },
    { name: 'Neoplasms, Post-Traumatic', group: '4' },
    { name: 'Neurofibromatoses', group: '4' },
    { name: 'Leukemic Infiltration', group: '4' },
    { name: 'Neuroectodermal Tumors', group: '4' },
    { name: 'Neuroectodermal Tumor, Melanotic', group: '4' },
    { name: 'WAGR Syndrome', group: '4' },
    { name: 'Lymphoma, Large-Cell, Anaplastic', group: '4' },
    { name: 'Lymphomatoid Papulosis', group: '4' },
    { name: 'Bone Cysts, Aneurysmal', group: '4' },
    { name: 'Lymphatic Vessel Tumors', group: '4' },
    { name: 'Lymphangioma, Cystic', group: '4' },
    { name: 'Lymphangioleiomyomatosis', group: '4' },
    { name: 'Neoplasms, Complex and Mixed', group: '4' },
    { name: 'Adenomyoma', group: '4' },
    { name: 'Adenosarcoma', group: '4' },
    { name: 'Carcinoma, Adenosquamous', group: '4' },
    { name: 'Hepatoblastoma', group: '4' },
    { name: 'Mixed Tumor, Malignant', group: '4' },
    { name: 'Mixed Tumor, Mesodermal', group: '4' },
    { name: 'Mixed Tumor, Mullerian', group: '4' },
    { name: 'Nephroma, Mesoblastic', group: '4' },
    { name: 'Pulmonary Blastoma', group: '4' },
    { name: 'Sarcoma, Endometrial Stromal', group: '4' },
    { name: 'Neoplasms, Connective and Soft Tissue', group: '4' },
    { name: 'Neoplasms, Adipose Tissue', group: '4' },
    { name: 'Angiolipoma', group: '4' },
    { name: 'Angiomyolipoma', group: '4' },
    { name: 'Liposarcoma, Myxoid', group: '4' },
    { name: 'Myelolipoma', group: '4' },
    { name: 'Chondromatosis', group: '4' },
    { name: 'Chondrosarcoma, Mesenchymal', group: '4' },
    { name: 'Giant Cell Tumor of Bone', group: '4' },
    { name: 'Neoplasms, Bone Tissue', group: '4' },
    { name: 'Fibroma, Ossifying', group: '4' },
    { name: 'Osteoblastoma', group: '4' },
    { name: 'Osteochondromatosis', group: '4' },
    { name: 'Osteosarcoma, Juxtacortical', group: '4' },
    { name: 'Neoplasms, Fibrous Tissue', group: '4' },
    { name: 'Histiocytoma, Benign Fibrous', group: '4' },
    { name: 'Fibroma, Desmoplastic', group: '4' },
    { name: 'Fibromatosis, Abdominal', group: '4' },
    { name: 'Fibromatosis, Aggressive', group: '4' },
    { name: 'Dermatofibrosarcoma', group: '4' },
    { name: 'Myofibromatosis', group: '4' },
    { name: 'Neoplasms, Fibroepithelial', group: '4' },
    { name: 'Fibroadenoma', group: '4' },
    { name: 'Sarcoma, Clear Cell', group: '4' },
    { name: 'Sarcoma, Small Cell', group: '4' },
    { name: 'Angiomyoma', group: '4' },
    { name: 'Leiomyoma, Epithelioid', group: '4' },
    { name: 'Leiomyomatosis', group: '4' },
    { name: 'Rhabdomyosarcoma, Alveolar', group: '4' },
    { name: 'Rhabdomyosarcoma, Embryonal', group: '4' },
    { name: 'Sarcoma, Alveolar Soft Part', group: '4' },
    { name: 'Smooth Muscle Tumor', group: '4' },
    { name: 'Carcinoma, Embryonal', group: '4' },
    { name: 'Germinoma', group: '4' },
    { name: 'Gonadoblastoma', group: '4' },
    { name: 'Seminoma', group: '4' },
    { name: 'Endodermal Sinus Tumor', group: '4' },
    { name: 'Neuroectodermal Tumors, Primitive, Peripheral', group: '4' },
    { name: 'Neuroectodermal Tumors, Primitive', group: '4' },
    { name: 'Teratocarcinoma', group: '4' },
    { name: 'Trophoblastic Tumor, Placental Site', group: '4' },
    { name: 'Adrenocortical Adenoma', group: '4' },
    { name: 'Adenoma, Liver Cell', group: '4' },
    { name: 'Adenoma, Oxyphilic', group: '4' },
    { name: 'Acrospiroma', group: '4' },
    { name: 'Hidrocystoma', group: '4' },
    { name: 'Syringoma', group: '4' },
    { name: 'Adenoma, Villous', group: '4' },
    { name: 'Adenomatoid Tumor', group: '4' },
    { name: 'Adenomatosis, Pulmonary', group: '4' },
    { name: 'Adenomatous Polyps', group: '4' },
    { name: 'Mesothelioma, Cystic', group: '4' },
    { name: 'Adenocarcinoma, Clear Cell', group: '4' },
    { name: 'Adenocarcinoma, Follicular', group: '4' },
    { name: 'Carcinoma, Papillary, Follicular', group: '4' },
    { name: 'Adenocarcinoma, Sebaceous', group: '4' },
    { name: 'Carcinoma, Acinar Cell', group: '4' },
    { name: 'Adrenocortical Carcinoma', group: '4' },
    { name: 'Carcinoma, Endometrioid', group: '4' },
    { name: 'Carcinoma, Ductal, Breast', group: '4' },
    { name: 'Carcinoma, Islet Cell', group: '4' },
    { name: 'Carcinoma, Lobular', group: '4' },
    { name: 'Carcinoma, Medullary', group: '4' },
    { name: 'Carcinoma, Mucoepidermoid', group: '4' },
    { name: 'Carcinoma, Neuroendocrine', group: '4' },
    { name: 'Carcinoma, Signet Ring Cell', group: '4' },
    { name: 'Carcinoma, Skin Appendage', group: '4' },
    { name: 'Cholangiocarcinoma', group: '4' },
    { name: 'Cystadenocarcinoma, Mucinous', group: '4' },
    { name: 'Cystadenocarcinoma, Papillary', group: '4' },
    { name: 'Cystadenocarcinoma, Serous', group: '4' },
    { name: 'Klatskin Tumor', group: '4' },
    { name: 'Carcinoma, Giant Cell', group: '4' },
    { name: 'Carcinoma, Large Cell', group: '4' },
    { name: 'Carcinoma, Small Cell', group: '4' },
    { name: 'Carcinoma, Verrucous', group: '4' },
    { name: 'Cervical Intraepithelial Neoplasia', group: '4' },
    { name: 'Cystadenoma, Mucinous', group: '4' },
    { name: 'Cystadenoma, Papillary', group: '4' },
    { name: 'Cystadenoma, Serous', group: '4' },
    { name: 'Neoplasms, Adnexal and Skin Appendage', group: '4' },
    { name: 'Neoplasms, Basal Cell', group: '4' },
    { name: 'Pilomatrixoma', group: '4' },
    { name: 'Neoplasms, Cystic, Mucinous, and Serous', group: '4' },
    { name: 'Mucoepidermoid Tumor', group: '4' },
    { name: 'Neoplasms, Ductal, Lobular, and Medullary', group: '4' },
    { name: 'Papilloma, Intraductal', group: '4' },
    { name: 'Neoplasms, Mesothelial', group: '4' },
    { name: 'Neoplasms, Neuroepithelial', group: '4' },
    { name: 'Ganglioglioma', group: '4' },
    { name: 'Esthesioneuroblastoma, Olfactory', group: '4' },
    { name: 'Ganglioneuroblastoma', group: '4' },
    { name: 'Neurocytoma', group: '4' },
    { name: 'Neoplasms, Squamous Cell', group: '4' },
    { name: 'Papilloma, Inverted', group: '4' },
    { name: 'Neoplasms, Gonadal Tissue', group: '4' },
    { name: 'Sertoli-Leydig Cell Tumor', group: '4' },
    { name: 'Luteoma', group: '4' },
    { name: 'Sex Cord-Gonadal Stromal Tumors', group: '4' },
    { name: 'Glioma, Subependymal', group: '4' },
    { name: 'Gliosarcoma', group: '4' },
    { name: 'Nerve Sheath Neoplasms', group: '4' },
    { name: 'Neurofibroma, Plexiform', group: '4' },
    { name: 'Neurofibrosarcoma', group: '4' },
    { name: 'Neurothekeoma', group: '4' },
    { name: 'Angiofibroma', group: '4' },
    { name: 'Hemangioendothelioma, Epithelioid', group: '4' },
    { name: 'Hemangioma, Capillary', group: '4' },
    { name: 'Hemangioblastoma', group: '4' },
    { name: 'Nevi and Melanomas', group: '4' },
    { name: 'Hutchinson\'s Melanotic Freckle', group: '4' },
    { name: 'Melanoma, Amelanotic', group: '4' },
    { name: 'Nevus, Blue', group: '4' },
    { name: 'Nevus, Intradermal', group: '4' },
    { name: 'Nevus, Spindle Cell', group: '4' },
    { name: 'Nevus, Epithelioid and Spindle Cell', group: '4' },
    { name: 'Odontogenic Cyst, Calcifying', group: '4' },
    { name: 'Rhabdoid Tumor', group: '4' },
    { name: 'Neuroendocrine Tumors', group: '4' },
    { name: 'Neoplasm, Residual', group: '4' },
    { name: 'Lymphoma, B-Cell, Marginal Zone', group: '4' },
    { name: 'Breast Neoplasms, Male', group: '4' },
    { name: 'Multiple Endocrine Neoplasia Type 1', group: '4' },
    { name: 'Multiple Endocrine Neoplasia Type 2a', group: '4' },
    { name: 'Multiple Endocrine Neoplasia Type 2b', group: '4' },
    { name: 'Carcinoma, Lewis Lung', group: '4' },
    { name: 'Muscle Neoplasms', group: '4' },
    { name: 'Vascular Neoplasms', group: '4' },
    { name: 'Bone Marrow Neoplasms', group: '4' },
    { name: 'Prostatic Intraepithelial Neoplasia', group: '4' },
    { name: 'Skull Base Neoplasms', group: '4' },
    { name: 'Hematologic Neoplasms', group: '4' },
    { name: 'Retinal Neoplasms', group: '4' },
    { name: 'Optic Nerve Neoplasms', group: '4' },
    { name: 'Papilloma, Choroid Plexus', group: '4' },
    { name: 'Brain Stem Neoplasms', group: '4' },
    { name: 'Optic Nerve Glioma', group: '4' },
    { name: 'Paraneoplastic Syndromes, Nervous System', group: '4' },
    { name: 'Paraneoplastic Cerebellar Degeneration', group: '4' },
    { name: 'Limbic Encephalitis', group: '4' },
    { name: 'Paraneoplastic Polyneuropathy', group: '4' },
    { name: 'Lymphoma, Mantle-Cell', group: '4' },
    { name: 'Hemangioma, Cavernous, Central Nervous System', group: '4' },
    { name: 'Central Nervous System Venous Angioma', group: '4' },
    { name: 'Central Nervous System Cysts', group: '4' },
    { name: 'Carcinoma, Pancreatic Ductal', group: '4' },
    { name: 'Sarcoma, Myeloid', group: '4' },
    { name: 'Denys-Drash Syndrome', group: '4' },
    { name: 'Gestational Trophoblastic Disease', group: '4' },
    { name: 'Choriocarcinoma, Non-gestational', group: '4' },
    { name: 'Mastocytosis, Cutaneous', group: '4' },
    { name: 'Mastocytosis, Systemic', group: '4' },
    { name: 'Mastocytoma', group: '4' },
    { name: 'Endometrial Stromal Tumors', group: '4' },
    { name: 'Glomus Tympanicum Tumor', group: '4' },
    { name: 'Carcinoma, Ductal', group: '4' },
    { name: 'Ganglion Cysts', group: '4' },
    { name: 'Gastrointestinal Stromal Tumors', group: '4' },
    { name: 'Breast Cyst', group: '4' },
    { name: 'Myofibroma', group: '4' },
    { name: 'Pulmonary Sclerosing Hemangioma', group: '4' },
    { name: 'Acanthoma', group: '4' },
    { name: 'Mongolian Spot', group: '4' },
    { name: 'Growth Hormone-Secreting Pituitary Adenoma', group: '4' },
    { name: 'ACTH-Secreting Pituitary Adenoma', group: '4' },
    { name: 'Adamantinoma', group: '4' },
    { name: 'Odontogenic Tumor, Squamous', group: '4' },
    { name: 'Histiocytoma', group: '4' },
    { name: 'Histiocytoma, Malignant Fibrous', group: '4' },
    { name: 'Tarlov Cysts', group: '4' },
    { name: 'Opsoclonus-Myoclonus Syndrome', group: '4' },
    { name: 'Nevus, Sebaceous of Jadassohn', group: '4' },
    { name: 'Leukemia, Large Granular Lymphocytic', group: '4' },
    { name: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', group: '4' },
    { name: 'Precursor T-Cell Lymphoblastic Leukemia-Lymphoma', group: '4' },
    { name: 'Neoplasms, Plasma Cell', group: '4' },
    { name: 'Solitary Fibrous Tumor, Pleural', group: '4' },
    { name: 'Solitary Fibrous Tumors', group: '4' },
    { name: 'Lymphoma, Extranodal NK-T-Cell', group: '4' },
    { name: 'Leukemia, Prolymphocytic, B-Cell', group: '4' },
    { name: 'Leukemia, Myelomonocytic, Juvenile', group: '4' },
    { name: 'Leukemia, Myeloid, Chronic, Atypical, BCR-ABL Negative', group: '4' },
    { name: 'Lymphoma, Primary Cutaneous Anaplastic Large Cell', group: '4' },
    { name: 'Lymphoma, Primary Effusion', group: '4' },
    { name: 'Mastocytoma, Skin', group: '4' },
    { name: 'Dendritic Cell Sarcoma, Interdigitating', group: '4' },
    { name: 'Dendritic Cell Sarcoma, Follicular', group: '4' },
    { name: 'Histiocytic Sarcoma', group: '4' },
    { name: 'Langerhans Cell Sarcoma', group: '4' },
    { name: 'Perivascular Epithelioid Cell Neoplasms', group: '4' },
    { name: 'Pallister-Hall Syndrome', group: '4' },
    { name: 'Adenomyoepithelioma', group: '4' },
    { name: 'Multiple Pulmonary Nodules', group: '4' },
    { name: 'Keratosis, Actinic', group: '4' },
    { name: 'Muir-Torre Syndrome', group: '4' },
    { name: 'Small Cell Lung Carcinoma', group: '4' },
    { name: 'Meningeal Carcinomatosis', group: '4' },
    { name: 'Lynch Syndrome II', group: '4' },
    { name: 'Nevus, Halo', group: '4' },
    { name: 'Pagetoid Reticulosis', group: '4' },
    { name: 'Colloid Cysts', group: '4' },
    { name: 'Carney Complex', group: '4' },
    { name: 'Eccrine Porocarcinoma', group: '4' },
    { name: 'Poroma', group: '4' },
    { name: 'Birt-Hogg-Dube Syndrome', group: '4' },
    { name: 'Sister Mary Joseph\'s Nodule', group: '4' },
    { name: 'Desmoplastic Small Round Cell Tumor', group: '4' },
    { name: 'Enteropathy-Associated T-Cell Lymphoma', group: '4' },
    { name: 'Composite Lymphoma', group: '4' },
    { name: 'Aberrant Crypt Foci', group: '4' },
    { name: 'Inflammatory Breast Neoplasms', group: '4' },
    { name: 'Paraneoplastic Syndromes, Ocular', group: '4' },
    { name: 'Kasabach-Merritt Syndrome', group: '4' },
    { name: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', group: '4' },
    { name: 'Neoplasm Micrometastasis', group: '4' },
    { name: 'Hereditary Breast and Ovarian Cancer Syndrome', group: '4' },
    { name: 'Cystadenofibroma', group: '4' },
    { name: 'Lipoblastoma', group: '4' },
    { name: 'Carcinogenesis', group: '4' },
    { name: 'Intraocular Lymphoma', group: '4' },
    { name: 'Prostatic Neoplasms, Castration-Resistant', group: '4' },
    { name: 'Triple Negative Breast Neoplasms', group: '4' },
    { name: 'Atypical Squamous Cells of the Cervix', group: '4' },
    { name: 'Squamous Intraepithelial Lesions of the Cervix', group: '4' },
    { name: 'Adenocarcinoma in Situ', group: '4' },
    { name: 'Thyroid Carcinoma, Anaplastic', group: '4' },
    { name: 'Talipes', group: '5' },
    { name: 'Talipes Cavus', group: '5' },
    { name: 'Metatarsal Valgus', group: '5' },
    { name: 'Metatarsus Varus', group: '5' },
    { name: 'Coxa Magna', group: '5' },
    { name: 'Tarsal Coalition', group: '5' },
    { name: 'Morton Neuroma', group: '5' },
    { name: 'Diastasis, Muscle', group: '5' },
    { name: 'Diastasis, Bone', group: '5' },
    { name: 'Elbow Tendinopathy', group: '5' },
    { name: 'Rotator Cuff Tear Arthropathy', group: '5' },
    { name: 'Crystal Arthropathies', group: '5' },
    { name: 'Enthesopathy', group: '5' },
    { name: 'Bunion', group: '5' },
    { name: 'Fracture Dislocation', group: '5' },
    { name: 'Salter-Harris Fractures', group: '5' },
    { name: 'Achondroplasia', group: '5' },
    { name: 'Acrocephalosyndactylia', group: '5' },
    { name: 'Acromegaly', group: '5' },
    { name: 'Ainhum', group: '5' },
    { name: 'Ankylosis', group: '5' },
    { name: 'Anterior Compartment Syndrome', group: '5' },
    { name: 'Arthritis', group: '5' },
    { name: 'Arthritis, Experimental', group: '5' },
    { name: 'Arthritis, Juvenile', group: '5' },
    { name: 'Arthritis, Rheumatoid', group: '5' },
    { name: 'Arthrogryposis', group: '5' },
    { name: 'Arthropathy, Neurogenic', group: '5' },
    { name: 'Bone Diseases', group: '5' },
    { name: 'Bone Diseases, Developmental', group: '5' },
    { name: 'Bone Diseases, Endocrine', group: '5' },
    { name: 'Bone Diseases, Metabolic', group: '5' },
    { name: 'Bone Resorption', group: '5' },
    { name: 'Bursitis', group: '5' },
    { name: 'Caplan Syndrome', group: '5' },
    { name: 'Cartilage Diseases', group: '5' },
    { name: 'Cherubism', group: '5' },
    { name: 'Chondrocalcinosis', group: '5' },
    { name: 'Chondrodysplasia Punctata', group: '5' },
    { name: 'Cleft Palate', group: '5' },
    { name: 'Cleidocranial Dysplasia', group: '5' },
    { name: 'Clubfoot', group: '5' },
    { name: 'Cockayne Syndrome', group: '5' },
    { name: 'Colles\' Fracture', group: '5' },
    { name: 'Compartment Syndromes', group: '5' },
    { name: 'Contracture', group: '5' },
    { name: 'Craniofacial Dysostosis', group: '5' },
    { name: 'Craniosynostoses', group: '5' },
    { name: 'Congenital Hypothyroidism', group: '5' },
    { name: 'Decalcification, Pathologic', group: '5' },
    { name: 'Dermatomyositis', group: '5' },
    { name: 'Camurati-Engelmann Syndrome', group: '5' },
    { name: 'Hyperostosis, Diffuse Idiopathic Skeletal', group: '5' },
    { name: 'DiGeorge Syndrome', group: '5' },
    { name: 'Joint Dislocations', group: '5' },
    { name: 'Dwarfism', group: '5' },
    { name: 'Dwarfism, Pituitary', group: '5' },
    { name: 'Dysostoses', group: '5' },
    { name: 'Ectromelia', group: '5' },
    { name: 'Ellis-Van Creveld Syndrome', group: '5' },
    { name: 'Enchondromatosis', group: '5' },
    { name: 'Eosinophilic Granuloma', group: '5' },
    { name: 'Epiphyses, Slipped', group: '5' },
    { name: 'Equinus Deformity', group: '5' },
    { name: 'Exostoses', group: '5' },
    { name: 'Fasciitis', group: '5' },
    { name: 'Felty Syndrome', group: '5' },
    { name: 'Femur Head Necrosis', group: '5' },
    { name: 'Fibromyalgia', group: '5' },
    { name: 'Fibrous Dysplasia of Bone', group: '5' },
    { name: 'Fibrous Dysplasia, Monostotic', group: '5' },
    { name: 'Fibrous Dysplasia, Polyostotic', group: '5' },
    { name: 'Flatfoot', group: '5' },
    { name: 'Focal Dermal Hypoplasia', group: '5' },
    { name: 'Foot Deformities', group: '5' },
    { name: 'Foot Deformities, Acquired', group: '5' },
    { name: 'Foot Deformities, Congenital', group: '5' },
    { name: 'Foot Diseases', group: '5' },
    { name: 'Funnel Chest', group: '5' },
    { name: 'Gigantism', group: '5' },
    { name: 'Glycogen Storage Disease Type VII', group: '5' },
    { name: 'Goldenhar Syndrome', group: '5' },
    { name: 'Gout', group: '5' },
    { name: 'Granuloma, Giant Cell', group: '5' },
    { name: 'Hallermann\'s Syndrome', group: '5' },
    { name: 'Hallux Valgus', group: '5' },
    { name: 'Hand Deformities', group: '5' },
    { name: 'Hand Deformities, Acquired', group: '5' },
    { name: 'Hand Deformities, Congenital', group: '5' },
    { name: 'Hemarthrosis', group: '5' },
    { name: 'Hip Contracture', group: '5' },
    { name: 'Hip Dislocation', group: '5' },
    { name: 'Hip Dislocation, Congenital', group: '5' },
    { name: 'Hydrarthrosis', group: '5' },
    { name: 'Hyperostosis Frontalis Interna', group: '5' },
    { name: 'Hyperostosis, Cortical, Congenital', group: '5' },
    { name: 'Hypertelorism', group: '5' },
    { name: 'Intervertebral Disc Displacement', group: '5' },
    { name: 'Jaw Abnormalities', group: '5' },
    { name: 'Jaw Diseases', group: '5' },
    { name: 'Jaw, Edentulous', group: '5' },
    { name: 'Jaw, Edentulous, Partially', group: '5' },
    { name: 'Joint Diseases', group: '5' },
    { name: 'Joint Instability', group: '5' },
    { name: 'Joint Loose Bodies', group: '5' },
    { name: 'Kearns-Sayre Syndrome', group: '5' },
    { name: 'Klippel-Feil Syndrome', group: '5' },
    { name: 'Kyphosis', group: '5' },
    { name: 'Leg Length Inequality', group: '5' },
    { name: 'Legg-Calve-Perthes Disease', group: '5' },
    { name: 'Lordosis', group: '5' },
    { name: 'Mandibular Diseases', group: '5' },
    { name: 'Mandibulofacial Dysostosis', group: '5' },
    { name: 'Marfan Syndrome', group: '5' },
    { name: 'Maxillary Diseases', group: '5' },
    { name: 'Melorheostosis', group: '5' },
    { name: 'Microcephaly', group: '5' },
    { name: 'Micrognathism', group: '5' },
    { name: 'Monteggia\'s Fracture', group: '5' },
    { name: 'Mucolipidoses', group: '5' },
    { name: 'Muscle Cramp', group: '5' },
    { name: 'Muscle Rigidity', group: '5' },
    { name: 'Muscle Spasticity', group: '5' },
    { name: 'Muscular Diseases', group: '5' },
    { name: 'Muscular Dystrophies', group: '5' },
    { name: 'Musculoskeletal Abnormalities', group: '5' },
    { name: 'Musculoskeletal Diseases', group: '5' },
    { name: 'Myofascial Pain Syndromes', group: '5' },
    { name: 'Myoglobinuria', group: '5' },
    { name: 'Myositis', group: '5' },
    { name: 'Myositis Ossificans', group: '5' },
    { name: 'Myotonic Dystrophy', group: '5' },
    { name: 'Myotonia Congenita', group: '5' },
    { name: 'Nail-Patella Syndrome', group: '5' },
    { name: 'Noonan Syndrome', group: '5' },
    { name: 'Orofaciodigital Syndromes', group: '5' },
    { name: 'Osteitis', group: '5' },
    { name: 'Osteitis Deformans', group: '5' },
    { name: 'Osteitis Fibrosa Cystica', group: '5' },
    { name: 'Osteoarthritis', group: '5' },
    { name: 'Osteoarthropathy, Primary Hypertrophic', group: '5' },
    { name: 'Osteoarthropathy, Secondary Hypertrophic', group: '5' },
    { name: 'Osteochondritis', group: '5' },
    { name: 'Osteochondritis Dissecans', group: '5' },
    { name: 'Osteochondrodysplasias', group: '5' },
    { name: 'Osteogenesis Imperfecta', group: '5' },
    { name: 'Osteolysis', group: '5' },
    { name: 'Osteolysis, Essential', group: '5' },
    { name: 'Osteomalacia', group: '5' },
    { name: 'Osteonecrosis', group: '5' },
    { name: 'Osteopetrosis', group: '5' },
    { name: 'Osteopoikilosis', group: '5' },
    { name: 'Osteoporosis', group: '5' },
    { name: 'Osteosclerosis', group: '5' },
    { name: 'Paralyses, Familial Periodic', group: '5' },
    { name: 'Periarthritis', group: '5' },
    { name: 'Pierre Robin Syndrome', group: '5' },
    { name: 'Platybasia', group: '5' },
    { name: 'Poland Syndrome', group: '5' },
    { name: 'Polychondritis, Relapsing', group: '5' },
    { name: 'Polymyalgia Rheumatica', group: '5' },
    { name: 'Prognathism', group: '5' },
    { name: 'Pseudohypoparathyroidism', group: '5' },
    { name: 'Pseudopseudohypoparathyroidism', group: '5' },
    { name: 'Chronic Kidney Disease-Mineral and Bone Disorder', group: '5' },
    { name: 'Rhabdomyolysis', group: '5' },
    { name: 'Rheumatic Nodule', group: '5' },
    { name: 'Rheumatic Diseases', group: '5' },
    { name: 'Rheumatoid Nodule', group: '5' },
    { name: 'Rickets', group: '5' },
    { name: 'Rubinstein-Taybi Syndrome', group: '5' },
    { name: 'Scheuermann Disease', group: '5' },
    { name: 'Scoliosis', group: '5' },
    { name: 'Short Rib-Polydactyly Syndrome', group: '5' },
    { name: 'Shoulder Dislocation', group: '5' },
    { name: 'Sjogren\'s Syndrome', group: '5' },
    { name: 'Spinal Curvatures', group: '5' },
    { name: 'Spinal Diseases', group: '5' },
    { name: 'Spinal Osteophytosis', group: '5' },
    { name: 'Spinal Stenosis', group: '5' },
    { name: 'Spondylitis, Ankylosing', group: '5' },
    { name: 'Spondylolisthesis', group: '5' },
    { name: 'Spondylolysis', group: '5' },
    { name: 'Syndactyly', group: '5' },
    { name: 'Synostosis', group: '5' },
    { name: 'Synovitis', group: '5' },
    { name: 'Temporomandibular Joint Disorders', group: '5' },
    { name: 'Temporomandibular Joint Dysfunction Syndrome', group: '5' },
    { name: 'Tennis Elbow', group: '5' },
    { name: 'Tenosynovitis', group: '5' },
    { name: 'Thanatophoric Dysplasia', group: '5' },
    { name: 'Tietze\'s Syndrome', group: '5' },
    { name: 'Wissler\'s Syndrome', group: '5' },
    { name: 'Osteoarthritis, Hip', group: '5' },
    { name: 'Arthritis, Gouty', group: '5' },
    { name: 'Hyperostosis, Sternocostoclavicular', group: '5' },
    { name: 'Arthritis, Psoriatic', group: '5' },
    { name: 'Hyperostosis', group: '5' },
    { name: 'Osteoporosis, Postmenopausal', group: '5' },
    { name: 'Langer-Giedion Syndrome', group: '5' },
    { name: 'Chondromatosis, Synovial', group: '5' },
    { name: 'Holoprosencephaly', group: '5' },
    { name: 'Alveolar Bone Loss', group: '5' },
    { name: 'Eosinophilia-Myalgia Syndrome', group: '5' },
    { name: 'Still\'s Disease, Adult-Onset', group: '5' },
    { name: 'Joint Deformities, Acquired', group: '5' },
    { name: 'Mitochondrial Encephalomyopathies', group: '5' },
    { name: 'Mitochondrial Myopathies', group: '5' },
    { name: 'MELAS Syndrome', group: '5' },
    { name: 'MERRF Syndrome', group: '5' },
    { name: 'Ophthalmoplegia, Chronic Progressive External', group: '5' },
    { name: 'Craniomandibular Disorders', group: '5' },
    { name: 'Polymyositis', group: '5' },
    { name: 'Polydactyly', group: '5' },
    { name: 'Myopathies, Nemaline', group: '5' },
    { name: 'Bone Malalignment', group: '5' },
    { name: 'Limb Deformities, Congenital', group: '5' },
    { name: 'Ossification of Posterior Longitudinal Ligament', group: '5' },
    { name: 'Bone Demineralization, Pathologic', group: '5' },
    { name: 'Arthralgia', group: '5' },
    { name: 'Chondrodysplasia Punctata, Rhizomelic', group: '5' },
    { name: 'Muscle Weakness', group: '5' },
    { name: 'Myositis, Inclusion Body', group: '5' },
    { name: 'Craniofacial Abnormalities', group: '5' },
    { name: 'Shoulder Impingement Syndrome', group: '5' },
    { name: 'Maxillofacial Abnormalities', group: '5' },
    { name: 'Shoulder Pain', group: '5' },
    { name: 'Acquired Hyperostosis Syndrome', group: '5' },
    { name: 'Gastroschisis', group: '5' },
    { name: 'Osteoarthritis, Knee', group: '5' },
    { name: 'Isaacs Syndrome', group: '5' },
    { name: 'Muscular Dystrophy, Duchenne', group: '5' },
    { name: 'Muscular Dystrophy, Emery-Dreifuss', group: '5' },
    { name: 'Muscular Dystrophy, Facioscapulohumeral', group: '5' },
    { name: 'Myopathy, Central Core', group: '5' },
    { name: 'Paralysis, Hyperkalemic Periodic', group: '5' },
    { name: 'Hypokalemic Periodic Paralysis', group: '5' },
    { name: 'Hallux Limitus', group: '5' },
    { name: 'Hallux Rigidus', group: '5' },
    { name: 'Myopathies, Structural, Congenital', group: '5' },
    { name: 'Muscular Disorders, Atrophic', group: '5' },
    { name: 'Myotonic Disorders', group: '5' },
    { name: 'Spondylarthritis', group: '5' },
    { name: 'Spondylarthropathies', group: '5' },
    { name: 'Acro-Osteolysis', group: '5' },
    { name: 'Knee Dislocation', group: '5' },
    { name: 'Patellar Dislocation', group: '5' },
    { name: 'Hajdu-Cheney Syndrome', group: '5' },
    { name: 'Fasciitis, Plantar', group: '5' },
    { name: 'Heel Spur', group: '5' },
    { name: 'Metatarsalgia', group: '5' },
    { name: 'Posterior Tibial Tendon Dysfunction', group: '5' },
    { name: 'Hammer Toe Syndrome', group: '5' },
    { name: 'Lower Extremity Deformities, Congenital', group: '5' },
    { name: 'Upper Extremty Deformities, Congenital', group: '5' },
    { name: 'Muscular Dystrophy, Oculopharyngeal', group: '5' },
    { name: 'LEOPARD Syndrome', group: '5' },
    { name: 'Laron Syndrome', group: '5' },
    { name: 'Pubic Symphysis Diastasis', group: '5' },
    { name: 'Patellofemoral Pain Syndrome', group: '5' },
    { name: 'Chondromalacia Patellae', group: '5' },
    { name: 'Plagiocephaly, Nonsynostotic', group: '5' },
    { name: 'Muscular Dystrophies, Limb-Girdle', group: '5' },
    { name: 'Distal Myopathies', group: '5' },
    { name: 'Mulibrey Nanism', group: '5' },
    { name: 'Hallux Varus', group: '5' },
    { name: 'Bunion, Tailor\'s', group: '5' },
    { name: 'Tendinopathy', group: '5' },
    { name: 'Trigger Finger Disorder', group: '5' },
    { name: 'Familial Hypophosphatemic Rickets', group: '5' },
    { name: 'Tendon Entrapment', group: '5' },
    { name: 'De Quervain Disease', group: '5' },
    { name: 'Female Athlete Triad Syndrome', group: '5' },
    { name: 'Ischemic Contracture', group: '5' },
    { name: 'Arachnodactyly', group: '5' },
    { name: 'Osteophyte', group: '5' },
    { name: 'Antley-Bixler Syndrome Phenotype', group: '5' },
    { name: 'Spondylosis', group: '5' },
    { name: 'Posterior Cervical Sympathetic Syndrome', group: '5' },
    { name: 'Osteoarthritis, Spine', group: '5' },
    { name: 'Osteochondrosis', group: '5' },
    { name: 'Spinal Osteochondrosis', group: '5' },
    { name: 'Campomelic Dysplasia', group: '5' },
    { name: 'Tracheobronchomalacia', group: '5' },
    { name: 'Tracheomalacia', group: '5' },
    { name: 'Bronchomalacia', group: '5' },
    { name: 'Laryngomalacia', group: '5' },
    { name: 'Orbital Myositis', group: '5' },
    { name: 'Loeys-Dietz Syndrome', group: '5' },
    { name: 'Intervertebral Disc Degeneration', group: '5' },
    { name: 'Genu Valgum', group: '5' },
    { name: 'Genu Varum', group: '5' },
    { name: 'Rheumatoid Vasculitis', group: '5' },
    { name: 'Costello Syndrome', group: '5' },
    { name: 'Silver-Russell Syndrome', group: '5' },
    { name: 'Donohue Syndrome', group: '5' },
    { name: 'Weill-Marchesani Syndrome', group: '5' },
    { name: 'Kashin-Beck Disease', group: '5' },
    { name: 'Femoracetabular Impingement', group: '5' },
    { name: 'Sarcoglycanopathies', group: '5' },
    { name: '22q11 Deletion Syndrome', group: '5' },
    { name: 'Fraser Syndrome', group: '5' },
    { name: 'Sacroiliitis', group: '5' },
    { name: 'Megalencephaly', group: '5' },
    { name: 'Pycnodysostosis', group: '5' },
    { name: 'Medial Tibial Stress Syndrome', group: '5' },
    { name: 'Plagiocephaly', group: '5' },
    { name: 'Bisphosphonate-Associated Osteonecrosis of the Jaw', group: '5' },
    { name: 'Intra-Abdominal Hypertension', group: '5' },
    { name: 'Brachydactyly', group: '5' },
    { name: 'Musculoskeletal Pain', group: '5' },
    { name: 'Slipped Capital Femoral Epiphyses', group: '5' },
    { name: 'Bone Anteversion', group: '5' },
    { name: 'Bone Retroversion', group: '5' },
    { name: 'Coxa Vara', group: '5' },
    { name: 'Coxa Valga', group: '5' },
    { name: 'Dentofacial Deformities', group: '5' },
    { name: 'Retrognathia', group: '5' },
    { name: 'Rickets, Hypophosphatemic', group: '5' },
    { name: 'Myalgia', group: '5' },
    { name: 'Hemimegalencephaly', group: '5' },
    { name: 'Porencephaly', group: '5' },
    { name: 'Pectus Carinatum', group: '5' },
    { name: 'Anorectal Malformations', group: '6' },
    { name: 'Achlorhydria', group: '6' },
    { name: 'Afferent Loop Syndrome', group: '6' },
    { name: 'Anus Diseases', group: '6' },
    { name: 'Anus, Imperforate', group: '6' },
    { name: 'Barrett Esophagus', group: '6' },
    { name: 'Bile Duct Diseases', group: '6' },
    { name: 'Cholestasis, Extrahepatic', group: '6' },
    { name: 'Bile Reflux', group: '6' },
    { name: 'Biliary Atresia', group: '6' },
    { name: 'Biliary Dyskinesia', group: '6' },
    { name: 'Biliary Fistula', group: '6' },
    { name: 'Biliary Tract Diseases', group: '6' },
    { name: 'Blind Loop Syndrome', group: '6' },
    { name: 'Cecal Diseases', group: '6' },
    { name: 'Celiac Disease', group: '6' },
    { name: 'Cholangitis', group: '6' },
    { name: 'Cholecystitis', group: '6' },
    { name: 'Cholelithiasis', group: '6' },
    { name: 'Cholestasis', group: '6' },
    { name: 'Cholestasis, Intrahepatic', group: '6' },
    { name: 'Chylous Ascites', group: '6' },
    { name: 'Colitis', group: '6' },
    { name: 'Colitis, Ulcerative', group: '6' },
    { name: 'Colonic Diseases', group: '6' },
    { name: 'Colonic Diseases, Functional', group: '6' },
    { name: 'Colonic Pseudo-Obstruction', group: '6' },
    { name: 'Common Bile Duct Diseases', group: '6' },
    { name: 'Crohn Disease', group: '6' },
    { name: 'Cystic Fibrosis', group: '6' },
    { name: 'Deglutition Disorders', group: '6' },
    { name: 'Diaphragmatic Eventration', group: '6' },
    { name: 'Digestive System Abnormalities', group: '6' },
    { name: 'Digestive System Diseases', group: '6' },
    { name: 'Diverticulitis, Colonic', group: '6' },
    { name: 'Dumping Syndrome', group: '6' },
    { name: 'Duodenal Diseases', group: '6' },
    { name: 'Duodenal Obstruction', group: '6' },
    { name: 'Duodenal Ulcer', group: '6' },
    { name: 'Duodenitis', group: '6' },
    { name: 'Duodenogastric Reflux', group: '6' },
    { name: 'Dysentery', group: '6' },
    { name: 'Enteritis', group: '6' },
    { name: 'Enterocolitis', group: '6' },
    { name: 'Esophageal Achalasia', group: '6' },
    { name: 'Esophageal and Gastric Varices', group: '6' },
    { name: 'Esophageal Atresia', group: '6' },
    { name: 'Esophageal Diseases', group: '6' },
    { name: 'Esophageal Fistula', group: '6' },
    { name: 'Esophageal Perforation', group: '6' },
    { name: 'Esophageal Stenosis', group: '6' },
    { name: 'Esophagitis', group: '6' },
    { name: 'Esophagitis, Peptic', group: '6' },
    { name: 'Fatty Liver', group: '6' },
    { name: 'Fatty Liver, Alcoholic', group: '6' },
    { name: 'Fecal Incontinence', group: '6' },
    { name: 'Fecal Impaction', group: '6' },
    { name: 'Fissure in Ano', group: '6' },
    { name: 'Gallbladder Diseases', group: '6' },
    { name: 'Gastric Fistula', group: '6' },
    { name: 'Gastritis', group: '6' },
    { name: 'Gastritis, Atrophic', group: '6' },
    { name: 'Gastritis, Hypertrophic', group: '6' },
    { name: 'Gastroenteritis', group: '6' },
    { name: 'Gastroesophageal Reflux', group: '6' },
    { name: 'Gastrointestinal Diseases', group: '6' },
    { name: 'Hematemesis', group: '6' },
    { name: 'Hemoperitoneum', group: '6' },
    { name: 'Gastrointestinal Hemorrhage', group: '6' },
    { name: 'Hemorrhoids', group: '6' },
    { name: 'Hepatic Encephalopathy', group: '6' },
    { name: 'Budd-Chiari Syndrome', group: '6' },
    { name: 'Hepatic Veno-Occlusive Disease', group: '6' },
    { name: 'Hepatitis', group: '6' },
    { name: 'Hepatitis, Alcoholic', group: '6' },
    { name: 'Hepatitis, Animal', group: '6' },
    { name: 'Hepatitis, Chronic', group: '6' },
    { name: 'Hepatolenticular Degeneration', group: '6' },
    { name: 'Hepatomegaly', group: '6' },
    { name: 'Hepatorenal Syndrome', group: '6' },
    { name: 'Hirschsprung Disease', group: '6' },
    { name: 'Hypertension, Portal', group: '6' },
    { name: 'Ileal Diseases', group: '6' },
    { name: 'Ileitis', group: '6' },
    { name: 'Intestinal Atresia', group: '6' },
    { name: 'Intestinal Diseases', group: '6' },
    { name: 'Intestinal Fistula', group: '6' },
    { name: 'Intestinal Obstruction', group: '6' },
    { name: 'Intestinal Perforation', group: '6' },
    { name: 'Intestinal Pseudo-Obstruction', group: '6' },
    { name: 'Intussusception', group: '6' },
    { name: 'Jejunal Diseases', group: '6' },
    { name: 'Lactose Intolerance', group: '6' },
    { name: 'Liver Cirrhosis', group: '6' },
    { name: 'Liver Cirrhosis, Alcoholic', group: '6' },
    { name: 'Liver Cirrhosis, Biliary', group: '6' },
    { name: 'Liver Cirrhosis, Experimental', group: '6' },
    { name: 'Liver Diseases', group: '6' },
    { name: 'Liver Diseases, Alcoholic', group: '6' },
    { name: 'Malabsorption Syndromes', group: '6' },
    { name: 'Mallory-Weiss Syndrome', group: '6' },
    { name: 'Meckel Diverticulum', group: '6' },
    { name: 'Megacolon', group: '6' },
    { name: 'Megacolon, Toxic', group: '6' },
    { name: 'Melena', group: '6' },
    { name: 'Mesenteric Lymphadenitis', group: '6' },
    { name: 'Mesenteric Vascular Occlusion', group: '6' },
    { name: 'Pancreatic Diseases', group: '6' },
    { name: 'Pancreatic Fistula', group: '6' },
    { name: 'Exocrine Pancreatic Insufficiency', group: '6' },
    { name: 'Pancreatitis', group: '6' },
    { name: 'Peliosis Hepatis', group: '6' },
    { name: 'Peptic Ulcer', group: '6' },
    { name: 'Peptic Ulcer Hemorrhage', group: '6' },
    { name: 'Peptic Ulcer Perforation', group: '6' },
    { name: 'Peritoneal Diseases', group: '6' },
    { name: 'Plummer-Vinson Syndrome', group: '6' },
    { name: 'Pneumatosis Cystoides Intestinalis', group: '6' },
    { name: 'Pneumoperitoneum', group: '6' },
    { name: 'Postgastrectomy Syndromes', group: '6' },
    { name: 'Proctitis', group: '6' },
    { name: 'Proctocolitis', group: '6' },
    { name: 'Protein-Losing Enteropathies', group: '6' },
    { name: 'Pruritus Ani', group: '6' },
    { name: 'Pyloric Stenosis', group: '6' },
    { name: 'Rectal Diseases', group: '6' },
    { name: 'Rectal Fistula', group: '6' },
    { name: 'Rectal Prolapse', group: '6' },
    { name: 'Rectovaginal Fistula', group: '6' },
    { name: 'Reye Syndrome', group: '6' },
    { name: 'Short Bowel Syndrome', group: '6' },
    { name: 'Sigmoid Diseases', group: '6' },
    { name: 'Sprue, Tropical', group: '6' },
    { name: 'Gastric Dilatation', group: '6' },
    { name: 'Stomach Diseases', group: '6' },
    { name: 'Stomach Rupture', group: '6' },
    { name: 'Stomach Ulcer', group: '6' },
    { name: 'Stomach Volvulus', group: '6' },
    { name: 'Superior Mesenteric Artery Syndrome', group: '6' },
    { name: 'Tracheoesophageal Fistula', group: '6' },
    { name: 'Visceral Prolapse', group: '6' },
    { name: 'Esophageal Motility Disorders', group: '6' },
    { name: 'Esophageal Spasm, Diffuse', group: '6' },
    { name: 'Cholangitis, Sclerosing', group: '6' },
    { name: 'Zellweger Syndrome', group: '6' },
    { name: 'Inflammatory Bowel Diseases', group: '6' },
    { name: 'Panniculitis, Peritoneal', group: '6' },
    { name: 'Digestive System Fistula', group: '6' },
    { name: 'Alagille Syndrome', group: '6' },
    { name: 'Caroli Disease', group: '6' },
    { name: 'Colitis, Ischemic', group: '6' },
    { name: 'Liver Failure', group: '6' },
    { name: 'Porphyrias, Hepatic', group: '6' },
    { name: 'Liver Failure, Acute', group: '6' },
    { name: 'Porphyria, Acute Intermittent', group: '6' },
    { name: 'Porphyria Cutanea Tarda', group: '6' },
    { name: 'Porphyria, Hepatoerythropoietic', group: '6' },
    { name: 'Gastric Outlet Obstruction', group: '6' },
    { name: 'Postcholecystectomy Syndrome', group: '6' },
    { name: 'CREST Syndrome', group: '6' },
    { name: 'Cholera Morbus', group: '6' },
    { name: 'Gastroparesis', group: '6' },
    { name: 'Pancreatitis, Acute Necrotizing', group: '6' },
    { name: 'Pouchitis', group: '6' },
    { name: 'Pancreatitis, Alcoholic', group: '6' },
    { name: 'Hepatitis, Autoimmune', group: '6' },
    { name: 'alpha 1-Antitrypsin Deficiency', group: '6' },
    { name: 'Rectocele', group: '6' },
    { name: 'Hepatopulmonary Syndrome', group: '6' },
    { name: 'Gastric Antral Vascular Ectasia', group: '6' },
    { name: 'Enterocolitis, Necrotizing', group: '6' },
    { name: 'Focal Nodular Hyperplasia', group: '6' },
    { name: 'Cholecystolithiasis', group: '6' },
    { name: 'Cholecystitis, Acute', group: '6' },
    { name: 'Emphysematous Cholecystitis', group: '6' },
    { name: 'Acalculous Cholecystitis', group: '6' },
    { name: 'Gallstones', group: '6' },
    { name: 'Choledocholithiasis', group: '6' },
    { name: 'Irritable Bowel Syndrome', group: '6' },
    { name: 'Diverticulosis, Colonic', group: '6' },
    { name: 'Intestinal Polyposis', group: '6' },
    { name: 'Enterocolitis, Neutropenic', group: '6' },
    { name: 'Congenital Hyperinsulinism', group: '6' },
    { name: 'Steatorrhea', group: '6' },
    { name: 'Diverticulosis, Esophageal', group: '6' },
    { name: 'Diverticulosis, Stomach', group: '6' },
    { name: 'Intestinal Volvulus', group: '6' },
    { name: 'Ileus', group: '6' },
    { name: 'Pyloric Stenosis, Hypertrophic', group: '6' },
    { name: 'Coproporphyria, Hereditary', group: '6' },
    { name: 'Porphyria, Variegate', group: '6' },
    { name: 'Protoporphyria, Erythropoietic', group: '6' },
    { name: 'Sphincter of Oddi Dysfunction', group: '6' },
    { name: 'Colitis, Microscopic', group: '6' },
    { name: 'Colitis, Collagenous', group: '6' },
    { name: 'Colitis, Lymphocytic', group: '6' },
    { name: 'Nesidioblastosis', group: '6' },
    { name: 'Massive Hepatic Necrosis', group: '6' },
    { name: 'Hepatic Insufficiency', group: '6' },
    { name: 'Pancreatitis, Chronic', group: '6' },
    { name: 'Mucositis', group: '6' },
    { name: 'Neurogenic Bowel', group: '6' },
    { name: 'Pancreatitis, Graft', group: '6' },
    { name: 'Chemical and Drug Induced Liver Injury', group: '6' },
    { name: 'Drug-Induced Liver Injury, Chronic', group: '6' },
    { name: 'Peritoneal Fibrosis', group: '6' },
    { name: 'Laryngopharyngeal Reflux', group: '6' },
    { name: 'Eosinophilic Esophagitis', group: '6' },
    { name: 'Mirizzi Syndrome', group: '6' },
    { name: 'End Stage Liver Disease', group: '6' },
    { name: 'Chilaiditi Syndrome', group: '6' },
    { name: 'Respiratory Aspiration of Gastric Contents', group: '6' },
    { name: 'Collagenous Sprue', group: '6' },
    { name: 'Acute-On-Chronic Liver Failure', group: '6' },
    { name: 'Non-alcoholic Fatty Liver Disease', group: '6' },
    { name: 'Mesenteric Ischemia', group: '6' },
    { name: 'Ankyloglossia', group: '7' },
    { name: 'Amelogenesis Imperfecta', group: '7' },
    { name: 'Anodontia', group: '7' },
    { name: 'Behcet Syndrome', group: '7' },
    { name: 'Bruxism', group: '7' },
    { name: 'Burning Mouth Syndrome', group: '7' },
    { name: 'Cheilitis', group: '7' },
    { name: 'Cleft Lip', group: '7' },
    { name: 'Cracked Tooth Syndrome', group: '7' },
    { name: 'Dens in Dente', group: '7' },
    { name: 'Dental Calculus', group: '7' },
    { name: 'Dental Caries', group: '7' },
    { name: 'Dental Deposits', group: '7' },
    { name: 'Dental Enamel Hypoplasia', group: '7' },
    { name: 'Dental Fissures', group: '7' },
    { name: 'Dental Fistula', group: '7' },
    { name: 'Dental Leakage', group: '7' },
    { name: 'Dental Occlusion, Traumatic', group: '7' },
    { name: 'Dental Plaque', group: '7' },
    { name: 'Dental Pulp Calcification', group: '7' },
    { name: 'Dental Pulp Diseases', group: '7' },
    { name: 'Dental Pulp Exposure', group: '7' },
    { name: 'Dental Pulp Necrosis', group: '7' },
    { name: 'Dentin Dysplasia', group: '7' },
    { name: 'Dentin Sensitivity', group: '7' },
    { name: 'Dentin, Secondary', group: '7' },
    { name: 'Dentinogenesis Imperfecta', group: '7' },
    { name: 'Diastema', group: '7' },
    { name: 'Dry Socket', group: '7' },
    { name: 'Facial Hemiatrophy', group: '7' },
    { name: 'Facial Nerve Diseases', group: '7' },
    { name: 'Facial Neuralgia', group: '7' },
    { name: 'Facial Paralysis', group: '7' },
    { name: 'Fibromatosis, Gingival', group: '7' },
    { name: 'Fused Teeth', group: '7' },
    { name: 'Gingival Diseases', group: '7' },
    { name: 'Gingival Hemorrhage', group: '7' },
    { name: 'Gingival Hyperplasia', group: '7' },
    { name: 'Gingival Hypertrophy', group: '7' },
    { name: 'Gingival Pocket', group: '7' },
    { name: 'Gingival Recession', group: '7' },
    { name: 'Gingivitis', group: '7' },
    { name: 'Glossalgia', group: '7' },
    { name: 'Glossitis', group: '7' },
    { name: 'Glossitis, Benign Migratory', group: '7' },
    { name: 'Oral Hemorrhage', group: '7' },
    { name: 'Hypercementosis', group: '7' },
    { name: 'Leukoedema, Oral', group: '7' },
    { name: 'Lip Diseases', group: '7' },
    { name: 'Macroglossia', group: '7' },
    { name: 'Macrostomia', group: '7' },
    { name: 'Malocclusion', group: '7' },
    { name: 'Malocclusion, Angle Class I', group: '7' },
    { name: 'Malocclusion, Angle Class II', group: '7' },
    { name: 'Malocclusion, Angle Class III', group: '7' },
    { name: 'Melkersson-Rosenthal Syndrome', group: '7' },
    { name: 'Mesial Movement of Teeth', group: '7' },
    { name: 'Microstomia', group: '7' },
    { name: 'Mikulicz\' Disease', group: '7' },
    { name: 'Fluorosis, Dental', group: '7' },
    { name: 'Mouth Abnormalities', group: '7' },
    { name: 'Stomatognathic Diseases', group: '7' },
    { name: 'Mouth Diseases', group: '7' },
    { name: 'Mouth, Edentulous', group: '7' },
    { name: 'Nasopharyngeal Diseases', group: '7' },
    { name: 'Nasopharyngitis', group: '7' },
    { name: 'Noma', group: '7' },
    { name: 'Oral Manifestations', group: '7' },
    { name: 'Oral Submucous Fibrosis', group: '7' },
    { name: 'Oroantral Fistula', group: '7' },
    { name: 'Parotid Diseases', group: '7' },
    { name: 'Parotitis', group: '7' },
    { name: 'Periapical Diseases', group: '7' },
    { name: 'Periapical Granuloma', group: '7' },
    { name: 'Periapical Periodontitis', group: '7' },
    { name: 'Pericoronitis', group: '7' },
    { name: 'Periodontal Diseases', group: '7' },
    { name: 'Periodontal Pocket', group: '7' },
    { name: 'Periodontitis', group: '7' },
    { name: 'Aggressive Periodontitis', group: '7' },
    { name: 'Pharyngeal Diseases', group: '7' },
    { name: 'Pharyngitis', group: '7' },
    { name: 'Pulpitis', group: '7' },
    { name: 'Root Resorption', group: '7' },
    { name: 'Salivary Duct Calculi', group: '7' },
    { name: 'Salivary Gland Diseases', group: '7' },
    { name: 'Salivary Gland Fistula', group: '7' },
    { name: 'Sialadenitis', group: '7' },
    { name: 'Sialometaplasia, Necrotizing', group: '7' },
    { name: 'Sialorrhea', group: '7' },
    { name: 'Stevens-Johnson Syndrome', group: '7' },
    { name: 'Stomatitis', group: '7' },
    { name: 'Stomatitis, Aphthous', group: '7' },
    { name: 'Stomatitis, Denture', group: '7' },
    { name: 'Submandibular Gland Diseases', group: '7' },
    { name: 'Tongue Diseases', group: '7' },
    { name: 'Tongue, Fissured', group: '7' },
    { name: 'Tongue, Hairy', group: '7' },
    { name: 'Tonsillitis', group: '7' },
    { name: 'Tooth Abnormalities', group: '7' },
    { name: 'Tooth Abrasion', group: '7' },
    { name: 'Tooth Discoloration', group: '7' },
    { name: 'Tooth Diseases', group: '7' },
    { name: 'Tooth Erosion', group: '7' },
    { name: 'Tooth Eruption, Ectopic', group: '7' },
    { name: 'Tooth Fractures', group: '7' },
    { name: 'Tooth Avulsion', group: '7' },
    { name: 'Tooth Migration', group: '7' },
    { name: 'Tooth Mobility', group: '7' },
    { name: 'Tooth Resorption', group: '7' },
    { name: 'Tooth, Impacted', group: '7' },
    { name: 'Tooth, Supernumerary', group: '7' },
    { name: 'Tooth, Unerupted', group: '7' },
    { name: 'Toothache', group: '7' },
    { name: 'Trigeminal Neuralgia', group: '7' },
    { name: 'Velopharyngeal Insufficiency', group: '7' },
    { name: 'Xerostomia', group: '7' },
    { name: 'Salivary Gland Calculi', group: '7' },
    { name: 'Oral Fistula', group: '7' },
    { name: 'Smear Layer', group: '7' },
    { name: 'Tooth Loss', group: '7' },
    { name: 'Tooth Demineralization', group: '7' },
    { name: 'Root Caries', group: '7' },
    { name: 'Focal Epithelial Hyperplasia', group: '7' },
    { name: 'Periodontal Attachment Loss', group: '7' },
    { name: 'Lichen Planus, Oral', group: '7' },
    { name: 'Furcation Defects', group: '7' },
    { name: 'Odontodysplasia', group: '7' },
    { name: 'Stomatognathic System Abnormalities', group: '7' },
    { name: 'Tooth Injuries', group: '7' },
    { name: 'Gingival Overgrowth', group: '7' },
    { name: 'Tooth Attrition', group: '7' },
    { name: 'Oral Ulcer', group: '7' },
    { name: 'Tooth, Nonvital', group: '7' },
    { name: 'Hemifacial Spasm', group: '7' },
    { name: 'Sleep Bruxism', group: '7' },
    { name: 'Facial Nerve Injuries', group: '7' },
    { name: 'Tooth Ankylosis', group: '7' },
    { name: 'Mobius Syndrome', group: '7' },
    { name: 'Trigeminal Nerve Diseases', group: '7' },
    { name: 'Salivary Calculi', group: '7' },
    { name: 'Open Bite', group: '7' },
    { name: 'Granulomatosis, Orofacial', group: '7' },
    { name: 'Periodontal Atrophy', group: '7' },
    { name: 'Chronic Periodontitis', group: '7' },
    { name: 'Tooth Wear', group: '7' },
    { name: 'Peri-Implantitis', group: '7' },
    { name: 'Overbite', group: '7' },
    { name: 'Trigeminal Nerve Injuries', group: '7' },
    { name: 'Lingual Nerve Injuries', group: '7' },
    { name: 'Glossoptosis', group: '7' },
    { name: 'Pneumonia, Necrotizing', group: '8' },
    { name: 'Acidosis, Respiratory', group: '8' },
    { name: 'Airway Obstruction', group: '8' },
    { name: 'Alkalosis, Respiratory', group: '8' },
    { name: 'Altitude Sickness', group: '8' },
    { name: 'Alveolitis, Extrinsic Allergic', group: '8' },
    { name: 'Anthracosilicosis', group: '8' },
    { name: 'Aphonia', group: '8' },
    { name: 'Apnea', group: '8' },
    { name: 'Asbestosis', group: '8' },
    { name: 'Asthma', group: '8' },
    { name: 'Asthma, Exercise-Induced', group: '8' },
    { name: 'Pulmonary Atelectasis', group: '8' },
    { name: 'Berylliosis', group: '8' },
    { name: 'Bird Fancier\'s Lung', group: '8' },
    { name: 'Bronchial Diseases', group: '8' },
    { name: 'Bronchial Fistula', group: '8' },
    { name: 'Bronchial Spasm', group: '8' },
    { name: 'Bronchiectasis', group: '8' },
    { name: 'Bronchiolitis', group: '8' },
    { name: 'Bronchiolitis Obliterans', group: '8' },
    { name: 'Bronchitis', group: '8' },
    { name: 'Bronchopneumonia', group: '8' },
    { name: 'Bronchopulmonary Dysplasia', group: '8' },
    { name: 'Bronchopulmonary Sequestration', group: '8' },
    { name: 'Byssinosis', group: '8' },
    { name: 'Cheyne-Stokes Respiration', group: '8' },
    { name: 'Choanal Atresia', group: '8' },
    { name: 'Chylothorax', group: '8' },
    { name: 'Ciliary Motility Disorders', group: '8' },
    { name: 'Solitary Pulmonary Nodule', group: '8' },
    { name: 'Cough', group: '8' },
    { name: 'Croup', group: '8' },
    { name: 'Dyspnea', group: '8' },
    { name: 'Dyspnea, Paroxysmal', group: '8' },
    { name: 'Epiglottitis', group: '8' },
    { name: 'Epistaxis', group: '8' },
    { name: 'Farmer\'s Lung', group: '8' },
    { name: 'Granuloma, Laryngeal', group: '8' },
    { name: 'Granuloma, Lethal Midline', group: '8' },
    { name: 'Rhinitis, Allergic, Seasonal', group: '8' },
    { name: 'Hemopneumothorax', group: '8' },
    { name: 'Hemoptysis', group: '8' },
    { name: 'Hemothorax', group: '8' },
    { name: 'Histiocytosis, Langerhans-Cell', group: '8' },
    { name: 'Hoarseness', group: '8' },
    { name: 'Hyaline Membrane Disease', group: '8' },
    { name: 'Hydropneumothorax', group: '8' },
    { name: 'Hydrothorax', group: '8' },
    { name: 'Hypertension, Pulmonary', group: '8' },
    { name: 'Hyperventilation', group: '8' },
    { name: 'Hypoventilation', group: '8' },
    { name: 'Kartagener Syndrome', group: '8' },
    { name: 'Laryngeal Diseases', group: '8' },
    { name: 'Laryngeal Edema', group: '8' },
    { name: 'Laryngismus', group: '8' },
    { name: 'Laryngitis', group: '8' },
    { name: 'Laryngostenosis', group: '8' },
    { name: 'Lipoid Proteinosis of Urbach and Wiethe', group: '8' },
    { name: 'Lung Diseases', group: '8' },
    { name: 'Lung Diseases, Obstructive', group: '8' },
    { name: 'Meconium Aspiration Syndrome', group: '8' },
    { name: 'Mediastinal Diseases', group: '8' },
    { name: 'Mediastinal Emphysema', group: '8' },
    { name: 'Mediastinitis', group: '8' },
    { name: 'Middle Lobe Syndrome', group: '8' },
    { name: 'Mouth Breathing', group: '8' },
    { name: 'Nasal Polyps', group: '8' },
    { name: 'Nose Deformities, Acquired', group: '8' },
    { name: 'Nose Diseases', group: '8' },
    { name: 'Paranasal Sinus Diseases', group: '8' },
    { name: 'Persistent Fetal Circulation Syndrome', group: '8' },
    { name: 'Obesity Hypoventilation Syndrome', group: '8' },
    { name: 'Pleural Diseases', group: '8' },
    { name: 'Pleural Effusion', group: '8' },
    { name: 'Pleurisy', group: '8' },
    { name: 'Pleuropneumonia', group: '8' },
    { name: 'Pneumoconiosis', group: '8' },
    { name: 'Pneumonia', group: '8' },
    { name: 'Pneumonia, Aspiration', group: '8' },
    { name: 'Pneumonia, Atypical Interstitial, of Cattle', group: '8' },
    { name: 'Pneumonia, Lipid', group: '8' },
    { name: 'Pneumothorax', group: '8' },
    { name: 'Pulmonary Alveolar Proteinosis', group: '8' },
    { name: 'Pulmonary Edema', group: '8' },
    { name: 'Pulmonary Embolism', group: '8' },
    { name: 'Pulmonary Emphysema', group: '8' },
    { name: 'Pulmonary Eosinophilia', group: '8' },
    { name: 'Pulmonary Fibrosis', group: '8' },
    { name: 'Pulmonary Veno-Occlusive Disease', group: '8' },
    { name: 'Respiration Disorders', group: '8' },
    { name: 'Respiratory Distress Syndrome, Newborn', group: '8' },
    { name: 'Respiratory Distress Syndrome, Adult', group: '8' },
    { name: 'Respiratory Hypersensitivity', group: '8' },
    { name: 'Respiratory Insufficiency', group: '8' },
    { name: 'Respiratory Paralysis', group: '8' },
    { name: 'Respiratory Tract Diseases', group: '8' },
    { name: 'Rhinitis', group: '8' },
    { name: 'Rhinitis, Allergic, Perennial', group: '8' },
    { name: 'Rhinitis, Atrophic', group: '8' },
    { name: 'Rhinitis, Vasomotor', group: '8' },
    { name: 'Scimitar Syndrome', group: '8' },
    { name: 'Siderosis', group: '8' },
    { name: 'Silicosis', group: '8' },
    { name: 'Silo Filler\'s Disease', group: '8' },
    { name: 'Sinusitis', group: '8' },
    { name: 'Sleep Apnea Syndromes', group: '8' },
    { name: 'Status Asthmaticus', group: '8' },
    { name: 'Thoracic Diseases', group: '8' },
    { name: 'Tracheal Diseases', group: '8' },
    { name: 'Tracheal Stenosis', group: '8' },
    { name: 'Tracheitis', group: '8' },
    { name: 'Tracheobronchomegaly', group: '8' },
    { name: 'Vocal Cord Paralysis', group: '8' },
    { name: 'Voice Disorders', group: '8' },
    { name: 'Granulomatosis with Polyangiitis', group: '8' },
    { name: 'Nasal Obstruction', group: '8' },
    { name: 'Ethmoid Sinusitis', group: '8' },
    { name: 'Frontal Sinusitis', group: '8' },
    { name: 'Maxillary Sinusitis', group: '8' },
    { name: 'Sphenoid Sinusitis', group: '8' },
    { name: 'Cystic Adenomatoid Malformation of Lung, Congenital', group: '8' },
    { name: 'Respiratory System Abnormalities', group: '8' },
    { name: 'Granuloma, Respiratory Tract', group: '8' },
    { name: 'Respiratory Tract Fistula', group: '8' },
    { name: 'Bronchial Hyperreactivity', group: '8' },
    { name: 'Plasma Cell Granuloma, Pulmonary', group: '8' },
    { name: 'Lung Diseases, Interstitial', group: '8' },
    { name: 'Radiation Pneumonitis', group: '8' },
    { name: 'Sarcoidosis, Pulmonary', group: '8' },
    { name: 'Positive-Pressure Respiration, Intrinsic', group: '8' },
    { name: 'Cryptogenic Organizing Pneumonia', group: '8' },
    { name: 'Lung, Hyperlucent', group: '8' },
    { name: 'Anti-Glomerular Basement Membrane Disease', group: '8' },
    { name: 'Sleep Apnea, Obstructive', group: '8' },
    { name: 'Sleep Apnea, Central', group: '8' },
    { name: 'Pulmonary Disease, Chronic Obstructive', group: '8' },
    { name: 'Bronchitis, Chronic', group: '8' },
    { name: 'Bovine Respiratory Disease Complex', group: '8' },
    { name: 'Respiratory Aspiration', group: '8' },
    { name: 'Pulmonary Infarction', group: '8' },
    { name: 'Idiopathic Interstitial Pneumonias', group: '8' },
    { name: 'Idiopathic Pulmonary Fibrosis', group: '8' },
    { name: 'Anthracosis', group: '8' },
    { name: 'Dysphonia', group: '8' },
    { name: 'Lung Injury', group: '8' },
    { name: 'Acute Lung Injury', group: '8' },
    { name: 'Ventilator-Induced Lung Injury', group: '8' },
    { name: 'Asthma, Aspirin-Induced', group: '8' },
    { name: 'Acute Chest Syndrome', group: '8' },
    { name: 'Infantile Apparent Life-Threatening Event', group: '8' },
    { name: 'Transient Tachypnea of the Newborn', group: '8' },
    { name: 'Tachypnea', group: '8' },
    { name: 'Asthma, Occupational', group: '8' },
    { name: 'Supraglottitis', group: '8' },
    { name: 'Laryngocele', group: '8' },
    { name: 'Laryngeal Nerve Injuries', group: '8' },
    { name: 'Recurrent Laryngeal Nerve Injuries', group: '8' },
    { name: 'Nasal Septal Perforation', group: '8' },
    { name: 'Vocal Cord Dysfunction', group: '8' },
    { name: 'Familial Primary Pulmonary Hypertension', group: '8' },
    { name: 'Rhinitis, Allergic', group: '8' },
    { name: 'Bilateral Vestibulopathy', group: '9' },
    { name: 'Vestibulocochlear Nerve Diseases', group: '9' },
    { name: 'Auditory Diseases, Central', group: '9' },
    { name: 'Auditory Perceptual Disorders', group: '9' },
    { name: 'Deafness', group: '9' },
    { name: 'Hearing Loss, Sudden', group: '9' },
    { name: 'Ear Deformities, Acquired', group: '9' },
    { name: 'Ear Diseases', group: '9' },
    { name: 'Earache', group: '9' },
    { name: 'Hearing Disorders', group: '9' },
    { name: 'Hearing Loss, Bilateral', group: '9' },
    { name: 'Hearing Loss, Central', group: '9' },
    { name: 'Hearing Loss, Conductive', group: '9' },
    { name: 'Hearing Loss, Functional', group: '9' },
    { name: 'Hearing Loss, High-Frequency', group: '9' },
    { name: 'Hearing Loss, Noise-Induced', group: '9' },
    { name: 'Hearing Loss, Sensorineural', group: '9' },
    { name: 'Labyrinth Diseases', group: '9' },
    { name: 'Labyrinthitis', group: '9' },
    { name: 'Meniere Disease', group: '9' },
    { name: 'Otitis', group: '9' },
    { name: 'Otitis Externa', group: '9' },
    { name: 'Otitis Media', group: '9' },
    { name: 'Otitis Media with Effusion', group: '9' },
    { name: 'Otorhinolaryngologic Diseases', group: '9' },
    { name: 'Otosclerosis', group: '9' },
    { name: 'Presbycusis', group: '9' },
    { name: 'Hyperacusis', group: '9' },
    { name: 'Retrocochlear Diseases', group: '9' },
    { name: 'Tinnitus', group: '9' },
    { name: 'Vertigo', group: '9' },
    { name: 'Wolfram Syndrome', group: '9' },
    { name: 'Cochlear Diseases', group: '9' },
    { name: 'Vestibular Diseases', group: '9' },
    { name: 'Tympanic Membrane Perforation', group: '9' },
    { name: 'Endolymphatic Hydrops', group: '9' },
    { name: 'Cholesteatoma, Middle Ear', group: '9' },
    { name: 'Vestibular Neuronitis', group: '9' },
    { name: 'Hearing Loss', group: '9' },
    { name: 'Hearing Loss, Unilateral', group: '9' },
    { name: 'Hearing Loss, Mixed Conductive-Sensorineural', group: '9' },
    { name: 'Usher Syndromes', group: '9' },
    { name: 'Deaf-Blind Disorders', group: '9' },
    { name: 'Susac Syndrome', group: '9' },
    { name: 'Vestibulocochlear Nerve Injuries', group: '9' },
    { name: 'Myringosclerosis', group: '9' },
    { name: 'Benign Paroxysmal Positional Vertigo', group: '9' },
    { name: 'Congenital Microtia', group: '9' },
    { name: 'Abdomen, Acute', group: '10' },
    { name: 'Drug Resistant Epilepsy', group: '10' },
    { name: 'Autoimmune Hypophysitis', group: '10' },
    { name: 'Infectious Encephalitis', group: '10' },
    { name: 'Brain Contusion', group: '10' },
    { name: 'Brain Injuries, Diffuse', group: '10' },
    { name: 'Chronic Traumatic Encephalopathy', group: '10' },
    { name: 'Brain Injuries, Traumatic', group: '10' },
    { name: 'Hyperekplexia', group: '10' },
    { name: 'Tardive Dyskinesia', group: '10' },
    { name: 'Acute Febrile Encephalopathy', group: '10' },
    { name: 'Small Fiber Neuropathy', group: '10' },
    { name: 'Emergence Delirium', group: '10' },
    { name: 'Hypophysitis', group: '10' },
    { name: 'Cancer Pain', group: '10' },
    { name: 'Acrodynia', group: '10' },
    { name: 'Adie Syndrome', group: '10' },
    { name: 'Adrenoleukodystrophy', group: '10' },
    { name: 'Ageusia', group: '10' },
    { name: 'Agnosia', group: '10' },
    { name: 'Agraphia', group: '10' },
    { name: 'Akinetic Mutism', group: '10' },
    { name: 'Alcohol Amnestic Disorder', group: '10' },
    { name: 'Alcohol Withdrawal Delirium', group: '10' },
    { name: 'Alzheimer Disease', group: '10' },
    { name: 'Amblyopia', group: '10' },
    { name: 'Amnesia', group: '10' },
    { name: 'Amnesia, Retrograde', group: '10' },
    { name: 'Amyotrophic Lateral Sclerosis', group: '10' },
    { name: 'Pain Insensitivity, Congenital', group: '10' },
    { name: 'Anencephaly', group: '10' },
    { name: 'Angina Pectoris', group: '10' },
    { name: 'Angina Pectoris, Variant', group: '10' },
    { name: 'Angina, Unstable', group: '10' },
    { name: 'Fabry Disease', group: '10' },
    { name: 'Anomia', group: '10' },
    { name: 'Olfaction Disorders', group: '10' },
    { name: 'Aphasia', group: '10' },
    { name: 'Aphasia, Broca', group: '10' },
    { name: 'Aphasia, Wernicke', group: '10' },
    { name: 'Apraxias', group: '10' },
    { name: 'Arachnoiditis', group: '10' },
    { name: 'Arnold-Chiari Malformation', group: '10' },
    { name: 'Articulation Disorders', group: '10' },
    { name: 'Ataxia', group: '10' },
    { name: 'Ataxia Telangiectasia', group: '10' },
    { name: 'Athetosis', group: '10' },
    { name: 'Autonomic Nervous System Diseases', group: '10' },
    { name: 'Back Pain', group: '10' },
    { name: 'Basal Ganglia Diseases', group: '10' },
    { name: 'Urinary Bladder, Neurogenic', group: '10' },
    { name: 'Blindness', group: '10' },
    { name: 'Brain Concussion', group: '10' },
    { name: 'Brain Damage, Chronic', group: '10' },
    { name: 'Brain Death', group: '10' },
    { name: 'Brain Diseases', group: '10' },
    { name: 'Brain Diseases, Metabolic', group: '10' },
    { name: 'Brain Edema', group: '10' },
    { name: 'Brain Injuries', group: '10' },
    { name: 'Carotid Artery Diseases', group: '10' },
    { name: 'Carotid Artery Thrombosis', group: '10' },
    { name: 'Carpal Tunnel Syndrome', group: '10' },
    { name: 'Catalepsy', group: '10' },
    { name: 'Cataplexy', group: '10' },
    { name: 'Catatonia', group: '10' },
    { name: 'Causalgia', group: '10' },
    { name: 'Central Nervous System Diseases', group: '10' },
    { name: 'Cerebellar Ataxia', group: '10' },
    { name: 'Cerebellar Diseases', group: '10' },
    { name: 'Myoclonic Cerebellar Dyssynergia', group: '10' },
    { name: 'Intracranial Aneurysm', group: '10' },
    { name: 'Hypoxia, Brain', group: '10' },
    { name: 'Intracranial Arteriosclerosis', group: '10' },
    { name: 'Intracranial Arteriovenous Malformations', group: '10' },
    { name: 'Cerebral Arterial Diseases', group: '10' },
    { name: 'Intracranial Embolism and Thrombosis', group: '10' },
    { name: 'Cerebral Hemorrhage', group: '10' },
    { name: 'Cerebral Infarction', group: '10' },
    { name: 'Brain Ischemia', group: '10' },
    { name: 'Ischemic Attack, Transient', group: '10' },
    { name: 'Cerebral Palsy', group: '10' },
    { name: 'Diffuse Cerebral Sclerosis of Schilder', group: '10' },
    { name: 'Cerebrospinal Fluid Otorrhea', group: '10' },
    { name: 'Cerebrospinal Fluid Rhinorrhea', group: '10' },
    { name: 'Cerebrovascular Disorders', group: '10' },
    { name: 'Cervical Rib Syndrome', group: '10' },
    { name: 'Charcot-Marie-Tooth Disease', group: '10' },
    { name: 'Chest Pain', group: '10' },
    { name: 'Chorea', group: '10' },
    { name: 'Cluster Headache', group: '10' },
    { name: 'Color Vision Defects', group: '10' },
    { name: 'Coma', group: '10' },
    { name: 'Communication Disorders', group: '10' },
    { name: 'Confusion', group: '10' },
    { name: 'Consciousness Disorders', group: '10' },
    { name: 'Seizures, Febrile', group: '10' },
    { name: 'Cranial Nerve Diseases', group: '10' },
    { name: 'Cri-du-Chat Syndrome', group: '10' },
    { name: 'Dandy-Walker Syndrome', group: '10' },
    { name: 'De Lange Syndrome', group: '10' },
    { name: 'Decerebrate State', group: '10' },
    { name: 'Delirium', group: '10' },
    { name: 'Dementia', group: '10' },
    { name: 'Demyelinating Diseases', group: '10' },
    { name: 'Diabetic Neuropathies', group: '10' },
    { name: 'Diplopia', group: '10' },
    { name: 'Dizziness', group: '10' },
    { name: 'Down Syndrome', group: '10' },
    { name: 'Duane Retraction Syndrome', group: '10' },
    { name: 'Dysarthria', group: '10' },
    { name: 'Dysautonomia, Familial', group: '10' },
    { name: 'Dysgeusia', group: '10' },
    { name: 'Dyskinesia, Drug-Induced', group: '10' },
    { name: 'Dyslexia', group: '10' },
    { name: 'Dyslexia, Acquired', group: '10' },
    { name: 'Dystonia', group: '10' },
    { name: 'Dystonia Musculorum Deformans', group: '10' },
    { name: 'Echolalia', group: '10' },
    { name: 'Empty Sella Syndrome', group: '10' },
    { name: 'Encephalitis', group: '10' },
    { name: 'Encephalomyelitis, Acute Disseminated', group: '10' },
    { name: 'Encephalocele', group: '10' },
    { name: 'Encephalomalacia', group: '10' },
    { name: 'Encephalomyelitis', group: '10' },
    { name: 'Encephalomyelitis, Autoimmune, Experimental', group: '10' },
    { name: 'Leukoencephalitis, Acute Hemorrhagic', group: '10' },
    { name: 'Epilepsy', group: '10' },
    { name: 'Epilepsies, Partial', group: '10' },
    { name: 'Epilepsy, Generalized', group: '10' },
    { name: 'Epilepsy, Tonic-Clonic', group: '10' },
    { name: 'Epilepsies, Myoclonic', group: '10' },
    { name: 'Epilepsy, Absence', group: '10' },
    { name: 'Epilepsy, Temporal Lobe', group: '10' },
    { name: 'Epilepsy, Post-Traumatic', group: '10' },
    { name: 'Esotropia', group: '10' },
    { name: 'Exotropia', group: '10' },
    { name: 'Eye Burns', group: '10' },
    { name: 'Eye Foreign Bodies', group: '10' },
    { name: 'Eye Injuries', group: '10' },
    { name: 'Facial Injuries', group: '10' },
    { name: 'Facial Pain', group: '10' },
    { name: 'Fasciculation', group: '10' },
    { name: 'Fragile X Syndrome', group: '10' },
    { name: 'Friedreich Ataxia', group: '10' },
    { name: 'Fucosidosis', group: '10' },
    { name: 'Galactosemias', group: '10' },
    { name: 'Gangliosidoses', group: '10' },
    { name: 'Gaucher Disease', group: '10' },
    { name: 'Gerstmann Syndrome', group: '10' },
    { name: 'Tourette Syndrome', group: '10' },
    { name: 'Glycogen Storage Disease Type II', group: '10' },
    { name: 'Pantothenate Kinase-Associated Neurodegeneration', group: '10' },
    { name: 'Hallucinations', group: '10' },
    { name: 'Hartnup Disease', group: '10' },
    { name: 'Craniocerebral Trauma', group: '10' },
    { name: 'Headache', group: '10' },
    { name: 'Hematoma, Epidural, Cranial', group: '10' },
    { name: 'Hematoma, Subdural', group: '10' },
    { name: 'Hemianopsia', group: '10' },
    { name: 'Hemiplegia', group: '10' },
    { name: 'High Pressure Neurological Syndrome', group: '10' },
    { name: 'von Hippel-Lindau Disease', group: '10' },
    { name: 'Homocystinuria', group: '10' },
    { name: 'Horner Syndrome', group: '10' },
    { name: 'Huntington Disease', group: '10' },
    { name: 'Hydranencephaly', group: '10' },
    { name: 'Hydrocephalus', group: '10' },
    { name: 'Hydrocephalus, Normal Pressure', group: '10' },
    { name: 'Hyperalgesia', group: '10' },
    { name: 'Hyperesthesia', group: '10' },
    { name: 'Hyperkinesis', group: '10' },
    { name: 'Hyperpituitarism', group: '10' },
    { name: 'Hyperprolactinemia', group: '10' },
    { name: 'Disorders of Excessive Somnolence', group: '10' },
    { name: 'Hypesthesia', group: '10' },
    { name: 'Hypopituitarism', group: '10' },
    { name: 'Hypotension, Orthostatic', group: '10' },
    { name: 'Hypothalamic Diseases', group: '10' },
    { name: 'Illusions', group: '10' },
    { name: 'Inappropriate ADH Syndrome', group: '10' },
    { name: 'Sleep Initiation and Maintenance Disorders', group: '10' },
    { name: 'Insulin Coma', group: '10' },
    { name: 'Creutzfeldt-Jakob Syndrome', group: '10' },
    { name: 'Jaw Fractures', group: '10' },
    { name: 'Kernicterus', group: '10' },
    { name: 'Menkes Kinky Hair Syndrome', group: '10' },
    { name: 'Kuru', group: '10' },
    { name: 'Language Development Disorders', group: '10' },
    { name: 'Language Disorders', group: '10' },
    { name: 'Laurence-Moon Syndrome', group: '10' },
    { name: 'Learning Disorders', group: '10' },
    { name: 'Leigh Disease', group: '10' },
    { name: 'Lesch-Nyhan Syndrome', group: '10' },
    { name: 'Leukodystrophy, Globoid Cell', group: '10' },
    { name: 'Leukodystrophy, Metachromatic', group: '10' },
    { name: 'Leukomalacia, Periventricular', group: '10' },
    { name: 'Mandibular Fractures', group: '10' },
    { name: 'Mandibular Injuries', group: '10' },
    { name: 'Maple Syrup Urine Disease', group: '10' },
    { name: 'Maxillary Fractures', group: '10' },
    { name: 'Maxillofacial Injuries', group: '10' },
    { name: 'Meige Syndrome', group: '10' },
    { name: 'Memory Disorders', group: '10' },
    { name: 'Meningism', group: '10' },
    { name: 'Meningitis', group: '10' },
    { name: 'Meningitis, Aseptic', group: '10' },
    { name: 'Meningocele', group: '10' },
    { name: 'Meningoencephalitis', group: '10' },
    { name: 'Meningomyelocele', group: '10' },
    { name: 'Intellectual Disability', group: '10' },
    { name: 'Migraine Disorders', group: '10' },
    { name: 'Movement Disorders', group: '10' },
    { name: 'Moyamoya Disease', group: '10' },
    { name: 'Multiple Sclerosis', group: '10' },
    { name: 'Muscle Hypertonia', group: '10' },
    { name: 'Muscle Hypotonia', group: '10' },
    { name: 'Muscular Atrophy', group: '10' },
    { name: 'Muscular Atrophy, Spinal', group: '10' },
    { name: 'Mutism', group: '10' },
    { name: 'Myasthenia Gravis', group: '10' },
    { name: 'Myelitis', group: '10' },
    { name: 'Myoclonus', group: '10' },
    { name: 'Myotonia', group: '10' },
    { name: 'Narcolepsy', group: '10' },
    { name: 'Nerve Compression Syndromes', group: '10' },
    { name: 'Nervous System Malformations', group: '10' },
    { name: 'Nervous System Diseases', group: '10' },
    { name: 'Neural Tube Defects', group: '10' },
    { name: 'Neuralgia', group: '10' },
    { name: 'Neuritis', group: '10' },
    { name: 'Neuritis, Autoimmune, Experimental', group: '10' },
    { name: 'Neuroleptic Malignant Syndrome', group: '10' },
    { name: 'Neurologic Manifestations', group: '10' },
    { name: 'Neuromuscular Diseases', group: '10' },
    { name: 'Neuromyelitis Optica', group: '10' },
    { name: 'Neuronal Ceroid-Lipofuscinoses', group: '10' },
    { name: 'Hereditary Sensory and Autonomic Neuropathies', group: '10' },
    { name: 'Niemann-Pick Diseases', group: '10' },
    { name: 'Nystagmus, Pathologic', group: '10' },
    { name: 'Oculocerebrorenal Syndrome', group: '10' },
    { name: 'Olivopontocerebellar Atrophies', group: '10' },
    { name: 'Ophthalmoplegia', group: '10' },
    { name: 'Optic Atrophy', group: '10' },
    { name: 'Optic Nerve Diseases', group: '10' },
    { name: 'Optic Neuritis', group: '10' },
    { name: 'Orbital Fractures', group: '10' },
    { name: 'Pain', group: '10' },
    { name: 'Pain, Intractable', group: '10' },
    { name: 'Pain, Postoperative', group: '10' },
    { name: 'Papilledema', group: '10' },
    { name: 'Paralysis', group: '10' },
    { name: 'Bulbar Palsy, Progressive', group: '10' },
    { name: 'Paraplegia', group: '10' },
    { name: 'Paresis', group: '10' },
    { name: 'Paresthesia', group: '10' },
    { name: 'Parkinson Disease', group: '10' },
    { name: 'Parkinson Disease, Postencephalitic', group: '10' },
    { name: 'Parkinson Disease, Secondary', group: '10' },
    { name: 'Perceptual Disorders', group: '10' },
    { name: 'Peripheral Nervous System Diseases', group: '10' },
    { name: 'Phantom Limb', group: '10' },
    { name: 'Phenylketonurias', group: '10' },
    { name: 'Pituitary Apoplexy', group: '10' },
    { name: 'Pituitary Diseases', group: '10' },
    { name: 'Pneumocephalus', group: '10' },
    { name: 'Polyneuropathies', group: '10' },
    { name: 'Polyradiculopathy', group: '10' },
    { name: 'Polyradiculoneuropathy', group: '10' },
    { name: 'Prader-Willi Syndrome', group: '10' },
    { name: 'Pseudotumor Cerebri', group: '10' },
    { name: 'Psychomotor Agitation', group: '10' },
    { name: 'Psychomotor Disorders', group: '10' },
    { name: 'Pupil Disorders', group: '10' },
    { name: 'Quadriplegia', group: '10' },
    { name: 'Radiculopathy', group: '10' },
    { name: 'Reflex Sympathetic Dystrophy', group: '10' },
    { name: 'Reflex, Abnormal', group: '10' },
    { name: 'Refsum Disease', group: '10' },
    { name: 'Restless Legs Syndrome', group: '10' },
    { name: 'Sandhoff Disease', group: '10' },
    { name: 'Sciatica', group: '10' },
    { name: 'Scotoma', group: '10' },
    { name: 'Scrapie', group: '10' },
    { name: 'Sea-Blue Histiocyte Syndrome', group: '10' },
    { name: 'Seizures', group: '10' },
    { name: 'Sensation Disorders', group: '10' },
    { name: 'Shy-Drager Syndrome', group: '10' },
    { name: 'Sinus Thrombosis, Intracranial', group: '10' },
    { name: 'Skull Fractures', group: '10' },
    { name: 'Sleep Deprivation', group: '10' },
    { name: 'Sleep Wake Disorders', group: '10' },
    { name: 'Somnambulism', group: '10' },
    { name: 'Spasm', group: '10' },
    { name: 'Spasms, Infantile', group: '10' },
    { name: 'Speech Disorders', group: '10' },
    { name: 'Sphingolipidoses', group: '10' },
    { name: 'Spinal Cord Compression', group: '10' },
    { name: 'Spinal Cord Diseases', group: '10' },
    { name: 'Spinal Cord Injuries', group: '10' },
    { name: 'Spinocerebellar Degenerations', group: '10' },
    { name: 'Status Epilepticus', group: '10' },
    { name: 'Strabismus', group: '10' },
    { name: 'Stuttering', group: '10' },
    { name: 'Subarachnoid Hemorrhage', group: '10' },
    { name: 'Subclavian Steal Syndrome', group: '10' },
    { name: 'Subdural Effusion', group: '10' },
    { name: 'Supranuclear Palsy, Progressive', group: '10' },
    { name: 'Sweating, Gustatory', group: '10' },
    { name: 'Syncope', group: '10' },
    { name: 'Syringomyelia', group: '10' },
    { name: 'Tangier Disease', group: '10' },
    { name: 'Tarsal Tunnel Syndrome', group: '10' },
    { name: 'Taste Disorders', group: '10' },
    { name: 'Tay-Sachs Disease', group: '10' },
    { name: 'Giant Cell Arteritis', group: '10' },
    { name: 'Tetany', group: '10' },
    { name: 'Thalamic Diseases', group: '10' },
    { name: 'Thoracic Outlet Syndrome', group: '10' },
    { name: 'Tic Disorders', group: '10' },
    { name: 'Torticollis', group: '10' },
    { name: 'Tremor', group: '10' },
    { name: 'Trismus', group: '10' },
    { name: 'Unconsciousness', group: '10' },
    { name: 'Uveomeningoencephalitic Syndrome', group: '10' },
    { name: 'Vascular Headaches', group: '10' },
    { name: 'Vertebrobasilar Insufficiency', group: '10' },
    { name: 'Vision Disorders', group: '10' },
    { name: 'Lateral Medullary Syndrome', group: '10' },
    { name: 'Spinal Muscular Atrophies of Childhood', group: '10' },
    { name: 'Wernicke Encephalopathy', group: '10' },
    { name: 'Zygomatic Fractures', group: '10' },
    { name: 'Dementia, Vascular', group: '10' },
    { name: 'Dementia, Multi-Infarct', group: '10' },
    { name: 'Pyruvate Carboxylase Deficiency Disease', group: '10' },
    { name: 'Pyruvate Dehydrogenase Complex Deficiency Disease', group: '10' },
    { name: 'Vision, Low', group: '10' },
    { name: 'Hereditary Sensory and Motor Neuropathy', group: '10' },
    { name: 'Optic Atrophies, Hereditary', group: '10' },
    { name: 'Spastic Paraplegia, Hereditary', group: '10' },
    { name: 'Rett Syndrome', group: '10' },
    { name: 'Optic Disk Drusen', group: '10' },
    { name: 'Abdominal Pain', group: '10' },
    { name: 'Eye Injuries, Penetrating', group: '10' },
    { name: 'Ocular Motility Disorders', group: '10' },
    { name: 'Oculomotor Nerve Diseases', group: '10' },
    { name: 'Tonic Pupil', group: '10' },
    { name: 'Anisocoria', group: '10' },
    { name: 'Miosis', group: '10' },
    { name: 'Gerstmann-Straussler-Scheinker Disease', group: '10' },
    { name: 'Spinal Dysraphism', group: '10' },
    { name: 'Spina Bifida Occulta', group: '10' },
    { name: 'Spina Bifida Cystica', group: '10' },
    { name: 'Motor Neuron Disease', group: '10' },
    { name: 'Head Injuries, Closed', group: '10' },
    { name: 'Mucopolysaccharidosis II', group: '10' },
    { name: 'Gangliosidosis, GM1', group: '10' },
    { name: 'Encephalopathy, Bovine Spongiform', group: '10' },
    { name: 'Cerebral Amyloid Angiopathy', group: '10' },
    { name: 'Stiff-Person Syndrome', group: '10' },
    { name: 'POEMS Syndrome', group: '10' },
    { name: 'Carotid Stenosis', group: '10' },
    { name: 'Epilepsy, Complex Partial', group: '10' },
    { name: 'Epilepsy, Frontal Lobe', group: '10' },
    { name: 'Epilepsia Partialis Continua', group: '10' },
    { name: 'Phenylketonuria, Maternal', group: '10' },
    { name: 'Prion Diseases', group: '10' },
    { name: 'Akathisia, Drug-Induced', group: '10' },
    { name: 'Low Back Pain', group: '10' },
    { name: 'Angelman Syndrome', group: '10' },
    { name: 'Myelinolysis, Central Pontine', group: '10' },
    { name: 'Kleine-Levin Syndrome', group: '10' },
    { name: 'Ulnar Nerve Compression Syndromes', group: '10' },
    { name: 'Amyloid Neuropathies', group: '10' },
    { name: 'Canavan Disease', group: '10' },
    { name: 'Machado-Joseph Disease', group: '10' },
    { name: 'Brown-Sequard Syndrome', group: '10' },
    { name: 'Persistent Vegetative State', group: '10' },
    { name: 'Hypokinesia', group: '10' },
    { name: 'Tension-Type Headache', group: '10' },
    { name: 'Sneddon Syndrome', group: '10' },
    { name: 'Aphasia, Conduction', group: '10' },
    { name: 'Landau-Kleffner Syndrome', group: '10' },
    { name: 'Aphasia, Primary Progressive', group: '10' },
    { name: 'Optic Neuropathy, Ischemic', group: '10' },
    { name: 'Williams Syndrome', group: '10' },
    { name: 'Neuroaxonal Dystrophies', group: '10' },
    { name: 'Epilepsy, Rolandic', group: '10' },
    { name: 'Syncope, Vasovagal', group: '10' },
    { name: 'Neck Pain', group: '10' },
    { name: 'Blindness, Cortical', group: '10' },
    { name: 'Multiple System Atrophy', group: '10' },
    { name: 'Intracranial Hypotension', group: '10' },
    { name: 'Intracranial Hypertension', group: '10' },
    { name: 'Neurodegenerative Diseases', group: '10' },
    { name: 'Miller Fisher Syndrome', group: '10' },
    { name: 'Neurobehavioral Manifestations', group: '10' },
    { name: 'Neurogenic Inflammation', group: '10' },
    { name: 'Lysosomal Storage Diseases, Nervous System', group: '10' },
    { name: 'Gangliosidoses, GM2', group: '10' },
    { name: 'Basal Ganglia Cerebrovascular Disease', group: '10' },
    { name: 'Basal Ganglia Hemorrhage', group: '10' },
    { name: 'Putaminal Hemorrhage', group: '10' },
    { name: 'Manganese Poisoning', group: '10' },
    { name: 'Chorea Gravidarum', group: '10' },
    { name: 'Hyperglycinemia, Nonketotic', group: '10' },
    { name: 'Citrullinemia', group: '10' },
    { name: 'Hyperargininemia', group: '10' },
    { name: 'Ornithine Carbamoyltransferase Deficiency Disease', group: '10' },
    { name: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', group: '10' },
    { name: 'Hyperlysinemias', group: '10' },
    { name: 'Tyrosinemias', group: '10' },
    { name: 'Hypersomnolence, Idiopathic', group: '10' },
    { name: 'Sleep Disorders, Circadian Rhythm', group: '10' },
    { name: 'Jet Lag Syndrome', group: '10' },
    { name: 'Nocturnal Paroxysmal Dystonia', group: '10' },
    { name: 'Night Terrors', group: '10' },
    { name: 'REM Sleep Behavior Disorder', group: '10' },
    { name: 'Sleep Paralysis', group: '10' },
    { name: 'Nocturnal Myoclonus Syndrome', group: '10' },
    { name: 'Myoclonic Epilepsy, Juvenile', group: '10' },
    { name: 'Myoclonic Epilepsies, Progressive', group: '10' },
    { name: 'Lafora Disease', group: '10' },
    { name: 'Unverricht-Lundborg Syndrome', group: '10' },
    { name: 'Epilepsy, Reflex', group: '10' },
    { name: 'Trauma, Nervous System', group: '10' },
    { name: 'Head Injuries, Penetrating', group: '10' },
    { name: 'Intracranial Hemorrhage, Traumatic', group: '10' },
    { name: 'Hematoma, Subdural, Acute', group: '10' },
    { name: 'Hematoma, Subdural, Chronic', group: '10' },
    { name: 'Brain Hemorrhage, Traumatic', group: '10' },
    { name: 'Cerebral Hemorrhage, Traumatic', group: '10' },
    { name: 'Brain Stem Hemorrhage, Traumatic', group: '10' },
    { name: 'Skull Fracture, Depressed', group: '10' },
    { name: 'Skull Fracture, Basilar', group: '10' },
    { name: 'Subarachnoid Hemorrhage, Traumatic', group: '10' },
    { name: 'Coma, Post-Head Injury', group: '10' },
    { name: 'Brain Injury, Chronic', group: '10' },
    { name: 'Cranial Nerve Injuries', group: '10' },
    { name: 'Central Cord Syndrome', group: '10' },
    { name: 'Autonomic Dysreflexia', group: '10' },
    { name: 'Carotid Artery Injuries', group: '10' },
    { name: 'Cerebrovascular Trauma', group: '10' },
    { name: 'Carotid Artery, Internal, Dissection', group: '10' },
    { name: 'Carotid-Cavernous Sinus Fistula', group: '10' },
    { name: 'Vertebral Artery Dissection', group: '10' },
    { name: 'Optic Nerve Injuries', group: '10' },
    { name: 'Abducens Nerve Injury', group: '10' },
    { name: 'Sagittal Sinus Thrombosis', group: '10' },
    { name: 'Cavernous Sinus Thrombosis', group: '10' },
    { name: 'Lateral Sinus Thrombosis', group: '10' },
    { name: 'Kluver-Bucy Syndrome', group: '10' },
    { name: 'Gait Disorders, Neurologic', group: '10' },
    { name: 'Gait Ataxia', group: '10' },
    { name: 'Gait Apraxia', group: '10' },
    { name: 'Amnesia, Transient Global', group: '10' },
    { name: 'Alexia, Pure', group: '10' },
    { name: 'Prosopagnosia', group: '10' },
    { name: 'Apraxia, Ideomotor', group: '10' },
    { name: 'Infarction, Anterior Cerebral Artery', group: '10' },
    { name: 'Infarction, Middle Cerebral Artery', group: '10' },
    { name: 'Neurotoxicity Syndromes', group: '10' },
    { name: 'Heavy Metal Poisoning, Nervous System', group: '10' },
    { name: 'Arsenic Poisoning', group: '10' },
    { name: 'Mercury Poisoning, Nervous System', group: '10' },
    { name: 'Lead Poisoning, Nervous System', group: '10' },
    { name: 'Lead Poisoning, Nervous System, Childhood', group: '10' },
    { name: 'Lead Poisoning, Nervous System, Adult', group: '10' },
    { name: 'MPTP Poisoning', group: '10' },
    { name: 'Alcohol-Induced Disorders, Nervous System', group: '10' },
    { name: 'Alcoholic Neuropathy', group: '10' },
    { name: 'Alcohol Withdrawal Seizures', group: '10' },
    { name: 'Heredodegenerative Disorders, Nervous System', group: '10' },
    { name: 'Autoimmune Diseases of the Nervous System', group: '10' },
    { name: 'Guillain-Barre Syndrome', group: '10' },
    { name: 'Polyradiculoneuropathy, Chronic Inflammatory Demyelinating', group: '10' },
    { name: 'Demyelinating Autoimmune Diseases, CNS', group: '10' },
    { name: 'Hereditary Central Nervous System Demyelinating Diseases', group: '10' },
    { name: 'Vasculitis, Central Nervous System', group: '10' },
    { name: 'Myasthenic Syndromes, Congenital', group: '10' },
    { name: 'Intracranial Hemorrhage, Hypertensive', group: '10' },
    { name: 'Intracranial Hemorrhages', group: '10' },
    { name: 'Vasospasm, Intracranial', group: '10' },
    { name: 'Tics', group: '10' },
    { name: 'Amnesia, Anterograde', group: '10' },
    { name: 'Migraine with Aura', group: '10' },
    { name: 'Migraine without Aura', group: '10' },
    { name: 'Essential Tremor', group: '10' },
    { name: 'Tolosa-Hunt Syndrome', group: '10' },
    { name: 'Paraparesis', group: '10' },
    { name: 'Paraparesis, Spastic', group: '10' },
    { name: 'Hypertensive Encephalopathy', group: '10' },
    { name: 'Pelizaeus-Merzbacher Disease', group: '10' },
    { name: 'Myokymia', group: '10' },
    { name: 'Nystagmus, Congenital', group: '10' },
    { name: 'Vagus Nerve Diseases', group: '10' },
    { name: 'Mononeuropathies', group: '10' },
    { name: 'Median Neuropathy', group: '10' },
    { name: 'Ulnar Neuropathies', group: '10' },
    { name: 'Radial Neuropathy', group: '10' },
    { name: 'Sciatic Neuropathy', group: '10' },
    { name: 'Peroneal Neuropathies', group: '10' },
    { name: 'Femoral Neuropathy', group: '10' },
    { name: 'Tibial Neuropathy', group: '10' },
    { name: 'Cubital Tunnel Syndrome', group: '10' },
    { name: 'Olfactory Nerve Diseases', group: '10' },
    { name: 'Trochlear Nerve Diseases', group: '10' },
    { name: 'Abducens Nerve Diseases', group: '10' },
    { name: 'Glossopharyngeal Nerve Diseases', group: '10' },
    { name: 'Accessory Nerve Diseases', group: '10' },
    { name: 'Hypoglossal Nerve Diseases', group: '10' },
    { name: 'Parasomnias', group: '10' },
    { name: 'Neuromuscular Junction Diseases', group: '10' },
    { name: 'Brachial Plexus Neuropathies', group: '10' },
    { name: 'Brain Infarction', group: '10' },
    { name: 'Stroke', group: '10' },
    { name: 'Brain Stem Infarctions', group: '10' },
    { name: 'Multiple Sclerosis, Chronic Progressive', group: '10' },
    { name: 'Multiple Sclerosis, Relapsing-Remitting', group: '10' },
    { name: 'Myasthenia Gravis, Autoimmune, Experimental', group: '10' },
    { name: 'Nervous System Autoimmune Disease, Experimental', group: '10' },
    { name: 'Parkinsonian Disorders', group: '10' },
    { name: 'Brain Diseases, Metabolic, Inborn', group: '10' },
    { name: 'Neurocutaneous Syndromes', group: '10' },
    { name: 'Spinocerebellar Ataxias', group: '10' },
    { name: 'Amaurosis Fugax', group: '10' },
    { name: 'Spinal Cord Vascular Diseases', group: '10' },
    { name: 'Anterior Spinal Artery Syndrome', group: '10' },
    { name: 'Spinal Cord Ischemia', group: '10' },
    { name: 'Infarction, Posterior Cerebral Artery', group: '10' },
    { name: 'Intracranial Arterial Diseases', group: '10' },
    { name: 'Intracranial Embolism', group: '10' },
    { name: 'Intracranial Thrombosis', group: '10' },
    { name: 'Headache Disorders', group: '10' },
    { name: 'Pick Disease of the Brain', group: '10' },
    { name: 'Sinus Pericranii', group: '10' },
    { name: 'Central Nervous System Vascular Malformations', group: '10' },
    { name: 'Bardet-Biedl Syndrome', group: '10' },
    { name: 'Photophobia', group: '10' },
    { name: 'Perimeningeal Infections', group: '10' },
    { name: 'Dyskinesias', group: '10' },
    { name: 'Dystonic Disorders', group: '10' },
    { name: 'Pseudobulbar Palsy', group: '10' },
    { name: 'Diffuse Axonal Injury', group: '10' },
    { name: 'Neuromuscular Manifestations', group: '10' },
    { name: 'Somatosensory Disorders', group: '10' },
    { name: 'Korsakoff Syndrome', group: '10' },
    { name: 'Complex Regional Pain Syndromes', group: '10' },
    { name: 'Sleep Disorders, Intrinsic', group: '10' },
    { name: 'Dyssomnias', group: '10' },
    { name: 'Sleep Arousal Disorders', group: '10' },
    { name: 'Sleep-Wake Transition Disorders', group: '10' },
    { name: 'REM Sleep Parasomnias', group: '10' },
    { name: 'Hypoxia-Ischemia, Brain', group: '10' },
    { name: 'Epilepsy, Benign Neonatal', group: '10' },
    { name: 'Epilepsy, Partial, Sensory', group: '10' },
    { name: 'Epilepsy, Partial, Motor', group: '10' },
    { name: 'Myasthenia Gravis, Neonatal', group: '10' },
    { name: 'Lupus Vasculitis, Central Nervous System', group: '10' },
    { name: 'Striatonigral Degeneration', group: '10' },
    { name: 'Lewy Body Disease', group: '10' },
    { name: 'Brachial Plexus Neuritis', group: '10' },
    { name: 'Chronobiology Disorders', group: '10' },
    { name: 'Flank Pain', group: '10' },
    { name: 'Tauopathies', group: '10' },
    { name: 'Septo-Optic Dysplasia', group: '10' },
    { name: 'Amyloid Neuropathies, Familial', group: '10' },
    { name: 'Cerebral Amyloid Angiopathy, Familial', group: '10' },
    { name: 'Optic Atrophy, Autosomal Dominant', group: '10' },
    { name: 'Optic Atrophy, Hereditary, Leber', group: '10' },
    { name: 'Sialic Acid Storage Disease', group: '10' },
    { name: 'Insomnia, Fatal Familial', group: '10' },
    { name: 'Wasting Disease, Chronic', group: '10' },
    { name: 'Post-Concussion Syndrome', group: '10' },
    { name: 'Alexander Disease', group: '10' },
    { name: 'Shaken Baby Syndrome', group: '10' },
    { name: 'Mental Retardation, X-Linked', group: '10' },
    { name: 'Coffin-Lowry Syndrome', group: '10' },
    { name: 'CADASIL', group: '10' },
    { name: 'Synkinesis', group: '10' },
    { name: 'Hematoma, Subdural, Intracranial', group: '10' },
    { name: 'Pituitary ACTH Hypersecretion', group: '10' },
    { name: 'Labor Pain', group: '10' },
    { name: 'Tay-Sachs Disease, AB Variant', group: '10' },
    { name: 'SUNCT Syndrome', group: '10' },
    { name: 'Headache Disorders, Primary', group: '10' },
    { name: 'Headache Disorders, Secondary', group: '10' },
    { name: 'Post-Traumatic Headache', group: '10' },
    { name: 'Post-Dural Puncture Headache', group: '10' },
    { name: 'Paroxysmal Hemicrania', group: '10' },
    { name: 'Trigeminal Autonomic Cephalalgias', group: '10' },
    { name: 'Neuralgia, Postherpetic', group: '10' },
    { name: 'Glycogen Storage Disease Type IIb', group: '10' },
    { name: 'Sulfatidosis', group: '10' },
    { name: 'Multiple Sulfatase Deficiency Disease', group: '10' },
    { name: 'Niemann-Pick Disease, Type A', group: '10' },
    { name: 'Niemann-Pick Disease, Type B', group: '10' },
    { name: 'Niemann-Pick Disease, Type C', group: '10' },
    { name: 'Subacute Combined Degeneration', group: '10' },
    { name: 'Refsum Disease, Infantile', group: '10' },
    { name: 'Hand-Arm Vibration Syndrome', group: '10' },
    { name: 'Pain, Referred', group: '10' },
    { name: 'Stupor', group: '10' },
    { name: 'Lethargy', group: '10' },
    { name: 'Posterior Leukoencephalopathy Syndrome', group: '10' },
    { name: 'Mevalonate Kinase Deficiency', group: '10' },
    { name: 'Vein of Galen Malformations', group: '10' },
    { name: 'Malformations of Cortical Development, Group II', group: '10' },
    { name: 'Lissencephaly', group: '10' },
    { name: 'Periventricular Nodular Heterotopia', group: '10' },
    { name: 'Malformations of Cortical Development', group: '10' },
    { name: 'Classical Lissencephalies and Subcortical Band Heterotopias', group: '10' },
    { name: 'Cobblestone Lissencephaly', group: '10' },
    { name: 'Marchiafava-Bignami Disease', group: '10' },
    { name: 'Neuroacanthocytosis', group: '10' },
    { name: 'Primary Dysautonomias', group: '10' },
    { name: 'Pure Autonomic Failure', group: '10' },
    { name: 'Orthostatic Intolerance', group: '10' },
    { name: 'Postural Orthostatic Tachycardia Syndrome', group: '10' },
    { name: 'Failed Back Surgery Syndrome', group: '10' },
    { name: 'Bulbo-Spinal Atrophy, X-Linked', group: '10' },
    { name: 'Farber Lipogranulomatosis', group: '10' },
    { name: 'Acrocallosal Syndrome', group: '10' },
    { name: 'Sarcopenia', group: '10' },
    { name: 'Cogan Syndrome', group: '10' },
    { name: 'Microscopic Polyangiitis', group: '10' },
    { name: 'Diffuse Neurofibrillary Tangles with Calcification', group: '10' },
    { name: 'Piriformis Muscle Syndrome', group: '10' },
    { name: 'Alien Hand Syndrome', group: '10' },
    { name: 'Slit Ventricle Syndrome', group: '10' },
    { name: 'Giant Axonal Neuropathy', group: '10' },
    { name: 'Alstrom Syndrome', group: '10' },
    { name: 'Leukoencephalopathies', group: '10' },
    { name: 'Urea Cycle Disorders, Inborn', group: '10' },
    { name: 'Argininosuccinic Aciduria', group: '10' },
    { name: 'Renal Colic', group: '10' },
    { name: 'Contrecoup Injury', group: '10' },
    { name: 'Corneal Perforation', group: '10' },
    { name: 'Frontotemporal Lobar Degeneration', group: '10' },
    { name: 'TDP-43 Proteinopathies', group: '10' },
    { name: 'Primary Progressive Nonfluent Aphasia', group: '10' },
    { name: 'Frontotemporal Dementia', group: '10' },
    { name: 'Post-Exercise Hypotension', group: '10' },
    { name: 'Eye Pain', group: '10' },
    { name: 'Walker-Warburg Syndrome', group: '10' },
    { name: 'Smith-Magenis Syndrome', group: '10' },
    { name: 'Pentalogy of Cantrell', group: '10' },
    { name: 'Aicardi Syndrome', group: '10' },
    { name: 'Cerebral Ventriculitis', group: '10' },
    { name: 'Nociceptive Pain', group: '10' },
    { name: 'Visceral Pain', group: '10' },
    { name: 'Cerebral Small Vessel Diseases', group: '10' },
    { name: 'Peripheral Nerve Injuries', group: '10' },
    { name: 'Chronic Pain', group: '10' },
    { name: 'Mastodynia', group: '10' },
    { name: 'Pelvic Girdle Pain', group: '10' },
    { name: 'Breakthrough Pain', group: '10' },
    { name: 'Stroke, Lacunar', group: '10' },
    { name: 'Anhedonia', group: '10' },
    { name: 'Acute Pain', group: '10' },
    { name: 'Angina, Stable', group: '10' },
    { name: 'Ophthalmoplegic Migraine', group: '10' },
    { name: 'Pudendal Neuralgia', group: '10' },
    { name: 'Dyscalculia', group: '10' },
    { name: 'Agenesis of Corpus Callosum', group: '10' },
    { name: 'Olfactory Nerve Injuries', group: '10' },
    { name: 'Oculomotor Nerve Injuries', group: '10' },
    { name: 'Vagus Nerve Injuries', group: '10' },
    { name: 'Accessory Nerve Injuries', group: '10' },
    { name: 'Hypoglossal Nerve Injuries', group: '10' },
    { name: 'Trochlear Nerve Injuries', group: '10' },
    { name: 'Glossopharyngeal Nerve Injuries', group: '10' },
    { name: 'Alice in Wonderland Syndrome', group: '10' },
    { name: 'Pneumorrhachis', group: '10' },
    { name: 'Nodding Syndrome', group: '10' },
    { name: 'Sepsis-Associated Encephalopathy', group: '10' },
    { name: 'Corneal Injuries', group: '10' },
    { name: 'Cerebrospinal Fluid Leak', group: '10' },
    { name: 'Malformations of Cortical Development, Group I', group: '10' },
    { name: 'Malformations of Cortical Development, Group III', group: '10' },
    { name: 'Polymicrogyria', group: '10' },
    { name: 'Schizencephaly', group: '10' },
    { name: 'Lennox Gastaut Syndrome', group: '10' },
    { name: 'Allesthesia', group: '10' },
    { name: 'Canaliculitis', group: '11' },
    { name: 'Cone-Rod Dystrophies', group: '11' },
    { name: 'Albinism', group: '11' },
    { name: 'Angioid Streaks', group: '11' },
    { name: 'Aniseikonia', group: '11' },
    { name: 'Anophthalmos', group: '11' },
    { name: 'Aphakia', group: '11' },
    { name: 'Aphakia, Postcataract', group: '11' },
    { name: 'Arcus Senilis', group: '11' },
    { name: 'Asthenopia', group: '11' },
    { name: 'Astigmatism', group: '11' },
    { name: 'Blepharitis', group: '11' },
    { name: 'Blepharoptosis', group: '11' },
    { name: 'Blepharospasm', group: '11' },
    { name: 'Cataract', group: '11' },
    { name: 'Chorioretinitis', group: '11' },
    { name: 'Choroid Hemorrhage', group: '11' },
    { name: 'Choroiditis', group: '11' },
    { name: 'Coloboma', group: '11' },
    { name: 'Conjunctival Diseases', group: '11' },
    { name: 'Conjunctivitis', group: '11' },
    { name: 'Conjunctivitis, Allergic', group: '11' },
    { name: 'Corneal Diseases', group: '11' },
    { name: 'Corneal Dystrophies, Hereditary', group: '11' },
    { name: 'Corneal Opacity', group: '11' },
    { name: 'Dacryocystitis', group: '11' },
    { name: 'Diabetic Retinopathy', group: '11' },
    { name: 'Ectopia Lentis', group: '11' },
    { name: 'Ectropion', group: '11' },
    { name: 'Entropion', group: '11' },
    { name: 'Exophthalmos', group: '11' },
    { name: 'Eye Abnormalities', group: '11' },
    { name: 'Eye Diseases', group: '11' },
    { name: 'Eye Hemorrhage', group: '11' },
    { name: 'Eye Manifestations', group: '11' },
    { name: 'Eyelid Diseases', group: '11' },
    { name: 'Fuchs\' Endothelial Dystrophy', group: '11' },
    { name: 'Glaucoma', group: '11' },
    { name: 'Glaucoma, Open-Angle', group: '11' },
    { name: 'Graves Disease', group: '11' },
    { name: 'Hydrophthalmos', group: '11' },
    { name: 'Hyperopia', group: '11' },
    { name: 'Hyphema', group: '11' },
    { name: 'Iris Diseases', group: '11' },
    { name: 'Iritis', group: '11' },
    { name: 'Keratitis', group: '11' },
    { name: 'Keratoconjunctivitis', group: '11' },
    { name: 'Keratoconjunctivitis Sicca', group: '11' },
    { name: 'Keratoconus', group: '11' },
    { name: 'Lacrimal Apparatus Diseases', group: '11' },
    { name: 'Lacrimal Duct Obstruction', group: '11' },
    { name: 'Lens Diseases', group: '11' },
    { name: 'Lens Subluxation', group: '11' },
    { name: 'Macular Degeneration', group: '11' },
    { name: 'Macular Edema', group: '11' },
    { name: 'Microphthalmos', group: '11' },
    { name: 'Myopia', group: '11' },
    { name: 'Night Blindness', group: '11' },
    { name: 'Ocular Hypertension', group: '11' },
    { name: 'Ophthalmia, Sympathetic', group: '11' },
    { name: 'Orbital Diseases', group: '11' },
    { name: 'Pemphigoid, Benign Mucous Membrane', group: '11' },
    { name: 'Presbyopia', group: '11' },
    { name: 'Pterygium', group: '11' },
    { name: 'Refractive Errors', group: '11' },
    { name: 'Retinal Degeneration', group: '11' },
    { name: 'Retinal Detachment', group: '11' },
    { name: 'Retinal Diseases', group: '11' },
    { name: 'Retinal Hemorrhage', group: '11' },
    { name: 'Retinal Perforations', group: '11' },
    { name: 'Retinal Vein Occlusion', group: '11' },
    { name: 'Retinitis', group: '11' },
    { name: 'Retinitis Pigmentosa', group: '11' },
    { name: 'Retinopathy of Prematurity', group: '11' },
    { name: 'Uveal Diseases', group: '11' },
    { name: 'Uveitis', group: '11' },
    { name: 'Uveitis, Anterior', group: '11' },
    { name: 'Vitreous Hemorrhage', group: '11' },
    { name: 'Xerophthalmia', group: '11' },
    { name: 'Dry Eye Syndromes', group: '11' },
    { name: 'Glaucoma, Neovascular', group: '11' },
    { name: 'Retinal Artery Occlusion', group: '11' },
    { name: 'Scleral Diseases', group: '11' },
    { name: 'Scleritis', group: '11' },
    { name: 'Retinal Drusen', group: '11' },
    { name: 'Corneal Edema', group: '11' },
    { name: 'Aniridia', group: '11' },
    { name: 'Eye Diseases, Hereditary', group: '11' },
    { name: 'Retinal Dysplasia', group: '11' },
    { name: 'Choroideremia', group: '11' },
    { name: 'Gyrate Atrophy', group: '11' },
    { name: 'Glaucoma, Angle-Closure', group: '11' },
    { name: 'Ocular Hypotension', group: '11' },
    { name: 'Enophthalmos', group: '11' },
    { name: 'Anisometropia', group: '11' },
    { name: 'Retinal Neovascularization', group: '11' },
    { name: 'Choroid Diseases', group: '11' },
    { name: 'Iridocyclitis', group: '11' },
    { name: 'Panuveitis', group: '11' },
    { name: 'Uveitis, Posterior', group: '11' },
    { name: 'Uveitis, Intermediate', group: '11' },
    { name: 'Pars Planitis', group: '11' },
    { name: 'Mydriasis', group: '11' },
    { name: 'Retinal Necrosis Syndrome, Acute', group: '11' },
    { name: 'Albinism, Oculocutaneous', group: '11' },
    { name: 'Albinism, Ocular', group: '11' },
    { name: 'Corneal Neovascularization', group: '11' },
    { name: 'Blepharophimosis', group: '11' },
    { name: 'Orbital Pseudotumor', group: '11' },
    { name: 'Exfoliation Syndrome', group: '11' },
    { name: 'Vitreoretinopathy, Proliferative', group: '11' },
    { name: 'Retrobulbar Hemorrhage', group: '11' },
    { name: 'Epiretinal Membrane', group: '11' },
    { name: 'Vitreous Detachment', group: '11' },
    { name: 'Choroidal Neovascularization', group: '11' },
    { name: 'Hermanski-Pudlak Syndrome', group: '11' },
    { name: 'Retinal Vasculitis', group: '11' },
    { name: 'Retinoschisis', group: '11' },
    { name: 'Myopia, Degenerative', group: '11' },
    { name: 'Graves Ophthalmopathy', group: '11' },
    { name: 'Corneal Dystrophy, Juvenile Epithelial of Meesmann', group: '11' },
    { name: 'Persistent Hyperplastic Primary Vitreous', group: '11' },
    { name: 'Orbital Cellulitis', group: '11' },
    { name: 'Corneal Endothelial Cell Loss', group: '11' },
    { name: 'Central Serous Chorioretinopathy', group: '11' },
    { name: 'Low Tension Glaucoma', group: '11' },
    { name: 'Geographic Atrophy', group: '11' },
    { name: 'Corneal Wavefront Aberration', group: '11' },
    { name: 'Iridocorneal Endothelial Syndrome', group: '11' },
    { name: 'Leber Congenital Amaurosis', group: '11' },
    { name: 'Wet Macular Degeneration', group: '11' },
    { name: 'Vitelliform Macular Dystrophy', group: '11' },
    { name: 'Posterior Capsular Rupture, Ocular', group: '11' },
    { name: 'Anterior Capsular Rupture, Ocular', group: '11' },
    { name: 'Hypertensive Retinopathy', group: '11' },
    { name: 'Capsule Opacification', group: '11' },
    { name: 'Retinal Telangiectasis', group: '11' },
    { name: 'Trichiasis', group: '11' },
    { name: 'Retinal Dystrophies', group: '11' },
    { name: 'Pinguecula', group: '11' },
    { name: 'Artificial Lens Implant Migration', group: '11' },
    { name: 'Fused Kidney', group: '12' },
    { name: 'Staghorn Calculi', group: '12' },
    { name: 'Teratozoospermia', group: '12' },
    { name: 'Acidosis, Renal Tubular', group: '12' },
    { name: 'Adrenal Hyperplasia, Congenital', group: '12' },
    { name: 'Albuminuria', group: '12' },
    { name: 'Renal Aminoacidurias', group: '12' },
    { name: 'Anuria', group: '12' },
    { name: 'Balanitis', group: '12' },
    { name: 'Balkan Nephropathy', group: '12' },
    { name: 'Bartter Syndrome', group: '12' },
    { name: 'Urinary Bladder Calculi', group: '12' },
    { name: 'Urinary Bladder Diseases', group: '12' },
    { name: 'Bladder Exstrophy', group: '12' },
    { name: 'Urinary Bladder Fistula', group: '12' },
    { name: 'Urinary Bladder Neck Obstruction', group: '12' },
    { name: 'Cryptorchidism', group: '12' },
    { name: 'Cystinuria', group: '12' },
    { name: 'Cystitis', group: '12' },
    { name: 'Diabetes Insipidus', group: '12' },
    { name: 'Diabetic Nephropathies', group: '12' },
    { name: 'Dyspareunia', group: '12' },
    { name: 'Enuresis', group: '12' },
    { name: 'Epididymitis', group: '12' },
    { name: 'Epispadias', group: '12' },
    { name: 'Fanconi Syndrome', group: '12' },
    { name: 'Freemartinism', group: '12' },
    { name: 'Genital Diseases, Male', group: '12' },
    { name: 'Glomerulonephritis', group: '12' },
    { name: 'Glomerulonephritis, IGA', group: '12' },
    { name: 'Glomerulosclerosis, Focal Segmental', group: '12' },
    { name: 'Glycosuria', group: '12' },
    { name: 'Glycosuria, Renal', group: '12' },
    { name: 'Gonadal Dysgenesis', group: '12' },
    { name: 'Gonadal Dysgenesis, Mixed', group: '12' },
    { name: 'Gonadal Dysgenesis, 46,XY', group: '12' },
    { name: 'Hematocele', group: '12' },
    { name: 'Hematuria', group: '12' },
    { name: 'Hemoglobinuria', group: '12' },
    { name: 'Hemolytic-Uremic Syndrome', group: '12' },
    { name: 'Testicular Hydrocele', group: '12' },
    { name: 'Hydronephrosis', group: '12' },
    { name: 'Hyperoxaluria', group: '12' },
    { name: 'Hyperoxaluria, Primary', group: '12' },
    { name: 'Hypertension, Renal', group: '12' },
    { name: 'Hypertension, Renovascular', group: '12' },
    { name: 'Hypophosphatemia, Familial', group: '12' },
    { name: 'Hypospadias', group: '12' },
    { name: 'Erectile Dysfunction', group: '12' },
    { name: 'Infertility', group: '12' },
    { name: 'Infertility, Male', group: '12' },
    { name: 'Kidney Calculi', group: '12' },
    { name: 'Kidney Cortex Necrosis', group: '12' },
    { name: 'Kidney Diseases', group: '12' },
    { name: 'Kidney Failure, Chronic', group: '12' },
    { name: 'Kidney Papillary Necrosis', group: '12' },
    { name: 'Kidney Tubular Necrosis, Acute', group: '12' },
    { name: 'Polycystic Kidney Diseases', group: '12' },
    { name: 'Medullary Sponge Kidney', group: '12' },
    { name: 'Klinefelter Syndrome', group: '12' },
    { name: 'Lupus Nephritis', group: '12' },
    { name: 'Nephritis', group: '12' },
    { name: 'Nephritis, Hereditary', group: '12' },
    { name: 'Nephritis, Interstitial', group: '12' },
    { name: 'Nephrocalcinosis', group: '12' },
    { name: 'Nephrosclerosis', group: '12' },
    { name: 'Nephrosis', group: '12' },
    { name: 'Nephrosis, Lipoid', group: '12' },
    { name: 'Nephrotic Syndrome', group: '12' },
    { name: 'Oligospermia', group: '12' },
    { name: 'Oliguria', group: '12' },
    { name: 'Orchitis', group: '12' },
    { name: 'Paraphimosis', group: '12' },
    { name: 'Penile Diseases', group: '12' },
    { name: 'Penile Induration', group: '12' },
    { name: 'Perinephritis', group: '12' },
    { name: 'Phimosis', group: '12' },
    { name: 'Polyuria', group: '12' },
    { name: 'Priapism', group: '12' },
    { name: 'Prostatic Diseases', group: '12' },
    { name: 'Prostatic Hyperplasia', group: '12' },
    { name: 'Prostatitis', group: '12' },
    { name: 'Proteinuria', group: '12' },
    { name: 'Pseudohypoaldosteronism', group: '12' },
    { name: 'Pyelitis', group: '12' },
    { name: 'Pyelonephritis', group: '12' },
    { name: 'Pyelonephritis, Xanthogranulomatous', group: '12' },
    { name: 'Renal Artery Obstruction', group: '12' },
    { name: 'Disorders of Sex Development', group: '12' },
    { name: 'Sexual Dysfunction, Physiological', group: '12' },
    { name: 'Spermatic Cord Torsion', group: '12' },
    { name: 'Spermatocele', group: '12' },
    { name: 'Testicular Diseases', group: '12' },
    { name: 'Androgen-Insensitivity Syndrome', group: '12' },
    { name: 'Turner Syndrome', group: '12' },
    { name: 'Uremia', group: '12' },
    { name: 'Ureteral Calculi', group: '12' },
    { name: 'Ureteral Diseases', group: '12' },
    { name: 'Ureteral Obstruction', group: '12' },
    { name: 'Ureterocele', group: '12' },
    { name: 'Urethral Diseases', group: '12' },
    { name: 'Urethral Obstruction', group: '12' },
    { name: 'Urethral Stricture', group: '12' },
    { name: 'Urethritis', group: '12' },
    { name: 'Urinary Calculi', group: '12' },
    { name: 'Urinary Fistula', group: '12' },
    { name: 'Urinary Incontinence', group: '12' },
    { name: 'Urinary Incontinence, Stress', group: '12' },
    { name: 'Urination Disorders', group: '12' },
    { name: 'Urogenital Abnormalities', group: '12' },
    { name: 'Urologic Diseases', group: '12' },
    { name: 'Varicocele', group: '12' },
    { name: 'Vesico-Ureteral Reflux', group: '12' },
    { name: 'Glomerulonephritis, Membranoproliferative', group: '12' },
    { name: 'Glomerulonephritis, Membranous', group: '12' },
    { name: 'Renal Tubular Transport, Inborn Errors', group: '12' },
    { name: 'Urinary Retention', group: '12' },
    { name: 'Polycystic Kidney, Autosomal Dominant', group: '12' },
    { name: 'Polycystic Kidney, Autosomal Recessive', group: '12' },
    { name: 'Kallmann Syndrome', group: '12' },
    { name: 'Hyperandrogenism', group: '12' },
    { name: 'Diabetes Insipidus, Nephrogenic', group: '12' },
    { name: 'Impotence, Vasculogenic', group: '12' },
    { name: 'Cystitis, Interstitial', group: '12' },
    { name: 'Diabetes Insipidus, Neurogenic', group: '12' },
    { name: 'Multicystic Dysplastic Kidney', group: '12' },
    { name: 'Gonadal Dysgenesis, 46,XX', group: '12' },
    { name: 'Adrenogenital Syndrome', group: '12' },
    { name: 'Sexual Infantilism', group: '12' },
    { name: 'Ovotesticular Disorders of Sex Development', group: '12' },
    { name: 'Renal Insufficiency, Chronic', group: '12' },
    { name: 'Renal Insufficiency', group: '12' },
    { name: 'Hemospermia', group: '12' },
    { name: 'Frasier Syndrome', group: '12' },
    { name: 'Kidney Diseases, Cystic', group: '12' },
    { name: 'Balanitis Xerotica Obliterans', group: '12' },
    { name: 'Male Urogenital Diseases', group: '12' },
    { name: 'Cystocele', group: '12' },
    { name: 'Urolithiasis', group: '12' },
    { name: 'Pyonephrosis', group: '12' },
    { name: 'Ureterolithiasis', group: '12' },
    { name: 'Nephrolithiasis', group: '12' },
    { name: 'Azotemia', group: '12' },
    { name: 'Urinary Bladder, Overactive', group: '12' },
    { name: 'Urinary Incontinence, Urge', group: '12' },
    { name: 'Nocturnal Enuresis', group: '12' },
    { name: 'Diurnal Enuresis', group: '12' },
    { name: 'Gitelman Syndrome', group: '12' },
    { name: 'Asthenozoospermia', group: '12' },
    { name: 'Pyelocystitis', group: '2' },
    { name: 'Azoospermia', group: '12' },
    { name: 'Aspermia', group: '12' },
    { name: 'Sertoli Cell-Only Syndrome', group: '12' },
    { name: 'Liddle Syndrome', group: '12' },
    { name: 'Dent Disease', group: '12' },
    { name: 'Acute Kidney Injury', group: '12' },
    { name: '46, XX Disorders of Sex Development', group: '12' },
    { name: '46, XY Disorders of Sex Development', group: '12' },
    { name: '46, XX Testicular Disorders of Sex Development', group: '12' },
    { name: 'Sex Chromosome Disorders of Sex Development', group: '12' },
    { name: 'Renal Nutcracker Syndrome', group: '12' },
    { name: 'Cardio-Renal Syndrome', group: '12' },
    { name: 'Pelvic Floor Disorders', group: '12' },
    { name: 'Premature Ejaculation', group: '12' },
    { name: 'Retrocaval Ureter', group: '12' },
    { name: 'Atypical Hemolytic Uremic Syndrome', group: '12' },
    { name: 'Abortion, Spontaneous', group: '13' },
    { name: 'Abortion, Habitual', group: '13' },
    { name: 'Abortion, Incomplete', group: '13' },
    { name: 'Abortion, Missed', group: '13' },
    { name: 'Abortion, Threatened', group: '13' },
    { name: 'Abortion, Veterinary', group: '13' },
    { name: 'Abruptio Placentae', group: '13' },
    { name: 'Adnexal Diseases', group: '13' },
    { name: 'Anovulation', group: '13' },
    { name: 'Breech Presentation', group: '13' },
    { name: 'Uterine Cervicitis', group: '13' },
    { name: 'Uterine Cervical Diseases', group: '13' },
    { name: 'Uterine Cervical Erosion', group: '13' },
    { name: 'Uterine Cervical Incompetence', group: '13' },
    { name: 'Chiari-Frommel Syndrome', group: '13' },
    { name: 'Chorioamnionitis', group: '13' },
    { name: 'Dystocia', group: '13' },
    { name: 'Eclampsia', group: '13' },
    { name: 'Embolism, Amniotic Fluid', group: '13' },
    { name: 'Endometrial Hyperplasia', group: '13' },
    { name: 'Endometriosis', group: '13' },
    { name: 'Endometritis', group: '13' },
    { name: 'Erythroblastosis, Fetal', group: '13' },
    { name: 'Fallopian Tube Diseases', group: '13' },
    { name: 'Female Urogenital Diseases and Pregnancy Complications', group: '13' },
    { name: 'Fetal Hypoxia', group: '13' },
    { name: 'Fetal Death', group: '13' },
    { name: 'Fetal Diseases', group: '13' },
    { name: 'Fetal Growth Retardation', group: '13' },
    { name: 'Fetal Macrosomia', group: '13' },
    { name: 'Fetal Membranes, Premature Rupture', group: '13' },
    { name: 'Fetal Resorption', group: '13' },
    { name: 'Galactorrhea', group: '13' },
    { name: 'Genital Diseases, Female', group: '13' },
    { name: 'Gynatresia', group: '13' },
    { name: 'Hematocolpos', group: '13' },
    { name: 'Hematometra', group: '13' },
    { name: 'Postpartum Hemorrhage', group: '13' },
    { name: 'Pemphigoid Gestationis', group: '13' },
    { name: 'Polyhydramnios', group: '13' },
    { name: 'Hyperemesis Gravidarum', group: '13' },
    { name: 'Infertility, Female', group: '13' },
    { name: 'Vulvar Lichen Sclerosus', group: '13' },
    { name: 'Obstetric Labor Complications', group: '13' },
    { name: 'Obstetric Labor, Premature', group: '13' },
    { name: 'Lactation Disorders', group: '13' },
    { name: 'Leukorrhea', group: '13' },
    { name: 'Mastitis', group: '13' },
    { name: 'Menopause, Premature', group: '13' },
    { name: 'Menorrhagia', group: '13' },
    { name: 'Metrorrhagia', group: '13' },
    { name: 'Oophoritis', group: '13' },
    { name: 'Ovarian Diseases', group: '13' },
    { name: 'Parametritis', group: '13' },
    { name: 'Placenta Accreta', group: '13' },
    { name: 'Placenta Diseases', group: '13' },
    { name: 'Placenta Previa', group: '13' },
    { name: 'Placental Insufficiency', group: '13' },
    { name: 'Pre-Eclampsia', group: '13' },
    { name: 'Pregnancy Complications', group: '13' },
    { name: 'Pregnancy Complications, Cardiovascular', group: '13' },
    { name: 'Pregnancy Complications, Hematologic', group: '13' },
    { name: 'Pregnancy in Diabetics', group: '13' },
    { name: 'Pregnancy, Abdominal', group: '13' },
    { name: 'Pregnancy, Ectopic', group: '13' },
    { name: 'Pregnancy, Prolonged', group: '13' },
    { name: 'Pregnancy, Tubal', group: '13' },
    { name: 'Prenatal Exposure Delayed Effects', group: '13' },
    { name: 'Pruritus Vulvae', group: '13' },
    { name: 'Puerperal Disorders', group: '13' },
    { name: 'Salpingitis', group: '13' },
    { name: 'Uterine Diseases', group: '13' },
    { name: 'Uterine Hemorrhage', group: '13' },
    { name: 'Uterine Inertia', group: '13' },
    { name: 'Uterine Perforation', group: '13' },
    { name: 'Uterine Prolapse', group: '13' },
    { name: 'Uterine Rupture', group: '13' },
    { name: 'Vaginal Diseases', group: '13' },
    { name: 'Vaginal Fistula', group: '13' },
    { name: 'Vaginitis', group: '13' },
    { name: 'Vesicovaginal Fistula', group: '13' },
    { name: 'Vulvar Diseases', group: '13' },
    { name: 'Vulvitis', group: '13' },
    { name: 'Vulvovaginitis', group: '13' },
    { name: 'Hydrops Fetalis', group: '13' },
    { name: 'Oligohydramnios', group: '13' },
    { name: 'Ovarian Hyperstimulation Syndrome', group: '13' },
    { name: 'Diabetes, Gestational', group: '13' },
    { name: 'Primary Ovarian Insufficiency', group: '13' },
    { name: 'HELLP Syndrome', group: '13' },
    { name: 'Placenta, Retained', group: '13' },
    { name: 'Depression, Postpartum', group: '13' },
    { name: 'Vaginal Discharge', group: '13' },
    { name: 'Uterine Inversion', group: '13' },
    { name: 'Embryo Loss', group: '13' },
    { name: 'Hypertension, Pregnancy-Induced', group: '13' },
    { name: 'Premature Birth', group: '13' },
    { name: 'Fetal Nutrition Disorders', group: '13' },
    { name: 'Morning Sickness', group: '13' },
    { name: 'Prenatal Injuries', group: '13' },
    { name: 'Postpartum Thyroiditis', group: '13' },
    { name: 'Stillbirth', group: '13' },
    { name: 'Vaginismus', group: '13' },
    { name: 'Cephalopelvic Disproportion', group: '13' },
    { name: 'Hydrocolpos', group: '13' },
    { name: 'Female Urogenital Diseases', group: '13' },
    { name: 'Nuchal Cord', group: '13' },
    { name: 'Vulvar Vestibulitis', group: '13' },
    { name: 'Pyometra', group: '13' },
    { name: 'Vasa Previa', group: '13' },
    { name: 'Vulvodynia', group: '13' },
    { name: 'Echogenic Bowel', group: '13' },
    { name: 'Pyelectasis', group: '13' },
    { name: 'Granulomatous Mastitis', group: '13' },
    { name: 'Atrophic Vaginitis', group: '13' },
    { name: 'Uterine Retroversion', group: '13' },
    { name: 'Adenomyosis', group: '13' },
    { name: 'Maternal Death', group: '13' },
    { name: 'Pregnancy, Heterotopic', group: '13' },
    { name: 'Fetal Alcohol Spectrum Disorders', group: '13' },
    { name: 'Pregnancy, Interstitial', group: '13' },
    { name: 'Pregnancy, Angular', group: '13' },
    { name: 'Pregnancy, Ovarian', group: '13' },
    { name: 'Pregnancy, Cornual', group: '13' },
    { name: 'Perinatal Death', group: '13' },
    { name: 'Microaneurysm', group: '14' },
    { name: 'Stenosis, Pulmonary Vein', group: '14' },
    { name: 'Stenosis, Pulmonary Artery', group: '14' },
    { name: 'ST Elevation Myocardial Infarction', group: '14' },
    { name: 'Non-ST Elevated Myocardial Infarction', group: '14' },
    { name: 'Adams-Stokes Syndrome', group: '14' },
    { name: 'Aneurysm', group: '14' },
    { name: 'Aneurysm, Dissecting', group: '14' },
    { name: 'Angiomatosis', group: '14' },
    { name: 'Angioedema', group: '14' },
    { name: 'Aortic Aneurysm', group: '14' },
    { name: 'Aortic Arch Syndromes', group: '14' },
    { name: 'Aortic Coarctation', group: '14' },
    { name: 'Aortic Diseases', group: '14' },
    { name: 'Aortic Rupture', group: '14' },
    { name: 'Aortic Stenosis, Subvalvular', group: '14' },
    { name: 'Aortic Valve Insufficiency', group: '14' },
    { name: 'Aortic Valve Prolapse', group: '14' },
    { name: 'Aortic Valve Stenosis', group: '14' },
    { name: 'Aortitis', group: '14' },
    { name: 'Aortopulmonary Septal Defect', group: '14' },
    { name: 'Arrhythmias, Cardiac', group: '14' },
    { name: 'Arrhythmia, Sinus', group: '14' },
    { name: 'Arterial Occlusive Diseases', group: '14' },
    { name: 'Arterio-Arterial Fistula', group: '14' },
    { name: 'Arteriosclerosis', group: '14' },
    { name: 'Arteriosclerosis Obliterans', group: '14' },
    { name: 'Arteriovenous Fistula', group: '14' },
    { name: 'Arteriovenous Malformations', group: '14' },
    { name: 'Arteritis', group: '14' },
    { name: 'Atrial Fibrillation', group: '14' },
    { name: 'Atrial Flutter', group: '14' },
    { name: 'Bradycardia', group: '14' },
    { name: 'Bundle-Branch Block', group: '14' },
    { name: 'Cardiac Output, Low', group: '14' },
    { name: 'Cardiac Tamponade', group: '14' },
    { name: 'Cardiomyopathy, Alcoholic', group: '14' },
    { name: 'Cardiomyopathy, Dilated', group: '14' },
    { name: 'Cardiomyopathy, Hypertrophic', group: '14' },
    { name: 'Cardiomyopathy, Restrictive', group: '14' },
    { name: 'Cardiovascular Diseases', group: '14' },
    { name: 'Cor Triatriatum', group: '14' },
    { name: 'Coronary Aneurysm', group: '14' },
    { name: 'Coronary Artery Disease', group: '14' },
    { name: 'Coronary Disease', group: '14' },
    { name: 'Coronary Thrombosis', group: '14' },
    { name: 'Coronary Vasospasm', group: '14' },
    { name: 'Coronary Vessel Anomalies', group: '14' },
    { name: 'Crisscross Heart', group: '14' },
    { name: 'Cryoglobulinemia', group: '14' },
    { name: 'Dextrocardia', group: '14' },
    { name: 'Diabetic Angiopathies', group: '14' },
    { name: 'Double Outlet Right Ventricle', group: '14' },
    { name: 'Ductus Arteriosus, Patent', group: '14' },
    { name: 'Ebstein Anomaly', group: '14' },
    { name: 'Edema, Cardiac', group: '14' },
    { name: 'Ehlers-Danlos Syndrome', group: '14' },
    { name: 'Eisenmenger Complex', group: '14' },
    { name: 'Embolism', group: '14' },
    { name: 'Embolism, Air', group: '14' },
    { name: 'Embolism, Fat', group: '14' },
    { name: 'Endarteritis', group: '14' },
    { name: 'Endocardial Cushion Defects', group: '14' },
    { name: 'Endocardial Fibroelastosis', group: '14' },
    { name: 'Endocarditis', group: '14' },
    { name: 'Endomyocardial Fibrosis', group: '14' },
    { name: 'Erythromelalgia', group: '14' },
    { name: 'Cardiac Complexes, Premature', group: '14' },
    { name: 'Fibromuscular Dysplasia', group: '14' },
    { name: 'Heart Aneurysm', group: '14' },
    { name: 'Heart Arrest', group: '14' },
    { name: 'Heart Block', group: '14' },
    { name: 'Heart Defects, Congenital', group: '14' },
    { name: 'Heart Diseases', group: '14' },
    { name: 'Cardiomegaly', group: '14' },
    { name: 'Heart Failure', group: '14' },
    { name: 'Heart Rupture', group: '14' },
    { name: 'Heart Rupture, Post-Infarction', group: '14' },
    { name: 'Heart Septal Defects', group: '14' },
    { name: 'Heart Septal Defects, Atrial', group: '14' },
    { name: 'Heart Septal Defects, Ventricular', group: '14' },
    { name: 'Heart Valve Diseases', group: '14' },
    { name: 'Hyperemia', group: '14' },
    { name: 'Hypertension', group: '14' },
    { name: 'Hypertension, Malignant', group: '14' },
    { name: 'Hypotension', group: '14' },
    { name: 'Intermittent Claudication', group: '14' },
    { name: 'Klippel-Trenaunay-Weber Syndrome', group: '14' },
    { name: 'Leriche Syndrome', group: '14' },
    { name: 'Levocardia', group: '14' },
    { name: 'Long QT Syndrome', group: '14' },
    { name: 'Lown-Ganong-Levine Syndrome', group: '14' },
    { name: 'Lutembacher Syndrome', group: '14' },
    { name: 'Mitral Valve Insufficiency', group: '14' },
    { name: 'Mitral Valve Prolapse', group: '14' },
    { name: 'Mitral Valve Stenosis', group: '14' },
    { name: 'Mucocutaneous Lymph Node Syndrome', group: '14' },
    { name: 'Cardiomyopathies', group: '14' },
    { name: 'Myocardial Infarction', group: '14' },
    { name: 'Myocarditis', group: '14' },
    { name: 'Polyarteritis Nodosa', group: '14' },
    { name: 'Pericardial Effusion', group: '14' },
    { name: 'Pericarditis', group: '14' },
    { name: 'Pericarditis, Constrictive', group: '14' },
    { name: 'Phlebitis', group: '14' },
    { name: 'Pneumopericardium', group: '14' },
    { name: 'Postpericardiotomy Syndrome', group: '14' },
    { name: 'Postphlebitic Syndrome', group: '14' },
    { name: 'Pre-Excitation Syndromes', group: '14' },
    { name: 'Pre-Excitation, Mahaim-Type', group: '14' },
    { name: 'Pseudoxanthoma Elasticum', group: '14' },
    { name: 'Pulmonary Heart Disease', group: '14' },
    { name: 'Pulmonary Subvalvular Stenosis', group: '14' },
    { name: 'Pulmonary Valve Insufficiency', group: '14' },
    { name: 'Pulmonary Valve Stenosis', group: '14' },
    { name: 'Purpura, Hyperglobulinemic', group: '14' },
    { name: 'Purpura, Schoenlein-Henoch', group: '14' },
    { name: 'Raynaud Disease', group: '14' },
    { name: 'Scurvy', group: '14' },
    { name: 'Shock, Cardiogenic', group: '14' },
    { name: 'Shwartzman Phenomenon', group: '14' },
    { name: 'Sick Sinus Syndrome', group: '14' },
    { name: 'Sinoatrial Block', group: '14' },
    { name: 'Splenic Infarction', group: '14' },
    { name: 'Superior Vena Cava Syndrome', group: '14' },
    { name: 'Tachycardia', group: '14' },
    { name: 'Tachycardia, Atrioventricular Nodal Reentry', group: '14' },
    { name: 'Tachycardia, Ectopic Atrial', group: '14' },
    { name: 'Tachycardia, Ectopic Junctional', group: '14' },
    { name: 'Tachycardia, Paroxysmal', group: '14' },
    { name: 'Tachycardia, Sinoatrial Nodal Reentry', group: '14' },
    { name: 'Tachycardia, Sinus', group: '14' },
    { name: 'Tachycardia, Supraventricular', group: '14' },
    { name: 'Takayasu Arteritis', group: '14' },
    { name: 'Telangiectasia, Hereditary Hemorrhagic', group: '14' },
    { name: 'Telangiectasis', group: '14' },
    { name: 'Tetralogy of Fallot', group: '14' },
    { name: 'Thromboangiitis Obliterans', group: '14' },
    { name: 'Thromboembolism', group: '14' },
    { name: 'Thrombophlebitis', group: '14' },
    { name: 'Thrombosis', group: '14' },
    { name: 'Transposition of Great Vessels', group: '14' },
    { name: 'Tricuspid Valve Insufficiency', group: '14' },
    { name: 'Tricuspid Valve Prolapse', group: '14' },
    { name: 'Tricuspid Valve Stenosis', group: '14' },
    { name: 'Trilogy of Fallot', group: '14' },
    { name: 'Truncus Arteriosus, Persistent', group: '14' },
    { name: 'Varicose Ulcer', group: '14' },
    { name: 'Varicose Veins', group: '14' },
    { name: 'Vascular Diseases', group: '14' },
    { name: 'Vasculitis', group: '14' },
    { name: 'Venous Insufficiency', group: '14' },
    { name: 'Ventricular Fibrillation', group: '14' },
    { name: 'Ventricular Outflow Obstruction', group: '14' },
    { name: 'Wolff-Parkinson-White Syndrome', group: '14' },
    { name: 'Churg-Strauss Syndrome', group: '14' },
    { name: 'Reperfusion Injury', group: '14' },
    { name: 'Myocardial Reperfusion Injury', group: '14' },
    { name: 'Heart Valve Prolapse', group: '14' },
    { name: 'Vascular Fistula', group: '14' },
    { name: 'Accelerated Idioventricular Rhythm', group: '14' },
    { name: 'Torsades de Pointes', group: '14' },
    { name: 'Peripheral Vascular Diseases', group: '14' },
    { name: 'Cardiac Output, High', group: '14' },
    { name: 'Death, Sudden, Cardiac', group: '14' },
    { name: 'Embolism and Thrombosis', group: '14' },
    { name: 'Angiodysplasia', group: '14' },
    { name: 'Tachycardia, Ventricular', group: '14' },
    { name: 'Myocardial Ischemia', group: '14' },
    { name: 'Hypertrophy, Left Ventricular', group: '14' },
    { name: 'Hypertrophy, Right Ventricular', group: '14' },
    { name: 'Aneurysm, False', group: '14' },
    { name: 'Aneurysm, Ruptured', group: '14' },
    { name: 'Iliac Aneurysm', group: '14' },
    { name: 'Aortic Aneurysm, Abdominal', group: '14' },
    { name: 'Aortic Aneurysm, Thoracic', group: '14' },
    { name: 'Microvascular Angina', group: '14' },
    { name: 'Parasystole', group: '14' },
    { name: 'Myocardial Stunning', group: '14' },
    { name: 'Embolism, Cholesterol', group: '14' },
    { name: 'Diabetic Foot', group: '14' },
    { name: 'Vasculitis, Leukocytoclastic, Cutaneous', group: '14' },
    { name: 'Cardiovascular Abnormalities', group: '14' },
    { name: 'Blue Toe Syndrome', group: '14' },
    { name: 'Ventricular Dysfunction, Left', group: '14' },
    { name: 'Ventricular Dysfunction, Right', group: '14' },
    { name: 'Pulmonary Atresia', group: '14' },
    { name: 'Hypoplastic Left Heart Syndrome', group: '14' },
    { name: 'Ventricular Septal Rupture', group: '14' },
    { name: 'Ventricular Dysfunction', group: '14' },
    { name: 'Tricuspid Atresia', group: '14' },
    { name: 'Ventricular Premature Complexes', group: '14' },
    { name: 'Atrial Premature Complexes', group: '14' },
    { name: 'Embolism, Paradoxical', group: '14' },
    { name: 'Capillary Leak Syndrome', group: '14' },
    { name: 'Arrhythmogenic Right Ventricular Dysplasia', group: '14' },
    { name: 'Hemostatic Disorders', group: '14' },
    { name: 'Venous Thrombosis', group: '14' },
    { name: 'Aortic Stenosis, Supravalvular', group: '14' },
    { name: 'Discrete Subaortic Stenosis', group: '14' },
    { name: 'Coronary Restenosis', group: '14' },
    { name: 'Coronary Stenosis', group: '14' },
    { name: 'Cardiomyopathy, Hypertrophic, Familial', group: '14' },
    { name: 'Jervell-Lange Nielsen Syndrome', group: '14' },
    { name: 'Romano-Ward Syndrome', group: '14' },
    { name: 'Andersen Syndrome', group: '14' },
    { name: 'Atherosclerosis', group: '14' },
    { name: 'Arteriolosclerosis', group: '14' },
    { name: 'Brugada Syndrome', group: '14' },
    { name: 'Acute Coronary Syndrome', group: '14' },
    { name: 'Coronary Occlusion', group: '14' },
    { name: 'Livedo Reticularis', group: '14' },
    { name: 'Postthrombotic Syndrome', group: '14' },
    { name: 'Vascular Malformations', group: '14' },
    { name: 'Ectopia Cordis', group: '14' },
    { name: 'Myocardial Bridging', group: '14' },
    { name: 'Foramen Ovale, Patent', group: '14' },
    { name: 'Sinus Arrest, Cardiac', group: '14' },
    { name: 'Tachycardia, Reciprocating', group: '14' },
    { name: 'Ventricular Flutter', group: '14' },
    { name: 'Heart Failure, Systolic', group: '14' },
    { name: 'Heart Failure, Diastolic', group: '14' },
    { name: 'Angioedemas, Hereditary', group: '14' },
    { name: 'No-Reflow Phenomenon', group: '14' },
    { name: 'Atrioventricular Block', group: '14' },
    { name: 'Takotsubo Cardiomyopathy', group: '14' },
    { name: 'Venous Thromboembolism', group: '14' },
    { name: 'Malignant Atrophic Papulosis', group: '14' },
    { name: 'Primary Graft Dysfunction', group: '14' },
    { name: 'Commotio Cordis', group: '14' },
    { name: 'Systemic Vasculitis', group: '14' },
    { name: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', group: '14' },
    { name: 'Upper Extremity Deep Vein Thrombosis', group: '14' },
    { name: 'Hereditary Angioedema Type III', group: '14' },
    { name: 'Hereditary Angioedema Types I and II', group: '14' },
    { name: 'Isolated Noncompaction of the Ventricular Myocardium', group: '14' },
    { name: 'Barth Syndrome', group: '14' },
    { name: 'Vasoplegia', group: '14' },
    { name: 'Anterior Wall Myocardial Infarction', group: '14' },
    { name: 'Inferior Wall Myocardial Infarction', group: '14' },
    { name: 'Vascular System Injuries', group: '14' },
    { name: 'Endoleak', group: '14' },
    { name: 'Diabetic Cardiomyopathies', group: '14' },
    { name: 'Prehypertension', group: '14' },
    { name: 'Single Umbilical Artery', group: '14' },
    { name: 'Coronary-Subclavian Steal Syndrome', group: '14' },
    { name: 'Out-of-Hospital Cardiac Arrest', group: '14' },
    { name: 'Peripheral Arterial Disease', group: '14' },
    { name: 'Heterotaxy Syndrome', group: '14' },
    { name: 'White Coat Hypertension', group: '14' },
    { name: 'Masked Hypertension', group: '14' },
    { name: 'Endocarditis, Non-Infective', group: '14' },
    { name: 'May-Thurner Syndrome', group: '14' },
    { name: 'Bland White Garland Syndrome', group: '14' },
    { name: 'Karoshi Death', group: '14' },
    { name: 'Lymphadenopathy', group: '15' },
    { name: 'Breast Cancer Lymphedema', group: '15' },
    { name: 'Afibrinogenemia', group: '15' },
    { name: 'Agammaglobulinemia', group: '15' },
    { name: 'Agranulocytosis', group: '15' },
    { name: 'Anemia', group: '15' },
    { name: 'Anemia, Aplastic', group: '15' },
    { name: 'Anemia, Dyserythropoietic, Congenital', group: '15' },
    { name: 'Anemia, Hemolytic', group: '15' },
    { name: 'Anemia, Hemolytic, Autoimmune', group: '15' },
    { name: 'Anemia, Hemolytic, Congenital', group: '15' },
    { name: 'Anemia, Hemolytic, Congenital Nonspherocytic', group: '15' },
    { name: 'Anemia, Hypochromic', group: '15' },
    { name: 'Anemia, Macrocytic', group: '15' },
    { name: 'Anemia, Megaloblastic', group: '15' },
    { name: 'Anemia, Myelophthisic', group: '15' },
    { name: 'Anemia, Neonatal', group: '15' },
    { name: 'Anemia, Pernicious', group: '15' },
    { name: 'Anemia, Refractory', group: '15' },
    { name: 'Anemia, Refractory, with Excess of Blasts', group: '15' },
    { name: 'Anemia, Sickle Cell', group: '15' },
    { name: 'Anemia, Sideroblastic', group: '15' },
    { name: 'Angiolymphoid Hyperplasia with Eosinophilia', group: '15' },
    { name: 'Bernard-Soulier Syndrome', group: '15' },
    { name: 'Blood Coagulation Disorders', group: '15' },
    { name: 'Blood Platelet Disorders', group: '15' },
    { name: 'Blood Protein Disorders', group: '15' },
    { name: 'Bone Marrow Diseases', group: '15' },
    { name: 'Chediak-Higashi Syndrome', group: '15' },
    { name: 'Hemophilia B', group: '15' },
    { name: 'Disseminated Intravascular Coagulation', group: '15' },
    { name: 'Dysgammaglobulinemia', group: '15' },
    { name: 'Ecchymosis', group: '15' },
    { name: 'Elephantiasis', group: '15' },
    { name: 'Elliptocytosis, Hereditary', group: '15' },
    { name: 'Eosinophilia', group: '15' },
    { name: 'Factor V Deficiency', group: '15' },
    { name: 'Factor VII Deficiency', group: '15' },
    { name: 'Factor X Deficiency', group: '15' },
    { name: 'Factor XI Deficiency', group: '15' },
    { name: 'Factor XII Deficiency', group: '15' },
    { name: 'Factor XIII Deficiency', group: '15' },
    { name: 'Fanconi Anemia', group: '15' },
    { name: 'Favism', group: '15' },
    { name: 'Fetofetal Transfusion', group: '15' },
    { name: 'Fetomaternal Transfusion', group: '15' },
    { name: 'Giant Lymph Node Hyperplasia', group: '15' },
    { name: 'Glucosephosphate Dehydrogenase Deficiency', group: '15' },
    { name: 'Granuloma', group: '15' },
    { name: 'Granulomatous Disease, Chronic', group: '15' },
    { name: 'Heavy Chain Disease', group: '15' },
    { name: 'Hematologic Diseases', group: '15' },
    { name: 'Hemic and Lymphatic Diseases', group: '15' },
    { name: 'Hemoglobin C Disease', group: '15' },
    { name: 'Hemoglobin SC Disease', group: '15' },
    { name: 'Hemoglobinopathies', group: '15' },
    { name: 'Hemoglobinuria, Paroxysmal', group: '15' },
    { name: 'Hemophilia A', group: '15' },
    { name: 'Hemorrhagic Disorders', group: '15' },
    { name: 'Vitamin K Deficiency Bleeding', group: '15' },
    { name: 'Hypergammaglobulinemia', group: '15' },
    { name: 'Hypersplenism', group: '15' },
    { name: 'Hypoproteinemia', group: '15' },
    { name: 'Hypoprothrombinemias', group: '15' },
    { name: 'Immunoblastic Lymphadenopathy', group: '15' },
    { name: 'Job Syndrome', group: '15' },
    { name: 'Leukemoid Reaction', group: '15' },
    { name: 'Leukocyte Disorders', group: '15' },
    { name: 'Leukocytosis', group: '15' },
    { name: 'Leukopenia', group: '15' },
    { name: 'Lymphadenitis', group: '15' },
    { name: 'Lymphangiectasis', group: '15' },
    { name: 'Lymphangiectasis, Intestinal', group: '15' },
    { name: 'Lymphangitis', group: '15' },
    { name: 'Lymphatic Diseases', group: '15' },
    { name: 'Lymphedema', group: '15' },
    { name: 'Lymphocytosis', group: '15' },
    { name: 'Lymphopenia', group: '15' },
    { name: 'Lymphoproliferative Disorders', group: '15' },
    { name: 'Methemoglobinemia', group: '15' },
    { name: 'Monoclonal Gammopathy of Undetermined Significance', group: '15' },
    { name: 'Myelodysplastic Syndromes', group: '15' },
    { name: 'Myeloproliferative Disorders', group: '15' },
    { name: 'Neutropenia', group: '15' },
    { name: 'Pancytopenia', group: '15' },
    { name: 'Paraproteinemias', group: '15' },
    { name: 'Pelger-Huet Anomaly', group: '15' },
    { name: 'Phagocyte Bactericidal Dysfunction', group: '15' },
    { name: 'Platelet Storage Pool Deficiency', group: '15' },
    { name: 'Polycythemia', group: '15' },
    { name: 'Purpura', group: '15' },
    { name: 'Purpura, Thrombocytopenic', group: '15' },
    { name: 'Purpura, Thrombotic Thrombocytopenic', group: '15' },
    { name: 'Red-Cell Aplasia, Pure', group: '15' },
    { name: 'Sarcoidosis', group: '15' },
    { name: 'Sickle Cell Trait', group: '15' },
    { name: 'Spherocytosis, Hereditary', group: '15' },
    { name: 'Splenic Diseases', group: '15' },
    { name: 'Splenic Rupture', group: '15' },
    { name: 'Sulfhemoglobinemia', group: '15' },
    { name: 'Thalassemia', group: '15' },
    { name: 'Thrombasthenia', group: '15' },
    { name: 'Thrombocythemia, Essential', group: '15' },
    { name: 'Thrombocytopenia', group: '15' },
    { name: 'Thrombocytosis', group: '15' },
    { name: 'Thymus Hyperplasia', group: '15' },
    { name: 'Uveoparotid Fever', group: '15' },
    { name: 'Vitamin K Deficiency', group: '15' },
    { name: 'von Willebrand Diseases', group: '15' },
    { name: 'Wiskott-Aldrich Syndrome', group: '15' },
    { name: 'Xanthogranuloma, Juvenile', group: '15' },
    { name: 'Tumor Lysis Syndrome', group: '15' },
    { name: 'Leukemia, Neutrophilic, Chronic', group: '15' },
    { name: 'Histiocytosis', group: '15' },
    { name: 'Histiocytosis, Non-Langerhans-Cell', group: '15' },
    { name: 'Histiocytosis, Sinus', group: '15' },
    { name: 'Purpura, Thrombocytopenic, Idiopathic', group: '15' },
    { name: 'alpha-Thalassemia', group: '15' },
    { name: 'beta-Thalassemia', group: '15' },
    { name: 'IgA Deficiency', group: '15' },
    { name: 'IgG Deficiency', group: '15' },
    { name: 'Hypereosinophilic Syndrome', group: '15' },
    { name: 'Splenosis', group: '15' },
    { name: 'T-Lymphocytopenia, Idiopathic CD4-Positive', group: '15' },
    { name: 'Protein S Deficiency', group: '15' },
    { name: 'Anemia, Iron-Deficiency', group: '15' },
    { name: 'Leukostasis', group: '15' },
    { name: 'Pseudolymphoma', group: '15' },
    { name: 'Thrombophilia', group: '15' },
    { name: 'Activated Protein C Resistance', group: '15' },
    { name: 'Histiocytic Necrotizing Lymphadenitis', group: '15' },
    { name: 'Coagulation Protein Disorders', group: '15' },
    { name: 'Protein C Deficiency', group: '15' },
    { name: 'Antithrombin III Deficiency', group: '15' },
    { name: 'Blood Coagulation Disorders, Inherited', group: '15' },
    { name: 'Anemia, Hypoplastic, Congenital', group: '15' },
    { name: 'Anemia, Diamond-Blackfan', group: '15' },
    { name: 'Erdheim-Chester Disease', group: '15' },
    { name: 'Hypoalbuminemia', group: '15' },
    { name: 'Lymphatic Abnormalities', group: '15' },
    { name: 'Wandering Spleen', group: '15' },
    { name: 'Lymphohistiocytosis, Hemophagocytic', group: '15' },
    { name: 'Hyper-IgM Immunodeficiency Syndrome', group: '15' },
    { name: 'Hyper-IgM Immunodeficiency Syndrome, Type 1', group: '15' },
    { name: 'Thrombocytopenia, Neonatal Alloimmune', group: '15' },
    { name: 'Myelodysplastic-Myeloproliferative Diseases', group: '15' },
    { name: 'Jacobsen Distal 11q Deletion Syndrome', group: '15' },
    { name: 'delta-Thalassemia', group: '15' },
    { name: 'Gray Platelet Syndrome', group: '15' },
    { name: 'Purpura Fulminans', group: '15' },
    { name: 'Primary Myelofibrosis', group: '15' },
    { name: 'von Willebrand Disease, Type 1', group: '15' },
    { name: 'von Willebrand Disease, Type 2', group: '15' },
    { name: 'von Willebrand Disease, Type 3', group: '15' },
    { name: 'Autoimmune Lymphoproliferative Syndrome', group: '15' },
    { name: 'Thrombotic Microangiopathies', group: '15' },
    { name: 'Necrobiotic Xanthogranuloma', group: '15' },
    { name: 'Non-Filarial Lymphedema', group: '15' },
    { name: 'Chemotherapy-Induced Febrile Neutropenia', group: '15' },
    { name: 'Febrile Neutropenia', group: '15' },
    { name: 'Transfusion Reaction', group: '15' },
    { name: 'Abetalipoproteinemia', group: '16' },
    { name: 'Congenital Abnormalities', group: '16' },
    { name: 'Abnormalities, Drug-Induced', group: '16' },
    { name: 'Abnormalities, Multiple', group: '16' },
    { name: 'Abnormalities, Radiation-Induced', group: '16' },
    { name: 'Familial Multiple Lipomatosis', group: '16' },
    { name: 'Ciliopathies', group: '16' },
    { name: 'Acrodermatitis', group: '16' },
    { name: 'Alkaptonuria', group: '16' },
    { name: 'Amino Acid Metabolism, Inborn Errors', group: '16' },
    { name: 'Amniotic Band Syndrome', group: '16' },
    { name: 'Asphyxia Neonatorum', group: '16' },
    { name: 'Beckwith-Wiedemann Syndrome', group: '16' },
    { name: 'Birth Injuries', group: '16' },
    { name: 'Bloom Syndrome', group: '16' },
    { name: 'Carbohydrate Metabolism, Inborn Errors', group: '16' },
    { name: 'Colic', group: '16' },
    { name: 'Crigler-Najjar Syndrome', group: '16' },
    { name: 'Cutis Laxa', group: '16' },
    { name: 'Cystinosis', group: '16' },
    { name: 'Dermatitis, Atopic', group: '16' },
    { name: 'Ectodermal Dysplasia', group: '16' },
    { name: 'Epidermolysis Bullosa', group: '16' },
    { name: 'Fructose Intolerance', group: '16' },
    { name: 'Gilbert Disease', group: '16' },
    { name: 'Glycogen Storage Disease Type I', group: '16' },
    { name: 'Glycogen Storage Disease', group: '16' },
    { name: 'Glycogen Storage Disease Type III', group: '16' },
    { name: 'Glycogen Storage Disease Type IV', group: '16' },
    { name: 'Glycogen Storage Disease Type V', group: '16' },
    { name: 'Glycogen Storage Disease Type VI', group: '16' },
    { name: 'Glycogen Storage Disease Type VIII', group: '16' },
    { name: 'Hemochromatosis', group: '16' },
    { name: 'Hernia, Umbilical', group: '16' },
    { name: 'Hyperbilirubinemia, Hereditary', group: '16' },
    { name: 'Hyperlipoproteinemia Type II', group: '16' },
    { name: 'Hyperlipidemia, Familial Combined', group: '16' },
    { name: 'Hyperlipoproteinemia Type III', group: '16' },
    { name: 'Hyperlipoproteinemia Type IV', group: '16' },
    { name: 'Hyperlipoproteinemia Type V', group: '16' },
    { name: 'Hypobetalipoproteinemias', group: '16' },
    { name: 'Hypolipoproteinemias', group: '16' },
    { name: 'Hypophosphatasia', group: '16' },
    { name: 'Ichthyosis', group: '16' },
    { name: 'Incontinentia Pigmenti', group: '16' },
    { name: 'Infant, Newborn, Diseases', group: '16' },
    { name: 'Infant, Premature, Diseases', group: '16' },
    { name: 'Jaundice, Chronic Idiopathic', group: '16' },
    { name: 'Jaundice, Neonatal', group: '16' },
    { name: 'Darier Disease', group: '16' },
    { name: 'Keratoderma, Palmoplantar', group: '16' },
    { name: 'Lecithin Cholesterol Acyltransferase Deficiency', group: '16' },
    { name: 'Lipid Metabolism, Inborn Errors', group: '16' },
    { name: 'Mucopolysaccharidosis I', group: '16' },
    { name: 'Lipidoses', group: '16' },
    { name: 'Hyperlipoproteinemia Type I', group: '16' },
    { name: 'alpha-Mannosidosis', group: '16' },
    { name: 'Metabolism, Inborn Errors', group: '16' },
    { name: 'Metal Metabolism, Inborn Errors', group: '16' },
    { name: 'Abnormalities, Severe Teratoid', group: '16' },
    { name: 'Mucopolysaccharidoses', group: '16' },
    { name: 'Mucopolysaccharidosis III', group: '16' },
    { name: 'Mucopolysaccharidosis IV', group: '16' },
    { name: 'Mucopolysaccharidosis VI', group: '16' },
    { name: 'Multiple Carboxylase Deficiency', group: '16' },
    { name: 'Neonatal Abstinence Syndrome', group: '16' },
    { name: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', group: '16' },
    { name: 'Papillon-Lefevre Disease', group: '16' },
    { name: 'Paralysis, Obstetric', group: '16' },
    { name: 'Familial Mediterranean Fever', group: '16' },
    { name: 'Rothmund-Thomson Syndrome', group: '16' },
    { name: 'Progeria', group: '16' },
    { name: 'Prune Belly Syndrome', group: '16' },
    { name: 'Purine-Pyrimidine Metabolism, Inborn Errors', group: '16' },
    { name: 'Sclerema Neonatorum', group: '16' },
    { name: 'Situs Inversus', group: '16' },
    { name: 'Skin Abnormalities', group: '16' },
    { name: 'Skin Diseases, Genetic', group: '16' },
    { name: 'Twins, Conjoined', group: '16' },
    { name: 'Waardenburg Syndrome', group: '16' },
    { name: 'Werner Syndrome', group: '16' },
    { name: 'Cholesterol Ester Storage Disease', group: '16' },
    { name: 'Wolman Disease', group: '16' },
    { name: 'Fructose Metabolism, Inborn Errors', group: '16' },
    { name: 'Fructose-1,6-Diphosphatase Deficiency', group: '16' },
    { name: 'Pyruvate Metabolism, Inborn Errors', group: '16' },
    { name: 'Keratoderma, Palmoplantar, Diffuse', group: '16' },
    { name: 'Epidermolysis Bullosa Acquisita', group: '16' },
    { name: 'Epidermolysis Bullosa Dystrophica', group: '16' },
    { name: 'Epidermolysis Bullosa, Junctional', group: '16' },
    { name: 'Epidermolysis Bullosa Simplex', group: '16' },
    { name: 'Sjogren-Larsson Syndrome', group: '16' },
    { name: 'Ichthyosis Vulgaris', group: '16' },
    { name: 'Ichthyosiform Erythroderma, Congenital', group: '16' },
    { name: 'Ichthyosis, X-Linked', group: '16' },
    { name: 'Piebaldism', group: '16' },
    { name: 'Lysosomal Storage Diseases', group: '16' },
    { name: 'Pemphigus, Benign Familial', group: '16' },
    { name: 'Severe Combined Immunodeficiency', group: '16' },
    { name: 'Mucopolysaccharidosis VII', group: '16' },
    { name: 'Porphyria, Erythropoietic', group: '16' },
    { name: 'Hyperkeratosis, Epidermolytic', group: '16' },
    { name: 'Ichthyosis, Lamellar', group: '16' },
    { name: 'Porokeratosis', group: '16' },
    { name: 'Peroxisomal Disorders', group: '16' },
    { name: 'Congenital Disorders of Glycosylation', group: '16' },
    { name: 'Smith-Lemli-Opitz Syndrome', group: '16' },
    { name: 'Branchio-Oto-Renal Syndrome', group: '16' },
    { name: 'Xanthomatosis, Cerebrotendinous', group: '16' },
    { name: 'Port-Wine Stain', group: '16' },
    { name: 'Dyskeratosis Congenita', group: '16' },
    { name: 'Hyperhomocysteinemia', group: '16' },
    { name: 'Amino Acid Transport Disorders, Inborn', group: '16' },
    { name: 'Acatalasia', group: '16' },
    { name: 'Chromosome Disorders', group: '16' },
    { name: 'Sex Chromosome Disorders', group: '16' },
    { name: 'Amyloidosis, Familial', group: '16' },
    { name: 'Biotinidase Deficiency', group: '16' },
    { name: 'Holocarboxylase Synthetase Deficiency', group: '16' },
    { name: 'Genetic Diseases, Inborn', group: '16' },
    { name: 'Cytochrome-c Oxidase Deficiency', group: '16' },
    { name: 'Genetic Diseases, X-Linked', group: '16' },
    { name: 'Steroid Metabolism, Inborn Errors', group: '16' },
    { name: 'Mineralocorticoid Excess Syndrome, Apparent', group: '16' },
    { name: 'Mannosidase Deficiency Diseases', group: '16' },
    { name: 'beta-Mannosidosis', group: '16' },
    { name: 'Lingual Thyroid', group: '16' },
    { name: 'Lingual Goiter', group: '16' },
    { name: 'Hyperthyroxinemia, Familial Dysalbuminemic', group: '16' },
    { name: 'Thyroid Dysgenesis', group: '16' },
    { name: 'Genetic Diseases, Y-Linked', group: '16' },
    { name: 'Hyperbilirubinemia, Neonatal', group: '16' },
    { name: 'Hypoalphalipoproteinemias', group: '16' },
    { name: 'Lipodystrophy, Congenital Generalized', group: '16' },
    { name: 'Ectodermal Dysplasia 1, Anhidrotic', group: '16' },
    { name: 'Ectodermal Dysplasia 3, Anhidrotic', group: '16' },
    { name: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', group: '16' },
    { name: 'Leukokeratosis, Hereditary Mucosal', group: '16' },
    { name: 'Keratoderma, Palmoplantar, Epidermolytic', group: '16' },
    { name: 'Pachyonychia Congenita', group: '16' },
    { name: 'Ichthyosis Bullosa of Siemens', group: '16' },
    { name: 'X-Linked Combined Immunodeficiency Diseases', group: '16' },
    { name: 'Dihydropyrimidine Dehydrogenase Deficiency', group: '16' },
    { name: 'Multiple Acyl Coenzyme A Dehydrogenase Deficiency', group: '16' },
    { name: 'Trichothiodystrophy Syndromes', group: '16' },
    { name: 'Wolf-Hirschhorn Syndrome', group: '16' },
    { name: 'Aspartylglucosaminuria', group: '16' },
    { name: 'Erythrokeratodermia Variabilis', group: '16' },
    { name: 'Cryopyrin-Associated Periodic Syndromes', group: '16' },
    { name: 'Hereditary Autoinflammatory Diseases', group: '16' },
    { name: 'Yellow Nail Syndrome', group: '16' },
    { name: 'Propionic Acidemia', group: '16' },
    { name: 'Prolidase Deficiency', group: '16' },
    { name: 'Monilethrix', group: '16' },
    { name: 'Netherton Syndrome', group: '16' },
    { name: 'Hyalinosis, Systemic', group: '16' },
    { name: 'Sotos Syndrome', group: '16' },
    { name: 'CHARGE Syndrome', group: '16' },
    { name: 'Steatocystoma Multiplex', group: '16' },
    { name: 'Hernias, Diaphragmatic, Congenital', group: '16' },
    { name: 'Acantholysis', group: '17' },
    { name: 'Acanthosis Nigricans', group: '17' },
    { name: 'Acne Conglobata', group: '17' },
    { name: 'Acne Vulgaris', group: '17' },
    { name: 'Acne Keloid', group: '17' },
    { name: 'Adiposis Dolorosa', group: '17' },
    { name: 'Alopecia', group: '17' },
    { name: 'Alopecia Areata', group: '17' },
    { name: 'Mucinosis, Follicular', group: '17' },
    { name: 'Argyria', group: '17' },
    { name: 'Blister', group: '17' },
    { name: 'Breast Diseases', group: '17' },
    { name: 'Callosities', group: '17' },
    { name: 'Cholesteatoma', group: '17' },
    { name: 'Collagen Diseases', group: '17' },
    { name: 'Connective Tissue Diseases', group: '17' },
    { name: 'Pressure Ulcer', group: '17' },
    { name: 'Dermatitis', group: '17' },
    { name: 'Dermatitis, Exfoliative', group: '17' },
    { name: 'Dermatitis Herpetiformis', group: '17' },
    { name: 'Drug Eruptions', group: '17' },
    { name: 'Dermatitis, Contact', group: '17' },
    { name: 'Diaper Rash', group: '17' },
    { name: 'Eczema', group: '17' },
    { name: 'Erythema', group: '17' },
    { name: 'Erythema Multiforme', group: '17' },
    { name: 'Erythema Nodosum', group: '17' },
    { name: 'Exanthema', group: '17' },
    { name: 'Facial Dermatoses', group: '17' },
    { name: 'Fibrocystic Breast Disease', group: '17' },
    { name: 'Folliculitis', group: '17' },
    { name: 'Foot Dermatoses', group: '17' },
    { name: 'Fox-Fordyce Disease', group: '17' },
    { name: 'Gynecomastia', group: '17' },
    { name: 'Hair Diseases', group: '17' },
    { name: 'Hand Dermatoses', group: '17' },
    { name: 'Hirsutism', group: '17' },
    { name: 'Hydroa Vacciniforme', group: '17' },
    { name: 'Hyperhidrosis', group: '17' },
    { name: 'Hypertrichosis', group: '17' },
    { name: 'Hypohidrosis', group: '17' },
    { name: 'Hypotrichosis', group: '17' },
    { name: 'Immersion Foot', group: '17' },
    { name: 'Intertrigo', group: '17' },
    { name: 'Keloid', group: '17' },
    { name: 'Keratoacanthoma', group: '17' },
    { name: 'Keratosis', group: '17' },
    { name: 'Leg Dermatoses', group: '17' },
    { name: 'Leg Ulcer', group: '17' },
    { name: 'Lentigo', group: '17' },
    { name: 'Lichen Planus', group: '17' },
    { name: 'Lipodystrophy', group: '17' },
    { name: 'Lipomatosis', group: '17' },
    { name: 'Lipomatosis, Multiple Symmetrical', group: '17' },
    { name: 'Lupus Erythematosus, Cutaneous', group: '17' },
    { name: 'Lupus Erythematosus, Discoid', group: '17' },
    { name: 'Lupus Erythematosus, Systemic', group: '17' },
    { name: 'Melanosis', group: '17' },
    { name: 'Miliaria', group: '17' },
    { name: 'Mixed Connective Tissue Disease', group: '17' },
    { name: 'Myxedema', group: '17' },
    { name: 'Nail Diseases', group: '17' },
    { name: 'Nails, Ingrown', group: '17' },
    { name: 'Necrobiosis Lipoidica', group: '17' },
    { name: 'Neurodermatitis', group: '17' },
    { name: 'Dermatitis, Occupational', group: '17' },
    { name: 'Panniculitis, Nodular Nonsuppurative', group: '17' },
    { name: 'Parakeratosis', group: '17' },
    { name: 'Parapsoriasis', group: '17' },
    { name: 'Pemphigoid, Bullous', group: '17' },
    { name: 'Pemphigus', group: '17' },
    { name: 'Photosensitivity Disorders', group: '17' },
    { name: 'Pigmentation Disorders', group: '17' },
    { name: 'Pityriasis', group: '17' },
    { name: 'Pityriasis Rubra Pilaris', group: '17' },
    { name: 'Dermatitis, Toxicodendron', group: '17' },
    { name: 'Eczema, Dyshidrotic', group: '17' },
    { name: 'Prurigo', group: '17' },
    { name: 'Pruritus', group: '17' },
    { name: 'Psoriasis', group: '17' },
    { name: 'Pyoderma', group: '17' },
    { name: 'Radiodermatitis', group: '17' },
    { name: 'Rhinophyma', group: '17' },
    { name: 'Rosacea', group: '17' },
    { name: 'Scalp Dermatoses', group: '17' },
    { name: 'Scleredema Adultorum', group: '17' },
    { name: 'Scleroderma, Localized', group: '17' },
    { name: 'Scleroderma, Systemic', group: '17' },
    { name: 'Sebaceous Gland Diseases', group: '17' },
    { name: 'Dermatitis, Seborrheic', group: '17' },
    { name: 'Serum Sickness', group: '17' },
    { name: 'Skin Diseases', group: '17' },
    { name: 'Skin Diseases, Vesiculobullous', group: '17' },
    { name: 'Skin Diseases, Metabolic', group: '17' },
    { name: 'Skin Ulcer', group: '17' },
    { name: 'Sunburn', group: '17' },
    { name: 'Sweat Gland Diseases', group: '17' },
    { name: 'Urticaria', group: '17' },
    { name: 'Vitiligo', group: '17' },
    { name: 'Panniculitis', group: '17' },
    { name: 'Panniculitis, Lupus Erythematosus', group: '17' },
    { name: 'Granuloma Annulare', group: '17' },
    { name: 'Sweet Syndrome', group: '17' },
    { name: 'Foot Ulcer', group: '17' },
    { name: 'Hidradenitis', group: '17' },
    { name: 'Skin and Connective Tissue Diseases', group: '17' },
    { name: 'Necrobiotic Disorders', group: '17' },
    { name: 'Skin Diseases, Eczematous', group: '17' },
    { name: 'Skin Diseases, Papulosquamous', group: '17' },
    { name: 'Skin Diseases, Vascular', group: '17' },
    { name: 'Dermatitis, Allergic Contact', group: '17' },
    { name: 'Dermatitis, Irritant', group: '17' },
    { name: 'Dermatitis, Photoallergic', group: '17' },
    { name: 'Dermatitis, Phototoxic', group: '17' },
    { name: 'Acneiform Eruptions', group: '17' },
    { name: 'Keratosis, Seborrheic', group: '17' },
    { name: 'Hyperpigmentation', group: '17' },
    { name: 'Hypopigmentation', group: '17' },
    { name: 'Pyoderma Gangrenosum', group: '17' },
    { name: 'Lichenoid Eruptions', group: '17' },
    { name: 'Lichen Nitidus', group: '17' },
    { name: 'Pityriasis Lichenoides', group: '17' },
    { name: 'Pityriasis Rosea', group: '17' },
    { name: 'Mucinoses', group: '17' },
    { name: 'Cutaneous Fistula', group: '17' },
    { name: 'Lichen Sclerosus et Atrophicus', group: '17' },
    { name: 'Cafe-au-Lait Spots', group: '17' },
    { name: 'Dermatitis, Perioral', group: '17' },
    { name: 'Scleroderma, Diffuse', group: '17' },
    { name: 'Scleroderma, Limited', group: '17' },
    { name: 'Lipodystrophy, Familial Partial', group: '17' },
    { name: 'Scleromyxedema', group: '17' },
    { name: 'Onycholysis', group: '17' },
    { name: 'Chloracne', group: '17' },
    { name: 'Nephrogenic Fibrosing Dermopathy', group: '17' },
    { name: 'Morgellons Disease', group: '17' },
    { name: 'Acute Generalized Exanthematous Pustulosis', group: '17' },
    { name: 'Anetoderma', group: '17' },
    { name: 'Loose Anagen Hair Syndrome', group: '17' },
    { name: 'Necrolytic Migratory Erythema', group: '17' },
    { name: 'Hand-Foot Syndrome', group: '17' },
    { name: 'Linear IgA Bullous Dermatosis', group: '17' },
    { name: 'Dandruff', group: '17' },
    { name: 'Drug Hypersensitivity Syndrome', group: '17' },
    { name: 'Lipedema', group: '17' },
    { name: 'Nicolau Syndrome', group: '17' },
    { name: 'Severe Acute Malnutrition', group: '18' },
    { name: 'Obesity, Metabolically Benign', group: '18' },
    { name: 'Latent Autoimmune Diabetes in Adults', group: '18' },
    { name: 'Acid-Base Imbalance', group: '18' },
    { name: 'Acidosis', group: '18' },
    { name: 'Acidosis, Lactic', group: '18' },
    { name: 'Alkalosis', group: '18' },
    { name: 'Amyloidosis', group: '18' },
    { name: 'Ascorbic Acid Deficiency', group: '18' },
    { name: 'Avitaminosis', group: '18' },
    { name: 'Beriberi', group: '18' },
    { name: 'Calcinosis', group: '18' },
    { name: 'Calciphylaxis', group: '18' },
    { name: 'Calcium Metabolism Disorders', group: '18' },
    { name: 'Choline Deficiency', group: '18' },
    { name: 'Deficiency Diseases', group: '18' },
    { name: 'Dehydration', group: '18' },
    { name: 'Diabetes Mellitus', group: '18' },
    { name: 'Diabetes Mellitus, Experimental', group: '18' },
    { name: 'Diabetes Mellitus, Type 1', group: '18' },
    { name: 'Diabetes Mellitus, Lipoatrophic', group: '18' },
    { name: 'Diabetes Mellitus, Type 2', group: '18' },
    { name: 'Folic Acid Deficiency', group: '18' },
    { name: 'Hemosiderosis', group: '18' },
    { name: 'Hypercalcemia', group: '18' },
    { name: 'Hypercholesterolemia', group: '18' },
    { name: 'Hyperglycemia', group: '18' },
    { name: 'Hyperinsulinism', group: '18' },
    { name: 'Hyperkalemia', group: '18' },
    { name: 'Hyperlipidemias', group: '18' },
    { name: 'Hyperlipoproteinemias', group: '18' },
    { name: 'Hypernatremia', group: '18' },
    { name: 'Hypervitaminosis A', group: '18' },
    { name: 'Hypocalcemia', group: '18' },
    { name: 'Hypoglycemia', group: '18' },
    { name: 'Hypokalemia', group: '18' },
    { name: 'Hyponatremia', group: '18' },
    { name: 'Infant Nutrition Disorders', group: '18' },
    { name: 'Insulin Resistance', group: '18' },
    { name: 'Ketosis', group: '18' },
    { name: 'Kwashiorkor', group: '18' },
    { name: 'Magnesium Deficiency', group: '18' },
    { name: 'Metabolic Diseases', group: '18' },
    { name: 'Nutrition Disorders', group: '18' },
    { name: 'Nutritional and Metabolic Diseases', group: '18' },
    { name: 'Obesity', group: '18' },
    { name: 'Obesity, Morbid', group: '18' },
    { name: 'Pellagra', group: '18' },
    { name: 'Phosphorus Metabolism Disorders', group: '18' },
    { name: 'Porphyrias', group: '18' },
    { name: 'Potassium Deficiency', group: '18' },
    { name: 'Prediabetic State', group: '18' },
    { name: 'Protein Deficiency', group: '18' },
    { name: 'Protein-Energy Malnutrition', group: '18' },
    { name: 'Riboflavin Deficiency', group: '18' },
    { name: 'Starvation', group: '18' },
    { name: 'Steatitis', group: '18' },
    { name: 'Swayback', group: '18' },
    { name: 'Thiamine Deficiency', group: '18' },
    { name: 'Vitamin A Deficiency', group: '18' },
    { name: 'Vitamin B Deficiency', group: '18' },
    { name: 'Vitamin B 12 Deficiency', group: '18' },
    { name: 'Vitamin D Deficiency', group: '18' },
    { name: 'Vitamin E Deficiency', group: '18' },
    { name: 'Water Intoxication', group: '18' },
    { name: 'Water-Electrolyte Imbalance', group: '18' },
    { name: 'Xanthomatosis', group: '18' },
    { name: 'Hypertriglyceridemia', group: '18' },
    { name: 'Child Nutrition Disorders', group: '18' },
    { name: 'Diabetic Ketoacidosis', group: '18' },
    { name: 'Hypophosphatemia', group: '18' },
    { name: 'Glucose Intolerance', group: '18' },
    { name: 'Iron Metabolism Disorders', group: '18' },
    { name: 'Iron Overload', group: '18' },
    { name: 'Wasting Syndrome', group: '18' },
    { name: 'Metabolic Syndrome X', group: '18' },
    { name: 'Vitamin B 6 Deficiency', group: '18' },
    { name: 'Mitochondrial Diseases', group: '18' },
    { name: 'Malnutrition', group: '18' },
    { name: 'Overnutrition', group: '18' },
    { name: 'Glucose Metabolism Disorders', group: '18' },
    { name: 'DNA Repair-Deficiency Disorders', group: '18' },
    { name: 'Nijmegen Breakage Syndrome', group: '18' },
    { name: 'Dyslipidemias', group: '18' },
    { name: 'Monckeberg Medial Calcific Sclerosis', group: '18' },
    { name: 'Lipid Metabolism Disorders', group: '18' },
    { name: 'Hypobetalipoproteinemia, Familial, Apolipoprotein B', group: '18' },
    { name: 'Hyperphosphatemia', group: '18' },
    { name: 'Refeeding Syndrome', group: '18' },
    { name: 'Obesity, Abdominal', group: '18' },
    { name: 'Proteostasis Deficiencies', group: '18' },
    { name: 'Vascular Calcification', group: '18' },
    { name: 'Pediatric Obesity', group: '18' },
    { name: 'Hypertriglyceridemic Waist', group: '18' },
    { name: 'Hyperlactatemia', group: '18' },
    { name: 'Addison Disease', group: '19' },
    { name: 'Adrenal Cortex Diseases', group: '19' },
    { name: 'Adrenal Gland Diseases', group: '19' },
    { name: 'Adrenocortical Hyperfunction', group: '19' },
    { name: 'Adrenal Insufficiency', group: '19' },
    { name: 'Cushing Syndrome', group: '19' },
    { name: 'Diabetic Coma', group: '19' },
    { name: 'Endocrine System Diseases', group: '19' },
    { name: 'Eunuchism', group: '19' },
    { name: 'Euthyroid Sick Syndromes', group: '19' },
    { name: 'Goiter', group: '19' },
    { name: 'Goiter, Endemic', group: '19' },
    { name: 'Goiter, Nodular', group: '19' },
    { name: 'Goiter, Substernal', group: '19' },
    { name: 'Gonadal Disorders', group: '19' },
    { name: 'Hyperaldosteronism', group: '19' },
    { name: 'Hyperglycemic Hyperosmolar Nonketotic Coma', group: '19' },
    { name: 'Hyperparathyroidism', group: '19' },
    { name: 'Hyperparathyroidism, Secondary', group: '19' },
    { name: 'Hyperthyroidism', group: '19' },
    { name: 'Hyperthyroxinemia', group: '19' },
    { name: 'Hypoaldosteronism', group: '19' },
    { name: 'Hypogonadism', group: '19' },
    { name: 'Hypoparathyroidism', group: '19' },
    { name: 'Hypothyroidism', group: '19' },
    { name: 'Parathyroid Diseases', group: '19' },
    { name: 'Puberty, Delayed', group: '19' },
    { name: 'Puberty, Precocious', group: '19' },
    { name: 'Thyroid Crisis', group: '19' },
    { name: 'Thyroid Diseases', group: '19' },
    { name: 'Thyroiditis', group: '19' },
    { name: 'Thyroiditis, Autoimmune', group: '19' },
    { name: 'Thyroiditis, Subacute', group: '19' },
    { name: 'Thyrotoxicosis', group: '19' },
    { name: 'Polyendocrinopathies, Autoimmune', group: '19' },
    { name: 'Thyroid Hormone Resistance Syndrome', group: '19' },
    { name: 'Diabetes Complications', group: '19' },
    { name: 'Hyperparathyroidism, Primary', group: '19' },
    { name: 'Hashimoto Disease', group: '19' },
    { name: 'Shellfish Hypersensitivity', group: '20' },
    { name: 'Anaphylaxis', group: '20' },
    { name: 'Arthus Reaction', group: '20' },
    { name: 'Autoimmune Diseases', group: '20' },
    { name: 'Drug Hypersensitivity', group: '20' },
    { name: 'Food Hypersensitivity', group: '20' },
    { name: 'Graft vs Host Disease', group: '20' },
    { name: 'Hypersensitivity', group: '20' },
    { name: 'Hypersensitivity, Delayed', group: '20' },
    { name: 'Hypersensitivity, Immediate', group: '20' },
    { name: 'Immune Complex Diseases', group: '20' },
    { name: 'Immunologic Deficiency Syndromes', group: '20' },
    { name: 'Immune System Diseases', group: '20' },
    { name: 'Immunoproliferative Disorders', group: '20' },
    { name: 'Milk Hypersensitivity', group: '20' },
    { name: 'Antiphospholipid Syndrome', group: '20' },
    { name: 'Common Variable Immunodeficiency', group: '20' },
    { name: 'Leukocyte-Adhesion Deficiency Syndrome', group: '20' },
    { name: 'Multiple Chemical Sensitivity', group: '20' },
    { name: 'Environmental Illness', group: '20' },
    { name: 'Schnitzler Syndrome', group: '20' },
    { name: 'Latex Hypersensitivity', group: '20' },
    { name: 'Egg Hypersensitivity', group: '20' },
    { name: 'Wheat Hypersensitivity', group: '20' },
    { name: 'Peanut Hypersensitivity', group: '20' },
    { name: 'Nut Hypersensitivity', group: '20' },
    { name: 'Immune Reconstitution Inflammatory Syndrome', group: '20' },
    { name: 'Macrophage Activation Syndrome', group: '20' },
    { name: 'Disorders of Environmental Origin', group: '21' },
    { name: 'Preconception Injuries', group: '21' },
    { name: 'Animal Diseases', group: '22' },
    { name: 'Bird Diseases', group: '22' },
    { name: 'Cat Diseases', group: '22' },
    { name: 'Cattle Diseases', group: '22' },
    { name: 'Disease Models, Animal', group: '22' },
    { name: 'Dog Diseases', group: '22' },
    { name: 'Edema Disease of Swine', group: '22' },
    { name: 'Epidermitis, Exudative, of Swine', group: '22' },
    { name: 'Fish Diseases', group: '22' },
    { name: 'Foot Rot', group: '22' },
    { name: 'Hip Dysplasia, Canine', group: '22' },
    { name: 'Horse Diseases', group: '22' },
    { name: 'Lameness, Animal', group: '22' },
    { name: 'Mastitis, Bovine', group: '22' },
    { name: 'Monkey Diseases', group: '22' },
    { name: 'Muscular Dystrophy, Animal', group: '22' },
    { name: 'Parturient Paresis', group: '22' },
    { name: 'Poultry Diseases', group: '22' },
    { name: 'Rodent Diseases', group: '22' },
    { name: 'Sheep Diseases', group: '22' },
    { name: 'Swine Diseases', group: '22' },
    { name: 'White Muscle Disease', group: '22' },
    { name: 'Goat Diseases', group: '22' },
    { name: 'Primate Diseases', group: '22' },
    { name: 'Ape Diseases', group: '22' },
    { name: 'Poult Enteritis Mortality Syndrome', group: '22' },
    { name: 'White Heifer Disease', group: '22' },
    { name: 'Reticuloendotheliosis, Avian', group: '22' },
    { name: 'Symptom Flare Up', group: '23' },
    { name: 'Late Onset Disorders', group: '23' },
    { name: 'Compassion Fatigue', group: '23' },
    { name: 'Incisional Hernia', group: '23' },
    { name: 'Long Term Adverse Effects', group: '23' },
    { name: 'Alert Fatigue, Health Personnel', group: '23' },
    { name: 'Multiple Chronic Conditions', group: '23' },
    { name: 'Body Remains', group: '23' },
    { name: 'Cellulite', group: '23' },
    { name: 'Medically Unexplained Symptoms', group: '23' },
    { name: 'Margins of Excision', group: '23' },
    { name: 'Spontaneous Perforation', group: '23' },
    { name: 'Tertiary Lymphoid Structures', group: '23' },
    { name: 'Chromothripsis', group: '23' },
    { name: 'Acute Disease', group: '23' },
    { name: 'Acute-Phase Reaction', group: '23' },
    { name: 'Tissue Adhesions', group: '23' },
    { name: 'Aerophagy', group: '23' },
    { name: 'Amenorrhea', group: '23' },
    { name: 'Aneuploidy', group: '23' },
    { name: 'Anorexia', group: '23' },
    { name: 'Hypoxia', group: '23' },
    { name: 'Ascites', group: '23' },
    { name: 'Asphyxia', group: '23' },
    { name: 'Asthenia', group: '23' },
    { name: 'Atrophy', group: '23' },
    { name: 'Autolysis', group: '23' },
    { name: 'Birth Weight', group: '23' },
    { name: 'Body Temperature Changes', group: '23' },
    { name: 'Body Weight', group: '23' },
    { name: 'Body Weight Changes', group: '23' },
    { name: 'Bulimia', group: '23' },
    { name: 'Cachexia', group: '23' },
    { name: 'Cadaver', group: '23' },
    { name: 'Calculi', group: '23' },
    { name: 'Catastrophic Illness', group: '23' },
    { name: 'Choristoma', group: '23' },
    { name: 'Chromosome Aberrations', group: '23' },
    { name: 'Chromosome Deletion', group: '23' },
    { name: 'Chromosome Fragility', group: '23' },
    { name: 'Chronic Disease', group: '23' },
    { name: 'Cicatrix', group: '23' },
    { name: 'Colonic Polyps', group: '23' },
    { name: 'Constipation', group: '23' },
    { name: 'Constriction, Pathologic', group: '23' },
    { name: 'Convalescence', group: '23' },
    { name: 'Cyanosis', group: '23' },
    { name: 'Death', group: '23' },
    { name: 'Death, Sudden', group: '23' },
    { name: 'Diarrhea', group: '23' },
    { name: 'Diarrhea, Infantile', group: '23' },
    { name: 'Dilatation, Pathologic', group: '23' },
    { name: 'Disease', group: '23' },
    { name: 'Disease Susceptibility', group: '23' },
    { name: 'Diseases in Twins', group: '23' },
    { name: 'Diverticulum', group: '23' },
    { name: 'Diverticulum, Colon', group: '23' },
    { name: 'Drowning', group: '23' },
    { name: 'Dysmenorrhea', group: '23' },
    { name: 'Dyspepsia', group: '23' },
    { name: 'Edema', group: '23' },
    { name: 'Emaciation', group: '23' },
    { name: 'Emergencies', group: '23' },
    { name: 'Emphysema', group: '23' },
    { name: 'Encopresis', group: '23' },
    { name: 'Eructation', group: '23' },
    { name: 'Diverticulum, Esophageal', group: '23' },
    { name: 'Extravasation of Diagnostic and Therapeutic Materials', group: '23' },
    { name: 'Facial Asymmetry', group: '23' },
    { name: 'Failure to Thrive', group: '23' },
    { name: 'Fat Necrosis', group: '23' },
    { name: 'Fatigue', group: '23' },
    { name: 'Mental Fatigue', group: '23' },
    { name: 'Feminization', group: '23' },
    { name: 'Fetal Distress', group: '23' },
    { name: 'Fever', group: '23' },
    { name: 'Fever of Unknown Origin', group: '23' },
    { name: 'Fibrosis', group: '23' },
    { name: 'Fistula', group: '23' },
    { name: 'Flatulence', group: '23' },
    { name: 'Flushing', group: '23' },
    { name: 'Foreign-Body Reaction', group: '23' },
    { name: 'Gagging', group: '23' },
    { name: 'Gangrene', group: '23' },
    { name: 'Gliosis', group: '23' },
    { name: 'Graft Occlusion, Vascular', group: '23' },
    { name: 'Granuloma, Plasma Cell', group: '23' },
    { name: 'Growth Disorders', group: '23' },
    { name: 'Halitosis', group: '23' },
    { name: 'Heart Murmurs', group: '23' },
    { name: 'Heartburn', group: '23' },
    { name: 'Hematoma', group: '23' },
    { name: 'Hemobilia', group: '23' },
    { name: 'Hemolysis', group: '23' },
    { name: 'Hemorrhage', group: '23' },
    { name: 'Hernia', group: '23' },
    { name: 'Hernia, Diaphragmatic', group: '23' },
    { name: 'Hernia, Diaphragmatic, Traumatic', group: '23' },
    { name: 'Hernia, Femoral', group: '23' },
    { name: 'Hernia, Hiatal', group: '23' },
    { name: 'Hernia, Inguinal', group: '23' },
    { name: 'Hernia, Obturator', group: '23' },
    { name: 'Hernia, Ventral', group: '23' },
    { name: 'Hiccup', group: '23' },
    { name: 'Hyperbilirubinemia', group: '23' },
    { name: 'Hypercapnia', group: '23' },
    { name: 'Hyperphagia', group: '23' },
    { name: 'Hyperplasia', group: '23' },
    { name: 'Hypertrophy', group: '23' },
    { name: 'Hypothermia', group: '23' },
    { name: 'Iatrogenic Disease', group: '23' },
    { name: 'Infarction', group: '23' },
    { name: 'Inflammation', group: '23' },
    { name: 'Intestinal Polyps', group: '23' },
    { name: 'Intraoperative Complications', group: '23' },
    { name: 'Chromosome Inversion', group: '23' },
    { name: 'Ischemia', group: '23' },
    { name: 'Jaundice', group: '23' },
    { name: 'Malacoplakia', group: '23' },
    { name: 'Malignant Hyperthermia', group: '23' },
    { name: 'Menstruation Disturbances', group: '23' },
    { name: 'Metaplasia', group: '23' },
    { name: 'Monosomy', group: '23' },
    { name: 'Motion Sickness', group: '23' },
    { name: 'Multiple Organ Failure', group: '23' },
    { name: 'Nails, Malformed', group: '23' },
    { name: 'Nausea', group: '23' },
    { name: 'Necrosis', group: '23' },
    { name: 'Neovascularization, Pathologic', group: '23' },
    { name: 'Nerve Degeneration', group: '23' },
    { name: 'Nondisjunction, Genetic', group: '23' },
    { name: 'Ochronosis', group: '23' },
    { name: 'Oligomenorrhea', group: '23' },
    { name: 'Ossification, Heterotopic', group: '23' },
    { name: 'Pallor', group: '23' },
    { name: 'Pathologic Processes', group: '23' },
    { name: 'Philadelphia Chromosome', group: '23' },
    { name: 'Pica', group: '23' },
    { name: 'Polyploidy', group: '23' },
    { name: 'Polyps', group: '23' },
    { name: 'Postmortem Changes', group: '23' },
    { name: 'Postoperative Complications', group: '23' },
    { name: 'Premenstrual Syndrome', group: '23' },
    { name: 'Prolapse', group: '23' },
    { name: 'Prosthesis Failure', group: '23' },
    { name: 'Psychophysiologic Disorders', group: '23' },
    { name: 'Recurrence', group: '23' },
    { name: 'Remission, Spontaneous', group: '23' },
    { name: 'Respiratory Sounds', group: '23' },
    { name: 'Retrograde Degeneration', group: '23' },
    { name: 'Retroperitoneal Fibrosis', group: '23' },
    { name: 'Retropneumoperitoneum', group: '23' },
    { name: 'Rigor Mortis', group: '23' },
    { name: 'Ring Chromosomes', group: '23' },
    { name: 'Rupture, Spontaneous', group: '23' },
    { name: 'Sclerosis', group: '23' },
    { name: 'Serositis', group: '23' },
    { name: 'Sex Chromosome Aberrations', group: '23' },
    { name: 'Shock', group: '23' },
    { name: 'Shock, Hemorrhagic', group: '23' },
    { name: 'Shock, Surgical', group: '23' },
    { name: 'Shock, Traumatic', group: '23' },
    { name: 'Signs and Symptoms', group: '23' },
    { name: 'Signs and Symptoms, Digestive', group: '23' },
    { name: 'Signs and Symptoms, Respiratory', group: '23' },
    { name: 'Skin Manifestations', group: '23' },
    { name: 'Sneezing', group: '23' },
    { name: 'Snoring', group: '23' },
    { name: 'Splenomegaly', group: '23' },
    { name: 'Diverticulum, Stomach', group: '23' },
    { name: 'Subcutaneous Emphysema', group: '23' },
    { name: 'Sudden Infant Death', group: '23' },
    { name: 'Surgical Wound Dehiscence', group: '23' },
    { name: 'Pathological Conditions, Signs and Symptoms', group: '23' },
    { name: 'Syndrome', group: '23' },
    { name: 'Thinness', group: '23' },
    { name: 'Torsion Abnormality', group: '23' },
    { name: 'Translocation, Genetic', group: '23' },
    { name: 'Trisomy', group: '23' },
    { name: 'Ulcer', group: '23' },
    { name: 'Virilism', group: '23' },
    { name: 'Vomiting', group: '23' },
    { name: 'Vomiting, Anticipatory', group: '23' },
    { name: 'Wallerian Degeneration', group: '23' },
    { name: 'XYY Karyotype', group: '23' },
    { name: 'Weight Gain', group: '23' },
    { name: 'Weight Loss', group: '23' },
    { name: 'Granuloma, Foreign-Body', group: '23' },
    { name: 'Blood Loss, Surgical', group: '23' },
    { name: 'Critical Illness', group: '23' },
    { name: 'Zenker Diverticulum', group: '23' },
    { name: 'Yin Deficiency', group: '23' },
    { name: 'Yang Deficiency', group: '23' },
    { name: 'Hypocapnia', group: '23' },
    { name: 'Cicatrix, Hypertrophic', group: '23' },
    { name: 'Pelvic Pain', group: '23' },
    { name: 'Granuloma, Pyogenic', group: '23' },
    { name: 'Isochromosomes', group: '23' },
    { name: 'Disease Progression', group: '23' },
    { name: 'Space Motion Sickness', group: '23' },
    { name: 'Hyperoxia', group: '23' },
    { name: 'Sweating Sickness', group: '23' },
    { name: 'Systemic Inflammatory Response Syndrome', group: '23' },
    { name: 'Facies', group: '23' },
    { name: 'Postoperative Hemorrhage', group: '23' },
    { name: 'Chromosome Breakage', group: '23' },
    { name: 'Hot Flashes', group: '23' },
    { name: 'Aging, Premature', group: '23' },
    { name: 'Pseudophakia', group: '23' },
    { name: 'Genetic Predisposition to Disease', group: '23' },
    { name: 'Anticipation, Genetic', group: '23' },
    { name: 'Postoperative Nausea and Vomiting', group: '23' },
    { name: 'Ventricular Remodeling', group: '23' },
    { name: 'Lithiasis', group: '23' },
    { name: 'Fetal Weight', group: '23' },
    { name: 'Pathological Conditions, Anatomical', group: '23' },
    { name: 'Hypovolemia', group: '23' },
    { name: 'Urological Manifestations', group: '23' },
    { name: 'Disease Attributes', group: '23' },
    { name: 'Hyperammonemia', group: '23' },
    { name: 'Chills', group: '23' },
    { name: 'Uniparental Disomy', group: '23' },
    { name: 'Hyperuricemia', group: '23' },
    { name: 'Hyperamylasemia', group: '23' },
    { name: 'Rare Diseases', group: '23' },
    { name: 'Jaundice, Obstructive', group: '23' },
    { name: 'Genomic Instability', group: '23' },
    { name: 'Chromosomal Instability', group: '23' },
    { name: 'Reticulocytosis', group: '23' },
    { name: 'Hernia, Abdominal', group: '23' },
    { name: 'Hematoma, Subdural, Spinal', group: '23' },
    { name: 'Hematoma, Epidural, Spinal', group: '23' },
    { name: 'Micronuclei, Chromosome-Defective', group: '23' },
    { name: 'Seroma', group: '23' },
    { name: 'Leukoaraiosis', group: '23' },
    { name: 'Overweight', group: '23' },
    { name: 'Mobility Limitation', group: '23' },
    { name: 'Delayed Graft Function', group: '23' },
    { name: 'Nocturia', group: '23' },
    { name: 'Dysuria', group: '23' },
    { name: 'Channelopathies', group: '23' },
    { name: 'Prostatism', group: '23' },
    { name: 'Hypercalciuria', group: '23' },
    { name: 'Urinoma', group: '23' },
    { name: 'DNA Degradation, Necrotic', group: '23' },
    { name: 'Microsatellite Instability', group: '23' },
    { name: 'Systolic Murmurs', group: '23' },
    { name: 'Delayed Emergence from Anesthesia', group: '23' },
    { name: 'Corpse Dismemberment', group: '23' },
    { name: 'Airway Remodeling', group: '23' },
    { name: 'Pelvic Organ Prolapse', group: '23' },
    { name: 'Anastomotic Leak', group: '23' },
    { name: 'Triploidy', group: '23' },
    { name: 'Tetraploidy', group: '23' },
    { name: 'Striae Distensae', group: '23' },
    { name: 'Implant Capsular Contracture', group: '23' },
    { name: 'Neglected Diseases', group: '23' },
    { name: 'Asymptomatic Diseases', group: '23' },
    { name: 'Plaque, Amyloid', group: '23' },
    { name: 'Plaque, Atherosclerotic', group: '23' },
    { name: 'Asymptomatic Infections', group: '23' },
    { name: 'Neointima', group: '23' },
    { name: 'Accessory Atrioventricular Bundle', group: '23' },
    { name: 'Tetrasomy', group: '23' },
    { name: 'Chromosome Duplication', group: '23' },
    { name: 'Exsanguination', group: '23' },
    { name: 'Intraoperative Awareness', group: '23' },
    { name: 'Lower Urinary Tract Symptoms', group: '23' },
    { name: 'Polydipsia', group: '23' },
    { name: 'Polydipsia, Psychogenic', group: '23' },
    { name: 'Abnormal Karyotype', group: '23' },
    { name: 'Disease Resistance', group: '23' },
    { name: 'Prodromal Symptoms', group: '23' },
    { name: 'Parental Death', group: '23' },
    { name: 'Atrial Remodeling', group: '23' },
    { name: 'Teratogenesis', group: '23' },
    { name: 'Dysbiosis', group: '23' },
    { name: 'Morphological and Microscopic Findings', group: '23' },
    { name: 'Premenstrual Dysphoric Disorder', group: '23' },
    { name: 'Infant Death', group: '23' },
    { name: 'Cardiotoxicity', group: '23' },
    { name: 'Vascular Remodeling', group: '23' },
    { name: 'Protein Aggregation, Pathological', group: '23' },
    { name: 'Agricultural Workers\' Diseases', group: '24' },
    { name: 'Burnout, Professional', group: '24' },
    { name: 'Inert Gas Narcosis', group: '24' },
    { name: 'Occupational Diseases', group: '24' },
    { name: 'Persian Gulf Syndrome', group: '24' },
    { name: 'Propofol Infusion Syndrome', group: '25' },
    { name: 'Alcoholic Intoxication', group: '25' },
    { name: 'Alcoholism', group: '25' },
    { name: 'Spider Bites', group: '25' },
    { name: 'Bites and Stings', group: '25' },
    { name: 'Cadmium Poisoning', group: '25' },
    { name: 'Marijuana Abuse', group: '25' },
    { name: 'Carbon Monoxide Poisoning', group: '25' },
    { name: 'Carbon Tetrachloride Poisoning', group: '25' },
    { name: 'Ergotism', group: '25' },
    { name: 'Fluoride Poisoning', group: '25' },
    { name: 'Foodborne Diseases', group: '25' },
    { name: 'Gas Poisoning', group: '25' },
    { name: 'Heroin Dependence', group: '25' },
    { name: 'Insect Bites and Stings', group: '25' },
    { name: 'Lathyrism', group: '25' },
    { name: 'Lead Poisoning', group: '25' },
    { name: 'Mercury Poisoning', group: '25' },
    { name: 'Morphine Dependence', group: '25' },
    { name: 'Mushroom Poisoning', group: '25' },
    { name: 'Opioid-Related Disorders', group: '25' },
    { name: 'Phencyclidine Abuse', group: '25' },
    { name: 'Plant Poisoning', group: '25' },
    { name: 'Poisoning', group: '25' },
    { name: 'Psychoses, Alcoholic', group: '25' },
    { name: 'Psychoses, Substance-Induced', group: '25' },
    { name: 'Snake Bites', group: '25' },
    { name: 'Substance Withdrawal Syndrome', group: '25' },
    { name: 'Tick Paralysis', group: '25' },
    { name: 'Tick Toxicoses', group: '25' },
    { name: 'Tobacco Use Disorder', group: '25' },
    { name: 'Mycotoxicosis', group: '25' },
    { name: 'Substance Abuse, Intravenous', group: '25' },
    { name: 'Substance-Related Disorders', group: '25' },
    { name: 'Amphetamine-Related Disorders', group: '25' },
    { name: 'Cocaine-Related Disorders', group: '25' },
    { name: 'Alcohol-Related Disorders', group: '25' },
    { name: 'Serotonin Syndrome', group: '25' },
    { name: 'Alcohol-Induced Disorders', group: '25' },
    { name: 'Ciguatera Poisoning', group: '25' },
    { name: 'Shellfish Poisoning', group: '25' },
    { name: 'Inhalant Abuse', group: '25' },
    { name: 'Organophosphate Poisoning', group: '25' },
    { name: 'Drug Overdose', group: '25' },
    { name: 'Binge Drinking', group: '25' },
    { name: 'Chemically-Induced Disorders', group: '25' },
    { name: 'Drug-Related Side Effects and Adverse Reactions', group: '25' },
    { name: 'Anticholinergic Syndrome', group: '25' },
    { name: 'Tick Bites', group: '25' },
    { name: 'Scorpion Stings', group: '25' },
    { name: 'Metabolic Side Effects of Drugs and Substances', group: '25' },
    { name: 'Abdominal Injuries', group: '26' },
    { name: 'Cold Injury', group: '26' },
    { name: 'War-Related Injuries', group: '26' },
    { name: 'Fractures, Multiple', group: '26' },
    { name: 'Degloving Injuries', group: '26' },
    { name: 'Anterior Cruciate Ligament Injuries', group: '26' },
    { name: 'Shoulder Injuries', group: '26' },
    { name: 'Tibial Meniscus Injuries', group: '26' },
    { name: 'Microtrauma, Physical', group: '26' },
    { name: 'Rotator Cuff Injuries', group: '26' },
    { name: 'Bankart Lesions', group: '26' },
    { name: 'Fractures, Avulsion', group: '26' },
    { name: 'Crush Injuries', group: '26' },
    { name: 'Myocardial Contusions', group: '26' },
    { name: 'Surgical Wound', group: '26' },
    { name: 'Amputation, Traumatic', group: '26' },
    { name: 'Arm Injuries', group: '26' },
    { name: 'Athletic Injuries', group: '26' },
    { name: 'Barotrauma', group: '26' },
    { name: 'Battered Child Syndrome', group: '26' },
    { name: 'Bezoars', group: '26' },
    { name: 'Bites, Human', group: '26' },
    { name: 'Blast Injuries', group: '26' },
    { name: 'Burns', group: '26' },
    { name: 'Burns, Chemical', group: '26' },
    { name: 'Burns, Electric', group: '26' },
    { name: 'Burns, Inhalation', group: '26' },
    { name: 'Chilblains', group: '26' },
    { name: 'Contusions', group: '26' },
    { name: 'Crush Syndrome', group: '26' },
    { name: 'Decompression Sickness', group: '26' },
    { name: 'Electric Injuries', group: '26' },
    { name: 'Femoral Fractures', group: '26' },
    { name: 'Femoral Neck Fractures', group: '26' },
    { name: 'Finger Injuries', group: '26' },
    { name: 'Flail Chest', group: '26' },
    { name: 'Forearm Injuries', group: '26' },
    { name: 'Foreign Bodies', group: '26' },
    { name: 'Foreign-Body Migration', group: '26' },
    { name: 'Fractures, Closed', group: '26' },
    { name: 'Fractures, Open', group: '26' },
    { name: 'Fractures, Spontaneous', group: '26' },
    { name: 'Fractures, Ununited', group: '26' },
    { name: 'Frostbite', group: '26' },
    { name: 'Hand Injuries', group: '26' },
    { name: 'Heart Injuries', group: '26' },
    { name: 'Heat Exhaustion', group: '26' },
    { name: 'Hip Fractures', group: '26' },
    { name: 'Humeral Fractures', group: '26' },
    { name: 'Knee Injuries', group: '26' },
    { name: 'Leg Injuries', group: '26' },
    { name: 'Multiple Trauma', group: '26' },
    { name: 'Osteoradionecrosis', group: '26' },
    { name: 'Pseudarthrosis', group: '26' },
    { name: 'Radiation Injuries', group: '26' },
    { name: 'Radiation Injuries, Experimental', group: '26' },
    { name: 'Radius Fractures', group: '26' },
    { name: 'Cumulative Trauma Disorders', group: '26' },
    { name: 'Rib Fractures', group: '26' },
    { name: 'Rupture', group: '26' },
    { name: 'Self Mutilation', group: '26' },
    { name: 'Shoulder Fractures', group: '26' },
    { name: 'Spinal Injuries', group: '26' },
    { name: 'Sprains and Strains', group: '26' },
    { name: 'Sunstroke', group: '26' },
    { name: 'Tendon Injuries', group: '26' },
    { name: 'Thoracic Injuries', group: '26' },
    { name: 'Tibial Fractures', group: '26' },
    { name: 'Ulna Fractures', group: '26' },
    { name: 'Whiplash Injuries', group: '26' },
    { name: 'Wounds and Injuries', group: '26' },
    { name: 'Wounds, Gunshot', group: '26' },
    { name: 'Wounds, Nonpenetrating', group: '26' },
    { name: 'Wounds, Penetrating', group: '26' },
    { name: 'Wounds, Stab', group: '26' },
    { name: 'Wrist Injuries', group: '26' },
    { name: 'Lightning Injuries', group: '26' },
    { name: 'Smoke Inhalation Injury', group: '26' },
    { name: 'Near Drowning', group: '26' },
    { name: 'Fractures, Stress', group: '26' },
    { name: 'Spinal Fractures', group: '26' },
    { name: 'Ankle Injuries', group: '26' },
    { name: 'Needlestick Injuries', group: '26' },
    { name: 'Soft Tissue Injuries', group: '26' },
    { name: 'Fractures, Malunited', group: '26' },
    { name: 'Foot Injuries', group: '26' },
    { name: 'Fractures, Comminuted', group: '26' },
    { name: 'Heat Stress Disorders', group: '26' },
    { name: 'Heat Stroke', group: '26' },
    { name: 'Back Injuries', group: '26' },
    { name: 'Neck Injuries', group: '26' },
    { name: 'Lacerations', group: '26' },
    { name: 'Hip Injuries', group: '26' },
    { name: 'Decapitation', group: '26' },
    { name: 'Fractures, Bone', group: '26' },
    { name: 'Fractures, Cartilage', group: '26' },
    { name: 'Fractures, Compression', group: '26' },
    { name: 'Acute Radiation Syndrome', group: '26' },
    { name: 'Periprosthetic Fractures', group: '26' },
    { name: 'Intra-Articular Fractures', group: '26' },
    { name: 'Conducted Energy Weapon Injuries', group: '26' },
    { name: 'Intrauterine Device Migration', group: '26' },
    { name: 'Iliotibial Band Syndrome', group: '26' },
    { name: 'Osteoporotic Fractures', group: '26' },
    { name: 'Occupational Injuries', group: '26' },
    { name: 'Ankle Fractures', group: '26' },
    { name: 'Psychological Trauma', group: '27' },
    { name: 'Social Communication Disorder', group: '27' },
    { name: 'Childhood-Onset Fluency Disorder', group: '27' },
    { name: 'Specific Learning Disorder', group: '27' },
    { name: 'Hoarding Disorder', group: '27' },
    { name: 'Autism Spectrum Disorder', group: '27' },
    { name: 'Motor Disorders', group: '27' },
    { name: 'Trauma and Stressor Related Disorders', group: '27' },
    { name: 'Bipolar and Related Disorders', group: '27' },
    { name: 'Gender Dysphoria', group: '27' },
    { name: 'Phobia, Social', group: '27' },
    { name: 'Adjustment Disorders', group: '27' },
    { name: 'Affective Disorders, Psychotic', group: '27' },
    { name: 'Agoraphobia', group: '27' },
    { name: 'Anorexia Nervosa', group: '27' },
    { name: 'Antisocial Personality Disorder', group: '27' },
    { name: 'Anxiety Disorders', group: '27' },
    { name: 'Anxiety, Separation', group: '27' },
    { name: 'Feeding and Eating Disorders', group: '27' },
    { name: 'Attention Deficit Disorder with Hyperactivity', group: '27' },
    { name: 'Autistic Disorder', group: '27' },
    { name: 'Mental Disorders', group: '27' },
    { name: 'Bipolar Disorder', group: '27' },
    { name: 'Borderline Personality Disorder', group: '27' },
    { name: 'Capgras Syndrome', group: '27' },
    { name: 'Child Behavior Disorders', group: '27' },
    { name: 'Developmental Disabilities', group: '27' },
    { name: 'Child Development Disorders, Pervasive', group: '27' },
    { name: 'Cognition Disorders', group: '27' },
    { name: 'Combat Disorders', group: '27' },
    { name: 'Compulsive Personality Disorder', group: '27' },
    { name: 'Conversion Disorder', group: '27' },
    { name: 'Cyclothymic Disorder', group: '27' },
    { name: 'Dependent Personality Disorder', group: '27' },
    { name: 'Depressive Disorder, Major', group: '27' },
    { name: 'Depressive Disorder', group: '27' },
    { name: 'Dissociative Disorders', group: '27' },
    { name: 'Exhibitionism', group: '27' },
    { name: 'Factitious Disorders', group: '27' },
    { name: 'Fetishism (Psychiatric)', group: '27' },
    { name: 'Firesetting Behavior', group: '27' },
    { name: 'Gambling', group: '27' },
    { name: 'Histrionic Personality Disorder', group: '27' },
    { name: 'Hypochondriasis', group: '27' },
    { name: 'Hysteria', group: '27' },
    { name: 'Disruptive, Impulse Control, and Conduct Disorders', group: '27' },
    { name: 'Masochism', group: '27' },
    { name: 'Multiple Personality Disorder', group: '27' },
    { name: 'Munchausen Syndrome', group: '27' },
    { name: 'Neurasthenia', group: '27' },
    { name: 'Neurocirculatory Asthenia', group: '27' },
    { name: 'Neurotic Disorders', group: '27' },
    { name: 'Obsessive-Compulsive Disorder', group: '27' },
    { name: 'Paranoid Disorders', group: '27' },
    { name: 'Paranoid Personality Disorder', group: '27' },
    { name: 'Paraphilic Disorders', group: '27' },
    { name: 'Passive-Aggressive Personality Disorder', group: '27' },
    { name: 'Pedophilia', group: '27' },
    { name: 'Personality Disorders', group: '27' },
    { name: 'Phobic Disorders', group: '27' },
    { name: 'Psychotic Disorders', group: '27' },
    { name: 'Sadism', group: '27' },
    { name: 'Schizoid Personality Disorder', group: '27' },
    { name: 'Schizophrenia', group: '27' },
    { name: 'Schizophrenia, Catatonic', group: '27' },
    { name: 'Schizophrenia, Childhood', group: '27' },
    { name: 'Schizophrenia, Disorganized', group: '27' },
    { name: 'Schizophrenia, Paranoid', group: '27' },
    { name: 'Schizotypal Personality Disorder', group: '27' },
    { name: 'Shared Paranoid Disorder', group: '27' },
    { name: 'Somatoform Disorders', group: '27' },
    { name: 'Stress Disorders, Post-Traumatic', group: '27' },
    { name: 'Transvestism', group: '27' },
    { name: 'Trichotillomania', group: '27' },
    { name: 'Voyeurism', group: '27' },
    { name: 'Seasonal Affective Disorder', group: '27' },
    { name: 'Panic Disorder', group: '27' },
    { name: 'Munchausen Syndrome by Proxy', group: '27' },
    { name: 'Dysthymic Disorder', group: '27' },
    { name: 'Conduct Disorder', group: '27' },
    { name: 'Stereotypic Movement Disorder', group: '27' },
    { name: 'Motor Skills Disorders', group: '27' },
    { name: 'Attention Deficit and Disruptive Behavior Disorders', group: '27' },
    { name: 'Feeding and Eating Disorders of Childhood', group: '27' },
    { name: 'Elimination Disorders', group: '27' },
    { name: 'Reactive Attachment Disorder', group: '27' },
    { name: 'Mood Disorders', group: '27' },
    { name: 'Neurocognitive Disorders', group: '27' },
    { name: 'Schizophrenia Spectrum and Other Psychotic Disorders', group: '27' },
    { name: 'Sexual and Gender Disorders', group: '27' },
    { name: 'Sexual Dysfunctions, Psychological', group: '27' },
    { name: 'Asperger Syndrome', group: '27' },
    { name: 'Stress Disorders, Traumatic, Acute', group: '27' },
    { name: 'Stress Disorders, Traumatic', group: '27' },
    { name: 'Bulimia Nervosa', group: '27' },
    { name: 'Binge-Eating Disorder', group: '27' },
    { name: 'Body Dysmorphic Disorders', group: '27' },
    { name: 'Cognitive Dysfunction', group: '27' },
    { name: 'Depressive Disorder, Treatment-Resistant', group: '27' },
    { name: 'Delusional Parasitosis', group: '27' },
    { name: 'Neurodevelopmental Disorders', group: '27' },
    { name: 'Speech Sound Disorder', group: '27' },
    { name: 'All diseases', group: '27' }
  ],
  links: [
    { target: 'Bacteremia', source: 'Bacterial Infections', value: '11' },
    { target: 'Central Nervous System Bacterial Infections', source: 'Bacterial Infections', value: '11' },
    { target: 'Endocarditis, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Eye Infections, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Fournier Gangrene', source: 'Bacterial Infections', value: '11' },
    { target: 'Gram-Negative Bacterial Infections', source: 'Bacterial Infections', value: '11' },
    { target: 'Gram-Positive Bacterial Infections', source: 'Bacterial Infections', value: '11' },
    { target: 'Pneumonia, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Sexually Transmitted Diseases, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Skin Diseases, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Spirochaetales Infections', source: 'Bacterial Infections', value: '11' },
    { target: 'Vaginosis, Bacterial', source: 'Bacterial Infections', value: '11' },
    { target: 'Hemorrhagic Septicemia', source: 'Bacteremia', value: '15' },
    { target: 'Lyme Neuroborreliosis', source: 'Central Nervous System Bacterial Infections', value: '15' },
    { target: 'Meningitis, Bacterial', source: 'Central Nervous System Bacterial Infections', value: '15' },
    { target: 'Neurosyphilis', source: 'Central Nervous System Bacterial Infections', value: '15' },
    { target: 'Meningitis, Escherichia coli', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Haemophilus', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Listeria', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Meningococcal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Pneumococcal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Tuberculosis, Meningeal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Meningitis, Meningococcal', value: '23' },
    { target: 'Endocarditis, Subacute Bacterial', source: 'Endocarditis, Bacterial', value: '15' },
    { target: 'Conjunctivitis, Bacterial', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Hordeolum', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Tuberculosis, Ocular', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Conjunctivitis, Inclusion', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Ophthalmia Neonatorum', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Trachoma', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '19' },
    { target: 'Aliivibrio Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Anaplasmataceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Bacteroidaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Bartonellaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Bordetella Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Borrelia Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Brucellosis', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Burkholderia Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Campylobacter Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Cat-Scratch Disease', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Chlamydiaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Cytophagaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Desulfovibrionaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Enterobacteriaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Flavobacteriaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Fusobacteriaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Helicobacter Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Legionellosis', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Leptospirosis', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Moraxellaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Mycoplasmatales Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Neisseriaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Pasteurellaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Piscirickettsiaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Pseudomonas Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Q Fever', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Rat-Bite Fever', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Rickettsiaceae Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Tick-Borne Diseases', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Treponemal Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Tularemia', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Vibrio Infections', source: 'Gram-Negative Bacterial Infections', value: '15' },
    { target: 'Anaplasmosis', source: 'Anaplasmataceae Infections', value: '19' },
    { target: 'Ehrlichiosis', source: 'Anaplasmataceae Infections', value: '19' },
    { target: 'Heartwater Disease', source: 'Ehrlichiosis', value: '23' },
    { target: 'Bacteroides Infections', source: 'Bacteroidaceae Infections', value: '19' },
    { target: 'Bartonella Infections', source: 'Bartonellaceae Infections', value: '19' },
    { target: 'Angiomatosis, Bacillary', source: 'Bartonella Infections', value: '23' },
    { target: 'Cat-Scratch Disease', source: 'Bartonella Infections', value: '23' },
    { target: 'Trench Fever', source: 'Bartonella Infections', value: '23' },
    { target: 'Whooping Cough', source: 'Bordetella Infections', value: '19' },
    { target: 'Lyme Disease', source: 'Borrelia Infections', value: '19' },
    { target: 'Relapsing Fever', source: 'Borrelia Infections', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Lyme Disease', value: '23' },
    { target: 'Lyme Neuroborreliosis', source: 'Lyme Disease', value: '23' },
    { target: 'Brucellosis, Bovine', source: 'Brucellosis', value: '19' },
    { target: 'Glanders', source: 'Burkholderia Infections', value: '19' },
    { target: 'Melioidosis', source: 'Burkholderia Infections', value: '19' },
    { target: 'Chlamydia Infections', source: 'Chlamydiaceae Infections', value: '19' },
    { target: 'Chlamydophila Infections', source: 'Chlamydiaceae Infections', value: '19' },
    { target: 'Chlamydial Pneumonia', source: 'Chlamydia Infections', value: '23' },
    { target: 'Conjunctivitis, Inclusion', source: 'Chlamydia Infections', value: '23' },
    { target: 'Lymphogranuloma Venereum', source: 'Chlamydia Infections', value: '23' },
    { target: 'Trachoma', source: 'Chlamydia Infections', value: '23' },
    { target: 'Chlamydial Pneumonia', source: 'Chlamydophila Infections', value: '23' },
    { target: 'Psittacosis', source: 'Chlamydophila Infections', value: '23' },
    { target: 'Dysentery, Bacillary', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Escherichia coli Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Granuloma Inguinale', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Klebsiella Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Proteus Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Salmonella Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Serratia Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Yersinia Infections', source: 'Enterobacteriaceae Infections', value: '19' },
    { target: 'Meningitis, Escherichia coli', source: 'Escherichia coli Infections', value: '23' },
    { target: 'Rhinoscleroma', source: 'Klebsiella Infections', value: '23' },
    { target: 'Paratyphoid Fever', source: 'Salmonella Infections', value: '23' },
    { target: 'Salmonella Food Poisoning', source: 'Salmonella Infections', value: '23' },
    { target: 'Salmonella Infections, Animal', source: 'Salmonella Infections', value: '23' },
    { target: 'Typhoid Fever', source: 'Salmonella Infections', value: '23' },
    { target: 'Plague', source: 'Yersinia Infections', value: '23' },
    { target: 'Yersinia pseudotuberculosis Infections', source: 'Yersinia Infections', value: '23' },
    { target: 'Fusobacterium Infections', source: 'Fusobacteriaceae Infections', value: '19' },
    { target: 'Gingivitis, Necrotizing Ulcerative', source: 'Fusobacterium Infections', value: '23' },
    { target: 'Lemierre Syndrome', source: 'Fusobacterium Infections', value: '23' },
    { target: 'Legionnaires\' Disease', source: 'Legionellosis', value: '19' },
    { target: 'Weil Disease', source: 'Leptospirosis', value: '19' },
    { target: 'Acinetobacter Infections', source: 'Moraxellaceae Infections', value: '19' },
    { target: 'Mycoplasma Infections', source: 'Mycoplasmatales Infections', value: '19' },
    { target: 'Ureaplasma Infections', source: 'Mycoplasmatales Infections', value: '19' },
    { target: 'Pleuropneumonia, Contagious', source: 'Mycoplasma Infections', value: '23' },
    { target: 'Pneumonia, Mycoplasma', source: 'Mycoplasma Infections', value: '23' },
    { target: 'Gonorrhea', source: 'Neisseriaceae Infections', value: '19' },
    { target: 'Meningococcal Infections', source: 'Neisseriaceae Infections', value: '19' },
    { target: 'Ophthalmia Neonatorum', source: 'Gonorrhea', value: '23' },
    { target: 'Meningitis, Meningococcal', source: 'Meningococcal Infections', value: '23' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Meningitis, Meningococcal', value: '27' },
    { target: 'Actinobacillus Infections', source: 'Pasteurellaceae Infections', value: '19' },
    { target: 'Haemophilus Infections', source: 'Pasteurellaceae Infections', value: '19' },
    { target: 'Pasteurella Infections', source: 'Pasteurellaceae Infections', value: '19' },
    { target: 'Pasteurellosis, Pneumonic', source: 'Pasteurellaceae Infections', value: '19' },
    { target: 'Actinobacillosis', source: 'Actinobacillus Infections', value: '23' },
    { target: 'Chancroid', source: 'Haemophilus Infections', value: '23' },
    { target: 'Meningitis, Haemophilus', source: 'Haemophilus Infections', value: '23' },
    { target: 'Hemorrhagic Septicemia', source: 'Pasteurella Infections', value: '23' },
    { target: 'Pneumonia, Rickettsial', source: 'Rickettsiaceae Infections', value: '19' },
    { target: 'Rickettsia Infections', source: 'Rickettsiaceae Infections', value: '19' },
    { target: 'Scrub Typhus', source: 'Rickettsiaceae Infections', value: '19' },
    { target: 'Boutonneuse Fever', source: 'Rickettsia Infections', value: '23' },
    { target: 'Rocky Mountain Spotted Fever', source: 'Rickettsia Infections', value: '23' },
    { target: 'Typhus, Endemic Flea-Borne', source: 'Rickettsia Infections', value: '23' },
    { target: 'Typhus, Epidemic Louse-Borne', source: 'Rickettsia Infections', value: '23' },
    { target: 'Anaplasmosis', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Boutonneuse Fever', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Ehrlichiosis', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Heartwater Disease', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Lyme Disease', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Relapsing Fever', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Rocky Mountain Spotted Fever', source: 'Tick-Borne Diseases', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Lyme Disease', value: '23' },
    { target: 'Lyme Neuroborreliosis', source: 'Lyme Disease', value: '23' },
    { target: 'Pinta', source: 'Treponemal Infections', value: '19' },
    { target: 'Syphilis', source: 'Treponemal Infections', value: '19' },
    { target: 'Yaws', source: 'Treponemal Infections', value: '19' },
    { target: 'Chancre', source: 'Syphilis', value: '23' },
    { target: 'Neurosyphilis', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Cardiovascular', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Congenital', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Cutaneous', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Latent', source: 'Syphilis', value: '23' },
    { target: 'Tabes Dorsalis', source: 'Neurosyphilis', value: '27' },
    { target: 'Cholera', source: 'Vibrio Infections', value: '19' },
    { target: 'Actinomycetales Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Bacillaceae Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Bifidobacteriales Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Clostridium Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Erysipelothrix Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Listeriosis', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Staphylococcal Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Streptococcal Infections', source: 'Gram-Positive Bacterial Infections', value: '15' },
    { target: 'Actinomycosis', source: 'Actinomycetales Infections', value: '19' },
    { target: 'Corynebacterium Infections', source: 'Actinomycetales Infections', value: '19' },
    { target: 'Mycobacterium Infections', source: 'Actinomycetales Infections', value: '19' },
    { target: 'Nocardia Infections', source: 'Actinomycetales Infections', value: '19' },
    { target: 'Actinomycosis, Cervicofacial', source: 'Actinomycosis', value: '23' },
    { target: 'Whipple Disease', source: 'Actinomycosis', value: '23' },
    { target: 'Diphtheria', source: 'Corynebacterium Infections', value: '23' },
    { target: 'Erythrasma', source: 'Corynebacterium Infections', value: '23' },
    { target: 'Leprosy', source: 'Mycobacterium Infections', value: '23' },
    { target: 'Mycobacterium Infections, Nontuberculous', source: 'Mycobacterium Infections', value: '23' },
    { target: 'Paratuberculosis', source: 'Mycobacterium Infections', value: '23' },
    { target: 'Tuberculosis', source: 'Mycobacterium Infections', value: '23' },
    { target: 'Leprosy, Multibacillary', source: 'Leprosy', value: '27' },
    { target: 'Leprosy, Paucibacillary', source: 'Leprosy', value: '27' },
    { target: 'Leprosy, Lepromatous', source: 'Leprosy, Multibacillary', value: '31' },
    { target: 'Leprosy, Borderline', source: 'Leprosy, Paucibacillary', value: '31' },
    { target: 'Leprosy, Tuberculoid', source: 'Leprosy, Paucibacillary', value: '31' },
    { target: 'Buruli Ulcer', source: 'Mycobacterium Infections, Nontuberculous', value: '27' },
    { target: 'Mycobacterium avium-intracellulare Infection', source: 'Mycobacterium Infections, Nontuberculous', value: '27' },
    { target: 'Latent Tuberculosis', source: 'Tuberculosis', value: '27' },
    { target: 'Peritonitis, Tuberculous', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculoma', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Avian', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Bovine', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Cardiovascular', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Central Nervous System', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Cutaneous', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Endocrine', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Gastrointestinal', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Hepatic', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Laryngeal', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Lymph Node', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Miliary', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Multidrug-Resistant', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Ocular', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Oral', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Osteoarticular', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Pleural', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Pulmonary', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Splenic', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculosis, Urogenital', source: 'Tuberculosis', value: '27' },
    { target: 'Tuberculoma, Intracranial', source: 'Tuberculoma', value: '31' },
    { target: 'Pericarditis, Tuberculous', source: 'Tuberculosis, Cardiovascular', value: '31' },
    { target: 'Tuberculoma, Intracranial', source: 'Tuberculosis, Central Nervous System', value: '31' },
    { target: 'Tuberculosis, Meningeal', source: 'Tuberculosis, Central Nervous System', value: '31' },
    { target: 'Erythema Induratum', source: 'Tuberculosis, Cutaneous', value: '31' },
    { target: 'Lupus Vulgaris', source: 'Tuberculosis, Cutaneous', value: '31' },
    { target: 'King\'s Evil', source: 'Tuberculosis, Lymph Node', value: '31' },
    { target: 'Extensively Drug-Resistant Tuberculosis', source: 'Tuberculosis, Multidrug-Resistant', value: '31' },
    { target: 'Tuberculosis, Spinal', source: 'Tuberculosis, Osteoarticular', value: '31' },
    { target: 'Empyema, Tuberculous', source: 'Tuberculosis, Pleural', value: '31' },
    { target: 'Silicotuberculosis', source: 'Tuberculosis, Pulmonary', value: '31' },
    { target: 'Tuberculosis, Female Genital', source: 'Tuberculosis, Urogenital', value: '31' },
    { target: 'Tuberculosis, Male Genital', source: 'Tuberculosis, Urogenital', value: '31' },
    { target: 'Tuberculosis, Renal', source: 'Tuberculosis, Urogenital', value: '31' },
    { target: 'Mycetoma', source: 'Nocardia Infections', value: '23' },
    { target: 'Anthrax', source: 'Bacillaceae Infections', value: '19' },
    { target: 'Botulism', source: 'Clostridium Infections', value: '19' },
    { target: 'Enterocolitis, Pseudomembranous', source: 'Clostridium Infections', value: '19' },
    { target: 'Enterotoxemia', source: 'Clostridium Infections', value: '19' },
    { target: 'Gas Gangrene', source: 'Clostridium Infections', value: '19' },
    { target: 'Tetanus', source: 'Clostridium Infections', value: '19' },
    { target: 'Erysipeloid', source: 'Erysipelothrix Infections', value: '19' },
    { target: 'Swine Erysipelas', source: 'Erysipelothrix Infections', value: '19' },
    { target: 'Meningitis, Listeria', source: 'Listeriosis', value: '19' },
    { target: 'Pneumonia, Staphylococcal', source: 'Staphylococcal Infections', value: '19' },
    { target: 'Staphylococcal Food Poisoning', source: 'Staphylococcal Infections', value: '19' },
    { target: 'Staphylococcal Skin Infections', source: 'Staphylococcal Infections', value: '19' },
    { target: 'Furunculosis', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Impetigo', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Staphylococcal Scalded Skin Syndrome', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Carbuncle', source: 'Furunculosis', value: '27' },
    { target: 'Ecthyma', source: 'Streptococcal Infections', value: '19' },
    { target: 'Endocarditis, Subacute Bacterial', source: 'Streptococcal Infections', value: '19' },
    { target: 'Erysipelas', source: 'Streptococcal Infections', value: '19' },
    { target: 'Impetigo', source: 'Streptococcal Infections', value: '19' },
    { target: 'Pneumococcal Infections', source: 'Streptococcal Infections', value: '19' },
    { target: 'Rheumatic Fever', source: 'Streptococcal Infections', value: '19' },
    { target: 'Scarlet Fever', source: 'Streptococcal Infections', value: '19' },
    { target: 'Meningitis, Pneumococcal', source: 'Pneumococcal Infections', value: '23' },
    { target: 'Pneumonia, Pneumococcal', source: 'Pneumococcal Infections', value: '23' },
    { target: 'Rheumatic Heart Disease', source: 'Rheumatic Fever', value: '23' },
    { target: 'Chlamydial Pneumonia', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia, Mycoplasma', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia of Calves, Enzootic', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia of Swine, Mycoplasmal', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia, Pneumococcal', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia, Rickettsial', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Pneumonia, Staphylococcal', source: 'Pneumonia, Bacterial', value: '15' },
    { target: 'Chancroid', source: 'Sexually Transmitted Diseases, Bacterial', value: '15' },
    { target: 'Chlamydia Infections', source: 'Sexually Transmitted Diseases, Bacterial', value: '15' },
    { target: 'Gonorrhea', source: 'Sexually Transmitted Diseases, Bacterial', value: '15' },
    { target: 'Granuloma Inguinale', source: 'Sexually Transmitted Diseases, Bacterial', value: '15' },
    { target: 'Syphilis', source: 'Sexually Transmitted Diseases, Bacterial', value: '15' },
    { target: 'Lymphogranuloma Venereum', source: 'Chlamydia Infections', value: '19' },
    { target: 'Actinomycosis, Cervicofacial', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Angiomatosis, Bacillary', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Digital Dermatitis', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Ecthyma', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Erysipelas', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Erythema Chronicum Migrans', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Erythrasma', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Fasciitis, Necrotizing', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Granuloma Inguinale', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Hidradenitis Suppurativa', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Mycetoma', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Pinta', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Rhinoscleroma', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Staphylococcal Skin Infections', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Syphilis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Tuberculosis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Yaws', source: 'Skin Diseases, Bacterial', value: '15' },
    { target: 'Furunculosis', source: 'Staphylococcal Skin Infections', value: '19' },
    { target: 'Impetigo', source: 'Staphylococcal Skin Infections', value: '19' },
    { target: 'Staphylococcal Scalded Skin Syndrome', source: 'Staphylococcal Skin Infections', value: '19' },
    { target: 'Carbuncle', source: 'Furunculosis', value: '23' },
    { target: 'Erythema Induratum', source: 'Tuberculosis, Cutaneous', value: '19' },
    { target: 'Lupus Vulgaris', source: 'Tuberculosis, Cutaneous', value: '19' },
    { target: 'Borrelia Infections', source: 'Spirochaetales Infections', value: '15' },
    { target: 'Leptospirosis', source: 'Spirochaetales Infections', value: '15' },
    { target: 'Treponemal Infections', source: 'Spirochaetales Infections', value: '15' },
    { target: 'Lyme Disease', source: 'Borrelia Infections', value: '19' },
    { target: 'Relapsing Fever', source: 'Borrelia Infections', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Lyme Disease', value: '23' },
    { target: 'Lyme Neuroborreliosis', source: 'Lyme Disease', value: '23' },
    { target: 'Weil Disease', source: 'Leptospirosis', value: '19' },
    { target: 'Pinta', source: 'Treponemal Infections', value: '19' },
    { target: 'Syphilis', source: 'Treponemal Infections', value: '19' },
    { target: 'Yaws', source: 'Treponemal Infections', value: '19' },
    { target: 'Chancre', source: 'Syphilis', value: '23' },
    { target: 'Neurosyphilis', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Cardiovascular', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Congenital', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Cutaneous', source: 'Syphilis', value: '23' },
    { target: 'Syphilis, Latent', source: 'Syphilis', value: '23' },
    { target: 'Tabes Dorsalis', source: 'Neurosyphilis', value: '27' },
    { target: 'Brain Abscess', source: 'Central Nervous System Infections', value: '11' },
    { target: 'Central Nervous System Bacterial Infections', source: 'Central Nervous System Infections', value: '11' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Brain Abscess', value: '15' },
    { target: 'Aneurysm, Infected', source: 'Infection', value: '11' },
    { target: 'Arthritis, Infectious', source: 'Infection', value: '11' },
    { target: 'Bone Diseases, Infectious', source: 'Infection', value: '11' },
    { target: 'Cardiovascular Infections', source: 'Infection', value: '11' },
    { target: 'Catheter-Related Infections', source: 'Infection', value: '11' },
    { target: 'Coinfection', source: 'Infection', value: '11' },
    { target: 'Communicable Diseases', source: 'Infection', value: '11' },
    { target: 'Community-Acquired Infections', source: 'Infection', value: '11' },
    { target: 'Cross Infection', source: 'Infection', value: '11' },
    { target: 'Eye Infections', source: 'Infection', value: '11' },
    { target: 'Focal Infection', source: 'Infection', value: '11' },
    { target: 'Gingivitis, Necrotizing Ulcerative', source: 'Infection', value: '11' },
    { target: 'Intraabdominal Infections', source: 'Infection', value: '11' },
    { target: 'Laboratory Infection', source: 'Infection', value: '11' },
    { target: 'Ludwig\'s Angina', source: 'Infection', value: '11' },
    { target: 'Opportunistic Infections', source: 'Infection', value: '11' },
    { target: 'Pelvic Infection', source: 'Infection', value: '11' },
    { target: 'Pregnancy Complications, Infectious', source: 'Infection', value: '11' },
    { target: 'Prosthesis-Related Infections', source: 'Infection', value: '11' },
    { target: 'Reproductive Tract Infections', source: 'Infection', value: '11' },
    { target: 'Respiratory Tract Infections', source: 'Infection', value: '11' },
    { target: 'Sepsis', source: 'Infection', value: '11' },
    { target: 'Sexually Transmitted Diseases', source: 'Infection', value: '11' },
    { target: 'Skin Diseases, Infectious', source: 'Infection', value: '11' },
    { target: 'Soft Tissue Infections', source: 'Infection', value: '11' },
    { target: 'Suppuration', source: 'Infection', value: '11' },
    { target: 'Toxemia', source: 'Infection', value: '11' },
    { target: 'Urinary Tract Infections', source: 'Infection', value: '11' },
    { target: 'Wound Infection', source: 'Infection', value: '11' },
    { target: 'Arthritis, Reactive', source: 'Arthritis, Infectious', value: '15' },
    { target: 'Osteomyelitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Periostitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Spondylitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Tuberculosis, Osteoarticular', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Mastoiditis', source: 'Osteomyelitis', value: '19' },
    { target: 'Petrositis', source: 'Osteomyelitis', value: '19' },
    { target: 'Pott Puffy Tumor', source: 'Osteomyelitis', value: '19' },
    { target: 'Discitis', source: 'Spondylitis', value: '19' },
    { target: 'Tuberculosis, Spinal', source: 'Tuberculosis, Osteoarticular', value: '19' },
    { target: 'Endocarditis, Bacterial', source: 'Cardiovascular Infections', value: '15' },
    { target: 'Syphilis, Cardiovascular', source: 'Cardiovascular Infections', value: '15' },
    { target: 'Tuberculosis, Cardiovascular', source: 'Cardiovascular Infections', value: '15' },
    { target: 'Endocarditis, Subacute Bacterial', source: 'Endocarditis, Bacterial', value: '19' },
    { target: 'Pericarditis, Tuberculous', source: 'Tuberculosis, Cardiovascular', value: '19' },
    { target: 'Communicable Diseases, Emerging', source: 'Communicable Diseases', value: '15' },
    { target: 'Waterborne Diseases', source: 'Communicable Diseases', value: '15' },
    { target: 'Pneumonia, Ventilator-Associated', source: 'Cross Infection', value: '15' },
    { target: 'Corneal Ulcer', source: 'Eye Infections', value: '15' },
    { target: 'Endophthalmitis', source: 'Eye Infections', value: '15' },
    { target: 'Eye Infections, Bacterial', source: 'Eye Infections', value: '15' },
    { target: 'Eye Infections, Fungal', source: 'Eye Infections', value: '15' },
    { target: 'Uveitis, Suppurative', source: 'Endophthalmitis', value: '19' },
    { target: 'Conjunctivitis, Bacterial', source: 'Eye Infections, Bacterial', value: '19' },
    { target: 'Hordeolum', source: 'Eye Infections, Bacterial', value: '19' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Eye Infections, Bacterial', value: '19' },
    { target: 'Tuberculosis, Ocular', source: 'Eye Infections, Bacterial', value: '19' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Bacterial', value: '19' },
    { target: 'Conjunctivitis, Inclusion', source: 'Conjunctivitis, Bacterial', value: '23' },
    { target: 'Ophthalmia Neonatorum', source: 'Conjunctivitis, Bacterial', value: '23' },
    { target: 'Trachoma', source: 'Conjunctivitis, Bacterial', value: '23' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '23' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Fungal', value: '19' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '23' },
    { target: 'Focal Infection, Dental', source: 'Focal Infection', value: '15' },
    { target: 'Appendicitis', source: 'Intraabdominal Infections', value: '15' },
    { target: 'Diverticulitis', source: 'Intraabdominal Infections', value: '15' },
    { target: 'Peritonitis', source: 'Intraabdominal Infections', value: '15' },
    { target: 'Typhlitis', source: 'Intraabdominal Infections', value: '15' },
    { target: 'Peritonitis, Tuberculous', source: 'Peritonitis', value: '19' },
    { target: 'Subphrenic Abscess', source: 'Peritonitis', value: '19' },
    { target: 'AIDS-Related Opportunistic Infections', source: 'Opportunistic Infections', value: '15' },
    { target: 'Superinfection', source: 'Opportunistic Infections', value: '15' },
    { target: 'Pelvic Inflammatory Disease', source: 'Pelvic Infection', value: '15' },
    { target: 'Abortion, Septic', source: 'Pregnancy Complications, Infectious', value: '15' },
    { target: 'Puerperal Infection', source: 'Pregnancy Complications, Infectious', value: '15' },
    { target: 'Empyema, Pleural', source: 'Respiratory Tract Infections', value: '15' },
    { target: 'Whooping Cough', source: 'Respiratory Tract Infections', value: '15' },
    { target: 'Empyema, Tuberculous', source: 'Empyema, Pleural', value: '19' },
    { target: 'Bacteremia', source: 'Sepsis', value: '15' },
    { target: 'Fungemia', source: 'Sepsis', value: '15' },
    { target: 'Neonatal Sepsis', source: 'Sepsis', value: '15' },
    { target: 'Shock, Septic', source: 'Sepsis', value: '15' },
    { target: 'Endotoxemia', source: 'Bacteremia', value: '19' },
    { target: 'Hemorrhagic Septicemia', source: 'Bacteremia', value: '19' },
    { target: 'Candidemia', source: 'Fungemia', value: '19' },
    { target: 'Sexually Transmitted Diseases, Bacterial', source: 'Sexually Transmitted Diseases', value: '15' },
    { target: 'Chancroid', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Chlamydia Infections', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Gonorrhea', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Granuloma Inguinale', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Syphilis', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Lymphogranuloma Venereum', source: 'Chlamydia Infections', value: '23' },
    { target: 'Cellulitis', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Dermatomycoses', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Paronychia', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Skin Diseases, Bacterial', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Blastomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Candidiasis, Chronic Mucocutaneous', source: 'Dermatomycoses', value: '19' },
    { target: 'Candidiasis, Cutaneous', source: 'Dermatomycoses', value: '19' },
    { target: 'Chromoblastomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Hyalohyphomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Lobomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Mycetoma', source: 'Dermatomycoses', value: '19' },
    { target: 'Sporotrichosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Tinea', source: 'Dermatomycoses', value: '19' },
    { target: 'Tinea Versicolor', source: 'Dermatomycoses', value: '19' },
    { target: 'Alternariosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Cerebral Phaeohyphomycosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Fusariosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Onychomycosis', source: 'Tinea', value: '23' },
    { target: 'Tinea Capitis', source: 'Tinea', value: '23' },
    { target: 'Tinea Pedis', source: 'Tinea', value: '23' },
    { target: 'Tinea Favosa', source: 'Tinea Capitis', value: '27' },
    { target: 'Actinomycosis, Cervicofacial', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Angiomatosis, Bacillary', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Digital Dermatitis', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Ecthyma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erysipelas', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erythrasma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Granuloma Inguinale', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Hidradenitis Suppurativa', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Mycetoma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Pinta', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Rhinoscleroma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Staphylococcal Skin Infections', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Syphilis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Tuberculosis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Yaws', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Furunculosis', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Impetigo', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Staphylococcal Scalded Skin Syndrome', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Carbuncle', source: 'Furunculosis', value: '27' },
    { target: 'Erythema Induratum', source: 'Tuberculosis, Cutaneous', value: '23' },
    { target: 'Lupus Vulgaris', source: 'Tuberculosis, Cutaneous', value: '23' },
    { target: 'Abscess', source: 'Suppuration', value: '15' },
    { target: 'Cellulitis', source: 'Suppuration', value: '15' },
    { target: 'Empyema', source: 'Suppuration', value: '15' },
    { target: 'Hidradenitis Suppurativa', source: 'Suppuration', value: '15' },
    { target: 'Otitis Media, Suppurative', source: 'Suppuration', value: '15' },
    { target: 'Pyomyositis', source: 'Suppuration', value: '15' },
    { target: 'Thyroiditis, Suppurative', source: 'Suppuration', value: '15' },
    { target: 'Uveitis, Suppurative', source: 'Suppuration', value: '15' },
    { target: 'Abdominal Abscess', source: 'Abscess', value: '19' },
    { target: 'Brain Abscess', source: 'Abscess', value: '19' },
    { target: 'Epidural Abscess', source: 'Abscess', value: '19' },
    { target: 'Lung Abscess', source: 'Abscess', value: '19' },
    { target: 'Periapical Abscess', source: 'Abscess', value: '19' },
    { target: 'Periodontal Abscess', source: 'Abscess', value: '19' },
    { target: 'Peritonsillar Abscess', source: 'Abscess', value: '19' },
    { target: 'Psoas Abscess', source: 'Abscess', value: '19' },
    { target: 'Retropharyngeal Abscess', source: 'Abscess', value: '19' },
    { target: 'Liver Abscess', source: 'Abdominal Abscess', value: '23' },
    { target: 'Subphrenic Abscess', source: 'Abdominal Abscess', value: '23' },
    { target: 'Liver Abscess, Amebic', source: 'Liver Abscess', value: '27' },
    { target: 'Liver Abscess, Pyogenic', source: 'Liver Abscess', value: '27' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Brain Abscess', value: '23' },
    { target: 'Empyema, Pleural', source: 'Empyema', value: '19' },
    { target: 'Empyema, Subdural', source: 'Empyema', value: '19' },
    { target: 'Empyema, Tuberculous', source: 'Empyema, Pleural', value: '23' },
    { target: 'Endotoxemia', source: 'Toxemia', value: '15' },
    { target: 'Bacteriuria', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Pyuria', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Schistosomiasis haematobia', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Surgical Wound Infection', source: 'Wound Infection', value: '15' },
    { target: 'Aspergillosis', source: 'Mycoses', value: '11' },
    { target: 'Candidiasis', source: 'Mycoses', value: '11' },
    { target: 'Central Nervous System Fungal Infections', source: 'Mycoses', value: '11' },
    { target: 'Coccidioidomycosis', source: 'Mycoses', value: '11' },
    { target: 'Cryptococcosis', source: 'Mycoses', value: '11' },
    { target: 'Dermatomycoses', source: 'Mycoses', value: '11' },
    { target: 'Eye Infections, Fungal', source: 'Mycoses', value: '11' },
    { target: 'Geotrichosis', source: 'Mycoses', value: '11' },
    { target: 'Histoplasmosis', source: 'Mycoses', value: '11' },
    { target: 'Invasive Fungal Infections', source: 'Mycoses', value: '11' },
    { target: 'Lung Diseases, Fungal', source: 'Mycoses', value: '11' },
    { target: 'Microsporidiosis', source: 'Mycoses', value: '11' },
    { target: 'Otomycosis', source: 'Mycoses', value: '11' },
    { target: 'Paracoccidioidomycosis', source: 'Mycoses', value: '11' },
    { target: 'Phaeohyphomycosis', source: 'Mycoses', value: '11' },
    { target: 'Piedra', source: 'Mycoses', value: '11' },
    { target: 'Pneumocystis Infections', source: 'Mycoses', value: '11' },
    { target: 'Trichosporonosis', source: 'Mycoses', value: '11' },
    { target: 'Zygomycosis', source: 'Mycoses', value: '11' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Aspergillosis', value: '15' },
    { target: 'Neuroaspergillosis', source: 'Aspergillosis', value: '15' },
    { target: 'Pulmonary Aspergillosis', source: 'Aspergillosis', value: '15' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Pulmonary Aspergillosis', value: '19' },
    { target: 'Invasive Pulmonary Aspergillosis', source: 'Pulmonary Aspergillosis', value: '19' },
    { target: 'Candidiasis, Chronic Mucocutaneous', source: 'Candidiasis', value: '15' },
    { target: 'Candidiasis, Cutaneous', source: 'Candidiasis', value: '15' },
    { target: 'Candidiasis, Invasive', source: 'Candidiasis', value: '15' },
    { target: 'Candidiasis, Oral', source: 'Candidiasis', value: '15' },
    { target: 'Candidiasis, Vulvovaginal', source: 'Candidiasis', value: '15' },
    { target: 'Candidemia', source: 'Candidiasis, Invasive', value: '19' },
    { target: 'Meningitis, Fungal', source: 'Central Nervous System Fungal Infections', value: '15' },
    { target: 'Meningitis, Cryptococcal', source: 'Meningitis, Fungal', value: '19' },
    { target: 'Meningitis, Cryptococcal', source: 'Cryptococcosis', value: '15' },
    { target: 'Blastomycosis', source: 'Dermatomycoses', value: '15' },
    { target: 'Candidiasis, Chronic Mucocutaneous', source: 'Dermatomycoses', value: '15' },
    { target: 'Candidiasis, Cutaneous', source: 'Dermatomycoses', value: '15' },
    { target: 'Chromoblastomycosis', source: 'Dermatomycoses', value: '15' },
    { target: 'Hyalohyphomycosis', source: 'Dermatomycoses', value: '15' },
    { target: 'Lobomycosis', source: 'Dermatomycoses', value: '15' },
    { target: 'Mycetoma', source: 'Dermatomycoses', value: '15' },
    { target: 'Sporotrichosis', source: 'Dermatomycoses', value: '15' },
    { target: 'Tinea', source: 'Dermatomycoses', value: '15' },
    { target: 'Tinea Versicolor', source: 'Dermatomycoses', value: '15' },
    { target: 'Alternariosis', source: 'Hyalohyphomycosis', value: '19' },
    { target: 'Aspergillosis', source: 'Hyalohyphomycosis', value: '19' },
    { target: 'Cerebral Phaeohyphomycosis', source: 'Hyalohyphomycosis', value: '19' },
    { target: 'Fusariosis', source: 'Hyalohyphomycosis', value: '19' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Aspergillosis', value: '23' },
    { target: 'Neuroaspergillosis', source: 'Aspergillosis', value: '23' },
    { target: 'Onychomycosis', source: 'Tinea', value: '19' },
    { target: 'Tinea Capitis', source: 'Tinea', value: '19' },
    { target: 'Tinea Pedis', source: 'Tinea', value: '19' },
    { target: 'Tinea Favosa', source: 'Tinea Capitis', value: '23' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Fungal', value: '15' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '19' },
    { target: 'Candidiasis, Invasive', source: 'Invasive Fungal Infections', value: '15' },
    { target: 'Fungemia', source: 'Invasive Fungal Infections', value: '15' },
    { target: 'Invasive Pulmonary Aspergillosis', source: 'Invasive Fungal Infections', value: '15' },
    { target: 'Neuroaspergillosis', source: 'Invasive Fungal Infections', value: '15' },
    { target: 'Candidemia', source: 'Candidiasis, Invasive', value: '19' },
    { target: 'Candidemia', source: 'Fungemia', value: '19' },
    { target: 'Aspergillosis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Blastomycosis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Aspergillosis', value: '19' },
    { target: 'Neuroaspergillosis', source: 'Aspergillosis', value: '19' },
    { target: 'Encephalitozoonosis', source: 'Microsporidiosis', value: '15' },
    { target: 'Cerebral Phaeohyphomycosis', source: 'Phaeohyphomycosis', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Pneumocystis Infections', value: '15' },
    { target: 'Mucormycosis', source: 'Zygomycosis', value: '15' },
    { target: 'African Horse Sickness', source: 'Arbovirus Infections', value: '11' },
    { target: 'Bluetongue', source: 'Arbovirus Infections', value: '11' },
    { target: 'Dengue', source: 'Arbovirus Infections', value: '11' },
    { target: 'Encephalitis, Arbovirus', source: 'Arbovirus Infections', value: '11' },
    { target: 'Encephalomyelitis, Equine', source: 'Arbovirus Infections', value: '11' },
    { target: 'Phlebotomus Fever', source: 'Arbovirus Infections', value: '11' },
    { target: 'Rift Valley Fever', source: 'Arbovirus Infections', value: '11' },
    { target: 'Tick-Borne Diseases', source: 'Arbovirus Infections', value: '11' },
    { target: 'Yellow Fever', source: 'Arbovirus Infections', value: '11' },
    { target: 'Zika Virus Infection', source: 'Arbovirus Infections', value: '11' },
    { target: 'Severe Dengue', source: 'Dengue', value: '15' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'African Swine Fever', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Colorado Tick Fever', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Encephalitis, Tick-Borne', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Hemorrhagic Fever, Crimean', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Hemorrhagic Fever, Omsk', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Kyasanur Forest Disease', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Nairobi Sheep Disease', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Encephalitis, Viral', source: 'Central Nervous System Viral Diseases', value: '11' },
    { target: 'Meningitis, Viral', source: 'Central Nervous System Viral Diseases', value: '11' },
    { target: 'Pseudorabies', source: 'Central Nervous System Viral Diseases', value: '11' },
    { target: 'Encephalitis, Arbovirus', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Encephalitis, Viral', value: '15' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '19' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '19' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '19' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '19' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '19' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '19' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '19' },
    { target: 'Lymphocytic Choriomeningitis', source: 'Meningitis, Viral', value: '15' },
    { target: 'Adenoviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'African Swine Fever', source: 'DNA Virus Infections', value: '11' },
    { target: 'Circoviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Hepadnaviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Herpesviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Papillomavirus Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Parvoviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Polyomavirus Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Poxviridae Infections', source: 'DNA Virus Infections', value: '11' },
    { target: 'Adenovirus Infections, Human', source: 'Adenoviridae Infections', value: '15' },
    { target: 'Hepatitis, Infectious Canine', source: 'Adenoviridae Infections', value: '15' },
    { target: 'Porcine Postweaning Multisystemic Wasting Syndrome', source: 'Circoviridae Infections', value: '15' },
    { target: 'Hepatitis B', source: 'Hepadnaviridae Infections', value: '15' },
    { target: 'Hepatitis B, Chronic', source: 'Hepatitis B', value: '19' },
    { target: 'Bell Palsy', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Chickenpox', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Cytomegalovirus Infections', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Epstein-Barr Virus Infections', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Herpes Simplex', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Herpes Zoster', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Infectious Bovine Rhinotracheitis', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Malignant Catarrh', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Marek Disease', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Pseudorabies', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Roseolovirus Infections', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Sarcoma, Kaposi', source: 'Herpesviridae Infections', value: '15' },
    { target: 'Cytomegalovirus Retinitis', source: 'Cytomegalovirus Infections', value: '19' },
    { target: 'Burkitt Lymphoma', source: 'Epstein-Barr Virus Infections', value: '19' },
    { target: 'Infectious Mononucleosis', source: 'Epstein-Barr Virus Infections', value: '19' },
    { target: 'Leukoplakia, Hairy', source: 'Epstein-Barr Virus Infections', value: '19' },
    { target: 'Herpes Genitalis', source: 'Herpes Simplex', value: '19' },
    { target: 'Herpes Labialis', source: 'Herpes Simplex', value: '19' },
    { target: 'Kaposi Varicelliform Eruption', source: 'Herpes Simplex', value: '19' },
    { target: 'Keratitis, Herpetic', source: 'Herpes Simplex', value: '19' },
    { target: 'Stomatitis, Herpetic', source: 'Herpes Simplex', value: '19' },
    { target: 'Keratitis, Dendritic', source: 'Keratitis, Herpetic', value: '23' },
    { target: 'Herpes Zoster Ophthalmicus', source: 'Herpes Zoster', value: '19' },
    { target: 'Herpes Zoster Oticus', source: 'Herpes Zoster', value: '19' },
    { target: 'Zoster Sine Herpete', source: 'Herpes Zoster', value: '19' },
    { target: 'Exanthema Subitum', source: 'Roseolovirus Infections', value: '19' },
    { target: 'Warts', source: 'Papillomavirus Infections', value: '15' },
    { target: 'Condylomata Acuminata', source: 'Warts', value: '19' },
    { target: 'Epidermodysplasia Verruciformis', source: 'Warts', value: '19' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Condylomata Acuminata', value: '23' },
    { target: 'Aleutian Mink Disease', source: 'Parvoviridae Infections', value: '15' },
    { target: 'Erythema Infectiosum', source: 'Parvoviridae Infections', value: '15' },
    { target: 'Feline Panleukopenia', source: 'Parvoviridae Infections', value: '15' },
    { target: 'Mink Viral Enteritis', source: 'Parvoviridae Infections', value: '15' },
    { target: 'Carcinoma, Merkel Cell', source: 'Polyomavirus Infections', value: '15' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Polyomavirus Infections', value: '15' },
    { target: 'Cowpox', source: 'Poxviridae Infections', value: '15' },
    { target: 'Ecthyma, Contagious', source: 'Poxviridae Infections', value: '15' },
    { target: 'Ectromelia, Infectious', source: 'Poxviridae Infections', value: '15' },
    { target: 'Fowlpox', source: 'Poxviridae Infections', value: '15' },
    { target: 'Lumpy Skin Disease', source: 'Poxviridae Infections', value: '15' },
    { target: 'Molluscum Contagiosum', source: 'Poxviridae Infections', value: '15' },
    { target: 'Monkeypox', source: 'Poxviridae Infections', value: '15' },
    { target: 'Myxomatosis, Infectious', source: 'Poxviridae Infections', value: '15' },
    { target: 'Smallpox', source: 'Poxviridae Infections', value: '15' },
    { target: 'Vaccinia', source: 'Poxviridae Infections', value: '15' },
    { target: 'Encephalitis, Arbovirus', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Encephalitis, Viral', value: '11' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '15' },
    { target: 'Conjunctivitis, Viral', source: 'Eye Infections, Viral', value: '11' },
    { target: 'Cytomegalovirus Retinitis', source: 'Eye Infections, Viral', value: '11' },
    { target: 'Herpes Zoster Ophthalmicus', source: 'Eye Infections, Viral', value: '11' },
    { target: 'Keratitis, Herpetic', source: 'Eye Infections, Viral', value: '11' },
    { target: 'Conjunctivitis, Acute Hemorrhagic', source: 'Conjunctivitis, Viral', value: '15' },
    { target: 'Keratitis, Dendritic', source: 'Keratitis, Herpetic', value: '15' },
    { target: 'Hepatitis, Infectious Canine', source: 'Hepatitis, Viral, Animal', value: '11' },
    { target: 'Rift Valley Fever', source: 'Hepatitis, Viral, Animal', value: '11' },
    { target: 'Hepatitis A', source: 'Hepatitis, Viral, Human', value: '11' },
    { target: 'Hepatitis B', source: 'Hepatitis, Viral, Human', value: '11' },
    { target: 'Hepatitis C', source: 'Hepatitis, Viral, Human', value: '11' },
    { target: 'Hepatitis D', source: 'Hepatitis, Viral, Human', value: '11' },
    { target: 'Hepatitis E', source: 'Hepatitis, Viral, Human', value: '11' },
    { target: 'Hepatitis B, Chronic', source: 'Hepatitis B', value: '15' },
    { target: 'Hepatitis C, Chronic', source: 'Hepatitis C', value: '15' },
    { target: 'Hepatitis D, Chronic', source: 'Hepatitis D', value: '15' },
    { target: 'AIDS-Related Opportunistic Infections', source: 'Opportunistic Infections', value: '11' },
    { target: 'Superinfection', source: 'Opportunistic Infections', value: '11' },
    { target: 'Arenaviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Astroviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Birnaviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Bunyaviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Caliciviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Encephalitis, Arbovirus', source: 'RNA Virus Infections', value: '11' },
    { target: 'Flaviviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Hemorrhagic Fevers, Viral', source: 'RNA Virus Infections', value: '11' },
    { target: 'Hepatitis D', source: 'RNA Virus Infections', value: '11' },
    { target: 'Hepatitis E', source: 'RNA Virus Infections', value: '11' },
    { target: 'Mononegavirales Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Nidovirales Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Orthomyxoviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Picornaviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Reoviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Retroviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Togaviridae Infections', source: 'RNA Virus Infections', value: '11' },
    { target: 'Hemorrhagic Fever, American', source: 'Arenaviridae Infections', value: '15' },
    { target: 'Lassa Fever', source: 'Arenaviridae Infections', value: '15' },
    { target: 'Lymphocytic Choriomeningitis', source: 'Arenaviridae Infections', value: '15' },
    { target: 'Encephalitis, California', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Hantavirus Infections', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Hemorrhagic Fever, Crimean', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Nairobi Sheep Disease', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Phlebotomus Fever', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Rift Valley Fever', source: 'Bunyaviridae Infections', value: '15' },
    { target: 'Hantavirus Pulmonary Syndrome', source: 'Hantavirus Infections', value: '19' },
    { target: 'Hemorrhagic Fever with Renal Syndrome', source: 'Hantavirus Infections', value: '19' },
    { target: 'Vesicular Exanthema of Swine', source: 'Caliciviridae Infections', value: '15' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '15' },
    { target: 'Flavivirus Infections', source: 'Flaviviridae Infections', value: '15' },
    { target: 'Hepatitis C', source: 'Flaviviridae Infections', value: '15' },
    { target: 'Pestivirus Infections', source: 'Flaviviridae Infections', value: '15' },
    { target: 'Dengue', source: 'Flavivirus Infections', value: '19' },
    { target: 'Encephalitis, Japanese', source: 'Flavivirus Infections', value: '19' },
    { target: 'Encephalitis, St. Louis', source: 'Flavivirus Infections', value: '19' },
    { target: 'Encephalitis, Tick-Borne', source: 'Flavivirus Infections', value: '19' },
    { target: 'Hemorrhagic Fever, Omsk', source: 'Flavivirus Infections', value: '19' },
    { target: 'Kyasanur Forest Disease', source: 'Flavivirus Infections', value: '19' },
    { target: 'Louping Ill', source: 'Flavivirus Infections', value: '19' },
    { target: 'West Nile Fever', source: 'Flavivirus Infections', value: '19' },
    { target: 'Yellow Fever', source: 'Flavivirus Infections', value: '19' },
    { target: 'Zika Virus Infection', source: 'Flavivirus Infections', value: '19' },
    { target: 'Severe Dengue', source: 'Dengue', value: '23' },
    { target: 'Hepatitis C, Chronic', source: 'Hepatitis C', value: '19' },
    { target: 'Border Disease', source: 'Pestivirus Infections', value: '19' },
    { target: 'Bovine Virus Diarrhea-Mucosal Disease', source: 'Pestivirus Infections', value: '19' },
    { target: 'Classical Swine Fever', source: 'Pestivirus Infections', value: '19' },
    { target: 'Hemorrhagic Syndrome, Bovine', source: 'Pestivirus Infections', value: '19' },
    { target: 'Dengue', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Hemorrhagic Fever, American', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Hemorrhagic Fever, Crimean', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Hemorrhagic Fever, Ebola', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Hemorrhagic Fever, Omsk', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Hemorrhagic Fever with Renal Syndrome', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Kyasanur Forest Disease', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Lassa Fever', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Marburg Virus Disease', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Rift Valley Fever', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Yellow Fever', source: 'Hemorrhagic Fevers, Viral', value: '15' },
    { target: 'Severe Dengue', source: 'Dengue', value: '19' },
    { target: 'Hepatitis D, Chronic', source: 'Hepatitis D', value: '15' },
    { target: 'Borna Disease', source: 'Mononegavirales Infections', value: '15' },
    { target: 'Filoviridae Infections', source: 'Mononegavirales Infections', value: '15' },
    { target: 'Paramyxoviridae Infections', source: 'Mononegavirales Infections', value: '15' },
    { target: 'Rhabdoviridae Infections', source: 'Mononegavirales Infections', value: '15' },
    { target: 'Hemorrhagic Fever, Ebola', source: 'Filoviridae Infections', value: '19' },
    { target: 'Marburg Virus Disease', source: 'Filoviridae Infections', value: '19' },
    { target: 'Avulavirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Henipavirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Morbillivirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Respirovirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Pneumovirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Rubulavirus Infections', source: 'Paramyxoviridae Infections', value: '19' },
    { target: 'Newcastle Disease', source: 'Avulavirus Infections', value: '23' },
    { target: 'Distemper', source: 'Morbillivirus Infections', value: '23' },
    { target: 'Measles', source: 'Morbillivirus Infections', value: '23' },
    { target: 'Peste-des-Petits-Ruminants', source: 'Morbillivirus Infections', value: '23' },
    { target: 'Rinderpest', source: 'Morbillivirus Infections', value: '23' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Measles', value: '27' },
    { target: 'Pasteurellosis, Pneumonic', source: 'Respirovirus Infections', value: '23' },
    { target: 'Respiratory Syncytial Virus Infections', source: 'Pneumovirus Infections', value: '23' },
    { target: 'Mumps', source: 'Rubulavirus Infections', value: '23' },
    { target: 'Ephemeral Fever', source: 'Rhabdoviridae Infections', value: '19' },
    { target: 'Hemorrhagic Septicemia, Viral', source: 'Rhabdoviridae Infections', value: '19' },
    { target: 'Rabies', source: 'Rhabdoviridae Infections', value: '19' },
    { target: 'Vesicular Stomatitis', source: 'Rhabdoviridae Infections', value: '19' },
    { target: 'Arterivirus Infections', source: 'Nidovirales Infections', value: '15' },
    { target: 'Coronaviridae Infections', source: 'Nidovirales Infections', value: '15' },
    { target: 'Porcine Reproductive and Respiratory Syndrome', source: 'Arterivirus Infections', value: '19' },
    { target: 'Coronavirus Infections', source: 'Coronaviridae Infections', value: '19' },
    { target: 'Torovirus Infections', source: 'Coronaviridae Infections', value: '19' },
    { target: 'Enteritis, Transmissible, of Turkeys', source: 'Coronavirus Infections', value: '23' },
    { target: 'Feline Infectious Peritonitis', source: 'Coronavirus Infections', value: '23' },
    { target: 'Gastroenteritis, Transmissible, of Swine', source: 'Coronavirus Infections', value: '23' },
    { target: 'Severe Acute Respiratory Syndrome', source: 'Coronavirus Infections', value: '23' },
    { target: 'Influenza, Human', source: 'Orthomyxoviridae Infections', value: '15' },
    { target: 'Influenza in Birds', source: 'Orthomyxoviridae Infections', value: '15' },
    { target: 'Cardiovirus Infections', source: 'Picornaviridae Infections', value: '15' },
    { target: 'Common Cold', source: 'Picornaviridae Infections', value: '15' },
    { target: 'Enterovirus Infections', source: 'Picornaviridae Infections', value: '15' },
    { target: 'Foot-and-Mouth Disease', source: 'Picornaviridae Infections', value: '15' },
    { target: 'Conjunctivitis, Acute Hemorrhagic', source: 'Enterovirus Infections', value: '19' },
    { target: 'Coxsackievirus Infections', source: 'Enterovirus Infections', value: '19' },
    { target: 'Echovirus Infections', source: 'Enterovirus Infections', value: '19' },
    { target: 'Encephalomyelitis, Enzootic Porcine', source: 'Enterovirus Infections', value: '19' },
    { target: 'Hepatitis A', source: 'Enterovirus Infections', value: '19' },
    { target: 'Poliomyelitis', source: 'Enterovirus Infections', value: '19' },
    { target: 'Swine Vesicular Disease', source: 'Enterovirus Infections', value: '19' },
    { target: 'Hand, Foot and Mouth Disease', source: 'Coxsackievirus Infections', value: '23' },
    { target: 'Herpangina', source: 'Coxsackievirus Infections', value: '23' },
    { target: 'Pleurodynia, Epidemic', source: 'Coxsackievirus Infections', value: '23' },
    { target: 'Herpangina', source: 'Echovirus Infections', value: '23' },
    { target: 'Poliomyelitis, Bulbar', source: 'Poliomyelitis', value: '23' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Poliomyelitis', value: '23' },
    { target: 'African Horse Sickness', source: 'Reoviridae Infections', value: '15' },
    { target: 'Bluetongue', source: 'Reoviridae Infections', value: '15' },
    { target: 'Colorado Tick Fever', source: 'Reoviridae Infections', value: '15' },
    { target: 'Rotavirus Infections', source: 'Reoviridae Infections', value: '15' },
    { target: 'Avian Leukosis', source: 'Retroviridae Infections', value: '15' },
    { target: 'Deltaretrovirus Infections', source: 'Retroviridae Infections', value: '15' },
    { target: 'Lentivirus Infections', source: 'Retroviridae Infections', value: '15' },
    { target: 'Leukemia, Feline', source: 'Retroviridae Infections', value: '15' },
    { target: 'Murine Acquired Immunodeficiency Syndrome', source: 'Retroviridae Infections', value: '15' },
    { target: 'Pulmonary Adenomatosis, Ovine', source: 'Retroviridae Infections', value: '15' },
    { target: 'Sarcoma, Avian', source: 'Retroviridae Infections', value: '15' },
    { target: 'Enzootic Bovine Leukosis', source: 'Deltaretrovirus Infections', value: '19' },
    { target: 'HTLV-I Infections', source: 'Deltaretrovirus Infections', value: '19' },
    { target: 'HTLV-II Infections', source: 'Deltaretrovirus Infections', value: '19' },
    { target: 'Paraparesis, Tropical Spastic', source: 'HTLV-I Infections', value: '23' },
    { target: 'Equine Infectious Anemia', source: 'Lentivirus Infections', value: '19' },
    { target: 'Feline Acquired Immunodeficiency Syndrome', source: 'Lentivirus Infections', value: '19' },
    { target: 'HIV Infections', source: 'Lentivirus Infections', value: '19' },
    { target: 'Pneumonia, Progressive Interstitial, of Sheep', source: 'Lentivirus Infections', value: '19' },
    { target: 'Simian Acquired Immunodeficiency Syndrome', source: 'Lentivirus Infections', value: '19' },
    { target: 'Visna', source: 'Lentivirus Infections', value: '19' },
    { target: 'Acquired Immunodeficiency Syndrome', source: 'HIV Infections', value: '23' },
    { target: 'Acute Retroviral Syndrome', source: 'HIV Infections', value: '23' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'HIV Infections', value: '23' },
    { target: 'AIDS-Associated Nephropathy', source: 'HIV Infections', value: '23' },
    { target: 'AIDS Dementia Complex', source: 'HIV Infections', value: '23' },
    { target: 'AIDS-Related Complex', source: 'HIV Infections', value: '23' },
    { target: 'AIDS-Related Opportunistic Infections', source: 'HIV Infections', value: '23' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'HIV Infections', value: '23' },
    { target: 'HIV Enteropathy', source: 'HIV Infections', value: '23' },
    { target: 'HIV Seropositivity', source: 'HIV Infections', value: '23' },
    { target: 'HIV Wasting Syndrome', source: 'HIV Infections', value: '23' },
    { target: 'Alphavirus Infections', source: 'Togaviridae Infections', value: '15' },
    { target: 'Rubivirus Infections', source: 'Togaviridae Infections', value: '15' },
    { target: 'Chikungunya Fever', source: 'Alphavirus Infections', value: '19' },
    { target: 'Encephalomyelitis, Equine', source: 'Alphavirus Infections', value: '19' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Rubella', source: 'Rubivirus Infections', value: '19' },
    { target: 'Rubella Syndrome, Congenital', source: 'Rubella', value: '23' },
    { target: 'Sexually Transmitted Diseases, Viral', source: 'Sexually Transmitted Diseases', value: '11' },
    { target: 'Condylomata Acuminata', source: 'Sexually Transmitted Diseases, Viral', value: '15' },
    { target: 'Herpes Genitalis', source: 'Sexually Transmitted Diseases, Viral', value: '15' },
    { target: 'HIV Infections', source: 'Sexually Transmitted Diseases, Viral', value: '15' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Condylomata Acuminata', value: '19' },
    { target: 'Acquired Immunodeficiency Syndrome', source: 'HIV Infections', value: '19' },
    { target: 'Acute Retroviral Syndrome', source: 'HIV Infections', value: '19' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'HIV Infections', value: '19' },
    { target: 'AIDS-Associated Nephropathy', source: 'HIV Infections', value: '19' },
    { target: 'AIDS Dementia Complex', source: 'HIV Infections', value: '19' },
    { target: 'AIDS-Related Complex', source: 'HIV Infections', value: '19' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'HIV Infections', value: '19' },
    { target: 'HIV Enteropathy', source: 'HIV Infections', value: '19' },
    { target: 'HIV Seropositivity', source: 'HIV Infections', value: '19' },
    { target: 'HIV Wasting Syndrome', source: 'HIV Infections', value: '19' },
    { target: 'Erythema Infectiosum', source: 'Skin Diseases, Viral', value: '11' },
    { target: 'Exanthema Subitum', source: 'Skin Diseases, Viral', value: '11' },
    { target: 'Herpes Simplex', source: 'Skin Diseases, Viral', value: '11' },
    { target: 'Molluscum Contagiosum', source: 'Skin Diseases, Viral', value: '11' },
    { target: 'Warts', source: 'Skin Diseases, Viral', value: '11' },
    { target: 'Herpes Labialis', source: 'Herpes Simplex', value: '15' },
    { target: 'Kaposi Varicelliform Eruption', source: 'Herpes Simplex', value: '15' },
    { target: 'Condylomata Acuminata', source: 'Warts', value: '15' },
    { target: 'Epidermodysplasia Verruciformis', source: 'Warts', value: '15' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Condylomata Acuminata', value: '19' },
    { target: 'Acquired Immunodeficiency Syndrome', source: 'Slow Virus Diseases', value: '11' },
    { target: 'AIDS-Related Complex', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Aleutian Mink Disease', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Equine Infectious Anemia', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Feline Acquired Immunodeficiency Syndrome', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Pneumonia, Progressive Interstitial, of Sheep', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Simian Acquired Immunodeficiency Syndrome', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Visna', source: 'Slow Virus Diseases', value: '11' },
    { target: 'Avian Leukosis', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Carcinoma, Merkel Cell', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Epstein-Barr Virus Infections', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Marek Disease', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Murine Acquired Immunodeficiency Syndrome', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Papillomavirus Infections', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Pulmonary Adenomatosis, Ovine', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Sarcoma, Avian', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Warts', source: 'Tumor Virus Infections', value: '11' },
    { target: 'Burkitt Lymphoma', source: 'Epstein-Barr Virus Infections', value: '15' },
    { target: 'Condylomata Acuminata', source: 'Warts', value: '15' },
    { target: 'Epidermodysplasia Verruciformis', source: 'Warts', value: '15' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Condylomata Acuminata', value: '19' },
    { target: 'Central Nervous System Helminthiasis', source: 'Central Nervous System Parasitic Infections', value: '11' },
    { target: 'Central Nervous System Protozoal Infections', source: 'Central Nervous System Parasitic Infections', value: '11' },
    { target: 'Neurocysticercosis', source: 'Central Nervous System Helminthiasis', value: '15' },
    { target: 'Neuroschistosomiasis', source: 'Central Nervous System Helminthiasis', value: '15' },
    { target: 'Malaria, Cerebral', source: 'Central Nervous System Protozoal Infections', value: '15' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Central Nervous System Protozoal Infections', value: '15' },
    { target: 'Acanthamoeba Keratitis', source: 'Eye Infections, Parasitic', value: '11' },
    { target: 'Onchocerciasis, Ocular', source: 'Eye Infections, Parasitic', value: '11' },
    { target: 'Toxoplasmosis, Ocular', source: 'Eye Infections, Parasitic', value: '11' },
    { target: 'Cestode Infections', source: 'Helminthiasis', value: '11' },
    { target: 'Helminthiasis, Animal', source: 'Helminthiasis', value: '11' },
    { target: 'Nematode Infections', source: 'Helminthiasis', value: '11' },
    { target: 'Trematode Infections', source: 'Helminthiasis', value: '11' },
    { target: 'Diphyllobothriasis', source: 'Cestode Infections', value: '15' },
    { target: 'Echinococcosis', source: 'Cestode Infections', value: '15' },
    { target: 'Hymenolepiasis', source: 'Cestode Infections', value: '15' },
    { target: 'Monieziasis', source: 'Cestode Infections', value: '15' },
    { target: 'Taeniasis', source: 'Cestode Infections', value: '15' },
    { target: 'Sparganosis', source: 'Diphyllobothriasis', value: '19' },
    { target: 'Echinococcosis, Hepatic', source: 'Echinococcosis', value: '19' },
    { target: 'Echinococcosis, Pulmonary', source: 'Echinococcosis', value: '19' },
    { target: 'Cysticercosis', source: 'Taeniasis', value: '19' },
    { target: 'Neurocysticercosis', source: 'Cysticercosis', value: '23' },
    { target: 'Dictyocaulus Infections', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Dirofilariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Fascioloidiasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Monieziasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Setariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Strongyle Infections, Equine', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Toxocariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Adenophorea Infections', source: 'Nematode Infections', value: '15' },
    { target: 'Larva Migrans', source: 'Nematode Infections', value: '15' },
    { target: 'Secernentea Infections', source: 'Nematode Infections', value: '15' },
    { target: 'Enoplida Infections', source: 'Adenophorea Infections', value: '19' },
    { target: 'Trichinellosis', source: 'Enoplida Infections', value: '23' },
    { target: 'Trichuriasis', source: 'Enoplida Infections', value: '23' },
    { target: 'Larva Migrans, Visceral', source: 'Larva Migrans', value: '19' },
    { target: 'Ascaridida Infections', source: 'Secernentea Infections', value: '19' },
    { target: 'Oxyurida Infections', source: 'Secernentea Infections', value: '19' },
    { target: 'Rhabditida Infections', source: 'Secernentea Infections', value: '19' },
    { target: 'Spirurida Infections', source: 'Secernentea Infections', value: '19' },
    { target: 'Strongylida Infections', source: 'Secernentea Infections', value: '19' },
    { target: 'Anisakiasis', source: 'Ascaridida Infections', value: '23' },
    { target: 'Ascariasis', source: 'Ascaridida Infections', value: '23' },
    { target: 'Ascaridiasis', source: 'Ascaridida Infections', value: '23' },
    { target: 'Toxascariasis', source: 'Ascaridida Infections', value: '23' },
    { target: 'Toxocariasis', source: 'Ascaridida Infections', value: '23' },
    { target: 'Larva Migrans, Visceral', source: 'Toxocariasis', value: '27' },
    { target: 'Oxyuriasis', source: 'Oxyurida Infections', value: '23' },
    { target: 'Enterobiasis', source: 'Oxyuriasis', value: '27' },
    { target: 'Strongyloidiasis', source: 'Rhabditida Infections', value: '23' },
    { target: 'Dracunculiasis', source: 'Spirurida Infections', value: '23' },
    { target: 'Filariasis', source: 'Spirurida Infections', value: '23' },
    { target: 'Gnathostomiasis', source: 'Spirurida Infections', value: '23' },
    { target: 'Acanthocheilonemiasis', source: 'Filariasis', value: '27' },
    { target: 'Dipetalonema Infections', source: 'Filariasis', value: '27' },
    { target: 'Dirofilariasis', source: 'Filariasis', value: '27' },
    { target: 'Elephantiasis, Filarial', source: 'Filariasis', value: '27' },
    { target: 'Loiasis', source: 'Filariasis', value: '27' },
    { target: 'Mansonelliasis', source: 'Filariasis', value: '27' },
    { target: 'Onchocerciasis', source: 'Filariasis', value: '27' },
    { target: 'Setariasis', source: 'Filariasis', value: '27' },
    { target: 'Onchocerciasis, Ocular', source: 'Onchocerciasis', value: '31' },
    { target: 'Hookworm Infections', source: 'Strongylida Infections', value: '23' },
    { target: 'Oesophagostomiasis', source: 'Strongylida Infections', value: '23' },
    { target: 'Strongyle Infections, Equine', source: 'Strongylida Infections', value: '23' },
    { target: 'Trichostrongyloidiasis', source: 'Strongylida Infections', value: '23' },
    { target: 'Ancylostomiasis', source: 'Hookworm Infections', value: '27' },
    { target: 'Necatoriasis', source: 'Hookworm Infections', value: '27' },
    { target: 'Dictyocaulus Infections', source: 'Trichostrongyloidiasis', value: '27' },
    { target: 'Haemonchiasis', source: 'Trichostrongyloidiasis', value: '27' },
    { target: 'Ostertagiasis', source: 'Trichostrongyloidiasis', value: '27' },
    { target: 'Trichostrongylosis', source: 'Trichostrongyloidiasis', value: '27' },
    { target: 'Clonorchiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Dicrocoeliasis', source: 'Trematode Infections', value: '15' },
    { target: 'Echinostomiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Fascioliasis', source: 'Trematode Infections', value: '15' },
    { target: 'Fascioloidiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Opisthorchiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Paragonimiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Schistosomiasis', source: 'Trematode Infections', value: '15' },
    { target: 'Neuroschistosomiasis', source: 'Schistosomiasis', value: '19' },
    { target: 'Schistosomiasis haematobia', source: 'Schistosomiasis', value: '19' },
    { target: 'Schistosomiasis japonica', source: 'Schistosomiasis', value: '19' },
    { target: 'Schistosomiasis mansoni', source: 'Schistosomiasis', value: '19' },
    { target: 'Anisakiasis', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Balantidiasis', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Blastocystis Infections', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Cryptosporidiosis', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Dientamoebiasis', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Dysentery, Amebic', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Giardiasis', source: 'Intestinal Diseases, Parasitic', value: '11' },
    { target: 'Echinococcosis, Hepatic', source: 'Liver Diseases, Parasitic', value: '11' },
    { target: 'Fascioliasis', source: 'Liver Diseases, Parasitic', value: '11' },
    { target: 'Liver Abscess, Amebic', source: 'Liver Diseases, Parasitic', value: '11' },
    { target: 'Echinococcosis, Pulmonary', source: 'Lung Diseases, Parasitic', value: '11' },
    { target: 'Rhinosporidiosis', source: 'Mesomycetozoea Infections', value: '11' },
    { target: 'AIDS-Related Opportunistic Infections', source: 'Opportunistic Infections', value: '11' },
    { target: 'Superinfection', source: 'Opportunistic Infections', value: '11' },
    { target: 'Helminthiasis, Animal', source: 'Parasitic Diseases, Animal', value: '11' },
    { target: 'Protozoan Infections, Animal', source: 'Parasitic Diseases, Animal', value: '11' },
    { target: 'Dictyocaulus Infections', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Dirofilariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Fascioloidiasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Monieziasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Setariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Strongyle Infections, Equine', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Toxocariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Babesiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Cryptosporidiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Dourine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Theileriasis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Toxoplasmosis, Animal', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Trypanosomiasis, Bovine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Amebiasis', source: 'Protozoan Infections', value: '11' },
    { target: 'Central Nervous System Protozoal Infections', source: 'Protozoan Infections', value: '11' },
    { target: 'Ciliophora Infections', source: 'Protozoan Infections', value: '11' },
    { target: 'Coccidiosis', source: 'Protozoan Infections', value: '11' },
    { target: 'Dientamoebiasis', source: 'Protozoan Infections', value: '11' },
    { target: 'Euglenozoa Infections', source: 'Protozoan Infections', value: '11' },
    { target: 'Giardiasis', source: 'Protozoan Infections', value: '11' },
    { target: 'Malaria', source: 'Protozoan Infections', value: '11' },
    { target: 'Protozoan Infections, Animal', source: 'Protozoan Infections', value: '11' },
    { target: 'Tick-Borne Diseases', source: 'Protozoan Infections', value: '11' },
    { target: 'Trichomonas Infections', source: 'Protozoan Infections', value: '11' },
    { target: 'Acanthamoeba Keratitis', source: 'Amebiasis', value: '15' },
    { target: 'Blastocystis Infections', source: 'Amebiasis', value: '15' },
    { target: 'Dysentery, Amebic', source: 'Amebiasis', value: '15' },
    { target: 'Entamoebiasis', source: 'Amebiasis', value: '15' },
    { target: 'Liver Abscess, Amebic', source: 'Amebiasis', value: '15' },
    { target: 'Balantidiasis', source: 'Ciliophora Infections', value: '15' },
    { target: 'Cryptosporidiosis', source: 'Coccidiosis', value: '15' },
    { target: 'Cyclosporiasis', source: 'Coccidiosis', value: '15' },
    { target: 'Isosporiasis', source: 'Coccidiosis', value: '15' },
    { target: 'Sarcocystosis', source: 'Coccidiosis', value: '15' },
    { target: 'Toxoplasmosis', source: 'Coccidiosis', value: '15' },
    { target: 'Toxoplasmosis, Animal', source: 'Toxoplasmosis', value: '19' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Toxoplasmosis', value: '19' },
    { target: 'Toxoplasmosis, Congenital', source: 'Toxoplasmosis', value: '19' },
    { target: 'Toxoplasmosis, Ocular', source: 'Toxoplasmosis', value: '19' },
    { target: 'Leishmaniasis', source: 'Euglenozoa Infections', value: '15' },
    { target: 'Trypanosomiasis', source: 'Euglenozoa Infections', value: '15' },
    { target: 'Leishmaniasis, Cutaneous', source: 'Leishmaniasis', value: '19' },
    { target: 'Leishmaniasis, Visceral', source: 'Leishmaniasis', value: '19' },
    { target: 'Leishmaniasis, Diffuse Cutaneous', source: 'Leishmaniasis, Cutaneous', value: '23' },
    { target: 'Leishmaniasis, Mucocutaneous', source: 'Leishmaniasis, Cutaneous', value: '23' },
    { target: 'Chagas Disease', source: 'Trypanosomiasis', value: '19' },
    { target: 'Dourine', source: 'Trypanosomiasis', value: '19' },
    { target: 'Trypanosomiasis, African', source: 'Trypanosomiasis', value: '19' },
    { target: 'Trypanosomiasis, Bovine', source: 'Trypanosomiasis', value: '19' },
    { target: 'Chagas Cardiomyopathy', source: 'Chagas Disease', value: '23' },
    { target: 'Malaria, Avian', source: 'Malaria', value: '15' },
    { target: 'Malaria, Cerebral', source: 'Malaria', value: '15' },
    { target: 'Malaria, Falciparum', source: 'Malaria', value: '15' },
    { target: 'Malaria, Vivax', source: 'Malaria', value: '15' },
    { target: 'Blackwater Fever', source: 'Malaria, Falciparum', value: '19' },
    { target: 'Babesiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Cryptosporidiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Dourine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Theileriasis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Toxoplasmosis, Animal', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Trypanosomiasis, Bovine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Babesiosis', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Theileriasis', source: 'Tick-Borne Diseases', value: '15' },
    { target: 'Trichomonas Vaginitis', source: 'Trichomonas Infections', value: '15' },
    { target: 'Ectoparasitic Infestations', source: 'Skin Diseases, Parasitic', value: '11' },
    { target: 'Larva Migrans', source: 'Skin Diseases, Parasitic', value: '11' },
    { target: 'Leishmaniasis', source: 'Skin Diseases, Parasitic', value: '11' },
    { target: 'Onchocerciasis', source: 'Skin Diseases, Parasitic', value: '11' },
    { target: 'Flea Infestations', source: 'Ectoparasitic Infestations', value: '15' },
    { target: 'Lice Infestations', source: 'Ectoparasitic Infestations', value: '15' },
    { target: 'Mite Infestations', source: 'Ectoparasitic Infestations', value: '15' },
    { target: 'Myiasis', source: 'Ectoparasitic Infestations', value: '15' },
    { target: 'Tick Infestations', source: 'Ectoparasitic Infestations', value: '15' },
    { target: 'Tungiasis', source: 'Flea Infestations', value: '19' },
    { target: 'Scabies', source: 'Mite Infestations', value: '19' },
    { target: 'Trombiculiasis', source: 'Mite Infestations', value: '19' },
    { target: 'Hypodermyiasis', source: 'Myiasis', value: '19' },
    { target: 'Screw Worm Infection', source: 'Myiasis', value: '19' },
    { target: 'Leishmaniasis, Cutaneous', source: 'Leishmaniasis', value: '15' },
    { target: 'Leishmaniasis, Diffuse Cutaneous', source: 'Leishmaniasis, Cutaneous', value: '19' },
    { target: 'Leishmaniasis, Mucocutaneous', source: 'Leishmaniasis, Cutaneous', value: '19' },
    { target: 'Pythiosis', source: 'Zoonoses', value: '11' },
    { target: 'Arachnoid Cysts', source: 'Cysts', value: '11' },
    { target: 'Bone Cysts', source: 'Cysts', value: '11' },
    { target: 'Branchioma', source: 'Cysts', value: '11' },
    { target: 'Breast Cyst', source: 'Cysts', value: '11' },
    { target: 'Bronchogenic Cyst', source: 'Cysts', value: '11' },
    { target: 'Chalazion', source: 'Cysts', value: '11' },
    { target: 'Choledochal Cyst', source: 'Cysts', value: '11' },
    { target: 'Colloid Cysts', source: 'Cysts', value: '11' },
    { target: 'Dermoid Cyst', source: 'Cysts', value: '11' },
    { target: 'Epidermal Cyst', source: 'Cysts', value: '11' },
    { target: 'Esophageal Cyst', source: 'Cysts', value: '11' },
    { target: 'Follicular Cyst', source: 'Cysts', value: '11' },
    { target: 'Ganglion Cysts', source: 'Cysts', value: '11' },
    { target: 'Lymphocele', source: 'Cysts', value: '11' },
    { target: 'Mediastinal Cyst', source: 'Cysts', value: '11' },
    { target: 'Mesenteric Cyst', source: 'Cysts', value: '11' },
    { target: 'Mucocele', source: 'Cysts', value: '11' },
    { target: 'Ovarian Cysts', source: 'Cysts', value: '11' },
    { target: 'Pancreatic Cyst', source: 'Cysts', value: '11' },
    { target: 'Parovarian Cyst', source: 'Cysts', value: '11' },
    { target: 'Pilonidal Sinus', source: 'Cysts', value: '11' },
    { target: 'Ranula', source: 'Cysts', value: '11' },
    { target: 'Synovial Cyst', source: 'Cysts', value: '11' },
    { target: 'Tarlov Cysts', source: 'Cysts', value: '11' },
    { target: 'Thyroglossal Cyst', source: 'Cysts', value: '11' },
    { target: 'Urachal Cyst', source: 'Cysts', value: '11' },
    { target: 'Bone Cysts, Aneurysmal', source: 'Bone Cysts', value: '15' },
    { target: 'Jaw Cysts', source: 'Bone Cysts', value: '15' },
    { target: 'Nonodontogenic Cysts', source: 'Jaw Cysts', value: '19' },
    { target: 'Odontogenic Cysts', source: 'Jaw Cysts', value: '19' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Odontogenic Cysts', value: '23' },
    { target: 'Dentigerous Cyst', source: 'Odontogenic Cysts', value: '23' },
    { target: 'Odontogenic Cyst, Calcifying', source: 'Odontogenic Cysts', value: '23' },
    { target: 'Periodontal Cyst', source: 'Odontogenic Cysts', value: '23' },
    { target: 'Radicular Cyst', source: 'Periodontal Cyst', value: '27' },
    { target: 'Polycystic Ovary Syndrome', source: 'Ovarian Cysts', value: '15' },
    { target: 'Pancreatic Pseudocyst', source: 'Pancreatic Cyst', value: '15' },
    { target: 'Popliteal Cyst', source: 'Synovial Cyst', value: '15' },
    { target: 'Hamartoma Syndrome, Multiple', source: 'Hamartoma', value: '11' },
    { target: 'Pallister-Hall Syndrome', source: 'Hamartoma', value: '11' },
    { target: 'Tuberous Sclerosis', source: 'Hamartoma', value: '11' },
    { target: 'Proteus Syndrome', source: 'Hamartoma Syndrome, Multiple', value: '15' },
    { target: 'Histiocytic Disorders, Malignant', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Leukemia', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Lymphatic Vessel Tumors', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Lymphoma', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Complex and Mixed', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Connective and Soft Tissue', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Germ Cell and Embryonal', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Glandular and Epithelial', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Gonadal Tissue', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Nerve Tissue', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Plasma Cell', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Neoplasms, Vascular Tissue', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Nevi and Melanomas', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Odontogenic Tumors', source: 'Neoplasms by Histologic Type', value: '11' },
    { target: 'Dendritic Cell Sarcoma, Follicular', source: 'Histiocytic Disorders, Malignant', value: '15' },
    { target: 'Dendritic Cell Sarcoma, Interdigitating', source: 'Histiocytic Disorders, Malignant', value: '15' },
    { target: 'Histiocytic Sarcoma', source: 'Histiocytic Disorders, Malignant', value: '15' },
    { target: 'Langerhans Cell Sarcoma', source: 'Histiocytic Disorders, Malignant', value: '15' },
    { target: 'Enzootic Bovine Leukosis', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Experimental', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Feline', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Hairy Cell', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Lymphoid', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Mast-Cell', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Myeloid', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Plasma Cell', source: 'Leukemia', value: '15' },
    { target: 'Leukemia, Radiation-Induced', source: 'Leukemia', value: '15' },
    { target: 'Avian Leukosis', source: 'Leukemia, Experimental', value: '19' },
    { target: 'Leukemia L1210', source: 'Leukemia, Experimental', value: '19' },
    { target: 'Leukemia L5178', source: 'Leukemia, Experimental', value: '19' },
    { target: 'Leukemia P388', source: 'Leukemia, Experimental', value: '19' },
    { target: 'Leukemia, B-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Biphenotypic, Acute', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Prolymphocytic', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, T-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Lymphocytic, Chronic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Large Granular Lymphocytic', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia-Lymphoma, Adult T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Precursor B-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Precursor T-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Leukemia, Myeloid, Acute', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Leukemia, Myeloid, Chronic, Atypical, BCR-ABL Negative', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Leukemia, Myelomonocytic, Acute', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Leukemia, Myelomonocytic, Chronic', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Leukemia, Myelomonocytic, Juvenile', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Sarcoma, Myeloid', source: 'Leukemia, Myeloid', value: '19' },
    { target: 'Blast Crisis', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Leukemia, Myeloid, Accelerated Phase', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Leukemia, Myeloid, Chronic-Phase', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Leukemia, Basophilic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Eosinophilic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Erythroblastic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Mast-Cell', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Megakaryoblastic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Monocytic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Leukemia, Promyelocytic, Acute', source: 'Leukemia, Myeloid, Acute', value: '23' },
    { target: 'Lymphangioma', source: 'Lymphatic Vessel Tumors', value: '15' },
    { target: 'Lymphangiomyoma', source: 'Lymphatic Vessel Tumors', value: '15' },
    { target: 'Lymphangiosarcoma', source: 'Lymphatic Vessel Tumors', value: '15' },
    { target: 'Lymphangioma, Cystic', source: 'Lymphangioma', value: '19' },
    { target: 'Lymphangioleiomyomatosis', source: 'Lymphangiomyoma', value: '19' },
    { target: 'Composite Lymphoma', source: 'Lymphoma', value: '15' },
    { target: 'Hodgkin Disease', source: 'Lymphoma', value: '15' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Lymphoma', value: '15' },
    { target: 'Intraocular Lymphoma', source: 'Lymphoma', value: '15' },
    { target: 'Lymphoma, Non-Hodgkin', source: 'Lymphoma', value: '15' },
    { target: 'Lymphoma, B-Cell', source: 'Lymphoma, Non-Hodgkin', value: '19' },
    { target: 'Lymphoma, Follicular', source: 'Lymphoma, Non-Hodgkin', value: '19' },
    { target: 'Lymphoma, Large-Cell, Immunoblastic', source: 'Lymphoma, Non-Hodgkin', value: '19' },
    { target: 'Lymphoma, Mantle-Cell', source: 'Lymphoma, Non-Hodgkin', value: '19' },
    { target: 'Lymphoma, T-Cell', source: 'Lymphoma, Non-Hodgkin', value: '19' },
    { target: 'Burkitt Lymphoma', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Lymphoma, AIDS-Related', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Lymphoma, B-Cell, Marginal Zone', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Lymphoma, Large B-Cell, Diffuse', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Lymphoma, Primary Effusion', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Lymphomatoid Granulomatosis', source: 'Lymphoma, B-Cell', value: '23' },
    { target: 'Plasmablastic Lymphoma', source: 'Lymphoma, Large B-Cell, Diffuse', value: '27' },
    { target: 'Enteropathy-Associated T-Cell Lymphoma', source: 'Lymphoma, T-Cell', value: '23' },
    { target: 'Lymphoma, Extranodal NK-T-Cell', source: 'Lymphoma, T-Cell', value: '23' },
    { target: 'Lymphoma, Large-Cell, Anaplastic', source: 'Lymphoma, T-Cell', value: '23' },
    { target: 'Lymphoma, T-Cell, Cutaneous', source: 'Lymphoma, T-Cell', value: '23' },
    { target: 'Lymphoma, T-Cell, Peripheral', source: 'Lymphoma, T-Cell', value: '23' },
    { target: 'Lymphoma, Primary Cutaneous Anaplastic Large Cell', source: 'Lymphoma, T-Cell, Cutaneous', value: '27' },
    { target: 'Lymphomatoid Papulosis', source: 'Lymphoma, T-Cell, Cutaneous', value: '27' },
    { target: 'Mycosis Fungoides', source: 'Lymphoma, T-Cell, Cutaneous', value: '27' },
    { target: 'Sezary Syndrome', source: 'Lymphoma, T-Cell, Cutaneous', value: '27' },
    { target: 'Pagetoid Reticulosis', source: 'Mycosis Fungoides', value: '31' },
    { target: 'Adenolymphoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Adenoma, Pleomorphic', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Adenomyoepithelioma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Adenomyoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Adenosarcoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Carcinoma, Adenosquamous', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Carcinosarcoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Composite Lymphoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Hepatoblastoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Mesenchymoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Mixed Tumor, Malignant', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Mixed Tumor, Mesodermal', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Mixed Tumor, Mullerian', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Myoepithelioma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Wilms Tumor', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Nephroma, Mesoblastic', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Pulmonary Blastoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Rhabdoid Tumor', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Sarcoma, Endometrial Stromal', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Thymoma', source: 'Neoplasms, Complex and Mixed', value: '15' },
    { target: 'Carcinoma 256, Walker', source: 'Carcinosarcoma', value: '19' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '19' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '19' },
    { target: 'Neoplasms, Adipose Tissue', source: 'Neoplasms, Connective and Soft Tissue', value: '15' },
    { target: 'Neoplasms, Connective Tissue', source: 'Neoplasms, Connective and Soft Tissue', value: '15' },
    { target: 'Neoplasms, Muscle Tissue', source: 'Neoplasms, Connective and Soft Tissue', value: '15' },
    { target: 'Perivascular Epithelioid Cell Neoplasms', source: 'Neoplasms, Connective and Soft Tissue', value: '15' },
    { target: 'Sarcoma', source: 'Neoplasms, Connective and Soft Tissue', value: '15' },
    { target: 'Angiolipoma', source: 'Neoplasms, Adipose Tissue', value: '19' },
    { target: 'Angiomyolipoma', source: 'Neoplasms, Adipose Tissue', value: '19' },
    { target: 'Lipoma', source: 'Neoplasms, Adipose Tissue', value: '19' },
    { target: 'Liposarcoma', source: 'Neoplasms, Adipose Tissue', value: '19' },
    { target: 'Myelolipoma', source: 'Neoplasms, Adipose Tissue', value: '19' },
    { target: 'Lipoblastoma', source: 'Lipoma', value: '23' },
    { target: 'Liposarcoma, Myxoid', source: 'Liposarcoma', value: '23' },
    { target: 'Chondroblastoma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Chondroma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Chondrosarcoma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Endometrial Stromal Tumors', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Gastrointestinal Stromal Tumors', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Giant Cell Tumors', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Mastocytosis', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Myofibroma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Myxoma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Myxosarcoma', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Neoplasms, Bone Tissue', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Neoplasms, Fibrous Tissue', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Sarcoma, Clear Cell', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Sarcoma, Small Cell', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Sarcoma, Synovial', source: 'Neoplasms, Connective Tissue', value: '19' },
    { target: 'Chondromatosis', source: 'Chondroma', value: '23' },
    { target: 'Chondrosarcoma, Mesenchymal', source: 'Chondrosarcoma', value: '23' },
    { target: 'Giant Cell Tumor of Bone', source: 'Giant Cell Tumors', value: '23' },
    { target: 'Giant Cell Tumor of Tendon Sheath', source: 'Giant Cell Tumors', value: '23' },
    { target: 'Synovitis, Pigmented Villonodular', source: 'Giant Cell Tumor of Tendon Sheath', value: '27' },
    { target: 'Mast-Cell Sarcoma', source: 'Mastocytosis', value: '23' },
    { target: 'Mastocytoma', source: 'Mastocytosis', value: '23' },
    { target: 'Mastocytosis, Cutaneous', source: 'Mastocytosis', value: '23' },
    { target: 'Mastocytosis, Systemic', source: 'Mastocytosis', value: '23' },
    { target: 'Mastocytoma, Skin', source: 'Mastocytoma', value: '27' },
    { target: 'Mastocytoma, Skin', source: 'Mastocytosis, Cutaneous', value: '27' },
    { target: 'Urticaria Pigmentosa', source: 'Mastocytosis, Cutaneous', value: '27' },
    { target: 'Leukemia, Mast-Cell', source: 'Mastocytosis, Systemic', value: '27' },
    { target: 'Carney Complex', source: 'Myxoma', value: '23' },
    { target: 'Neurothekeoma', source: 'Myxoma', value: '23' },
    { target: 'Fibroma, Ossifying', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Giant Cell Tumor of Bone', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Osteoblastoma', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Osteochondroma', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Osteoma', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Osteosarcoma', source: 'Neoplasms, Bone Tissue', value: '23' },
    { target: 'Osteochondromatosis', source: 'Osteochondroma', value: '27' },
    { target: 'Exostoses, Multiple Hereditary', source: 'Osteochondromatosis', value: '31' },
    { target: 'Osteoma, Osteoid', source: 'Osteoma', value: '27' },
    { target: 'Osteosarcoma, Juxtacortical', source: 'Osteosarcoma', value: '27' },
    { target: 'Sarcoma, Ewing', source: 'Osteosarcoma', value: '27' },
    { target: 'Fibroma', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Fibrosarcoma', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Histiocytoma', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Myofibromatosis', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Neoplasms, Fibroepithelial', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Solitary Fibrous Tumors', source: 'Neoplasms, Fibrous Tissue', value: '23' },
    { target: 'Dupuytren Contracture', source: 'Fibroma', value: '27' },
    { target: 'Fibroma, Desmoplastic', source: 'Fibroma', value: '27' },
    { target: 'Fibroma, Ossifying', source: 'Fibroma', value: '27' },
    { target: 'Fibromatosis, Abdominal', source: 'Fibroma', value: '27' },
    { target: 'Fibromatosis, Aggressive', source: 'Fibroma', value: '27' },
    { target: 'Fibromatosis, Plantar', source: 'Fibroma', value: '27' },
    { target: 'Dermatofibrosarcoma', source: 'Fibrosarcoma', value: '27' },
    { target: 'Neurofibrosarcoma', source: 'Fibrosarcoma', value: '27' },
    { target: 'Histiocytoma, Benign Fibrous', source: 'Histiocytoma', value: '27' },
    { target: 'Histiocytoma, Malignant Fibrous', source: 'Histiocytoma', value: '27' },
    { target: 'Adenofibroma', source: 'Neoplasms, Fibroepithelial', value: '27' },
    { target: 'Brenner Tumor', source: 'Neoplasms, Fibroepithelial', value: '27' },
    { target: 'Fibroadenoma', source: 'Neoplasms, Fibroepithelial', value: '27' },
    { target: 'Cystadenofibroma', source: 'Adenofibroma', value: '31' },
    { target: 'Solitary Fibrous Tumor, Pleural', source: 'Solitary Fibrous Tumors', value: '27' },
    { target: 'Granular Cell Tumor', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Leiomyoma', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Leiomyosarcoma', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Myoma', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Myosarcoma', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Sarcoma, Alveolar Soft Part', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Smooth Muscle Tumor', source: 'Neoplasms, Muscle Tissue', value: '19' },
    { target: 'Angiomyoma', source: 'Leiomyoma', value: '23' },
    { target: 'Leiomyoma, Epithelioid', source: 'Leiomyoma', value: '23' },
    { target: 'Leiomyomatosis', source: 'Leiomyoma', value: '23' },
    { target: 'Rhabdomyoma', source: 'Myoma', value: '23' },
    { target: 'Rhabdomyosarcoma', source: 'Myosarcoma', value: '23' },
    { target: 'Rhabdomyosarcoma, Alveolar', source: 'Rhabdomyosarcoma', value: '27' },
    { target: 'Rhabdomyosarcoma, Embryonal', source: 'Rhabdomyosarcoma', value: '27' },
    { target: 'Angiomyolipoma', source: 'Perivascular Epithelioid Cell Neoplasms', value: '19' },
    { target: 'Lymphangioleiomyomatosis', source: 'Perivascular Epithelioid Cell Neoplasms', value: '19' },
    { target: 'Adenosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Carcinosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Chondrosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Desmoplastic Small Round Cell Tumor', source: 'Sarcoma', value: '19' },
    { target: 'Endometrial Stromal Tumors', source: 'Sarcoma', value: '19' },
    { target: 'Fibrosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Hemangiosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Histiocytoma, Malignant Fibrous', source: 'Sarcoma', value: '19' },
    { target: 'Leiomyosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Liposarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Lymphangiosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Mixed Tumor, Mesodermal', source: 'Sarcoma', value: '19' },
    { target: 'Myosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Myxosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Osteosarcoma', source: 'Sarcoma', value: '19' },
    { target: 'Phyllodes Tumor', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Alveolar Soft Part', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Clear Cell', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Experimental', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Kaposi', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Myeloid', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Small Cell', source: 'Sarcoma', value: '19' },
    { target: 'Sarcoma, Synovial', source: 'Sarcoma', value: '19' },
    { target: 'Carcinoma 256, Walker', source: 'Carcinosarcoma', value: '23' },
    { target: 'Chondrosarcoma, Mesenchymal', source: 'Chondrosarcoma', value: '23' },
    { target: 'Sarcoma, Endometrial Stromal', source: 'Endometrial Stromal Tumors', value: '23' },
    { target: 'Dermatofibrosarcoma', source: 'Fibrosarcoma', value: '23' },
    { target: 'Neurofibrosarcoma', source: 'Fibrosarcoma', value: '23' },
    { target: 'Liposarcoma, Myxoid', source: 'Liposarcoma', value: '23' },
    { target: 'Rhabdomyosarcoma', source: 'Myosarcoma', value: '23' },
    { target: 'Rhabdomyosarcoma, Alveolar', source: 'Rhabdomyosarcoma', value: '27' },
    { target: 'Rhabdomyosarcoma, Embryonal', source: 'Rhabdomyosarcoma', value: '27' },
    { target: 'Osteosarcoma, Juxtacortical', source: 'Osteosarcoma', value: '23' },
    { target: 'Sarcoma, Ewing', source: 'Osteosarcoma', value: '23' },
    { target: 'Sarcoma 37', source: 'Sarcoma, Experimental', value: '23' },
    { target: 'Sarcoma 180', source: 'Sarcoma, Experimental', value: '23' },
    { target: 'Sarcoma, Avian', source: 'Sarcoma, Experimental', value: '23' },
    { target: 'Sarcoma, Yoshida', source: 'Sarcoma, Experimental', value: '23' },
    { target: 'Carcinoma, Embryonal', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Chordoma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Germinoma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Gonadoblastoma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Mesonephroma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Neuroectodermal Tumors', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Teratocarcinoma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Teratoma', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Trophoblastic Neoplasms', source: 'Neoplasms, Germ Cell and Embryonal', value: '15' },
    { target: 'Dysgerminoma', source: 'Germinoma', value: '19' },
    { target: 'Seminoma', source: 'Germinoma', value: '19' },
    { target: 'Endodermal Sinus Tumor', source: 'Mesonephroma', value: '19' },
    { target: 'Craniopharyngioma', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neoplasms, Neuroepithelial', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neuroectodermal Tumor, Melanotic', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neuroendocrine Tumors', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Ganglioneuroma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Glioma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Neurocytoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Neuroectodermal Tumors, Primitive', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Pinealoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Retinoblastoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Astrocytoma', source: 'Glioma', value: '27' },
    { target: 'Ependymoma', source: 'Glioma', value: '27' },
    { target: 'Ganglioglioma', source: 'Glioma', value: '27' },
    { target: 'Gliosarcoma', source: 'Glioma', value: '27' },
    { target: 'Medulloblastoma', source: 'Glioma', value: '27' },
    { target: 'Oligodendroglioma', source: 'Glioma', value: '27' },
    { target: 'Optic Nerve Glioma', source: 'Glioma', value: '27' },
    { target: 'Glioblastoma', source: 'Astrocytoma', value: '31' },
    { target: 'Glioma, Subependymal', source: 'Ependymoma', value: '31' },
    { target: 'Medulloblastoma', source: 'Neuroectodermal Tumors, Primitive', value: '27' },
    { target: 'Neuroectodermal Tumors, Primitive, Peripheral', source: 'Neuroectodermal Tumors, Primitive', value: '27' },
    { target: 'Neuroblastoma', source: 'Neuroectodermal Tumors, Primitive, Peripheral', value: '31' },
    { target: 'Esthesioneuroblastoma, Olfactory', source: 'Neuroblastoma', value: '35' },
    { target: 'Ganglioneuroblastoma', source: 'Neuroblastoma', value: '35' },
    { target: 'Adenoma, Acidophil', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Adenoma, Basophil', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Adenoma, Chromophobe', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Apudoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Carcinoid Tumor', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Carcinoma, Neuroendocrine', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Melanoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Neurilemmoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Paraganglioma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Malignant Carcinoid Syndrome', source: 'Carcinoid Tumor', value: '27' },
    { target: 'Carcinoid Heart Disease', source: 'Malignant Carcinoid Syndrome', value: '31' },
    { target: 'Carcinoma, Medullary', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Carcinoma, Merkel Cell', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Vipoma', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Hutchinson\'s Melanotic Freckle', source: 'Melanoma', value: '27' },
    { target: 'Melanoma, Amelanotic', source: 'Melanoma', value: '27' },
    { target: 'Melanoma, Experimental', source: 'Melanoma', value: '27' },
    { target: 'Neuroma, Acoustic', source: 'Neurilemmoma', value: '27' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '31' },
    { target: 'Paraganglioma, Extra-Adrenal', source: 'Paraganglioma', value: '27' },
    { target: 'Pheochromocytoma', source: 'Paraganglioma', value: '27' },
    { target: 'Carotid Body Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Glomus Jugulare Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Glomus Tympanicum Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Dermoid Cyst', source: 'Teratoma', value: '19' },
    { target: 'Struma Ovarii', source: 'Teratoma', value: '19' },
    { target: 'Choriocarcinoma', source: 'Trophoblastic Neoplasms', value: '19' },
    { target: 'Gestational Trophoblastic Disease', source: 'Trophoblastic Neoplasms', value: '19' },
    { target: 'Choriocarcinoma, Non-gestational', source: 'Choriocarcinoma', value: '23' },
    { target: 'Trophoblastic Tumor, Placental Site', source: 'Choriocarcinoma', value: '23' },
    { target: 'Hydatidiform Mole', source: 'Gestational Trophoblastic Disease', value: '23' },
    { target: 'Hydatidiform Mole, Invasive', source: 'Hydatidiform Mole', value: '27' },
    { target: 'Adenoma', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Carcinoma', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Adnexal and Skin Appendage', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Basal Cell', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Cystic, Mucinous, and Serous', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Ductal, Lobular, and Medullary', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Fibroepithelial', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Mesothelial', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Neuroepithelial', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'Neoplasms, Squamous Cell', source: 'Neoplasms, Glandular and Epithelial', value: '15' },
    { target: 'ACTH-Secreting Pituitary Adenoma', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Acidophil', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Basophil', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Bile Duct', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Chromophobe', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Islet Cell', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Liver Cell', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Oxyphilic', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Pleomorphic', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Sweat Gland', source: 'Adenoma', value: '19' },
    { target: 'Adenoma, Villous', source: 'Adenoma', value: '19' },
    { target: 'Adenomatoid Tumor', source: 'Adenoma', value: '19' },
    { target: 'Adenomatosis, Pulmonary', source: 'Adenoma', value: '19' },
    { target: 'Adenomatous Polyps', source: 'Adenoma', value: '19' },
    { target: 'Adrenal Rest Tumor', source: 'Adenoma', value: '19' },
    { target: 'Apudoma', source: 'Adenoma', value: '19' },
    { target: 'Cystadenoma', source: 'Adenoma', value: '19' },
    { target: 'Growth Hormone-Secreting Pituitary Adenoma', source: 'Adenoma', value: '19' },
    { target: 'Mesothelioma', source: 'Adenoma', value: '19' },
    { target: 'Prolactinoma', source: 'Adenoma', value: '19' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '23' },
    { target: 'Acrospiroma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Hidrocystoma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Syringoma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Poroma', source: 'Acrospiroma', value: '27' },
    { target: 'Adenomatous Polyposis Coli', source: 'Adenomatous Polyps', value: '23' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '27' },
    { target: 'Cystadenoma, Mucinous', source: 'Cystadenoma', value: '23' },
    { target: 'Cystadenoma, Papillary', source: 'Cystadenoma', value: '23' },
    { target: 'Cystadenoma, Serous', source: 'Cystadenoma', value: '23' },
    { target: 'Mesothelioma, Cystic', source: 'Mesothelioma', value: '23' },
    { target: 'Adenocarcinoma', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Adenosquamous', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Basal Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Basosquamous', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Ehrlich Tumor', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Giant Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma in Situ', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Krebs 2', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Large Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Lewis Lung', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Papillary', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Small Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Squamous Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Transitional Cell', source: 'Carcinoma', value: '19' },
    { target: 'Carcinoma, Verrucous', source: 'Carcinoma', value: '19' },
    { target: 'Mammary Analogue Secretory Carcinoma', source: 'Carcinoma', value: '19' },
    { target: 'Thyroid Carcinoma, Anaplastic', source: 'Carcinoma', value: '19' },
    { target: 'Adenocarcinoma in Situ', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Bronchiolo-Alveolar', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Clear Cell', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Follicular', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Mucinous', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Papillary', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Scirrhous', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adenocarcinoma, Sebaceous', source: 'Adenocarcinoma', value: '23' },
    { target: 'Adrenocortical Carcinoma', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoid Tumor', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Acinar Cell', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Adenoid Cystic', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Ductal', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Endometrioid', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Hepatocellular', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Intraductal, Noninfiltrating', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Islet Cell', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Lobular', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Mucoepidermoid', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Neuroendocrine', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Renal Cell', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Signet Ring Cell', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Skin Appendage', source: 'Adenocarcinoma', value: '23' },
    { target: 'Cholangiocarcinoma', source: 'Adenocarcinoma', value: '23' },
    { target: 'Choriocarcinoma', source: 'Adenocarcinoma', value: '23' },
    { target: 'Cystadenocarcinoma', source: 'Adenocarcinoma', value: '23' },
    { target: 'Eccrine Porocarcinoma', source: 'Adenocarcinoma', value: '23' },
    { target: 'Paget Disease, Extramammary', source: 'Adenocarcinoma', value: '23' },
    { target: 'Pulmonary Adenomatosis, Ovine', source: 'Adenocarcinoma', value: '23' },
    { target: 'Carcinoma, Papillary, Follicular', source: 'Adenocarcinoma, Follicular', value: '27' },
    { target: 'Carcinoma, Papillary, Follicular', source: 'Adenocarcinoma, Papillary', value: '27' },
    { target: 'Linitis Plastica', source: 'Adenocarcinoma, Scirrhous', value: '27' },
    { target: 'Malignant Carcinoid Syndrome', source: 'Carcinoid Tumor', value: '27' },
    { target: 'Carcinoid Heart Disease', source: 'Malignant Carcinoid Syndrome', value: '31' },
    { target: 'Carcinoma, Ductal, Breast', source: 'Carcinoma, Ductal', value: '27' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Carcinoma, Ductal', value: '27' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '27' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '27' },
    { target: 'Carcinoma, Medullary', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Carcinoma, Merkel Cell', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Vipoma', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Krukenberg Tumor', source: 'Carcinoma, Signet Ring Cell', value: '27' },
    { target: 'Klatskin Tumor', source: 'Cholangiocarcinoma', value: '27' },
    { target: 'Choriocarcinoma, Non-gestational', source: 'Choriocarcinoma', value: '27' },
    { target: 'Trophoblastic Tumor, Placental Site', source: 'Choriocarcinoma', value: '27' },
    { target: 'Cystadenocarcinoma, Mucinous', source: 'Cystadenocarcinoma', value: '27' },
    { target: 'Cystadenocarcinoma, Papillary', source: 'Cystadenocarcinoma', value: '27' },
    { target: 'Cystadenocarcinoma, Serous', source: 'Cystadenocarcinoma', value: '27' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Carcinoma, Basal Cell', value: '23' },
    { target: 'Adenocarcinoma in Situ', source: 'Carcinoma in Situ', value: '23' },
    { target: 'Breast Carcinoma In Situ', source: 'Carcinoma in Situ', value: '23' },
    { target: 'Cervical Intraepithelial Neoplasia', source: 'Carcinoma in Situ', value: '23' },
    { target: 'Prostatic Intraepithelial Neoplasia', source: 'Carcinoma in Situ', value: '23' },
    { target: 'Carcinoma, Intraductal, Noninfiltrating', source: 'Breast Carcinoma In Situ', value: '27' },
    { target: 'Paget\'s Disease, Mammary', source: 'Breast Carcinoma In Situ', value: '27' },
    { target: 'Bowen\'s Disease', source: 'Carcinoma, Squamous Cell', value: '23' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Carcinoma, Verrucous', value: '23' },
    { target: 'Adenocarcinoma, Sebaceous', source: 'Neoplasms, Adnexal and Skin Appendage', value: '19' },
    { target: 'Adenoma, Sweat Gland', source: 'Neoplasms, Adnexal and Skin Appendage', value: '19' },
    { target: 'Carcinoma, Skin Appendage', source: 'Neoplasms, Adnexal and Skin Appendage', value: '19' },
    { target: 'Acrospiroma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Hidrocystoma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Syringoma', source: 'Adenoma, Sweat Gland', value: '23' },
    { target: 'Poroma', source: 'Acrospiroma', value: '27' },
    { target: 'Carcinoma, Basal Cell', source: 'Neoplasms, Basal Cell', value: '19' },
    { target: 'Carcinoma, Basosquamous', source: 'Neoplasms, Basal Cell', value: '19' },
    { target: 'Pilomatrixoma', source: 'Neoplasms, Basal Cell', value: '19' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Carcinoma, Basal Cell', value: '23' },
    { target: 'Adenocarcinoma, Mucinous', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Carcinoma, Mucoepidermoid', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Carcinoma, Signet Ring Cell', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Cystadenocarcinoma', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Cystadenofibroma', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Cystadenoma', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Mucoepidermoid Tumor', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Pseudomyxoma Peritonei', source: 'Neoplasms, Cystic, Mucinous, and Serous', value: '19' },
    { target: 'Krukenberg Tumor', source: 'Carcinoma, Signet Ring Cell', value: '23' },
    { target: 'Cystadenocarcinoma, Mucinous', source: 'Cystadenocarcinoma', value: '23' },
    { target: 'Cystadenocarcinoma, Papillary', source: 'Cystadenocarcinoma', value: '23' },
    { target: 'Cystadenocarcinoma, Serous', source: 'Cystadenocarcinoma', value: '23' },
    { target: 'Cystadenoma, Mucinous', source: 'Cystadenoma', value: '23' },
    { target: 'Cystadenoma, Papillary', source: 'Cystadenoma', value: '23' },
    { target: 'Cystadenoma, Serous', source: 'Cystadenoma', value: '23' },
    { target: 'Carcinoma, Ductal', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Carcinoma, Intraductal, Noninfiltrating', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Carcinoma, Lobular', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Carcinoma, Medullary', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Paget Disease, Extramammary', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Papilloma, Intraductal', source: 'Neoplasms, Ductal, Lobular, and Medullary', value: '19' },
    { target: 'Carcinoma, Ductal, Breast', source: 'Carcinoma, Ductal', value: '23' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Carcinoma, Ductal', value: '23' },
    { target: 'Paget\'s Disease, Mammary', source: 'Carcinoma, Intraductal, Noninfiltrating', value: '23' },
    { target: 'Adenofibroma', source: 'Neoplasms, Fibroepithelial', value: '19' },
    { target: 'Brenner Tumor', source: 'Neoplasms, Fibroepithelial', value: '19' },
    { target: 'Fibroadenoma', source: 'Neoplasms, Fibroepithelial', value: '19' },
    { target: 'Cystadenofibroma', source: 'Adenofibroma', value: '23' },
    { target: 'Adenomatoid Tumor', source: 'Neoplasms, Mesothelial', value: '19' },
    { target: 'Mesothelioma', source: 'Neoplasms, Mesothelial', value: '19' },
    { target: 'Mesothelioma, Cystic', source: 'Mesothelioma', value: '23' },
    { target: 'Ganglioneuroma', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Glioma', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Neurocytoma', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Neuroectodermal Tumors, Primitive', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Pinealoma', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Retinoblastoma', source: 'Neoplasms, Neuroepithelial', value: '19' },
    { target: 'Astrocytoma', source: 'Glioma', value: '23' },
    { target: 'Ependymoma', source: 'Glioma', value: '23' },
    { target: 'Ganglioglioma', source: 'Glioma', value: '23' },
    { target: 'Gliosarcoma', source: 'Glioma', value: '23' },
    { target: 'Medulloblastoma', source: 'Glioma', value: '23' },
    { target: 'Oligodendroglioma', source: 'Glioma', value: '23' },
    { target: 'Optic Nerve Glioma', source: 'Glioma', value: '23' },
    { target: 'Glioblastoma', source: 'Astrocytoma', value: '27' },
    { target: 'Glioma, Subependymal', source: 'Ependymoma', value: '27' },
    { target: 'Medulloblastoma', source: 'Neuroectodermal Tumors, Primitive', value: '23' },
    { target: 'Neuroectodermal Tumors, Primitive, Peripheral', source: 'Neuroectodermal Tumors, Primitive', value: '23' },
    { target: 'Neuroblastoma', source: 'Neuroectodermal Tumors, Primitive, Peripheral', value: '27' },
    { target: 'Esthesioneuroblastoma, Olfactory', source: 'Neuroblastoma', value: '31' },
    { target: 'Ganglioneuroblastoma', source: 'Neuroblastoma', value: '31' },
    { target: 'Acanthoma', source: 'Neoplasms, Squamous Cell', value: '19' },
    { target: 'Carcinoma, Papillary', source: 'Neoplasms, Squamous Cell', value: '19' },
    { target: 'Carcinoma, Squamous Cell', source: 'Neoplasms, Squamous Cell', value: '19' },
    { target: 'Carcinoma, Verrucous', source: 'Neoplasms, Squamous Cell', value: '19' },
    { target: 'Papilloma', source: 'Neoplasms, Squamous Cell', value: '19' },
    { target: 'Bowen\'s Disease', source: 'Carcinoma, Squamous Cell', value: '23' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Carcinoma, Verrucous', value: '23' },
    { target: 'Papilloma, Inverted', source: 'Papilloma', value: '23' },
    { target: 'Gonadoblastoma', source: 'Neoplasms, Gonadal Tissue', value: '15' },
    { target: 'Sex Cord-Gonadal Stromal Tumors', source: 'Neoplasms, Gonadal Tissue', value: '15' },
    { target: 'Granulosa Cell Tumor', source: 'Sex Cord-Gonadal Stromal Tumors', value: '19' },
    { target: 'Luteoma', source: 'Sex Cord-Gonadal Stromal Tumors', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Sex Cord-Gonadal Stromal Tumors', value: '19' },
    { target: 'Thecoma', source: 'Sex Cord-Gonadal Stromal Tumors', value: '19' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Meningioma', source: 'Neoplasms, Nerve Tissue', value: '15' },
    { target: 'Nerve Sheath Neoplasms', source: 'Neoplasms, Nerve Tissue', value: '15' },
    { target: 'Neuroectodermal Tumors', source: 'Neoplasms, Nerve Tissue', value: '15' },
    { target: 'Neurofibroma', source: 'Nerve Sheath Neoplasms', value: '19' },
    { target: 'Neuroma', source: 'Nerve Sheath Neoplasms', value: '19' },
    { target: 'Neurothekeoma', source: 'Nerve Sheath Neoplasms', value: '19' },
    { target: 'Neurofibroma, Plexiform', source: 'Neurofibroma', value: '23' },
    { target: 'Neurofibromatoses', source: 'Neurofibroma', value: '23' },
    { target: 'Neurofibrosarcoma', source: 'Neurofibroma', value: '23' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '27' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '27' },
    { target: 'Neurilemmoma', source: 'Neuroma', value: '23' },
    { target: 'Neuroma, Acoustic', source: 'Neurilemmoma', value: '27' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '31' },
    { target: 'Craniopharyngioma', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neoplasms, Neuroepithelial', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neuroectodermal Tumor, Melanotic', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Neuroendocrine Tumors', source: 'Neuroectodermal Tumors', value: '19' },
    { target: 'Ganglioneuroma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Glioma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Neurocytoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Neuroectodermal Tumors, Primitive', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Pinealoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Retinoblastoma', source: 'Neoplasms, Neuroepithelial', value: '23' },
    { target: 'Astrocytoma', source: 'Glioma', value: '27' },
    { target: 'Ependymoma', source: 'Glioma', value: '27' },
    { target: 'Ganglioglioma', source: 'Glioma', value: '27' },
    { target: 'Gliosarcoma', source: 'Glioma', value: '27' },
    { target: 'Medulloblastoma', source: 'Glioma', value: '27' },
    { target: 'Oligodendroglioma', source: 'Glioma', value: '27' },
    { target: 'Optic Nerve Glioma', source: 'Glioma', value: '27' },
    { target: 'Glioblastoma', source: 'Astrocytoma', value: '31' },
    { target: 'Glioma, Subependymal', source: 'Ependymoma', value: '31' },
    { target: 'Medulloblastoma', source: 'Neuroectodermal Tumors, Primitive', value: '27' },
    { target: 'Neuroectodermal Tumors, Primitive, Peripheral', source: 'Neuroectodermal Tumors, Primitive', value: '27' },
    { target: 'Neuroblastoma', source: 'Neuroectodermal Tumors, Primitive, Peripheral', value: '31' },
    { target: 'Esthesioneuroblastoma, Olfactory', source: 'Neuroblastoma', value: '35' },
    { target: 'Ganglioneuroblastoma', source: 'Neuroblastoma', value: '35' },
    { target: 'Adenoma, Acidophil', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Adenoma, Basophil', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Adenoma, Chromophobe', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Apudoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Carcinoid Tumor', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Carcinoma, Neuroendocrine', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Melanoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Neurilemmoma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Paraganglioma', source: 'Neuroendocrine Tumors', value: '23' },
    { target: 'Malignant Carcinoid Syndrome', source: 'Carcinoid Tumor', value: '27' },
    { target: 'Carcinoid Heart Disease', source: 'Malignant Carcinoid Syndrome', value: '31' },
    { target: 'Carcinoma, Medullary', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Carcinoma, Merkel Cell', source: 'Carcinoma, Neuroendocrine', value: '27' },
    { target: 'Hutchinson\'s Melanotic Freckle', source: 'Melanoma', value: '27' },
    { target: 'Melanoma, Amelanotic', source: 'Melanoma', value: '27' },
    { target: 'Melanoma, Experimental', source: 'Melanoma', value: '27' },
    { target: 'Neuroma, Acoustic', source: 'Neurilemmoma', value: '27' },
    { target: 'Paraganglioma, Extra-Adrenal', source: 'Paraganglioma', value: '27' },
    { target: 'Pheochromocytoma', source: 'Paraganglioma', value: '27' },
    { target: 'Carotid Body Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Glomus Jugulare Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Glomus Tympanicum Tumor', source: 'Paraganglioma, Extra-Adrenal', value: '31' },
    { target: 'Multiple Myeloma', source: 'Neoplasms, Plasma Cell', value: '15' },
    { target: 'Plasmacytoma', source: 'Neoplasms, Plasma Cell', value: '15' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Neoplasms, Plasma Cell', value: '15' },
    { target: 'Leukemia, Plasma Cell', source: 'Multiple Myeloma', value: '19' },
    { target: 'Angiofibroma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Angiokeratoma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Glomus Tumor', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Hemangioma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Hemangiopericytoma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Hemangiosarcoma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Meningioma', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Sarcoma, Kaposi', source: 'Neoplasms, Vascular Tissue', value: '15' },
    { target: 'Central Nervous System Venous Angioma', source: 'Hemangioma', value: '19' },
    { target: 'Hemangioendothelioma', source: 'Hemangioma', value: '19' },
    { target: 'Hemangioma, Capillary', source: 'Hemangioma', value: '19' },
    { target: 'Hemangioma, Cavernous', source: 'Hemangioma', value: '19' },
    { target: 'Kasabach-Merritt Syndrome', source: 'Hemangioma', value: '19' },
    { target: 'Sturge-Weber Syndrome', source: 'Hemangioma', value: '19' },
    { target: 'Hemangioendothelioma, Epithelioid', source: 'Hemangioendothelioma', value: '23' },
    { target: 'Hemangioblastoma', source: 'Hemangioma, Capillary', value: '23' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Hemangioma, Cavernous', value: '23' },
    { target: 'Melanoma', source: 'Nevi and Melanomas', value: '15' },
    { target: 'Nevus', source: 'Nevi and Melanomas', value: '15' },
    { target: 'Hutchinson\'s Melanotic Freckle', source: 'Melanoma', value: '19' },
    { target: 'Melanoma, Amelanotic', source: 'Melanoma', value: '19' },
    { target: 'Melanoma, Experimental', source: 'Melanoma', value: '19' },
    { target: 'Dysplastic Nevus Syndrome', source: 'Nevus', value: '19' },
    { target: 'Nevus, Halo', source: 'Nevus', value: '19' },
    { target: 'Nevus, Intradermal', source: 'Nevus', value: '19' },
    { target: 'Nevus, Pigmented', source: 'Nevus', value: '19' },
    { target: 'Nevus, Sebaceous of Jadassohn', source: 'Nevus', value: '19' },
    { target: 'Mongolian Spot', source: 'Nevus, Pigmented', value: '23' },
    { target: 'Nevus, Blue', source: 'Nevus, Pigmented', value: '23' },
    { target: 'Nevus of Ota', source: 'Nevus, Pigmented', value: '23' },
    { target: 'Nevus, Spindle Cell', source: 'Nevus, Pigmented', value: '23' },
    { target: 'Nevus, Epithelioid and Spindle Cell', source: 'Nevus, Spindle Cell', value: '27' },
    { target: 'Ameloblastoma', source: 'Odontogenic Tumors', value: '15' },
    { target: 'Cementoma', source: 'Odontogenic Tumors', value: '15' },
    { target: 'Odontogenic Cyst, Calcifying', source: 'Odontogenicumors', value: '15' },
    { target: 'Odontogenic Tumor, Squamous', source: 'Odontogenic Tumors', value: '15' },
    { target: 'Odontoma', source: 'Odontogenic Tumors', value: '15' },
    { target: 'Abdominal Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Anal Gland Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Bone Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Breast Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Digestive System Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Endocrine Gland Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Eye Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Head and Neck Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Hematologic Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Mammary Neoplasms, Animal', source: 'Neoplasms by Site', value: '11' },
    { target: 'Nervous System Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Pelvic Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Skin Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Soft Tissue Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Splenic Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Thoracic Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Urogenital Neoplasms', source: 'Neoplasms by Site', value: '11' },
    { target: 'Peritoneal Neoplasms', source: 'Abdominal Neoplasms', value: '15' },
    { target: 'Retroperitoneal Neoplasms', source: 'Abdominal Neoplasms', value: '15' },
    { target: 'Sister Mary Joseph\'s Nodule', source: 'Abdominal Neoplasms', value: '15' },
    { target: 'Adamantinoma', source: 'Bone Neoplasms', value: '15' },
    { target: 'Femoral Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Skull Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Spinal Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Jaw Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Nose Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Orbital Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Skull Base Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Mandibular Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Maxillary Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Palatal Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Breast Carcinoma In Situ', source: 'Breast Neoplasms', value: '15' },
    { target: 'Breast Neoplasms, Male', source: 'Breast Neoplasms', value: '15' },
    { target: 'Carcinoma, Ductal, Breast', source: 'Breast Neoplasms', value: '15' },
    { target: 'Carcinoma, Lobular', source: 'Breast Neoplasms', value: '15' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Breast Neoplasms', value: '15' },
    { target: 'Inflammatory Breast Neoplasms', source: 'Breast Neoplasms', value: '15' },
    { target: 'Unilateral Breast Neoplasms', source: 'Breast Neoplasms', value: '15' },
    { target: 'Triple Negative Breast Neoplasms', source: 'Breast Neoplasms', value: '15' },
    { target: 'Biliary Tract Neoplasms', source: 'Digestive System Neoplasms', value: '15' },
    { target: 'Gastrointestinal Neoplasms', source: 'Digestive System Neoplasms', value: '15' },
    { target: 'Liver Neoplasms', source: 'Digestive System Neoplasms', value: '15' },
    { target: 'Pancreatic Neoplasms', source: 'Digestive System Neoplasms', value: '15' },
    { target: 'Peritoneal Neoplasms', source: 'Digestive System Neoplasms', value: '15' },
    { target: 'Bile Duct Neoplasms', source: 'Biliary Tract Neoplasms', value: '19' },
    { target: 'Gallbladder Neoplasms', source: 'Biliary Tract Neoplasms', value: '19' },
    { target: 'Common Bile Duct Neoplasms', source: 'Bile Duct Neoplasms', value: '23' },
    { target: 'Esophageal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '19' },
    { target: 'Intestinal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '19' },
    { target: 'Stomach Neoplasms', source: 'Gastrointestinal Neoplasms', value: '19' },
    { target: 'Cecal Neoplasms', source: 'Intestinal Neoplasms', value: '23' },
    { target: 'Colorectal Neoplasms', source: 'Intestinal Neoplasms', value: '23' },
    { target: 'Duodenal Neoplasms', source: 'Intestinal Neoplasms', value: '23' },
    { target: 'Ileal Neoplasms', source: 'Intestinal Neoplasms', value: '23' },
    { target: 'Jejunal Neoplasms', source: 'Intestinal Neoplasms', value: '23' },
    { target: 'Appendiceal Neoplasms', source: 'Cecal Neoplasms', value: '27' },
    { target: 'Adenomatous Polyposis Coli', source: 'Colorectal Neoplasms', value: '27' },
    { target: 'Colonic Neoplasms', source: 'Colorectal Neoplasms', value: '27' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Colorectal Neoplasms', value: '27' },
    { target: 'Rectal Neoplasms', source: 'Colorectal Neoplasms', value: '27' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '31' },
    { target: 'Sigmoid Neoplasms', source: 'Colonic Neoplasms', value: '31' },
    { target: 'Anus Neoplasms', source: 'Rectal Neoplasms', value: '31' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '35' },
    { target: 'Adenoma, Liver Cell', source: 'Liver Neoplasms', value: '19' },
    { target: 'Carcinoma, Hepatocellular', source: 'Liver Neoplasms', value: '19' },
    { target: 'Liver Neoplasms, Experimental', source: 'Liver Neoplasms', value: '19' },
    { target: 'Adenoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Carcinoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '23' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Vipoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Adrenal Gland Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Multiple Endocrine Neoplasia', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Ovarian Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Pancreatic Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Paraneoplastic Endocrine Syndromes', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Parathyroid Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Pituitary Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Testicular Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Thyroid Neoplasms', source: 'Endocrine Gland Neoplasms', value: '15' },
    { target: 'Adrenal Cortex Neoplasms', source: 'Adrenal Gland Neoplasms', value: '19' },
    { target: 'Adrenocortical Adenoma', source: 'Adrenal Cortex Neoplasms', value: '23' },
    { target: 'Adrenocortical Carcinoma', source: 'Adrenal Cortex Neoplasms', value: '23' },
    { target: 'Multiple Endocrine Neoplasia Type 1', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 2a', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 2b', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Granulosa Cell Tumor', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Luteoma', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Meigs Syndrome', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Thecoma', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Adenoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Carcinoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Pancreatic Neoplasms', value: '19' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '23' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'Vipoma', source: 'Carcinoma, Islet Cell', value: '23' },
    { target: 'ACTH-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '19' },
    { target: 'Growth Hormone-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '19' },
    { target: 'Prolactinoma', source: 'Pituitary Neoplasms', value: '19' },
    { target: 'Nelson Syndrome', source: 'ACTH-Secreting Pituitary Adenoma', value: '23' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '19' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Thyroid Nodule', source: 'Thyroid Neoplasms', value: '19' },
    { target: 'Conjunctival Neoplasms', source: 'Eye Neoplasms', value: '15' },
    { target: 'Intraocular Lymphoma', source: 'Eye Neoplasms', value: '15' },
    { target: 'Orbital Neoplasms', source: 'Eye Neoplasms', value: '15' },
    { target: 'Paraneoplastic Syndromes, Ocular', source: 'Eye Neoplasms', value: '15' },
    { target: 'Retinal Neoplasms', source: 'Eye Neoplasms', value: '15' },
    { target: 'Uveal Neoplasms', source: 'Eye Neoplasms', value: '15' },
    { target: 'Retinoblastoma', source: 'Retinal Neoplasms', value: '19' },
    { target: 'Choroid Neoplasms', source: 'Uveal Neoplasms', value: '19' },
    { target: 'Iris Neoplasms', source: 'Uveal Neoplasms', value: '19' },
    { target: 'Esophageal Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Facial Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Mouth Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Otorhinolaryngologic Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Parathyroid Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Thyroid Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Tracheal Neoplasms', source: 'Head and Neck Neoplasms', value: '15' },
    { target: 'Eyelid Neoplasms', source: 'Facial Neoplasms', value: '19' },
    { target: 'Gingival Neoplasms', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Leukoplakia, Oral', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Lip Neoplasms', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Palatal Neoplasms', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Salivary Gland Neoplasms', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Tongue Neoplasms', source: 'Mouth Neoplasms', value: '19' },
    { target: 'Leukoplakia, Hairy', source: 'Leukoplakia, Oral', value: '23' },
    { target: 'Parotid Neoplasms', source: 'Salivary Gland Neoplasms', value: '23' },
    { target: 'Sublingual Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '23' },
    { target: 'Submandibular Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '23' },
    { target: 'Ear Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '19' },
    { target: 'Laryngeal Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '19' },
    { target: 'Nose Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '19' },
    { target: 'Pharyngeal Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '19' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Nose Neoplasms', value: '23' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '27' },
    { target: 'Hypopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '23' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '23' },
    { target: 'Oropharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '23' },
    { target: 'Tonsillar Neoplasms', source: 'Oropharyngeal Neoplasms', value: '27' },
    { target: 'Thyroid Nodule', source: 'Thyroid Neoplasms', value: '19' },
    { target: 'Bone Marrow Neoplasms', source: 'Hematologic Neoplasms', value: '15' },
    { target: 'Polycythemia Vera', source: 'Bone Marrow Neoplasms', value: '19' },
    { target: 'Mammary Neoplasms, Experimental', source: 'Mammary Neoplasms, Animal', value: '15' },
    { target: 'Central Nervous System Neoplasms', source: 'Nervous System Neoplasms', value: '15' },
    { target: 'Cranial Nerve Neoplasms', source: 'Nervous System Neoplasms', value: '15' },
    { target: 'Paraneoplastic Syndromes, Nervous System', source: 'Nervous System Neoplasms', value: '15' },
    { target: 'Peripheral Nervous System Neoplasms', source: 'Nervous System Neoplasms', value: '15' },
    { target: 'Brain Neoplasms', source: 'Central Nervous System Neoplasms', value: '19' },
    { target: 'Central Nervous System Cysts', source: 'Central Nervous System Neoplasms', value: '19' },
    { target: 'Meningeal Neoplasms', source: 'Central Nervous System Neoplasms', value: '19' },
    { target: 'Spinal Cord Neoplasms', source: 'Central Nervous System Neoplasms', value: '19' },
    { target: 'Cerebral Ventricle Neoplasms', source: 'Brain Neoplasms', value: '23' },
    { target: 'Infratentorial Neoplasms', source: 'Brain Neoplasms', value: '23' },
    { target: 'Neurocytoma', source: 'Brain Neoplasms', value: '23' },
    { target: 'Pinealoma', source: 'Brain Neoplasms', value: '23' },
    { target: 'Supratentorial Neoplasms', source: 'Brain Neoplasms', value: '23' },
    { target: 'Choroid Plexus Neoplasms', source: 'Cerebral Ventricle Neoplasms', value: '27' },
    { target: 'Papilloma, Choroid Plexus', source: 'Choroid Plexus Neoplasms', value: '31' },
    { target: 'Brain Stem Neoplasms', source: 'Infratentorial Neoplasms', value: '27' },
    { target: 'Cerebellar Neoplasms', source: 'Infratentorial Neoplasms', value: '27' },
    { target: 'Hypothalamic Neoplasms', source: 'Supratentorial Neoplasms', value: '27' },
    { target: 'Pallister-Hall Syndrome', source: 'Hypothalamic Neoplasms', value: '31' },
    { target: 'Pituitary Neoplasms', source: 'Hypothalamic Neoplasms', value: '31' },
    { target: 'Arachnoid Cysts', source: 'Central Nervous System Cysts', value: '23' },
    { target: 'Colloid Cysts', source: 'Central Nervous System Cysts', value: '23' },
    { target: 'Meningeal Carcinomatosis', source: 'Meningeal Neoplasms', value: '23' },
    { target: 'Meningioma', source: 'Meningeal Neoplasms', value: '23' },
    { target: 'Epidural Neoplasms', source: 'Spinal Cord Neoplasms', value: '23' },
    { target: 'Neuroma, Acoustic', source: 'Cranial Nerve Neoplasms', value: '19' },
    { target: 'Optic Nerve Neoplasms', source: 'Cranial Nerve Neoplasms', value: '19' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '23' },
    { target: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Limbic Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Myelitis, Transverse', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Paraneoplastic Cerebellar Degeneration', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Paraneoplastic Polyneuropathy', source: 'Paraneoplastic Syndromes, Nervous System', value: '19' },
    { target: 'Cranial Nerve Neoplasms', source: 'Peripheral Nervous System Neoplasms', value: '19' },
    { target: 'Neuroma, Acoustic', source: 'Cranial Nerve Neoplasms', value: '23' },
    { target: 'Optic Nerve Neoplasms', source: 'Cranial Nerve Neoplasms', value: '23' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '27' },
    { target: 'Acanthoma', source: 'Skin Neoplasms', value: '15' },
    { target: 'Sebaceous Gland Neoplasms', source: 'Skin Neoplasms', value: '15' },
    { target: 'Sweat Gland Neoplasms', source: 'Skin Neoplasms', value: '15' },
    { target: 'Muir-Torre Syndrome', source: 'Sebaceous Gland Neoplasms', value: '19' },
    { target: 'Muscle Neoplasms', source: 'Soft Tissue Neoplasms', value: '15' },
    { target: 'Vascular Neoplasms', source: 'Soft Tissue Neoplasms', value: '15' },
    { target: 'Heart Neoplasms', source: 'Thoracic Neoplasms', value: '15' },
    { target: 'Mediastinal Neoplasms', source: 'Thoracic Neoplasms', value: '15' },
    { target: 'Respiratory Tract Neoplasms', source: 'Thoracic Neoplasms', value: '15' },
    { target: 'Thymus Neoplasms', source: 'Thoracic Neoplasms', value: '15' },
    { target: 'Carney Complex', source: 'Heart Neoplasms', value: '19' },
    { target: 'Lung Neoplasms', source: 'Respiratory Tract Neoplasms', value: '19' },
    { target: 'Pleural Neoplasms', source: 'Respiratory Tract Neoplasms', value: '19' },
    { target: 'Tracheal Neoplasms', source: 'Respiratory Tract Neoplasms', value: '19' },
    { target: 'Bronchial Neoplasms', source: 'Lung Neoplasms', value: '23' },
    { target: 'Multiple Pulmonary Nodules', source: 'Lung Neoplasms', value: '23' },
    { target: 'Pancoast Syndrome', source: 'Lung Neoplasms', value: '23' },
    { target: 'Pulmonary Blastoma', source: 'Lung Neoplasms', value: '23' },
    { target: 'Pulmonary Sclerosing Hemangioma', source: 'Lung Neoplasms', value: '23' },
    { target: 'Carcinoma, Bronchogenic', source: 'Bronchial Neoplasms', value: '27' },
    { target: 'Carcinoma, Non-Small-Cell Lung', source: 'Carcinoma, Bronchogenic', value: '31' },
    { target: 'Small Cell Lung Carcinoma', source: 'Carcinoma, Bronchogenic', value: '31' },
    { target: 'Pleural Effusion, Malignant', source: 'Pleural Neoplasms', value: '23' },
    { target: 'Solitary Fibrous Tumor, Pleural', source: 'Pleural Neoplasms', value: '23' },
    { target: 'Thymoma', source: 'Thymus Neoplasms', value: '19' },
    { target: 'Genital Neoplasms, Female', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Genital Neoplasms, Male', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Urologic Neoplasms', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Venereal Tumors, Veterinary', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Fallopian Tube Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Uterine Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Vaginal Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Vulvar Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Endometrial Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Uterine Cervical Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Carcinoma, Endometrioid', source: 'Endometrial Neoplasms', value: '27' },
    { target: 'Penile Neoplasms', source: 'Genital Neoplasms, Male', value: '19' },
    { target: 'Prostatic Neoplasms', source: 'Genital Neoplasms, Male', value: '19' },
    { target: 'Testicular Neoplasms', source: 'Genital Neoplasms, Male', value: '19' },
    { target: 'Prostatic Neoplasms, Castration-Resistant', source: 'Prostatic Neoplasms', value: '23' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '23' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '27' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '27' },
    { target: 'Kidney Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Ureteral Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Urethral Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Urinary Bladder Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Carcinoma, Renal Cell', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Wilms Tumor', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Nephroma, Mesoblastic', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'Carcinoma 256, Walker', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Carcinoma, Brown-Pearce', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Carcinoma, Ehrlich Tumor', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Carcinoma, Krebs 2', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Carcinoma, Lewis Lung', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Leukemia, Experimental', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Liver Neoplasms, Experimental', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Mammary Neoplasms, Experimental', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Melanoma, Experimental', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Sarcoma, Experimental', source: 'Neoplasms, Experimental', value: '11' },
    { target: 'Avian Leukosis', source: 'Leukemia, Experimental', value: '15' },
    { target: 'Leukemia L1210', source: 'Leukemia, Experimental', value: '15' },
    { target: 'Leukemia L5178', source: 'Leukemia, Experimental', value: '15' },
    { target: 'Leukemia P388', source: 'Leukemia, Experimental', value: '15' },
    { target: 'Sarcoma 37', source: 'Sarcoma, Experimental', value: '15' },
    { target: 'Sarcoma 180', source: 'Sarcoma, Experimental', value: '15' },
    { target: 'Sarcoma, Avian', source: 'Sarcoma, Experimental', value: '15' },
    { target: 'Sarcoma, Yoshida', source: 'Sarcoma, Experimental', value: '15' },
    { target: 'Hamartoma Syndrome, Multiple', source: 'Neoplasms, Multiple Primary', value: '11' },
    { target: 'Multiple Endocrine Neoplasia', source: 'Neoplasms, Multiple Primary', value: '11' },
    { target: 'Tuberous Sclerosis', source: 'Neoplasms, Multiple Primary', value: '11' },
    { target: 'Proteus Syndrome', source: 'Hamartoma Syndrome, Multiple', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 1', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2a', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2b', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Leukemia, Radiation-Induced', source: 'Neoplasms, Radiation-Induced', value: '11' },
    { target: 'Anaplasia', source: 'Neoplastic Processes', value: '11' },
    { target: 'Carcinogenesis', source: 'Neoplastic Processes', value: '11' },
    { target: 'Neoplasm Invasiveness', source: 'Neoplastic Processes', value: '11' },
    { target: 'Neoplasm Metastasis', source: 'Neoplastic Processes', value: '11' },
    { target: 'Neoplasm Recurrence, Local', source: 'Neoplastic Processes', value: '11' },
    { target: 'Neoplasm Regression, Spontaneous', source: 'Neoplastic Processes', value: '11' },
    { target: 'Neoplasm, Residual', source: 'Neoplastic Processes', value: '11' },
    { target: 'Cell Transformation, Neoplastic', source: 'Carcinogenesis', value: '15' },
    { target: 'Cocarcinogenesis', source: 'Carcinogenesis', value: '15' },
    { target: 'Blast Crisis', source: 'Cell Transformation, Neoplastic', value: '19' },
    { target: 'Cell Transformation, Viral', source: 'Cell Transformation, Neoplastic', value: '19' },
    { target: 'Leukemic Infiltration', source: 'Neoplasm Invasiveness', value: '15' },
    { target: 'Lymphatic Metastasis', source: 'Neoplasm Metastasis', value: '15' },
    { target: 'Neoplasm Micrometastasis', source: 'Neoplasm Metastasis', value: '15' },
    { target: 'Neoplasm Seeding', source: 'Neoplasm Metastasis', value: '15' },
    { target: 'Neoplasms, Unknown Primary', source: 'Neoplasm Metastasis', value: '15' },
    { target: 'Neoplastic Cells, Circulating', source: 'Neoplasm Metastasis', value: '15' },
    { target: 'Adenomatous Polyposis Coli', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Birt-Hogg-Dube Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Dysplastic Nevus Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Exostoses, Multiple Hereditary', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Hamartoma Syndrome, Multiple', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Li-Fraumeni Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Multiple Endocrine Neoplasia', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Tuberous Sclerosis', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Wilms Tumor', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Neurofibromatoses', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Peutz-Jeghers Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '11' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '15' },
    { target: 'Lynch Syndrome II', source: 'Colorectal Neoplasms, Hereditary Nonpolyposis', value: '15' },
    { target: 'Muir-Torre Syndrome', source: 'Lynch Syndrome II', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 1', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2a', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2b', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '15' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '15' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '15' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '15' },
    { target: 'Paraneoplastic Endocrine Syndromes', source: 'Paraneoplastic Syndromes', value: '11' },
    { target: 'Paraneoplastic Syndromes, Nervous System', source: 'Paraneoplastic Syndromes', value: '11' },
    { target: 'Paraneoplastic Syndromes, Ocular', source: 'Paraneoplastic Syndromes', value: '11' },
    { target: 'ACTH Syndrome, Ectopic', source: 'Paraneoplastic Endocrine Syndromes', value: '15' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Paraneoplastic Endocrine Syndromes', value: '15' },
    { target: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Limbic Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Myelitis, Transverse', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Paraneoplastic Cerebellar Degeneration', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Paraneoplastic Polyneuropathy', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Aberrant Crypt Foci', source: 'Precancerous Conditions', value: '11' },
    { target: 'Erythroplasia', source: 'Precancerous Conditions', value: '11' },
    { target: 'Keratosis, Actinic', source: 'Precancerous Conditions', value: '11' },
    { target: 'Leukoplakia', source: 'Precancerous Conditions', value: '11' },
    { target: 'Lymphomatoid Granulomatosis', source: 'Precancerous Conditions', value: '11' },
    { target: 'Preleukemia', source: 'Precancerous Conditions', value: '11' },
    { target: 'Uterine Cervical Dysplasia', source: 'Precancerous Conditions', value: '11' },
    { target: 'Xeroderma Pigmentosum', source: 'Precancerous Conditions', value: '11' },
    { target: 'Leukoplakia, Oral', source: 'Leukoplakia', value: '15' },
    { target: 'Leukoplakia, Hairy', source: 'Leukoplakia, Oral', value: '19' },
    { target: 'Atypical Squamous Cells of the Cervix', source: 'Uterine Cervical Dysplasia', value: '15' },
    { target: 'Squamous Intraepithelial Lesions of the Cervix', source: 'Uterine Cervical Dysplasia', value: '15' },
    { target: 'Trophoblastic Neoplasms', source: 'Pregnancy Complications, Neoplastic', value: '11' },
    { target: 'Choriocarcinoma', source: 'Trophoblastic Neoplasms', value: '15' },
    { target: 'Gestational Trophoblastic Disease', source: 'Trophoblastic Neoplasms', value: '15' },
    { target: 'Choriocarcinoma, Non-gestational', source: 'Choriocarcinoma', value: '19' },
    { target: 'Trophoblastic Tumor, Placental Site', source: 'Choriocarcinoma', value: '19' },
    { target: 'Hydatidiform Mole', source: 'Gestational Trophoblastic Disease', value: '19' },
    { target: 'Hydatidiform Mole, Invasive', source: 'Hydatidiform Mole', value: '23' },
    { target: 'Bone Cysts', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Diseases, Developmental', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Diseases, Endocrine', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Diseases, Infectious', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Diseases, Metabolic', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Malalignment', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Neoplasms', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Resorption', source: 'Bone Diseases', value: '11' },
    { target: 'Coxa Magna', source: 'Bone Diseases', value: '11' },
    { target: 'Coxa Valga', source: 'Bone Diseases', value: '11' },
    { target: 'Joint Dislocations', source: 'Bone Diseases', value: '11' },
    { target: 'Eosinophilic Granuloma', source: 'Bone Diseases', value: '11' },
    { target: 'Epiphyses, Slipped', source: 'Bone Diseases', value: '11' },
    { target: 'Genu Valgum', source: 'Bone Diseases', value: '11' },
    { target: 'Genu Varum', source: 'Bone Diseases', value: '11' },
    { target: 'Hyperostosis', source: 'Bone Diseases', value: '11' },
    { target: 'Osteitis', source: 'Bone Diseases', value: '11' },
    { target: 'Osteitis Deformans', source: 'Bone Diseases', value: '11' },
    { target: 'Osteoarthropathy, Primary Hypertrophic', source: 'Bone Diseases', value: '11' },
    { target: 'Osteoarthropathy, Secondary Hypertrophic', source: 'Bone Diseases', value: '11' },
    { target: 'Osteochondritis', source: 'Bone Diseases', value: '11' },
    { target: 'Osteochondrosis', source: 'Bone Diseases', value: '11' },
    { target: 'Osteonecrosis', source: 'Bone Diseases', value: '11' },
    { target: 'Spinal Diseases', source: 'Bone Diseases', value: '11' },
    { target: 'Bone Cysts, Aneurysmal', source: 'Bone Cysts', value: '15' },
    { target: 'Acro-Osteolysis', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Dwarfism', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Dysostoses', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Funnel Chest', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Gigantism', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Leg Length Inequality', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Marfan Syndrome', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Osteochondrodysplasias', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Osteolysis, Essential', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Pectus Carinatum', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Platybasia', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Proteus Syndrome', source: 'Bone Diseases, Developmental', value: '15' },
    { target: 'Hajdu-Cheney Syndrome', source: 'Acro-Osteolysis', value: '19' },
    { target: 'Achondroplasia', source: 'Dwarfism', value: '19' },
    { target: 'Cockayne Syndrome', source: 'Dwarfism', value: '19' },
    { target: 'Congenital Hypothyroidism', source: 'Dwarfism', value: '19' },
    { target: 'Dwarfism, Pituitary', source: 'Dwarfism', value: '19' },
    { target: 'Laron Syndrome', source: 'Dwarfism', value: '19' },
    { target: 'Mulibrey Nanism', source: 'Dwarfism', value: '19' },
    { target: 'Weill-Marchesani Syndrome', source: 'Dwarfism', value: '19' },
    { target: 'Thanatophoric Dysplasia', source: 'Achondroplasia', value: '23' },
    { target: 'Craniofacial Dysostosis', source: 'Dysostoses', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Dysostoses', value: '19' },
    { target: 'Klippel-Feil Syndrome', source: 'Dysostoses', value: '19' },
    { target: 'Orofaciodigital Syndromes', source: 'Dysostoses', value: '19' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Dysostoses', value: '19' },
    { target: 'Synostosis', source: 'Dysostoses', value: '19' },
    { target: 'Hallermann\'s Syndrome', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Hypertelorism', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Mandibulofacial Dysostosis', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Goldenhar Syndrome', source: 'Mandibulofacial Dysostosis', value: '27' },
    { target: 'Antley-Bixler Syndrome Phenotype', source: 'Synostosis', value: '23' },
    { target: 'Craniosynostoses', source: 'Synostosis', value: '23' },
    { target: 'Syndactyly', source: 'Synostosis', value: '23' },
    { target: 'Tarsal Coalition', source: 'Synostosis', value: '23' },
    { target: 'Acrocephalosyndactylia', source: 'Craniosynostoses', value: '27' },
    { target: 'Acrocephalosyndactylia', source: 'Syndactyly', value: '27' },
    { target: 'Fraser Syndrome', source: 'Syndactyly', value: '27' },
    { target: 'Poland Syndrome', source: 'Syndactyly', value: '27' },
    { target: 'Achondroplasia', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Acquired Hyperostosis Syndrome', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Camurati-Engelmann Syndrome', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Chondrodysplasia Punctata', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Cleidocranial Dysplasia', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Ellis-Van Creveldyndrome', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Enchondromatosis', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Fibrous Dysplasia of Bone', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Hyperostosis, Cortical, Congenital', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Hyperostosis Frontalis Interna', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Kashin-Beck Disease', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Langer-Giedion Syndrome', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Osteochondroma', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Osteogenesis Imperfecta', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Osteosclerosis', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Pycnodysostosis', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Short Rib-Polydactyly Syndrome', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Slipped Capital Femoral Epiphyses', source: 'Osteochondrodysplasias', value: '19' },
    { target: 'Thanatophoric Dysplasia', source: 'Achondroplasia', value: '23' },
    { target: 'Chondrodysplasia Punctata, Rhizomelic', source: 'Chondrodysplasia Punctata', value: '23' },
    { target: 'Cherubism', source: 'Fibrous Dysplasia of Bone', value: '23' },
    { target: 'Fibrous Dysplasia, Monostotic', source: 'Fibrous Dysplasia of Bone', value: '23' },
    { target: 'Fibrous Dysplasia, Polyostotic', source: 'Fibrous Dysplasia of Bone', value: '23' },
    { target: 'Osteochondromatosis', source: 'Osteochondroma', value: '23' },
    { target: 'Exostoses, Multiple Hereditary', source: 'Osteochondromatosis', value: '27' },
    { target: 'Melorheostosis', source: 'Osteosclerosis', value: '23' },
    { target: 'Osteopetrosis', source: 'Osteosclerosis', value: '23' },
    { target: 'Osteopoikilosis', source: 'Osteosclerosis', value: '23' },
    { target: 'Acromegaly', source: 'Bone Diseases, Endocrine', value: '15' },
    { target: 'Congenital Hypothyroidism', source: 'Bone Diseases, Endocrine', value: '15' },
    { target: 'Dwarfism, Pituitary', source: 'Bone Diseases, Endocrine', value: '15' },
    { target: 'Gigantism', source: 'Bone Diseases, Endocrine', value: '15' },
    { target: 'Osteitis Fibrosa Cystica', source: 'Bone Diseases, Endocrine', value: '15' },
    { target: 'Osteomyelitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Periostitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Spondylitis', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Tuberculosis, Osteoarticular', source: 'Bone Diseases, Infectious', value: '15' },
    { target: 'Mastoiditis', source: 'Osteomyelitis', value: '19' },
    { target: 'Petrositis', source: 'Osteomyelitis', value: '19' },
    { target: 'Pott Puffy Tumor', source: 'Osteomyelitis', value: '19' },
    { target: 'Discitis', source: 'Spondylitis', value: '19' },
    { target: 'Tuberculosis, Spinal', source: 'Tuberculosis, Osteoarticular', value: '19' },
    { target: 'Bone Demineralization, Pathologic', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Mucolipidoses', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Osteoporosis', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Pseudohypoparathyroidism', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Rickets', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Decalcification, Pathologic', source: 'Bone Demineralization, Pathologic', value: '19' },
    { target: 'Female Athlete Triad Syndrome', source: 'Osteoporosis', value: '19' },
    { target: 'Osteoporosis, Postmenopausal', source: 'Osteoporosis', value: '19' },
    { target: 'Pseudopseudohypoparathyroidism', source: 'Pseudohypoparathyroidism', value: '19' },
    { target: 'Osteomalacia', source: 'Rickets', value: '19' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Rickets', value: '19' },
    { target: 'Rickets, Hypophosphatemic', source: 'Rickets', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Rickets, Hypophosphatemic', value: '23' },
    { target: 'Bone Anteversion', source: 'Bone Malalignment', value: '15' },
    { target: 'Bone Retroversion', source: 'Bone Malalignment', value: '15' },
    { target: 'Coxa Vara', source: 'Bone Anteversion', value: '19' },
    { target: 'Coxa Valga', source: 'Bone Retroversion', value: '19' },
    { target: 'Adamantinoma', source: 'Bone Neoplasms', value: '15' },
    { target: 'Femoral Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Skull Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Spinal Neoplasms', source: 'Bone Neoplasms', value: '15' },
    { target: 'Jaw Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Nose Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Orbital Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Skull Base Neoplasms', source: 'Skull Neoplasms', value: '19' },
    { target: 'Mandibular Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Maxillary Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Palatal Neoplasms', source: 'Jaw Neoplasms', value: '23' },
    { target: 'Ainhum', source: 'Bone Resorption', value: '15' },
    { target: 'Alveolar Bone Loss', source: 'Bone Resorption', value: '15' },
    { target: 'Osteolysis', source: 'Bone Resorption', value: '15' },
    { target: 'Acro-Osteolysis', source: 'Osteolysis', value: '19' },
    { target: 'Osteolysis, Essential', source: 'Osteolysis', value: '19' },
    { target: 'Hajdu-Cheney Syndrome', source: 'Acro-Osteolysis', value: '23' },
    { target: 'Diastasis, Bone', source: 'Joint Dislocations', value: '15' },
    { target: 'Diastasis, Muscle', source: 'Joint Dislocations', value: '15' },
    { target: 'Fracture Dislocation', source: 'Joint Dislocations', value: '15' },
    { target: 'Hip Dislocation', source: 'Joint Dislocations', value: '15' },
    { target: 'Knee Dislocation', source: 'Joint Dislocations', value: '15' },
    { target: 'Patellar Dislocation', source: 'Joint Dislocations', value: '15' },
    { target: 'Shoulder Dislocation', source: 'Joint Dislocations', value: '15' },
    { target: 'Pubic Symphysis Diastasis', source: 'Diastasis, Bone', value: '19' },
    { target: 'Colles\' Fracture', source: 'Fracture Dislocation', value: '19' },
    { target: 'Monteggia\'s Fracture', source: 'Fracture Dislocation', value: '19' },
    { target: 'Salter-Harris Fractures', source: 'Fracture Dislocation', value: '19' },
    { target: 'Slipped Capital Femoral Epiphyses', source: 'Epiphyses, Slipped', value: '15' },
    { target: 'Exostoses', source: 'Hyperostosis', value: '15' },
    { target: 'Hyperostosis, Cortical, Congenital', source: 'Hyperostosis', value: '15' },
    { target: 'Hyperostosis, Diffuse Idiopathic Skeletal', source: 'Hyperostosis', value: '15' },
    { target: 'Hyperostosis Frontalis Interna', source: 'Hyperostosis', value: '15' },
    { target: 'Hyperostosis, Sternocostoclavicular', source: 'Hyperostosis', value: '15' },
    { target: 'Exostoses, Multiple Hereditary', source: 'Exostoses', value: '19' },
    { target: 'Heel Spur', source: 'Exostoses', value: '19' },
    { target: 'Osteophyte', source: 'Exostoses', value: '19' },
    { target: 'Osteochondritis Dissecans', source: 'Osteochondritis', value: '15' },
    { target: 'Spinal Osteochondrosis', source: 'Osteochondrosis', value: '15' },
    { target: 'Scheuermann Disease', source: 'Spinal Osteochondrosis', value: '19' },
    { target: 'Bisphosphonate-Associated Osteonecrosis of the Jaw', source: 'Osteonecrosis', value: '15' },
    { target: 'Femur Head Necrosis', source: 'Osteonecrosis', value: '15' },
    { target: 'Legg-Calve-Perthes Disease', source: 'Femur Head Necrosis', value: '19' },
    { target: 'Intervertebral Disc Degeneration', source: 'Spinal Diseases', value: '15' },
    { target: 'Intervertebral Disc Displacement', source: 'Spinal Diseases', value: '15' },
    { target: 'Ossification of Posterior Longitudinal Ligament', source: 'Spinal Diseases', value: '15' },
    { target: 'Platybasia', source: 'Spinal Diseases', value: '15' },
    { target: 'Posterior Cervical Sympathetic Syndrome', source: 'Spinal Diseases', value: '15' },
    { target: 'Spinal Curvatures', source: 'Spinal Diseases', value: '15' },
    { target: 'Spinal Neoplasms', source: 'Spinal Diseases', value: '15' },
    { target: 'Spinal Osteochondrosis', source: 'Spinal Diseases', value: '15' },
    { target: 'Spinal Osteophytosis', source: 'Spinal Diseases', value: '15' },
    { target: 'Spinal Stenosis', source: 'Spinal Diseases', value: '15' },
    { target: 'Spondylitis', source: 'Spinal Diseases', value: '15' },
    { target: 'Spondylosis', source: 'Spinal Diseases', value: '15' },
    { target: 'Kyphosis', source: 'Spinal Curvatures', value: '19' },
    { target: 'Lordosis', source: 'Spinal Curvatures', value: '19' },
    { target: 'Scoliosis', source: 'Spinal Curvatures', value: '19' },
    { target: 'Scheuermann Disease', source: 'Kyphosis', value: '23' },
    { target: 'Scheuermann Disease', source: 'Spinal Osteochondrosis', value: '19' },
    { target: 'Hyperostosis, Diffuse Idiopathic Skeletal', source: 'Spinal Osteophytosis', value: '19' },
    { target: 'Discitis', source: 'Spondylitis', value: '19' },
    { target: 'Spondylarthritis', source: 'Spondylitis', value: '19' },
    { target: 'Tuberculosis, Spinal', source: 'Spondylitis', value: '19' },
    { target: 'Osteoarthritis, Spine', source: 'Spondylarthritis', value: '23' },
    { target: 'Spondylarthropathies', source: 'Spondylarthritis', value: '23' },
    { target: 'Arthritis, Psoriatic', source: 'Spondylarthropathies', value: '27' },
    { target: 'Arthritis, Reactive', source: 'Spondylarthropathies', value: '27' },
    { target: 'Spondylitis, Ankylosing', source: 'Spondylarthropathies', value: '27' },
    { target: 'Spondylolysis', source: 'Spondylosis', value: '19' },
    { target: 'Spondylolisthesis', source: 'Spondylolysis', value: '23' },
    { target: 'Chondromalacia Patellae', source: 'Cartilage Diseases', value: '11' },
    { target: 'Laryngomalacia', source: 'Cartilage Diseases', value: '11' },
    { target: 'Osteochondritis', source: 'Cartilage Diseases', value: '11' },
    { target: 'Pectus Carinatum', source: 'Cartilage Diseases', value: '11' },
    { target: 'Polychondritis, Relapsing', source: 'Cartilage Diseases', value: '11' },
    { target: 'Tietze\'s Syndrome', source: 'Cartilage Diseases', value: '11' },
    { target: 'Tracheobronchomalacia', source: 'Cartilage Diseases', value: '11' },
    { target: 'Bronchomalacia', source: 'Tracheobronchomalacia', value: '15' },
    { target: 'Tracheomalacia', source: 'Tracheobronchomalacia', value: '15' },
    { target: 'Fasciitis, Necrotizing', source: 'Fasciitis', value: '11' },
    { target: 'Fasciitis, Plantar', source: 'Fasciitis', value: '11' },
    { target: 'Foot Deformities, Acquired', source: 'Foot Deformities', value: '11' },
    { target: 'Foot Deformities, Congenital', source: 'Foot Deformities', value: '11' },
    { target: 'Hallux Valgus', source: 'Foot Deformities', value: '11' },
    { target: 'Hallux Varus', source: 'Foot Deformities', value: '11' },
    { target: 'Hammer Toe Syndrome', source: 'Foot Deformities', value: '11' },
    { target: 'Metatarsal Valgus', source: 'Foot Deformities', value: '11' },
    { target: 'Metatarsus Varus', source: 'Foot Deformities', value: '11' },
    { target: 'Bunion', source: 'Foot Deformities, Acquired', value: '15' },
    { target: 'Hallux Limitus', source: 'Foot Deformities, Acquired', value: '15' },
    { target: 'Hallux Rigidus', source: 'Foot Deformities, Acquired', value: '15' },
    { target: 'Talipes', source: 'Foot Deformities, Acquired', value: '15' },
    { target: 'Bunion, Tailor\'s', source: 'Bunion', value: '19' },
    { target: 'Clubfoot', source: 'Talipes', value: '19' },
    { target: 'Equinus Deformity', source: 'Talipes', value: '19' },
    { target: 'Flatfoot', source: 'Talipes', value: '19' },
    { target: 'Talipes Cavus', source: 'Talipes', value: '19' },
    { target: 'Talipes', source: 'Foot Deformities, Congenital', value: '15' },
    { target: 'Tarsal Coalition', source: 'Foot Deformities, Congenital', value: '15' },
    { target: 'Clubfoot', source: 'Talipes', value: '19' },
    { target: 'Equinus Deformity', source: 'Talipes', value: '19' },
    { target: 'Flatfoot', source: 'Talipes', value: '19' },
    { target: 'Talipes Cavus', source: 'Talipes', value: '19' },
    { target: 'Fasciitis, Plantar', source: 'Foot Diseases', value: '11' },
    { target: 'Fibromatosis, Plantar', source: 'Foot Diseases', value: '11' },
    { target: 'Heel Spur', source: 'Foot Diseases', value: '11' },
    { target: 'Metatarsalgia', source: 'Foot Diseases', value: '11' },
    { target: 'Posterior Tibial Tendon Dysfunction', source: 'Foot Diseases', value: '11' },
    { target: 'Morton Neuroma', source: 'Metatarsalgia', value: '15' },
    { target: 'Hand Deformities, Acquired', source: 'Hand Deformities', value: '11' },
    { target: 'Hand Deformities, Congenital', source: 'Hand Deformities', value: '11' },
    { target: 'Bisphosphonate-Associated Osteonecrosis of the Jaw', source: 'Jaw Diseases', value: '11' },
    { target: 'Cherubism', source: 'Jaw Diseases', value: '11' },
    { target: 'Granuloma, Giant Cell', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Abnormalities', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Cysts', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw, Edentulous', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Neoplasms', source: 'Jaw Diseases', value: '11' },
    { target: 'Mandibular Diseases', source: 'Jaw Diseases', value: '11' },
    { target: 'Maxillary Diseases', source: 'Jaw Diseases', value: '11' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Nonodontogenic Cysts', source: 'Jaw Cysts', value: '15' },
    { target: 'Odontogenic Cysts', source: 'Jaw Cysts', value: '15' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Dentigerous Cyst', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Odontogenic Cyst, Calcifying', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Periodontal Cyst', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Radicular Cyst', source: 'Periodontal Cyst', value: '23' },
    { target: 'Jaw, Edentulous, Partially', source: 'Jaw, Edentulous', value: '15' },
    { target: 'Mandibular Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Maxillary Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Palatal Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Craniomandibular Disorders', source: 'Mandibular Diseases', value: '15' },
    { target: 'Mandibular Neoplasms', source: 'Mandibular Diseases', value: '15' },
    { target: 'Prognathism', source: 'Mandibular Diseases', value: '15' },
    { target: 'Temporomandibular Joint Disorders', source: 'Craniomandibular Disorders', value: '19' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Temporomandibular Joint Disorders', value: '23' },
    { target: 'Maxillary Neoplasms', source: 'Maxillary Diseases', value: '15' },
    { target: 'Ankylosis', source: 'Joint Diseases', value: '11' },
    { target: 'Arthralgia', source: 'Joint Diseases', value: '11' },
    { target: 'Arthritis', source: 'Joint Diseases', value: '11' },
    { target: 'Arthrogryposis', source: 'Joint Diseases', value: '11' },
    { target: 'Arthropathy, Neurogenic', source: 'Joint Diseases', value: '11' },
    { target: 'Bursitis', source: 'Joint Diseases', value: '11' },
    { target: 'Chondromatosis, Synovial', source: 'Joint Diseases', value: '11' },
    { target: 'Contracture', source: 'Joint Diseases', value: '11' },
    { target: 'Crystal Arthropathies', source: 'Joint Diseases', value: '11' },
    { target: 'Femoracetabular Impingement', source: 'Joint Diseases', value: '11' },
    { target: 'Hallux Limitus', source: 'Joint Diseases', value: '11' },
    { target: 'Hallux Rigidus', source: 'Joint Diseases', value: '11' },
    { target: 'Hemarthrosis', source: 'Joint Diseases', value: '11' },
    { target: 'Hydrarthrosis', source: 'Joint Diseases', value: '11' },
    { target: 'Joint Deformities, Acquired', source: 'Joint Diseases', value: '11' },
    { target: 'Joint Instability', source: 'Joint Diseases', value: '11' },
    { target: 'Joint Loose Bodies', source: 'Joint Diseases', value: '11' },
    { target: 'Metatarsalgia', source: 'Joint Diseases', value: '11' },
    { target: 'Nail-Patella Syndrome', source: 'Joint Diseases', value: '11' },
    { target: 'Osteoarthropathy, Primary Hypertrophic', source: 'Joint Diseases', value: '11' },
    { target: 'Osteoarthropathy, Secondary Hypertrophic', source: 'Joint Diseases', value: '11' },
    { target: 'Patellofemoral Pain Syndrome', source: 'Joint Diseases', value: '11' },
    { target: 'Shoulder Impingement Syndrome', source: 'Joint Diseases', value: '11' },
    { target: 'Synovitis', source: 'Joint Diseases', value: '11' },
    { target: 'Temporomandibular Joint Disorders', source: 'Joint Diseases', value: '11' },
    { target: 'Spondylitis, Ankylosing', source: 'Ankylosis', value: '15' },
    { target: 'Shoulder Pain', source: 'Arthralgia', value: '15' },
    { target: 'Arthritis, Experimental', source: 'Arthritis', value: '15' },
    { target: 'Arthritis, Infectious', source: 'Arthritis', value: '15' },
    { target: 'Arthritis, Juvenile', source: 'Arthritis', value: '15' },
    { target: 'Arthritis, Psoriatic', source: 'Arthritis', value: '15' },
    { target: 'Arthritis, Rheumatoid', source: 'Arthritis', value: '15' },
    { target: 'Chondrocalcinosis', source: 'Arthritis', value: '15' },
    { target: 'Gout', source: 'Arthritis', value: '15' },
    { target: 'Osteoarthritis', source: 'Arthritis', value: '15' },
    { target: 'Periarthritis', source: 'Arthritis', value: '15' },
    { target: 'Rheumatic Fever', source: 'Arthritis', value: '15' },
    { target: 'Sacroiliitis', source: 'Arthritis', value: '15' },
    { target: 'Spondylarthritis', source: 'Arthritis', value: '15' },
    { target: 'Arthritis, Reactive', source: 'Arthritis, Infectious', value: '19' },
    { target: 'Caplan Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Felty Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Rheumatoid Nodule', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Rheumatoid Vasculitis', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Sjogren\'s Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Still\'s Disease, Adult-Onset', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Rotator Cuff Tear Arthropathy', source: 'Chondrocalcinosis', value: '19' },
    { target: 'Arthritis, Gouty', source: 'Gout', value: '19' },
    { target: 'Osteoarthritis, Hip', source: 'Osteoarthritis', value: '19' },
    { target: 'Osteoarthritis, Knee', source: 'Osteoarthritis', value: '19' },
    { target: 'Osteoarthritis, Spine', source: 'Osteoarthritis', value: '19' },
    { target: 'Rheumatic Nodule', source: 'Rheumatic Fever', value: '19' },
    { target: 'Wissler\'s Syndrome', source: 'Rheumatic Fever', value: '19' },
    { target: 'Osteoarthritis, Spine', source: 'Spondylarthritis', value: '19' },
    { target: 'Spondylarthropathies', source: 'Spondylarthritis', value: '19' },
    { target: 'Arthritis, Psoriatic', source: 'Spondylarthropathies', value: '23' },
    { target: 'Arthritis, Reactive', source: 'Spondylarthropathies', value: '23' },
    { target: 'Spondylitis, Ankylosing', source: 'Spondylarthropathies', value: '23' },
    { target: 'Periarthritis', source: 'Bursitis', value: '15' },
    { target: 'Hip Contracture', source: 'Contracture', value: '15' },
    { target: 'Ischemic Contracture', source: 'Contracture', value: '15' },
    { target: 'Chondrocalcinosis', source: 'Crystal Arthropathies', value: '15' },
    { target: 'Rotator Cuff Tear Arthropathy', source: 'Crystal Arthropathies', value: '15' },
    { target: 'Gout', source: 'Crystal Arthropathies', value: '15' },
    { target: 'Arthritis, Gouty', source: 'Gout', value: '19' },
    { target: 'Morton Neuroma', source: 'Metatarsalgia', value: '15' },
    { target: 'Giant Cell Tumor of Tendon Sheath', source: 'Synovitis', value: '15' },
    { target: 'Synovitis, Pigmented Villonodular', source: 'Giant Cell Tumor of Tendon Sheath', value: '19' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Temporomandibular Joint Disorders', value: '15' },
    { target: 'Arthrogryposis', source: 'Muscular Diseases', value: '11' },
    { target: 'Compartment Syndromes', source: 'Muscular Diseases', value: '11' },
    { target: 'Contracture', source: 'Muscular Diseases', value: '11' },
    { target: 'Craniomandibular Disorders', source: 'Muscular Diseases', value: '11' },
    { target: 'Eosinophilia-Myalgia Syndrome', source: 'Muscular Diseases', value: '11' },
    { target: 'Fatigue Syndrome, Chronic', source: 'Muscular Diseases', value: '11' },
    { target: 'Fibromyalgia', source: 'Muscular Diseases', value: '11' },
    { target: 'Isaacs Syndrome', source: 'Muscular Diseases', value: '11' },
    { target: 'Medial Tibial Stress Syndrome', source: 'Muscular Diseases', value: '11' },
    { target: 'Mitochondrial Myopathies', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscle Cramp', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscle Neoplasms', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscle Rigidity', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscle Spasticity', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscle Weakness', source: 'Muscular Diseases', value: '11' },
    { target: 'Muscular Disorders, Atrophic', source: 'Muscular Diseases', value: '11' },
    { target: 'Musculoskeletal Pain', source: 'Muscular Diseases', value: '11' },
    { target: 'Myalgia', source: 'Muscular Diseases', value: '11' },
    { target: 'Myofascial Pain Syndromes', source: 'Muscular Diseases', value: '11' },
    { target: 'Myopathies, Structural, Congenital', source: 'Muscular Diseases', value: '11' },
    { target: 'Myositis', source: 'Muscular Diseases', value: '11' },
    { target: 'Myotonic Disorders', source: 'Muscular Diseases', value: '11' },
    { target: 'Paralyses, Familial Periodic', source: 'Muscular Diseases', value: '11' },
    { target: 'Polymyalgia Rheumatica', source: 'Muscular Diseases', value: '11' },
    { target: 'Rhabdomyolysis', source: 'Muscular Diseases', value: '11' },
    { target: 'Tendinopathy', source: 'Muscular Diseases', value: '11' },
    { target: 'Anterior Compartment Syndrome', source: 'Compartment Syndromes', value: '15' },
    { target: 'Intra-Abdominal Hypertension', source: 'Compartment Syndromes', value: '15' },
    { target: 'Ischemic Contracture', source: 'Compartment Syndromes', value: '15' },
    { target: 'Dupuytren Contracture', source: 'Contracture', value: '15' },
    { target: 'Fibromatosis, Plantar', source: 'Contracture', value: '15' },
    { target: 'Hip Contracture', source: 'Contracture', value: '15' },
    { target: 'Ischemic Contracture', source: 'Contracture', value: '15' },
    { target: 'Temporomandibular Joint Disorders', source: 'Craniomandibular Disorders', value: '15' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Temporomandibular Joint Disorders', value: '19' },
    { target: 'Mitochondrial Encephalomyopathies', source: 'Mitochondrial Myopathies', value: '15' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Mitochondrial Myopathies', value: '15' },
    { target: 'MELAS Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '19' },
    { target: 'MERRF Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '19' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '19' },
    { target: 'Muscular Dystrophies', source: 'Muscular Disorders, Atrophic', value: '15' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Muscular Disorders, Atrophic', value: '15' },
    { target: 'Distal Myopathies', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Glycogen Storage Disease Type VII', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Muscular Dystrophies, Limb-Girdle', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Muscular Dystrophy, Duchenne', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Muscular Dystrophy, Emery-Dreifuss', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Muscular Dystrophy, Facioscapulohumeral', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Muscular Dystrophy, Oculopharyngeal', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Myotonic Dystrophy', source: 'Muscular Dystrophies', value: '19' },
    { target: 'Sarcoglycanopathies', source: 'Muscular Dystrophies, Limb-Girdle', value: '23' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Myofascial Pain Syndromes', value: '15' },
    { target: 'Myopathies, Nemaline', source: 'Myopathies, Structural, Congenital', value: '15' },
    { target: 'Myopathy, Central Core', source: 'Myopathies, Structural, Congenital', value: '15' },
    { target: 'Myositis, Inclusion Body', source: 'Myositis', value: '15' },
    { target: 'Myositis Ossificans', source: 'Myositis', value: '15' },
    { target: 'Orbital Myositis', source: 'Myositis', value: '15' },
    { target: 'Polymyositis', source: 'Myositis', value: '15' },
    { target: 'Pyomyositis', source: 'Myositis', value: '15' },
    { target: 'Dermatomyositis', source: 'Polymyositis', value: '19' },
    { target: 'Myotonia Congenita', source: 'Myotonic Disorders', value: '15' },
    { target: 'Myotonic Dystrophy', source: 'Myotonic Disorders', value: '15' },
    { target: 'Hypokalemic Periodic Paralysis', source: 'Paralyses, Familial Periodic', value: '15' },
    { target: 'Paralysis, Hyperkalemic Periodic', source: 'Paralyses, Familial Periodic', value: '15' },
    { target: 'Myoglobinuria', source: 'Rhabdomyolysis', value: '15' },
    { target: 'Elbow Tendinopathy', source: 'Tendinopathy', value: '15' },
    { target: 'Enthesopathy', source: 'Tendinopathy', value: '15' },
    { target: 'Giant Cell Tumor of Tendon Sheath', source: 'Tendinopathy', value: '15' },
    { target: 'Tendon Entrapment', source: 'Tendinopathy', value: '15' },
    { target: 'Tenosynovitis', source: 'Tendinopathy', value: '15' },
    { target: 'Tennis Elbow', source: 'Elbow Tendinopathy', value: '19' },
    { target: 'Synovitis, Pigmented Villonodular', source: 'Giant Cell Tumor of Tendon Sheath', value: '19' },
    { target: 'De Quervain Disease', source: 'Tendon Entrapment', value: '19' },
    { target: 'Trigger Finger Disorder', source: 'Tendon Entrapment', value: '19' },
    { target: 'Arthrogryposis', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Campomelic Dysplasia', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Craniofacial Abnormalities', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Funnel Chest', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Gastroschisis', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Hip Dislocation, Congenital', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Klippel-Feil Syndrome', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Limb Deformities, Congenital', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Pectus Carinatum', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: 'Synostosis', source: 'Musculoskeletal Abnormalities', value: '11' },
    { target: '22q11 Deletion Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Cleidocranial Dysplasia', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Costello Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Craniofacial Dysostosis', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Craniosynostoses', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Donohue Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Holoprosencephaly', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'LEOPARD Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Loeys-Dietz Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Megalencephaly', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Maxillofacial Abnormalities', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Microcephaly', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Noonan Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Orofaciodigital Syndromes', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Plagiocephaly', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Platybasia', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'Silver-Russell Syndrome', source: 'Craniofacial Abnormalities', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Hallermann\'s Syndrome', source: 'Craniofacial Dysostosis', value: '19' },
    { target: 'Hypertelorism', source: 'Craniofacial Dysostosis', value: '19' },
    { target: 'Mandibulofacial Dysostosis', source: 'Craniofacial Dysostosis', value: '19' },
    { target: 'Goldenhar Syndrome', source: 'Mandibulofacial Dysostosis', value: '23' },
    { target: 'Acrocephalosyndactylia', source: 'Craniosynostoses', value: '19' },
    { target: 'Hemimegalencephaly', source: 'Megalencephaly', value: '19' },
    { target: 'Dentofacial Deformities', source: 'Maxillofacial Abnormalities', value: '19' },
    { target: 'Jaw Abnormalities', source: 'Maxillofacial Abnormalities', value: '19' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Porencephaly', source: 'Microcephaly', value: '19' },
    { target: 'Plagiocephaly, Nonsynostotic', source: 'Plagiocephaly', value: '19' },
    { target: 'Arachnodactyly', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Brachydactyly', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Ectromelia', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Lower Extremity Deformities, Congenital', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Polydactyly', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Proteus Syndrome', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Syndactyly', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Thanatophoric Dysplasia', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Upper Extremity Deformities, Congenital', source: 'Limb Deformities, Congenital', value: '15' },
    { target: 'Foot Deformities, Congenital', source: 'Lower Extremity Deformities, Congenital', value: '19' },
    { target: 'Talipes', source: 'Foot Deformities, Congenital', value: '23' },
    { target: 'Tarsal Coalition', source: 'Foot Deformities, Congenital', value: '23' },
    { target: 'Clubfoot', source: 'Talipes', value: '27' },
    { target: 'Equinus Deformity', source: 'Talipes', value: '27' },
    { target: 'Flatfoot', source: 'Talipes', value: '27' },
    { target: 'Talipes Cavus', source: 'Talipes', value: '27' },
    { target: 'Pallister-Hall Syndrome', source: 'Polydactyly', value: '19' },
    { target: 'Short Rib-Polydactyly Syndrome', source: 'Polydactyly', value: '19' },
    { target: 'Acrocephalosyndactylia', source: 'Syndactyly', value: '19' },
    { target: 'Fraser Syndrome', source: 'Syndactyly', value: '19' },
    { target: 'Poland Syndrome', source: 'Syndactyly', value: '19' },
    { target: 'Hand Deformities, Congenital', source: 'Upper Extremity Deformities, Congenital', value: '19' },
    { target: 'Antley-Bixler Syndrome Phenotype', source: 'Synostosis', value: '15' },
    { target: 'Craniosynostoses', source: 'Synostosis', value: '15' },
    { target: 'Syndactyly', source: 'Synostosis', value: '15' },
    { target: 'Tarsal Coalition', source: 'Synostosis', value: '15' },
    { target: 'Acrocephalosyndactylia', source: 'Craniosynostoses', value: '19' },
    { target: 'Acrocephalosyndactylia', source: 'Syndactyly', value: '19' },
    { target: 'Fraser Syndrome', source: 'Syndactyly', value: '19' },
    { target: 'Poland Syndrome', source: 'Syndactyly', value: '19' },
    { target: 'Arthritis, Juvenile', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Arthritis, Rheumatoid', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Fibromyalgia', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Gout', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Hyperostosis, Sternocostoclavicular', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Osteoarthritis', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Polymyalgia Rheumatica', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Rheumatic Fever', source: 'Rheumatic Diseases', value: '11' },
    { target: 'Caplan Syndrome', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Felty Syndrome', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Rheumatoid Nodule', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Rheumatoid Vasculitis', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Sjogren\'s Syndrome', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Still\'s Disease, Adult-Onset', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Arthritis, Gouty', source: 'Gout', value: '15' },
    { target: 'Osteoarthritis, Hip', source: 'Osteoarthritis', value: '15' },
    { target: 'Osteoarthritis, Knee', source: 'Osteoarthritis', value: '15' },
    { target: 'Osteoarthritis, Spine', source: 'Osteoarthritis', value: '15' },
    { target: 'Rheumatic Nodule', source: 'Rheumatic Fever', value: '15' },
    { target: 'Wissler\'s Syndrome', source: 'Rheumatic Fever', value: '15' },
    { target: 'Bile Duct Diseases', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Bile Reflux', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Biliary Tract Neoplasms', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Cholelithiasis', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Gallbladder Diseases', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Postcholecystectomy Syndrome', source: 'Biliary Tract Diseases', value: '11' },
    { target: 'Bile Duct Neoplasms', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Biliary Atresia', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Choledochal Cyst', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Cholestasis', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Cholangitis', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Common Bile Duct Diseases', source: 'Bile Duct Diseases', value: '15' },
    { target: 'Common Bile Duct Neoplasms', source: 'Bile Duct Neoplasms', value: '19' },
    { target: 'Caroli Disease', source: 'Choledochal Cyst', value: '19' },
    { target: 'Cholestasis, Extrahepatic', source: 'Cholestasis', value: '19' },
    { target: 'Cholestasis, Intrahepatic', source: 'Cholestasis', value: '19' },
    { target: 'Mirizzi Syndrome', source: 'Cholestasis', value: '19' },
    { target: 'Alagille Syndrome', source: 'Cholestasis, Intrahepatic', value: '23' },
    { target: 'Liver Cirrhosis, Biliary', source: 'Cholestasis, Intrahepatic', value: '23' },
    { target: 'Cholangitis, Sclerosing', source: 'Cholangitis', value: '19' },
    { target: 'Biliary Dyskinesia', source: 'Common Bile Duct Diseases', value: '19' },
    { target: 'Choledocholithiasis', source: 'Common Bile Duct Diseases', value: '19' },
    { target: 'Common Bile Duct Neoplasms', source: 'Common Bile Duct Diseases', value: '19' },
    { target: 'Sphincter of Oddi Dysfunction', source: 'Biliary Dyskinesia', value: '23' },
    { target: 'Bile Duct Neoplasms', source: 'Biliary Tract Neoplasms', value: '15' },
    { target: 'Gallbladder Neoplasms', source: 'Biliary Tract Neoplasms', value: '15' },
    { target: 'Common Bile Duct Neoplasms', source: 'Bile Duct Neoplasms', value: '19' },
    { target: 'Cholecystolithiasis', source: 'Cholelithiasis', value: '15' },
    { target: 'Choledocholithiasis', source: 'Cholelithiasis', value: '15' },
    { target: 'Gallstones', source: 'Cholelithiasis', value: '15' },
    { target: 'Cholecystitis', source: 'Gallbladder Diseases', value: '15' },
    { target: 'Cholecystolithiasis', source: 'Gallbladder Diseases', value: '15' },
    { target: 'Gallbladder Neoplasms', source: 'Gallbladder Diseases', value: '15' },
    { target: 'Acalculous Cholecystitis', source: 'Cholecystitis', value: '19' },
    { target: 'Cholecystitis, Acute', source: 'Cholecystitis', value: '19' },
    { target: 'Emphysematous Cholecystitis', source: 'Cholecystitis, Acute', value: '23' },
    { target: 'Gallstones', source: 'Cholecystolithiasis', value: '19' },
    { target: 'Anorectal Malformations', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Anus, Imperforate', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Barrett Esophagus', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Biliary Atresia', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Choledochal Cyst', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Diaphragmatic Eventration', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Esophageal Atresia', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Hirschsprung Disease', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Intestinal Atresia', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Meckel Diverticulum', source: 'Digestive System Abnormalities', value: '11' },
    { target: 'Caroli Disease', source: 'Choledochal Cyst', value: '15' },
    { target: 'Biliary Fistula', source: 'Digestive System Fistula', value: '11' },
    { target: 'Esophageal Fistula', source: 'Digestive System Fistula', value: '11' },
    { target: 'Gastric Fistula', source: 'Digestive System Fistula', value: '11' },
    { target: 'Intestinal Fistula', source: 'Digestive System Fistula', value: '11' },
    { target: 'Pancreatic Fistula', source: 'Digestive System Fistula', value: '11' },
    { target: 'Tracheoesophageal Fistula', source: 'Esophageal Fistula', value: '15' },
    { target: 'Rectal Fistula', source: 'Intestinal Fistula', value: '15' },
    { target: 'Rectovaginal Fistula', source: 'Rectal Fistula', value: '19' },
    { target: 'Biliary Tract Neoplasms', source: 'Digestive System Neoplasms', value: '11' },
    { target: 'Gastrointestinal Neoplasms', source: 'Digestive System Neoplasms', value: '11' },
    { target: 'Liver Neoplasms', source: 'Digestive System Neoplasms', value: '11' },
    { target: 'Pancreatic Neoplasms', source: 'Digestive System Neoplasms', value: '11' },
    { target: 'Peritoneal Neoplasms', source: 'Digestive System Neoplasms', value: '11' },
    { target: 'Bile Duct Neoplasms', source: 'Biliary Tract Neoplasms', value: '15' },
    { target: 'Gallbladder Neoplasms', source: 'Biliary Tract Neoplasms', value: '15' },
    { target: 'Common Bile Duct Neoplasms', source: 'Bile Duct Neoplasms', value: '19' },
    { target: 'Esophageal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Gastrointestinal Stromal Tumors', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Intestinal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Stomach Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Cecal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Colorectal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Duodenal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Ileal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Jejunal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Appendiceal Neoplasms', source: 'Cecal Neoplasms', value: '23' },
    { target: 'Adenomatous Polyposis Coli', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colonic Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Rectal Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '27' },
    { target: 'Sigmoid Neoplasms', source: 'Colonic Neoplasms', value: '27' },
    { target: 'Anus Neoplasms', source: 'Rectal Neoplasms', value: '27' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '31' },
    { target: 'Adenoma, Liver Cell', source: 'Liver Neoplasms', value: '15' },
    { target: 'Carcinoma, Hepatocellular', source: 'Liver Neoplasms', value: '15' },
    { target: 'Liver Neoplasms, Experimental', source: 'Liver Neoplasms', value: '15' },
    { target: 'Adenoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '19' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Vipoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Esophageal Diseases', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Gastroenteritis', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Gastrointestinal Hemorrhage', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Gastrointestinal Neoplasms', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Intestinal Diseases', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Stomach Diseases', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Tuberculosis, Gastrointestinal', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Visceral Prolapse', source: 'Gastrointestinal Diseases', value: '11' },
    { target: 'Barrett Esophagus', source: 'Esophageal Diseases', value: '15' },
    { target: 'Deglutition Disorders', source: 'Esophageal Diseases', value: '15' },
    { target: 'Diverticulosis, Esophageal', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal and Gastric Varices', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Atresia', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Cyst', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Fistula', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Neoplasms', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Perforation', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Stenosis', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophagitis', source: 'Esophageal Diseases', value: '15' },
    { target: 'Esophageal Motility Disorders', source: 'Deglutition Disorders', value: '19' },
    { target: 'CREST Syndrome', source: 'Esophageal Motility Disorders', value: '23' },
    { target: 'Esophageal Achalasia', source: 'Esophageal Motility Disorders', value: '23' },
    { target: 'Esophageal Spasm, Diffuse', source: 'Esophageal Motility Disorders', value: '23' },
    { target: 'Gastroesophageal Reflux', source: 'Esophageal Motility Disorders', value: '23' },
    { target: 'Plummer-Vinson Syndrome', source: 'Esophageal Motility Disorders', value: '23' },
    { target: 'Laryngopharyngeal Reflux', source: 'Gastroesophageal Reflux', value: '27' },
    { target: 'Respiratory Aspiration of Gastric Contents', source: 'Laryngopharyngeal Reflux', value: '31' },
    { target: 'Tracheoesophageal Fistula', source: 'Esophageal Fistula', value: '19' },
    { target: 'Mallory-Weiss Syndrome', source: 'Esophageal Perforation', value: '19' },
    { target: 'Eosinophilic Esophagitis', source: 'Esophagitis', value: '19' },
    { target: 'Esophagitis, Peptic', source: 'Esophagitis', value: '19' },
    { target: 'Appendicitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Cholera Morbus', source: 'Gastroenteritis', value: '15' },
    { target: 'Colitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Diverticulitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Dysentery', source: 'Gastroenteritis', value: '15' },
    { target: 'Enteritis', source: 'Gastroenteritis', value: '15' },
    { target: 'Enterocolitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Esophagitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Gastritis', source: 'Gastroenteritis', value: '15' },
    { target: 'Inflammatory Bowel Diseases', source: 'Gastroenteritis', value: '15' },
    { target: 'Mucositis', source: 'Gastroenteritis', value: '15' },
    { target: 'Proctitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Typhlitis', source: 'Gastroenteritis', value: '15' },
    { target: 'Colitis, Ischemic', source: 'Colitis', value: '19' },
    { target: 'Colitis, Microscopic', source: 'Colitis', value: '19' },
    { target: 'Colitis, Ulcerative', source: 'Colitis', value: '19' },
    { target: 'Proctocolitis', source: 'Colitis', value: '19' },
    { target: 'Colitis, Collagenous', source: 'Colitis, Microscopic', value: '23' },
    { target: 'Colitis, Lymphocytic', source: 'Colitis, Microscopic', value: '23' },
    { target: 'Dysentery, Amebic', source: 'Dysentery', value: '19' },
    { target: 'Dysentery, Bacillary', source: 'Dysentery', value: '19' },
    { target: 'Duodenitis', source: 'Enteritis', value: '19' },
    { target: 'Ileitis', source: 'Enteritis', value: '19' },
    { target: 'Pouchitis', source: 'Ileitis', value: '23' },
    { target: 'Enterocolitis, Necrotizing', source: 'Enterocolitis', value: '19' },
    { target: 'Enterocolitis, Neutropenic', source: 'Enterocolitis', value: '19' },
    { target: 'Enterocolitis, Pseudomembranous', source: 'Enterocolitis', value: '19' },
    { target: 'Eosinophilic Esophagitis', source: 'Esophagitis', value: '19' },
    { target: 'Esophagitis, Peptic', source: 'Esophagitis', value: '19' },
    { target: 'Gastritis, Atrophic', source: 'Gastritis', value: '19' },
    { target: 'Gastritis, Hypertrophic', source: 'Gastritis', value: '19' },
    { target: 'Colitis, Ulcerative', source: 'Inflammatory Bowel Diseases', value: '19' },
    { target: 'Crohn Disease', source: 'Inflammatory Bowel Diseases', value: '19' },
    { target: 'Proctocolitis', source: 'Proctitis', value: '19' },
    { target: 'Hematemesis', source: 'Gastrointestinal Hemorrhage', value: '15' },
    { target: 'Melena', source: 'Gastrointestinal Hemorrhage', value: '15' },
    { target: 'Peptic Ulcer Hemorrhage', source: 'Gastrointestinal Hemorrhage', value: '15' },
    { target: 'Esophageal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Gastrointestinal Stromal Tumors', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Intestinal Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Stomach Neoplasms', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Gastrointestinal Neoplasms', value: '15' },
    { target: 'Cecal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Colorectal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Duodenal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Ileal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Jejunal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Appendiceal Neoplasms', source: 'Cecal Neoplasms', value: '23' },
    { target: 'Adenomatous Polyposis Coli', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colonic Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Rectal Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '27' },
    { target: 'Sigmoid Neoplasms', source: 'Colonic Neoplasms', value: '27' },
    { target: 'Anus Neoplasms', source: 'Rectal Neoplasms', value: '27' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '31' },
    { target: 'Cecal Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Colonic Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Duodenal Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Dysentery', source: 'Intestinal Diseases', value: '15' },
    { target: 'Enteritis', source: 'Intestinal Diseases', value: '15' },
    { target: 'Enterocolitis', source: 'Intestinal Diseases', value: '15' },
    { target: 'HIV Enteropathy', source: 'Intestinal Diseases', value: '15' },
    { target: 'Ileal Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Inflammatory Bowel Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Atresia', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Diseases, Parasitic', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Fistula', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Neoplasms', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Obstruction', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Perforation', source: 'Intestinal Diseases', value: '15' },
    { target: 'Intestinal Polyposis', source: 'Intestinal Diseases', value: '15' },
    { target: 'Jejunal Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Malabsorption Syndromes', source: 'Intestinal Diseases', value: '15' },
    { target: 'Mesenteric Ischemia', source: 'Intestinal Diseases', value: '15' },
    { target: 'Mesenteric Vascular Occlusion', source: 'Intestinal Diseases', value: '15' },
    { target: 'Pneumatosis Cystoides Intestinalis', source: 'Intestinal Diseases', value: '15' },
    { target: 'Protein-Losing Enteropathies', source: 'Intestinal Diseases', value: '15' },
    { target: 'Rectal Diseases', source: 'Intestinal Diseases', value: '15' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Intestinal Diseases', value: '15' },
    { target: 'Appendicitis', source: 'Cecal Diseases', value: '19' },
    { target: 'Cecal Neoplasms', source: 'Cecal Diseases', value: '19' },
    { target: 'Typhlitis', source: 'Cecal Diseases', value: '19' },
    { target: 'Appendiceal Neoplasms', source: 'Cecal Neoplasms', value: '23' },
    { target: 'Chilaiditi Syndrome', source: 'Colonic Diseases', value: '19' },
    { target: 'Colitis', source: 'Colonic Diseases', value: '19' },
    { target: 'Colonic Diseases, Functional', source: 'Colonic Diseases', value: '19' },
    { target: 'Colorectal Neoplasms', source: 'Colonic Diseases', value: '19' },
    { target: 'Diverticulosis, Colonic', source: 'Colonic Diseases', value: '19' },
    { target: 'Megacolon', source: 'Colonic Diseases', value: '19' },
    { target: 'Sigmoid Diseases', source: 'Colonic Diseases', value: '19' },
    { target: 'Colitis, Ischemic', source: 'Colitis', value: '23' },
    { target: 'Colitis, Microscopic', source: 'Colitis', value: '23' },
    { target: 'Colitis, Ulcerative', source: 'Colitis', value: '23' },
    { target: 'Proctocolitis', source: 'Colitis', value: '23' },
    { target: 'Colitis, Collagenous', source: 'Colitis, Microscopic', value: '27' },
    { target: 'Colitis, Lymphocytic', source: 'Colitis, Microscopic', value: '27' },
    { target: 'Colonic Pseudo-Obstruction', source: 'Colonic Diseases, Functional', value: '23' },
    { target: 'Irritable Bowel Syndrome', source: 'Colonic Diseases, Functional', value: '23' },
    { target: 'Neurogenic Bowel', source: 'Colonic Diseases, Functional', value: '23' },
    { target: 'Adenomatous Polyposis Coli', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colonic Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '27' },
    { target: 'Sigmoid Neoplasms', source: 'Colonic Neoplasms', value: '27' },
    { target: 'Diverticulitis, Colonic', source: 'Diverticulosis, Colonic', value: '23' },
    { target: 'Hirschsprung Disease', source: 'Megacolon', value: '23' },
    { target: 'Megacolon, Toxic', source: 'Megacolon', value: '23' },
    { target: 'Proctocolitis', source: 'Sigmoid Diseases', value: '23' },
    { target: 'Sigmoid Neoplasms', source: 'Sigmoid Diseases', value: '23' },
    { target: 'Duodenal Neoplasms', source: 'Duodenal Diseases', value: '19' },
    { target: 'Duodenal Obstruction', source: 'Duodenal Diseases', value: '19' },
    { target: 'Duodenitis', source: 'Duodenal Diseases', value: '19' },
    { target: 'Duodenogastric Reflux', source: 'Duodenal Diseases', value: '19' },
    { target: 'Peptic Ulcer', source: 'Duodenal Diseases', value: '19' },
    { target: 'Superior Mesenteric Artery Syndrome', source: 'Duodenal Obstruction', value: '23' },
    { target: 'Duodenal Ulcer', source: 'Peptic Ulcer', value: '23' },
    { target: 'Esophagitis, Peptic', source: 'Peptic Ulcer', value: '23' },
    { target: 'Peptic Ulcer Perforation', source: 'Peptic Ulcer', value: '23' },
    { target: 'Stomach Ulcer', source: 'Peptic Ulcer', value: '23' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Peptic Ulcer', value: '23' },
    { target: 'Dysentery, Amebic', source: 'Dysentery', value: '19' },
    { target: 'Dysentery, Bacillary', source: 'Dysentery', value: '19' },
    { target: 'Duodenitis', source: 'Enteritis', value: '19' },
    { target: 'Ileitis', source: 'Enteritis', value: '19' },
    { target: 'Pouchitis', source: 'Ileitis', value: '23' },
    { target: 'Enterocolitis, Necrotizing', source: 'Enterocolitis', value: '19' },
    { target: 'Enterocolitis, Neutropenic', source: 'Enterocolitis', value: '19' },
    { target: 'Enterocolitis, Pseudomembranous', source: 'Enterocolitis', value: '19' },
    { target: 'Ileal Neoplasms', source: 'Ileal Diseases', value: '19' },
    { target: 'Ileitis', source: 'Ileal Diseases', value: '19' },
    { target: 'Pouchitis', source: 'Ileitis', value: '23' },
    { target: 'Colitis, Ulcerative', source: 'Inflammatory Bowel Diseases', value: '19' },
    { target: 'Crohn Disease', source: 'Inflammatory Bowel Diseases', value: '19' },
    { target: 'Anisakiasis', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Balantidiasis', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Blastocystis Infections', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Cryptosporidiosis', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Dientamoebiasis', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Dysentery, Amebic', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Giardiasis', source: 'Intestinal Diseases, Parasitic', value: '19' },
    { target: 'Rectal Fistula', source: 'Intestinal Fistula', value: '19' },
    { target: 'Rectovaginal Fistula', source: 'Rectal Fistula', value: '23' },
    { target: 'Cecal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Colorectal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Duodenal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Ileal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Jejunal Neoplasms', source: 'Intestinal Neoplasms', value: '19' },
    { target: 'Appendiceal Neoplasms', source: 'Cecal Neoplasms', value: '23' },
    { target: 'Adenomatous Polyposis Coli', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colonic Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Rectal Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '27' },
    { target: 'Sigmoid Neoplasms', source: 'Colonic Neoplasms', value: '27' },
    { target: 'Anus Neoplasms', source: 'Rectal Neoplasms', value: '27' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '31' },
    { target: 'Afferent Loop Syndrome', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Duodenal Obstruction', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Fecal Impaction', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Ileus', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Intestinal Volvulus', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Intussusception', source: 'Intestinal Obstruction', value: '19' },
    { target: 'Intestinal Pseudo-Obstruction', source: 'Ileus', value: '23' },
    { target: 'Colonic Pseudo-Obstruction', source: 'Intestinal Pseudo-Obstruction', value: '27' },
    { target: 'Adenomatous Polyposis Coli', source: 'Intestinal Polyposis', value: '19' },
    { target: 'Peutz-Jeghers Syndrome', source: 'Intestinal Polyposis', value: '19' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '23' },
    { target: 'Jejunal Neoplasms', source: 'Jejunal Diseases', value: '19' },
    { target: 'Blind Loop Syndrome', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Celiac Disease', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Collagenous Sprue', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Lactose Intolerance', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Short Bowel Syndrome', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Sprue, Tropical', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Steatorrhea', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Whipple Disease', source: 'Malabsorption Syndromes', value: '19' },
    { target: 'Anus Diseases', source: 'Rectal Diseases', value: '19' },
    { target: 'Colorectal Neoplasms', source: 'Rectal Diseases', value: '19' },
    { target: 'Fecal Incontinence', source: 'Rectal Diseases', value: '19' },
    { target: 'Hemorrhoids', source: 'Rectal Diseases', value: '19' },
    { target: 'Proctitis', source: 'Rectal Diseases', value: '19' },
    { target: 'Rectal Fistula', source: 'Rectal Diseases', value: '19' },
    { target: 'Rectal Prolapse', source: 'Rectal Diseases', value: '19' },
    { target: 'Rectocele', source: 'Rectal Diseases', value: '19' },
    { target: 'Anus Neoplasms', source: 'Anus Diseases', value: '23' },
    { target: 'Fissure in Ano', source: 'Anus Diseases', value: '23' },
    { target: 'Pruritus Ani', source: 'Anus Diseases', value: '23' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '27' },
    { target: 'Rectal Neoplasms', source: 'Colorectal Neoplasms', value: '23' },
    { target: 'Anus Neoplasms', source: 'Rectal Neoplasms', value: '27' },
    { target: 'Anal Gland Neoplasms', source: 'Anus Neoplasms', value: '31' },
    { target: 'Proctocolitis', source: 'Proctitis', value: '23' },
    { target: 'Rectovaginal Fistula', source: 'Rectal Fistula', value: '23' },
    { target: 'Achlorhydria', source: 'Stomach Diseases', value: '15' },
    { target: 'Diverticulosis, Stomach', source: 'Stomach Diseases', value: '15' },
    { target: 'Duodenogastric Reflux', source: 'Stomach Diseases', value: '15' },
    { target: 'Gastric Antral Vascular Ectasia', source: 'Stomach Diseases', value: '15' },
    { target: 'Gastric Dilatation', source: 'Stomach Diseases', value: '15' },
    { target: 'Gastric Outlet Obstruction', source: 'Stomach Diseases', value: '15' },
    { target: 'Gastritis', source: 'Stomach Diseases', value: '15' },
    { target: 'Gastroparesis', source: 'Stomach Diseases', value: '15' },
    { target: 'Peptic Ulcer', source: 'Stomach Diseases', value: '15' },
    { target: 'Postgastrectomy Syndromes', source: 'Stomach Diseases', value: '15' },
    { target: 'Stomach Neoplasms', source: 'Stomach Diseases', value: '15' },
    { target: 'Stomach Rupture', source: 'Stomach Diseases', value: '15' },
    { target: 'Stomach Volvulus', source: 'Stomach Diseases', value: '15' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Stomach Diseases', value: '15' },
    { target: 'Bile Reflux', source: 'Duodenogastric Reflux', value: '19' },
    { target: 'Pyloric Stenosis', source: 'Gastric Outlet Obstruction', value: '19' },
    { target: 'Pyloric Stenosis, Hypertrophic', source: 'Pyloric Stenosis', value: '23' },
    { target: 'Gastritis, Atrophic', source: 'Gastritis', value: '19' },
    { target: 'Gastritis, Hypertrophic', source: 'Gastritis', value: '19' },
    { target: 'Duodenal Ulcer', source: 'Peptic Ulcer', value: '19' },
    { target: 'Esophagitis, Peptic', source: 'Peptic Ulcer', value: '19' },
    { target: 'Peptic Ulcer Perforation', source: 'Peptic Ulcer', value: '19' },
    { target: 'Stomach Ulcer', source: 'Peptic Ulcer', value: '19' },
    { target: 'Zollinger-Ellison Syndrome', source: 'Peptic Ulcer', value: '19' },
    { target: 'Dumping Syndrome', source: 'Postgastrectomy Syndromes', value: '19' },
    { target: 'alpha 1-Antitrypsin Deficiency', source: 'Liver Diseases', value: '11' },
    { target: 'Cholestasis, Intrahepatic', source: 'Liver Diseases', value: '11' },
    { target: 'Chemical and Drug Induced Liver Injury', source: 'Liver Diseases', value: '11' },
    { target: 'Fatty Liver', source: 'Liver Diseases', value: '11' },
    { target: 'Focal Nodular Hyperplasia', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatic Insufficiency', source: 'Liver Diseases', value: '11' },
    { target: 'Budd-Chiari Syndrome', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatic Veno-Occlusive Disease', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatitis', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatolenticular Degeneration', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatomegaly', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatopulmonary Syndrome', source: 'Liver Diseases', value: '11' },
    { target: 'Hepatorenal Syndrome', source: 'Liver Diseases', value: '11' },
    { target: 'Hypertension, Portal', source: 'Liver Diseases', value: '11' },
    { target: 'Liver Abscess', source: 'Liver Diseases', value: '11' },
    { target: 'Liver Cirrhosis', source: 'Liver Diseases', value: '11' },
    { target: 'Liver Diseases, Alcoholic', source: 'Liver Diseases', value: '11' },
    { target: 'Liver Diseases, Parasitic', source: 'Liver Diseases', value: '11' },
    { target: 'Liver Neoplasms', source: 'Liver Diseases', value: '11' },
    { target: 'Peliosis Hepatis', source: 'Liver Diseases', value: '11' },
    { target: 'Porphyrias, Hepatic', source: 'Liver Diseases', value: '11' },
    { target: 'Tuberculosis, Hepatic', source: 'Liver Diseases', value: '11' },
    { target: 'Zellweger Syndrome', source: 'Liver Diseases', value: '11' },
    { target: 'Alagille Syndrome', source: 'Cholestasis, Intrahepatic', value: '15' },
    { target: 'Liver Cirrhosis, Biliary', source: 'Cholestasis, Intrahepatic', value: '15' },
    { target: 'Drug-Induced Liver Injury, Chronic', source: 'Chemical and Drug Induced Liver Injury', value: '15' },
    { target: 'Fatty Liver, Alcoholic', source: 'Fatty Liver', value: '15' },
    { target: 'Non-alcoholic Fatty Liver Disease', source: 'Fatty Liver', value: '15' },
    { target: 'Reye Syndrome', source: 'Fatty Liver', value: '15' },
    { target: 'Liver Failure', source: 'Hepatic Insufficiency', value: '15' },
    { target: 'End Stage Liver Disease', source: 'Liver Failure', value: '19' },
    { target: 'Hepatic Encephalopathy', source: 'Liver Failure', value: '19' },
    { target: 'Liver Failure, Acute', source: 'Liver Failure', value: '19' },
    { target: 'Acute-On-Chronic Liver Failure', source: 'Liver Failure, Acute', value: '23' },
    { target: 'Massive Hepatic Necrosis', source: 'Liver Failure, Acute', value: '23' },
    { target: 'Hepatitis, Alcoholic', source: 'Hepatitis', value: '15' },
    { target: 'Hepatitis, Animal', source: 'Hepatitis', value: '15' },
    { target: 'Hepatitis, Chronic', source: 'Hepatitis', value: '15' },
    { target: 'Hepatitis, Viral, Human', source: 'Hepatitis', value: '15' },
    { target: 'Hepatitis, Viral, Animal', source: 'Hepatitis, Animal', value: '19' },
    { target: 'Hepatitis, Infectious Canine', source: 'Hepatitis, Viral, Animal', value: '23' },
    { target: 'Rift Valley Fever', source: 'Hepatitis, Viral, Animal', value: '23' },
    { target: 'Hepatitis, Autoimmune', source: 'Hepatitis, Chronic', value: '19' },
    { target: 'Hepatitis B, Chronic', source: 'Hepatitis, Chronic', value: '19' },
    { target: 'Hepatitis C, Chronic', source: 'Hepatitis, Chronic', value: '19' },
    { target: 'Hepatitis D, Chronic', source: 'Hepatitis, Chronic', value: '19' },
    { target: 'Hepatitis A', source: 'Hepatitis, Viral, Human', value: '19' },
    { target: 'Hepatitis B', source: 'Hepatitis, Viral, Human', value: '19' },
    { target: 'Hepatitis C', source: 'Hepatitis, Viral, Human', value: '19' },
    { target: 'Hepatitis D', source: 'Hepatitis, Viral, Human', value: '19' },
    { target: 'Hepatitis E', source: 'Hepatitis, Viral, Human', value: '19' },
    { target: 'Hepatitis B, Chronic', source: 'Hepatitis B', value: '23' },
    { target: 'Hepatitis C, Chronic', source: 'Hepatitis C', value: '23' },
    { target: 'Hepatitis D, Chronic', source: 'Hepatitis D', value: '23' },
    { target: 'Esophageal and Gastric Varices', source: 'Hypertension, Portal', value: '15' },
    { target: 'Liver Abscess, Amebic', source: 'Liver Abscess', value: '15' },
    { target: 'Liver Abscess, Pyogenic', source: 'Liver Abscess', value: '15' },
    { target: 'Liver Cirrhosis, Alcoholic', source: 'Liver Cirrhosis', value: '15' },
    { target: 'Liver Cirrhosis, Biliary', source: 'Liver Cirrhosis', value: '15' },
    { target: 'Liver Cirrhosis, Experimental', source: 'Liver Cirrhosis', value: '15' },
    { target: 'Fatty Liver, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '15' },
    { target: 'Hepatitis, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '15' },
    { target: 'Liver Cirrhosis, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '15' },
    { target: 'Echinococcosis, Hepatic', source: 'Liver Diseases, Parasitic', value: '15' },
    { target: 'Fascioliasis', source: 'Liver Diseases, Parasitic', value: '15' },
    { target: 'Liver Abscess, Amebic', source: 'Liver Diseases, Parasitic', value: '15' },
    { target: 'Adenoma, Liver Cell', source: 'Liver Neoplasms', value: '15' },
    { target: 'Carcinoma, Hepatocellular', source: 'Liver Neoplasms', value: '15' },
    { target: 'Liver Neoplasms, Experimental', source: 'Liver Neoplasms', value: '15' },
    { target: 'Coproporphyria, Hereditary', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Porphyria, Acute Intermittent', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Porphyria Cutanea Tarda', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Porphyria, Hepatoerythropoietic', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Porphyria, Variegate', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Protoporphyria, Erythropoietic', source: 'Porphyrias, Hepatic', value: '15' },
    { target: 'Congenital Hyperinsulinism', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Cystic Fibrosis', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Exocrine Pancreatic Insufficiency', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Pancreatic Cyst', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Pancreatic Fistula', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Pancreatic Neoplasms', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Pancreatitis', source: 'Pancreatic Diseases', value: '11' },
    { target: 'Nesidioblastosis', source: 'Congenital Hyperinsulinism', value: '15' },
    { target: 'Pancreatic Pseudocyst', source: 'Pancreatic Cyst', value: '15' },
    { target: 'Adenoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '19' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Vipoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Pancreatitis, Acute Necrotizing', source: 'Pancreatitis', value: '15' },
    { target: 'Pancreatitis, Alcoholic', source: 'Pancreatitis', value: '15' },
    { target: 'Pancreatitis, Chronic', source: 'Pancreatitis', value: '15' },
    { target: 'Pancreatitis, Graft', source: 'Pancreatitis', value: '15' },
    { target: 'Chylous Ascites', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Hemoperitoneum', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Mesenteric Ischemia', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Mesenteric Lympadenitis', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Mesenteric Vascular Occlusion', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Panniculitis, Peritoneal', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Peritoneal Fibrosis', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Peritoneal Neoplasms', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Peritonitis', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Pneumoperitoneum', source: 'Peritoneal Diseases', value: '11' },
    { target: 'Mesenteric Cyst', source: 'Peritoneal Neoplasms', value: '15' },
    { target: 'Peritonitis, Tuberculous', source: 'Peritonitis', value: '15' },
    { target: 'Subphrenic Abscess', source: 'Peritonitis', value: '15' },
    { target: 'Bisphosphonate-Associated Osteonecrosis of the Jaw', source: 'Jaw Diseases', value: '11' },
    { target: 'Cherubism', source: 'Jaw Diseases', value: '11' },
    { target: 'Granuloma, Giant Cell', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Abnormalities', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Cysts', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw, Edentulous', source: 'Jaw Diseases', value: '11' },
    { target: 'Jaw Neoplasms', source: 'Jaw Diseases', value: '11' },
    { target: 'Mandibular Diseases', source: 'Jaw Diseases', value: '11' },
    { target: 'Maxillary Diseases', source: 'Jaw Diseases', value: '11' },
    { target: 'Periapical Diseases', source: 'Jaw Diseases', value: '11' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '15' },
    { target: 'Nonodontogenic Cysts', source: 'Jaw Cysts', value: '15' },
    { target: 'Odontogenic Cysts', source: 'Jaw Cysts', value: '15' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Dentigerous Cyst', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Odontogenic Cyst, Calcifying', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Periodontal Cyst', source: 'Odontogenic Cysts', value: '19' },
    { target: 'Radicular Cyst', source: 'Periodontal Cyst', value: '23' },
    { target: 'Jaw, Edentulous, Partially', source: 'Jaw, Edentulous', value: '15' },
    { target: 'Mandibular Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Maxillary Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Palatal Neoplasms', source: 'Jaw Neoplasms', value: '15' },
    { target: 'Craniomandibular Disorders', source: 'Mandibular Diseases', value: '15' },
    { target: 'Mandibular Neoplasms', source: 'Mandibular Diseases', value: '15' },
    { target: 'Prognathism', source: 'Mandibular Diseases', value: '15' },
    { target: 'Retrognathia', source: 'Mandibular Diseases', value: '15' },
    { target: 'Temporomandibular Joint Disorders', source: 'Craniomandibular Disorders', value: '19' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Temporomandibular Joint Disorders', value: '23' },
    { target: 'Maxillary Neoplasms', source: 'Maxillary Diseases', value: '15' },
    { target: 'Periapical Periodontitis', source: 'Periapical Diseases', value: '15' },
    { target: 'Radicular Cyst', source: 'Periapical Diseases', value: '15' },
    { target: 'Periapical Abscess', source: 'Periapical Periodontitis', value: '19' },
    { target: 'Periapical Granuloma', source: 'Periapical Periodontitis', value: '19' },
    { target: 'Behcet Syndrome', source: 'Mouth Diseases', value: '11' },
    { target: 'Bell Palsy', source: 'Mouth Diseases', value: '11' },
    { target: 'Burning Mouth Syndrome', source: 'Mouth Diseases', value: '11' },
    { target: 'Candidiasis, Oral', source: 'Mouth Diseases', value: '11' },
    { target: 'Dry Socket', source: 'Mouth Diseases', value: '11' },
    { target: 'Facial Hemiatrophy', source: 'Mouth Diseases', value: '11' },
    { target: 'Facial Nerve Diseases', source: 'Mouth Diseases', value: '11' },
    { target: 'Facial Paralysis', source: 'Mouth Diseases', value: '11' },
    { target: 'Focal Epithelial Hyperplasia', source: 'Mouth Diseases', value: '11' },
    { target: 'Granulomatosis, Orofacial', source: 'Mouth Diseases', value: '11' },
    { target: 'Hemifacial Spasm', source: 'Mouth Diseases', value: '11' },
    { target: 'Leukoedema, Oral', source: 'Mouth Diseases', value: '11' },
    { target: 'Lichen Planus, Oral', source: 'Mouth Diseases', value: '11' },
    { target: 'Lip Diseases', source: 'Mouth Diseases', value: '11' },
    { target: 'Ludwig\'s Angina', source: 'Mouth Diseases', value: '11' },
    { target: 'Melkersson-Rosenthal Syndrome', source: 'Mouth Diseases', value: '11' },
    { target: 'Mouth Abnormalities', source: 'Mouth Diseases', value: '11' },
    { target: 'Mouth, Edentulous', source: 'Mouth Diseases', value: '11' },
    { target: 'Mouth Neoplasms', source: 'Mouth Diseases', value: '11' },
    { target: 'Mucositis', source: 'Mouth Diseases', value: '11' },
    { target: 'Noma', source: 'Mouth Diseases', value: '11' },
    { target: 'Oral Fistula', source: 'Mouth Diseases', value: '11' },
    { target: 'Oral Hemorrhage', source: 'Mouth Diseases', value: '11' },
    { target: 'Oral Manifestations', source: 'Mouth Diseases', value: '11' },
    { target: 'Oral Submucous Fibrosis', source: 'Mouth Diseases', value: '11' },
    { target: 'Oral Ulcer', source: 'Mouth Diseases', value: '11' },
    { target: 'Periodontal Diseases', source: 'Mouth Diseases', value: '11' },
    { target: 'Ranula', source: 'Mouth Diseases', value: '11' },
    { target: 'Salivary Gland Diseases', source: 'Mouth Diseases', value: '11' },
    { target: 'Stomatitis', source: 'Mouth Diseases', value: '11' },
    { target: 'Tongue Diseases', source: 'Mouth Diseases', value: '11' },
    { target: 'Tuberculosis, Oral', source: 'Mouth Diseases', value: '11' },
    { target: 'Bell Palsy', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Hemiatrophy', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Nerve Injuries', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Neuralgia', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Herpes Zoster Oticus', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Melkersson-Rosenthal Syndrome', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Mobius Syndrome', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Trigeminal Nerve Diseases', source: 'Facial Neuralgia', value: '19' },
    { target: 'Trigeminal Nerve Injuries', source: 'Trigeminal Nerve Diseases', value: '23' },
    { target: 'Trigeminal Neuralgia', source: 'Trigeminal Nerve Diseases', value: '23' },
    { target: 'Lingual Nerve Injuries', source: 'Trigeminal Nerve Injuries', value: '27' },
    { target: 'Cheilitis', source: 'Lip Diseases', value: '15' },
    { target: 'Cleft Lip', source: 'Lip Diseases', value: '15' },
    { target: 'Herpes Labialis', source: 'Lip Diseases', value: '15' },
    { target: 'Lip Neoplasms', source: 'Lip Diseases', value: '15' },
    { target: 'Cleft Lip', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Cleft Palate', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Fibromatosis, Gingival', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Macrostomia', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Microstomia', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Velopharyngeal Insufficiency', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Jaw, Edentulous', source: 'Mouth, Edentulous', value: '15' },
    { target: 'Jaw, Edentulous, Partially', source: 'Jaw, Edentulous', value: '19' },
    { target: 'Gingival Neoplasms', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Leukoplakia, Oral', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Lip Neoplasms', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Palatal Neoplasms', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Salivary Gland Neoplasms', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Tongue Neoplasms', source: 'Mouth Neoplasms', value: '15' },
    { target: 'Leukoplakia, Hairy', source: 'Leukoplakia, Oral', value: '19' },
    { target: 'Parotid Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Sublingual Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Submandibular Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Dental Fistula', source: 'Oral Fistula', value: '15' },
    { target: 'Oroantral Fistula', source: 'Oral Fistula', value: '15' },
    { target: 'Salivary Gland Fistula', source: 'Oral Fistula', value: '15' },
    { target: 'Gingival Hemorrhage', source: 'Oral Hemorrhage', value: '15' },
    { target: 'Furcation Defects', source: 'Periodontal Diseases', value: '15' },
    { target: 'Gingival Diseases', source: 'Periodontal Diseases', value: '15' },
    { target: 'Peri-Implantitis', source: 'Periodontal Diseases', value: '15' },
    { target: 'Periapical Diseases', source: 'Periodontal Diseases', value: '15' },
    { target: 'Periodontal Atrophy', source: 'Periodontal Diseases', value: '15' },
    { target: 'Periodontal Cyst', source: 'Periodontal Diseases', value: '15' },
    { target: 'Periodontitis', source: 'Periodontal Diseases', value: '15' },
    { target: 'Tooth Loss', source: 'Periodontal Diseases', value: '15' },
    { target: 'Tooth Migration', source: 'Periodontal Diseases', value: '15' },
    { target: 'Tooth Mobility', source: 'Periodontal Diseases', value: '15' },
    { target: 'Gingival Hemorrhage', source: 'Gingival Diseases', value: '19' },
    { target: 'Gingival Neoplasms', source: 'Gingival Diseases', value: '19' },
    { target: 'Gingival Overgrowth', source: 'Gingival Diseases', value: '19' },
    { target: 'Gingival Recession', source: 'Gingival Diseases', value: '19' },
    { target: 'Gingivitis', source: 'Gingival Diseases', value: '19' },
    { target: 'Granuloma, Giant Cell', source: 'Gingival Diseases', value: '19' },
    { target: 'Pericoronitis', source: 'Gingival Diseases', value: '19' },
    { target: 'Fibromatosis, Gingival', source: 'Gingival Overgrowth', value: '23' },
    { target: 'Gingival Hyperplasia', source: 'Gingival Overgrowth', value: '23' },
    { target: 'Gingival Hypertrophy', source: 'Gingival Overgrowth', value: '23' },
    { target: 'Gingival Pocket', source: 'Gingivitis', value: '23' },
    { target: 'Gingivitis, Necrotizing Ulcerative', source: 'Gingivitis', value: '23' },
    { target: 'Periapical Periodontitis', source: 'Periapical Diseases', value: '19' },
    { target: 'Radicular Cyst', source: 'Periapical Diseases', value: '19' },
    { target: 'Periapical Abscess', source: 'Periapical Periodontitis', value: '23' },
    { target: 'Periapical Granuloma', source: 'Periapical Periodontitis', value: '23' },
    { target: 'Alveolar Bone Loss', source: 'Periodontal Atrophy', value: '19' },
    { target: 'Gingival Recession', source: 'Periodontal Atrophy', value: '19' },
    { target: 'Periodontal Attachment Loss', source: 'Periodontal Atrophy', value: '19' },
    { target: 'Aggressive Periodontitis', source: 'Periodontitis', value: '19' },
    { target: 'Chronic Periodontitis', source: 'Periodontitis', value: '19' },
    { target: 'Periapical Periodontitis', source: 'Periodontitis', value: '19' },
    { target: 'Periodontal Abscess', source: 'Periodontitis', value: '19' },
    { target: 'Periodontal Pocket', source: 'Periodontitis', value: '19' },
    { target: 'Periapical Abscess', source: 'Periapical Periodontitis', value: '23' },
    { target: 'Periapical Granuloma', source: 'Periapical Periodontitis', value: '23' },
    { target: 'Mesial Movement of Teeth', source: 'Tooth Migration', value: '19' },
    { target: 'Mikulicz\' Disease', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Parotid Diseases', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Salivary Calculi', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Salivary Gland Fistula', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Salivary Gland Neoplasms', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Sialadenitis', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Sialometaplasia, Necrotizing', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Sialorrhea', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Submandibular Gland Diseases', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Xerostomia', source: 'Salivary Gland Diseases', value: '15' },
    { target: 'Parotid Neoplasms', source: 'Parotid Diseases', value: '19' },
    { target: 'Parotitis', source: 'Parotid Diseases', value: '19' },
    { target: 'Mumps', source: 'Parotitis', value: '23' },
    { target: 'Salivary Duct Calculi', source: 'Salivary Calculi', value: '19' },
    { target: 'Salivary Gland Calculi', source: 'Salivary Calculi', value: '19' },
    { target: 'Parotid Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Sublingual Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Submandibular Gland Neoplasms', source: 'Salivary Gland Neoplasms', value: '19' },
    { target: 'Parotitis', source: 'Sialadenitis', value: '19' },
    { target: 'Submandibular Gland Neoplasms', source: 'Submandibular Gland Diseases', value: '19' },
    { target: 'Sjogren\'s Syndrome', source: 'Xerostomia', value: '19' },
    { target: 'Stevens-Johnson Syndrome', source: 'Stomatitis', value: '15' },
    { target: 'Stomatitis, Aphthous', source: 'Stomatitis', value: '15' },
    { target: 'Stomatitis, Denture', source: 'Stomatitis', value: '15' },
    { target: 'Stomatitis, Herpetic', source: 'Stomatitis', value: '15' },
    { target: 'Vesicular Stomatitis', source: 'Stomatitis', value: '15' },
    { target: 'Glossalgia', source: 'Tongue Diseases', value: '15' },
    { target: 'Glossitis', source: 'Tongue Diseases', value: '15' },
    { target: 'Glossoptosis', source: 'Tongue Diseases', value: '15' },
    { target: 'Macroglossia', source: 'Tongue Diseases', value: '15' },
    { target: 'Tongue, Fissured', source: 'Tongue Diseases', value: '15' },
    { target: 'Tongue, Hairy', source: 'Tongue Diseases', value: '15' },
    { target: 'Tongue Neoplasms', source: 'Tongue Diseases', value: '15' },
    { target: 'Glossitis, Benign Migratory', source: 'Glossitis', value: '19' },
    { target: 'Lemierre Syndrome', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Nasopharyngeal Diseases', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Pharyngeal Neoplasms', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Pharyngitis', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Velopharyngeal Insufficiency', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Nasopharyngeal Diseases', value: '15' },
    { target: 'Nasopharyngitis', source: 'Nasopharyngeal Diseases', value: '15' },
    { target: 'Hypopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Oropharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Tonsillar Neoplasms', source: 'Oropharyngeal Neoplasms', value: '19' },
    { target: 'Nasopharyngitis', source: 'Pharyngitis', value: '15' },
    { target: 'Retropharyngeal Abscess', source: 'Pharyngitis', value: '15' },
    { target: 'Tonsillitis', source: 'Pharyngitis', value: '15' },
    { target: 'Peritonsillar Abscess', source: 'Tonsillitis', value: '19' },
    { target: 'Maxillofacial Abnormalities', source: 'Stomatognathic System Abnormalities', value: '11' },
    { target: 'Mouth Abnormalities', source: 'Stomatognathic System Abnormalities', value: '11' },
    { target: 'Tooth Abnormalities', source: 'Stomatognathic System Abnormalities', value: '11' },
    { target: 'Dentofacial Deformities', source: 'Maxillofacial Abnormalities', value: '15' },
    { target: 'Jaw Abnormalities', source: 'Maxillofacial Abnormalities', value: '15' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '19' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '19' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '19' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '19' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '19' },
    { target: 'Cleft Lip', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Cleft Palate', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Fibromatosis, Gingival', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Macrostomia', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Microstomia', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Velopharyngeal Insufficiency', source: 'Mouth Abnormalities', value: '15' },
    { target: 'Anodontia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dens in Dente', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dental Enamel Hypoplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dentin Dysplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dentinogenesis Imperfecta', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Diastema', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Fused Teeth', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Odontodysplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Tooth, Supernumerary', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Amelogenesis Imperfecta', source: 'Dental Enamel Hypoplasia', value: '19' },
    { target: 'Temporomandibular Joint Dysfunction Syndrome', source: 'Temporomandibular Joint Disorders', value: '11' },
    { target: 'Bruxism', source: 'Tooth Diseases', value: '11' },
    { target: 'Dental Deposits', source: 'Tooth Diseases', value: '11' },
    { target: 'Dental Leakage', source: 'Tooth Diseases', value: '11' },
    { target: 'Dental Pulp Diseases', source: 'Tooth Diseases', value: '11' },
    { target: 'Dentin Sensitivity', source: 'Tooth Diseases', value: '11' },
    { target: 'Fluorosis, Dental', source: 'Tooth Diseases', value: '11' },
    { target: 'Focal Infection, Dental', source: 'Tooth Diseases', value: '11' },
    { target: 'Hypercementosis', source: 'Tooth Diseases', value: '11' },
    { target: 'Malocclusion', source: 'Tooth Diseases', value: '11' },
    { target: 'Mouth, Edentulous', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Abnormalities', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Ankylosis', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Demineralization', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Discoloration', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Eruption, Ectopic', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Wear', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth, Impacted', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Injuries', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Loss', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth Resorption', source: 'Tooth Diseases', value: '11' },
    { target: 'Tooth, Unerupted', source: 'Tooth Diseases', value: '11' },
    { target: 'Toothache', source: 'Tooth Diseases', value: '11' },
    { target: 'Sleep Bruxism', source: 'Bruxism', value: '15' },
    { target: 'Dental Calculus', source: 'Dental Deposits', value: '15' },
    { target: 'Dental Plaque', source: 'Dental Deposits', value: '15' },
    { target: 'Smear Layer', source: 'Dental Deposits', value: '15' },
    { target: 'Dental Pulp Calcification', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Dental Pulp Exposure', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Dental Pulp Necrosis', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Dentin, Secondary', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Pulpitis', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Tooth, Nonvital', source: 'Dental Pulp Diseases', value: '15' },
    { target: 'Dental Occlusion, Traumatic', source: 'Malocclusion', value: '15' },
    { target: 'Malocclusion, Angle Class I', source: 'Malocclusion', value: '15' },
    { target: 'Malocclusion, Angle Class II', source: 'Malocclusion', value: '15' },
    { target: 'Malocclusion, Angle Class III', source: 'Malocclusion', value: '15' },
    { target: 'Open Bite', source: 'Malocclusion', value: '15' },
    { target: 'Overbite', source: 'Malocclusion, Angle Class II', value: '19' },
    { target: 'Jaw, Edentulous', source: 'Mouth, Edentulous', value: '15' },
    { target: 'Jaw, Edentulous, Partially', source: 'Jaw, Edentulous', value: '19' },
    { target: 'Anodontia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dens in Dente', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dental Enamel Hypoplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dentin Dysplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Dentinogenesis Imperfecta', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Diastema', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Fused Teeth', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Odontodysplasia', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Tooth, Supernumerary', source: 'Tooth Abnormalities', value: '15' },
    { target: 'Amelogenesis Imperfecta', source: 'Dental Enamel Hypoplasia', value: '19' },
    { target: 'Dental Caries', source: 'Tooth Demineralization', value: '15' },
    { target: 'Dental Fissures', source: 'Dental Caries', value: '19' },
    { target: 'Root Caries', source: 'Dental Caries', value: '19' },
    { target: 'Tooth Abrasion', source: 'Tooth Wear', value: '15' },
    { target: 'Tooth Attrition', source: 'Tooth Wear', value: '15' },
    { target: 'Tooth Erosion', source: 'Tooth Wear', value: '15' },
    { target: 'Tooth Avulsion', source: 'Tooth Injuries', value: '15' },
    { target: 'Tooth Fractures', source: 'Tooth Injuries', value: '15' },
    { target: 'Cracked Tooth Syndrome', source: 'Tooth Fractures', value: '19' },
    { target: 'Root Resorption', source: 'Tooth Resorption', value: '15' },
    { target: 'Asthma', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchial Fistula', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchial Hyperreactivity', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchial Neoplasms', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchial Spasm', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchiectasis', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchitis', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchogenic Cyst', source: 'Bronchial Diseases', value: '11' },
    { target: 'Bronchopneumonia', source: 'Bronchial Diseases', value: '11' },
    { target: 'Tracheobronchomalacia', source: 'Bronchial Diseases', value: '11' },
    { target: 'Tracheobronchomegaly', source: 'Bronchial Diseases', value: '11' },
    { target: 'Asthma, Aspirin-Induced', source: 'Asthma', value: '15' },
    { target: 'Asthma, Exercise-Induced', source: 'Asthma', value: '15' },
    { target: 'Asthma, Occupational', source: 'Asthma', value: '15' },
    { target: 'Status Asthmaticus', source: 'Asthma', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Bronchiectasis', value: '15' },
    { target: 'Bronchiolitis', source: 'Bronchitis', value: '15' },
    { target: 'Bronchitis, Chronic', source: 'Bronchitis', value: '15' },
    { target: 'Bronchiolitis Obliterans', source: 'Bronchiolitis', value: '19' },
    { target: 'Bronchiolitis, Viral', source: 'Bronchiolitis', value: '19' },
    { target: 'Cryptogenic Organizing Pneumonia', source: 'Bronchiolitis Obliterans', value: '23' },
    { target: 'Bronchomalacia', source: 'Tracheobronchomalacia', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Ciliary Motility Disorders', value: '11' },
    { target: 'Granuloma, Laryngeal', source: 'Granuloma, Respiratory Tract', value: '11' },
    { target: 'Granuloma, Laryngeal', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Edema', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Neoplasms', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Nerve Injuries', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngitis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngocele', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngomalacia', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngopharyngeal Reflux', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngostenosis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Supraglottitis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Tuberculosis, Laryngeal', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Vocal Cord Dysfunction', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Vocal Cord Paralysis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Voice Disorders', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Recurrent Laryngeal Nerve Injuries', source: 'Laryngeal Nerve Injuries', value: '15' },
    { target: 'Croup', source: 'Laryngitis', value: '15' },
    { target: 'Laryngismus', source: 'Vocal Cord Dysfunction', value: '15' },
    { target: 'Aphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Dysphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Hoarseness', source: 'Voice Disorders', value: '15' },
    { target: 'Acute Chest Syndrome', source: 'Lung Diseases', value: '11' },
    { target: 'alpha 1-Antitrypsin Deficiency', source: 'Lung Diseases', value: '11' },
    { target: 'Cystic Adenomatoid Malformation of Lung, Congenital', source: 'Lung Diseases', value: '11' },
    { target: 'Cystic Fibrosis', source: 'Lung Diseases', value: '11' },
    { target: 'Hemoptysis', source: 'Lung Diseases', value: '11' },
    { target: 'Hepatopulmonary Syndrome', source: 'Lung Diseases', value: '11' },
    { target: 'Hypertension, Pulmonary', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Abscess', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Diseases, Fungal', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Diseases, Interstitial', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Diseases, Obstructive', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Diseases, Parasitic', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Injury', source: 'Lung Diseases', value: '11' },
    { target: 'Lung Neoplasms', source: 'Lung Diseases', value: '11' },
    { target: 'Lung, Hyperlucent', source: 'Lung Diseases', value: '11' },
    { target: 'Plasma Cell Granuloma, Pulmonary', source: 'Lung Diseases', value: '11' },
    { target: 'Pneumonia', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Alveolar Proteinosis', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Atelectasis', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Edema', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Embolism', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Eosinophilia', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Fibrosis', source: 'Lung Diseases', value: '11' },
    { target: 'Pulmonary Veno-Occlusive Disease', source: 'Lung Diseases', value: '11' },
    { target: 'Respiratory Distress Syndrome, Adult', source: 'Lung Diseases', value: '11' },
    { target: 'Respiratory Distress Syndrome, Newborn', source: 'Lung Diseases', value: '11' },
    { target: 'Scimitar Syndrome', source: 'Lung Diseases', value: '11' },
    { target: 'Solitary Pulmonary Nodule', source: 'Lung Diseases', value: '11' },
    { target: 'Tuberculosis, Pulmonary', source: 'Lung Diseases', value: '11' },
    { target: 'Persistent Fetal Circulation Syndrome', source: 'Hypertension, Pulmonary', value: '15' },
    { target: 'Familial Primary Pulmonary Hypertension', source: 'Hypertension, Pulmonary', value: '15' },
    { target: 'Blastomycosis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Pulmonary Aspergillosis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Pulmonary Aspergillosis', value: '19' },
    { target: 'Invasive Pulmonary Aspergillosis', source: 'Pulmonary Aspergillosis', value: '19' },
    { target: 'Alveolitis, Extrinsic Allergic', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Anti-Glomerular Basement Membrane Disease', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Histiocytosis, Langerhans-Cell', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Idiopathic Interstitial Pneumonias', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Pneumoconiosis', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Radiation Pneumonitis', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Sarcoidosis, Pulmonary', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Granulomatosis with Polyangiitis', source: 'Lung Diseases, Interstitial', value: '15' },
    { target: 'Bird Fancier\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '19' },
    { target: 'Farmer\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '19' },
    { target: 'Silo Filler\'s Disease', source: 'Alveolitis, Extrinsic Allergic', value: '19' },
    { target: 'Trichosporonosis', source: 'Alveolitis, Extrinsic Allergic', value: '19' },
    { target: 'Eosinophilic Granuloma', source: 'Histiocytosis, Langerhans-Cell', value: '19' },
    { target: 'Cryptogenic Organizing Pneumonia', source: 'Idiopathic Interstitial Pneumonias', value: '19' },
    { target: 'Idiopathic Pulmonary Fibrosis', source: 'Idiopathic Interstitial Pneumonias', value: '19' },
    { target: 'Anthracosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Asbestosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Berylliosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Byssinosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Caplan Syndrome', source: 'Pneumoconiosis', value: '19' },
    { target: 'Siderosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Silicosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Anthracosilicosis', source: 'Anthracosis', value: '23' },
    { target: 'Anthracosilicosis', source: 'Silicosis', value: '23' },
    { target: 'Silicotuberculosis', source: 'Silicosis', value: '23' },
    { target: 'Asthma', source: 'Lung Diseases, Obstructive', value: '15' },
    { target: 'Bronchitis', source: 'Lung Diseases, Obstructive', value: '15' },
    { target: 'Pulmonary Disease, Chronic Obstructive', source: 'Lung Diseases, Obstructive', value: '15' },
    { target: 'Bronchiolitis', source: 'Bronchitis', value: '19' },
    { target: 'Bronchitis, Chronic', source: 'Bronchitis', value: '19' },
    { target: 'Bronchiolitis Obliterans', source: 'Bronchiolitis', value: '23' },
    { target: 'Bronchiolitis, Viral', source: 'Bronchiolitis', value: '23' },
    { target: 'Cryptogenic Organizing Pneumonia', source: 'Bronchiolitis Obliterans', value: '27' },
    { target: 'Bronchitis, Chronic', source: 'Pulmonary Disease, Chronic Obstructive', value: '19' },
    { target: 'Pulmonary Emphysema', source: 'Pulmonary Disease, Chronic Obstructive', value: '19' },
    { target: 'Echinococcosis, Pulmonary', source: 'Lung Diseases, Parasitic', value: '15' },
    { target: 'Acute Lung Injury', source: 'Lung Injury', value: '15' },
    { target: 'Meconium Aspiration Syndrome', source: 'Lung Injury', value: '15' },
    { target: 'Pneumoconiosis', source: 'Lung Injury', value: '15' },
    { target: 'Radiation Pneumonitis', source: 'Lung Injury', value: '15' },
    { target: 'Ventilator-Induced Lung Injury', source: 'Lung Injury', value: '15' },
    { target: 'Anthracosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Asbestosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Berylliosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Byssinosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Caplan Syndrome', source: 'Pneumoconiosis', value: '19' },
    { target: 'Siderosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Silicosis', source: 'Pneumoconiosis', value: '19' },
    { target: 'Anthracosilicosis', source: 'Anthracosis', value: '23' },
    { target: 'Anthracosilicosis', source: 'Silicosis', value: '23' },
    { target: 'Silicotuberculosis', source: 'Silicosis', value: '23' },
    { target: 'Bronchopulmonary Dysplasia', source: 'Ventilator-Induced Lung Injury', value: '19' },
    { target: 'Pneumonia, Ventilator-Associated', source: 'Ventilator-Induced Lung Injury', value: '19' },
    { target: 'Carcinoma, Bronchogenic', source: 'Lung Neoplasms', value: '15' },
    { target: 'Multiple Pulmonary Nodules', source: 'Lung Neoplasms', value: '15' },
    { target: 'Pancoast Syndrome', source: 'Lung Neoplasms', value: '15' },
    { target: 'Pulmonary Sclerosing Hemangioma', source: 'Lung Neoplasms', value: '15' },
    { target: 'Carcinoma, Non-Small-Cell Lung', source: 'Carcinoma, Bronchogenic', value: '19' },
    { target: 'Small Cell Lung Carcinoma', source: 'Carcinoma, Bronchogenic', value: '19' },
    { target: 'Bronchopneumonia', source: 'Pneumonia', value: '15' },
    { target: 'Pleuropneumonia', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Aspiration', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Bacterial', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Necrotizing', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Ventilator-Associated', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Viral', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Lipid', source: 'Pneumonia, Aspiration', value: '19' },
    { target: 'Chlamydial Pneumonia', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Mycoplasma', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia of Calves, Enzootic', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia of Swine, Mycoplasmal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Pneumococcal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Rickettsial', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Staphylococcal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Middle Lobe Syndrome', source: 'Pulmonary Atelectasis', value: '15' },
    { target: 'Pulmonary Infarction', source: 'Pulmonary Embolism', value: '15' },
    { target: 'Idiopathic Pulmonary Fibrosis', source: 'Pulmonary Fibrosis', value: '15' },
    { target: 'Hyaline Membrane Disease', source: 'Respiratory Distress Syndrome, Newborn', value: '15' },
    { target: 'Transient Tachypnea of the Newborn', source: 'Respiratory Distress Syndrome, Newborn', value: '15' },
    { target: 'ilicotuberculosis', source: 'Tuberculosis, Pulmonary', value: '15' },
    { target: 'Choanal Atresia', source: 'Nose Diseases', value: '11' },
    { target: 'Epistaxis', source: 'Nose Diseases', value: '11' },
    { target: 'Granuloma, Lethal Midline', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Obstruction', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Polyps', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Septal Perforation', source: 'Nose Diseases', value: '11' },
    { target: 'Nose Deformities, Acquired', source: 'Nose Diseases', value: '11' },
    { target: 'Nose Neoplasms', source: 'Nose Diseases', value: '11' },
    { target: 'Paranasal Sinus Diseases', source: 'Nose Diseases', value: '11' },
    { target: 'Rhinitis', source: 'Nose Diseases', value: '11' },
    { target: 'Rhinoscleroma', source: 'Nose Diseases', value: '11' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Nose Neoplasms', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Paranasal Sinus Diseases', value: '15' },
    { target: 'Sinusitis', source: 'Paranasal Sinus Diseases', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Ethmoid Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Frontal Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Maxillary Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Sphenoid Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Rhinitis, Allergic', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Atrophic', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Vasomotor', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Allergic, Perennial', source: 'Rhinitis, Allergic', value: '19' },
    { target: 'Rhinitis, Allergic, Seasonal', source: 'Rhinitis, Allergic', value: '19' },
    { target: 'Chylothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Empyema, Pleural', source: 'Pleural Diseases', value: '11' },
    { target: 'Hemopneumothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Hemothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Hydropneumothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Hydrothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Pleural Effusion', source: 'Pleural Diseases', value: '11' },
    { target: 'Pleural Neoplasms', source: 'Pleural Diseases', value: '11' },
    { target: 'Pleurisy', source: 'Pleural Diseases', value: '11' },
    { target: 'Pneumothorax', source: 'Pleural Diseases', value: '11' },
    { target: 'Tuberculosis, Pleural', source: 'Pleural Diseases', value: '11' },
    { target: 'Empyema, Tuberculous', source: 'Empyema, Pleural', value: '15' },
    { target: 'Pleural Effusion, Malignant', source: 'Pleural Effusion', value: '15' },
    { target: 'Pleural Effusion, Malignant', source: 'Pleural Neoplasms', value: '15' },
    { target: 'Pleuropneumonia', source: 'Pleurisy', value: '15' },
    { target: 'Empyema, Tuberculous', source: 'Tuberculosis, Pleural', value: '15' },
    { target: 'Acute Chest Syndrome', source: 'Respiration Disorders', value: '11' },
    { target: 'Altitude Sickness', source: 'Respiration Disorders', value: '11' },
    { target: 'Apnea', source: 'Respiration Disorders', value: '11' },
    { target: 'Cheyne-Stokes Respiration', source: 'Respiration Disorders', value: '11' },
    { target: 'Cough', source: 'Respiration Disorders', value: '11' },
    { target: 'Dyspnea', source: 'Respiration Disorders', value: '11' },
    { target: 'Hoarseness', source: 'Respiration Disorders', value: '11' },
    { target: 'Hyperventilation', source: 'Respiration Disorders', value: '11' },
    { target: 'Meconium Aspiration Syndrome', source: 'Respiration Disorders', value: '11' },
    { target: 'Mouth Breathing', source: 'Respiration Disorders', value: '11' },
    { target: 'Respiratory Aspiration', source: 'Respiration Disorders', value: '11' },
    { target: 'Respiratory Distress Syndrome, Adult', source: 'Respiration Disorders', value: '11' },
    { target: 'Respiratory Distress Syndrome, Newborn', source: 'Respiration Disorders', value: '11' },
    { target: 'Respiratory Insufficiency', source: 'Respiration Disorders', value: '11' },
    { target: 'Sarcoglycanopathies', source: 'Respiration Disorders', value: '11' },
    { target: 'Tachypnea', source: 'Respiration Disorders', value: '11' },
    { target: 'Vocal Cord Dysfunction', source: 'Respiration Disorders', value: '11' },
    { target: 'Sleep Apnea Syndromes', source: 'Apnea', value: '15' },
    { target: 'Sleep Apnea, Central', source: 'Sleep Apnea Syndromes', value: '19' },
    { target: 'Sleep Apnea, Obstructive', source: 'Sleep Apnea Syndromes', value: '19' },
    { target: 'Obesity Hypoventilation Syndrome', source: 'Sleep Apnea, Obstructive', value: '23' },
    { target: 'Dyspnea, Paroxysmal', source: 'Dyspnea', value: '15' },
    { target: 'Lipoid Proteinosis of Urbach and Wiethe', source: 'Hoarseness', value: '15' },
    { target: 'Alkalosis, Respiratory', source: 'Hyperventilation', value: '15' },
    { target: 'Respiratory Aspiration of Gastric Contents', source: 'Respiratory Aspiration', value: '15' },
    { target: 'Hyaline Membrane Disease', source: 'Respiratory Distress Syndrome, Newborn', value: '15' },
    { target: 'Transient Tachypnea of the Newborn', source: 'Respiratory Distress Syndrome, Newborn', value: '15' },
    { target: 'Acidosis, Respiratory', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Airway Obstruction', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Granuloma, Laryngeal', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Hantavirus Pulmonary Syndrome', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Hypoventilation', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Infantile Apparent Life-Threatening Event', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Positive-Pressure Respiration, Intrinsic', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Respiratory Paralysis', source: 'Respiratory Insufficiency', value: '15' },
    { target: 'Nasal Obstruction', source: 'Airway Obstruction', value: '19' },
    { target: 'Obesity Hypoventilation Syndrome', source: 'Hypoventilation', value: '19' },
    { target: 'Transient Tachypnea of the Newborn', source: 'Tachypnea', value: '15' },
    { target: 'Laryngismus', source: 'Vocal Cord Dysfunction', value: '15' },
    { target: 'Alveolitis, Extrinsic Allergic', source: 'Respiratory Hypersensitivity', value: '11' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Respiratory Hypersensitivity', value: '11' },
    { target: 'Asthma', source: 'Respiratory Hypersensitivity', value: '11' },
    { target: 'Rhinitis, Allergic', source: 'Respiratory Hypersensitivity', value: '11' },
    { target: 'Bird Fancier\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '15' },
    { target: 'Farmer\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '15' },
    { target: 'Trichosporonosis', source: 'Alveolitis, Extrinsic Allergic', value: '15' },
    { target: 'Asthma, Aspirin-Induced', source: 'Asthma', value: '15' },
    { target: 'Asthma, Exercise-Induced', source: 'Asthma', value: '15' },
    { target: 'Asthma, Occupational', source: 'Asthma', value: '15' },
    { target: 'Status Asthmaticus', source: 'Asthma', value: '15' },
    { target: 'Rhinitis, Allergic, Perennial', source: 'Rhinitis, Allergic', value: '15' },
    { target: 'Rhinitis, Allergic, Seasonal', source: 'Rhinitis, Allergic', value: '15' },
    { target: 'Bronchogenic Cyst', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Bronchopulmonary Sequestration', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Choanal Atresia', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Cystic Adenomatoid Malformation of Lung, Congenital', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Kartagener Syndrome', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Laryngocele', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Scimitar Syndrome', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Tracheobronchomegaly', source: 'Respiratory System Abnormalities', value: '11' },
    { target: 'Bronchial Fistula', source: 'Respiratory Tract Fistula', value: '11' },
    { target: 'Tracheoesophageal Fistula', source: 'Respiratory Tract Fistula', value: '11' },
    { target: 'Bovine Respiratory Disease Complex', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Bronchitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Common Cold', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Empyema, Pleural', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Influenza, Human', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Laryngitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Legionellosis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Lung Abscess', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Lung Diseases, Fungal', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Lung Diseases, Parasitic', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Pharyngitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Pleurisy', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Pneumonia', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Rhinitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Rhinoscleroma', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Severe Acute Respiratory Syndrome', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Sinusitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Supraglottitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Tracheitis', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Tuberculosis, Laryngeal', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Tuberculosis, Pleural', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Tuberculosis, Pulmonary', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Whooping Cough', source: 'Respiratory Tract Infections', value: '11' },
    { target: 'Pasteurellosis, Pneumonic', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Pneumonia, Atypical Interstitial, of Cattle', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Pneumonia of Calves, Enzootic', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Bronchiolitis', source: 'Bronchitis', value: '15' },
    { target: 'Bronchitis, Chronic', source: 'Bronchitis', value: '15' },
    { target: 'Bronchiolitis, Viral', source: 'Bronchiolitis', value: '19' },
    { target: 'Empyema, Tuberculous', source: 'Empyema, Pleural', value: '15' },
    { target: 'Legionnaires\' Disease', source: 'Legionellosis', value: '15' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Blastomycosis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Lung Diseases, Fungal', value: '15' },
    { target: 'Echinococcosis, Pulmonary', source: 'Lung Diseases, Parasitic', value: '15' },
    { target: 'Nasopharyngitis', source: 'Pharyngitis', value: '15' },
    { target: 'Retropharyngeal Abscess', source: 'Pharyngitis', value: '15' },
    { target: 'Tonsillitis', source: 'Pharyngitis', value: '15' },
    { target: 'Peritonsillar Abscess', source: 'Tonsillitis', value: '19' },
    { target: 'Pleuropneumonia', source: 'Pleurisy', value: '15' },
    { target: 'Bronchopneumonia', source: 'Pneumonia', value: '15' },
    { target: 'Pleuropneumonia', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Aspiration', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Bacterial', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Necrotizing', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Pneumocystis', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Ventilator-Associated', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Viral', source: 'Pneumonia', value: '15' },
    { target: 'Pneumonia, Lipid', source: 'Pneumonia, Aspiration', value: '19' },
    { target: 'Chlamydial Pneumonia', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Mycoplasma', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia of Calves, Enzootic', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia of Swine, Mycoplasmal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Pneumococcal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Rickettsial', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Pneumonia, Staphylococcal', source: 'Pneumonia, Bacterial', value: '19' },
    { target: 'Ethmoid Sinusitis', source: 'Sinusitis', value: '15' },
    { target: 'Frontal Sinusitis', source: 'Sinusitis', value: '15' },
    { target: 'Maxillary Sinusitis', source: 'Sinusitis', value: '15' },
    { target: 'Sphenoid Sinusitis', source: 'Sinusitis', value: '15' },
    { target: 'Epiglottitis', source: 'Supraglottitis', value: '15' },
    { target: 'Empyema, Tuberculous', source: 'Tuberculosis, Pleural', value: '15' },
    { target: 'Silicotuberculosis', source: 'Tuberculosis, Pulmonary', value: '15' },
    { target: 'Laryngeal Neoplasms', source: 'Respiratory Tract Neoplasms', value: '11' },
    { target: 'Lung Neoplasms', source: 'Respiratory Tract Neoplasms', value: '11' },
    { target: 'Nose Neoplasms', source: 'Respiratory Tract Neoplasms', value: '11' },
    { target: 'Pleural Neoplasms', source: 'Respiratory Tract Neoplasms', value: '11' },
    { target: 'Tracheal Neoplasms', source: 'Respiratory Tract Neoplasms', value: '11' },
    { target: 'Bronchial Neoplasms', source: 'Lung Neoplasms', value: '15' },
    { target: 'Multiple Pulmonary Nodules', source: 'Lung Neoplasms', value: '15' },
    { target: 'Pancoast Syndrome', source: 'Lung Neoplasms', value: '15' },
    { target: 'Pulmonary Sclerosing Hemangioma', source: 'Lung Neoplasms', value: '15' },
    { target: 'Carcinoma, Bronchogenic', source: 'Bronchial Neoplasms', value: '19' },
    { target: 'Carcinoma, Non-Small-Cell Lung', source: 'Carcinoma, Bronchogenic', value: '23' },
    { target: 'Small Cell Lung Carcinoma', source: 'Carcinoma, Bronchogenic', value: '23' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Nose Neoplasms', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Pleural Effusion, Malignant', source: 'Pleural Neoplasms', value: '15' },
    { target: 'Mediastinal Diseases', source: 'Thoracic Diseases', value: '11' },
    { target: 'Mediastinal Cyst', source: 'Mediastinal Diseases', value: '15' },
    { target: 'Mediastinal Emphysema', source: 'Mediastinal Diseases', value: '15' },
    { target: 'Mediastinal Neoplasms', source: 'Mediastinal Diseases', value: '15' },
    { target: 'Mediastinitis', source: 'Mediastinal Diseases', value: '15' },
    { target: 'Tracheal Neoplasms', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheal Stenosis', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheitis', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheobronchomalacia', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheobronchomegaly', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheoesophageal Fistula', source: 'Tracheal Diseases', value: '11' },
    { target: 'Tracheomalacia', source: 'Tracheobronchomalacia', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Ciliary Motility Disorders', value: '11' },
    { target: 'Cholesteatoma, Middle Ear', source: 'Ear Diseases', value: '11' },
    { target: 'Congenital Microtia', source: 'Ear Diseases', value: '11' },
    { target: 'Ear Deformities, Acquired', source: 'Ear Diseases', value: '11' },
    { target: 'Ear Neoplasms', source: 'Ear Diseases', value: '11' },
    { target: 'Earache', source: 'Ear Diseases', value: '11' },
    { target: 'Hearing Disorders', source: 'Ear Diseases', value: '11' },
    { target: 'Herpes Zoster Oticus', source: 'Ear Diseases', value: '11' },
    { target: 'Labyrinth Diseases', source: 'Ear Diseases', value: '11' },
    { target: 'Myringosclerosis', source: 'Ear Diseases', value: '11' },
    { target: 'Otitis', source: 'Ear Diseases', value: '11' },
    { target: 'Otomycosis', source: 'Ear Diseases', value: '11' },
    { target: 'Otosclerosis', source: 'Ear Diseases', value: '11' },
    { target: 'Retrocochlear Diseases', source: 'Ear Diseases', value: '11' },
    { target: 'Susac Syndrome', source: 'Ear Diseases', value: '11' },
    { target: 'Tympanic Membrane Perforation', source: 'Ear Diseases', value: '11' },
    { target: 'Hearing Loss', source: 'Hearing Disorders', value: '15' },
    { target: 'Hyperacusis', source: 'Hearing Disorders', value: '15' },
    { target: 'Tinnitus', source: 'Hearing Disorders', value: '15' },
    { target: 'Deafness', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Bilateral', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Conductive', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Functional', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, High-Frequency', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Mixed Conductive-Sensorineural', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Sensorineural', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Sudden', source: 'Hearing Loss', value: '19' },
    { target: 'Hearing Loss, Unilateral', source: 'Hearing Loss', value: '19' },
    { target: 'Deaf-Blind Disorders', source: 'Deafness', value: '23' },
    { target: 'Usher Syndromes', source: 'Deaf-Blind Disorders', value: '27' },
    { target: 'Wolfram Syndrome', source: 'Deaf-Blind Disorders', value: '27' },
    { target: 'Hearing Loss, Central', source: 'Hearing Loss, Sensorineural', value: '23' },
    { target: 'Hearing Loss, Noise-Induced', source: 'Hearing Loss, Sensorineural', value: '23' },
    { target: 'Presbycusis', source: 'Hearing Loss, Sensorineural', value: '23' },
    { target: 'Usher Syndromes', source: 'Hearing Loss, Sensorineural', value: '23' },
    { target: 'Cochlear Diseases', source: 'Labyrinth Diseases', value: '15' },
    { target: 'Endolymphatic Hydrops', source: 'Labyrinth Diseases', value: '15' },
    { target: 'Labyrinthitis', source: 'Labyrinth Diseases', value: '15' },
    { target: 'Vestibular Diseases', source: 'Labyrinth Diseases', value: '15' },
    { target: 'Meniere Disease', source: 'Endolymphatic Hydrops', value: '19' },
    { target: 'Bilateral Vestibulopathy', source: 'Vestibular Diseases', value: '19' },
    { target: 'Vertigo', source: 'Vestibular Diseases', value: '19' },
    { target: 'Benign Paroxysmal Positional Vertigo', source: 'Vertigo', value: '23' },
    { target: 'Labyrinthitis', source: 'Otitis', value: '15' },
    { target: 'Otitis Externa', source: 'Otitis', value: '15' },
    { target: 'Otitis Media', source: 'Otitis', value: '15' },
    { target: 'Mastoiditis', source: 'Otitis Media', value: '19' },
    { target: 'Otitis Media, Suppurative', source: 'Otitis Media', value: '19' },
    { target: 'Otitis Media with Effusion', source: 'Otitis Media', value: '19' },
    { target: 'Petrositis', source: 'Otitis Media', value: '19' },
    { target: 'Auditory Diseases, Central', source: 'Retrocochlear Diseases', value: '15' },
    { target: 'Vestibulocochlear Nerve Diseases', source: 'Retrocochlear Diseases', value: '15' },
    { target: 'Auditory Perceptual Disorders', source: 'Auditory Diseases, Central', value: '19' },
    { target: 'Hearing Loss, Central', source: 'Auditory Diseases, Central', value: '19' },
    { target: 'Neuroma, Acoustic', source: 'Vestibulocochlear Nerve Diseases', value: '19' },
    { target: 'Vestibular Neuronitis', source: 'Vestibulocochlear Nerve Diseases', value: '19' },
    { target: 'Vestibulocochlear Nerve Injuries', source: 'Vestibulocochlear Nerve Diseases', value: '19' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '23' },
    { target: 'Granuloma, Laryngeal', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Edema', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Neoplasms', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngeal Nerve Injuries', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngitis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngocele', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngomalacia', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Laryngostenosis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Supraglottitis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Tuberculosis, Laryngeal', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Vocal Cord Dysfunction', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Vocal Cord Paralysis', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Voice Disorders', source: 'Laryngeal Diseases', value: '11' },
    { target: 'Recurrent Laryngeal Nerve Injuries', source: 'Laryngeal Nerve Injuries', value: '15' },
    { target: 'Croup', source: 'Laryngitis', value: '15' },
    { target: 'Laryngismus', source: 'Vocal Cord Dysfunction', value: '15' },
    { target: 'Aphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Dysphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Hoarseness', source: 'Voice Disorders', value: '15' },
    { target: 'Choanal Atresia', source: 'Nose Diseases', value: '11' },
    { target: 'Epistaxis', source: 'Nose Diseases', value: '11' },
    { target: 'Granuloma, Lethal Midline', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Obstruction', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Polyps', source: 'Nose Diseases', value: '11' },
    { target: 'Nasal Septal Perforation', source: 'Nose Diseases', value: '11' },
    { target: 'Nose Deformities, Acquired', source: 'Nose Diseases', value: '11' },
    { target: 'Nose Neoplasms', source: 'Nose Diseases', value: '11' },
    { target: 'Paranasal Sinus Diseases', source: 'Nose Diseases', value: '11' },
    { target: 'Rhinitis', source: 'Nose Diseases', value: '11' },
    { target: 'Rhinoscleroma', source: 'Nose Diseases', value: '11' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Nose Neoplasms', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Paranasal Sinus Diseases', value: '15' },
    { target: 'Sinusitis', source: 'Paranasal Sinus Diseases', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Ethmoid Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Frontal Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Maxillary Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Sphenoid Sinusitis', source: 'Sinusitis', value: '19' },
    { target: 'Rhinitis, Allergic', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Atrophic', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Vasomotor', source: 'Rhinitis', value: '15' },
    { target: 'Rhinitis, Allergic, Perennial', source: 'Rhinitis, Allergic', value: '19' },
    { target: 'Rhinitis, Allergic, Seasonal', source: 'Rhinitis, Allergic', value: '19' },
    { target: 'Ear Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '11' },
    { target: 'Laryngeal Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '11' },
    { target: 'Neuroma, Acoustic', source: 'Otorhinolaryngologic Neoplasms', value: '11' },
    { target: 'Nose Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '11' },
    { target: 'Pharyngeal Neoplasms', source: 'Otorhinolaryngologic Neoplasms', value: '11' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '15' },
    { target: 'Paranasal Sinus Neoplasms', source: 'Nose Neoplasms', value: '15' },
    { target: 'Maxillary Sinus Neoplasms', source: 'Paranasal Sinus Neoplasms', value: '19' },
    { target: 'Hypopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Oropharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Tonsillar Neoplasms', source: 'Oropharyngeal Neoplasms', value: '19' },
    { target: 'Deglutition Disorders', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Lemierre Syndrome', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Nasopharyngeal Diseases', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Pharyngeal Neoplasms', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Pharyngitis', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Velopharyngeal Insufficiency', source: 'Pharyngeal Diseases', value: '11' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Nasopharyngeal Diseases', value: '15' },
    { target: 'Nasopharyngitis', source: 'Nasopharyngeal Diseases', value: '15' },
    { target: 'Hypopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Nasopharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Oropharyngeal Neoplasms', source: 'Pharyngeal Neoplasms', value: '15' },
    { target: 'Tonsillar Neoplasms', source: 'Oropharyngeal Neoplasms', value: '19' },
    { target: 'Nasopharyngitis', source: 'Pharyngitis', value: '15' },
    { target: 'Retropharyngeal Abscess', source: 'Pharyngitis', value: '15' },
    { target: 'Tonsillitis', source: 'Pharyngitis', value: '15' },
    { target: 'Peritonsillar Abscess', source: 'Tonsillitis', value: '19' },
    { target: 'Demyelinating Autoimmune Diseases, CNS', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Myasthenia Gravis', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Nervous System Autoimmune Disease, Experimental', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Polyradiculoneuropathy', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Stiff-Person Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Uveomeningoencephalitic Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Vasculitis, Central Nervous System', source: 'Autoimmune Diseases of the Nervous System', value: '11' },
    { target: 'Diffuse Cerebral Sclerosis of Schilder', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Encephalomyelitis, Acute Disseminated', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Multiple Sclerosis', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Myelitis, Transverse', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Neuromyelitis Optica', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Encephalomyelitis, Acute Disseminated', value: '19' },
    { target: 'Multiple Sclerosis, Chronic Progressive', source: 'Multiple Sclerosis', value: '19' },
    { target: 'Multiple Sclerosis, Relapsing-Remitting', source: 'Multiple Sclerosis', value: '19' },
    { target: 'Neuromyelitis Optica', source: 'Myelitis, Transverse', value: '19' },
    { target: 'Myasthenia Gravis, Autoimmune, Experimental', source: 'Myasthenia Gravis', value: '15' },
    { target: 'Myasthenia Gravis, Neonatal', source: 'Myasthenia Gravis', value: '15' },
    { target: 'Encephalomyelitis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '15' },
    { target: 'Myasthenia Gravis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '15' },
    { target: 'Neuritis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '15' },
    { target: 'Guillain-Barre Syndrome', source: 'Polyradiculoneuropathy', value: '15' },
    { target: 'Polyradiculoneuropathy, Chronic Inflammatory Demyelinating', source: 'Polyradiculoneuropathy', value: '15' },
    { target: 'Miller Fisher Syndrome', source: 'Guillain-Barre Syndrome', value: '19' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '15' },
    { target: 'Giant Cell Arteritis', source: 'Vasculitis, Central Nervous System', value: '15' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '15' },
    { target: 'Adie Syndrome', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Autonomic Dysreflexia', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Complex Regional Pain Syndromes', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Horner Syndrome', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Primary Dysautonomias', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Sweating, Gustatory', source: 'Autonomic Nervous System Diseases', value: '11' },
    { target: 'Causalgia', source: 'Complex Regional Pain Syndromes', value: '15' },
    { target: 'Reflex Sympathetic Dystrophy', source: 'Complex Regional Pain Syndromes', value: '15' },
    { target: 'Dysautonomia, Familial', source: 'Primary Dysautonomias', value: '15' },
    { target: 'Multiple System Atrophy', source: 'Primary Dysautonomias', value: '15' },
    { target: 'Orthostatic Intolerance', source: 'Primary Dysautonomias', value: '15' },
    { target: 'Pure Autonomic Failure', source: 'Primary Dysautonomias', value: '15' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Shy-Drager Syndrome', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Striatonigral Degeneration', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Hypotension, Orthostatic', source: 'Orthostatic Intolerance', value: '19' },
    { target: 'Post-Exercise Hypotension', source: 'Orthostatic Intolerance', value: '19' },
    { target: 'Postural Orthostatic Tachycardia Syndrome', source: 'Orthostatic Intolerance', value: '19' },
    { target: 'Syncope, Vasovagal', source: 'Orthostatic Intolerance', value: '19' },
    { target: 'Brain Diseases', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Central Nervous System Infections', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Encephalomyelitis', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'High Pressure Neurological Syndrome', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Hyperekplexia', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Meningitis', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Movement Disorders', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Ocular Motility Disorders', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Pneumocephalus', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Spinal Cord Diseases', source: 'Central Nervous System Diseases', value: '11' },
    { target: 'Acute Febrile Encephalopathy', source: 'Brain Diseases', value: '15' },
    { target: 'Akinetic Mutism', source: 'Brain Diseases', value: '15' },
    { target: 'Amblyopia', source: 'Brain Diseases', value: '15' },
    { target: 'Amnesia, Transient Global', source: 'Brain Diseases', value: '15' },
    { target: 'Auditory Diseases, Central', source: 'Brain Diseases', value: '15' },
    { target: 'Basal Ganglia Diseases', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Abscess', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Damage, Chronic', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Death', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Diseases, Metabolic', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Edema', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Injuries', source: 'Brain Diseases', value: '15' },
    { target: 'Brain Neoplasms', source: 'Brain Diseases', value: '15' },
    { target: 'Cerebellar Diseases', source: 'Brain Diseases', value: '15' },
    { target: 'Cerebrovascular Disorders', source: 'Brain Diseases', value: '15' },
    { target: 'Dementia', source: 'Brain Diseases', value: '15' },
    { target: 'Diffuse Cerebral Sclerosis of Schilder', source: 'Brain Diseases', value: '15' },
    { target: 'Encephalitis', source: 'Brain Diseases', value: '15' },
    { target: 'Encephalomalacia', source: 'Brain Diseases', value: '15' },
    { target: 'Epilepsy', source: 'Brain Diseases', value: '15' },
    { target: 'Headache Disorders', source: 'Brain Diseases', value: '15' },
    { target: 'Hydrocephalus', source: 'Brain Diseases', value: '15' },
    { target: 'Hypothalamic Diseases', source: 'Brain Diseases', value: '15' },
    { target: 'Hypoxia, Brain', source: 'Brain Diseases', value: '15' },
    { target: 'Intracranial Hypertension', source: 'Brain Diseases', value: '15' },
    { target: 'Intracranial Hypotension', source: 'Brain Diseases', value: '15' },
    { target: 'Leukoencephalopathies', source: 'Brain Diseases', value: '15' },
    { target: 'Neuroaxonal Dystrophies', source: 'Brain Diseases', value: '15' },
    { target: 'Sepsis-Associated Encephalopathy', source: 'Brain Diseases', value: '15' },
    { target: 'Subdural Effusion', source: 'Brain Diseases', value: '15' },
    { target: 'Thalamic Diseases', source: 'Brain Diseases', value: '15' },
    { target: 'Auditory Perceptual Disorders', source: 'Auditory Diseases, Central', value: '19' },
    { target: 'Hearing Loss, Central', source: 'Auditory Diseases, Central', value: '19' },
    { target: 'Basal Ganglia Cerebrovascular Disease', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Chorea Gravidarum', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Dystonia Musculorum Deformans', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Huntington Disease', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Meige Syndrome', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Multiple System Atropy', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Neuroleptic Malignant Syndrome', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Pantothenate Kinase-Associated Neurodegeneration', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Parkinsonian Disorders', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Tourette Syndrome', source: 'Basal Ganglia Diseases', value: '19' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Basal Ganglia Cerebrovascular Disease', value: '23' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '27' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Multiple System Atrophy', value: '23' },
    { target: 'Shy-Drager Syndrome', source: 'Multiple System Atrophy', value: '23' },
    { target: 'Striatonigral Degeneration', source: 'Multiple System Atrophy', value: '23' },
    { target: 'Lewy Body Disease', source: 'Parkinsonian Disorders', value: '23' },
    { target: 'Parkinson Disease', source: 'Parkinsonian Disorders', value: '23' },
    { target: 'Parkinson Disease, Secondary', source: 'Parkinsonian Disorders', value: '23' },
    { target: 'MPTP Poisoning', source: 'Parkinson Disease, Secondary', value: '27' },
    { target: 'Parkinson Disease, Postencephalitic', source: 'Parkinson Disease, Secondary', value: '27' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Brain Abscess', value: '19' },
    { target: 'Brain Injury, Chronic', source: 'Brain Damage, Chronic', value: '19' },
    { target: 'Cerebral Palsy', source: 'Brain Damage, Chronic', value: '19' },
    { target: 'Persistent Vegetative State', source: 'Brain Damage, Chronic', value: '19' },
    { target: 'Brain Diseases, Metabolic, Inborn', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Hepatic Encephalopathy', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Kernicterus', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Marchiafava-Bignami Disease', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Mitochondrial Encephalomyopathies', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Myelinolysis, Central Pontine', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Reye Syndrome', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Wernicke Encephalopathy', source: 'Brain Diseases, Metabolic', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Galactosemias', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Hartnup Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Hepatolenticular Degeneration', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Homocystinuria', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Hyperlysinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Leigh Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Maple Syrup Urine Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'MELAS Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'MERRF Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Phenylketonurias', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Refsum Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Refsum Disease, Infantile', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Tyrosinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Zellweger Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '23' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '27' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '27' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '27' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '27' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '27' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '27' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '31' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '31' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '31' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '31' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '31' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '31' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '31' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '31' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '35' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '35' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '39' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '39' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '39' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '35' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '35' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '35' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '35' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '35' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '27' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '27' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '27' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '27' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '27' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '27' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Diffuse', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injury, Chronic', source: 'Brain Injuries', value: '19' },
    { target: 'Epilepsy, Post-Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Pneumocephalus', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Diffuse Axonal Injury', source: 'Brain Injuries, Diffuse', value: '23' },
    { target: 'Brain Concussion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Brain Contusion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injury, Chronic', value: '23' },
    { target: 'Cerebral Ventricle Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Infratentorial Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Neurocytoma', source: 'Brain Neoplasms', value: '19' },
    { target: 'Pinealoma', source: 'Brain Neoplasms', value: '19' },
    { target: 'Supratentorial Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Choroid Plexus Neoplasms', source: 'Cerebral Ventricle Neoplasms', value: '23' },
    { target: 'Papilloma, Choroid Plexus', source: 'Choroid Plexus Neoplasms', value: '27' },
    { target: 'Brain Stem Neoplasms', source: 'Infratentorial Neoplasms', value: '23' },
    { target: 'Cerebellar Neoplasms', source: 'Infratentorial Neoplasms', value: '23' },
    { target: 'Hypothalamic Neoplasms', source: 'Supratentorial Neoplasms', value: '23' },
    { target: 'Pallister-Hall Syndrome', source: 'Hypothalamic Neoplasms', value: '27' },
    { target: 'Pituitary Neoplasms', source: 'Hypothalamic Neoplasms', value: '27' },
    { target: 'Cerebellar Ataxia', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Cerebellar Neoplasms', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Dandy-Walker Syndrome', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Miller Fisher Syndrome', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Paraneoplastic Cerebellar Degeneration', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Spinocerebellar Degenerations', source: 'Cerebellar Diseases', value: '19' },
    { target: 'Spinocerebellar Ataxias', source: 'Cerebellar Ataxia', value: '23' },
    { target: 'Ataxia Telangiectasia', source: 'Spinocerebellar Ataxias', value: '27' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '27' },
    { target: 'Friedreich Ataxia', source: 'Spinocerebellar Degenerations', value: '23' },
    { target: 'Myoclonic Cerebellar Dyssynergia', source: 'Spinocerebellar Degenerations', value: '23' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Spinocerebellar Degenerations', value: '23' },
    { target: 'Spinocerebellar Ataxias', source: 'Spinocerebellar Degenerations', value: '23' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '27' },
    { target: 'Basal Ganglia Cerebrovascular Disease', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Brain Ischemia', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Carotid Artery Diseases', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Cerebral Small Vessel Diseases', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Cerebrovascular Trauma', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Dementia, Vascular', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Intracranial Arterial Diseases', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Intracranial Embolism and Thrombosis', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Intracranial Hemorrhages', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Leukomalacia, Periventricular', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Sneddon Syndrome', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Stroke', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Susac Syndrome', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Vascular Headaches', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Vasculitis, Central Nervous System', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Vasospasm, Intracranial', source: 'Cerebrovascular Disorders', value: '19' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Basal Ganglia Cerebrovascular Disease', value: '23' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '27' },
    { target: 'Brain Infarction', source: 'Brain Ischemia', value: '23' },
    { target: 'Hypoxia-Ischemia, Brain', source: 'Brain Ischemia', value: '23' },
    { target: 'Ischemic Attack, Transient', source: 'Brain Ischemia', value: '23' },
    { target: 'Vertebrobasilar Insufficiency', source: 'Brain Ischemia', value: '23' },
    { target: 'Brain Stem Infarctions', source: 'Brain Infarction', value: '27' },
    { target: 'Cerebral Infarction', source: 'Brain Infarction', value: '27' },
    { target: 'Lateral Medullary Syndrome', source: 'Brain Stem Infarctions', value: '31' },
    { target: 'Dementia, Multi-Infarct', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'Subclavian Steal Syndrome', source: 'Vertebrobasilar Insufficiency', value: '27' },
    { target: 'Carotid Artery Injuries', source: 'Carotid Artery Diseases', value: '23' },
    { target: 'Carotid Artery Thrombosis', source: 'Carotid Artery Diseases', value: '23' },
    { target: 'Carotid Stenosis', source: 'Carotid Artery Diseases', value: '23' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Diseases', value: '23' },
    { target: 'Moyamoya Disease', source: 'Carotid Artery Diseases', value: '23' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '27' },
    { target: 'CADASIL', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'Fabry Disease', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'MELAS Syndrome', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'Microscopic Polyangiitis', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'Stroke, Lacunar', source: 'Cerebral Small Vessel Diseases', value: '23' },
    { target: 'Carotid Artery Injuries', source: 'Cerebrovascular Trauma', value: '23' },
    { target: 'Vertebral Artery Dissection', source: 'Cerebrovascular Trauma', value: '23' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '27' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Injuries', value: '27' },
    { target: 'CADASIL', source: 'Dementia, Vascular', value: '23' },
    { target: 'Dementia, Multi-Infarct', source: 'Dementia, Vascular', value: '23' },
    { target: 'Cerebral Arterial Diseases', source: 'Intracranial Arterial Diseases', value: '23' },
    { target: 'Intracranial Aneurysm', source: 'Intracranial Arterial Diseases', value: '23' },
    { target: 'Intracranial Arteriosclerosis', source: 'Intracranial Arterial Diseases', value: '23' },
    { target: 'CADASIL', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Cerebral Amyloid Angiopathy', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Moyamoya Disease', source: 'Cerebral Arterial Diseases', value: '27' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Cerebral Amyloid Angiopathy', value: '31' },
    { target: 'Dementia, Vascular', source: 'Intracranial Arteriosclerosis', value: '27' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '23' },
    { target: 'Intracranial Embolism', source: 'Intracranial Embolism and Thrombosis', value: '23' },
    { target: 'Intracranial Thrombosis', source: 'Intracranial Embolism and Thrombosis', value: '23' },
    { target: 'Sinus Thrombosis, Intracranial', source: 'Intracranial Thrombosis', value: '27' },
    { target: 'Cavernous Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Lateral Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Sagittal Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Cerebral Hemorrhage', source: 'Intracranial Hemorrhages', value: '23' },
    { target: 'Intracranial Hemorrhage, Hypertensive', source: 'Intracranial Hemorrhages', value: '23' },
    { target: 'Intracranial Hemorrhage, Traumatic', source: 'Intracranial Hemorrhages', value: '23' },
    { target: 'Pituitary Apoplexy', source: 'Intracranial Hemorrhages', value: '23' },
    { target: 'Subarachnoid Hemorrhage', source: 'Intracranial Hemorrhages', value: '23' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Cerebral Hemorrhage', value: '27' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Cerebral Hemorrhage', value: '27' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '31' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '27' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Intracranial Hemorrhage, Traumatic', value: '27' },
    { target: 'Hematoma, Subdural', source: 'Intracranial Hemorrhage, Traumatic', value: '27' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '27' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '31' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '31' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '31' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '31' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '31' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Subarachnoid Hemorrhage', value: '27' },
    { target: 'Brain Infarction', source: 'Stroke', value: '23' },
    { target: 'Stroke, Lacunar', source: 'Stroke', value: '23' },
    { target: 'Brain Stem Infarctions', source: 'Brain Infarction', value: '27' },
    { target: 'Cerebral Infarction', source: 'Brain Infarction', value: '27' },
    { target: 'Lateral Medullary Syndrome', source: 'Brain Stem Infarctions', value: '31' },
    { target: 'Dementia, Multi-Infarct', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Infarction', value: '31' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '23' },
    { target: 'Giant Cell Arteritis', source: 'Vasculitis, Central Nervous System', value: '23' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '23' },
    { target: 'AIDS Dementia Complex', source: 'Dementia', value: '19' },
    { target: 'Alzheimer Disease', source: 'Dementia', value: '19' },
    { target: 'Aphasia, Primary Progressive', source: 'Dementia', value: '19' },
    { target: 'Creutzfeldt-Jakob Syndrome', source: 'Dementia', value: '19' },
    { target: 'Dementia, Vascular', source: 'Dementia', value: '19' },
    { target: 'Diffuse Neurofibrillary Tangles with Calcification', source: 'Dementia', value: '19' },
    { target: 'Frontotemporal Lobar Degeneration', source: 'Dementia', value: '19' },
    { target: 'Huntington Disease', source: 'Dementia', value: '19' },
    { target: 'Kluver-Bucy Syndrome', source: 'Dementia', value: '19' },
    { target: 'Lewy Body Disease', source: 'Dementia', value: '19' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Aphasia, Primary Progressive', value: '23' },
    { target: 'CADASIL', source: 'Dementia, Vascular', value: '23' },
    { target: 'Dementia, Multi-Infarct', source: 'Dementia, Vascular', value: '23' },
    { target: 'Frontotemporal Dementia', source: 'Frontotemporal Lobar Degeneration', value: '23' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Frontotemporal Lobar Degeneration', value: '23' },
    { target: 'Pick Disease of the Brain', source: 'Frontotemporal Dementia', value: '27' },
    { target: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', source: 'Encephalitis', value: '19' },
    { target: 'Cerebral Ventriculitis', source: 'Encephalitis', value: '19' },
    { target: 'Infectious Encephalitis', source: 'Encephalitis', value: '19' },
    { target: 'Limbic Encephalitis', source: 'Encephalitis', value: '19' },
    { target: 'Meningoencephalitis', source: 'Encephalitis', value: '19' },
    { target: 'Encephalitis, Viral', source: 'Infectious Encephalitis', value: '23' },
    { target: 'Encephalitis, Arbovirus', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Encephalitis, Viral', value: '27' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '31' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '31' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '31' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '31' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '31' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '31' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '31' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '31' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Meningoencephalitis', value: '23' },
    { target: 'Leukomalacia, Periventricular', source: 'Encephalomalacia', value: '19' },
    { target: 'Drug Resistant Epilepsy', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsies, Myoclonic', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsies, Partial', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsy, Benign Neonatal', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsy, Generalized', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsy, Post-Traumatic', source: 'Epilepsy', value: '19' },
    { target: 'Epilepsy, Reflex', source: 'Epilepsy', value: '19' },
    { target: 'Landau-Kleffner Syndrome', source: 'Epilepsy', value: '19' },
    { target: 'Lennox Gastaut Syndrome', source: 'Epilepsy', value: '19' },
    { target: 'Seizures', source: 'Epilepsy', value: '19' },
    { target: 'Seizures, Febrile', source: 'Epilepsy', value: '19' },
    { target: 'Status Epilepticus', source: 'Epilepsy', value: '19' },
    { target: 'Myoclonic Epilepsies, Progressive', source: 'Epilepsies, Myoclonic', value: '23' },
    { target: 'Myoclonic Epilepsy, Juvenile', source: 'Epilepsies, Myoclonic', value: '23' },
    { target: 'Lafora Disease', source: 'Myoclonic Epilepsies, Progressive', value: '27' },
    { target: 'MERRF Syndrome', source: 'Myoclonic Epilepsies, Progressive', value: '27' },
    { target: 'Unverricht-Lundborg Syndrome', source: 'Myoclonic Epilepsies, Progressive', value: '27' },
    { target: 'Epilepsy, Complex Partial', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Frontal Lobe', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Partial, Motor', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Partial, Sensory', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Rolandic', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Temporal Lobe', source: 'Epilepsies, Partial', value: '23' },
    { target: 'Epilepsy, Absence', source: 'Epilepsy, Generalized', value: '23' },
    { target: 'Epilepsy, Tonic-Clonic', source: 'Epilepsy, Generalized', value: '23' },
    { target: 'Nodding Syndrome', source: 'Epilepsy, Generalized', value: '23' },
    { target: 'Spasms, Infantile', source: 'Epilepsy, Generalized', value: '23' },
    { target: 'Epilepsia Partialis Continua', source: 'Status Epilepticus', value: '23' },
    { target: 'Headache Disorders, Primary', source: 'Headache Disorders', value: '19' },
    { target: 'Headache Disorders, Secondary', source: 'Headache Disorders', value: '19' },
    { target: 'Migraine Disorders', source: 'Headache Disorders, Primary', value: '23' },
    { target: 'Tension-Type Headache', source: 'Headache Disorders, Primary', value: '23' },
    { target: 'Trigeminal Autonomic Cephalalgias', source: 'Headache Disorders, Primary', value: '23' },
    { target: 'Alice in Wonderland Syndrome', source: 'Migraine Disorders', value: '27' },
    { target: 'Migraine with Aura', source: 'Migraine Disorders', value: '27' },
    { target: 'Migraine without Aura', source: 'Migraine Disorders', value: '27' },
    { target: 'Ophthalmoplegic Migraine', source: 'Migraine Disorders', value: '27' },
    { target: 'Cluster Headache', source: 'Trigeminal Autonomic Cephalalgias', value: '27' },
    { target: 'Paroxysmal Hemicrania', source: 'Trigeminal Autonomic Cephalalgias', value: '27' },
    { target: 'SUNCT Syndrome', source: 'Trigeminal Autonomic Cephalalgias', value: '27' },
    { target: 'Post-Dural Puncture Headache', source: 'Headache Disorders, Secondary', value: '23' },
    { target: 'Post-Traumatic Headache', source: 'Headache Disorders, Secondary', value: '23' },
    { target: 'Vascular Headaches', source: 'Headache Disorders, Secondary', value: '23' },
    { target: 'Dandy-Walker Syndrome', source: 'Hydrocephalus', value: '19' },
    { target: 'Hydrocephalus, Normal Pressure', source: 'Hydrocephalus', value: '19' },
    { target: 'Bardet-Biedl Syndrome', source: 'Hypothalamic Diseases', value: '19' },
    { target: 'Hypothalamic Neoplasms', source: 'Hypothalamic Diseases', value: '19' },
    { target: 'Laurence-Moon Syndrome', source: 'Hypothalamic Diseases', value: '19' },
    { target: 'Pituitary Diseases', source: 'Hypothalamic Diseases', value: '19' },
    { target: 'Pallister-Hall Syndrome', source: 'Hypothalamic Neoplasms', value: '23' },
    { target: 'Pituitary Neoplasms', source: 'Hypothalamic Neoplasms', value: '23' },
    { target: 'Empty Sella Syndrome', source: 'Pituitary Diseases', value: '23' },
    { target: 'Hyperpituitarism', source: 'Pituitary Diseases', value: '23' },
    { target: 'Hypophysitis', source: 'Pituitary Diseases', value: '23' },
    { target: 'Hypopituitarism', source: 'Pituitary Diseases', value: '23' },
    { target: 'Inappropriate ADH Syndrome', source: 'Pituitary Diseases', value: '23' },
    { target: 'Pituitary Apoplexy', source: 'Pituitary Diseases', value: '23' },
    { target: 'Pituitary Neoplasms', source: 'Pituitary Diseases', value: '23' },
    { target: 'Acromegaly', source: 'Hyperpituitarism', value: '27' },
    { target: 'Hyperprolactinemia', source: 'Hyperpituitarism', value: '27' },
    { target: 'Pituitary ACTH Hypersecretion', source: 'Hyperpituitarism', value: '27' },
    { target: 'Autoimmune Hypophysitis', source: 'Hypophysitis', value: '27' },
    { target: 'Dwarfism, Pituitary', source: 'Hypopituitarism', value: '27' },
    { target: 'ACTH-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '27' },
    { target: 'Growth Hormone-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '27' },
    { target: 'Prolactinoma', source: 'Pituitary Neoplasms', value: '27' },
    { target: 'Nelson Syndrome', source: 'ACTH-Secreting Pituitary Adenoma', value: '31' },
    { target: 'Hypoxia-Ischemia, Brain', source: 'Hypoxia, Brain', value: '19' },
    { target: 'Hypertensive Encephalopathy', source: 'Intracranial Hypertension', value: '19' },
    { target: 'Pseudotumor Cerebri', source: 'Intracranial Hypertension', value: '19' },
    { target: 'Posterior Leukoencephalopathy Syndrome', source: 'Hypertensive Encephalopathy', value: '23' },
    { target: 'Dementia, Vascular', source: 'Leukoencephalopathies', value: '19' },
    { target: 'Demyelinating Autoimmune Diseases, CNS', source: 'Leukoencephalopathies', value: '19' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Leukoencephalopathies', value: '19' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Leukoencephalopathies', value: '19' },
    { target: 'Posterior Leukoencephalopathy Syndrome', source: 'Leukoencephalopathies', value: '19' },
    { target: 'Diffuse Cerebral Sclerosis of Schilder', source: 'Demyelinating Autoimmune Diseases, CNS', value: '23' },
    { target: 'Encephalomyelitis, Acute Disseminated', source: 'Demyelinating Autoimmune Diseases, CNS', value: '23' },
    { target: 'Encephalomyelitis, Autoimmune, Experimental', source: 'Demyelinating Autoimmune Diseases, CNS', value: '23' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Demyelinating Autoimmune Diseases, CNS', value: '23' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Encephalomyelitis, Acute Disseminated', value: '27' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Pantothenate Kinase-Associated Neurodegeneration', source: 'Neuroaxonal Dystrophies', value: '19' },
    { target: 'Brain Abscess', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Central Nervous System Bacterial Infections', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Central Nervous System Fungal Infections', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Central Nervous System Parasitic Infections', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Central Nervous System Viral Diseases', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Encephalomyelitis', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Epidural Abscess', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Infectious Encephalitis', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Meningoencephalitis', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Myelitis', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Perimeningeal Infections', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Prion Diseases', source: 'Central Nervous System Infections', value: '15' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Brain Abscess', value: '19' },
    { target: 'Lyme Neuroborreliosis', source: 'Central Nervous System Bacterial Infections', value: '19' },
    { target: 'Meningitis, Bacterial', source: 'Central Nervous System Bacterial Infections', value: '19' },
    { target: 'Neurosyphilis', source: 'Central Nervous System Bacterial Infections', value: '19' },
    { target: 'Tuberculosis, Central Nervous System', source: 'Central Nervous System Bacterial Infections', value: '19' },
    { target: 'Meningitis, Escherichia coli', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Meningitis, Haemophilus', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Meningitis, Listeria', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Meningitis, Meningococcal', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Meningitis, Pneumococcal', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Tuberculosis, Meningeal', source: 'Meningitis, Bacterial', value: '23' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Meningitis, Meningococcal', value: '27' },
    { target: 'Tabes Dorsalis', source: 'Neurosyphilis', value: '23' },
    { target: 'Tuberculoma, Intracranial', source: 'Tuberculosis, Central Nervous System', value: '23' },
    { target: 'Tuberculosis, Meningeal', source: 'Tuberculosis, Central Nervous System', value: '23' },
    { target: 'Meningitis, Fungal', source: 'Central Nervous System Fungal Infections', value: '19' },
    { target: 'Neuroaspergillosis', source: 'Central Nervous System Fungal Infections', value: '19' },
    { target: 'Meningitis, Cryptococcal', source: 'Meningitis, Fungal', value: '23' },
    { target: 'Central Nervous System Helminthiasis', source: 'Central Nervous System Parasiti Infections', value: '19' },
    { target: 'Central Nervous System Protozoal Infections', source: 'Central Nervous System Parasitic Infections', value: '19' },
    { target: 'Neurocysticercosis', source: 'Central Nervous System Helminthiasis', value: '23' },
    { target: 'Neuroschistosomiasis', source: 'Central Nervous System Helminthiasis', value: '23' },
    { target: 'Malaria, Cerebral', source: 'Central Nervous System Protozoal Infections', value: '23' },
    { target: 'Toxoplasmosis, Cerebral', source: 'Central Nervous System Protozoal Infections', value: '23' },
    { target: 'Toxoplasmosis, Congenital', source: 'Central Nervous System Protozoal Infections', value: '23' },
    { target: 'Cerebral Ventriculitis', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Encephalitis, Viral', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Meningitis, Viral', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Meningoencephalitis', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Limbic Encephalitis', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Pseudorabies', source: 'Central Nervous System Viral Diseases', value: '19' },
    { target: 'Encephalitis, Arbovirus', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Lymphocytic Choriomeningitis', source: 'Meningitis, Viral', value: '23' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Meningoencephalitis', value: '23' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalomyelitis', value: '19' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '23' },
    { target: 'Encephalitis, Viral', source: 'Infectious Encephalitis', value: '19' },
    { target: 'Encephalitis, Arbovirus', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, Herpes Simplex', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, Varicella Zoster', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Subacute Sclerosing Panencephalitis', source: 'Encephalitis, Viral', value: '23' },
    { target: 'Encephalitis, California', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, Japanese', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, St. Louis', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalitis, Tick-Borne', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'West Nile Fever', source: 'Encephalitis, Arbovirus', value: '27' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '27' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Meningoencephalitis', value: '19' },
    { target: 'Myelitis, Transverse', source: 'Myelitis', value: '19' },
    { target: 'Paraparesis, Tropical Spastic', source: 'Myelitis', value: '19' },
    { target: 'Poliomyelitis', source: 'Myelitis', value: '19' },
    { target: 'Poliomyelitis, Bulbar', source: 'Poliomyelitis', value: '23' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Poliomyelitis', value: '23' },
    { target: 'Empyema, Subdural', source: 'Perimeningeal Infections', value: '19' },
    { target: 'Epidural Abscess', source: 'Perimeningeal Infections', value: '19' },
    { target: 'Subdural Effusion', source: 'Perimeningeal Infections', value: '19' },
    { target: 'Creutzfeldt-Jakob Syndrome', source: 'Prion Diseases', value: '19' },
    { target: 'Encephalopathy, Bovine Spongiform', source: 'Prion Diseases', value: '19' },
    { target: 'Gerstmann-Straussler-Scheinker Disease', source: 'Prion Diseases', value: '19' },
    { target: 'Insomnia, Fatal Familial', source: 'Prion Diseases', value: '19' },
    { target: 'Kuru', source: 'Prion Diseases', value: '19' },
    { target: 'Scrapie', source: 'Prion Diseases', value: '19' },
    { target: 'Wasting Disease, Chronic', source: 'Prion Diseases', value: '19' },
    { target: 'Encephalomyelitis, Equine', source: 'Encephalomyelitis', value: '15' },
    { target: 'Fatigue Syndrome, Chronic', source: 'Encephalomyelitis', value: '15' },
    { target: 'Encephalomyelitis, Eastern Equine', source: 'Encephalomyelitis, Equine', value: '19' },
    { target: 'Encephalomyelitis, Venezuelan Equine', source: 'Encephalomyelitis, Equine', value: '19' },
    { target: 'Encephalomyelitis, Western Equine', source: 'Encephalomyelitis, Equine', value: '19' },
    { target: 'Arachnoiditis', source: 'Meningitis', value: '15' },
    { target: 'Meningitis, Aseptic', source: 'Meningitis', value: '15' },
    { target: 'Meningitis, Bacterial', source: 'Meningitis', value: '15' },
    { target: 'Meningitis, Fungal', source: 'Meningitis', value: '15' },
    { target: 'Meningitis, Viral', source: 'Meningitis', value: '15' },
    { target: 'Meningoencephalitis', source: 'Meningitis', value: '15' },
    { target: 'Meningitis, Escherichia coli', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Haemophilus', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Listeria', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Meningococcal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Meningitis, Pneumococcal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Tuberculosis, Meningeal', source: 'Meningitis, Bacterial', value: '19' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Meningitis, Meningococcal', value: '23' },
    { target: 'Meningitis, Cryptococcal', source: 'Meningitis, Fungal', value: '19' },
    { target: 'Lymphocytic Choriomeningitis', source: 'Meningitis, Viral', value: '19' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Meningoencephalitis', value: '19' },
    { target: 'Akathisia, Drug-Induced', source: 'Movement Disorders', value: '15' },
    { target: 'Angelman Syndrome', source: 'Movement Disorders', value: '15' },
    { target: 'Dyskinesias', source: 'Movement Disorders', value: '15' },
    { target: 'Dystonic Disorders', source: 'Movement Disorders', value: '15' },
    { target: 'Essential Tremor', source: 'Movement Disorders', value: '15' },
    { target: 'Hepatolenticular Degeneration', source: 'Movement Disorders', value: '15' },
    { target: 'Multiple System Atrophy', source: 'Movement Disorders', value: '15' },
    { target: 'Pantothenate Kinase-Associated Neurodegeneration', source: 'Movement Disorders', value: '15' },
    { target: 'Parkinsonian Disorders', source: 'Movement Disorders', value: '15' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Movement Disorders', value: '15' },
    { target: 'Tic Disorders', source: 'Movement Disorders', value: '15' },
    { target: 'Chorea', source: 'Dyskinesias', value: '19' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Dyskinesias', value: '19' },
    { target: 'Chorea Gravidarum', source: 'Chorea', value: '23' },
    { target: 'Huntington Disease', source: 'Chorea', value: '23' },
    { target: 'Neuroacanthocytosis', source: 'Chorea', value: '23' },
    { target: 'Tardive Dyskinesia', source: 'Dyskinesia, Drug-Induced', value: '23' },
    { target: 'Dystonia Musculorum Deformans', source: 'Dystonic Disorders', value: '19' },
    { target: 'Meige Syndrome', source: 'Dystonic Disorders', value: '19' },
    { target: 'Torticollis', source: 'Dystonic Disorders', value: '19' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Shy-Drager Syndrome', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Striatonigral Degeneration', source: 'Multiple System Atrophy', value: '19' },
    { target: 'Lewy Body Disease', source: 'Parkinsonian Disorders', value: '19' },
    { target: 'Parkinson Disease', source: 'Parkinsonian Disorders', value: '19' },
    { target: 'Parkinson Disease, Secondary', source: 'Parkinsonian Disorders', value: '19' },
    { target: 'MPTP Poisoning', source: 'Parkinson Disease, Secondary', value: '23' },
    { target: 'Parkinson Disease, Postencephalitic', source: 'Parkinson Disease, Secondary', value: '23' },
    { target: 'Tourette Syndrome', source: 'Tic Disorders', value: '19' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Amyotrophic Lateral Sclerosis', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Epidural Abscess', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Muscular Atrophy, Spinal', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Myelitis', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Pneumorrhachis', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Spinal Cord Compression', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Spinal Cord Neoplasms', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Spinal Cord Injuries', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Spinal Cord Vascular Diseases', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Spinocerebellar Degenerations', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Stiff-Person Syndrome', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Subacute Combined Degeneration', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Syringomyelia', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Tabes Dorsalis', source: 'Spinal Cord Diseases', value: '15' },
    { target: 'Bulbo-Spinal Atrophy, X-Linked', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Spinal Muscular Atrophies of Childhood', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Myelitis, Transverse', source: 'Myelitis', value: '19' },
    { target: 'Paraparesis, Tropical Spastic', source: 'Myelitis', value: '19' },
    { target: 'Poliomyelitis', source: 'Myelitis', value: '19' },
    { target: 'Poliomyelitis, Bulbar', source: 'Poliomyelitis', value: '23' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Poliomyelitis', value: '23' },
    { target: 'Epidural Neoplasms', source: 'Spinal Cord Neoplasms', value: '19' },
    { target: 'Central Cord Syndrome', source: 'Spinal Cord Injuries', value: '19' },
    { target: 'Spinal Cord Ischemia', source: 'Spinal Cord Vascular Diseases', value: '19' },
    { target: 'Anterior Spinal Artery Syndrome', source: 'Spinal Cord Ischemia', value: '23' },
    { target: 'Friedreich Ataxia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Myoclonic Cerebellar Dyssynergia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Spinocerebellar Ataxias', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '23' },
    { target: 'Jet Lag Syndrome', source: 'Chronobiology Disorders', value: '11' },
    { target: 'Sleep Disorders, Circadian Rhythm', source: 'Chronobiology Disorders', value: '11' },
    { target: 'Smith-Magenis Syndrome', source: 'Chronobiology Disorders', value: '11' },
    { target: 'Abducens Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Accessory Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Cranial Nerve Neoplasms', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Cranial Nerve Injuries', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Facial Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Glossopharyngeal Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Hypoglossal Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Ocular Motility Disorders', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Olfactory Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Ophthalmoplegic Migraine', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Optic Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Trochlear Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Vagus Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Vestibulocochlear Nerve Diseases', source: 'Cranial Nerve Diseases', value: '11' },
    { target: 'Abducens Nerve Injury', source: 'Abducens Nerve Diseases', value: '15' },
    { target: 'Accessory Nerve Injuries', source: 'Accessory Nerve Diseases', value: '15' },
    { target: 'Neuroma, Acoustic', source: 'Cranial Nerve Neoplasms', value: '15' },
    { target: 'Optic Nerve Neoplasms', source: 'Cranial Nerve Neoplasms', value: '15' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '19' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '19' },
    { target: 'Abducens Nerve Injury', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Accessory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Facial Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Glossopharyngeal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Hypoglossal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Oculomotor Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Olfactory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Optic Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Trigeminal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Trochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Vagus Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Vestibulocochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '15' },
    { target: 'Lingual Nerve Injuries', source: 'Trigeminal Nerve Injuries', value: '19' },
    { target: 'Laryngeal Nerve Injuries', source: 'Vagus Nerve Injuries', value: '19' },
    { target: 'Recurrent Laryngeal Nerve Injuries', source: 'Laryngeal Nerve Injuries', value: '23' },
    { target: 'Bell Palsy', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Hemiatrophy', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Nerve Injuries', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Facial Neuralgia', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Herpes Zoster Oticus', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Melkersson-Rosenthal Syndrome', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Mobius Syndrome', source: 'Facial Nerve Diseases', value: '15' },
    { target: 'Trigeminal Nerve Diseases', source: 'Facial Neuralgia', value: '19' },
    { target: 'Trigeminal Nerve Injuries', source: 'Trigeminal Nerve Diseases', value: '23' },
    { target: 'Trigeminal Neuralgia', source: 'Trigeminal Nerve Diseases', value: '23' },
    { target: 'Lingual Nerve Injuries', source: 'Trigeminal Nerve Injuries', value: '27' },
    { target: 'Glossopharyngeal Nerve Injuries', source: 'Glossopharyngeal Nerve Diseases', value: '15' },
    { target: 'Hypoglossal Nerve Injuries', source: 'Hypoglossal Nerve Diseases', value: '15' },
    { target: 'Duane Retraction Syndrome', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Miller Fisher Syndrome', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Nystagmus, Pathologic', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Oculomotor Nerve Diseases', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Ophthalmoplegia', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Strabismus', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Tolosa-Hunt Syndrome', source: 'Ocular Motility Disorders', value: '15' },
    { target: 'Nystagmus, Congenital', source: 'Nystagmus, Pathologic', value: '19' },
    { target: 'Adie Syndrome', source: 'Oculomotor Nerve Diseases', value: '19' },
    { target: 'Oculomotor Nerve Injuries', source: 'Oculomotor Nerve Diseases', value: '19' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Ophthalmoplegic Migraine', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '23' },
    { target: 'Esotropia', source: 'Strabismus', value: '19' },
    { target: 'Exotropia', source: 'Strabismus', value: '19' },
    { target: 'Esthesioneuroblastoma, Olfactory', source: 'Olfactory Nerve Diseases', value: '15' },
    { target: 'Olfactory Nerve Injuries', source: 'Olfactory Nerve Diseases', value: '15' },
    { target: 'Optic Atrophy', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Disk Drusen', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Nerve Injuries', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Nerve Neoplasms', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Neuritis', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Neuropathy, Ischemic', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Papilledema', source: 'Optic Nerve Diseases', value: '15' },
    { target: 'Optic Atrophies, Hereditary', source: 'Optic Atrophy', value: '19' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '23' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '23' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '23' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '19' },
    { target: 'Neuromyelitis Optica', source: 'Optic Neuritis', value: '19' },
    { target: 'Vagus Nerve Injuries', source: 'Vagus Nerve Diseases', value: '15' },
    { target: 'Vocal Cord Paralysis', source: 'Vagus Nerve Diseases', value: '15' },
    { target: 'Cogan Syndrome', source: 'Vestibulocochlear Nerve Diseases', value: '15' },
    { target: 'Neuroma, Acoustic', source: 'Vestibulocochlear Nerve Diseases', value: '15' },
    { target: 'Vestibular Neuronitis', source: 'Vestibulocochlear Nerve Diseases', value: '15' },
    { target: 'Vestibulocochlear Nerve Injuries', source: 'Vestibulocochlear Nerve Diseases', value: '15' },
    { target: 'Neurofibromatosis 2', source: 'Neuroma, Acoustic', value: '19' },
    { target: 'Demyelinating Autoimmune Diseases, CNS', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Leukoencephalopathy, Progressive Multifocal', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Marchiafava-Bignami Disease', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Myelinolysis, Central Pontine', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Ophthalmoplegic Migraine', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Polyradiculoneuropathy', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Subacute Combined Degeneration', source: 'Demyelinating Diseases', value: '11' },
    { target: 'Diffuse Cerebral Sclerosis of Schilder', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Encephalomyelitis, Acute Disseminated', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Encephalomyelitis, Autoimmune, Experimental', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Multiple Sclerosis', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Myelitis, Transverse', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Neuromyelitis Optica', source: 'Demyelinating Autoimmune Diseases, CNS', value: '15' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Encephalomyelitis, Acute Disseminated', value: '19' },
    { target: 'Multiple Sclerosis, Chronic Progressive', source: 'Multiple Sclerosis', value: '19' },
    { target: 'Multiple Sclerosis, Relapsing-Remitting', source: 'Multiple Sclerosis', value: '19' },
    { target: 'Neuromyelitis Optica', source: 'Myelitis, Transverse', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '15' },
    { target: 'Guillain-Barre Syndrome', source: 'Polyradiculoneuropathy', value: '15' },
    { target: 'Polyradiculoneuropathy, Chronic Inflammatory Demyelinating', source: 'Polyradiculoneuropathy', value: '15' },
    { target: 'Miller Fisher Syndrome', source: 'Guillain-Barre Syndrome', value: '19' },
    { target: 'Agenesis of Corpus Callosum', source: 'Nervous System Malformations', value: '11' },
    { target: 'Central Nervous System Cysts', source: 'Nervous System Malformations', value: '11' },
    { target: 'Central Nervous System Vascular Malformations', source: 'Nervous System Malformations', value: '11' },
    { target: 'Dandy-Walker Syndrome', source: 'Nervous System Malformations', value: '11' },
    { target: 'Hereditary Sensory and Motor Neuropathy', source: 'Nervous System Malformations', value: '11' },
    { target: 'Hereditary Sensory and Autonomic Neuropathies', source: 'Nervous System Malformations', value: '11' },
    { target: 'Hydranencephaly', source: 'Nervous System Malformations', value: '11' },
    { target: 'Malformations of Cortical Development', source: 'Nervous System Malformations', value: '11' },
    { target: 'Neural Tube Defects', source: 'Nervous System Malformations', value: '11' },
    { target: 'Septo-Optic Dysplasia', source: 'Nervous System Malformations', value: '11' },
    { target: 'Acrocallosal Syndrome', source: 'Agenesis of Corpus Callosum', value: '15' },
    { target: 'Aicardi Syndrome', source: 'Agenesis of Corpus Callosum', value: '15' },
    { target: 'Holoprosencephaly', source: 'Agenesis of Corpus Callosum', value: '15' },
    { target: 'Arachnoid Cysts', source: 'Central Nervous System Cysts', value: '15' },
    { target: 'Colloid Cysts', source: 'Central Nervous System Cysts', value: '15' },
    { target: 'Central Nervous System Venous Angioma', source: 'Central Nervous System Vascular Malformations', value: '15' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Central Nervous System Vascular Malformations', value: '15' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Central Nervous System Vascular Malformations', value: '15' },
    { target: 'Sinus Pericranii', source: 'Central Nervous System Vascular Malformations', value: '15' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '19' },
    { target: 'Alstrom Syndrome', source: 'Hereditary Sensory and Motor Neuropathy', value: '15' },
    { target: 'Charcot-Marie-Tooth Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '15' },
    { target: 'Giant Axonal Neuropathy', source: 'Hereditary Sensory and Motor Neuropathy', value: '15' },
    { target: 'Refsum Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '15' },
    { target: 'Spastic Paraplegia, Hereditary', source: 'Hereditary Sensory and Motor Neuropathy', value: '15' },
    { target: 'Dysautonomia, Familial', source: 'Hereditary Sensory and Autonomic Neuropathies', value: '15' },
    { target: 'Malformations of Cortical Development, Group I', source: 'Malformations of Cortical Development', value: '15' },
    { target: 'Malformations of Cortical Development, Group II', source: 'Malformations of Cortical Development', value: '15' },
    { target: 'Malformations of Cortical Development, Group III', source: 'Malformations of Cortical Development', value: '15' },
    { target: 'Megalencephaly', source: 'Malformations of Cortical Development, Group I', value: '19' },
    { target: 'Microcephaly', source: 'Malformations of Cortical Development, Group I', value: '19' },
    { target: 'Tuberous Sclerosis', source: 'Malformations of Cortical Development, Group I', value: '19' },
    { target: 'Hemimegalencephaly', source: 'Megalencephaly', value: '23' },
    { target: 'Classical Lissencephalies and Subcortical Band Heterotopias', source: 'Malformations of Cortical Development, Group II', value: '19' },
    { target: 'Cobblestone Lissencephaly', source: 'Malformations of Cortical Development, Group II', value: '19' },
    { target: 'Lissencephaly', source: 'Malformations of Cortical Development, Group II', value: '19' },
    { target: 'Periventricular Nodular Heterotopia', source: 'Malformations of Cortical Development, Group II', value: '19' },
    { target: 'Classical Lissencephalies and Subcortical Band Heterotopias', source: 'Lissencephaly', value: '23' },
    { target: 'Cobblestone Lissencephaly', source: 'Lissencephaly', value: '23' },
    { target: 'Walker-Warburg Syndrome', source: 'Cobblestone Lissencephaly', value: '27' },
    { target: 'Polymicrogyria', source: 'Malformations of Cortical Development, Group III', value: '19' },
    { target: 'Porencephaly', source: 'Malformations of Cortical Development, Group III', value: '19' },
    { target: 'Schizencephaly', source: 'Malformations of Cortical Development, Group III', value: '19' },
    { target: 'Anencephaly', source: 'Neural Tube Defects', value: '15' },
    { target: 'Arnold-Chiari Malformation', source: 'Neural Tube Defects', value: '15' },
    { target: 'Encephalocele', source: 'Neural Tube Defects', value: '15' },
    { target: 'Meningocele', source: 'Neural Tube Defects', value: '15' },
    { target: 'Meningomyelocele', source: 'Neural Tube Defects', value: '15' },
    { target: 'Pentalogy of Cantrell', source: 'Neural Tube Defects', value: '15' },
    { target: 'Spinal Dysraphism', source: 'Neural Tube Defects', value: '15' },
    { target: 'Spina Bifida Cystica', source: 'Spinal Dysraphism', value: '19' },
    { target: 'Spina Bifida Occulta', source: 'Spinal Dysraphism', value: '19' },
    { target: 'Central Nervous System Neoplasms', source: 'Nervous System Neoplasms', value: '11' },
    { target: 'Cranial Nerve Neoplasms', source: 'Nervous System Neoplasms', value: '11' },
    { target: 'Peripheral Nervous System Neoplasms', source: 'Nervous System Neoplasms', value: '11' },
    { target: 'Brain Neoplasms', source: 'Central Nervous System Neoplasms', value: '15' },
    { target: 'Central Nervous System Cysts', source: 'Central Nervous System Neoplasms', value: '15' },
    { target: 'Meningeal Neoplasms', source: 'Central Nervous System Neoplasms', value: '15' },
    { target: 'Spinal Cord Neoplasms', source: 'Central Nervous System Neoplasms', value: '15' },
    { target: 'Cerebral Ventricle Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Infratentorial Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Neurocytoma', source: 'Brain Neoplasms', value: '19' },
    { target: 'Pinealoma', source: 'Brain Neoplasms', value: '19' },
    { target: 'Supratentorial Neoplasms', source: 'Brain Neoplasms', value: '19' },
    { target: 'Choroid Plexus Neoplasms', source: 'Cerebral Ventricle Neoplasms', value: '23' },
    { target: 'Papilloma, Choroid Plexus', source: 'Choroid Plexus Neoplasms', value: '27' },
    { target: 'Brain Stem Neoplasms', source: 'Infratentorial Neoplasms', value: '23' },
    { target: 'Cerebellar Neoplasms', source: 'Infratentorial Neoplasms', value: '23' },
    { target: 'Hypothalamic Neoplasms', source: 'Supratentorial Neoplasms', value: '23' },
    { target: 'Pallister-Hall Syndrome', source: 'Hypothalamic Neoplasms', value: '27' },
    { target: 'Pituitary Neoplasms', source: 'Hypothalamic Neoplasms', value: '27' },
    { target: 'Arachnoid Cysts', source: 'Central Nervous System Cysts', value: '19' },
    { target: 'Colloid Cysts', source: 'Central Nervous System Cysts', value: '19' },
    { target: 'Meningeal Carcinomatosis', source: 'Meningeal Neoplasms', value: '19' },
    { target: 'Meningioma', source: 'Meningeal Neoplasms', value: '19' },
    { target: 'Epidural Neoplasms', source: 'Spinal Cord Neoplasms', value: '19' },
    { target: 'Optic Nerve Neoplasms', source: 'Cranial Nerve Neoplasms', value: '15' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '19' },
    { target: 'Cranial Nerve Neoplasms', source: 'Peripheral Nervous System Neoplasms', value: '15' },
    { target: 'Nerve Sheath Neoplasms', source: 'Peripheral Nervous System Neoplasms', value: '15' },
    { target: 'Optic Nerve Neoplasms', source: 'Cranial Nerve Neoplasms', value: '19' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '23' },
    { target: 'Neurofibroma', source: 'Nerve Sheath Neoplasms', value: '19' },
    { target: 'Neurofibroma, Plexiform', source: 'Neurofibroma', value: '23' },
    { target: 'Neurofibrosarcoma', source: 'Neurofibroma', value: '23' },
    { target: 'Ataxia Telangiectasia', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'Neurofibromatoses', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'Nevus, Sebaceous of Jadassohn', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'Sturge-Weber Syndrome', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'Tuberous Sclerosis', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'von Hippel-Lindau Disease', source: 'Neurocutaneous Syndromes', value: '11' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '15' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '15' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Heredodegenerative Disorders, Nervous System', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Lewy Body Disease', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Motor Neuron Disease', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Multiple System Atrophy', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Paraneoplastic Syndromes, Nervous System', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Parkinson Disease', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Prion Diseases', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Subacute Combined Degeneration', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Tauopathies', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'TDP-43 Proteinopathies', source: 'Neurodegenerative Diseases', value: '11' },
    { target: 'Alexander Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Bulbo-Spinal Atrophy, X-Linked', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Canavan Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Cockayne Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Dystonia Musculorum Deformans', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Gerstmann-Straussler-Scheinker Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hepatolenticular Degeneration', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Sensory and Motor Neuropathy', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Sensory and Autonomic Neuropathies', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Huntington Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Lafora Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Myotonia Congenita', source: 'Heredodegeneraive Disorders, Nervous System', value: '15' },
    { target: 'Myotonic Dystrophy', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Neurofibromatoses', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Neuronal Ceroid-Lipofuscinoses', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Optic Atrophies, Hereditary', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Pantothenate Kinase-Associated Neurodegeneration', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Spinal Muscular Atrophies of Childhood', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Spinocerebellar Degenerations', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Tourette Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Tuberous Sclerosis', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Unverricht-Lundborg Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Alstrom Syndrome', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Charcot-Marie-Tooth Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Giant Axonal Neuropathy', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Refsum Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Spastic Paraplegia, Hereditary', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Dysautonomia, Familial', source: 'Hereditary Sensory and Autonomic Neuropathies', value: '19' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '19' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '19' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Friedreich Ataxia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Myoclonic Cerebellar Dyssynergia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Spinocerebellar Ataxias', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '23' },
    { target: 'Amyotrophic Lateral Sclerosis', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Bulbar Palsy, Progressive', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Muscular Atrophy, Spinal', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Bulbo-Spinal Atrophy, X-Linked', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Spinal Muscular Atrophies of Childhood', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Multiple System Atrophy', value: '15' },
    { target: 'Shy-Drager Syndrome', source: 'Multiple System Atrophy', value: '15' },
    { target: 'Striatonigral Degeneration', source: 'Multiple System Atrophy', value: '15' },
    { target: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Limbic Encephalitis', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Myelitis, Transverse', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Paraneoplastic Cerebellar Degeneration', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Paraneoplastic Polyneuropathy', source: 'Paraneoplastic Syndromes, Nervous System', value: '15' },
    { target: 'Encephalopathy, Bovine Spongiform', source: 'Prion Diseases', value: '15' },
    { target: 'Gerstmann-Straussler-Scheinker Disease', source: 'Prion Diseases', value: '15' },
    { target: 'Insomnia, Fatal Familial', source: 'Prion Diseases', value: '15' },
    { target: 'Kuru', source: 'Prion Diseases', value: '15' },
    { target: 'Scrapie', source: 'Prion Diseases', value: '15' },
    { target: 'Wasting Disease, Chronic', source: 'Prion Diseases', value: '15' },
    { target: 'Alzheimer Disease', source: 'Tauopathies', value: '15' },
    { target: 'Diffuse Neurofibrillary Tangles with Calcification', source: 'Tauopathies', value: '15' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Tauopathies', value: '15' },
    { target: 'Amyotrophic Lateral Sclerosis', source: 'TDP-43 Proteinopathies', value: '15' },
    { target: 'Frontotemporal Lobar Degeneration', source: 'TDP-43 Proteinopathies', value: '15' },
    { target: 'Frontotemporal Dementia', source: 'Frontotemporal Lobar Degeneration', value: '19' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Frontotemporal Lobar Degeneration', value: '19' },
    { target: 'Bilateral Vestibulopathy', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Cerebrospinal Fluid Leak', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Decerebrate State', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Dyskinesias', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Gait Disorders, Neurologic', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Meningism', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Neurobehavioral Manifestations', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Neurogenic Inflammation', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Neuromuscular Manifestations', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Pain', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Paralysis', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Paresis', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Pupil Disorders', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Reflex, Abnormal', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Seizures', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Sensation Disorders', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Susac Syndrome', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Urinary Bladder, Neurogenic', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Vertigo', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Voice Disorders', source: 'Neurologic Manifestations', value: '11' },
    { target: 'Cerebrospinal Fluid Otorrhea', source: 'Cerebrospinal Fluid Leak', value: '15' },
    { target: 'Cerebrospinal Fluid Rhinorrhea', source: 'Cerebrospinal Fluid Leak', value: '15' },
    { target: 'Ataxia', source: 'Dyskinesias', value: '15' },
    { target: 'Athetosis', source: 'Dyskinesias', value: '15' },
    { target: 'Catalepsy', source: 'Dyskinesias', value: '15' },
    { target: 'Chorea', source: 'Dyskinesias', value: '15' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Dyskinesias', value: '15' },
    { target: 'Dystonia', source: 'Dyskinesias', value: '15' },
    { target: 'Hyperkinesis', source: 'Dyskinesias', value: '15' },
    { target: 'Hypokinesia', source: 'Dyskinesias', value: '15' },
    { target: 'Myoclonus', source: 'Dyskinesias', value: '15' },
    { target: 'Psychomotor Agitation', source: 'Dyskinesias', value: '15' },
    { target: 'Synkinesis', source: 'Dyskinesias', value: '15' },
    { target: 'Tics', source: 'Dyskinesias', value: '15' },
    { target: 'Tremor', source: 'Dyskinesias', value: '15' },
    { target: 'Cerebellar Ataxia', source: 'Ataxia', value: '19' },
    { target: 'Gait Ataxia', source: 'Ataxia', value: '19' },
    { target: 'Spinocerebellar Ataxias', source: 'Cerebellar Ataxia', value: '23' },
    { target: 'Ataxia Telangiectasia', source: 'Spinocerebellar Ataxias', value: '27' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '27' },
    { target: 'Tardive Dyskinesia', source: 'Dyskinesia, Drug-Induced', value: '19' },
    { target: 'Torticollis', source: 'Dystonia', value: '19' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Myoclonus', value: '19' },
    { target: 'Gait Apraxia', source: 'Gait Disorders, Neurologic', value: '15' },
    { target: 'Gait Ataxia', source: 'Gait Disorders, Neurologic', value: '15' },
    { target: 'Anhedonia', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Catatonia', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Communication Disorders', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Confusion', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Consciousness Disorders', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Lethargy', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Memory Disorders', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Intellectual Disability', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Perceptual Disorders', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Psychomotor Disorders', source: 'Neurobehavioral Manifestations', value: '15' },
    { target: 'Language Disorders', source: 'Communication Disorders', value: '19' },
    { target: 'Learning Disorders', source: 'Communication Disorders', value: '19' },
    { target: 'Agraphia', source: 'Language Disorders', value: '23' },
    { target: 'Anomia', source: 'Language Disorders', value: '23' },
    { target: 'Dyslexia', source: 'Language Disorders', value: '23' },
    { target: 'Language Development Disorders', source: 'Language Disorders', value: '23' },
    { target: 'Speech Disorders', source: 'Language Disorders', value: '23' },
    { target: 'Dyslexia, Acquired', source: 'Dyslexia', value: '27' },
    { target: 'Alexia, Pure', source: 'Dyslexia, Acquired', value: '31' },
    { target: 'Aphasia', source: 'Speech Disorders', value: '27' },
    { target: 'Articulation Disorders', source: 'Speech Disorders', value: '27' },
    { target: 'Echolalia', source: 'Speech Disorders', value: '27' },
    { target: 'Mutism', source: 'Speech Disorders', value: '27' },
    { target: 'Stuttering', source: 'Speech Disorders', value: '27' },
    { target: 'Aphasia, Broca', source: 'Aphasia', value: '31' },
    { target: 'Aphasia, Conduction', source: 'Aphasia', value: '31' },
    { target: 'Aphasia, Primary Progressive', source: 'Aphasia', value: '31' },
    { target: 'Aphasia, Wernicke', source: 'Aphasia', value: '31' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Aphasia, Primary Progressive', value: '35' },
    { target: 'Dysarthria', source: 'Articulation Disorders', value: '31' },
    { target: 'Dyscalculia', source: 'Learning Disorders', value: '23' },
    { target: 'Dyslexia', source: 'Learning Disorders', value: '23' },
    { target: 'Dyslexia, Acquired', source: 'Dyslexia', value: '27' },
    { target: 'Delirium', source: 'Confusion', value: '19' },
    { target: 'Emergence Delirium', source: 'Delirium', value: '23' },
    { target: 'Unconsciousness', source: 'Consciousness Disorders', value: '19' },
    { target: 'Coma', source: 'Unconsciousness', value: '23' },
    { target: 'Persistent Vegetative State', source: 'Unconsciousness', value: '23' },
    { target: 'Stupor', source: 'Unconsciousness', value: '23' },
    { target: 'Syncope', source: 'Unconsciousness', value: '23' },
    { target: 'Brain Death', source: 'Coma', value: '27' },
    { target: 'Coma, Post-Head Injury', source: 'Coma', value: '27' },
    { target: 'Insulin Coma', source: 'Coma', value: '27' },
    { target: 'Syncope, Vasovagal', source: 'Syncope', value: '27' },
    { target: 'Amnesia', source: 'Memory Disorders', value: '19' },
    { target: 'Korsakoff Syndrome', source: 'Memory Disorders', value: '19' },
    { target: 'Amnesia, Anterograde', source: 'Amnesia', value: '23' },
    { target: 'Amnesia, Retrograde', source: 'Amnesia', value: '23' },
    { target: 'Amnesia, Transient Global', source: 'Amnesia', value: '23' },
    { target: 'Cri-du-Chat Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'De Lange Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'Down Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'Mental Retardation, X-Linked', source: 'Intellectual Disability', value: '19' },
    { target: 'Prader-Willi Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'WAGR Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'Williams Syndrome', source: 'Intellectual Disability', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Coffin-Lowry Syndrome', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Fragile X Syndrome', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Glycogen Storage Disease Type IIb', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Mucopolysaccharidosis II', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Rett Syndrome', source: 'Mental Retardation, X-Linked', value: '23' },
    { target: 'Agnosia', source: 'Perceptual Disorders', value: '19' },
    { target: 'Alice in Wonderland Syndrome', source: 'Perceptual Disorders', value: '19' },
    { target: 'Allesthesia', source: 'Perceptual Disorders', value: '19' },
    { target: 'Auditory Perceptual Disorders', source: 'Perceptual Disorders', value: '19' },
    { target: 'Hallucinations', source: 'Perceptual Disorders', value: '19' },
    { target: 'Illusions', source: 'Perceptual Disorders', value: '19' },
    { target: 'Phantom Limb', source: 'Perceptual Disorders', value: '19' },
    { target: 'Gerstmann Syndrome', source: 'Agnosia', value: '23' },
    { target: 'Prosopagnosia', source: 'Agnosia', value: '23' },
    { target: 'Apraxias', source: 'Psychomotor Disorders', value: '19' },
    { target: 'Psychomotor Agitation', source: 'Psychomotor Disorders', value: '19' },
    { target: 'Alien Hand Syndrome', source: 'Apraxias', value: '23' },
    { target: 'Apraxia, Ideomotor', source: 'Apraxias', value: '23' },
    { target: 'Gait Apraxia', source: 'Apraxias', value: '23' },
    { target: 'Fasciculation', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscle Cramp', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscle Hypertonia', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscle Hypotonia', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscle Weakness', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscular Atrophy', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Myokymia', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Myotonia', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Spasm', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Tetany', source: 'Neuromuscular Manifestations', value: '15' },
    { target: 'Muscle Rigidity', source: 'Muscle Hypertonia', value: '19' },
    { target: 'Muscle Spasticity', source: 'Muscle Hypertonia', value: '19' },
    { target: 'Sarcopenia', source: 'Muscular Atrophy', value: '19' },
    { target: 'Hemifacial Spasm', source: 'Spasm', value: '19' },
    { target: 'Trismus', source: 'Spasm', value: '19' },
    { target: 'Abdominal Pain', source: 'Pain', value: '15' },
    { target: 'Acute Pain', source: 'Pain', value: '15' },
    { target: 'Arthralgia', source: 'Pain', value: '15' },
    { target: 'Back Pain', source: 'Pain', value: '15' },
    { target: 'Breakthrough Pain', source: 'Pain', value: '15' },
    { target: 'Cancer Pain', source: 'Pain', value: '15' },
    { target: 'Chest Pain', source: 'Pain', value: '15' },
    { target: 'Chronic Pain', source: 'Pain', value: '15' },
    { target: 'Earache', source: 'Pain', value: '15' },
    { target: 'Eye Pain', source: 'Pain', value: '15' },
    { target: 'Facial Pain', source: 'Pain', value: '15' },
    { target: 'Flank Pain', source: 'Pain', value: '15' },
    { target: 'Glossalgia', source: 'Pain', value: '15' },
    { target: 'Headache', source: 'Pain', value: '15' },
    { target: 'Labor Pain', source: 'Pain', value: '15' },
    { target: 'Mastodynia', source: 'Pain', value: '15' },
    { target: 'Metatarsalgia', source: 'Pain', value: '15' },
    { target: 'Musculoskeletal Pain', source: 'Pain', value: '15' },
    { target: 'Neck Pain', source: 'Pain', value: '15' },
    { target: 'Neuralgia', source: 'Pain', value: '15' },
    { target: 'Nociceptive Pain', source: 'Pain', value: '15' },
    { target: 'Pain, Intractable', source: 'Pain', value: '15' },
    { target: 'Pain, Postoperative', source: 'Pain', value: '15' },
    { target: 'Pain, Referred', source: 'Pain', value: '15' },
    { target: 'Renal Colic', source: 'Pain', value: '15' },
    { target: 'Abdomen, Acute', source: 'Abdominal Pain', value: '19' },
    { target: 'Shoulder Pain', source: 'Arthralgia', value: '19' },
    { target: 'Failed Back Surgery Syndrome', source: 'Back Pain', value: '19' },
    { target: 'Low Back Pain', source: 'Back Pain', value: '19' },
    { target: 'Angina Pectoris', source: 'Chest Pain', value: '19' },
    { target: 'Angina, Unstable', source: 'Angina Pectoris', value: '23' },
    { target: 'Angina, Stable', source: 'Angina Pectoris', value: '23' },
    { target: 'Angina Pectoris, Variant', source: 'Angina, Unstable', value: '27' },
    { target: 'Toothache', source: 'Facial Pain', value: '19' },
    { target: 'Slit Ventricle Syndrome', source: 'Headache', value: '19' },
    { target: 'Morton Neuroma', source: 'Metatarsalgia', value: '19' },
    { target: 'Myalgia', source: 'Musculoskeletal Pain', value: '19' },
    { target: 'Pelvic Girdle Pain', source: 'Musculoskeletal Pain', value: '19' },
    { target: 'Morton Neuroma', source: 'Neuralgia', value: '19' },
    { target: 'Neuralgia, Postherpetic', source: 'Neuralgia', value: '19' },
    { target: 'Piriformis Muscle Syndrome', source: 'Neuralgia', value: '19' },
    { target: 'Pudendal Neuralgia', source: 'Neuralgia', value: '19' },
    { target: 'Sciatica', source: 'Neuralgia', value: '19' },
    { target: 'Visceral Pain', source: 'Nociceptive Pain', value: '19' },
    { target: 'Phantom Limb', source: 'Pain, Postoperative', value: '19' },
    { target: 'Facial Paralysis', source: 'Paralysis', value: '15' },
    { target: 'Hemiplegia', source: 'Paralysis', value: '15' },
    { target: 'Ophthalmoplegia', source: 'Paralysis', value: '15' },
    { target: 'Paraplegia', source: 'Paralysis', value: '15' },
    { target: 'Pseudobulbar Palsy', source: 'Paralysis', value: '15' },
    { target: 'Quadriplegia', source: 'Paralysis', value: '15' },
    { target: 'Respiratory Paralysis', source: 'Paralysis', value: '15' },
    { target: 'Vocal Cord Paralysis', source: 'Paralysis', value: '15' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Ophthalmoplegic Migraine', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Ophthalmoplegia', value: '19' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '23' },
    { target: 'Brown-Sequard Syndrome', source: 'Paraplegia', value: '19' },
    { target: 'Paraparesis', source: 'Paresis', value: '15' },
    { target: 'Paraparesis, Spastic', source: 'Paraparesis', value: '19' },
    { target: 'Anisocoria', source: 'Pupil Disorders', value: '15' },
    { target: 'Miosis', source: 'Pupil Disorders', value: '15' },
    { target: 'Tonic Pupil', source: 'Pupil Disorders', value: '15' },
    { target: 'Horner Syndrome', source: 'Miosis', value: '19' },
    { target: 'Alcohol Withdrawal Seizures', source: 'Seizures', value: '15' },
    { target: 'Dizziness', source: 'Sensation Disorders', value: '15' },
    { target: 'Hearing Disorders', source: 'Sensation Disorders', value: '15' },
    { target: 'Olfaction Disorders', source: 'Sensation Disorders', value: '15' },
    { target: 'Somatosensory Disorders', source: 'Sensation Disorders', value: '15' },
    { target: 'Taste Disorders', source: 'Sensation Disorders', value: '15' },
    { target: 'Vision Disorders', source: 'Sensation Disorders', value: '15' },
    { target: 'Hearing Loss', source: 'Hearing Disorders', value: '19' },
    { target: 'Hyperacusis', source: 'Hearing Disorders', value: '19' },
    { target: 'Tinnitus', source: 'Hearing Disorders', value: '19' },
    { target: 'Deafness', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Bilateral', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Conductive', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Functional', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, High-Frequency', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Mixed Conductive-Sensorineural', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Sensorineural', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Sudden', source: 'Hearing Loss', value: '23' },
    { target: 'Hearing Loss, Unilateral', source: 'Hearing Loss', value: '23' },
    { target: 'Deaf-Blind Disorders', source: 'Deafness', value: '27' },
    { target: 'Usher Syndromes', source: 'Deaf-Blind Disorders', value: '31' },
    { target: 'Wolfram Syndrome', source: 'Deaf-Blind Disorders', value: '31' },
    { target: 'Hearing Loss, Central', source: 'Hearing Loss, Sensorineural', value: '27' },
    { target: 'Hearing Loss, Noise-Induced', source: 'Hearing Loss, Sensorineural', value: '27' },
    { target: 'Presbycusis', source: 'Hearing Loss, Sensorineural', value: '27' },
    { target: 'Usher Syndromes', source: 'Hearing Loss, Sensorineural', value: '27' },
    { target: 'Hyperalgesia', source: 'Somatosensory Disorders', value: '19' },
    { target: 'Hyperesthesia', source: 'Somatosensory Disorders', value: '19' },
    { target: 'Hypesthesia', source: 'Somatosensory Disorders', value: '19' },
    { target: 'Paresthesia', source: 'Somatosensory Disorders', value: '19' },
    { target: 'Ageusia', source: 'Taste Disorders', value: '19' },
    { target: 'Dysgeusia', source: 'Taste Disorders', value: '19' },
    { target: 'Alice in Wonderland Syndrome', source: 'Vision Disorders', value: '19' },
    { target: 'Amblyopia', source: 'Vision Disorders', value: '19' },
    { target: 'Blindness', source: 'Vision Disorders', value: '19' },
    { target: 'Color Vision Defects', source: 'Vision Disorders', value: '19' },
    { target: 'Diplopia', source: 'Vision Disorders', value: '19' },
    { target: 'Hemianopsia', source: 'Vision Disorders', value: '19' },
    { target: 'Photophobia', source: 'Vision Disorders', value: '19' },
    { target: 'Scotoma', source: 'Vision Disorders', value: '19' },
    { target: 'Vision, Low', source: 'Vision Disorders', value: '19' },
    { target: 'Amaurosis Fugax', source: 'Blindness', value: '23' },
    { target: 'Blindness, Cortical', source: 'Blindness', value: '23' },
    { target: 'Deaf-Blind Disorders', source: 'Blindness', value: '23' },
    { target: 'Usher Syndromes', source: 'Deaf-Blind Disorders', value: '27' },
    { target: 'Wolfram Syndrome', source: 'Deaf-Blind Disorders', value: '27' },
    { target: 'Benign Paroxysmal Positional Vertigo', source: 'Vertigo', value: '15' },
    { target: 'Aphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Dysphonia', source: 'Voice Disorders', value: '15' },
    { target: 'Hoarseness', source: 'Voice Disorders', value: '15' },
    { target: 'Fatigue Syndrome, Chronic', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Motor Neuron Disease', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Muscular Diseases', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Neuromuscular Junction Diseases', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Peripheral Nervous System Diseases', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Poliomyelitis', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Stiff-Person Syndrome', source: 'Neuromuscular Diseases', value: '11' },
    { target: 'Amyotrophic Lateral Sclerosis', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Bulbar Palsy, Progressive', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Muscular Atrophy, Spinal', source: 'Motor Neuron Disease', value: '15' },
    { target: 'Bulbo-Spinal Atrophy, X-Linked', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Spinal Muscular Atrophies of Childhood', source: 'Muscular Atrophy, Spinal', value: '19' },
    { target: 'Medial Tibial Stress Syndrome', source: 'Muscular Diseases', value: '15' },
    { target: 'Muscular Disorders, Atrophic', source: 'Muscular Diseases', value: '15' },
    { target: 'Eosinophilia-Myalgia Syndrome', source: 'Muscular Diseases', value: '15' },
    { target: 'Fibromyalgia', source: 'Muscular Diseases', value: '15' },
    { target: 'Mitochondrial Myopathies', source: 'Muscular Diseases', value: '15' },
    { target: 'Myalgia', source: 'Muscular Diseases', value: '15' },
    { target: 'Myopathies, Structural, Congenital', source: 'Muscular Diseases', value: '15' },
    { target: 'Myositis', source: 'Muscular Diseases', value: '15' },
    { target: 'Myotonic Disorders', source: 'Muscular Diseases', value: '15' },
    { target: 'Paralyses, Familial Periodic', source: 'Muscular Diseases', value: '15' },
    { target: 'Muscular Dystrophies', source: 'Muscular Disorders, Atrophic', value: '19' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Muscular Disorders, Atrophic', value: '19' },
    { target: 'Distal Myopathies', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Glycogen Storage Disease Type VII', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Muscular Dystrophies, Limb-Girdle', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Muscular Dystrophy, Duchenne', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Muscular Dystrophy, Emery-Dreifuss', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Muscular Dystrophy, Facioscapulohumeral', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Muscular Dystrophy, Oculopharyngeal', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Myotonic Dystrophy', source: 'Muscular Dystrophies', value: '23' },
    { target: 'Sarcoglycanopathies', source: 'Muscular Dystrophies, Limb-Girdle', value: '27' },
    { target: 'Mitochondrial Encephalomyopathies', source: 'Mitochondrial Myopathies', value: '19' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Mitochondrial Myopathies', value: '19' },
    { target: 'MELAS Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '23' },
    { target: 'MERRF Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '23' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '23' },
    { target: 'Myopathies, Nemaline', source: 'Myopathies, Structural, Congenital', value: '19' },
    { target: 'Myopathy, Central Core', source: 'Myopathies, Structural, Congenital', value: '19' },
    { target: 'Myositis, Inclusion Body', source: 'Myositis', value: '19' },
    { target: 'Orbital Myositis', source: 'Myositis', value: '19' },
    { target: 'Polymyositis', source: 'Myositis', value: '19' },
    { target: 'Pyomyositis', source: 'Myositis', value: '19' },
    { target: 'Dermatomyositis', source: 'Polymyositis', value: '23' },
    { target: 'Myotonia Congenita', source: 'Myotonic Disorders', value: '19' },
    { target: 'Myotonic Dystrophy', source: 'Myotonic Disorders', value: '19' },
    { target: 'Hypokalemic Periodic Paralysis', source: 'Paralyses, Familial Periodic', value: '19' },
    { target: 'Paralysis, Hyperkalemic Periodic', source: 'Paralyses, Familial Periodic', value: '19' },
    { target: 'Botulism', source: 'Neuromuscular Junction Diseases', value: '15' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Neuromuscular Junction Diseases', value: '15' },
    { target: 'Myasthenia Gravis', source: 'Neuromuscular Junction Diseases', value: '15' },
    { target: 'Myasthenic Syndromes, Congenital', source: 'Neuromuscular Junction Diseases', value: '15' },
    { target: 'Myasthenia Gravis, Autoimmune, Experimental', source: 'Myasthenia Gravis', value: '19' },
    { target: 'Myasthenia Gravis, Neonatal', source: 'Myasthenia Gravis', value: '19' },
    { target: 'Acrodynia', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Amyloid Neuropathies', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Brachial Plexus Neuropathies', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Complex Regional Pain Syndromes', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Diabetic Neuropathies', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Giant Axonal Neuropathy', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Guillain-Barre Syndrome', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Hand-Arm Vibration Syndrome', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Isaacs Syndrome', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Mononeuropathies', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Nerve Compression Syndromes', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Neuralgia', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Neuritis', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Neurofibromatosis 1', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Pain Insensitivity, Congenital', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Peripheral Nerve Injuries', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Peripheral Nervous System Neoplasms', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Polyneuropathies', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Radiculopathy', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Small Fiber Neuropathy', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Tarlov Cysts', source: 'Peripheral Nervous System Diseases', value: '15' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Amyloid Neuropathies', value: '19' },
    { target: 'Brachial Plexus Neuritis', source: 'Brachial Plexus Neuropathies', value: '19' },
    { target: 'Causalgia', source: 'Complex Regional Pain Syndromes', value: '19' },
    { target: 'Reflex Sympathetic Dystrophy', source: 'Complex Regional Pain Syndromes', value: '19' },
    { target: 'Miller Fisher Syndrome', source: 'Guillain-Barre Syndrome', value: '19' },
    { target: 'Femoral Neuropathy', source: 'Mononeuropathies', value: '19' },
    { target: 'Median Neuropathy', source: 'Mononeuropathies', value: '19' },
    { target: 'Peroneal Neuropathies', source: 'Mononeuropathies', value: '19' },
    { target: 'Radial Neuropathy', source: 'Mononeuropathies', value: '19' },
    { target: 'Sciatic Neuropathy', source: 'Mononeuropathies', value: '19' },
    { target: 'Tibial Neuropathy', source: 'Mononeuropathies', value: '19' },
    { target: 'Ulnar Neuropathies', source: 'Mononeuropathies', value: '19' },
    { target: 'Carpal Tunnel Syndrome', source: 'Median Neuropathy', value: '23' },
    { target: 'Piriformis Muscle Syndrome', source: 'Sciatic Neuropathy', value: '23' },
    { target: 'Sciatica', source: 'Sciatic Neuropathy', value: '23' },
    { target: 'Tarsal Tunnel Syndrome', source: 'Tibial Neuropathy', value: '23' },
    { target: 'Cubital Tunnel Syndrome', source: 'Ulnar Neuropathies', value: '23' },
    { target: 'Ulnar Nerve Compression Syndromes', source: 'Ulnar Neuropathies', value: '23' },
    { target: 'Carpal Tunnel Syndrome', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Piriformis Muscle Syndrome', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Pudendal Neuralgia', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Tarsal Tunnel Syndrome', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Thoracic Outlet Syndrome', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Ulnar Nerve Compression Syndromes', source: 'Nerve Compression Syndromes', value: '19' },
    { target: 'Cervical Rib Syndrome', source: 'Thoracic Outlet Syndrome', value: '23' },
    { target: 'Cubital Tunnel Syndrome', source: 'Ulnar Nerve Compression Syndromes', value: '23' },
    { target: 'Causalgia', source: 'Neuralgia', value: '19' },
    { target: 'Morton Neuroma', source: 'Neuralgia', value: '19' },
    { target: 'Neuralgia, Postherpetic', source: 'Neuralgia', value: '19' },
    { target: 'Piriformis Muscle Syndrome', source: 'Neuralgia', value: '19' },
    { target: 'Pudendal Neuralgia', source: 'Neuralgia', value: '19' },
    { target: 'Sciatica', source: 'Neuralgia', value: '19' },
    { target: 'Brachial Plexus Neuritis', source: 'Neuritis', value: '19' },
    { target: 'Neuritis, Autoimmune, Experimental', source: 'Neuritis', value: '19' },
    { target: 'Nerve Sheath Neoplasms', source: 'Peripheral Nervous System Neoplasms', value: '19' },
    { target: 'Neurofibroma', source: 'Nerve Sheath Neoplasms', value: '23' },
    { target: 'Neurofibroma, Plexiform', source: 'Neurofibroma', value: '27' },
    { target: 'Neurofibrosarcoma', source: 'Neurofibroma', value: '27' },
    { target: 'Alcoholic Neuropathy', source: 'Polyneuropathies', value: '19' },
    { target: 'Hereditary Sensory and Autonomic Neuropathies', source: 'Polyneuropathies', value: '19' },
    { target: 'Hereditary Sensory and Motor Neuropathy', source: 'Polyneuropathies', value: '19' },
    { target: 'Paraneoplastic Polyneuropathy', source: 'Polyneuropathies', value: '19' },
    { target: 'POEMS Syndrome', source: 'Polyneuropathies', value: '19' },
    { target: 'Polyradiculoneuropathy', source: 'Polyneuropathies', value: '19' },
    { target: 'Tangier Disease', source: 'Polyneuropathies', value: '19' },
    { target: 'Dysautonomia, Familial', source: 'Hereditary Sensory and Autonomic Neuropathies', value: '23' },
    { target: 'Alstrom Syndrome', source: 'Hereditary Sensory and Motor Neuropathy', value: '23' },
    { target: 'Charcot-Marie-Tooth Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '23' },
    { target: 'Giant Axonal Neuropathy', source: 'Hereditary Sensory and Motor Neuropathy', value: '23' },
    { target: 'Refsum Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '23' },
    { target: 'Spastic Paraplegia, Hereditary', source: 'Hereditary Sensory and Motor Neuropathy', value: '23' },
    { target: 'Guillain-Barre Syndrome', source: 'Polyradiculoneuropathy', value: '23' },
    { target: 'Polyradiculoneuropathy, Chronic Inflammatory Demyelinating', source: 'Polyradiculoneuropathy', value: '23' },
    { target: 'Polyradiculopathy', source: 'Polyradiculoneuropathy', value: '23' },
    { target: 'Miller Fisher Syndrome', source: 'Guillain-Barre Syndrome', value: '27' },
    { target: 'Poliomyelitis, Bulbar', source: 'Poliomyelitis', value: '15' },
    { target: 'Postpoliomyelitis Syndrome', source: 'Poliomyelitis', value: '15' },
    { target: 'Akathisia, Drug-Induced', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Alcohol-Induced Disorders, Nervous System', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Botulism', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Heavy Metal Poisoning, Nervous System', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'MPTP Poisoning', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Neuroleptic Malignant Syndrome', source: 'Neurotoxicity Syndromes', value: '11' },
    { target: 'Alcohol Amnestic Disorder', source: 'Alcohol-Induced Disorders, Nervous System', value: '15' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Alcohol-Induced Disorders, Nervous System', value: '15' },
    { target: 'Alcohol Withdrawal Seizures', source: 'Alcohol-Induced Disorders, Nervous System', value: '15' },
    { target: 'Alcoholic Neuropathy', source: 'Alcohol-Induced Disorders, Nervous System', value: '15' },
    { target: 'Korsakoff Syndrome', source: 'Alcohol Amnestic Disorder', value: '19' },
    { target: 'Arsenic Poisoning', source: 'Heavy Metal Poisoning, Nervous System', value: '15' },
    { target: 'Lead Poisoning, Nervous System', source: 'Heavy Metal Poisoning, Nervous System', value: '15' },
    { target: 'Manganese Poisoning', source: 'Heavy Metal Poisoning, Nervous System', value: '15' },
    { target: 'Mercury Poisoning, Nervous System', source: 'Heavy Metal Poisoning, Nervous System', value: '15' },
    { target: 'Lead Poisoning, Nervous System, Adult', source: 'Lead Poisoning, Nervous System', value: '19' },
    { target: 'Lead Poisoning, Nervous System, Childhood', source: 'Lead Poisoning, Nervous System', value: '19' },
    { target: 'Acrodynia', source: 'Mercury Poisoning, Nervous System', value: '19' },
    { target: 'Dyssomnias', source: 'Sleep Wake Disorders', value: '11' },
    { target: 'Parasomnias', source: 'Sleep Wake Disorders', value: '11' },
    { target: 'Sleep Deprivation', source: 'Dyssomnias', value: '15' },
    { target: 'Sleep Disorders, Circadian Rhythm', source: 'Dyssomnias', value: '15' },
    { target: 'Sleep Disorders, Intrinsic', source: 'Dyssomnias', value: '15' },
    { target: 'Jet Lag Syndrome', source: 'Sleep Disorders, Circadian Rhythm', value: '19' },
    { target: 'Disorders of Excessive Somnolence', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Nocturnal Myoclonus Syndrome', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Restless Legs Syndrome', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Sleep Apnea Syndromes', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Sleep Initiation and Maintenance Disorders', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Hypersomnolence, Idiopathic', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Kleine-Levin Syndrome', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Narcolepsy', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Cataplexy', source: 'Narcolepsy', value: '27' },
    { target: 'Sleep Apnea, Central', source: 'Sleep Apnea Syndromes', value: '23' },
    { target: 'Sleep Apnea, Obstructive', source: 'Sleep Apnea Syndromes', value: '23' },
    { target: 'Obesity Hypoventilation Syndrome', source: 'Sleep Apnea, Obstructive', value: '27' },
    { target: 'Insomnia, Fatal Familial', source: 'Sleep Initiation and Maintenance Disorders', value: '23' },
    { target: 'Nocturnal Myoclonus Syndrome', source: 'Parasomnias', value: '15' },
    { target: 'Nocturnal Paroxysmal Dystonia', source: 'Parasomnias', value: '15' },
    { target: 'REM Sleep Parasomnias', source: 'Parasomnias', value: '15' },
    { target: 'Restless Legs Syndrome', source: 'Parasomnias', value: '15' },
    { target: 'Sleep Arousal Disorders', source: 'Parasomnias', value: '15' },
    { target: 'Sleep Bruxism', source: 'Parasomnias', value: '15' },
    { target: 'Sleep-Wake Transition Disorders', source: 'Parasomnias', value: '15' },
    { target: 'REM Sleep Behavior Disorder', source: 'REM Sleep Parasomnias', value: '19' },
    { target: 'Sleep Paralysis', source: 'REM Sleep Parasomnias', value: '19' },
    { target: 'Night Terrors', source: 'Sleep Arousal Disorders', value: '19' },
    { target: 'Somnambulism', source: 'Sleep Arousal Disorders', value: '19' },
    { target: 'Cerebrovascular Trauma', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Craniocerebral Trauma', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Peripheral Nerve Injuries', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Spinal Cord Injuries', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Carotid Artery Injuries', source: 'Cerebrovascular Trauma', value: '15' },
    { target: 'Vertebral Artery Dissection', source: 'Cerebrovascular Trauma', value: '15' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '19' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Injuries', value: '19' },
    { target: 'Brain Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Cerebrospinal Fluid Leak', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Coma, Post-Head Injury', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Cranial Nerve Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Facial Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Head Injuries, Closed', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Head Injuries, Penetrating', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Intracranial Hemorrhage, Traumatic', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Skull Fractures', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Diffuse', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injury, Chronic', source: 'Brain Injuries', value: '19' },
    { target: 'Epilepsy, Post-Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Pneumocephalus', source: 'Brain Injuries', value: '19' },
    { target: 'Shaken Baby Syndrome', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Diffuse Axonal Injury', source: 'Brain Injuries, Diffuse', value: '23' },
    { target: 'Brain Concussion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Brain Contusion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injury, Chronic', value: '23' },
    { target: 'Cerebrospinal Fluid Otorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Cerebrospinal Fluid Rhinorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Abducens Nerve Injury', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Accessory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Facial Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Glossopharyngeal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Hypoglossal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Oculomotor Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Olfactory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Optic Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Trigeminal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Trochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Vagus Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Vestibulocochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Lingual Nerve Injuries', source: 'Trigeminal Nerve Injuries', value: '23' },
    { target: 'Laryngeal Nerve Injuries', source: 'Vagus Nerve Injuries', value: '23' },
    { target: 'Recurrent Laryngeal Nerve Injuries', source: 'Laryngeal Nerve Injuries', value: '27' },
    { target: 'Eye Injuries', source: 'Facial Injuries', value: '19' },
    { target: 'Maxillofacial Injuries', source: 'Facial Injuries', value: '19' },
    { target: 'Corneal Injuries', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Burns', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Foreign Bodies', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Injuries, Penetrating', source: 'Eye Injuries', value: '23' },
    { target: 'Corneal Perforation', source: 'Corneal Injuries', value: '27' },
    { target: 'Jaw Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Mandibular Injuries', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Orbital Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Zygomatic Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Mandibular Fractures', source: 'Jaw Fractures', value: '27' },
    { target: 'Maxillary Fractures', source: 'Jaw Fractures', value: '27' },
    { target: 'Brain Concussion', source: 'Head Injuries, Closed', value: '19' },
    { target: 'Contrecoup Injury', source: 'Brain Concussion', value: '23' },
    { target: 'Post-Concussion Syndrome', source: 'Brain Concussion', value: '23' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Hematoma, Subdural', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Skull Fracture, Basilar', source: 'Skull Fractures', value: '19' },
    { target: 'Skull Fracture, Depressed', source: 'Skull Fractures', value: '19' },
    { target: 'Autonomic Dysreflexia', source: 'Spinal Cord Injuries', value: '15' },
    { target: 'Central Cord Syndrome', source: 'Spinal Cord Injuries', value: '15' },
    { target: 'Conjunctival Neoplasms', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Conjunctivitis', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Pemphigoid, Benign Mucous Membrane', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Pinguecula', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Pterygium', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Xerophthalmia', source: 'Conjunctival Diseases', value: '11' },
    { target: 'Conjunctivitis, Allergic', source: 'Conjunctivitis', value: '15' },
    { target: 'Conjunctivitis, Bacterial', source: 'Conjunctivitis', value: '15' },
    { target: 'Conjunctivitis, Viral', source: 'Conjunctivitis', value: '15' },
    { target: 'Keratoconjunctivitis', source: 'Conjunctivitis', value: '15' },
    { target: 'Conjunctivitis, Inclusion', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Ophthalmia Neonatorum', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Trachoma', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Conjunctivitis, Acute Hemorrhagic', source: 'Conjunctivitis, Viral', value: '19' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Keratoconjunctivitis', value: '19' },
    { target: 'Keratoconjunctivitis Sicca', source: 'Keratoconjunctivitis', value: '19' },
    { target: 'Corneal Dystrophies, Hereditary', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Edema', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Endothelial Cell Loss', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Injuries', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Neovascularization', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Opacity', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Wavefront Aberration', source: 'Corneal Diseases', value: '11' },
    { target: 'Iridocorneal Endothelial Syndrome', source: 'Corneal Diseases', value: '11' },
    { target: 'Keratitis', source: 'Corneal Diseases', value: '11' },
    { target: 'Keratoconus', source: 'Corneal Diseases', value: '11' },
    { target: 'Trachoma', source: 'Corneal Diseases', value: '11' },
    { target: 'Corneal Dystrophy, Juvenile Epithelial of Meesmann', source: 'Corneal Dystrophies, Hereditary', value: '15' },
    { target: 'Fuchs\' Endothelial Dystrophy', source: 'Corneal Dystrophies, Hereditary', value: '15' },
    { target: 'Corneal Perforation', source: 'Corneal Injuries', value: '15' },
    { target: 'Arcus Senilis', source: 'Corneal Opacity', value: '15' },
    { target: 'Acanthamoeba Keratitis', source: 'Keratitis', value: '15' },
    { target: 'Corneal Ulcer', source: 'Keratitis', value: '15' },
    { target: 'Keratitis, Herpetic', source: 'Keratitis', value: '15' },
    { target: 'Keratoconjunctivitis', source: 'Keratitis', value: '15' },
    { target: 'Keratitis, Dendritic', source: 'Keratitis, Herpetic', value: '19' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Keratoconjunctivitis', value: '19' },
    { target: 'Keratoconjunctivitis Sicca', source: 'Keratoconjunctivitis', value: '19' },
    { target: 'Aniridia', source: 'Eye Abnormalities', value: '11' },
    { target: 'Anophthalmos', source: 'Eye Abnormalities', value: '11' },
    { target: 'Blepharophimosis', source: 'Eye Abnormalities', value: '11' },
    { target: 'Coloboma', source: 'Eye Abnormalities', value: '11' },
    { target: 'Ectopia Lentis', source: 'Eye Abnormalities', value: '11' },
    { target: 'Fraser Syndrome', source: 'Eye Abnormalities', value: '11' },
    { target: 'Hydrophthalmos', source: 'Eye Abnormalities', value: '11' },
    { target: 'Microphthalmos', source: 'Eye Abnormalities', value: '11' },
    { target: 'Persistent Hyperplastic Primary Vitreous', source: 'Eye Abnormalities', value: '11' },
    { target: 'Retinal Dysplasia', source: 'Eye Abnormalities', value: '11' },
    { target: 'WAGR Syndrome', source: 'Aniridia', value: '15' },
    { target: 'Aicardi Syndrome', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Albinism', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Aniridia', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Choroideremia', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Cone-Rod Dystrophies', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Corneal Dystrophies, Hereditary', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Duane Retraction Syndrome', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Gyrate Atrophy', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Leber Congenital Amaurosis', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Optic Atrophies, Hereditary', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Retinal Dysplasia', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Retinitis Pigmentosa', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Graves Ophthalmopathy', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Walker-Warburg Syndrome', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Weill-Marchesani Syndrome', source: 'Eye Diseases, Hereditary', value: '11' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '15' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '15' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '19' },
    { target: 'WAGR Syndrome', source: 'Aniridia', value: '15' },
    { target: 'Corneal Dystrophy, Juvenile Epithelial of Meesmann', source: 'Corneal Dystrophies, Hereditary', value: '15' },
    { target: 'Fuchs\' Endothelial Dystrophy', source: 'Corneal Dystrophies, Hereditary', value: '15' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '15' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '15' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '15' },
    { target: 'Alstrom Syndrome', source: 'Retinitis Pigmentosa', value: '15' },
    { target: 'Choroid Hemorrhage', source: 'Eye Hemorrhage', value: '11' },
    { target: 'Hyphema', source: 'Eye Hemorrhage', value: '11' },
    { target: 'Retinal Hemorrhage', source: 'Eye Hemorrhage', value: '11' },
    { target: 'Vitreous Hemorrhage', source: 'Eye Hemorrhage', value: '11' },
    { target: 'Corneal Ulcer', source: 'Eye Infections', value: '11' },
    { target: 'Endophthalmitis', source: 'Eye Infections', value: '11' },
    { target: 'Eye Infections, Bacterial', source: 'Eye Infections', value: '11' },
    { target: 'Eye Infections, Fungal', source: 'Eye Infections', value: '11' },
    { target: 'Eye Infections, Parasitic', source: 'Eye Infections', value: '11' },
    { target: 'Eye Infections, Viral', source: 'Eye Infections', value: '11' },
    { target: 'Uveitis, Suppurative', source: 'Endophthalmitis', value: '15' },
    { target: 'Conjunctivitis, Bacterial', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Hordeolum', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Tuberculosis, Ocular', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Bacterial', value: '15' },
    { target: 'Conjunctivitis, Inclusion', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Ophthalmia Neonatorum', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Trachoma', source: 'Conjunctivitis, Bacterial', value: '19' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '19' },
    { target: 'Uveitis, Suppurative', source: 'Eye Infections, Fungal', value: '15' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '19' },
    { target: 'Acanthamoeba Keratitis', source: 'Eye Infections, Parasitic', value: '15' },
    { target: 'Onchocerciasis, Ocular', source: 'Eye Infections, Parasitic', value: '15' },
    { target: 'Toxoplasmosis, Ocular', source: 'Eye Infections, Parasitic', value: '15' },
    { target: 'Conjunctivitis, Viral', source: 'Eye Infections, Viral', value: '15' },
    { target: 'Cytomegalovirus Retinitis', source: 'Eye Infections, Viral', value: '15' },
    { target: 'Herpes Zoster Ophthalmicus', source: 'Eye Infections, Viral', value: '15' },
    { target: 'Keratitis, Herpetic', source: 'Eye Infections, Viral', value: '15' },
    { target: 'Conjunctivitis, Acute Hemorrhagic', source: 'Conjunctivitis, Viral', value: '19' },
    { target: 'Keratitis, Dendritic', source: 'Keratitis, Herpetic', value: '19' },
    { target: 'Anterior Capsular Rupture, Ocular', source: 'Eye Injuries', value: '11' },
    { target: 'Corneal Injuries', source: 'Eye Injuries', value: '11' },
    { target: 'Posterior Capsular Rupture, Ocular', source: 'Eye Injuries', value: '11' },
    { target: 'Corneal Perforation', source: 'Corneal Injuries', value: '15' },
    { target: 'Eye Pain', source: 'Eye Manifestations', value: '11' },
    { target: 'Conjunctival Neoplasms', source: 'Eye Neoplasms', value: '11' },
    { target: 'Eyelid Neoplasms', source: 'Eye Neoplasms', value: '11' },
    { target: 'Orbital Neoplasms', source: 'Eye Neoplasms', value: '11' },
    { target: 'Paraneoplastic Syndromes, Ocular', source: 'Eye Neoplasms', value: '11' },
    { target: 'Retinal Neoplasms', source: 'Eye Neoplasms', value: '11' },
    { target: 'Uveal Neoplasms', source: 'Eye Neoplasms', value: '11' },
    { target: 'Retinoblastoma', source: 'Retinal Neoplasms', value: '15' },
    { target: 'Choroid Neoplasms', source: 'Uveal Neoplasms', value: '15' },
    { target: 'Iris Neoplasms', source: 'Uveal Neoplasms', value: '15' },
    { target: 'Blepharitis', source: 'Eyelid Diseases', value: '11' },
    { target: 'Blepharophimosis', source: 'Eyelid Diseases', value: '11' },
    { target: 'Blepharoptosis', source: 'Eyelid Diseases', value: '11' },
    { target: 'Blepharospasm', source: 'Eyelid Diseases', value: '11' },
    { target: 'Chalazion', source: 'Eyelid Diseases', value: '11' },
    { target: 'Ectropion', source: 'Eyelid Diseases', value: '11' },
    { target: 'Entropion', source: 'Eyelid Diseases', value: '11' },
    { target: 'Eyelid Neoplasms', source: 'Eyelid Diseases', value: '11' },
    { target: 'Hordeolum', source: 'Eyelid Diseases', value: '11' },
    { target: 'Trichiasis', source: 'Eyelid Diseases', value: '11' },
    { target: 'Dacryocystitis', source: 'Lacrimal Apparatus Diseases', value: '11' },
    { target: 'Dry Eye Syndromes', source: 'Lacrimal Apparatus Diseases', value: '11' },
    { target: 'Lacrimal Duct Obstruction', source: 'Lacrimal Apparatus Diseases', value: '11' },
    { target: 'Canaliculitis', source: 'Dacryocystitis', value: '15' },
    { target: 'Keratoconjunctivitis Sicca', source: 'Dry Eye Syndromes', value: '15' },
    { target: 'Sjogren\'s Syndrome', source: 'Dry Eye Syndromes', value: '15' },
    { target: 'Xerophthalmia', source: 'Dry Eye Syndromes', value: '15' },
    { target: 'Aphakia', source: 'Lens Diseases', value: '11' },
    { target: 'Artificial Lens Implant Migration', source: 'Lens Diseases', value: '11' },
    { target: 'Cataract', source: 'Lens Diseases', value: '11' },
    { target: 'Lens Subluxation', source: 'Lens Diseases', value: '11' },
    { target: 'Aphakia, Postcataract', source: 'Aphakia', value: '15' },
    { target: 'Capsule Opacification', source: 'Cataract', value: '15' },
    { target: 'Ectopia Lentis', source: 'Lens Subluxation', value: '15' },
    { target: 'Glaucoma', source: 'Ocular Hypertension', value: '11' },
    { target: 'Glaucoma, Angle-Closure', source: 'Glaucoma', value: '15' },
    { target: 'Glaucoma, Neovascular', source: 'Glaucoma', value: '15' },
    { target: 'Glaucoma, Open-Angle', source: 'Glaucoma', value: '15' },
    { target: 'Hydrophthalmos', source: 'Glaucoma, Open-Angle', value: '19' },
    { target: 'Duane Retraction Syndrome', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Miller Fisher Syndrome', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Nystagmus, Pathologic', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Oculomotor Nerve Diseases', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Ophthalmoplegia', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Opsoclonus-Myoclonus Syndrome', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Strabismus', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Tolosa-Hunt Syndrome', source: 'Ocular Motility Disorders', value: '11' },
    { target: 'Nystagmus, Congenital', source: 'Nystagmus, Pathologic', value: '15' },
    { target: 'Adie Syndrome', source: 'Oculomotor Nerve Diseases', value: '15' },
    { target: 'Oculomotor Nerve Injuries', source: 'Oculomotor Nerve Diseases', value: '15' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Ophthalmoplegia', value: '15' },
    { target: 'Ophthalmoplegic Migraine', source: 'Ophthalmoplegia', value: '15' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Ophthalmoplegia', value: '15' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '19' },
    { target: 'Esotropia', source: 'Strabismus', value: '15' },
    { target: 'Exotropia', source: 'Strabismus', value: '15' },
    { target: 'Low Tension Glaucoma', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Atrophy', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Disk Drusen', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Nerve Injuries', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Nerve Neoplasms', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Neuritis', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Neuropathy, Ischemic', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Papilledema', source: 'Optic Nerve Diseases', value: '11' },
    { target: 'Optic Atrophies, Hereditary', source: 'Optic Atrophy', value: '15' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Optic Nerve Glioma', source: 'Optic Nerve Neoplasms', value: '15' },
    { target: 'Neuromyelitis Optica', source: 'Optic Neuritis', value: '15' },
    { target: 'Enophthalmos', source: 'Orbital Diseases', value: '11' },
    { target: 'Exophthalmos', source: 'Orbital Diseases', value: '11' },
    { target: 'Orbital Cellulitis', source: 'Orbital Diseases', value: '11' },
    { target: 'Orbital Myositis', source: 'Orbital Diseases', value: '11' },
    { target: 'Orbital Neoplasms', source: 'Orbital Diseases', value: '11' },
    { target: 'Orbital Pseudotumor', source: 'Orbital Diseases', value: '11' },
    { target: 'Pott Puffy Tumor', source: 'Orbital Diseases', value: '11' },
    { target: 'Retrobulbar Hemorrhage', source: 'Orbital Diseases', value: '11' },
    { target: 'Graves Disease', source: 'Exophthalmos', value: '15' },
    { target: 'Graves Ophthalmopathy', source: 'Graves Disease', value: '19' },
    { target: 'Anisocoria', source: 'Pupil Disorders', value: '11' },
    { target: 'Miosis', source: 'Pupil Disorders', value: '11' },
    { target: 'Mydriasis', source: 'Pupil Disorders', value: '11' },
    { target: 'Tonic Pupil', source: 'Pupil Disorders', value: '11' },
    { target: 'Horner Syndrome', source: 'Miosis', value: '15' },
    { target: 'Adie Syndrome', source: 'Tonic Pupil', value: '15' },
    { target: 'Aniseikonia', source: 'Refractive Errors', value: '11' },
    { target: 'Anisometropia', source: 'Refractive Errors', value: '11' },
    { target: 'Astigmatism', source: 'Refractive Errors', value: '11' },
    { target: 'Corneal Wavefront Aberration', source: 'Refractive Errors', value: '11' },
    { target: 'Hyperopia', source: 'Refractive Errors', value: '11' },
    { target: 'Myopia', source: 'Refractive Errors', value: '11' },
    { target: 'Presbyopia', source: 'Refractive Errors', value: '11' },
    { target: 'Myopia, Degenerative', source: 'Myopia', value: '15' },
    { target: 'Angioid Streaks', source: 'Retinal Diseases', value: '11' },
    { target: 'Central Serous Chorioretinopathy', source: 'Retinal Diseases', value: '11' },
    { target: 'Diabetic Retinopathy', source: 'Retinal Diseases', value: '11' },
    { target: 'Epiretinal Membrane', source: 'Retinal Diseases', value: '11' },
    { target: 'Hypertensive Retinopathy', source: 'Retinal Diseases', value: '11' },
    { target: 'Leber Congenital Amaurosis', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Artery Occlusion', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Degeneration', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Detachment', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Dysplasia', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Hemorrhage', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Neoplasms', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Neovascularization', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Perforations', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Telangiectasis', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Vasculitis', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinal Vein Occlusion', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinitis', source: 'Retinal Diseases', value: '11' },
    { target: 'Retinopathy of Prematurity', source: 'Retinal Diseases', value: '11' },
    { target: 'Vitreoretinopathy, Proliferative', source: 'Retinal Diseases', value: '11' },
    { target: 'Susac Syndrome', source: 'Retinal Artery Occlusion', value: '15' },
    { target: 'Macular Degeneration', source: 'Retinal Degeneration', value: '15' },
    { target: 'Retinal Drusen', source: 'Retinal Degeneration', value: '15' },
    { target: 'Retinal Dystrophies', source: 'Retinal Degeneration', value: '15' },
    { target: 'Retinoschisis', source: 'Retinal Degeneration', value: '15' },
    { target: 'Geographic Atrophy', source: 'Macular Degeneration', value: '19' },
    { target: 'Macular Edema', source: 'Macular Degeneration', value: '19' },
    { target: 'Vitelliform Macular Dystrophy', source: 'Macular Degeneration', value: '19' },
    { target: 'Wet Macular Degeneration', source: 'Macular Degeneration', value: '19' },
    { target: 'Cone-Rod Dystrophies', source: 'Retinal Dystrophies', value: '19' },
    { target: 'Retinitis Pigmentosa', source: 'Retinal Dystrophies', value: '19' },
    { target: 'Kearns-Sayre Syndrome', source: 'Retinitis Pigmentosa', value: '23' },
    { target: 'Usher Syndromes', source: 'Retinitis Pigmentosa', value: '23' },
    { target: 'Retinoblastoma', source: 'Retinal Neoplasms', value: '15' },
    { target: 'Chorioretinitis', source: 'Retinitis', value: '15' },
    { target: 'Cytomegalovirus Retinitis', source: 'Retinitis', value: '15' },
    { target: 'Retinal Necrosis Syndrome, Acute', source: 'Retinitis', value: '15' },
    { target: 'Scleritis', source: 'Scleral Diseases', value: '11' },
    { target: 'Choroid Diseases', source: 'Uveal Diseases', value: '11' },
    { target: 'Iris Diseases', source: 'Uveal Diseases', value: '11' },
    { target: 'Uveal Neoplasms', source: 'Uveal Diseases', value: '11' },
    { target: 'Uveitis', source: 'Uveal Diseases', value: '11' },
    { target: 'Choroid Hemorrhage', source: 'Choroid Diseases', value: '15' },
    { target: 'Choroid Neoplasms', source: 'Choroid Diseases', value: '15' },
    { target: 'Choroidal Neovascularization', source: 'Choroid Diseases', value: '15' },
    { target: 'Choroideremia', source: 'Choroid Diseases', value: '15' },
    { target: 'Choroiditis', source: 'Choroid Diseases', value: '15' },
    { target: 'Gyrate Atrophy', source: 'Choroid Diseases', value: '15' },
    { target: 'Chorioretinitis', source: 'Choroiditis', value: '19' },
    { target: 'Pars Planitis', source: 'Choroiditis', value: '19' },
    { target: 'Aniridia', source: 'Iris Diseases', value: '15' },
    { target: 'Exfoliation Syndrome', source: 'Iris Diseases', value: '15' },
    { target: 'Iridocorneal Endothelial Syndrome', source: 'Iris Diseases', value: '15' },
    { target: 'Iridocyclitis', source: 'Iris Diseases', value: '15' },
    { target: 'Iris Neoplasms', source: 'Iris Diseases', value: '15' },
    { target: 'Iritis', source: 'Iris Diseases', value: '15' },
    { target: 'WAGR Syndrome', source: 'Aniridia', value: '19' },
    { target: 'Choroid Neoplasms', source: 'Uveal Neoplasms', value: '15' },
    { target: 'Iris Neoplasms', source: 'Uveal Neoplasms', value: '15' },
    { target: 'Panuveitis', source: 'Uveitis', value: '15' },
    { target: 'Uveitis, Intermediate', source: 'Uveitis', value: '15' },
    { target: 'Uveitis, Suppurative', source: 'Uveitis', value: '15' },
    { target: 'Uveomeningoencephalitic Syndrome', source: 'Uveitis', value: '15' },
    { target: 'Ophthalmia, Sympathetic', source: 'Panuveitis', value: '19' },
    { target: 'Uveitis, Anterior', source: 'Panuveitis', value: '19' },
    { target: 'Uveitis, Posterior', source: 'Panuveitis', value: '19' },
    { target: 'Behcet Syndrome', source: 'Uveitis, Anterior', value: '23' },
    { target: 'Iridocyclitis', source: 'Uveitis, Anterior', value: '23' },
    { target: 'Iritis', source: 'Uveitis, Anterior', value: '23' },
    { target: 'Choroiditis', source: 'Uveitis, Posterior', value: '23' },
    { target: 'Chorioretinitis', source: 'Choroiditis', value: '27' },
    { target: 'Pars Planitis', source: 'Choroiditis', value: '27' },
    { target: 'Pars Planitis', source: 'Uveitis, Intermediate', value: '19' },
    { target: 'Panophthalmitis', source: 'Uveitis, Suppurative', value: '19' },
    { target: 'Amblyopia', source: 'Vision Disorders', value: '11' },
    { target: 'Blindness', source: 'Vision Disorders', value: '11' },
    { target: 'Color Vision Defects', source: 'Vision Disorders', value: '11' },
    { target: 'Diplopia', source: 'Vision Disorders', value: '11' },
    { target: 'Night Blindness', source: 'Vision Disorders', value: '11' },
    { target: 'Photophobia', source: 'Vision Disorders', value: '11' },
    { target: 'Scotoma', source: 'Vision Disorders', value: '11' },
    { target: 'Susac Syndrome', source: 'Vision Disorders', value: '11' },
    { target: 'Vision, Low', source: 'Vision Disorders', value: '11' },
    { target: 'Amaurosis Fugax', source: 'Blindness', value: '15' },
    { target: 'Blindness, Cortical', source: 'Blindness', value: '15' },
    { target: 'Deaf-Blind Disorders', source: 'Blindness', value: '15' },
    { target: 'Hemianopsia', source: 'Blindness', value: '15' },
    { target: 'Usher Syndromes', source: 'Deaf-Blind Disorders', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Deaf-Blind Disorders', value: '19' },
    { target: 'Epididymitis', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Fournier Gangrene', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Genital Neoplasms, Male', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Hematocele', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Hemospermia', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Herpes Genitalis', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Infertility', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Penile Diseases', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Prostatic Diseases', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Reproductive Tract Infections', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Sexual Dysfunction, Physiological', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Sexually Transmitted Diseases', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Spermatic Cord Torsion', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Spermatocele', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Testicular Diseases', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Testicular Hydrocele', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Tuberculosis, Male Genital', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Varicocele', source: 'Genital Diseases, Male', value: '11' },
    { target: 'Penile Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Prostatic Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Testicular Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Prostatic Neoplasms, Castration-Resistant', source: 'Prostatic Neoplasms', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '19' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Infertility, Male', source: 'Infertility', value: '15' },
    { target: 'Aspermia', source: 'Infertility, Male', value: '19' },
    { target: 'Asthenozoospermia', source: 'Infertility, Male', value: '19' },
    { target: 'Azoospermia', source: 'Infertility, Male', value: '19' },
    { target: 'Oligospermia', source: 'Infertility, Male', value: '19' },
    { target: 'Sertoli Cell-Only Syndrome', source: 'Infertility, Male', value: '19' },
    { target: 'Teratozoospermia', source: 'Infertility, Male', value: '19' },
    { target: 'Balanitis', source: 'Penile Diseases', value: '15' },
    { target: 'Hypospadias', source: 'Penile Diseases', value: '15' },
    { target: 'Penile Induration', source: 'Penile Diseases', value: '15' },
    { target: 'Penile Neoplasms', source: 'Penile Diseases', value: '15' },
    { target: 'Phimosis', source: 'Penile Diseases', value: '15' },
    { target: 'Priapism', source: 'Penile Diseases', value: '15' },
    { target: 'Balanitis Xerotica Obliterans', source: 'Balanitis', value: '19' },
    { target: 'Paraphimosis', source: 'Phimosis', value: '19' },
    { target: 'Prostatic Hyperplasia', source: 'Prostatic Diseases', value: '15' },
    { target: 'Prostatic Neoplasms', source: 'Prostatic Diseases', value: '15' },
    { target: 'Prostatitis', source: 'Prostatic Diseases', value: '15' },
    { target: 'Prostatic Neoplasms, Castration-Resistant', source: 'Prostatic Neoplasms', value: '19' },
    { target: 'Dyspareunia', source: 'Sexual Dysfunction, Physiological', value: '15' },
    { target: 'Erectile Dysfunction', source: 'Sexual Dysfunction, Physiological', value: '15' },
    { target: 'Premature Ejaculation', source: 'Sexual Dysfunction, Physiological', value: '15' },
    { target: 'Impotence, Vasculogenic', source: 'Erectile Dysfunction', value: '19' },
    { target: 'Sexually Transmitted Diseases, Bacterial', source: 'Sexually Transmitted Diseases', value: '15' },
    { target: 'Chancroid', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Chlamydia Infections', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Gonorrhea', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Granuloma Inguinale', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Syphilis', source: 'Sexually Transmitted Diseases, Bacterial', value: '19' },
    { target: 'Lymphogranuloma Venereum', source: 'Chlamydia Infections', value: '23' },
    { target: 'Cryptorchidism', source: 'Testicular Diseases', value: '15' },
    { target: 'Orchitis', source: 'Testicular Diseases', value: '15' },
    { target: 'Tuberculosis, Male Genital', source: 'Tuberculosis, Urogenital', value: '11' },
    { target: 'Tuberculosis, Renal', source: 'Tuberculosis, Urogenital', value: '11' },
    { target: 'Bladder Exstrophy', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Cryptorchidism', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Disorders of Sex Development', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Epispadias', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Fraser Syndrome', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Fused Kidney', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Hypospadias', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Multicystic Dysplastic Kidney', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Nephritis, Hereditary', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Retrocaval Ureter', source: 'Urogenital Abnormalities', value: '11' },
    { target: 'Urinary Fistula', source: 'Urogenital Abnormalities', value: '11' },
    { target: '46, XX Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: '46, XY Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Adrenogenital Syndrome', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Gonadal Dysgenesis', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Ovotesticular Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: '46, XX Testicular Disorders of Sex Development', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Hyperandrogenism', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Androgen-Insensitivity Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Denys-Drash Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Frasier Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Kallmann Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'WAGR Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '23' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Adrenogenital Syndrome', value: '19' },
    { target: 'Hyperandrogenism', source: 'Adrenogenital Syndrome', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Sexual Infantilism', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Turner Syndrome', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '23' },
    { target: 'Freemartinism', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Urinary Bladder Fistula', source: 'Urinary Fistula', value: '15' },
    { target: 'Genital Neoplasms, Male', source: 'Urogenital Neoplasms', value: '11' },
    { target: 'Urologic Neoplasms', source: 'Urogenital Neoplasms', value: '11' },
    { target: 'Penile Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Prostatic Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Testicular Neoplasms', source: 'Genital Neoplasms, Male', value: '15' },
    { target: 'Prostatic Neoplasms, Castration-Resistant', source: 'Prostatic Neoplasms', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '19' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Kidney Neoplasms', source: 'Urologic Neoplasms', value: '15' },
    { target: 'Ureteral Neoplasms', source: 'Urologic Neoplasms', value: '15' },
    { target: 'Urethral Neoplasms', source: 'Urologic Neoplasms', value: '15' },
    { target: 'Urinary Bladder Neoplasms', source: 'Urologic Neoplasms', value: '15' },
    { target: 'Carcinoma, Renal Cell', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Nephroma, Mesoblastic', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Wilms Tumor', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '23' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '23' },
    { target: 'Kidney Diseases', source: 'Urologic Diseases', value: '11' },
    { target: 'Ureteral Diseases', source: 'Urologic Diseases', value: '11' },
    { target: 'Urethral Diseases', source: 'Urologic Diseases', value: '11' },
    { target: 'Urinary Bladder Diseases', source: 'Urologic Diseases', value: '11' },
    { target: 'Urinary Tract Infections', source: 'Urologic Diseases', value: '11' },
    { target: 'Urination Disorders', source: 'Urologic Diseases', value: '11' },
    { target: 'Urolithiasis', source: 'Urologic Diseases', value: '11' },
    { target: 'AIDS-Associated Nephropathy', source: 'Kidney Diseases', value: '15' },
    { target: 'Anuria', source: 'Kidney Diseases', value: '15' },
    { target: 'Diabetes Insipidus', source: 'Kidney Diseases', value: '15' },
    { target: 'Diabetic Nephropathies', source: 'Kidney Diseases', value: '15' },
    { target: 'Hepatorenal Syndrome', source: 'Kidney Diseases', value: '15' },
    { target: 'Hydronephrosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Hyperoxaluria', source: 'Kidney Diseases', value: '15' },
    { target: 'Hypertension, Renal', source: 'Kidney Diseases', value: '15' },
    { target: 'Kidney Cortex Necrosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Kidney Diseases, Cystic', source: 'Kidney Diseases', value: '15' },
    { target: 'Kidney Neoplasms', source: 'Kidney Diseases', value: '15' },
    { target: 'Kidney Papillary Necrosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Nephritis', source: 'Kidney Diseases', value: '15' },
    { target: 'Nephrocalcinosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Nephrolithiasis', source: 'Kidney Diseases', value: '15' },
    { target: 'Nephrosclerosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Nephrosis', source: 'Kidney Diseases', value: '15' },
    { target: 'Perinephritis', source: 'Kidney Diseases', value: '15' },
    { target: 'Renal Artery Obstruction', source: 'Kidney Diseases', value: '15' },
    { target: 'Renal Insufficiency', source: 'Kidney Diseases', value: '15' },
    { target: 'Renal Nutcracker Syndrome', source: 'Kidney Diseases', value: '15' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Kidney Diseases', value: '15' },
    { target: 'Renal Tubular Transport, Inborn Errors', source: 'Kidney Diseases', value: '15' },
    { target: 'Tuberculosis, Renal', source: 'Kidney Diseases', value: '15' },
    { target: 'Uremia', source: 'Kidney Diseases', value: '15' },
    { target: 'Zellweger Syndrome', source: 'Kidney Diseases', value: '15' },
    { target: 'Diabetes Insipidus, Nephrogenic', source: 'Diabetes Insipidus', value: '19' },
    { target: 'Diabetes Insipidus, Neurogenic', source: 'Diabetes Insipidus', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Diabetes Insipidus', value: '19' },
    { target: 'Pyonephrosis', source: 'Hydronephrosis', value: '19' },
    { target: 'Hyperoxaluria, Primary', source: 'Hyperoxaluria', value: '19' },
    { target: 'Hypertension, Renovascular', source: 'Hypertension, Renal', value: '19' },
    { target: 'Medullary Sponge Kidney', source: 'Kidney Diseases, Cystic', value: '19' },
    { target: 'Multicystic Dysplastic Kidney', source: 'Kidney Diseases, Cystic', value: '19' },
    { target: 'Polycystic Kidney Diseases', source: 'Kidney Diseases, Cystic', value: '19' },
    { target: 'Polycystic Kidney, Autosomal Dominant', source: 'Polycystic Kidney Diseases', value: '23' },
    { target: 'Polycystic Kidney, Autosomal Recessive', source: 'Polycystic Kidney Diseases', value: '23' },
    { target: 'Carcinoma, Renal Cell', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Nephroma, Mesoblastic', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Wilms Tumor', source: 'Kidney Neoplasms', value: '19' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '23' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '23' },
    { target: 'Glomerulonephritis', source: 'Nephritis', value: '19' },
    { target: 'Nephritis, Hereditary', source: 'Nephritis', value: '19' },
    { target: 'Nephritis, Interstitial', source: 'Nephritis', value: '19' },
    { target: 'Pyelitis', source: 'Nephritis', value: '19' },
    { target: 'Anti-Glomerular Basement Membrane Disease', source: 'Glomerulonephritis', value: '23' },
    { target: 'Glomerulonephritis, IGA', source: 'Glomerulonephritis', value: '23' },
    { target: 'Glomerulonephritis, Membranoproliferative', source: 'Glomerulonephritis', value: '23' },
    { target: 'Glomerulonephritis, Membranous', source: 'Glomerulonephritis', value: '23' },
    { target: 'Glomerulosclerosis, Focal Segmental', source: 'Glomerulonephritis', value: '23' },
    { target: 'Lupus Nephritis', source: 'Glomerulonephritis', value: '23' },
    { target: 'Balkan Nephropathy', source: 'Nephritis, Interstitial', value: '23' },
    { target: 'Pyelonephritis', source: 'Nephritis, Interstitial', value: '23' },
    { target: 'Pyelonephritis, Xanthogranulomatous', source: 'Pyelonephritis', value: '27' },
    { target: 'Pyelocystitis', source: 'Pyelitis', value: '23' },
    { target: 'Pyelonephritis', source: 'Pyelitis', value: '23' },
    { target: 'Pyelonephritis, Xanthogranulomatous', source: 'Pyelonephritis', value: '27' },
    { target: 'Kidney Calculi', source: 'Nephrolithiasis', value: '19' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '23' },
    { target: 'Nephrosis, Lipoid', source: 'Nephrosis', value: '19' },
    { target: 'Nephrotic Syndrome', source: 'Nephrosis', value: '19' },
    { target: 'Acute Kidney Injury', source: 'Renal Insufficiency', value: '19' },
    { target: 'Cardio-Renal Syndrome', source: 'Renal Insufficiency', value: '19' },
    { target: 'Renal Insufficiency, Chronic', source: 'Renal Insufficiency', value: '19' },
    { target: 'Kidney Tubular Necrosis, Acute', source: 'Acute Kidney Injury', value: '23' },
    { target: 'Kidney Failure, Chronic', source: 'Renal Insufficiency, Chronic', value: '23' },
    { target: 'Frasier Syndrome', source: 'Kidney Failure, Chronic', value: '27' },
    { target: 'Acidosis, Renal Tubular', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Bartter Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Dent Disease', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Fanconi Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Gitelman Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Glycosuria, Renal', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Hypophosphatemia, Familial', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Liddle Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Pseudohypoaldosteronism', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Renal Aminoacidurias', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Cystinuria', source: 'Renal Aminoacidurias', value: '23' },
    { target: 'Hartnup Disease', source: 'Renal Aminoacidurias', value: '23' },
    { target: 'Azotemia', source: 'Uremia', value: '19' },
    { target: 'Hemolytic-Uremic Syndrome', source: 'Uremia', value: '19' },
    { target: 'Atypical Hemolytic Uremic Syndrome', source: 'Hemolytic-Uremic Syndrome', value: '23' },
    { target: 'Ureteral Neoplasms', source: 'Ureteral Diseases', value: '15' },
    { target: 'Ureteral Obstruction', source: 'Ureteral Diseases', value: '15' },
    { target: 'Ureterocele', source: 'Ureteral Diseases', value: '15' },
    { target: 'Ureterolithiasis', source: 'Ureteral Diseases', value: '15' },
    { target: 'Ureteral Calculi', source: 'Ureterolithiasis', value: '19' },
    { target: 'Epispadias', source: 'Urethral Diseases', value: '15' },
    { target: 'Urethral Neoplasms', source: 'Urethral Diseases', value: '15' },
    { target: 'Urethral Obstruction', source: 'Urethral Diseases', value: '15' },
    { target: 'Urethritis', source: 'Urethral Diseases', value: '15' },
    { target: 'Urethral Stricture', source: 'Urethral Obstruction', value: '19' },
    { target: 'Urinary Bladder Neck Obstruction', source: 'Urethral Obstruction', value: '19' },
    { target: 'Bladder Exstrophy', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Cystitis', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Cystocele', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder Calculi', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder Fistula', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder Neck Obstruction', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder Neoplasms', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder, Neurogenic', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Urinary Bladder, Overactive', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Vesico-Ureteral Reflux', source: 'Urinary Bladder Diseases', value: '15' },
    { target: 'Cystitis, Interstitial', source: 'Cystitis', value: '19' },
    { target: 'Pyelocystitis', source: 'Cystitis', value: '19' },
    { target: 'Bacteriuria', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Pyuria', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Schistosomiasis haematobia', source: 'Urinary Tract Infections', value: '15' },
    { target: 'Anuria', source: 'Urination Disorders', value: '15' },
    { target: 'Enuresis', source: 'Urination Disorders', value: '15' },
    { target: 'Glycosuria', source: 'Urination Disorders', value: '15' },
    { target: 'Hematuria', source: 'Urination Disorders', value: '15' },
    { target: 'Oliguria', source: 'Urination Disorders', value: '15' },
    { target: 'Polyuria', source: 'Urination Disorders', value: '15' },
    { target: 'Proteinuria', source: 'Urination Disorders', value: '15' },
    { target: 'Urinary Incontinence', source: 'Urination Disorders', value: '15' },
    { target: 'Urinary Retention', source: 'Urination Disorders', value: '15' },
    { target: 'Diurnal Enuresis', source: 'Enuresis', value: '19' },
    { target: 'Nocturnal Enuresis', source: 'Enuresis', value: '19' },
    { target: 'Glycosuria, Renal', source: 'Glycosuria', value: '19' },
    { target: 'Albuminuria', source: 'Proteinuria', value: '19' },
    { target: 'Hemoglobinuria', source: 'Proteinuria', value: '19' },
    { target: 'Urinary Incontinence, Stress', source: 'Urinary Incontinence', value: '19' },
    { target: 'Urinary Incontinence, Urge', source: 'Urinary Incontinence', value: '19' },
    { target: 'Nephrolithiasis', source: 'Urolithiasis', value: '15' },
    { target: 'Ureterolithiasis', source: 'Urolithiasis', value: '15' },
    { target: 'Urinary Calculi', source: 'Urolithiasis', value: '15' },
    { target: 'Kidney Calculi', source: 'Nephrolithiasis', value: '19' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '23' },
    { target: 'Ureteral Calculi', source: 'Ureterolithiasis', value: '19' },
    { target: 'Kidney Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Ureteral Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Urinary Bladder Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '23' },
    { target: 'Genital Diseases, Female', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Pelvic Floor Disorders', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Tuberculosis, Urogenital', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Urogenital Abnormalities', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Urogenital Neoplasms', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Urologic Diseases', source: 'Female Urogenital Diseases', value: '11' },
    { target: 'Adnexal Diseases', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Endometriosis', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Gynatresia', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Herpes Genitalis', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Infertility', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Reproductive Tract Infections', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Sexual Dysfunction, Physiological', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Sexually Transmitted Diseases', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Tuberculosis, Female Genital', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Uterine Diseases', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Vaginal Diseases', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Vulvar Diseases', source: 'Genital Diseases, Female', value: '15' },
    { target: 'Fallopian Tube Diseases', source: 'Adnexal Diseases', value: '19' },
    { target: 'Ovarian Diseases', source: 'Adnexal Diseases', value: '19' },
    { target: 'Pelvic Inflammatory Disease', source: 'Adnexal Diseases', value: '19' },
    { target: 'Fallopian Tube Neoplasms', source: 'Fallopian Tube Diseases', value: '23' },
    { target: 'Salpingitis', source: 'Fallopian Tube Diseases', value: '23' },
    { target: 'Anovulation', source: 'Ovarian Diseases', value: '23' },
    { target: 'Menopause, Premature', source: 'Ovarian Diseases', value: '23' },
    { target: 'Oophoritis', source: 'Ovarian Diseases', value: '23' },
    { target: 'Ovarian Cysts', source: 'Ovarian Diseases', value: '23' },
    { target: 'Ovarian Hyperstimulation Syndrome', source: 'Ovarian Diseases', value: '23' },
    { target: 'Ovarian Neoplasms', source: 'Ovarian Diseases', value: '23' },
    { target: 'Primary Ovarian Insufficiency', source: 'Ovarian Diseases', value: '23' },
    { target: 'Polycystic Ovary Syndrome', source: 'Ovarian Cysts', value: '27' },
    { target: 'Brenner Tumor', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Carcinoma, Endometrioid', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Granulosa Cell Tumor', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Luteoma', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Meigs Syndrome', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Thecoma', source: 'Ovarian Neoplasms', value: '27' },
    { target: 'Endometritis', source: 'Pelvic Inflammatory Disease', value: '23' },
    { target: 'Oophoritis', source: 'Pelvic Inflammatory Disease', value: '23' },
    { target: 'Parametritis', source: 'Pelvic Inflammatory Disease', value: '23' },
    { target: 'Salpingitis', source: 'Pelvic Inflammatory Disease', value: '23' },
    { target: 'Infertility, Female', source: 'Infertility', value: '19' },
    { target: 'Dyspareunia', source: 'Sexual Dysfunction, Physiological', value: '19' },
    { target: 'Vaginismus', source: 'Sexual Dysfunction, Physiological', value: '19' },
    { target: 'Sexually Transmitted Diseases, Bacterial', source: 'Sexually Transmitted Diseases', value: '19' },
    { target: 'Chancroid', source: 'Sexually Transmitted Diseases, Bacterial', value: '23' },
    { target: 'Chlamydia Infections', source: 'Sexually Transmitted Diseases, Bacterial', value: '23' },
    { target: 'Gonorrhea', source: 'Sexually Transmitted Diseases, Bacterial', value: '23' },
    { target: 'Granuloma Inguinale', source: 'Sexually Transmitted Diseases, Bacterial', value: '23' },
    { target: 'Syphilis', source: 'Sexually Transmitted Diseases, Bacterial', value: '23' },
    { target: 'Lymphogranuloma Venereum', source: 'Chlamydia Infections', value: '27' },
    { target: 'Adenomyosis', source: 'Uterine Diseases', value: '19' },
    { target: 'Endometrial Hyperplasia', source: 'Uterine Diseases', value: '19' },
    { target: 'Endometritis', source: 'Uterine Diseases', value: '19' },
    { target: 'Hematometra', source: 'Uterine Diseases', value: '19' },
    { target: 'Pyometra', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Cervical Diseases', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Hemorrhage', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Inversion', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Neoplasms', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Prolapse', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Rupture', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Retroversion', source: 'Uterine Diseases', value: '19' },
    { target: 'Uterine Cervical Dysplasia', source: 'Uterine Cervical Diseases', value: '23' },
    { target: 'Uterine Cervical Erosion', source: 'Uterine Cervical Diseases', value: '23' },
    { target: 'Uterine Cervical Incompetence', source: 'Uterine Cervical Diseases', value: '23' },
    { target: 'Uterine Cervical Neoplasms', source: 'Uterine Cervical Diseases', value: '23' },
    { target: 'Uterine Cervicitis', source: 'Uterine Cervical Diseases', value: '23' },
    { target: 'Atypical Squamous Cells of the Cervix', source: 'Uterine Cervical Dysplasia', value: '27' },
    { target: 'Squamous Intraepithelial Lesions of the Cervix', source: 'Uterine Cervical Dysplasia', value: '27' },
    { target: 'Menorrhagia', source: 'Uterine Hemorrhage', value: '23' },
    { target: 'Metrorrhagia', source: 'Uterine Hemorrhage', value: '23' },
    { target: 'Endometrial Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Uterine Cervical Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Endometrial Stromal Tumors', source: 'Endometrial Neoplasms', value: '27' },
    { target: 'Sarcoma, Endometrial Stromal', source: 'Endometrial Stromal Tumors', value: '31' },
    { target: 'Uterine Perforation', source: 'Uterine Rupture', value: '23' },
    { target: 'Hematocolpos', source: 'Vaginal Diseases', value: '19' },
    { target: 'Hydrocolpos', source: 'Vaginal Diseases', value: '19' },
    { target: 'Vaginal Discharge', source: 'Vaginal Diseases', value: '19' },
    { target: 'Vaginal Fistula', source: 'Vaginal Diseases', value: '19' },
    { target: 'Vaginal Neoplasms', source: 'Vaginal Diseases', value: '19' },
    { target: 'Vaginismus', source: 'Vaginal Diseases', value: '19' },
    { target: 'Vaginitis', source: 'Vaginal Diseases', value: '19' },
    { target: 'Leukorrhea', source: 'Vaginal Discharge', value: '23' },
    { target: 'Rectovaginal Fistula', source: 'Vaginal Fistula', value: '23' },
    { target: 'Vesicovaginal Fistula', source: 'Vaginal Fistula', value: '23' },
    { target: 'Atrophic Vaginitis', source: 'Vaginitis', value: '23' },
    { target: 'Trichomonas Vaginitis', source: 'Vaginitis', value: '23' },
    { target: 'Vaginosis, Bacterial', source: 'Vaginitis', value: '23' },
    { target: 'Vulvovaginitis', source: 'Vaginitis', value: '23' },
    { target: 'Candidiasis, Vulvovaginal', source: 'Vulvovaginitis', value: '27' },
    { target: 'Pruritus Vulvae', source: 'Vulvar Diseases', value: '19' },
    { target: 'Vulvar Lichen Sclerosus', source: 'Vulvar Diseases', value: '19' },
    { target: 'Vulvar Neoplasms', source: 'Vulvar Diseases', value: '19' },
    { target: 'Vulvitis', source: 'Vulvar Diseases', value: '19' },
    { target: 'Vulvodynia', source: 'Vulvar Diseases', value: '19' },
    { target: 'Vulvar Vestibulitis', source: 'Vulvitis', value: '23' },
    { target: 'Vulvovaginitis', source: 'Vulvitis', value: '23' },
    { target: 'Candidiasis, Vulvovaginal', source: 'Vulvovaginitis', value: '27' },
    { target: 'Tuberculosis, Female Genital', source: 'Tuberculosis, Urogenital', value: '15' },
    { target: 'Tuberculosis, Renal', source: 'Tuberculosis, Urogenital', value: '15' },
    { target: 'Bladder Exstrophy', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Disorders of Sex Development', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Epispadias', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Fraser Syndrome', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Fused Kidney', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Hypospadias', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Multicystic Dysplastic Kidney', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Nephritis, Hereditary', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Retrocaval Ureter', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Urinary Fistula', source: 'Urogenital Abnormalities', value: '15' },
    { target: '46, XX Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: '46, XY Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Adrenogenital Syndrome', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Ovotesticular Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: '46, XX Testicular Disorders of Sex Development', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Hyperandrogenism', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Androgen-Insensitivity Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Denys-Drash Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Frasier Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Kallmann Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'WAGR Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '27' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Adrenogenital Syndrome', value: '23' },
    { target: 'Hyperandrogenism', source: 'Adrenogenital Syndrome', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Sexual Infantilism', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Turner Syndrome', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '27' },
    { target: 'Freemartinism', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Urinary Bladder Fistula', source: 'Urinary Fistula', value: '19' },
    { target: 'Vesicovaginal Fistula', source: 'Urinary Bladder Fistula', value: '23' },
    { target: 'Genital Neoplasms, Female', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Urologic Neoplasms', source: 'Urogenital Neoplasms', value: '15' },
    { target: 'Fallopian Tube Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Ovarian Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Uterine Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Vaginal Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Vulvar Neoplasms', source: 'Genital Neoplasms, Female', value: '19' },
    { target: 'Brenner Tumor', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Carcinoma, Endometrioid', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Granulosa Cell Tumor', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Luteoma', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Meigs Syndrome', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Thecoma', source: 'Ovarian Neoplasms', value: '23' },
    { target: 'Endometrial Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Uterine Cervical Neoplasms', source: 'Uterine Neoplasms', value: '23' },
    { target: 'Carcinoma, Endometrioid', source: 'Endometrial Neoplasms', value: '27' },
    { target: 'Endometrial Stromal Tumors', source: 'Endometrial Neoplasms', value: '27' },
    { target: 'Sarcoma, Endometrial Stromal', source: 'Endometrial Stromal Tumors', value: '31' },
    { target: 'Kidney Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Ureteral Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Urethral Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Urinary Bladder Neoplasms', source: 'Urologic Neoplasms', value: '19' },
    { target: 'Carcinoma, Renal Cell', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Wilms Tumor', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Nephroma, Mesoblastic', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'Kidney Diseases', source: 'Urologic Diseases', value: '15' },
    { target: 'Ureteral Diseases', source: 'Urologic Diseases', value: '15' },
    { target: 'Urethral Diseases', source: 'Urologic Diseases', value: '15' },
    { target: 'Urinary Bladder Diseases', source: 'Urologic Diseases', value: '15' },
    { target: 'Urinary Tract Infections', source: 'Urologic Diseases', value: '15' },
    { target: 'Urination Disorders', source: 'Urologic Diseases', value: '15' },
    { target: 'Urolithiasis', source: 'Urologic Diseases', value: '15' },
    { target: 'AIDS-Associated Nephropathy', source: 'Kidney Diseases', value: '19' },
    { target: 'Anuria', source: 'Kidney Diseases', value: '19' },
    { target: 'Diabetes Insipidus', source: 'Kidney Diseases', value: '19' },
    { target: 'Diabetic Nephropathies', source: 'Kidney Diseases', value: '19' },
    { target: 'Hepatorenal Syndrome', source: 'Kidney Diseases', value: '19' },
    { target: 'Hydronephrosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Hyperoxaluria', source: 'Kidney Diseases', value: '19' },
    { target: 'Hypertension, Renal', source: 'Kidney Diseases', value: '19' },
    { target: 'Kidney Cortex Necrosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Kidney Diseases, Cystic', source: 'Kidney Diseases', value: '19' },
    { target: 'Kidney Neoplasms', source: 'Kidney Diseases', value: '19' },
    { target: 'Kidney Papillary Necrosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Nephritis', source: 'Kidney Diseases', value: '19' },
    { target: 'Nephrocalcinosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Nephrolithiasis', source: 'Kidney Diseases', value: '19' },
    { target: 'Nephrosclerosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Nephrosis', source: 'Kidney Diseases', value: '19' },
    { target: 'Perinephritis', source: 'Kidney Diseases', value: '19' },
    { target: 'Renal Artery Obstruction', source: 'Kidney Diseases', value: '19' },
    { target: 'Renal Insufficiency', source: 'Kidney Diseases', value: '19' },
    { target: 'Renal Nutcracker Syndrome', source: 'Kidney Diseases', value: '19' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Kidney Diseases', value: '19' },
    { target: 'Renal Tubular Transport, Inborn Errors', source: 'Kidney Diseases', value: '19' },
    { target: 'Tuberculosis, Renal', source: 'Kidney Diseases', value: '19' },
    { target: 'Uremia', source: 'Kidney Diseases', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Kidney Diseases', value: '19' },
    { target: 'Diabetes Insipidus, Nephrogenic', source: 'Diabetes Insipidus', value: '23' },
    { target: 'Diabetes Insipidus, Neurogenic', source: 'Diabetes Insipidus', value: '23' },
    { target: 'Wolfram Syndrome', source: 'Diabetes Insipidus', value: '23' },
    { target: 'Pyonephrosis', source: 'Hydronephrosis', value: '23' },
    { target: 'Hyperoxaluria, Primary', source: 'Hyperoxaluria', value: '23' },
    { target: 'Hypertension, Renovascular', source: 'Hypertension, Renal', value: '23' },
    { target: 'Medullary Sponge Kidney', source: 'Kidney Diseases, Cystic', value: '23' },
    { target: 'Multicystic Dysplastic Kidney', source: 'Kidney Diseases, Cystic', value: '23' },
    { target: 'Polycystic Kidney Diseases', source: 'Kidney Diseases, Cystic', value: '23' },
    { target: 'Polycystic Kidney, Autosomal Dominant', source: 'Polycystic Kidney Diseases', value: '27' },
    { target: 'Polycystic Kidney, Autosomal Recessive', source: 'Polycystic Kidney Diseases', value: '27' },
    { target: 'Carcinoma, Renal Cell', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Nephroma, Mesoblastic', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Wilms Tumor', source: 'Kidney Neoplasms', value: '23' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '27' },
    { target: 'Glomerulonephritis', source: 'Nephritis', value: '23' },
    { target: 'Nephritis, Hereditary', source: 'Nephritis', value: '23' },
    { target: 'Nephritis, Interstitial', source: 'Nephritis', value: '23' },
    { target: 'Pyelitis', source: 'Nephritis', value: '23' },
    { target: 'Anti-Glomerular Basement Membrane Disease', source: 'Glomerulonephritis', value: '27' },
    { target: 'Glomerulonephritis, IGA', source: 'Glomerulonephritis', value: '27' },
    { target: 'Glomerulonephritis, Membranoproliferative', source: 'Glomerulonephritis', value: '27' },
    { target: 'Glomerulonephritis, Membranous', source: 'Glomerulonephritis', value: '27' },
    { target: 'Glomerulosclerosis, Focal Segmental', source: 'Glomerulonephritis', value: '27' },
    { target: 'Lupus Nephritis', source: 'Glomerulonephritis', value: '27' },
    { target: 'Balkan Nephropathy', source: 'Nephritis, Interstitial', value: '27' },
    { target: 'Pyelonephritis', source: 'Nephritis, Interstitial', value: '27' },
    { target: 'Pyelonephritis, Xanthogranulomatous', source: 'Pyelonephritis', value: '31' },
    { target: 'Pyelocystitis', source: 'Pyelitis', value: '27' },
    { target: 'Pyelonephritis', source: 'Pyelitis', value: '27' },
    { target: 'Pyelonephritis, Xanthogranulomatous', source: 'Pyelonephritis', value: '31' },
    { target: 'Kidney Calculi', source: 'Nephrolithiasis', value: '23' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '27' },
    { target: 'Nephrosis, Lipoid', source: 'Nephrosis', value: '23' },
    { target: 'Nephrotic Syndrome', source: 'Nephrosis', value: '23' },
    { target: 'Acute Kidney Injury', source: 'Renal Insufficiency', value: '23' },
    { target: 'Cardio-Renal Syndrome', source: 'Renal Insufficiency', value: '23' },
    { target: 'Renal Insufficiency, Chronic', source: 'Renal Insufficiency', value: '23' },
    { target: 'Kidney Tubular Necrosis, Acute', source: 'Acute Kidney Injury', value: '27' },
    { target: 'Kidney Failure, Chronic', source: 'Renal Insufficiency, Chronic', value: '27' },
    { target: 'Frasier Syndrome', source: 'Kidney Failure, Chronic', value: '31' },
    { target: 'Acidosis, Renal Tubular', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Bartter Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Dent Disease', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Fanconi Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Gitelman Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Glycosuria, Renal', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Hypophosphatemia, Familial', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Liddle Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Pseudohypoaldosteronism', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Renal Aminoacidurias', source: 'Renal Tubular Transport, Inborn Errors', value: '23' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '27' },
    { target: 'Cystinuria', source: 'Renal Aminoacidurias', value: '27' },
    { target: 'Hartnup Disease', source: 'Renal Aminoacidurias', value: '27' },
    { target: 'Azotemia', source: 'Uremia', value: '23' },
    { target: 'Hemolytic-Uremic Syndrome', source: 'Uremia', value: '23' },
    { target: 'Atypical Hemolytic Uremic Syndrome', source: 'Hemolytic-Uremic Syndrome', value: '27' },
    { target: 'Ureteral Neoplasms', source: 'Ureteral Diseases', value: '19' },
    { target: 'Ureteral Obstruction', source: 'Ureteral Diseases', value: '19' },
    { target: 'Ureterocele', source: 'Ureteral Diseases', value: '19' },
    { target: 'Ureterolithiasis', source: 'Ureteral Diseases', value: '19' },
    { target: 'Ureteral Calculi', source: 'Ureterolithiasis', value: '23' },
    { target: 'Epispadias', source: 'Urethral Diseases', value: '19' },
    { target: 'Urethral Neoplasms', source: 'Urethral Diseases', value: '19' },
    { target: 'Urethral Obstruction', source: 'Urethral Diseases', value: '19' },
    { target: 'Urethritis', source: 'Urethral Diseases', value: '19' },
    { target: 'Urethral Stricture', source: 'Urethral Obstruction', value: '23' },
    { target: 'Urinary Bladder Neck Obstruction', source: 'Urethral Obstruction', value: '23' },
    { target: 'Bladder Exstrophy', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Cystitis', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Cystocele', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder Calculi', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder Fistula', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder Neck Obstruction', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder Neoplasms', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder, Neurogenic', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Urinary Bladder, Overactive', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Vesico-Ureteral Reflux', source: 'Urinary Bladder Diseases', value: '19' },
    { target: 'Cystitis, Interstitial', source: 'Cystitis', value: '23' },
    { target: 'Pyelocystitis', source: 'Cystitis', value: '23' },
    { target: 'Vesicovaginal Fistula', source: 'Urinary Bladder Fistula', value: '23' },
    { target: 'Bacteriuria', source: 'Urinary Tract Infections', value: '19' },
    { target: 'Pyuria', source: 'Urinary Tract Infections', value: '19' },
    { target: 'Schistosomiasis haematobia', source: 'Urinary Tract Infections', value: '19' },
    { target: 'Anuria', source: 'Urination Disorders', value: '19' },
    { target: 'Enuresis', source: 'Urination Disorders', value: '19' },
    { target: 'Glycosuria', source: 'Urination Disorders', value: '19' },
    { target: 'Hematuria', source: 'Urination Disorders', value: '19' },
    { target: 'Oliguria', source: 'Urination Disorders', value: '19' },
    { target: 'Polyuria', source: 'Urination Disorders', value: '19' },
    { target: 'Proteinuria', source: 'Urination Disorders', value: '19' },
    { target: 'Urinary Incontinence', source: 'Urination Disorders', value: '19' },
    { target: 'Urinary Retention', source: 'Urination Disorders', value: '19' },
    { target: 'Diurnal Enuresis', source: 'Enuresis', value: '23' },
    { target: 'Nocturnal Enuresis', source: 'Enuresis', value: '23' },
    { target: 'Glycosuria, Renal', source: 'Glycosuria', value: '23' },
    { target: 'Albuminuria', source: 'Proteinuria', value: '23' },
    { target: 'Hemoglobinuria', source: 'Proteinuria', value: '23' },
    { target: 'Urinary Incontinence, Stress', source: 'Urinary Incontinence', value: '23' },
    { target: 'Urinary Incontinence, Urge', source: 'Urinary Incontinence', value: '23' },
    { target: 'Nephrolithiasis', source: 'Urolithiasis', value: '19' },
    { target: 'Ureterolithiasis', source: 'Urolithiasis', value: '19' },
    { target: 'Urinary Calculi', source: 'Urolithiasis', value: '19' },
    { target: 'Kidney Calculi', source: 'Nephrolithiasis', value: '23' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '27' },
    { target: 'Ureteral Calculi', source: 'Ureterolithiasis', value: '23' },
    { target: 'Kidney Calculi', source: 'Urinary Calculi', value: '23' },
    { target: 'Ureteral Calculi', source: 'Urinary Calculi', value: '23' },
    { target: 'Urinary Bladder Calculi', source: 'Urinary Calculi', value: '23' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '27' },
    { target: 'Abortion, Spontaneous', source: 'Pregnancy Complications', value: '11' },
    { target: 'Chorea Gravidarum', source: 'Pregnancy Complications', value: '11' },
    { target: 'Diabetes, Gestational', source: 'Pregnancy Complications', value: '11' },
    { target: 'Fetal Death', source: 'Pregnancy Complications', value: '11' },
    { target: 'Fetal Diseases', source: 'Pregnancy Complications', value: '11' },
    { target: 'Hypertension, Pregnancy-Induced', source: 'Pregnancy Complications', value: '11' },
    { target: 'Maternal Death', source: 'Pregnancy Complications', value: '11' },
    { target: 'Morning Sickness', source: 'Pregnancy Complications', value: '11' },
    { target: 'Nuchal Cord', source: 'Pregnancy Complications', value: '11' },
    { target: 'Obstetric Labor Complications', source: 'Pregnancy Complications', value: '11' },
    { target: 'Oligohydramnios', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pelvic Floor Disorders', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pemphigoid Gestationis', source: 'Pregnancy Complications', value: '11' },
    { target: 'Perinatal Death', source: 'Pregnancy Complications', value: '11' },
    { target: 'Phenylketonuria, Maternal', source: 'Pregnancy Complications', value: '11' },
    { target: 'Placenta Diseases', source: 'Pregnancy Complications', value: '11' },
    { target: 'Polyhydramnios', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy Complications, Cardiovascular', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy Complications, Hematologic', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy Complications, Infectious', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy Complications, Neoplastic', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy in Diabetics', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy, Ectopic', source: 'Pregnancy Complications', value: '11' },
    { target: 'Pregnancy, Prolonged', source: 'Pregnancy Complications', value: '11' },
    { target: 'Prenatal Injuries', source: 'Pregnancy Complications', value: '11' },
    { target: 'Puerperal Disorders', source: 'Pregnancy Complications', value: '11' },
    { target: 'Abortion, Habitual', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Abortion, Incomplete', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Abortion, Missed', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Abortion, Septic', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Abortion, Threatened', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Abortion, Veterinary', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Embryo Loss', source: 'Abortion, Spontaneous', value: '15' },
    { target: 'Uterine Cervical Incompetence', source: 'Abortion, Habitual', value: '19' },
    { target: 'Fetal Macrosomia', source: 'Diabetes, Gestational', value: '15' },
    { target: 'Fetal Resorption', source: 'Fetal Death', value: '15' },
    { target: 'Stillbirth', source: 'Fetal Death', value: '15' },
    { target: 'Chorioamnionitis', source: 'Fetal Diseases', value: '15' },
    { target: 'Echogenic Bowel', source: 'Fetal Diseases', value: '15' },
    { target: 'Erythroblastosis, Fetal', source: 'Fetal Diseases', value: '15' },
    { target: 'Fetal Alcohol Spectrum Disorders', source: 'Fetal Diseases', value: '15' },
    { target: 'Fetal Growth Retardation', source: 'Fetal Diseases', value: '15' },
    { target: 'Fetal Hypoxia', source: 'Fetal Diseases', value: '15' },
    { target: 'Fetal Macrosomia', source: 'Fetal Diseases', value: '15' },
    { target: 'Fetal Nutrition Disorders', source: 'Fetal Diseases', value: '15' },
    { target: 'Meconium Aspiration Syndrome', source: 'Fetal Diseases', value: '15' },
    { target: 'Pyelectasis', source: 'Fetal Diseases', value: '15' },
    { target: 'Hydrops Fetalis', source: 'Erythroblastosis, Fetal', value: '19' },
    { target: 'Eclampsia', source: 'Hypertension, Pregnancy-Induced', value: '15' },
    { target: 'HELLP Syndrome', source: 'Hypertension, Pregnancy-Induced', value: '15' },
    { target: 'Pre-Eclampsia', source: 'Hypertension, Pregnancy-Induced', value: '15' },
    { target: 'Hyperemesis Gravidarum', source: 'Morning Sickness', value: '15' },
    { target: 'Abruptio Placentae', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Breech Presentation', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Cephalopelvic Disproportion', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Dystocia', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Fetal Membranes, Premature Rupture', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Obstetric Labor, Premature', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Placenta Accreta', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Placenta Previa', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Postpartum Hemorrhage', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Uterine Inversion', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Uterine Rupture', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Vasa Previa', source: 'Obstetric Labor Complications', value: '15' },
    { target: 'Uterine Inertia', source: 'Dystocia', value: '19' },
    { target: 'Chorioamnionitis', source: 'Fetal Membranes, Premature Rupture', value: '19' },
    { target: 'Premature Birth', source: 'Obstetric Labor, Premature', value: '19' },
    { target: 'Abruptio Placentae', source: 'Placenta Diseases', value: '15' },
    { target: 'Chorioamnionitis', source: 'Placenta Diseases', value: '15' },
    { target: 'Placenta Accreta', source: 'Placenta Diseases', value: '15' },
    { target: 'Placenta Previa', source: 'Placenta Diseases', value: '15' },
    { target: 'Placenta, Retained', source: 'Placenta Diseases', value: '15' },
    { target: 'Placental Insufficiency', source: 'Placenta Diseases', value: '15' },
    { target: 'Embolism, Amniotic Fluid', source: 'Pregnancy Complications, Cardiovascular', value: '15' },
    { target: 'Abortion, Septic', source: 'Pregnancy Complications, Infectious', value: '15' },
    { target: 'Pregnancy Complications, Parasitic', source: 'Pregnancy Complications, Infectious', value: '15' },
    { target: 'Puerperal Infection', source: 'Pregnancy Complications, Infectious', value: '15' },
    { target: 'Trophoblastic Neoplasms', source: 'Pregnancy Complications, Neoplastic', value: '15' },
    { target: 'Choriocarcinoma', source: 'Trophoblastic Neoplasms', value: '19' },
    { target: 'Gestational Trophoblastic Disease', source: 'Trophoblastic Neoplasms', value: '19' },
    { target: 'Choriocarcinoma, Non-gestational', source: 'Choriocarcinoma', value: '23' },
    { target: 'Trophoblastic Tumor, Placental Site', source: 'Choriocarcinoma', value: '23' },
    { target: 'Hydatidiform Mole', source: 'Gestational Trophoblastic Disease', value: '23' },
    { target: 'Hydatidiform Mole, Invasive', source: 'Hydatidiform Mole', value: '27' },
    { target: 'Fetal Macrosomia', source: 'Pregnancy in Diabetics', value: '15' },
    { target: 'Pregnancy, Abdominal', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Angular', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Cornual', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Heterotopic', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Ovarian', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Tubal', source: 'Pregnancy, Ectopic', value: '15' },
    { target: 'Pregnancy, Interstitial', source: 'Pregnancy, Tubal', value: '19' },
    { target: 'Prenatal Exposure Delayed Effects', source: 'Prenatal Injuries', value: '15' },
    { target: 'Depression, Postpartum', source: 'Puerperal Disorders', value: '15' },
    { target: 'Lactation Disorders', source: 'Puerperal Disorders', value: '15' },
    { target: 'Mastitis', source: 'Puerperal Disorders', value: '15' },
    { target: 'Postpartum Hemorrhage', source: 'Puerperal Disorders', value: '15' },
    { target: 'Postpartum Thyroiditis', source: 'Puerperal Disorders', value: '15' },
    { target: 'Pubic Symphysis Diastasis', source: 'Puerperal Disorders', value: '15' },
    { target: 'Puerperal Infection', source: 'Puerperal Disorders', value: '15' },
    { target: 'Galactorrhea', source: 'Lactation Disorders', value: '19' },
    { target: 'Chiari-Frommel Syndrome', source: 'Galactorrhea', value: '23' },
    { target: 'Granulomatous Mastitis', source: 'Mastitis', value: '19' },
    { target: 'Heart Defects, Congenital', source: 'Cardiovascular Abnormalities', value: '11' },
    { target: 'Vascular Malformations', source: 'Cardiovascular Abnormalities', value: '11' },
    { target: '22q11 Deletion Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Alagille Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Aortic Coarctation', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Arrhythmogenic Right Ventricular Dysplasia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Barth Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Cor Triatriatum', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Coronary Vessel Anomalies', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Crisscross Heart', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Dextrocardia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Ductus Arteriosus, Patent', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Ebstein Anomaly', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Ectopia Cordis', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Eisenmenger Complex', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Heart Septal Defects', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Heterotaxy Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Hypoplastic Left Heart Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'LEOPARD Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Levocardia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Marfan Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Noonan Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Tetralogy of Fallot', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Transposition of Great Vessels', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Tricuspid Atresia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Trilogy of Fallot', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Turner Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Bland White Garland Syndrome', source: 'Coronary Vessel Anomalies', value: '19' },
    { target: 'Myocardial Bridging', source: 'Coronary Vessel Anomalies', value: '19' },
    { target: 'Kartagener Syndrome', source: 'Dextrocardia', value: '19' },
    { target: 'Aortopulmonary Septal Defect', source: 'Heart Septal Defects', value: '19' },
    { target: 'Endocardial Cushion Defects', source: 'Heart Septal Defects', value: '19' },
    { target: 'Heart Septal Defects, Atrial', source: 'Heart Septal Defects', value: '19' },
    { target: 'Heart Septal Defects, Ventricular', source: 'Heart Septal Defects', value: '19' },
    { target: 'Truncus Arteriosus, Persistent', source: 'Aortopulmonary Septal Defect', value: '23' },
    { target: 'Foramen Ovale, Patent', source: 'Heart Septal Defects, Atrial', value: '23' },
    { target: 'Lutembacher Syndrome', source: 'Heart Septal Defects, Atrial', value: '23' },
    { target: 'Double Outlet Right Ventricle', source: 'Transposition of Great Vessels', value: '19' },
    { target: 'Arteriovenous Malformations', source: 'Vascular Malformations', value: '15' },
    { target: 'Central Nervous System Vascular Malformations', source: 'Vascular Malformations', value: '15' },
    { target: 'May-Thurner Syndrome', source: 'Vascular Malformations', value: '15' },
    { target: 'Pulmonary Atresia', source: 'Vascular Malformations', value: '15' },
    { target: 'Scimitar Syndrome', source: 'Vascular Malformations', value: '15' },
    { target: 'Single Umbilical Artery', source: 'Vascular Malformations', value: '15' },
    { target: 'Vascular Fistula', source: 'Vascular Malformations', value: '15' },
    { target: 'Arteriovenous Fistula', source: 'Arteriovenous Malformations', value: '19' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Arteriovenous Malformations', value: '19' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '23' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '23' },
    { target: 'Central Nervous System Venous Angioma', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Sinus Pericranii', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '23' },
    { target: 'Arterio-Arterial Fistula', source: 'Vascular Fistula', value: '19' },
    { target: 'Arteriovenous Fistula', source: 'Vascular Fistula', value: '19' },
    { target: 'Bland White Garland Syndrome', source: 'Arterio-Arterial Fistula', value: '23' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '23' },
    { target: 'Endocarditis, Bacterial', source: 'Cardiovascular Infections', value: '11' },
    { target: 'Syphilis, Cardiovascular', source: 'Cardiovascular Infections', value: '11' },
    { target: 'Tuberculosis, Cardiovascular', source: 'Cardiovascular Infections', value: '11' },
    { target: 'Endocarditis, Subacute Bacterial', source: 'Endocarditis, Bacterial', value: '15' },
    { target: 'Pericarditis, Tuberculous', source: 'Tuberculosis, Cardiovascular', value: '15' },
    { target: 'Arrhythmias, Cardiac', source: 'Heart Diseases', value: '11' },
    { target: 'Carcinoid Heart Disease', source: 'Heart Diseases', value: '11' },
    { target: 'Cardiac Output, High', source: 'Heart Diseases', value: '11' },
    { target: 'Cardiac Output, Low', source: 'Heart Diseases', value: '11' },
    { target: 'Cardiac Tamponade', source: 'Heart Diseases', value: '11' },
    { target: 'Cardiomegaly', source: 'Heart Diseases', value: '11' },
    { target: 'Cardiomyopathies', source: 'Heart Diseases', value: '11' },
    { target: 'Endocarditis', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Aneurysm', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Arrest', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Defects, Congenital', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Failure', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Neoplasms', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Rupture', source: 'Heart Diseases', value: '11' },
    { target: 'Heart Valve Diseases', source: 'Heart Diseases', value: '11' },
    { target: 'Myocardial Ischemia', source: 'Heart Diseases', value: '11' },
    { target: 'Myocardial Stunning', source: 'Heart Diseases', value: '11' },
    { target: 'Pericardial Effusion', source: 'Heart Diseases', value: '11' },
    { target: 'Pericarditis', source: 'Heart Diseases', value: '11' },
    { target: 'Pneumopericardium', source: 'Heart Diseases', value: '11' },
    { target: 'Postpericardiotomy Syndrome', source: 'Heart Diseases', value: '11' },
    { target: 'Pulmonary Heart Disease', source: 'Heart Diseases', value: '11' },
    { target: 'Rheumatic Heart Disease', source: 'Heart Diseases', value: '11' },
    { target: 'Ventricular Dysfunction', source: 'Heart Diseases', value: '11' },
    { target: 'Ventricular Outflow Obstruction', source: 'Heart Diseases', value: '11' },
    { target: 'Arrhythmia, Sinus', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Atrial Fibrillation', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Atrial Flutter', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Bradycardia', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Brugada Syndrome', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Cardiac Complexes, Premature', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Commotio Cordis', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Heart Block', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Long QT Syndrome', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Parasystole', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Pre-Excitation Syndromes', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Tachycardia', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Ventricular Fibrillation', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Ventricular Flutter', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Sick Sinus Syndrome', source: 'Arrhythmia, Sinus', value: '19' },
    { target: 'Sinus Arrest, Cardiac', source: 'Arrhythmia, Sinus', value: '19' },
    { target: 'Atrial Premature Complexes', source: 'Cardiac Complexes, Premature', value: '19' },
    { target: 'Ventricular Premature Complexes', source: 'Cardiac Complexes, Premature', value: '19' },
    { target: 'Adams-Stokes Syndrome', source: 'Heart Block', value: '19' },
    { target: 'Atrioventricular Block', source: 'Heart Block', value: '19' },
    { target: 'Bundle-Branch Block', source: 'Heart Block', value: '19' },
    { target: 'Sick Sinus Syndrome', source: 'Heart Block', value: '19' },
    { target: 'Sinoatrial Block', source: 'Heart Block', value: '19' },
    { target: 'Andersen Syndrome', source: 'Long QT Syndrome', value: '19' },
    { target: 'Jervell-Lange Nielsen Syndrome', source: 'Long QT Syndrome', value: '19' },
    { target: 'Romano-Ward Syndrome', source: 'Long QT Syndrome', value: '19' },
    { target: 'Lown-Ganong-Levine Syndrome', source: 'Pre-Excitation Syndromes', value: '19' },
    { target: 'Pre-Excitation, Mahaim-Type', source: 'Pre-Excitation Syndromes', value: '19' },
    { target: 'Wolff-Parkinson-White Syndrome', source: 'Pre-Excitation Syndromes', value: '19' },
    { target: 'Tachycardia, Paroxysmal', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Reciprocating', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Supraventricular', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Ventricular', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Atrioventricular Nodal Reentry', source: 'Tachycardia, Reciprocating', value: '23' },
    { target: 'Tachycardia, Sinoatrial Nodal Reentry', source: 'Tachycardia, Reciprocating', value: '23' },
    { target: 'Tachycardia, Ectopic Atrial', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Tachycardia, Ectopic Junctional', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Tachycardia, Sinus', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Accelerated Idioventricular Rhythm', source: 'Tachycardia, Ventricular', value: '23' },
    { target: 'Torsades de Pointes', source: 'Tachycardia, Ventricular', value: '23' },
    { target: 'Cardiomyopathy, Dilated', source: 'Cardiomegaly', value: '15' },
    { target: 'Hypertrophy, Left Ventricular', source: 'Cardiomegaly', value: '15' },
    { target: 'Hypertrophy, Right Ventricular', source: 'Cardiomegaly', value: '15' },
    { target: 'Arrhythmogenic Right Ventricular Dysplasia', source: 'Cardiomyopathies', value: '15' },
    { target: 'Cardiomyopathy, Alcoholic', source: 'Cardiomyopathies', value: '15' },
    { target: 'Cardiomyopathy, Dilated', source: 'Cardiomyopathies', value: '15' },
    { target: 'Cardiomyopathy, Hypertrophic', source: 'Cardiomyopathies', value: '15' },
    { target: 'Cardiomyopathy, Restrictive', source: 'Cardiomyopathies', value: '15' },
    { target: 'Chagas Cardiomyopathy', source: 'Cardiomyopathies', value: '15' },
    { target: 'Diabetic Cardiomyopathies', source: 'Cardiomyopathies', value: '15' },
    { target: 'Endocardial Fibroelastosis', source: 'Cardiomyopathies', value: '15' },
    { target: 'Endomyocardial Fibrosis', source: 'Cardiomyopathies', value: '15' },
    { target: 'Glycogen Storage Disease Type IIb', source: 'Cardiomyopathies', value: '15' },
    { target: 'Kearns-Sayre Syndrome', source: 'Cardiomyopathies', value: '15' },
    { target: 'Myocardial Reperfusion Injury', source: 'Cardiomyopathies', value: '15' },
    { target: 'Myocarditis', source: 'Cardiomyopathies', value: '15' },
    { target: 'Sarcoglycanopathies', source: 'Cardiomyopathies', value: '15' },
    { target: 'Cardiomyopathy, Hypertrophic, Familial', source: 'Cardiomyopathy, Hypertrophic', value: '19' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Endocardial Fibroelastosis', value: '19' },
    { target: 'Endocarditis, Bacterial', source: 'Endocarditis', value: '15' },
    { target: 'Endocarditis, Non-Infective', source: 'Endocarditis', value: '15' },
    { target: 'Endocarditis, Subacute Bacterial', source: 'Endocarditis, Bacterial', value: '19' },
    { target: 'Death, Sudden, Cardiac', source: 'Heart Arrest', value: '15' },
    { target: 'Out-of-Hospital Cardiac Arrest', source: 'Heart Arrest', value: '15' },
    { target: 'Karoshi Death', source: 'Death, Sudden, Cardiac', value: '19' },
    { target: '22q11 Deletion Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Aortic Coarctation', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Arrhythmogenic Right Ventricular Dysplasia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Barth Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Cor Triatriatum', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Coronary Vessel Anomalies', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Crisscrosseart', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Dextrocardia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Ductus Arteriosus, Patent', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Ebstein Anomaly', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Eisenmenger Complex', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Heart Septal Defects', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Heterotaxy Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Hypoplastic Left Heart Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'LEOPARD Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Levocardia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Marfan Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Noonan Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Tetralogy of Fallot', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Transposition of Great Vessels', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Tricuspid Atresia', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Trilogy of Fallot', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'Turner Syndrome', source: 'Heart Defects, Congenital', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Bland White Garland Syndrome', source: 'Coronary Vessel Anomalies', value: '19' },
    { target: 'Myocardial Bridging', source: 'Coronary Vessel Anomalies', value: '19' },
    { target: 'Kartagener Syndrome', source: 'Dextrocardia', value: '19' },
    { target: 'Aortopulmonary Septal Defect', source: 'Heart Septal Defects', value: '19' },
    { target: 'Endocardial Cushion Defects', source: 'Heart Septal Defects', value: '19' },
    { target: 'Heart Septal Defects, Atrial', source: 'Heart Septal Defects', value: '19' },
    { target: 'Heart Septal Defects, Ventricular', source: 'Heart Septal Defects', value: '19' },
    { target: 'Truncus Arteriosus, Persistent', source: 'Aortopulmonary Septal Defect', value: '23' },
    { target: 'Foramen Ovale, Patent', source: 'Heart Septal Defects, Atrial', value: '23' },
    { target: 'Lutembacher Syndrome', source: 'Heart Septal Defects, Atrial', value: '23' },
    { target: 'Double Outlet Right Ventricle', source: 'Transposition of Great Vessels', value: '19' },
    { target: 'Cardio-Renal Syndrome', source: 'Heart Failure', value: '15' },
    { target: 'Dyspnea, Paroxysmal', source: 'Heart Failure', value: '15' },
    { target: 'Edema, Cardiac', source: 'Heart Failure', value: '15' },
    { target: 'Heart Failure, Diastolic', source: 'Heart Failure', value: '15' },
    { target: 'Heart Failure, Systolic', source: 'Heart Failure', value: '15' },
    { target: 'Carney Complex', source: 'Heart Neoplasms', value: '15' },
    { target: 'Heart Rupture, Post-Infarction', source: 'Heart Rupture', value: '15' },
    { target: 'Ventricular Septal Rupture', source: 'Heart Rupture, Post-Infarction', value: '19' },
    { target: 'Aortic Valve Insufficiency', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Aortic Valve Stenosis', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Heart Valve Prolapse', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Mitral Valve Insufficiency', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Mitral Valve Stenosis', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Pulmonary Atresia', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Pulmonary Valve Insufficiency', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Pulmonary Valve Stenosis', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Tricuspid Atresia', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Tricuspid Valve Insufficiency', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Tricuspid Valve Stenosis', source: 'Heart Valve Diseases', value: '15' },
    { target: 'Aortic Stenosis, Subvalvular', source: 'Aortic Valve Stenosis', value: '19' },
    { target: 'Aortic Stenosis, Supravalvular', source: 'Aortic Valve Stenosis', value: '19' },
    { target: 'Cardiomyopathy, Hypertrophic', source: 'Aortic Stenosis, Subvalvular', value: '23' },
    { target: 'Discrete Subaortic Stenosis', source: 'Aortic Stenosis, Subvalvular', value: '23' },
    { target: 'Cardiomyopathy, Hypertrophic, Familial', source: 'Cardiomyopathy, Hypertrophic', value: '27' },
    { target: 'Williams Syndrome', source: 'Aortic Stenosis, Supravalvular', value: '23' },
    { target: 'Aortic Valve Prolapse', source: 'Heart Valve Prolapse', value: '19' },
    { target: 'Mitral Valve Prolapse', source: 'Heart Valve Prolapse', value: '19' },
    { target: 'Tricuspid Valve Prolapse', source: 'Heart Valve Prolapse', value: '19' },
    { target: 'LEOPARD Syndrome', source: 'Pulmonary Valve Stenosis', value: '19' },
    { target: 'Pulmonary Subvalvular Stenosis', source: 'Pulmonary Valve Stenosis', value: '19' },
    { target: 'Acute Coronary Syndrome', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Angina Pectoris', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Coronary Disease', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Myocardial Infarction', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Myocardial Reperfusion Injury', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Angina, Unstable', source: 'Angina Pectoris', value: '19' },
    { target: 'Angina, Stable', source: 'Angina Pectoris', value: '19' },
    { target: 'Microvascular Angina', source: 'Angina Pectoris', value: '19' },
    { target: 'Angina Pectoris, Variant', source: 'Angina, Unstable', value: '23' },
    { target: 'Coronary Aneurysm', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Artery Disease', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Occlusion', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Stenosis', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Thrombosis', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Vasospasm', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary-Subclavian Steal Syndrome', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Restenosis', source: 'Coronary Stenosis', value: '23' },
    { target: 'Anterior Wall Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Inferior Wall Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Non-ST Elevated Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Shock, Cardiogenic', source: 'Myocardial Infarction', value: '19' },
    { target: 'ST Elevation Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Pericarditis, Constrictive', source: 'Pericarditis', value: '15' },
    { target: 'Pericarditis, Tuberculous', source: 'Pericarditis', value: '15' },
    { target: 'Ventricular Dysfunction, Left', source: 'Ventricular Dysfunction', value: '15' },
    { target: 'Ventricular Dysfunction, Right', source: 'Ventricular Dysfunction', value: '15' },
    { target: 'Takotsubo Cardiomyopathy', source: 'Ventricular Dysfunction, Left', value: '19' },
    { target: 'Aortic Valve Stenosis', source: 'Ventricular Outflow Obstruction', value: '15' },
    { target: 'Pulmonary Valve Stenosis', source: 'Ventricular Outflow Obstruction', value: '15' },
    { target: 'Aortic Stenosis, Subvalvular', source: 'Aortic Valve Stenosis', value: '19' },
    { target: 'Aortic Stenosis, Supravalvular', source: 'Aortic Valve Stenosis', value: '19' },
    { target: 'Discrete Subaortic Stenosis', source: 'Aortic Stenosis, Subvalvular', value: '23' },
    { target: 'Pulmonary Subvalvular Stenosis', source: 'Pulmonary Valve Stenosis', value: '19' },
    { target: 'Embolism, Amniotic Fluid', source: 'Pregnancy Complications, Cardiovascular', value: '11' },
    { target: 'Aneurysm', source: 'Vascular Diseases', value: '11' },
    { target: 'Angiodysplasia', source: 'Vascular Diseases', value: '11' },
    { target: 'Angiomatosis', source: 'Vascular Diseases', value: '11' },
    { target: 'Angioedema', source: 'Vascular Diseases', value: '11' },
    { target: 'Aortic Diseases', source: 'Vascular Diseases', value: '11' },
    { target: 'Arterial Occlusive Diseases', source: 'Vascular Diseases', value: '11' },
    { target: 'Arteriovenous Malformations', source: 'Vascular Diseases', value: '11' },
    { target: 'Capillary Leak Syndrome', source: 'Vascular Diseases', value: '11' },
    { target: 'Cerebrovascular Disorders', source: 'Vascular Diseases', value: '11' },
    { target: 'Colitis, Ischemic', source: 'Vascular Diseases', value: '11' },
    { target: 'Compartment Syndromes', source: 'Vascular Diseases', value: '11' },
    { target: 'Diabetic Angiopathies', source: 'Vascular Diseases', value: '11' },
    { target: 'Embolism and Thrombosis', source: 'Vascular Diseases', value: '11' },
    { target: 'Hand-Arm Vibration Syndrome', source: 'Vascular Diseases', value: '11' },
    { target: 'Hemorrhoids', source: 'Vascular Diseases', value: '11' },
    { target: 'Hemostatic Disorders', source: 'Vascular Diseases', value: '11' },
    { target: 'Hepatic Veno-Occlusive Disease', source: 'Vascular Diseases', value: '11' },
    { target: 'Hyperemia', source: 'Vascular Diseases', value: '11' },
    { target: 'Hypertension', source: 'Vascular Diseases', value: '11' },
    { target: 'Hypotension', source: 'Vascular Diseases', value: '11' },
    { target: 'Mesenteric Ischemia', source: 'Vascular Diseases', value: '11' },
    { target: 'Myocardial Ischemia', source: 'Vascular Diseases', value: '11' },
    { target: 'Optic Neuropathy, Ischemic', source: 'Vascular Diseases', value: '11' },
    { target: 'Peripheral Vascular Diseases', source: 'Vascular Diseases', value: '11' },
    { target: 'Prehypertension', source: 'Vascular Diseases', value: '11' },
    { target: 'Pulmonary Veno-Occlusive Disease', source: 'Vascular Diseases', value: '11' },
    { target: 'Reperfusion Injury', source: 'Vascular Diseases', value: '11' },
    { target: 'Retinal Vein Occlusion', source: 'Vascular Diseases', value: '11' },
    { target: 'Scimitar Syndrome', source: 'Vascular Diseases', value: '11' },
    { target: 'Spinal Cord Vascular Diseases', source: 'Vascular Diseases', value: '11' },
    { target: 'Splenic Infarction', source: 'Vascular Diseases', value: '11' },
    { target: 'Stenosis, Pulmonary Vein', source: 'Vascular Diseases', value: '11' },
    { target: 'Superior Vena Cava Syndrome', source: 'Vascular Diseases', value: '11' },
    { target: 'Telangiectasis', source: 'Vascular Diseases', value: '11' },
    { target: 'Thoracic Outlet Syndrome', source: 'Vascular Diseases', value: '11' },
    { target: 'Varicocele', source: 'Vascular Diseases', value: '11' },
    { target: 'Varicose Veins', source: 'Vascular Diseases', value: '11' },
    { target: 'Vascular Fistula', source: 'Vascular Diseases', value: '11' },
    { target: 'Vascular Neoplasms', source: 'Vascular Diseases', value: '11' },
    { target: 'Vascular System Injuries', source: 'Vascular Diseases', value: '11' },
    { target: 'Vasculitis', source: 'Vascular Diseases', value: '11' },
    { target: 'Vasoplegia', source: 'Vascular Diseases', value: '11' },
    { target: 'Venous Insufficiency', source: 'Vascular Diseases', value: '11' },
    { target: 'Aneurysm, Dissecting', source: 'Aneurysm', value: '15' },
    { target: 'Aneurysm, False', source: 'Aneurysm', value: '15' },
    { target: 'Aneurysm, Infected', source: 'Aneurysm', value: '15' },
    { target: 'Aneurysm, Ruptured', source: 'Aneurysm', value: '15' },
    { target: 'Aortic Aneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Coronary Aneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Endoleak', source: 'Aneurysm', value: '15' },
    { target: 'Heart Aneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Iliac Aneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Intracranial Aneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Microaneurysm', source: 'Aneurysm', value: '15' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Aneurysm, Dissecting', value: '19' },
    { target: 'Loeys-Dietz Syndrome', source: 'Aneurysm, Dissecting', value: '19' },
    { target: 'Vertebral Artery Dissection', source: 'Aneurysm, Dissecting', value: '19' },
    { target: 'Aortic Rupture', source: 'Aneurysm, Ruptured', value: '19' },
    { target: 'Aortic Aneurysm, Abdominal', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Aortic Aneurysm, Thoracic', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Aortic Rupture', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Loeys-Dietz Syndrome', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Gastric Antral Vascular Ectasia', source: 'Angiodysplasia', value: '15' },
    { target: 'Angiomatosis, Bacillary', source: 'Angiomatosis', value: '15' },
    { target: 'Klippel-Trenaunay-Weber Syndrome', source: 'Angiomatosis', value: '15' },
    { target: 'Sturge-Weber Syndrome', source: 'Angiomatosis', value: '15' },
    { target: 'von Hippel-Lindau Disease', source: 'Angiomatosis', value: '15' },
    { target: 'Angioedemas, Hereditary', source: 'Angioedema', value: '15' },
    { target: 'Hereditary Angioedema Type III', source: 'Angioedemas, Hereditary', value: '19' },
    { target: 'Hereditary Angioedema Types I and II', source: 'Angioedemas, Hereditary', value: '19' },
    { target: 'Aortic Aneurysm', source: 'Aortic Diseases', value: '15' },
    { target: 'Aortic Arch Syndromes', source: 'Aortic Diseases', value: '15' },
    { target: 'Aortitis', source: 'Aortic Diseases', value: '15' },
    { target: 'Leriche Syndrome', source: 'Aortic Diseases', value: '15' },
    { target: 'Aortic Aneurysm, Abdominal', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Aortic Aneurysm, Thoracic', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Aortic Rupture', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Loeys-Dietz Syndrome', source: 'Aortic Aneurysm', value: '19' },
    { target: 'Takayasu Arteritis', source: 'Aortic Arch Syndromes', value: '19' },
    { target: 'Arteriosclerosis', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Carotid Stenosis', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Fibromuscular Dysplasia', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Leriche Syndrome', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Malignant Atrophic Papulosis', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Mesenteric Vascular Occlusion', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Moyamoya Disease', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Renal Artery Obstruction', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Retinal Artery Occlusion', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Stenosis, Pulmonary Artery', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Thromboangiitis Obliterans', source: 'Arterial Occlusive Diseases', value: '15' },
    { target: 'Arteriolosclerosis', source: 'Arteriosclerosis', value: '19' },
    { target: 'Arteriosclerosis Obliterans', source: 'Arteriosclerosis', value: '19' },
    { target: 'Atherosclerosis', source: 'Arteriosclerosis', value: '19' },
    { target: 'Coronary Artery Disease', source: 'Arteriosclerosis', value: '19' },
    { target: 'Intracranial Arteriosclerosis', source: 'Arteriosclerosis', value: '19' },
    { target: 'Intermittent Claudication', source: 'Arteriosclerosis', value: '19' },
    { target: 'Peripheral Arterial Disease', source: 'Atherosclerosis', value: '23' },
    { target: 'Dementia, Vascular', source: 'Intracranial Arteriosclerosis', value: '23' },
    { target: 'Susac Syndrome', source: 'Retinal Artery Occlusion', value: '19' },
    { target: 'Arteriovenous Fistula', source: 'Arteriovenous Malformations', value: '15' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Arteriovenous Malformations', value: '15' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '19' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '19' },
    { target: 'Basal Ganglia Cerebrovascular Disease', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Brain Ischemia', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Carotid Artery Diseases', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Cerebral Small Vessel Diseases', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Cerebrovascular Trauma', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Intracranial Arterial Diseases', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Intracranial Embolism and Thrombosis', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Intracranial Hemorrhages', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Leukomalacia, Periventricular', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Sneddon Syndrome', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Stroke', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Vascular Headaches', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Vasculitis, Central Nervous System', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Vasospasm, Intracranial', source: 'Cerebrovascular Disorders', value: '15' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Basal Ganglia Cerebrovascular Disease', value: '19' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '23' },
    { target: 'Brain Infarction', source: 'Brain Ischemia', value: '19' },
    { target: 'Hypoxia-Ischemia, Brain', source: 'Brain Ischemia', value: '19' },
    { target: 'Ischemic Attack, Transient', source: 'Brain Ischemia', value: '19' },
    { target: 'Vertebrobasilar Insufficiency', source: 'Brain Ischemia', value: '19' },
    { target: 'Brain Stem Infarctions', source: 'Brain Infarction', value: '23' },
    { target: 'Cerebral Infarction', source: 'Brain Infarction', value: '23' },
    { target: 'Lateral Medullary Syndrome', source: 'Brain Stem Infarctions', value: '27' },
    { target: 'Dementia, Multi-Infarct', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'Subclavian Steal Syndrome', source: 'Vertebrobasilar Insufficiency', value: '23' },
    { target: 'Carotid Artery Injuries', source: 'Carotid Artery Diseases', value: '19' },
    { target: 'Carotid Artery Thrombosis', source: 'Carotid Artery Diseases', value: '19' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Diseases', value: '19' },
    { target: 'Carotid Stenosis', source: 'Carotid Artery Diseases', value: '19' },
    { target: 'Moyamoya Disease', source: 'Carotid Artery Diseases', value: '19' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '23' },
    { target: 'CADASIL', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'Fabry Disease', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'MELAS Syndrome', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'Microscopic Polyangiitis', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'Stroke, Lacunar', source: 'Cerebral Small Vessel Diseases', value: '19' },
    { target: 'Carotid Artery Injuries', source: 'Cerebrovascular Trauma', value: '19' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Cerebrovascular Trauma', value: '19' },
    { target: 'Vertebral Artery Dissection', source: 'Cerebrovascular Trauma', value: '19' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '23' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Injuries', value: '23' },
    { target: 'Cerebral Arterial Diseases', source: 'Intracranial Arterial Diseases', value: '19' },
    { target: 'Intracranial Aneurysm', source: 'Intracranial Arterial Diseases', value: '19' },
    { target: 'Intracranial Arteriosclerosis', source: 'Intracranial Arterial Diseases', value: '19' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Intracranial Arterial Diseases', value: '19' },
    { target: 'CADASIL', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Moyamoya Disease', source: 'Cerebral Arterial Diseases', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Cerebral Amyloid Angiopathy', value: '27' },
    { target: 'Dementia, Vascular', source: 'Intracranial Arteriosclerosis', value: '23' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '23' },
    { target: 'Carotid Artery Thrombosis', source: 'Intracranial Embolism and Thrombosis', value: '19' },
    { target: 'Intracranial Embolism', source: 'Intracranial Embolism and Thrombosis', value: '19' },
    { target: 'Intracranial Thrombosis', source: 'Intracranial Embolism and Thrombosis', value: '19' },
    { target: 'Sinus Thrombosis, Intracranial', source: 'Intracranial Thrombosis', value: '23' },
    { target: 'Cavernous Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '27' },
    { target: 'Lateral Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '27' },
    { target: 'Sagittal Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '27' },
    { target: 'Cerebral Hemorrhage', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Intracranial Hemorrhage, Hypertensive', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Intracranial Hemorrhage, Traumatic', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Pituitary Apoplexy', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Subarachnoid Hemorrhage', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Cerebral Hemorrhage', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Cerebral Hemorrhage', value: '23' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '27' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '23' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Intracranial Hemorrhage, Traumatic', value: '23' },
    { target: 'Hematoma, Subdural', source: 'Intracranial Hemorrhage, Traumatic', value: '23' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '23' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '27' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '27' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '27' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '27' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '27' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Subarachnoid Hemorrhage', value: '23' },
    { target: 'Brain Infarction', source: 'Stroke', value: '19' },
    { target: 'Stroke, Lacunar', source: 'Stroke', value: '19' },
    { target: 'Brain Stem Infarctions', source: 'Brain Infarction', value: '23' },
    { target: 'Cerebral Infarction', source: 'Brain Infarction', value: '23' },
    { target: 'Lateral Medullary Syndrome', source: 'Brain Stem Infarctions', value: '27' },
    { target: 'Dementia, Multi-Infarct', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Anterior Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Middle Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'Infarction, Posterior Cerebral Artery', source: 'Cerebral Infarction', value: '27' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Giant Cell Arteritis', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Anterior Compartment Syndrome', source: 'Compartment Syndromes', value: '15' },
    { target: 'Intra-Abdominal Hypertension', source: 'Compartment Syndromes', value: '15' },
    { target: 'Ischemic Contracture', source: 'Compartment Syndromes', value: '15' },
    { target: 'Diabetic Foot', source: 'Diabetic Angiopathies', value: '15' },
    { target: 'Diabetic Retinopathy', source: 'Diabetic Angiopathies', value: '15' },
    { target: 'Embolism', source: 'Embolism and Thrombosis', value: '15' },
    { target: 'Thromboembolism', source: 'Embolism and Thrombosis', value: '15' },
    { target: 'Thrombosis', source: 'Embolism and Thrombosis', value: '15' },
    { target: 'Embolism, Air', source: 'Embolism', value: '19' },
    { target: 'Embolism, Amniotic Fluid', source: 'Embolism', value: '19' },
    { target: 'Embolism, Fat', source: 'Embolism', value: '19' },
    { target: 'Pulmonary Embolism', source: 'Embolism', value: '19' },
    { target: 'Embolism, Cholesterol', source: 'Embolism, Fat', value: '23' },
    { target: 'Blue Toe Syndrome', source: 'Embolism, Cholesterol', value: '27' },
    { target: 'Pulmonary Infarction', source: 'Pulmonary Embolism', value: '23' },
    { target: 'Intracranial Embolism and Thrombosis', source: 'Thromboembolism', value: '19' },
    { target: 'Embolism, Paradoxical', source: 'Thromboembolism', value: '19' },
    { target: 'Venous Thromboembolism', source: 'Thromboembolism', value: '19' },
    { target: 'Carotid Artery Thrombosis', source: 'Intracranial Embolism and Thrombosis', value: '23' },
    { target: 'Intracranial Embolism', source: 'Intracranial Embolism and Thrombosis', value: '23' },
    { target: 'Intracranial Thrombosis', source: 'Intracranial Embolism and Thrombosis', value: '23' },
    { target: 'Sinus Thrombosis, Intracranial', source: 'Intracranial Thrombosis', value: '27' },
    { target: 'Cavernous Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Lateral Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Sagittal Sinus Thrombosis', source: 'Sinus Thrombosis, Intracranial', value: '31' },
    { target: 'Coronary Thrombosis', source: 'Thrombosis', value: '19' },
    { target: 'Venous Thrombosis', source: 'Thrombosis', value: '19' },
    { target: 'Budd-Chiari Syndrome', source: 'Venous Thrombosis', value: '23' },
    { target: 'Postthrombotic Syndrome', source: 'Venous Thrombosis', value: '23' },
    { target: 'Retinal Vein Occlusion', source: 'Venous Thrombosis', value: '23' },
    { target: 'Thrombophlebitis', source: 'Venous Thrombosis', value: '23' },
    { target: 'Upper Extremity Deep Vein Thrombosis', source: 'Venous Thrombosis', value: '23' },
    { target: 'Lemierre Syndrome', source: 'Thrombophlebitis', value: '27' },
    { target: 'Cryoglobulinemia', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Hemangioma, Cavernous', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Multiple Myeloma', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Purpura, Hyperglobulinemic', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Scurvy', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Shwartzman Phenomenon', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Telangiectasia, Hereditary Hemorrhagic', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Hemostatic Disorders', value: '15' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Hemangioma, Cavernous', value: '19' },
    { target: 'Hypertension, Malignant', source: 'Hypertension', value: '15' },
    { target: 'Hypertension, Pregnancy-Induced', source: 'Hypertension', value: '15' },
    { target: 'Hypertension, Renal', source: 'Hypertension', value: '15' },
    { target: 'Hypertensive Retinopathy', source: 'Hypertension', value: '15' },
    { target: 'Masked Hypertension', source: 'Hypertension', value: '15' },
    { target: 'White Coat Hypertension', source: 'Hypertension', value: '15' },
    { target: 'Hypertension, Renovascular', source: 'Hypertension, Renal', value: '19' },
    { target: 'Hypotension, Orthostatic', source: 'Hypotension', value: '15' },
    { target: 'Post-Exercise Hypotension', source: 'Hypotension', value: '15' },
    { target: 'Shy-Drager Syndrome', source: 'Hypotension', value: '15' },
    { target: 'Acute Coronary Syndrome', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Angina Pectoris', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Coronary Disease', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Myocardial Infarction', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Myocardial Reperfusion Injury', source: 'Myocardial Ischemia', value: '15' },
    { target: 'Angina, Unstable', source: 'Angina Pectoris', value: '19' },
    { target: 'Angina, Stable', source: 'Angina Pectoris', value: '19' },
    { target: 'Microvascular Angina', source: 'Angina Pectoris', value: '19' },
    { target: 'Angina Pectoris, Variant', source: 'Angina, Unstable', value: '23' },
    { target: 'Coronary Aneurysm', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Artery Disease', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Occlusion', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Stenosis', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Thrombosis', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Vasospasm', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary-Subclavian Steal Syndrome', source: 'Coronary Disease', value: '19' },
    { target: 'Coronary Restenosis', source: 'Coronary Stenosis', value: '23' },
    { target: 'Anterior Wall Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Inferior Wall Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'No-Reflow Phenomenon', source: 'Myocardial Infarction', value: '19' },
    { target: 'Non-ST Elevated Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Shock, Cardiogenic', source: 'Myocardial Infarction', value: '19' },
    { target: 'ST Elevation Myocardial Infarction', source: 'Myocardial Infarction', value: '19' },
    { target: 'Blue Toe Syndrome', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Erythromelalgia', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Livedo Reticularis', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'May-Thurner Syndrome', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Peripheral Arterial Disease', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Phlebitis', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Raynaud Disease', source: 'Peripheral Vascular Diseases', value: '15' },
    { target: 'Postphlebitic Syndrome', source: 'Phlebitis', value: '19' },
    { target: 'Thrombophlebitis', source: 'Phlebitis', value: '19' },
    { target: 'Lemierre Syndrome', source: 'Thrombophlebitis', value: '23' },
    { target: 'CREST Syndrome', source: 'Raynaud Disease', value: '19' },
    { target: 'Myocardial Reperfusion Injury', source: 'Reperfusion Injury', value: '15' },
    { target: 'Primary Graft Dysfunction', source: 'Reperfusion Injury', value: '15' },
    { target: 'Spinal Cord Ischemia', source: 'Spinal Cord Vascular Diseases', value: '15' },
    { target: 'Anterior Spinal Artery Syndrome', source: 'Spinal Cord Ischemia', value: '19' },
    { target: 'Ataxia Telangiectasia', source: 'Telangiectasis', value: '15' },
    { target: 'CREST Syndrome', source: 'Telangiectasis', value: '15' },
    { target: 'Retinal Telangiectasis', source: 'Telangiectasis', value: '15' },
    { target: 'Telangiectasia, Hereditary Hemorrhagic', source: 'Telangiectasis', value: '15' },
    { target: 'Cervical Rib Syndrome', source: 'Thoracic Outlet Syndrome', value: '15' },
    { target: 'Varicose Ulcer', source: 'Varicose Veins', value: '15' },
    { target: 'Arterio-Arterial Fistula', source: 'Vascular Fistula', value: '15' },
    { target: 'Arteriovenous Fistula', source: 'Vascular Fistula', value: '15' },
    { target: 'Bland White Garland Syndrome', source: 'Arterio-Arterial Fistula', value: '19' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '19' },
    { target: 'Aortitis', source: 'Vasculitis', value: '15' },
    { target: 'Arteritis', source: 'Vasculitis', value: '15' },
    { target: 'Behcet Syndrome', source: 'Vasculitis', value: '15' },
    { target: 'Cogan Syndrome', source: 'Vasculitis', value: '15' },
    { target: 'Malignant Atrophic Papulosis', source: 'Vasculitis', value: '15' },
    { target: 'Mucocutaneous Lymph Node Syndrome', source: 'Vasculitis', value: '15' },
    { target: 'Phlebitis', source: 'Vasculitis', value: '15' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Vasculitis', value: '15' },
    { target: 'Retinal Vasculitis', source: 'Vasculitis', value: '15' },
    { target: 'Shwartzman Phenomenon', source: 'Vasculitis', value: '15' },
    { target: 'Systemic Vasculitis', source: 'Vasculitis', value: '15' },
    { target: 'Thromboangiitis Obliterans', source: 'Vasculitis', value: '15' },
    { target: 'Vasculitis, Central Nervous System', source: 'Vasculitis', value: '15' },
    { target: 'Vasculitis, Leukocytoclastic, Cutaneous', source: 'Vasculitis', value: '15' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'Arteritis', value: '19' },
    { target: 'Endarteritis', source: 'Arteritis', value: '19' },
    { target: 'Giant Cell Arteritis', source: 'Arteriti', value: '19' },
    { target: 'Polyarteritis Nodosa', source: 'Arteritis', value: '19' },
    { target: 'Takayasu Arteritis', source: 'Arteritis', value: '19' },
    { target: 'Thrombophlebitis', source: 'Phlebitis', value: '19' },
    { target: 'Lemierre Syndrome', source: 'Thrombophlebitis', value: '23' },
    { target: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', source: 'Systemic Vasculitis', value: '19' },
    { target: 'Polyarteritis Nodosa', source: 'Systemic Vasculitis', value: '19' },
    { target: 'Rheumatoid Vasculitis', source: 'Systemic Vasculitis', value: '19' },
    { target: 'Churg-Strauss Syndrome', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '23' },
    { target: 'Microscopic Polyangiitis', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '23' },
    { target: 'Granulomatosis with Polyangiitis', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '23' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Giant Cell Arteritis', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Postphlebitic Syndrome', source: 'Venous Insufficiency', value: '15' },
    { target: 'Postthrombotic Syndrome', source: 'Venous Insufficiency', value: '15' },
    { target: 'Anemia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Blood Coagulation Disorders', source: 'Hematologic Diseases', value: '11' },
    { target: 'Blood Platelet Disorders', source: 'Hematologic Diseases', value: '11' },
    { target: 'Blood Protein Disorders', source: 'Hematologic Diseases', value: '11' },
    { target: 'Bone Marrow Diseases', source: 'Hematologic Diseases', value: '11' },
    { target: 'Erythroblastosis, Fetal', source: 'Hematologic Diseases', value: '11' },
    { target: 'Hematologic Neoplasms', source: 'Hematologic Diseases', value: '11' },
    { target: 'Hemoglobinopathies', source: 'Hematologic Diseases', value: '11' },
    { target: 'Hemorrhagic Disorders', source: 'Hematologic Diseases', value: '11' },
    { target: 'Leukocyte Disorders', source: 'Hematologic Diseases', value: '11' },
    { target: 'Methemoglobinemia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Pancytopenia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Polycythemia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Pregnancy Complications, Hematologic', source: 'Hematologic Diseases', value: '11' },
    { target: 'Preleukemia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Sulfhemoglobinemia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Thrombophilia', source: 'Hematologic Diseases', value: '11' },
    { target: 'Transfusion Reaction', source: 'Hematologic Diseases', value: '11' },
    { target: 'Anemia, Aplastic', source: 'Anemia', value: '15' },
    { target: 'Anemia, Hemolytic', source: 'Anemia', value: '15' },
    { target: 'Anemia, Hypochromic', source: 'Anemia', value: '15' },
    { target: 'Anemia, Macrocytic', source: 'Anemia', value: '15' },
    { target: 'Anemia, Myelophthisic', source: 'Anemia', value: '15' },
    { target: 'Anemia, Neonatal', source: 'Anemia', value: '15' },
    { target: 'Anemia, Refractory', source: 'Anemia', value: '15' },
    { target: 'Anemia, Sideroblastic', source: 'Anemia', value: '15' },
    { target: 'Red-Cell Aplasia, Pure', source: 'Anemia', value: '15' },
    { target: 'Anemia, Hypoplastic, Congenital', source: 'Anemia, Aplastic', value: '19' },
    { target: 'Anemia, Diamond-Blackfan', source: 'Anemia, Hypoplastic, Congenital', value: '23' },
    { target: 'Fanconi Anemia', source: 'Anemia, Hypoplastic, Congenital', value: '23' },
    { target: 'Anemia, Hemolytic, Autoimmune', source: 'Anemia, Hemolytic', value: '19' },
    { target: 'Anemia, Hemolytic, Congenital', source: 'Anemia, Hemolytic', value: '19' },
    { target: 'Favism', source: 'Anemia, Hemolytic', value: '19' },
    { target: 'Hemoglobinuria, Paroxysmal', source: 'Anemia, Hemolytic', value: '19' },
    { target: 'Hemolytic-Uremic Syndrome', source: 'Anemia, Hemolytic', value: '19' },
    { target: 'Anemia, Dyserythropoietic, Congenital', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Anemia, Hemolytic, Congenital Nonspherocytic', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Anemia, Sickle Cell', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Elliptocytosis, Hereditary', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Glucosephosphate Dehydrogenase Deficiency', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Hemoglobin C Disease', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Spherocytosis, Hereditary', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Thalassemia', source: 'Anemia, Hemolytic, Congenital', value: '23' },
    { target: 'Acute Chest Syndrome', source: 'Anemia, Sickle Cell', value: '27' },
    { target: 'Hemoglobin SC Disease', source: 'Anemia, Sickle Cell', value: '27' },
    { target: 'Sickle Cell Trait', source: 'Anemia, Sickle Cell', value: '27' },
    { target: 'Favism', source: 'Glucosephosphate Dehydrogenase Deficiency', value: '27' },
    { target: 'alpha-Thalassemia', source: 'Thalassemia', value: '27' },
    { target: 'beta-Thalassemia', source: 'Thalassemia', value: '27' },
    { target: 'delta-Thalassemia', source: 'Thalassemia', value: '27' },
    { target: 'Atypical Hemolytic Uremic Syndrome', source: 'Hemolytic-Uremic Syndrome', value: '23' },
    { target: 'Anemia, Iron-Deficiency', source: 'Anemia, Hypochromic', value: '19' },
    { target: 'Anemia, Megaloblastic', source: 'Anemia, Macrocytic', value: '19' },
    { target: 'Anemia, Pernicious', source: 'Anemia, Megaloblastic', value: '23' },
    { target: 'Fetofetal Transfusion', source: 'Anemia, Neonatal', value: '19' },
    { target: 'Fetomaternal Transfusion', source: 'Anemia, Neonatal', value: '19' },
    { target: 'Anemia, Refractory, with Excess of Blasts', source: 'Anemia, Refractory', value: '19' },
    { target: 'Anemia, Diamond-Blackfan', source: 'Red-Cell Aplasia, Pure', value: '19' },
    { target: 'Blood Coagulation Disorders, Inherited', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Coagulation Protein Disorders', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Disseminated Intravascular Coagulation', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Ecchymosis', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Platelet Storage Pool Deficiency', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Protein S Deficiency', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Purpura', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Thrombocythemia, Essential', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Vitamin K Deficiency', source: 'Blood Coagulation Disorders', value: '15' },
    { target: 'Activated Protein C Resistance', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Afibrinogenemia', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Antithrombin III Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Bernard-Soulier Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor V Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor VII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor X Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor XI Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor XII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Factor XIII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Hemophilia A', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Hemophilia B', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Hypoprothrombinemias', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Protein C Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Thrombasthenia', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'von Willebrand Diseases', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '19' },
    { target: 'von Willebrand Disease, Type 1', source: 'von Willebrand Diseases', value: '23' },
    { target: 'von Willebrand Disease, Type 2', source: 'von Willebrand Diseases', value: '23' },
    { target: 'von Willebrand Disease, Type 3', source: 'von Willebrand Diseases', value: '23' },
    { target: 'Activated Protein C Resistance', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Afibrinogenemia', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor V Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor VII Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor X Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor XI Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor XII Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Factor XIII Deficiency', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Hemophilia A', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Hemophilia B', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'Hypoprothrombinemias', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'von Willebrand Diseases', source: 'Coagulation Protein Disorders', value: '19' },
    { target: 'von Willebrand Disease, Type 1', source: 'von Willebrand Diseases', value: '23' },
    { target: 'von Willebrand Disease, Type 2', source: 'von Willebrand Diseases', value: '23' },
    { target: 'von Willebrand Disease, Type 3', source: 'von Willebrand Diseases', value: '23' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Platelet Storage Pool Deficiency', value: '19' },
    { target: 'Purpura Fulminans', source: 'Purpura', value: '19' },
    { target: 'Purpura, Hyperglobulinemic', source: 'Purpura', value: '19' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic', source: 'Purpura', value: '19' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Purpura, Thrombotic Thrombocytopenic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Vitamin K Deficiency Bleeding', source: 'Vitamin K Deficiency', value: '19' },
    { target: 'Bernard-Soulier Syndrome', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Gray Platelet Syndrome', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Platelet Storage Pool Deficiency', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Thrombasthenia', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Thrombocytopenia', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Thrombocytosis', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'von Willebrand Diseases', source: 'Blood Platelet Disorders', value: '15' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Platelet Storage Pool Deficiency', value: '19' },
    { target: 'Jacobsen Distal 11q Deletion Syndrome', source: 'Thrombocytopenia', value: '19' },
    { target: 'Kasabach-Merritt Syndrome', source: 'Thrombocytopenia', value: '19' },
    { target: 'Thrombocytopenia, Neonatal Alloimmune', source: 'Thrombocytopenia', value: '19' },
    { target: 'Thrombotic Microangiopathies', source: 'Thrombocytopenia', value: '19' },
    { target: 'Hemolytic-Uremic Syndrome', source: 'Thrombotic Microangiopathies', value: '23' },
    { target: 'Purpura, Thrombocytopenic', source: 'Thrombotic Microangiopathies', value: '23' },
    { target: 'Atypical Hemolytic Uremic Syndrome', source: 'Hemolytic-Uremic Syndrome', value: '27' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Purpura, Thrombocytopenic', value: '27' },
    { target: 'Purpura, Thrombotic Thrombocytopenic', source: 'Purpura, Thrombocytopenic', value: '27' },
    { target: 'Thrombocythemia, Essential', source: 'Thrombocytosis', value: '19' },
    { target: 'Agammaglobulinemia', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Antithrombin III Deficiency', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Dysgammaglobulinemia', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Hypergammaglobulinemia', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Hypoproteinemia', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Paraproteinemias', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Protein C Deficiency', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Protein S Deficiency', source: 'Blood Protein Disorders', value: '15' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome', source: 'Dysgammaglobulinemia', value: '19' },
    { target: 'IgA Deficiency', source: 'Dysgammaglobulinemia', value: '19' },
    { target: 'IgG Deficiency', source: 'Dysgammaglobulinemia', value: '19' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome, Type 1', source: 'Hyper-IgM Immunodeficiency Syndrome', value: '23' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Hypergammaglobulinemia', value: '19' },
    { target: 'Monoclonal Gammopathy of Undetermined Significance', source: 'Hypergammaglobulinemia', value: '19' },
    { target: 'Hypoalbuminemia', source: 'Hypoproteinemia', value: '19' },
    { target: 'Cryoglobulinemia', source: 'Paraproteinemias', value: '19' },
    { target: 'Heavy Chain Disease', source: 'Paraproteinemias', value: '19' },
    { target: 'Multiple Myeloma', source: 'Paraproteinemias', value: '19' },
    { target: 'POEMS Syndrome', source: 'Paraproteinemias', value: '19' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Paraproteinemias', value: '19' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Heavy Chain Disease', value: '23' },
    { target: 'Anemia, Aplastic', source: 'Bone Marrow Diseases', value: '15' },
    { target: 'Bone Marrow Neoplasms', source: 'Bone Marrow Diseases', value: '15' },
    { target: 'Myelodysplastic-Myeloproliferative Diseases', source: 'Bone Marrow Diseases', value: '15' },
    { target: 'Myelodysplastic Syndromes', source: 'Bone Marrow Diseases', value: '15' },
    { target: 'Myeloproliferative Disorders', source: 'Bone Marrow Diseases', value: '15' },
    { target: 'Anemia, Hypoplastic, Congenital', source: 'Anemia, Aplastic', value: '19' },
    { target: 'Anemia, Diamond-Blackfan', source: 'Anemia, Hypoplastic, Congenital', value: '23' },
    { target: 'Fanconi Anemia', source: 'Anemia, Hypoplastic, Congenital', value: '23' },
    { target: 'Polycythemia Vera', source: 'Bone Marrow Neoplasms', value: '19' },
    { target: 'Leukemia, Myeloid, Chronic, Atypical, BCR-ABL Negative', source: 'Myelodysplastic-Myeloproliferative Diseases', value: '19' },
    { target: 'Leukemia, Myelomonocytic, Chronic', source: 'Myelodysplastic-Myeloproliferative Diseases', value: '19' },
    { target: 'Leukemia, Myelomonocytic, Juvenile', source: 'Myelodysplastic-Myeloproliferative Diseases', value: '19' },
    { target: 'Anemia, Refractory', source: 'Myelodysplastic Syndromes', value: '19' },
    { target: 'Anemia, Sideroblastic', source: 'Myelodysplastic Syndromes', value: '19' },
    { target: 'Hemoglobinuria, Paroxysmal', source: 'Myelodysplastic Syndromes', value: '19' },
    { target: 'Anemia, Refractory, with Excess of Blasts', source: 'Anemia, Refractory', value: '23' },
    { target: 'Anemia, Myelophthisic', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Leukemia, Erythroblastic, Acute', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Leukemia, Neutrophilic, Chronic', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Leukemoid Reaction', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Polycythemia Vera', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Primary Myelofibrosis', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Thrombocytosis', source: 'Myeloproliferative Disorders', value: '19' },
    { target: 'Blast Crisis', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Leukemia, Myeloid, Accelerated Phase', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Leukemia, Myeloid, Chronic-Phase', source: 'Leukemia, Myelogenous, Chronic, BCR-ABL Positive', value: '23' },
    { target: 'Thrombocythemia, Essential', source: 'Thrombocytosis', value: '23' },
    { target: 'Hydrops Fetalis', source: 'Erythroblastosis, Fetal', value: '15' },
    { target: 'Kernicterus', source: 'Erythroblastosis, Fetal', value: '15' },
    { target: 'Bone Marrow Neoplasms', source: 'Hematologic Neoplasms', value: '15' },
    { target: 'Polycythemia Vera', source: 'Bone Marrow Neoplasms', value: '19' },
    { target: 'Anemia, Sickle Cell', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Hemoglobin C Disease', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Thalassemia', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Acute Chest Syndrome', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Hemoglobin SC Disease', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Sickle Cell Trait', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'alpha-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'beta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'delta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'Hydrops Fetalis', source: 'alpha-Thalassemia', value: '23' },
    { target: 'Afibrinogenemia', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Bernard-Soulier Syndrome', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Disseminated Intravascular Coagulation', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor V Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor VII Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor X Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor XI Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor XII Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Factor XIII Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Hemophilia A', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Hemophilia B', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Hemostatic Disorders', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Hypoprothrombinemias', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Platelet Storage Pool Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Thrombasthenia', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Thrombocythemia, Essential', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Vitamin K Deficiency', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'von Willebrand Diseases', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Hemorrhagic Disorders', value: '15' },
    { target: 'Cryoglobulinemia', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Hemangioma, Cavernous', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Multiple Myeloma', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Purpura, Hyperglobulinemic', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Scurvy', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Shwartzman Phenomenon', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Telangiectasia, Hereditary Hemorrhagic', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Hemostatic Disorders', value: '19' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Hemangioma, Cavernous', value: '23' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Platelet Storage Pool Deficiency', value: '19' },
    { target: 'Vitamin K Deficiency Bleeding', source: 'Vitamin K Deficiency', value: '19' },
    { target: 'von Willebrand Disease, Type 1', source: 'von Willebrand Diseases', value: '19' },
    { target: 'von Willebrand Disease, Type 2', source: 'von Willebrand Diseases', value: '19' },
    { target: 'von Willebrand Disease, Type 3', source: 'von Willebrand Diseases', value: '19' },
    { target: 'Eosinophilia', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Infectious Mononucleosis', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Leukocytosis', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Leukopenia', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Leukostasis', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Pelger-Huet Anomaly', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Phagocyte Bactericidal Dysfunction', source: 'Leukocyte Disorders', value: '15' },
    { target: 'Angiolymphoid Hyperplasia with Eosinophilia', source: 'Eosinophilia', value: '19' },
    { target: 'Eosinophilia-Myalgia Syndrome', source: 'Eosinophilia', value: '19' },
    { target: 'Eosinophilic Esophagitis', source: 'Eosinophilia', value: '19' },
    { target: 'Eosinophilic Granuloma', source: 'Eosinophilia', value: '19' },
    { target: 'Hypereosinophilic Syndrome', source: 'Eosinophilia', value: '19' },
    { target: 'Pulmonary Eosinophilia', source: 'Hypereosinophilic Syndrome', value: '23' },
    { target: 'Leukemoid Reaction', source: 'Leukocytosis', value: '19' },
    { target: 'Lymphocytosis', source: 'Leukocytosis', value: '19' },
    { target: 'Agranulocytosis', source: 'Leukopenia', value: '19' },
    { target: 'Lymphopenia', source: 'Leukopenia', value: '19' },
    { target: 'Neutropenia', source: 'Agranulocytosis', value: '23' },
    { target: 'Febrile Neutropenia', source: 'Neutropenia', value: '27' },
    { target: 'Chemotherapy-Induced Febrile Neutropenia', source: 'Febrile Neutropenia', value: '31' },
    { target: 'T-Lymphocytopenia, Idiopathic CD4-Positive', source: 'Lymphopenia', value: '23' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Lymphopenia', value: '23' },
    { target: 'Chediak-Higashi Syndrome', source: 'Phagocyte Bactericidal Dysfunction', value: '19' },
    { target: 'Granulomatous Disease, Chronic', source: 'Phagocyte Bactericidal Dysfunction', value: '19' },
    { target: 'Job Syndrome', source: 'Phagocyte Bactericidal Dysfunction', value: '19' },
    { target: 'Activated Protein C Resistance', source: 'Thrombophilia', value: '15' },
    { target: 'Antithrombin III Deficiency', source: 'Thrombophilia', value: '15' },
    { target: 'Disseminated Intravascular Coagulation', source: 'Thrombophilia', value: '15' },
    { target: 'Protein C Deficiency', source: 'Thrombophilia', value: '15' },
    { target: 'Protein S Deficiency', source: 'Thrombophilia', value: '15' },
    { target: 'Purpura, Thrombotic Thrombocytopenic', source: 'Thrombophilia', value: '15' },
    { target: 'Histiocytosis', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphadenitis', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphadenopathy', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphangiectasis', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphangitis', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphatic Abnormalities', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphedema', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphocele', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Lymphoproliferative Disorders', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Mucocutaneous Lymph Node Syndrome', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Pseudolymphoma', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Splenic Diseases', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Thymus Hyperplasia', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Thymus Neoplasms', source: 'Lymphatic Diseases', value: '11' },
    { target: 'Histiocytic Disorders, Malignant', source: 'Histiocytosis', value: '15' },
    { target: 'Histiocytosis, Langerhans-Cell', source: 'Histiocytosis', value: '15' },
    { target: 'Histiocytosis, Non-Langerhans-Cell', source: 'Histiocytosis', value: '15' },
    { target: 'Dendritic Cell Sarcoma, Follicular', source: 'Histiocytic Disorders, Malignant', value: '19' },
    { target: 'Dendritic Cell Sarcoma, Interdigitating', source: 'Histiocytic Disorders, Malignant', value: '19' },
    { target: 'Histiocytic Sarcoma', source: 'Histiocytic Disorders, Malignant', value: '19' },
    { target: 'Langerhans Cell Sarcoma', source: 'Histiocytic Disorders, Malignant', value: '19' },
    { target: 'Eosinophilic Granuloma', source: 'Histiocytosis, Langerhans-Cell', value: '19' },
    { target: 'Erdheim-Chester Disease', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Histiocytosis, Sinus', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Lymphohistiocytosis, Hemophagocytic', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Niemann-Pick Diseases', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Xanthogranuloma, Juvenile', source: 'Histiocytosis, Non-Langerhans-Cell', value: '19' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '23' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '23' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '23' },
    { target: 'Cat-Scratch Disease', source: 'Lymphadenitis', value: '15' },
    { target: 'Histiocytic Necrotizing Lymphadenitis', source: 'Lymphadenitis', value: '15' },
    { target: 'Mesenteric Lymphadenitis', source: 'Lymphadenitis', value: '15' },
    { target: 'Immunoblastic Lymphadenopathy', source: 'Lymphadenopathy', value: '15' },
    { target: 'Lymphangiectasis, Intestinal', source: 'Lymphangiectasis', value: '15' },
    { target: '22q11 Deletion Syndrome', source: 'Lymphatic Abnormalities', value: '15' },
    { target: 'Lymphangiectasis, Intestinal', source: 'Lymphatic Abnormalities', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Breast Cancer Lymphedema', source: 'Lymphedema', value: '15' },
    { target: 'Elephantiasis', source: 'Lymphedema', value: '15' },
    { target: 'Elephantiasis, Filarial', source: 'Lymphedema', value: '15' },
    { target: 'Non-Filarial Lymphedema', source: 'Lymphedema', value: '15' },
    { target: 'Agammaglobulinemia', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Autoimmune Lymphoproliferative Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Giant Lymph Node Hyperplasia', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Granuloma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Heavy Chain Disease', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Immunoblastic Lymphadenopathy', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Infectious Mononucleosis', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Leukemia, Hairy Cell', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Leukemia, Lymphoid', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Lymphangiomyoma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Lymphoma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Marek Disease', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Sarcoidosis', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Sezary Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Tumor Lysis Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Angiolymphoid Hyperplasia with Eosinophilia', source: 'Granuloma', value: '19' },
    { target: 'Churg-Strauss Syndrome', source: 'Granuloma', value: '19' },
    { target: 'Necrobiotic Xanthogranuloma', source: 'Granuloma', value: '19' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Heavy Chain Disease', value: '19' },
    { target: 'Leukemia, B-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Biphenotypic, Acute', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Prolymphocytic', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, T-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Lymphocytic, Chronic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Large Granular Lymphocytic', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia-Lymphoma, Adult T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Precursor B-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Precursor T-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Lymphangioleiomyomatosis', source: 'Lymphangiomyoma', value: '19' },
    { target: 'Composite Lymphoma', source: 'Lymphoma', value: '19' },
    { target: 'Hodgkin Disease', source: 'Lymphoma', value: '19' },
    { target: 'Intraocular Lymphoma', source: 'Lymphoma', value: '19' },
    { target: 'Lymphoma, Non-Hodgkin', source: 'Lymphoma', value: '19' },
    { target: 'Lymphoma, B-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Follicular', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Large-Cell, Immunoblastic', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Mantle-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, T-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Burkitt Lymphoma', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, AIDS-Related', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, B-Cell, Marginal Zone', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, Large B-Cell, Diffuse', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, Primary Effusion', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphomatoid Granulomatosis', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Plasmablastic Lymphoma', source: 'Lymphoma, Large B-Cell, Diffuse', value: '31' },
    { target: 'Enteropathy-Associated T-Cell Lymphoma', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, Large-Cell, Anaplastic', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, T-Cell, Cutaneous', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, T-Cell, Peripheral', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, Primary Cutaneous Anaplastic Large Cell', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Lymphomatoid Papulosis', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Mycosis Fungoides', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Sezary Syndrome', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Pagetoid Reticulosis', source: 'Mycosis Fungoides', value: '35' },
    { target: 'Sarcoidosis, Pulmonary', source: 'Sarcoidosis', value: '19' },
    { target: 'Uveoparotid Fever', source: 'Sarcoidosis', value: '19' },
    { target: 'Heterotaxy Syndrome', source: 'Splenic Diseases', value: '15' },
    { target: 'Hypersplenism', source: 'Splenic Diseases', value: '15' },
    { target: 'Splenic Infarction', source: 'Splenic Diseases', value: '15' },
    { target: 'Splenic Neoplasms', source: 'Splenic Diseases', value: '15' },
    { target: 'Splenic Rupture', source: 'Splenic Diseases', value: '15' },
    { target: 'Tuberculosis, Splenic', source: 'Splenic Diseases', value: '15' },
    { target: 'Wandering Spleen', source: 'Splenic Diseases', value: '15' },
    { target: 'Splenosis', source: 'Splenic Rupture', value: '19' },
    { target: 'Thymoma', source: 'Thymus Neoplasms', value: '15' },
    { target: 'Abnormalities, Drug-Induced', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Abnormalities, Multiple', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Abnormalities, Radiation-Induced', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Abnormalities, Severe Teratoid', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Aicardi Syndrome', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Cardiovascular Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Chromosome Disorders', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Congenital Microtia', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Digestive System Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Eye Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Hernias, Diaphragmatic, Congenital', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Lymphatic Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Musculoskeletal Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Nervous System Malformations', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Respiratory System Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Situs Inversus', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Skin Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Stomatognathic System Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Thyroid Dysgenesis', source: 'Congenital Abnormalities', value: '11' },
    { target: 'Urogenital Abnormalities', source: 'Congenital Abnormalities', value: '11' },
    { target: '22q11 Deletion Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Alagille Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Angelman Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Barth Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Beckwith-Wiedemann Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Bloom Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Branchio-Oto-Renal Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Carney Complex', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'CHARGE Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Ciliopathies', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Cockayne Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Costello Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Cri-du-Chat Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'De Lange Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Deaf-Blind Disorders', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Donohue Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Down Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Ectodermal Dysplasia', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Fraser Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Gardner Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Heterotaxy Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Holoprosencephaly', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Laurence-Moon Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'LEOPARD Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Loeys-Dietz Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Marfan Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Mobius Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Monilethrix', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Nail-Patella Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Netherton Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Nevus, Sebaceous of Jadassohn', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Orofaciodigital Syndromes', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Pallister-Hall Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Pentalogy of Cantrell', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'POEMS Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Polycystic Kidney Diseases', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Prader-Willi Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Prolidase Deficiency', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Proteus Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Prune Belly Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Rubella Syndrome, Congenital', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Short Rib-Polydactyly Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Silver-Russell Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Smith-Magenis Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Sotos Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Trichothiodystrophy Syndromes', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Waardenburg Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Weill-Marchesani Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Wolf-Hirschhorn Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'Zellweger Syndrome', source: 'Abnormalities, Multiple', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Alstrom Syndrome', source: 'Ciliopathies', value: '19' },
    { target: 'Bardet-Biedl Syndrome', source: 'Ciliopathies', value: '19' },
    { target: 'Caroli Disease', source: 'Ciliopathies', value: '19' },
    { target: 'Ciliary Motility Disorders', source: 'Ciliopathies', value: '19' },
    { target: 'Kartagener Syndrome', source: 'Ciliary Motility Disorders', value: '23' },
    { target: 'Usher Syndromes', source: 'Deaf-Blind Disorders', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Deaf-Blind Disorders', value: '19' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia 3, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ellis-Van Creveld Syndrome', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Neurocutaneous Syndromes', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Polycystic Kidney, Autosomal Dominant', source: 'Polycystic Kidney Diseases', value: '19' },
    { target: 'Polycystic Kidney, Autosomal Recessive', source: 'Polycystic Kidney Diseases', value: '19' },
    { target: 'Anencephaly', source: 'Abnormalities, Severe Teratoid', value: '15' },
    { target: 'Twins, Conjoined', source: 'Abnormalities, Severe Teratoid', value: '15' },
    { target: 'Heart Defects, Congenital', source: 'Cardiovascular Abnormalities', value: '15' },
    { target: 'Vascular Malformations', source: 'Cardiovascular Abnormalities', value: '15' },
    { target: '22q11 Deletion Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Alagille Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Aortic Coarctation', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Arrhythmogenic Right Ventricular Dysplasia', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Barth Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Cor Triatriatum', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Coronary Vessel Anomalies', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Crisscross Heart', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Dextrocardia', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Ductus Arteriosus, Patent', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Ebstein Anomaly', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Ectopia Cordis', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Eisenmenger Complex', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Heart Septal Defects', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Heterotaxy Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Hypoplastic Left Heart Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'LEOPARD Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Levocardia', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Long QT Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Marfan Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Noonan Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Tetralogy of Fallot', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Transposition of Great Vessels', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Tricuspid Atresia', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Trilogy of Fallot', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Turner Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'Wolff-Parkinson-White Syndrome', source: 'Heart Defects, Congenital', value: '19' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '23' },
    { target: 'Bland White Garland Syndrome', source: 'Coronary Vessel Anomalies', value: '23' },
    { target: 'Myocardial Bridging', source: 'Coronary Vessel Anomalies', value: '23' },
    { target: 'Kartagener Syndrome', source: 'Dextrocardia', value: '23' },
    { target: 'Aortopulmonary Septal Defect', source: 'Heart Septal Defects', value: '23' },
    { target: 'Endocardial Cushion Defects', source: 'Heart Septal Defects', value: '23' },
    { target: 'Heart Septal Defects, Atrial', source: 'Heart Septal Defects', value: '23' },
    { target: 'Heart Septal Defects, Ventricular', source: 'Heart Septal Defects', value: '23' },
    { target: 'Truncus Arteriosus, Persistent', source: 'Aortopulmonary Septal Defect', value: '27' },
    { target: 'Foramen Ovale, Patent', source: 'Heart Septal Defects, Atrial', value: '27' },
    { target: 'Lutembacher Syndrome', source: 'Heart Septal Defects, Atrial', value: '27' },
    { target: 'Andersen Syndrome', source: 'Long QT Syndrome', value: '23' },
    { target: 'Jervell-Lange Nielsen Syndrome', source: 'Long QT Syndrome', value: '23' },
    { target: 'Romano-Ward Syndrome', source: 'Long QT Syndrome', value: '23' },
    { target: 'Double Outlet Right Ventricle', source: 'Transposition of Great Vessels', value: '23' },
    { target: 'Arterio-Arterial Fistula', source: 'Vascular Malformations', value: '19' },
    { target: 'Arteriovenous Malformations', source: 'Vascular Malformations', value: '19' },
    { target: 'Central Nervous System Vascular Malformations', source: 'Vascular Malformations', value: '19' },
    { target: 'May-Thurner Syndrome', source: 'Vascular Malformations', value: '19' },
    { target: 'Pulmonary Atresia', source: 'Vascular Malformations', value: '19' },
    { target: 'Scimitar Syndrome', source: 'Vascular Malformations', value: '19' },
    { target: 'Single Umbilical Artery', source: 'Vascular Malformations', value: '19' },
    { target: 'Telangiectasia, Hereditary Hemorrhagic', source: 'Vascular Malformations', value: '19' },
    { target: 'Bland White Garland Syndrome', source: 'Arterio-Arterial Fistula', value: '23' },
    { target: 'Arteriovenous Fistula', source: 'Arteriovenous Malformations', value: '23' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Arteriovenous Malformations', value: '23' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '27' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '27' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Central Nervous System Vascular Malformations', value: '23' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '27' },
    { target: '22q11 Deletion Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Angelman Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Beckwith-Wiedemann Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Branchio-Oto-Renal Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Cri-du-Chat Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'De Lange Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Down Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Holoprosencephaly', source: 'Chromosome Disorders', value: '15' },
    { target: 'Jacobsen Distal 11q Deletion Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Prader-Willi Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Sex Chromosome Disorders', source: 'Chromosome Disorders', value: '15' },
    { target: 'Silver-Russell Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Smith-Magenis Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Sotos Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'WAGR Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Williams Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Wolf-Hirschhorn Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Fragile X Syndrome', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Orofaciodigital Syndromes', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Anorectal Malformations', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Anus, Imperforate', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Biliary Atresia', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Choledochal Cyst', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Diaphragmatic Eventration', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Esophageal Atresia', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Hirschsprung Disease', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Intestinal Atresia', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Meckel Diverticulum', source: 'Digestive System Abnormalities', value: '15' },
    { target: 'Caroli Disease', source: 'Choledochal Cyst', value: '19' },
    { target: 'Aniridia', source: 'Eye Abnormalities', value: '15' },
    { target: 'Anophthalmos', source: 'Eye Abnormalities', value: '15' },
    { target: 'Blepharophimosis', source: 'Eye Abnormalities', value: '15' },
    { target: 'Coloboma', source: 'Eye Abnormalities', value: '15' },
    { target: 'Ectopia Lentis', source: 'Eye Abnormalities', value: '15' },
    { target: 'Fraser Syndrome', source: 'Eye Abnormalities', value: '15' },
    { target: 'Hydrophthalmos', source: 'Eye Abnormalities', value: '15' },
    { target: 'Microphthalmos', source: 'Eye Abnormalities', value: '15' },
    { target: 'Persistent Hyperplastic Primary Vitreous', source: 'Eye Abnormalities', value: '15' },
    { target: 'Retinal Dysplasia', source: 'Eye Abnormalities', value: '15' },
    { target: 'WAGR Syndrome', source: 'Aniridia', value: '19' },
    { target: '22q11 Deletion Syndrome', source: 'Lymphatic Abnormalities', value: '15' },
    { target: 'Lymphangiectasis, Intestinal', source: 'Lymphatic Abnormalities', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Arthrogryposis', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Campomelic Dysplasia', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Cervical Rib Syndrome', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Craniofacial Abnormalities', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Funnel Chest', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Gastroschisis', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Hajdu-Cheney Syndrome', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Hip Dislocation, Congenital', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Klippel-Feil Syndrome', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Laryngomalacia', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Limb Deformities, Congenital', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Pectus Carinatum', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Synostosis', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: 'Tracheobronchomalacia', source: 'Musculoskeletal Abnormalities', value: '15' },
    { target: '22q11 Deletion Syndrome', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Cleidocranial Dysplasia', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Craniofacial Dysostosis', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Craniosynostoses', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Holoprosencephaly', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'LEOPARD Syndrome', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Megalencephaly', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Maxillofacial Abnormalities', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Microcephaly', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Noonan Syndrome', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Orofaciodigital Syndromes', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Plagiocephaly', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Platybasia', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Craniofacial Abnormalities', value: '19' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '23' },
    { target: 'Hallermann\'s Syndrome', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Hypertelorism', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Mandibulofacial Dysostosis', source: 'Craniofacial Dysostosis', value: '23' },
    { target: 'Goldenhar Syndrome', source: 'Mandibulofacial Dysostosis', value: '27' },
    { target: 'Acrocephalosyndactylia', source: 'Craniosynostoses', value: '23' },
    { target: 'Hemimegalencephaly', source: 'Megalencephaly', value: '23' },
    { target: 'Cherubism', source: 'Maxillofacial Abnormalities', value: '23' },
    { target: 'Dentofacial Deformities', source: 'Maxillofacial Abnormalities', value: '23' },
    { target: 'Jaw Abnormalities', source: 'Maxillofacial Abnormalities', value: '23' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '27' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '27' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '27' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '27' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '27' },
    { target: 'Porencephaly', source: 'Microcephaly', value: '23' },
    { target: 'Plagiocephaly, Nonsynostotic', source: 'Plagiocephaly', value: '23' },
    { target: 'Arachnodactyly', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Brachydactyly', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Ectromelia', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Lower Extremity Deformities, Congenital', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Polydactyly', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Proteus Syndrome', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Syndactyly', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Thanatophoric Dysplasia', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Upper Extremity Deformities, Congenital', source: 'Limb Deformities, Congenital', value: '19' },
    { target: 'Foot Deformities, Congenital', source: 'Lower Extremity Deformities, Congenital', value: '23' },
    { target: 'Talipes', source: 'Foot Deformities, Congenital', value: '27' },
    { target: 'Tarsal Coalition', source: 'Foot Deformities, Congenital', value: '27' },
    { target: 'Clubfoot', source: 'Talipes', value: '31' },
    { target: 'Equinus Deformity', source: 'Talipes', value: '31' },
    { target: 'Flatfoot', source: 'Talipes', value: '31' },
    { target: 'Talipes Cavus', source: 'Talipes', value: '31' },
    { target: 'Pallister-Hall Syndrome', source: 'Polydactyly', value: '23' },
    { target: 'Short Rib-Polydactyly Syndrome', source: 'Polydactyly', value: '23' },
    { target: 'Acrocephalosyndactylia', source: 'Syndactyly', value: '23' },
    { target: 'Fraser Syndrome', source: 'Syndactyly', value: '23' },
    { target: 'Poland Syndrome', source: 'Syndactyly', value: '23' },
    { target: 'Hand Deformities, Congenital', source: 'Upper Extremity Deformities, Congenital', value: '23' },
    { target: 'Antley-Bixler Syndrome Phenotype', source: 'Synostosis', value: '19' },
    { target: 'Craniosynostoses', source: 'Synostosis', value: '19' },
    { target: 'Syndactyly', source: 'Synostosis', value: '19' },
    { target: 'Tarsal Coalition', source: 'Synostosis', value: '19' },
    { target: 'Acrocephalosyndactylia', source: 'Craniosynostoses', value: '23' },
    { target: 'Acrocephalosyndactylia', source: 'Syndactyly', value: '23' },
    { target: 'Fraser Syndrome', source: 'Syndactyly', value: '23' },
    { target: 'Poland Syndrome', source: 'Syndactyly', value: '23' },
    { target: 'Bronchomalacia', source: 'Tracheobronchomalacia', value: '19' },
    { target: 'Tracheomalacia', source: 'Tracheobronchomalacia', value: '19' },
    { target: 'Agenesis of Corpus Callosum', source: 'Nervous System Malformations', value: '15' },
    { target: 'Central Nervous System Cysts', source: 'Nervous System Malformations', value: '15' },
    { target: 'Central Nervous System Vascular Malformations', source: 'Nervous System Malformations', value: '15' },
    { target: 'Dandy-Walker Syndrome', source: 'Nervous System Malformations', value: '15' },
    { target: 'Hereditary Sensory and Motor Neuropathy', source: 'Nervous System Malformations', value: '15' },
    { target: 'Hereditary Sensory and Autonomic Neuropathies', source: 'Nervous System Malformations', value: '15' },
    { target: 'Hydranencephaly', source: 'Nervous System Malformations', value: '15' },
    { target: 'Malformations of Cortical Development', source: 'Nervous System Malformations', value: '15' },
    { target: 'Neural Tube Defects', source: 'Nervous System Malformations', value: '15' },
    { target: 'Septo-Optic Dysplasia', source: 'Nervous System Malformations', value: '15' },
    { target: 'Acrocallosal Syndrome', source: 'Agenesis of Corpus Callosum', value: '19' },
    { target: 'Aicardi Syndrome', source: 'Agenesis of Corpus Callosum', value: '19' },
    { target: 'Holoprosencephaly', source: 'Agenesis of Corpus Callosum', value: '19' },
    { target: 'Arachnoid Cysts', source: 'Central Nervous System Cysts', value: '19' },
    { target: 'Colloid Cysts', source: 'Central Nervous System Cysts', value: '19' },
    { target: 'Central Nervous System Venous Angioma', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Hemangioma, Cavernous, Central Nervous System', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Intracranial Arteriovenous Malformations', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Sinus Pericranii', source: 'Central Nervous System Vascular Malformations', value: '19' },
    { target: 'Vein of Galen Malformations', source: 'Intracranial Arteriovenous Malformations', value: '23' },
    { target: 'Alstrom Syndrome', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Charcot-Marie-Tooth Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Giant Axonal Neuropathy', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Refsum Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Spastic Paraplegia, Hereditary', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Dysautonomia, Familial', source: 'Hereditary Sensory and Autonomic Neuropathies', value: '19' },
    { target: 'Malformations of Cortical Development, Group I', source: 'Malformations of Cortical Development', value: '19' },
    { target: 'Malformations of Cortical Development, Group II', source: 'Malformations of Cortical Development', value: '19' },
    { target: 'Malformations of Cortical Development, Group III', source: 'Malformations of Cortical Development', value: '19' },
    { target: 'Megalencephaly', source: 'Malformations of Cortical Development, Group I', value: '23' },
    { target: 'Microcephaly', source: 'Malformations of Cortical Development, Group I', value: '23' },
    { target: 'Tuberous Sclerosis', source: 'Malformations of Cortical Development, Group I', value: '23' },
    { target: 'Hemimegalencephaly', source: 'Megalencephaly', value: '27' },
    { target: 'Classical Lissencephalies and Subcortical Band Heterotopias', source: 'Malformations of Cortical Development, Group II', value: '23' },
    { target: 'Cobblestone Lissencephaly', source: 'Malformations of Cortical Development, Group II', value: '23' },
    { target: 'Lissencephaly', source: 'Malformations of Cortical Development, Group II', value: '23' },
    { target: 'Periventricular Nodular Heterotopia', source: 'Malformations of Cortical Development, Group II', value: '23' },
    { target: 'Classical Lissencephalies and Subcortical Band Heterotopias', source: 'Lissencephaly', value: '27' },
    { target: 'Cobblestone Lissencephaly', source: 'Lissencephaly', value: '27' },
    { target: 'Walker-Warburg Syndrome', source: 'Cobblestone Lissencephaly', value: '31' },
    { target: 'Polymicrogyria', source: 'Malformations of Cortical Development, Group III', value: '23' },
    { target: 'Porencephaly', source: 'Malformations of Cortical Development, Group III', value: '23' },
    { target: 'Schizencephaly', source: 'Malformations of Cortical Development, Group III', value: '23' },
    { target: 'Anencephaly', source: 'Neural Tube Defects', value: '19' },
    { target: 'Arnold-Chiari Malformation', source: 'Neural Tube Defects', value: '19' },
    { target: 'Encephalocele', source: 'Neural Tube Defects', value: '19' },
    { target: 'Meningocele', source: 'Neural Tube Defects', value: '19' },
    { target: 'Meningomyelocele', source: 'Neural Tube Defects', value: '19' },
    { target: 'Pentalogy of Cantrell', source: 'Neural Tube Defects', value: '19' },
    { target: 'Spinal Dysraphism', source: 'Neural Tube Defects', value: '19' },
    { target: 'Spina Bifida Cystica', source: 'Spinal Dysraphism', value: '23' },
    { target: 'Spina Bifida Occulta', source: 'Spinal Dysraphism', value: '23' },
    { target: 'Bronchogenic Cyst', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Bronchopulmonary Sequestration', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Choanal Atresia', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Cystic Adenomatoid Malformation of Lung, Congenital', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Laryngocele', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Laryngostenosis', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Scimitar Syndrome', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Tracheobronchomegaly', source: 'Respiratory System Abnormalities', value: '15' },
    { target: 'Dextrocardia', source: 'Situs Inversus', value: '15' },
    { target: 'Levocardia', source: 'Situs Inversus', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Dextrocardia', value: '19' },
    { target: 'Acrodermatitis', source: 'Skin Abnormalities', value: '15' },
    { target: 'Carney Complex', source: 'Skin Abnormalities', value: '15' },
    { target: 'Dyskeratosis Congenita', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ectodermal Dysplasia', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Skin Abnormalities', value: '15' },
    { target: 'Epidermolysis Bullosa', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ichthyosis', source: 'Skin Abnormalities', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Skin Abnormalities', value: '15' },
    { target: 'Port-Wine Stain', source: 'Skin Abnormalities', value: '15' },
    { target: 'Prolidase Deficiency', source: 'Skin Abnormalities', value: '15' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Rothmund-Thomson Syndrome', source: 'Skin Abnormalities', value: '15' },
    { target: 'Sclerema Neonatorum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Trichothiodystrophy Syndromes', source: 'Skin Abnormalities', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia 3, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ellis-Van Creveld Syndrome', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Neurocutaneous Syndromes', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Pachyonychia Congenita', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Steatocystoma Multiplex', source: 'Pachyonychia Congenita', value: '23' },
    { target: 'Epidermolysis Bullosa Acquisita', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa, Junctional', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Simplex', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Vulgaris', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis, X-Linked', source: 'Ichthyosis', value: '19' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Ichthyosis', value: '19' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Netherton Syndrome', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Maxillofacial Abnormalities', source: 'Stomatognathic System Abnormalities', value: '15' },
    { target: 'Mouth Abnormalities', source: 'Stomatognathic System Abnormalities', value: '15' },
    { target: 'Tooth Abnormalities', source: 'Stomatognathic System Abnormalities', value: '15' },
    { target: 'Dentofacial Deformities', source: 'Maxillofacial Abnormalities', value: '19' },
    { target: 'Jaw Abnormalities', source: 'Maxillofacial Abnormalities', value: '19' },
    { target: 'Cleft Palate', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Micrognathism', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Pierre Robin Syndrome', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Prognathism', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Retrognathia', source: 'Jaw Abnormalities', value: '23' },
    { target: 'Cleft Lip', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Cleft Palate', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Fibromatosis, Gingival', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Macrostomia', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Microstomia', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Velopharyngeal Insufficiency', source: 'Mouth Abnormalities', value: '19' },
    { target: 'Anodontia', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Dens in Dente', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Dental Enamel Hypoplasia', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Dentin Dysplasia', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Dentinogenesis Imperfecta', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Diastema', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Fused Teeth', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Odontodysplasia', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Toot, Supernumerary', source: 'Tooth Abnormalities', value: '19' },
    { target: 'Amelogenesis Imperfecta', source: 'Dental Enamel Hypoplasia', value: '23' },
    { target: 'Lingual Thyroid', source: 'Thyroid Dysgenesis', value: '15' },
    { target: 'Lingual Goiter', source: 'Lingual Thyroid', value: '19' },
    { target: 'Bladder Exstrophy', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Cryptorchidism', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Disorders of Sex Development', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Epispadias', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Fraser Syndrome', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Fused Kidney', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Hypospadias', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Multicystic Dysplastic Kidney', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Nephritis, Hereditary', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Pyelectasis', source: 'Urogenital Abnormalities', value: '15' },
    { target: 'Retrocaval Ureter', source: 'Urogenital Abnormalities', value: '15' },
    { target: '46, XX Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: '46, XY Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Adrenogenital Syndrome', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Ovotesticular Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Disorders of Sex Development', value: '19' },
    { target: '46, XX Testicular Disorders of Sex Development', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Hyperandrogenism', source: '46, XX Disorders of Sex Development', value: '23' },
    { target: 'Androgen-Insensitivity Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Denys-Drash Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Frasier Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Kallmann Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'WAGR Syndrome', source: '46, XY Disorders of Sex Development', value: '23' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '27' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Adrenogenital Syndrome', value: '23' },
    { target: 'Hyperandrogenism', source: 'Adrenogenital Syndrome', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Sexual Infantilism', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Turner Syndrome', source: 'Gonadal Dysgenesis', value: '23' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '27' },
    { target: 'Freemartinism', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Chorioamnionitis', source: 'Fetal Diseases', value: '11' },
    { target: 'Echogenic Bowel', source: 'Fetal Diseases', value: '11' },
    { target: 'Erythroblastosis, Fetal', source: 'Fetal Diseases', value: '11' },
    { target: 'Fetal Alcohol Spectrum Disorders', source: 'Fetal Diseases', value: '11' },
    { target: 'Fetal Growth Retardation', source: 'Fetal Diseases', value: '11' },
    { target: 'Fetal Hypoxia', source: 'Fetal Diseases', value: '11' },
    { target: 'Fetal Macrosomia', source: 'Fetal Diseases', value: '11' },
    { target: 'Meconium Aspiration Syndrome', source: 'Fetal Diseases', value: '11' },
    { target: 'Nuchal Cord', source: 'Fetal Diseases', value: '11' },
    { target: 'Pyelectasis', source: 'Fetal Diseases', value: '11' },
    { target: 'Hydrops Fetalis', source: 'Erythroblastosis, Fetal', value: '15' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Alagille Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'alpha 1-Antitrypsin Deficiency', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Anemia, Hemolytic, Congenital', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Anemia, Hypoplastic, Congenital', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Angioedemas, Hereditary', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Ataxia Telangiectasia', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Autoimmune Lymphoproliferative Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Blood Coagulation Disorders, Inherited', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Brugada Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'CADASIL', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Camurati-Engelmann Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Cardiomyopathy, Hypertrophic, Familial', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'CHARGE Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Cherubism', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Chromosome Disorders', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Ciliopathies', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Costello Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Cystic Fibrosis', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Donohue Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Dwarfism', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Eye Diseases, Hereditary', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Familial Multiple Lipomatosis', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Frasier Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Genetic Diseases, X-Linked', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Genetic Diseases, Y-Linked', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Hajdu-Cheney Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Hemoglobinopathies', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Hereditary Autoinflammatory Diseases', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Heredodegenerative Disorders, Nervous System', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Hyperthyroxinemia, Familial Dysalbuminemic', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Kallmann Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Kartagener Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Lennox Gastaut Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Loeys-Dietz Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Marfan Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Metabolism, Inborn Errors', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Muscular Dystrophies', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Myasthenic Syndromes, Congenital', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Nail-Patella Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Neoplastic Syndromes, Hereditary', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Orofaciodigital Syndromes', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Osteoarthropathy, Primary Hypertrophic', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Osteochondrodysplasias', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Osteogenesis Imperfecta', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Pain Insensitivity, Congenital', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Pelger-Huet Anomaly', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Polycystic Kidney Diseases', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Pycnodysostosis', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Skin Diseases, Genetic', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Werner Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Yellow Nail Syndrome', source: 'Genetic Diseases, Inborn', value: '11' },
    { target: 'Anemia, Dyserythropoietic, Congenital', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Anemia, Hemolytic, Congenital Nonspherocytic', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Anemia, Sickle Cell', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Elliptocytosis, Hereditary', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Glucosephosphate Dehydrogenase Deficiency', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Hemoglobin C Disease', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Spherocytosis, Hereditary', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Thalassemia', source: 'Anemia, Hemolytic, Congenital', value: '15' },
    { target: 'Acute Chest Syndrome', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Hemoglobin SC Disease', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Sickle Cell Trait', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Favism', source: 'Glucosephosphate Dehydrogenase Deficiency', value: '19' },
    { target: 'alpha-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'beta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'delta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'Anemia, Diamond-Blackfan', source: 'Anemia, Hypoplastic, Congenital', value: '15' },
    { target: 'Fanconi Anemia', source: 'Anemia, Hypoplastic, Congenital', value: '15' },
    { target: 'Activated Protein C Resistance', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Afibrinogenemia', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Antithrombin III Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Bernard-Soulier Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor V Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor VII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor X Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor XI Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor XII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Factor XIII Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Gray Platelet Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Hemophilia A', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Hemophilia B', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Hypoprothrombinemias', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Protein C Deficiency', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Thrombasthenia', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'von Willebrand Diseases', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Blood Coagulation Disorders, Inherited', value: '15' },
    { target: 'von Willebrand Disease, Type 1', source: 'von Willebrand Diseases', value: '19' },
    { target: 'von Willebrand Disease, Type 2', source: 'von Willebrand Diseases', value: '19' },
    { target: 'von Willebrand Disease, Type 3', source: 'von Willebrand Diseases', value: '19' },
    { target: '22q11 Deletion Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Angelman Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Beckwith-Wiedemann Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Branchio-Oto-Renal Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Cri-du-Chat Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'De Lange Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Down Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Holoprosencephaly', source: 'Chromosome Disorders', value: '15' },
    { target: 'Jacobsen Distal 11q Deletion Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Prader-Willi Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Rubinstein-Taybi Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Sex Chromosome Disorders', source: 'Chromosome Disorders', value: '15' },
    { target: 'Silver-Russell Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Smith-Magenis Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Sotos Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'WAGR Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Williams Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'Wolf-Hirschhorn Syndrome', source: 'Chromosome Disorders', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Fragile X Syndrome', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Orofaciodigital Syndromes', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Sex Chromosome Disorders', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '23' },
    { target: 'Alstrom Syndrome', source: 'Ciliopathies', value: '15' },
    { target: 'Bardet-Biedl Syndrome', source: 'Ciliopathies', value: '15' },
    { target: 'Caroli Disease', source: 'Ciliopathies', value: '15' },
    { target: 'Ciliary Motility Disorders', source: 'Ciliopathies', value: '15' },
    { target: 'Kartagener Syndrome', source: 'Ciliary Motility Disorders', value: '19' },
    { target: 'Achondroplasia', source: 'Dwarfism', value: '15' },
    { target: 'Cockayne Syndrome', source: 'Dwarfism', value: '15' },
    { target: 'Congenital Hypothyroidism', source: 'Dwarfism', value: '15' },
    { target: 'Laron Syndrome', source: 'Dwarfism', value: '15' },
    { target: 'Mulibrey Nanism', source: 'Dwarfism', value: '15' },
    { target: 'Silver-Russell Syndrome', source: 'Dwarfism', value: '15' },
    { target: 'Thanatophoric Dysplasia', source: 'Achondroplasia', value: '19' },
    { target: 'Aicardi Syndrome', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Albinism', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Aniridia', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Choroideremia', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Cone-Rod Dystrophies', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Corneal Dystrophies, Hereditary', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Duane Retraction Syndrome', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Gyrate Atrophy', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Optic Atrophies, Hereditary', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Retinal Dysplasia', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Retinitis Pigmentosa', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Vitelliform Macular Dystrophy', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Weill-Marchesani Syndrome', source: 'Eye Diseases, Hereditary', value: '15' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '19' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '19' },
    { target: 'Piebaldism', source: 'Albinism', value: '19' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '23' },
    { target: 'WAGR Syndrome', source: 'Aniridia', value: '19' },
    { target: 'Corneal Dystrophy, Juvenile Epithelial of Meesmann', source: 'Corneal Dystrophies, Hereditary', value: '19' },
    { target: 'Fuchs\' Endothelial Dystrophy', source: 'Corneal Dystrophies, Hereditary', value: '19' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Alstrom Syndrome', source: 'Retinitis Pigmentosa', value: '19' },
    { target: 'Usher Syndromes', source: 'Retinitis Pigmentosa', value: '19' },
    { target: 'Aicardi Syndrome', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Androgen-Insensitivity Syndrome', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Barth Syndrome', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Bulbo-Spinal Atrophy, X-Linked', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Choroideremia', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Dent Disease', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Dyskeratosis Congenita', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Fabry Disease', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Focal Dermal Hypoplasia', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Glycogen Storage Disease Type IIb', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Glycogen Storage Disease Type VIII', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Granulomatous Disease, Chronic', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Hemophilia B', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome, Type 1', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Ichthyosis, X-Linked', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Isolated Noncompaction of the Ventricular Myocardium', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Mental Retardation, X-Linked', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Muscular Dystrophy, Duchenne', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Muscular Dystrophy, Emery-Dreifuss', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'X-Linked Combined Immunodeficiency Diseases', source: 'Genetic Diseases, X-Linked', value: '15' },
    { target: 'Adrenoleukodystrophy', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Classical Lissencephalies and Subcortical Band Heterotopias', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Coffin-Lowry Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Fragile X Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Mucopolysaccharidosis II', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Rett Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Anemia, Sickle Cell', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Hemoglobin C Disease', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Thalassemia', source: 'Hemoglobinopathies', value: '15' },
    { target: 'Acute Chest Syndrome', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Hemoglobin SC Disease', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'Sickle Cell Trait', source: 'Anemia, Sickle Cell', value: '19' },
    { target: 'alpha-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'beta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'delta-Thalassemia', source: 'Thalassemia', value: '19' },
    { target: 'Hydrops Fetalis', source: 'alpha-Thalassemia', value: '23' },
    { target: 'Behcet Syndrome', source: 'Hereditary Autoinflammatory Diseases', value: '15' },
    { target: 'Cryopyrin-Associated Periodic Syndromes', source: 'Hereditary Autoinflammatory Diseases', value: '15' },
    { target: 'Familial Mediterranean Fever', source: 'Hereditary Autoinflammatory Diseases', value: '15' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Hereditary Autoinflammatory Diseases', value: '15' },
    { target: 'Alexander Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Canavan Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Cockayne Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Dystonia Musculorum Deformans', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Gerstmann-Straussler-Scheinker Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hepatolenticular Degeneration', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Sensory and Motor Neuropathy', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Hereditary Sensory and Autonomic Neuropathies', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Huntington Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Lafora Disease', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Mental Retardation, X-Linked', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Myotonia Congenita', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Myotonic Dystrophy', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Neuroacanthocytosis', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Neurofibromatoses', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Neuronal Ceroid-Lipofuscinoses', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Optic Atrophies, Hereditary', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Pantothenate Kinase-Associated Neurodegeneration', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Spinal Muscular Atrophies of Childhood', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Spinocerebellar Degenerations', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Tourette Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Tuberous Sclerosis', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Unverricht-Lundborg Syndrome', source: 'Heredodegenerative Disorders, Nervous System', value: '15' },
    { target: 'Alstrom Syndrome', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Charcot-Marie-Tooth Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Giant Axonal Neuropathy', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Refsum Disease', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Spastic Paraplegia, Hereditary', source: 'Hereditary Sensory and Motor Neuropathy', value: '19' },
    { target: 'Dysautonomia, Familial', source: 'Hereditary Sensory and Autonomic Neuropathies', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Coffin-Lowry Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Fragile X Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Mucopolysaccharidosis II', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Rett Syndrome', source: 'Mental Retardation, X-Linked', value: '19' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '19' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '19' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Optic Atrophies, Hereditary', value: '19' },
    { target: 'Friedreich Ataxia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Myoclonic Cerebellar Dyssynergia', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Olivopontocerebellar Atrophies', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Spinocerebellar Ataxias', source: 'Spinocerebellar Degenerations', value: '19' },
    { target: 'Machado-Joseph Disease', source: 'Spinocerebellar Ataxias', value: '23' },
    { target: 'Amino Acid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Amino Acid Transport Disorders, Inborn', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Amyloidosis, Familial', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Brain Diseases, Metabolic, Inborn', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Carbohydrate Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Cytochrome-c Oxidase Deficiency', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Hyperbilirubinemia, Hereditary', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Lipid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Lysosomal Storage Diseases', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Metal Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Peroxisomal Disorders', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Progeria', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Purine-Pyrimidine Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Renal Tubular Transport, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Steroid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Albinism', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Alkaptonuria', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperhomocysteinemia', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlysinemias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Maple Syrup Urine Disease', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Acyl Coenzyme A Dehydrogenase Deficiency', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Carboxylase Deficiency', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Phenylketonurias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Prolidase Deficiency', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Propionic Acidemia', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Tyrosinemias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '23' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '23' },
    { target: 'Piebaldism', source: 'Albinism', value: '23' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '27' },
    { target: 'Homocystinuria', source: 'Hyperhomocysteinemia', value: '23' },
    { target: 'Biotinidase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Holocarboxylase Synthetase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '23' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hartnup Disease', source: 'Amino Acid Transport Disorders, Inborn', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Amino Acid Transport Disorders, Inborn', value: '19' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Amyloidosis, Familial', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Amyloidosis, Familial', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Galactosemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hartnup Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Homocystinuria', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperlysinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Leigh Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Maple Syrup Urine Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MELAS Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MERRF Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Phenylketonurias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease, Infantile', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Tyrosinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '23' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Congenital Disorders of Glycosylation', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fructose Metabolism, Inborn Errors', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fucosidosis', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Galactosemias', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Glucosephosphate Dehydrogenase Deficiency', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Glycogen Storage Disease', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperoxaluria, Primary', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Lactose Intolerance', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mannosidase Deficiency Diseases', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mucolipidoses', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mucopolysaccharidoses', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Carboxylase Deficiency', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Pyruvate Metabolism, Inborn Errors', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fructose-1,6-Diphosphatase Deficiency', source: 'Fructose Metabolism, Inborn Errors', value: '23' },
    { target: 'Fructose Intolerance', source: 'Fructose Metabolism, Inborn Errors', value: '23' },
    { target: 'Glycogen Storage Disease Type I', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type IIb', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type III', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type IV', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type V', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VI', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VII', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VIII', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'alpha-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'beta-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'Mucopolysaccharidosis I', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis II', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis III', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis IV', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VI', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VII', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Biotinidase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Holocarboxylase Synthetase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Leigh Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Crigler-Najjar Syndrome', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Gilbert Disease', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Jaundice, Chronic Idiopathic', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Barth Syndrome', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipidemia, Familial Combined', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type I', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type II', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type III', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type IV', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type V', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypolipoproteinemias', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Lipidoses', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Lipodystrophy, Congenital Generalized', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Xanthomatosis, Cerebrotendinous', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypoalphalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Hypobetalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Lecithin Cholesterol Acyltransferase Deficiency', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Tangier Disease', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Abetalipoproteinemia', source: 'Hypobetalipoproteinemias', value: '27' },
    { target: 'Cholesterol Ester Storage Disease', source: 'Lipidoses', value: '23' },
    { target: 'Neuronal Ceroid-Lipofuscinoses', source: 'Lipidoses', value: '23' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Lipidoses', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lipidoses', value: '23' },
    { target: 'Wolman Disease', source: 'Cholesterol Ester Storage Disease', value: '27' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'Aspartylglucosaminuria', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Cholesterol Ester Storage Disease', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Cystinosis', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Mannosidase Deficiency Diseases', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Mucopolysaccharidoses', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Pycnodysostosis', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Wolman Disease', source: 'Cholesterol Ester Storage Disease', value: '23' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'alpha-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'beta-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'Mucopolysaccharidosis I', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis II', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis III', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis IV', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VI', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VII', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Hemochromatosis', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypophosphatasia', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypophosphatemia, Familial', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Paralyses, Familial Periodic', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Pseudohypoparathyroidism', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Hypokalemic Periodic Paralysis', source: 'Paralyses, Familial Periodic', value: '23' },
    { target: 'Paralysis, Hyperkalemic Periodic', source: 'Paralyses, Familial Periodic', value: '23' },
    { target: 'Pseudopseudohypoparathyroidism', source: 'Pseudohypoparathyroidism', value: '23' },
    { target: 'Acatalasia', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Chondrodysplasia Punctata, Rhizomelic', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Refsum Disease', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Refsum Disease, Infantile', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Dihydropyrimidine Dehydrogenase Deficiency', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Gout', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Arthritis, Gouty', source: 'Gout', value: '23' },
    { target: 'Acidosis, Renal Tubular', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Dent Disease', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Fanconi Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Gitelman Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Glycosuria, Renal', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Hypophosphatemia, Familial', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Liddle Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Pseudohypoaldosteronism', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Renal Aminoacidurias', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Cystinuria', source: 'Renal Aminoacidurias', value: '23' },
    { target: 'Hartnup Disease', source: 'Renal Aminoacidurias', value: '23' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Antley-Bixler Syndrome Phenotype', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Ichthyosis, X-Linked', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Mineralocorticoid Excess Syndrome, Apparent', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Distal Myopathies', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Glycogen Storage Disease Type VII', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Muscular Dystrophies, Limb-Girdle', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Muscular Dystrophy, Duchenne', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Muscular Dystrophy, Emery-Dreifuss', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Muscular Dystrophy, Facioscapulohumeral', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Muscular Dystrophy, Oculopharyngeal', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Myotonic Dystrophy', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Walker-Warburg Syndrome', source: 'Muscular Dystrophies', value: '15' },
    { target: 'Sarcoglycanopathies', source: 'Muscular Dystrophies, Limb-Girdle', value: '19' },
    { target: 'Adenomatous Polyposis Coli', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Basal Cell Nevus Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Birt-Hogg-Dube Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Dysplastic Nevus Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Exostoses, Multiple Hereditary', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Hamartoma Syndrome, Multiple', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Li-Fraumeni Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Multiple Endocrine Neoplasia', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Tuberous Sclerosis', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Wilms Tumor', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Neurofibromatoses', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Peutz-Jeghers Syndrome', source: 'Neoplastic Syndromes, Hereditary', value: '15' },
    { target: 'Gardner Syndrome', source: 'Adenomatous Polyposis Coli', value: '19' },
    { target: 'Lynch Syndrome II', source: 'Colorectal Neoplasms, Hereditary Nonpolyposis', value: '19' },
    { target: 'Muir-Torre Syndrome', source: 'Lynch Syndrome II', value: '23' },
    { target: 'Multiple Endocrine Neoplasia Type 1', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 2a', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 2b', source: 'Multiple Endocrine Neoplasia', value: '19' },
    { target: 'Denys-Drash Syndrome', source: 'Wilms Tumor', value: '19' },
    { target: 'WAGR Syndrome', source: 'Wilms Tumor', value: '19' },
    { target: 'Neurofibromatosis 1', source: 'Neurofibromatoses', value: '19' },
    { target: 'Neurofibromatosis 2', source: 'Neurofibromatoses', value: '19' },
    { target: 'Polycystic Kidney, Autosomal Dominant', source: 'Polycystic Kidney Diseases', value: '15' },
    { target: 'Polycystic Kidney, Autosomal Recessive', source: 'Polycystic Kidney Diseases', value: '15' },
    { target: 'Albinism', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Cutis Laxa', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Darier Disease', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Dermatitis, Atopic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Dyskeratosis Congenita', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ectodermal Dysplasia', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Epidermolysis Bullosa', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Erythrokeratodermia Variabilis', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Hyalinosis, Systemic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis Vulgaris', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis, X-Linked', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Keratoderma, Palmoplantar', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Leukokeratosis, Hereditary Mucosal', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Lipoid Proteinosis of Urbach and Wiethe', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Monilethrix', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Netherton Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Pemphigus, Benign Familial', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porokeratosis', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porphyria, Erythropoietic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porphyrias, Hepatic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Prolidase Deficiency', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Rothmund-Thomson Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Trichothiodystrophy Syndromes', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '19' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '19' },
    { target: 'Piebaldism', source: 'Albinism', value: '19' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '23' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia 3, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ellis-Van Creveld Syndrome', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Neurocutaneous Syndromes', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Pachyonychia Congenita', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Steatocystoma Multiplex', source: 'Pachyonychia Congenita', value: '23' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa, Junctional', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Simplex', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Keratoderma, Palmoplantar, Diffuse', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Papillon-Lefevre Disease', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Keratoderma, Palmoplantar, Epidermolytic', source: 'Keratoderma, Palmoplantar, Diffuse', value: '23' },
    { target: 'Coproporphyria, Hereditary', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Acute Intermittent', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria Cutanea Tarda', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Hepatoerythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Variegate', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Protoporphyria, Erythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Amniotic Band Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Anemia, Neonatal', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Asphyxia Neonatorum', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Birth Injuries', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Colic', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Congenital Hyperinsulinism', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Cystic Fibrosis', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Epilepsy, Benign Neonatal', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Erythroblastosis, Fetal', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Hernia, Umbilical', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Hydrophthalmos', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Hyperbilirubinemia, Neonatal', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Hyperostosis, Cortical, Congenital', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Ichthyosis', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Infant, Premature, Diseases', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Meconium Aspiration Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Mobius Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Neonatal Abstinence Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Neonatal Sepsis', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Nystagmus, Congenital', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Ophthalmia Neonatorum', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Persistent Fetal Circulation Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Rothmund-Thomson Syndrome', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Sclerema Neonatorum', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Severe Combined Immunodeficiency', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Syphilis, Congenital', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Thanatophoric Dysplasia', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Thrombocytopenia, Neonatal Alloimmune', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Toxoplasmosis, Congenital', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Vitamin K Deficiency Bleeding', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Wolman Disease', source: 'Infant, Newborn, Diseases', value: '11' },
    { target: 'Fetofetal Transfusion', source: 'Anemia, Neonatal', value: '15' },
    { target: 'Fetomaternal Transfusion', source: 'Anemia, Neonatal', value: '15' },
    { target: 'Paralysis, Obstetric', source: 'Birth Injuries', value: '15' },
    { target: 'Nesidioblastosis', source: 'Congenital Hyperinsulinism', value: '15' },
    { target: 'Kernicterus', source: 'Erythroblastosis, Fetal', value: '15' },
    { target: 'Jaundice, Neonatal', source: 'Hyperbilirubinemia, Neonatal', value: '15' },
    { target: 'Jaundice, Chronic Idiopathic', source: 'Jaundice, Neonatal', value: '19' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Ichthyosis', value: '15' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Ichthyosis', value: '15' },
    { target: 'Ichthyosis, X-Linked', source: 'Ichthyosis', value: '15' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Ichthyosis', value: '15' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Netherton Syndrome', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Bronchopulmonary Dysplasia', source: 'Infant, Premature, Diseases', value: '15' },
    { target: 'Leukomalacia, Periventricular', source: 'Infant, Premature, Diseases', value: '15' },
    { target: 'Respiratory Distress Syndrome, Newborn', source: 'Infant, Premature, Diseases', value: '15' },
    { target: 'Retinopathy of Prematurity', source: 'Infant, Premature, Diseases', value: '15' },
    { target: 'Hyaline Membrane Disease', source: 'Respiratory Distress Syndrome, Newborn', value: '19' },
    { target: 'Transient Tachypnea of the Newborn', source: 'Respiratory Distress Syndrome, Newborn', value: '19' },
    { target: 'X-Linked Combined Immunodeficiency Diseases', source: 'Severe Combined Immunodeficiency', value: '15' },
    { target: 'Anetoderma', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Cartilage Diseases', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Cellulitis', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Collagen Diseases', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Cutis Laxa', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Dermatomyositis', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Dupuytren Contracture', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Fibromatosis, Plantar', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Homocystinuria', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Lipedema', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Lupus Erythematosus, Cutaneous', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Lupus Erythematosus, Systemic', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Marfan Syndrome', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Mixed Connective Tissue Disease', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Mucinoses', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Neoplasms, Connective Tissue', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Noonan Syndrome', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Osteopoikilosis', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Panniculitis', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Penile Induration', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Rheumatic Diseases', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Scleroderma, Localized', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Scleroderma, Systemic', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Weill-Marchesani Syndrome', source: 'Connective Tissue Diseases', value: '11' },
    { target: 'Chondromalacia Patellae', source: 'Cartilage Diseases', value: '15' },
    { target: 'Laryngomalacia', source: 'Cartilage Diseases', value: '15' },
    { target: 'Osteochondritis', source: 'Cartilage Diseases', value: '15' },
    { target: 'Pectus Carinatum', source: 'Cartilage Diseases', value: '15' },
    { target: 'Polychondritis, Relapsing', source: 'Cartilage Diseases', value: '15' },
    { target: 'Tietze\'s Syndrome', source: 'Cartilage Diseases', value: '15' },
    { target: 'Tracheobronchomalacia', source: 'Cartilage Diseases', value: '15' },
    { target: 'Bronchomalacia', source: 'Tracheobronchomalacia', value: '19' },
    { target: 'Tracheomalacia', source: 'Tracheobronchomalacia', value: '19' },
    { target: 'Orbital Cellulitis', source: 'Cellulitis', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Collagen Diseases', value: '15' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Collagen Diseases', value: '15' },
    { target: 'Keloid', source: 'Collagen Diseases', value: '15' },
    { target: 'Necrobiotic Disorders', source: 'Collagen Diseases', value: '15' },
    { target: 'Nephritis, Hereditary', source: 'Collagen Diseases', value: '15' },
    { target: 'Osteogenesis Imperfecta', source: 'Collagen Diseases', value: '15' },
    { target: 'Acne Keloid', source: 'Keloid', value: '19' },
    { target: 'Granuloma Annulare', source: 'Necrobiotic Disorders', value: '19' },
    { target: 'Necrobiosis Lipoidica', source: 'Necrobiotic Disorders', value: '19' },
    { target: 'Necrobiotic Xanthogranuloma', source: 'Necrobiotic Disorders', value: '19' },
    { target: 'Lupus Erythematosus, Discoid', source: 'Lupus Erythematosus, Cutaneous', value: '15' },
    { target: 'Panniculitis, Lupus Erythematosus', source: 'Lupus Erythematosus, Discoid', value: '19' },
    { target: 'Lupus Nephritis', source: 'Lupus Erythematosus, Systemic', value: '15' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Lupus Erythematosus, Systemic', value: '15' },
    { target: 'Ganglion Cysts', source: 'Mucinoses', value: '15' },
    { target: 'Mucinosis, Follicular', source: 'Mucinoses', value: '15' },
    { target: 'Mucopolysaccharidoses', source: 'Mucinoses', value: '15' },
    { target: 'Myxedema', source: 'Mucinoses', value: '15' },
    { target: 'Scleredema Adultorum', source: 'Mucinoses', value: '15' },
    { target: 'Scleromyxedema', source: 'Mucinoses', value: '15' },
    { target: 'Mucopolysaccharidosis I', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Mucopolysaccharidosis II', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Mucopolysaccharidosis III', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Mucopolysaccharidosis IV', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Mucopolysaccharidosis VI', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Mucopolysaccharidosis VII', source: 'Mucopolysaccharidoses', value: '19' },
    { target: 'Myofibroma', source: 'Neoplasms, Connective Tissue', value: '15' },
    { target: 'Erythema Induratum', source: 'Panniculitis', value: '15' },
    { target: 'Panniculitis, Lupus Erythematosus', source: 'Panniculitis', value: '15' },
    { target: 'Panniculitis, Nodular Nonsuppurative', source: 'Panniculitis', value: '15' },
    { target: 'Panniculitis, Peritoneal', source: 'Panniculitis', value: '15' },
    { target: 'Arthritis, Juvenile', source: 'Rheumatic Diseases', value: '15' },
    { target: 'Arthritis, Rheumatoid', source: 'Rheumatic Diseases', value: '15' },
    { target: 'Hyperostosis, Sternocostoclavicular', source: 'Rheumatic Diseases', value: '15' },
    { target: 'Polymyalgia Rheumatica', source: 'Rheumatic Diseases', value: '15' },
    { target: 'Caplan Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Felty Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Rheumatoid Nodule', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Rheumatoid Vasculitis', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Sjogren\'s Syndrome', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Still\'s Disease, Adult-Onset', source: 'Arthritis, Rheumatoid', value: '19' },
    { target: 'Scleroderma, Diffuse', source: 'Scleroderma, Systemic', value: '15' },
    { target: 'Scleroderma, Limited', source: 'Scleroderma, Systemic', value: '15' },
    { target: 'CREST Syndrome', source: 'Scleroderma, Limited', value: '19' },
    { target: 'Acneiform Eruptions', source: 'Skin Diseases', value: '11' },
    { target: 'Angiolymphoid Hyperplasia with Eosinophilia', source: 'Skin Diseases', value: '11' },
    { target: 'Breast Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Cutaneous Fistula', source: 'Skin Diseases', value: '11' },
    { target: 'Dermatitis', source: 'Skin Diseases', value: '11' },
    { target: 'Dermatomyositis', source: 'Skin Diseases', value: '11' },
    { target: 'Erythema', source: 'Skin Diseases', value: '11' },
    { target: 'Exanthema', source: 'Skin Diseases', value: '11' },
    { target: 'Facial Dermatoses', source: 'Skin Diseases', value: '11' },
    { target: 'Foot Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Hair Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Hand Dermatoses', source: 'Skin Diseases', value: '11' },
    { target: 'Keratoacanthoma', source: 'Skin Diseases', value: '11' },
    { target: 'Keratosis', source: 'Skin Diseases', value: '11' },
    { target: 'Leg Dermatoses', source: 'Skin Diseases', value: '11' },
    { target: 'Lipomatosis', source: 'Skin Diseases', value: '11' },
    { target: 'Lupus Erythematosus, Cutaneous', source: 'Skin Diseases', value: '11' },
    { target: 'Mastocytosis', source: 'Skin Diseases', value: '11' },
    { target: 'Morgellons Disease', source: 'Skin Diseases', value: '11' },
    { target: 'Nail Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Necrobiotic Disorders', source: 'Skin Diseases', value: '11' },
    { target: 'Necrolytic Migratory Erythema', source: 'Skin Diseases', value: '11' },
    { target: 'Nephrogenic Fibrosing Dermopathy', source: 'Skin Diseases', value: '11' },
    { target: 'Panniculitis', source: 'Skin Diseases', value: '11' },
    { target: 'Photosensitivity Disorders', source: 'Skin Diseases', value: '11' },
    { target: 'Pigmentation Disorders', source: 'Skin Diseases', value: '11' },
    { target: 'Prurigo', source: 'Skin Diseases', value: '11' },
    { target: 'Pruritus', source: 'Skin Diseases', value: '11' },
    { target: 'Pyoderma', source: 'Skin Diseases', value: '11' },
    { target: 'Rosacea', source: 'Skin Diseases', value: '11' },
    { target: 'Scalp Dermatoses', source: 'Skin Diseases', value: '11' },
    { target: 'Scleredema Adultorum', source: 'Skin Diseases', value: '11' },
    { target: 'Scleroderma, Localized', source: 'Skin Diseases', value: '11' },
    { target: 'Scleroderma, Systemic', source: 'Skin Diseases', value: '11' },
    { target: 'Sebaceous Gland Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Abnormalities', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Eczematous', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Genetic', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Infectious', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Metabolic', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Papulosquamous', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Vascular', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Diseases, Vesiculobullous', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Neoplasms', source: 'Skin Diseases', value: '11' },
    { target: 'Skin Ulcer', source: 'Skin Diseases', value: '11' },
    { target: 'Sweat Gland Diseases', source: 'Skin Diseases', value: '11' },
    { target: 'Xanthogranuloma, Juvenile', source: 'Skin Diseases', value: '11' },
    { target: 'Acne Keloid', source: 'Acneiform Eruptions', value: '15' },
    { target: 'Acne Vulgaris', source: 'Acneiform Eruptions', value: '15' },
    { target: 'Chloracne', source: 'Acneiform Eruptions', value: '15' },
    { target: 'Acne Conglobata', source: 'Acne Vulgaris', value: '19' },
    { target: 'Breast Cyst', source: 'Breast Diseases', value: '15' },
    { target: 'Breast Neoplasms', source: 'Breast Diseases', value: '15' },
    { target: 'Fibrocystic Breast Disease', source: 'Breast Diseases', value: '15' },
    { target: 'Gynecomastia', source: 'Breast Diseases', value: '15' },
    { target: 'Lactation Disorders', source: 'Breast Diseases', value: '15' },
    { target: 'Mastitis', source: 'Breast Diseases', value: '15' },
    { target: 'Breast Carcinoma In Situ', source: 'Breast Neoplasms', value: '19' },
    { target: 'Breast Neoplasms, Male', source: 'Breast Neoplasms', value: '19' },
    { target: 'Carcinoma, Ductal, Breast', source: 'Breast Neoplasms', value: '19' },
    { target: 'Carcinoma, Lobular', source: 'Breast Neoplasms', value: '19' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Breast Neoplasms', value: '19' },
    { target: 'Inflammatory Breast Neoplasms', source: 'Breast Neoplasms', value: '19' },
    { target: 'Unilateral Breast Neoplasms', source: 'Breast Neoplasms', value: '19' },
    { target: 'Triple Negative Breast Neoplasms', source: 'Breast Neoplasms', value: '19' },
    { target: 'Galactorrhea', source: 'Lactation Disorders', value: '19' },
    { target: 'Chiari-Frommel Syndrome', source: 'Galactorrhea', value: '23' },
    { target: 'Granulomatous Mastitis', source: 'Mastitis', value: '19' },
    { target: 'Acrodermatitis', source: 'Dermatitis', value: '15' },
    { target: 'Dandruff', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Atopic', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Contact', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Exfoliative', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis Herpetiformis', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Perioral', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Seborrheic', source: 'Dermatitis', value: '15' },
    { target: 'Drug Eruptions', source: 'Dermatitis', value: '15' },
    { target: 'Eczema', source: 'Dermatitis', value: '15' },
    { target: 'Intertrigo', source: 'Dermatitis', value: '15' },
    { target: 'Neurodermatitis', source: 'Dermatitis', value: '15' },
    { target: 'Radiodermatitis', source: 'Dermatitis', value: '15' },
    { target: 'Dermatitis, Allergic Contact', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Irritant', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Occupational', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Photoallergic', source: 'Dermatitis, Allergic Contact', value: '23' },
    { target: 'Dermatitis, Toxicodendron', source: 'Dermatitis, Allergic Contact', value: '23' },
    { target: 'Dermatitis, Phototoxic', source: 'Dermatitis, Irritant', value: '23' },
    { target: 'Diaper Rash', source: 'Dermatitis, Irritant', value: '23' },
    { target: 'Acute Generalized Exanthematous Pustulosis', source: 'Drug Eruptions', value: '19' },
    { target: 'Drug Hypersensitivity Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Erythema Nodosum', source: 'Drug Eruptions', value: '19' },
    { target: 'Hand-Foot Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Nicolau Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Serum Sickness', source: 'Drug Eruptions', value: '19' },
    { target: 'Stevens-Johnson Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Eczema, Dyshidrotic', source: 'Eczema', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Erythema', value: '15' },
    { target: 'Erythema Induratum', source: 'Erythema', value: '15' },
    { target: 'Erythema Infectiosum', source: 'Erythema', value: '15' },
    { target: 'Erythema Multiforme', source: 'Erythema', value: '15' },
    { target: 'Erythema Nodosum', source: 'Erythema', value: '15' },
    { target: 'Erythrokeratodermia Variabilis', source: 'Erythema', value: '15' },
    { target: 'Sweet Syndrome', source: 'Erythema', value: '15' },
    { target: 'Stevens-Johnson Syndrome', source: 'Erythema Multiforme', value: '19' },
    { target: 'Exanthema Subitum', source: 'Exanthema', value: '15' },
    { target: 'Dermatitis, Perioral', source: 'Facial Dermatoses', value: '15' },
    { target: 'Fibromatosis, Plantar', source: 'Foot Diseases', value: '15' },
    { target: 'Foot Dermatoses', source: 'Foot Diseases', value: '15' },
    { target: 'Foot Ulcer', source: 'Foot Diseases', value: '15' },
    { target: 'Immersion Foot', source: 'Foot Diseases', value: '15' },
    { target: 'Tinea Pedis', source: 'Foot Dermatoses', value: '19' },
    { target: 'Folliculitis', source: 'Hair Diseases', value: '15' },
    { target: 'Hirsutism', source: 'Hair Diseases', value: '15' },
    { target: 'Hypertrichosis', source: 'Hair Diseases', value: '15' },
    { target: 'Hypotrichosis', source: 'Hair Diseases', value: '15' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Hair Diseases', value: '15' },
    { target: 'Monilethrix', source: 'Hair Diseases', value: '15' },
    { target: 'Piedra', source: 'Hair Diseases', value: '15' },
    { target: 'Acne Keloid', source: 'Folliculitis', value: '19' },
    { target: 'Alopecia', source: 'Hypotrichosis', value: '19' },
    { target: 'Alopecia Areata', source: 'Alopecia', value: '23' },
    { target: 'Loose Anagen Hair Syndrome', source: 'Alopecia', value: '23' },
    { target: 'Mucinosis, Follicular', source: 'Alopecia', value: '23' },
    { target: 'Callosities', source: 'Keratosis', value: '15' },
    { target: 'Cholesteatoma', source: 'Keratosis', value: '15' },
    { target: 'Darier Disease', source: 'Keratosis', value: '15' },
    { target: 'Erythrokeratodermia Variabilis', source: 'Keratosis', value: '15' },
    { target: 'Ichthyosis', source: 'Keratosis', value: '15' },
    { target: 'Keratoderma, Palmoplantar', source: 'Keratosis', value: '15' },
    { target: 'Keratosis, Actinic', source: 'Keratosis', value: '15' },
    { target: 'Keratosis, Seborrheic', source: 'Keratosis', value: '15' },
    { target: 'Parakeratosis', source: 'Keratosis', value: '15' },
    { target: 'Porokeratosis', source: 'Keratosis', value: '15' },
    { target: 'Cholesteatoma, Middle Ear', source: 'Cholesteatoma', value: '19' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Vulgaris', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis, X-Linked', source: 'Ichthyosis', value: '19' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Ichthyosis', value: '19' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Netherton Syndrome', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Keratoderma, Palmoplantar, Diffuse', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Papillon-Lefevre Disease', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Keratoderma, Palmoplantar, Epidermolytic', source: 'Keratoderma, Palmoplantar, Diffuse', value: '23' },
    { target: 'Adiposis Dolorosa', source: 'Lipomatosis', value: '15' },
    { target: 'Familial Multiple Lipomatosis', source: 'Lipomatosis', value: '15' },
    { target: 'Lipomatosis, Multiple Symmetrical', source: 'Lipomatosis', value: '15' },
    { target: 'Lupus Erythematosus, Discoid', source: 'Lupus Erythematosus, Cutaneous', value: '15' },
    { target: 'Panniculitis, Lupus Erythematosus', source: 'Lupus Erythematosus, Discoid', value: '19' },
    { target: 'Mastocytoma', source: 'Mastocytosis', value: '15' },
    { target: 'Mastocytosis, Cutaneous', source: 'Mastocytosis', value: '15' },
    { target: 'Mastocytoma, Skin', source: 'Mastocytoma', value: '19' },
    { target: 'Mastocytoma, Skin', source: 'Mastocytosis, Cutaneous', value: '19' },
    { target: 'Urticaria Pigmentosa', source: 'Mastocytosis, Cutaneous', value: '19' },
    { target: 'Nail-Patella Syndrome', source: 'Nail Diseases', value: '15' },
    { target: 'Nails, Ingrown', source: 'Nail Diseases', value: '15' },
    { target: 'Onycholysis', source: 'Nail Diseases', value: '15' },
    { target: 'Onychomycosis', source: 'Nail Diseases', value: '15' },
    { target: 'Pachyonychia Congenita', source: 'Nail Diseases', value: '15' },
    { target: 'Paronychia', source: 'Nail Diseases', value: '15' },
    { target: 'Yellow Nail Syndrome', source: 'Nail Diseases', value: '15' },
    { target: 'Steatocystoma Multiplex', source: 'Pachyonychia Congenita', value: '19' },
    { target: 'Granuloma Annulare', source: 'Necrobiotic Disorders', value: '15' },
    { target: 'Necrobiosis Lipoidica', source: 'Necrobiotic Disorders', value: '15' },
    { target: 'Necrobiotic Xanthogranuloma', source: 'Necrobiotic Disorders', value: '15' },
    { target: 'Erythema Induratum', source: 'Panniculitis', value: '15' },
    { target: 'Panniculitis, Lupus Erythematosus', source: 'Panniculitis', value: '15' },
    { target: 'Panniculitis, Nodular Nonsuppurative', source: 'Panniculitis', value: '15' },
    { target: 'Dermatitis, Photoallergic', source: 'Photosensitivity Disorders', value: '15' },
    { target: 'Dermatitis, Phototoxic', source: 'Photosensitivity Disorders', value: '15' },
    { target: 'Hydroa Vacciniforme', source: 'Photosensitivity Disorders', value: '15' },
    { target: 'Sunburn', source: 'Photosensitivity Disorders', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Photosensitivity Disorders', value: '15' },
    { target: 'Argyria', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Cafe-au-Lait Spots', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Hyperpigmentation', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Hypopigmentation', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Urticaria Pigmentosa', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Yellow Nail Syndrome', source: 'Pigmentation Disorders', value: '15' },
    { target: 'Melanosis', source: 'Hyperpigmentation', value: '19' },
    { target: 'Acanthosis Nigricans', source: 'Melanosis', value: '23' },
    { target: 'Lentigo', source: 'Melanosis', value: '23' },
    { target: 'LEOPARD Syndrome', source: 'Lentigo', value: '27' },
    { target: 'Peutz-Jeghers Syndrome', source: 'Lentigo', value: '27' },
    { target: 'Albinism', source: 'Hypopigmentation', value: '19' },
    { target: 'Vitiligo', source: 'Hypopigmentation', value: '19' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '23' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '23' },
    { target: 'Piebaldism', source: 'Albinism', value: '23' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '27' },
    { target: 'Pruritus Ani', source: 'Pruritus', value: '15' },
    { target: 'Pruritus Vulvae', source: 'Pruritus', value: '15' },
    { target: 'Ecthyma', source: 'Pyoderma', value: '15' },
    { target: 'Pyoderma Gangrenosum', source: 'Pyoderma', value: '15' },
    { target: 'Rhinophyma', source: 'Rosacea', value: '15' },
    { target: 'Dandruff', source: 'Scalp Dermatoses', value: '15' },
    { target: 'Tinea Capitis', source: 'Scalp Dermatoses', value: '15' },
    { target: 'Tinea Favosa', source: 'Tinea Capitis', value: '19' },
    { target: 'Scleroderma, Diffuse', source: 'Scleroderma, Systemic', value: '15' },
    { target: 'Scleroderma, Limited', source: 'Scleroderma, Systemic', value: '15' },
    { target: 'CREST Syndrome', source: 'Scleroderma, Limited', value: '19' },
    { target: 'Acne Vulgaris', source: 'Sebaceous Gland Diseases', value: '15' },
    { target: 'Dermatitis, Seborrheic', source: 'Sebaceous Gland Diseases', value: '15' },
    { target: 'Mucinosis, Follicular', source: 'Sebaceous Gland Diseases', value: '15' },
    { target: 'Rhinophyma', source: 'Sebaceous Gland Diseases', value: '15' },
    { target: 'Sebaceous Gland Neoplasms', source: 'Sebaceous Gland Diseases', value: '15' },
    { target: 'Acne Conglobata', source: 'Acne Vulgaris', value: '19' },
    { target: 'Muir-Torre Syndrome', source: 'Sebaceous Gland Neoplasms', value: '19' },
    { target: 'Acrodermatitis', source: 'Skin Abnormalities', value: '15' },
    { target: 'Anetoderma', source: 'Skin Abnormalities', value: '15' },
    { target: 'Dyskeratosis Congenita', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ectodermal Dysplasia', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Skin Abnormalities', value: '15' },
    { target: 'Epidermolysis Bullosa', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ichthyosis', source: 'Skin Abnormalities', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Skin Abnormalities', value: '15' },
    { target: 'Port-Wine Stain', source: 'Skin Abnormalities', value: '15' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Rothmund-Thomson Syndrome', source: 'Skin Abnormalities', value: '15' },
    { target: 'Sclerema Neonatorum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Trichothiodystrophy Syndromes', source: 'Skin Abnormalities', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Skin Abnormalities', value: '15' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia 3, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ellis-Van Creveld Syndrome', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Neurocutaneous Syndromes', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Pachyonychia Congenita', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Steatocystoma Multiplex', source: 'Pachyonychia Congenita', value: '23' },
    { target: 'Epidermolysis Bullosa Acquisita', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa, Junctional', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Simplex', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis Vulgaris', source: 'Ichthyosis', value: '19' },
    { target: 'Ichthyosis, X-Linked', source: 'Ichthyosis', value: '19' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Ichthyosis', value: '19' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Netherton Syndrome', source: 'Ichthyosiform Erythroderma, Congenital', value: '23' },
    { target: 'Dermatitis, Atopic', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Dermatitis, Contact', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Dermatitis, Exfoliative', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Dermatitis, Seborrheic', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Eczema', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Intertrigo', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Neurodermatitis', source: 'Skin Diseases, Eczematous', value: '15' },
    { target: 'Dermatitis, Allergic Contact', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Irritant', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Occupational', source: 'Dermatitis, Contact', value: '19' },
    { target: 'Dermatitis, Photoallergic', source: 'Dermatitis, Allergic Contact', value: '23' },
    { target: 'Dermatitis, Toxicodendron', source: 'Dermatitis, Allergic Contact', value: '23' },
    { target: 'Dermatitis, Phototoxic', source: 'Dermatitis, Irritant', value: '23' },
    { target: 'Diaper Rash', source: 'Dermatitis, Irritant', value: '23' },
    { target: 'Eczema, Dyshidrotic', source: 'Eczema', value: '19' },
    { target: 'Albinism', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Cutis Laxa', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Darier Disease', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Dermatitis, Atopic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Dyskeratosis Congenita', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ectodermal Dysplasia', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ehlers-Danlos Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Epidermolysis Bullosa', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Erythrokeratodermia Variabilis', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Hereditary Autoinflammatory Diseases', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Hyalinosis, Systemic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosiform Erythroderma, Congenital', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis Bullosa of Siemens', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis Vulgaris', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Ichthyosis, X-Linked', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Incontinentia Pigmenti', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Keratoderma, Palmoplantar', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Leukokeratosis, Hereditary Mucosal', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Monilethrix', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Muir-Torre Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Netherton Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Pemphigus, Benign Familial', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porokeratosis', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porphyria, Erythropoietic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Porphyrias, Hepatic', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Pseudoxanthoma Elasticum', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Rothmund-Thomson Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Trichothiodystrophy Syndromes', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'Skin Diseases, Genetic', value: '15' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '19' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '19' },
    { target: 'Piebaldism', source: 'Albinism', value: '19' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '23' },
    { target: 'Ectodermal Dysplasia 1, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia 3, Anhidrotic', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ectodermal Dysplasia, Hypohidrotic, Autosomal Recessive', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Ellis-Van Creveld Syndrome', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Focal Dermal Hypoplasia', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Neurocutaneous Syndromes', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Pachyonychia Congenita', source: 'Ectodermal Dysplasia', value: '19' },
    { target: 'Steatocystoma Multiplex', source: 'Pachyonychia Congenita', value: '23' },
    { target: 'Epidermolysis Bullosa Acquisita', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa, Junctional', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Simplex', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Behcet Syndrome', source: 'Hereditary Autoinflammatory Diseases', value: '19' },
    { target: 'Cryopyrin-Associated Periodic Syndromes', source: 'Hereditary Autoinflammatory Diseases', value: '19' },
    { target: 'Hyperkeratosis, Epidermolytic', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Ichthyosis, Lamellar', source: 'Ichthyosiform Erythroderma, Congenital', value: '19' },
    { target: 'Keratoderma, Palmoplantar, Diffuse', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Papillon-Lefevre Disease', source: 'Keratoderma, Palmoplantar', value: '19' },
    { target: 'Keratoderma, Palmoplantar, Epidermolytic', source: 'Keratoderma, Palmoplantar, Diffuse', value: '23' },
    { target: 'Coproporphyria, Hereditary', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Acute Intermittent', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria Cutanea Tarda', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Hepatoerythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Variegate', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Protoporphyria, Erythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Dermatomycoses', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Paronychia', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Skin Diseases, Bacterial', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Skin Diseases, Parasitic', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Skin Diseases, Viral', source: 'Skin Diseases, Infectious', value: '15' },
    { target: 'Blastomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Candidiasis, Chronic Mucocutaneous', source: 'Dermatomycoses', value: '19' },
    { target: 'Candidiasis, Cutaneous', source: 'Dermatomycoses', value: '19' },
    { target: 'Chromoblastomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Hyalohyphomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Lobomycosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Mycetoma', source: 'Dermatomycoses', value: '19' },
    { target: 'Sporotrichosis', source: 'Dermatomycoses', value: '19' },
    { target: 'Tinea', source: 'Dermatomycoses', value: '19' },
    { target: 'Tinea Versicolor', source: 'Dermatomycoses', value: '19' },
    { target: 'Alternariosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Aspergillosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Cerebral Phaeohyphomycosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Fusariosis', source: 'Hyalohyphomycosis', value: '23' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Aspergillosis', value: '27' },
    { target: 'Neuroaspergillosis', source: 'Aspergillosis', value: '27' },
    { target: 'Onychomycosis', source: 'Tinea', value: '23' },
    { target: 'Tinea Capitis', source: 'Tinea', value: '23' },
    { target: 'Tinea Pedis', source: 'Tinea', value: '23' },
    { target: 'Tinea Favosa', source: 'Tinea Capitis', value: '27' },
    { target: 'Actinomycosis, Cervicofacial', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Angiomatosis, Bacillary', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Digital Dermatitis', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Ecthyma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erysipelas', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erythema Chronicum Migrans', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Erythrasma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Granuloma Inguinale', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Hidradenitis Suppurativa', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Mycetoma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Pinta', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Rhinoscleroma', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Staphylococcal Skin Infections', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Syphilis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Tuberculosis, Cutaneous', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Yaws', source: 'Skin Diseases, Bacterial', value: '19' },
    { target: 'Furunculosis', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Impetigo', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Staphylococcal Scalded Skin Syndrome', source: 'Staphylococcal Skin Infections', value: '23' },
    { target: 'Carbuncle', source: 'Furunculosis', value: '27' },
    { target: 'Erythema Induratum', source: 'Tuberculosis, Cutaneous', value: '23' },
    { target: 'Lupus Vulgaris', source: 'Tuberculosis, Cutaneous', value: '23' },
    { target: 'Larva Migrans', source: 'Skin Diseases, Parasitic', value: '19' },
    { target: 'Leishmaniasis', source: 'Skin Diseases, Parasitic', value: '19' },
    { target: 'Lice Infestations', source: 'Skin Diseases, Parasitic', value: '19' },
    { target: 'Onchocerciasis', source: 'Skin Diseases, Parasitic', value: '19' },
    { target: 'Scabies', source: 'Skin Diseases, Parasitic', value: '19' },
    { target: 'Leishmaniasis, Cutaneous', source: 'Leishmaniasis', value: '23' },
    { target: 'Leishmaniasis, Diffuse Cutaneous', source: 'Leishmaniasis, Cutaneous', value: '27' },
    { target: 'Leishmaniasis, Mucocutaneous', source: 'Leishmaniasis, Cutaneous', value: '27' },
    { target: 'Erythema Infectiosum', source: 'Skin Diseases, Viral', value: '19' },
    { target: 'Exanthema Subitum', source: 'Skin Diseases, Viral', value: '19' },
    { target: 'Herpes Simplex', source: 'Skin Diseases, Viral', value: '19' },
    { target: 'Molluscum Contagiosum', source: 'Skin Diseases, Viral', value: '19' },
    { target: 'Warts', source: 'Skin Diseases, Viral', value: '19' },
    { target: 'Herpes Labialis', source: 'Herpes Simplex', value: '23' },
    { target: 'Kaposi Varicelliform Eruption', source: 'Herpes Simplex', value: '23' },
    { target: 'Condylomata Acuminata', source: 'Warts', value: '23' },
    { target: 'Epidermodysplasia Verruciformis', source: 'Warts', value: '23' },
    { target: 'Buschke-Lowenstein Tumor', source: 'Condylomata Acuminata', value: '27' },
    { target: 'Lipodystrophy', source: 'Skin Diseases, Metabolic', value: '15' },
    { target: 'Necrobiosis Lipoidica', source: 'Skin Diseases, Metabolic', value: '15' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Congenital Generalized', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Familial Partial', source: 'Lipodystrophy', value: '19' },
    { target: 'Dermatitis, Seborrheic', source: 'Skin Diseases, Papulosquamous', value: '15' },
    { target: 'Lichenoid Eruptions', source: 'Skin Diseases, Papulosquamous', value: '15' },
    { target: 'Parapsoriasis', source: 'Skin Diseases, Papulosquamous', value: '15' },
    { target: 'Pityriasis', source: 'Skin Diseases, Papulosquamous', value: '15' },
    { target: 'Psoriasis', source: 'Skin Diseases, Papulosquamous', value: '15' },
    { target: 'Lichen Nitidus', source: 'Lichenoid Eruptions', value: '19' },
    { target: 'Lichen Planus', source: 'Lichenoid Eruptions', value: '19' },
    { target: 'Lichen Sclerosus et Atrophicus', source: 'Lichenoid Eruptions', value: '19' },
    { target: 'Pityriasis Lichenoides', source: 'Lichenoid Eruptions', value: '19' },
    { target: 'Lichen Planus, Oral', source: 'Lichen Planus', value: '23' },
    { target: 'Pityriasis Lichenoides', source: 'Parapsoriasis', value: '19' },
    { target: 'Pityriasis Lichenoides', source: 'Pityriasis', value: '19' },
    { target: 'Pityriasis Rosea', source: 'Pityriasis', value: '19' },
    { target: 'Pityriasis Rubra Pilaris', source: 'Pityriasis', value: '19' },
    { target: 'Arthritis, Psoriatic', source: 'Psoriasis', value: '19' },
    { target: 'Angiomatosis, Bacillary', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Behcet Syndrome', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Giant Cell Arteritis', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Livedo Reticularis', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Malignant Atrophic Papulosis', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Mucocutaneous Lymph Node Syndrome', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Polyarteritis Nodosa', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Pyoderma Gangrenosum', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Sneddon Syndrome', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Takayasu Arteritis', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Urticaria', source: 'Skin Diseases, Vascular', value: '15' },
    { target: 'Angioedema', source: 'Urticaria', value: '19' },
    { target: 'Angioedemas, Hereditary', source: 'Angioedema', value: '23' },
    { target: 'Hereditary Angioedema Type III', source: 'Angioedemas, Hereditary', value: '27' },
    { target: 'Hereditary Angioedema Types I and II', source: 'Angioedemas, Hereditary', value: '27' },
    { target: 'Acantholysis', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Blister', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Dermatitis Herpetiformis', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Eczema, Dyshidrotic', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Epidermolysis Bullosa', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Erythema Multiforme', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Hydroa Vacciniforme', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Linear IgA Bullous Dermatosis', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Pemphigoid, Benign Mucous Membrane', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Pemphigoid, Bullous', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Pemphigoid Gestationis', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Pemphigus', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Pemphigus, Benign Familial', source: 'Skin Diseases, Vesiculobullous', value: '15' },
    { target: 'Epidermolysis Bullosa Acquisita', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Dystrophica', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa, Junctional', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Epidermolysis Bullosa Simplex', source: 'Epidermolysis Bullosa', value: '19' },
    { target: 'Stevens-Johnson Syndrome', source: 'Erythema Multiforme', value: '19' },
    { target: 'Sebaceous Gland Neoplasms', source: 'Skin Neoplasms', value: '15' },
    { target: 'Sweat Gland Neoplasms', source: 'Skin Neoplasms', value: '15' },
    { target: 'Muir-Torre Syndrome', source: 'Sebaceous Gland Neoplasms', value: '19' },
    { target: 'Buruli Ulcer', source: 'Skin Ulcer', value: '15' },
    { target: 'Leg Ulcer', source: 'Skin Ulcer', value: '15' },
    { target: 'Pressure Ulcer', source: 'Skin Ulcer', value: '15' },
    { target: 'Pyoderma Gangrenosum', source: 'Skin Ulcer', value: '15' },
    { target: 'Foot Ulcer', source: 'Leg Ulcer', value: '19' },
    { target: 'Varicose Ulcer', source: 'Leg Ulcer', value: '19' },
    { target: 'Diabetic Foot', source: 'Foot Ulcer', value: '23' },
    { target: 'Hidradenitis', source: 'Sweat Gland Diseases', value: '15' },
    { target: 'Hyperhidrosis', source: 'Sweat Gland Diseases', value: '15' },
    { target: 'Hypohidrosis', source: 'Sweat Gland Diseases', value: '15' },
    { target: 'Miliaria', source: 'Sweat Gland Diseases', value: '15' },
    { target: 'Sweat Gland Neoplasms', source: 'Sweat Gland Diseases', value: '15' },
    { target: 'Hidradenitis Suppurativa', source: 'Hidradenitis', value: '19' },
    { target: 'Sweating, Gustatory', source: 'Hyperhidrosis', value: '19' },
    { target: 'Fox-Fordyce Disease', source: 'Miliaria', value: '19' },
    { target: 'Acid-Base Imbalance', source: 'Metabolic Diseases', value: '11' },
    { target: 'Bone Diseases, Metabolic', source: 'Metabolic Diseases', value: '11' },
    { target: 'Brain Diseases, Metabolic', source: 'Metabolic Diseases', value: '11' },
    { target: 'Calcium Metabolism Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'DNA Repair-Deficiency Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'Glucose Metabolism Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'Hyperlactatemia', source: 'Metabolic Diseases', value: '11' },
    { target: 'Iron Metabolism Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'Lipid Metabolism Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'Malabsorption Syndromes', source: 'Metabolic Diseases', value: '11' },
    { target: 'Metabolic Syndrome X', source: 'Metabolic Diseases', value: '11' },
    { target: 'Metabolism, Inborn Errors', source: 'Metabolic Diseases', value: '11' },
    { target: 'Mitochondrial Diseases', source: 'Metabolic Diseases', value: '11' },
    { target: 'Phosphorus Metabolism Disorders', source: 'Metabolic Diseases', value: '11' },
    { target: 'Porphyrias', source: 'Metabolic Diseases', value: '11' },
    { target: 'Proteostasis Deficiencies', source: 'Metabolic Diseases', value: '11' },
    { target: 'Skin Diseases, Metabolic', source: 'Metabolic Diseases', value: '11' },
    { target: 'Wasting Syndrome', source: 'Metabolic Diseases', value: '11' },
    { target: 'Water-Electrolyte Imbalance', source: 'Metabolic Diseases', value: '11' },
    { target: 'Achlorhydria', source: 'Acid-Base Imbalance', value: '15' },
    { target: 'Acidosis', source: 'Acid-Base Imbalance', value: '15' },
    { target: 'Alkalosis', source: 'Acid-Base Imbalance', value: '15' },
    { target: 'Acidosis, Lactic', source: 'Acidosis', value: '19' },
    { target: 'Acidosis, Renal Tubular', source: 'Acidosis', value: '19' },
    { target: 'Acidosis, Respiratory', source: 'Acidosis', value: '19' },
    { target: 'Ketosis', source: 'Acidosis', value: '19' },
    { target: 'Diabetic Ketoacidosis', source: 'Ketosis', value: '23' },
    { target: 'Alkalosis, Respiratory', source: 'Alkalosis', value: '19' },
    { target: 'Bone Demineralization, Pathologic', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Osteoporosis', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Pseudohypoparathyroidism', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Rickets', source: 'Bone Diseases, Metabolic', value: '15' },
    { target: 'Decalcification, Pathologic', source: 'Bone Demineralization, Pathologic', value: '19' },
    { target: 'Female Athlete Triad Syndrome', source: 'Osteoporosis', value: '19' },
    { target: 'Osteoporosis, Postmenopausal', source: 'Osteoporosis', value: '19' },
    { target: 'Pseudopseudohypoparathyroidism', source: 'Pseudohypoparathyroidism', value: '19' },
    { target: 'Osteomalacia', source: 'Rickets', value: '19' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Rickets', value: '19' },
    { target: 'Rickets, Hypophosphatemic', source: 'Rickets', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Rickets, Hypophosphatemic', value: '23' },
    { target: 'Brain Diseases, Metabolic, Inborn', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Hepatic Encephalopathy', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Kernicterus', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Mitochondrial Encephalomyopathies', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Myelinolysis, Central Pontine', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Reye Syndrome', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Wernicke Encephalopathy', source: 'Brain Diseases, Metabolic', value: '15' },
    { target: 'Adrenoleukodystrophy', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Galactosemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hartnup Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Homocystinuria', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperlysinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Leigh Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Maple Syrup Urine Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MELAS Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MERRF Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Phenylketonurias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease, Infantile', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Tyrosinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '23' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Calcinosis', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Decalcification, Pathologic', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Hypercalcemia', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Hypocalcemia', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Pseudohypoparathyroidism', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Rickets', source: 'Calcium Metabolism Disorders', value: '15' },
    { target: 'Calciphylaxis', source: 'Calcinosis', value: '19' },
    { target: 'CREST Syndrome', source: 'Calcinosis', value: '19' },
    { target: 'Nephrocalcinosis', source: 'Calcinosis', value: '19' },
    { target: 'Vascular Calcification', source: 'Calcinosis', value: '19' },
    { target: 'Monckeberg Medial Calcific Sclerosis', source: 'Vascular Calcification', value: '23' },
    { target: 'Tetany', source: 'Hypocalcemia', value: '19' },
    { target: 'Pseudopseudohypoparathyroidism', source: 'Pseudohypoparathyroidism', value: '19' },
    { target: 'Osteomalacia', source: 'Rickets', value: '19' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Rickets', value: '19' },
    { target: 'Rickets, Hypophosphatemic', source: 'Rickets', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Rickets, Hypophosphatemic', value: '23' },
    { target: 'Ataxia Telangiectasia', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Bloom Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Cockayne Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Colorectal Neoplasms, Hereditary Nonpolyposis', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Fanconi Anemia', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Li-Fraumeni Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Nijmegen Breakage Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Rothmund-Thomson Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Severe Combined Immunodeficiency', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Werner Syndrome', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Xeroderma Pigmentosum', source: 'DNA Repair-Deficiency Disorders', value: '15' },
    { target: 'Diabetes Mellitus', source: 'Glucose Metabolism Disorders', value: '15' },
    { target: 'Glycosuria', source: 'Glucose Metabolism Disorders', value: '15' },
    { target: 'Hyperglycemia', source: 'Glucose Metabolism Disorders', value: '15' },
    { target: 'Hyperinsulinism', source: 'Glucose Metabolism Disorders', value: '15' },
    { target: 'Hypoglycemia', source: 'Glucose Metabolism Disorders', value: '15' },
    { target: 'Diabetes Mellitus, Experimental', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Diabetes Mellitus, Type 1', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Diabetes Mellitus, Type 2', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Diabetes, Gestational', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Diabetic Ketoacidosis', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Donohue Syndrome', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Latent Autoimmune Diabetes in Adults', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Prediabetic State', source: 'Diabetes Mellitus', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Diabetes Mellitus, Type 1', value: '23' },
    { target: 'Diabetes Mellitus, Lipoatrophic', source: 'Diabetes Mellitus, Type 2', value: '23' },
    { target: 'Glycosuria, Renal', source: 'Glycosuria', value: '19' },
    { target: 'Glucose Intolerance', source: 'Hyperglycemia', value: '19' },
    { target: 'Congenital Hyperinsulinism', source: 'Hyperinsulinism', value: '19' },
    { target: 'Insulin Resistance', source: 'Hyperinsulinism', value: '19' },
    { target: 'Nesidioblastosis', source: 'Congenital Hyperinsulinism', value: '23' },
    { target: 'Metabolic Syndrome X', source: 'Insulin Resistance', value: '23' },
    { target: 'Congenital Hyperinsulinism', source: 'Hypoglycemia', value: '19' },
    { target: 'Insulin Coma', source: 'Hypoglycemia', value: '19' },
    { target: 'Nesidioblastosis', source: 'Congenital Hyperinsulinism', value: '23' },
    { target: 'Anemia, Iron-Deficiency', source: 'Iron Metabolism Disorders', value: '15' },
    { target: 'Iron Overload', source: 'Iron Metabolism Disorders', value: '15' },
    { target: 'Hemochromatosis', source: 'Iron Overload', value: '19' },
    { target: 'Hemosiderosis', source: 'Iron Overload', value: '19' },
    { target: 'Dyslipidemias', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Lipid Metabolism, Inborn Errors', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Lipodystrophy', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Lipidoses', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Lipomatosis', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Xanthomatosis', source: 'Lipid Metabolism Disorders', value: '15' },
    { target: 'Hyperlipidemias', source: 'Dyslipidemias', value: '19' },
    { target: 'Hypolipoproteinemias', source: 'Dyslipidemias', value: '19' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Dyslipidemias', value: '19' },
    { target: 'Hypercholesterolemia', source: 'Hyperlipidemias', value: '23' },
    { target: 'Hyperlipidemia, Familial Combined', source: 'Hyperlipidemias', value: '23' },
    { target: 'Hyperlipoproteinemias', source: 'Hyperlipidemias', value: '23' },
    { target: 'Hypertriglyceridemia', source: 'Hyperlipidemias', value: '23' },
    { target: 'Hyperlipoproteinemia Type I', source: 'Hyperlipoproteinemias', value: '27' },
    { target: 'Hyperlipoproteinemia Type II', source: 'Hyperlipoproteinemias', value: '27' },
    { target: 'Hyperlipoproteinemia Type III', source: 'Hyperlipoproteinemias', value: '27' },
    { target: 'Hyperlipoproteinemia Type IV', source: 'Hyperlipoproteinemias', value: '27' },
    { target: 'Hyperlipoproteinemia Type V', source: 'Hyperlipoproteinemias', value: '27' },
    { target: 'Hyperlipoproteinemia Type IV', source: 'Hypertriglyceridemia', value: '27' },
    { target: 'Hyperlipoproteinemia Type V', source: 'Hypertriglyceridemia', value: '27' },
    { target: 'Hypertriglyceridemic Waist', source: 'Hypertriglyceridemia', value: '27' },
    { target: 'Hypoalphalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Hypobetalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Lecithin Cholesterol Acyltransferase Deficiency', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Tangier Disease', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Abetalipoproteinemia', source: 'Hypobetalipoproteinemias', value: '27' },
    { target: 'Hypobetalipoproteinemia, Familial, Apolipoprotein B', source: 'Hypobetalipoproteinemias', value: '27' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Congenital Generalized', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Familial Partial', source: 'Lipodystrophy', value: '19' },
    { target: 'Cholesterol Ester Storage Disease', source: 'Lipidoses', value: '19' },
    { target: 'Neuronal Ceroid-Lipofuscinoses', source: 'Lipidoses', value: '19' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Lipidoses', value: '19' },
    { target: 'Sphingolipidoses', source: 'Lipidoses', value: '19' },
    { target: 'Wolman Disease', source: 'Cholesterol Ester Storage Disease', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '23' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '23' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '23' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '23' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '23' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '23' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '23' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '23' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '27' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '27' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '31' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '31' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '31' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '27' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '27' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '27' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '27' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '27' },
    { target: 'Adiposis Dolorosa', source: 'Lipomatosis', value: '19' },
    { target: 'Familial Multiple Lipomatosis', source: 'Lipomatosis', value: '19' },
    { target: 'Lipomatosis, Multiple Symmetrical', source: 'Lipomatosis', value: '19' },
    { target: 'Necrobiotic Xanthogranuloma', source: 'Xanthomatosis', value: '19' },
    { target: 'Xanthomatosis, Cerebrotendinous', source: 'Xanthomatosis', value: '19' },
    { target: 'Blind Loop Syndrome', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Celiac Disease', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Collagenous Sprue', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Hyperhomocysteinemia', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Lactose Intolerance', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Sprue, Tropical', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Steatorrhea', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Whipple Disease', source: 'Malabsorption Syndromes', value: '15' },
    { target: 'Amino Acid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Amino Acid Transport Disorders, Inborn', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Amyloidosis, Familial', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Brain Diseases, Metabolic, Inborn', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Carbohydrate Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Hyperbilirubinemia, Hereditary', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Lipid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Lysosomal Storage Diseases', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Metal Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Peroxisomal Disorders', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Progeria', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Purine-Pyrimidine Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Renal Tubular Transport, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Steroid Metabolism, Inborn Errors', source: 'Metabolism, Inborn Errors', value: '15' },
    { target: 'Albinism', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Alkaptonuria', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperhomocysteinemia', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlysinemias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Maple Syrup Urine Disease', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Acyl Coenzyme A Dehydrogenase Deficiency', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Carboxylase Deficiency', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Phenylketonurias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Propionic Acidemia', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Tyrosinemias', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Amino Acid Metabolism, Inborn Errors', value: '19' },
    { target: 'Albinism, Ocular', source: 'Albinism', value: '23' },
    { target: 'Albinism, Oculocutaneous', source: 'Albinism', value: '23' },
    { target: 'Piebaldism', source: 'Albinism', value: '23' },
    { target: 'Hermanski-Pudlak Syndrome', source: 'Albinism, Oculocutaneous', value: '27' },
    { target: 'Homocystinuria', source: 'Hyperhomocysteinemia', value: '23' },
    { target: 'Biotinidase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Holocarboxylase Synthetase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '23' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hartnup Disease', source: 'Amino Acid Transport Disorders, Inborn', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Amino Acid Transport Disorders, Inborn', value: '19' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Amyloidosis, Familial', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Amyloidosis, Familial', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Galactosemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hartnup Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hereditary Central Nervous System Demyelinating Diseases', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Homocystinuria', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperglycinemia, Nonketotic', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Hyperlysinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Leigh Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Maple Syrup Urine Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MELAS Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'MERRF Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Phenylketonurias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Refsum Disease, Infantile', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Tyrosinemias', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Urea Cycle Disorders, Inborn', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Brain Diseases, Metabolic, Inborn', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Alexander Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Canavan Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Pelizaeus-Merzbacher Disease', source: 'Hereditary Central Nervous System Demyelinating Diseases', value: '23' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'Phenylketonuria, Maternal', source: 'Phenylketonurias', value: '23' },
    { target: 'Argininosuccinic Aciduria', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Citrullinemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Hyperargininemia', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Ornithine Carbamoyltransferase Deficiency Disease', source: 'Urea Cycle Disorders, Inborn', value: '23' },
    { target: 'Congenital Disorders of Glycosylation', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fructose Metabolism, Inborn Errors', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fucosidosis', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Galactosemias', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Glucosephosphate Dehydrogenase Deficiency', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Glycogen Storage Disease', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperoxaluria, Primary', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Lactose Intolerance', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mannosidase Deficiency Diseases', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mucolipidoses', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Mucopolysaccharidoses', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Multiple Carboxylase Deficiency', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Pyruvate Metabolism, Inborn Errors', source: 'Carbohydrate Metabolism, Inborn Errors', value: '19' },
    { target: 'Fructose-1,6-Diphosphatase Deficiency', source: 'Fructose Metabolism, Inborn Errors', value: '23' },
    { target: 'Fructose Intolerance', source: 'Fructose Metabolism, Inborn Errors', value: '23' },
    { target: 'Glycogen Storage Disease Type I', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type IIb', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type III', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type IV', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type V', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VI', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VII', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'Glycogen Storage Disease Type VIII', source: 'Glycogen Storage Disease', value: '23' },
    { target: 'alpha-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'beta-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'Mucopolysaccharidosis I', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis II', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis III', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis IV', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VI', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VII', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Biotinidase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Holocarboxylase Synthetase Deficiency', source: 'Multiple Carboxylase Deficiency', value: '23' },
    { target: 'Leigh Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Pyruvate Metabolism, Inborn Errors', value: '23' },
    { target: 'Crigler-Najjar Syndrome', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Gilbert Disease', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Jaundice, Chronic Idiopathic', source: 'Hyperbilirubinemia, Hereditary', value: '19' },
    { target: 'Barth Syndrome', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipidemia, Familial Combined', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type I', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type II', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type III', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type IV', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hyperlipoproteinemia Type V', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypolipoproteinemias', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Lipidoses', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Lipodystrophy, Congenital Generalized', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Xanthomatosis, Cerebrotendinous', source: 'Lipid Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypoalphalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Hypobetalipoproteinemias', source: 'Hypolipoproteinemias', value: '23' },
    { target: 'Lecithin Cholesterol Acyltransferase Deficiency', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Tangier Disease', source: 'Hypoalphalipoproteinemias', value: '27' },
    { target: 'Abetalipoproteinemia', source: 'Hypobetalipoproteinemias', value: '27' },
    { target: 'Cholesterol Ester Storage Disease', source: 'Lipidoses', value: '23' },
    { target: 'Neuronal Ceroid-Lipofuscinoses', source: 'Lipidoses', value: '23' },
    { target: 'Sjogren-Larsson Syndrome', source: 'Lipidoses', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lipidoses', value: '23' },
    { target: 'Wolman Disease', source: 'Cholesterol Ester Storage Disease', value: '27' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'Aspartylglucosaminuria', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Cholesterol Ester Storage Disease', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Cystinosis', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Lysosomal Storage Diseases, Nervous System', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Mannosidase Deficiency Diseases', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Mucopolysaccharidoses', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Pycnodysostosis', source: 'Lysosomal Storage Diseases', value: '19' },
    { target: 'Wolman Disease', source: 'Cholesterol Ester Storage Disease', value: '23' },
    { target: 'Fucosidosis', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Glycogen Storage Disease Type II', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Mucolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sialic Acid Storage Disease', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Sphingolipidoses', source: 'Lysosomal Storage Diseases, Nervous System', value: '23' },
    { target: 'Fabry Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Farber Lipogranulomatosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gaucher Disease', source: 'Sphingolipidoses', value: '27' },
    { target: 'Leukodystrophy, Globoid Cell', source: 'Sphingolipidoses', value: '27' },
    { target: 'Niemann-Pick Diseases', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sea-Blue Histiocyte Syndrome', source: 'Sphingolipidoses', value: '27' },
    { target: 'Sulfatidosis', source: 'Sphingolipidoses', value: '27' },
    { target: 'Gangliosidoses, GM2', source: 'Gangliosidoses', value: '31' },
    { target: 'Gangliosidosis, GM1', source: 'Gangliosidoses', value: '31' },
    { target: 'Sandhoff Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Tay-Sachs Disease, AB Variant', source: 'Gangliosidoses, GM2', value: '35' },
    { target: 'Niemann-Pick Disease, Type A', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type B', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Niemann-Pick Disease, Type C', source: 'Niemann-Pick Diseases', value: '31' },
    { target: 'Leukodystrophy, Metachromatic', source: 'Sulfatidosis', value: '31' },
    { target: 'Multiple Sulfatase Deficiency Disease', source: 'Sulfatidosis', value: '31' },
    { target: 'alpha-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'beta-Mannosidosis', source: 'Mannosidase Deficiency Diseases', value: '23' },
    { target: 'Mucopolysaccharidosis I', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis II', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis III', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis IV', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VI', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Mucopolysaccharidosis VII', source: 'Mucopolysaccharidoses', value: '23' },
    { target: 'Hemochromatosis', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hepatolenticular Degeneration', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypophosphatasia', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Hypophosphatemia, Familial', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Menkes Kinky Hair Syndrome', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Paralyses, Familial Periodic', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Pseudohypoparathyroidism', source: 'Metal Metabolism, Inborn Errors', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Hypokalemic Periodic Paralysis', source: 'Paralyses, Familial Periodic', value: '23' },
    { target: 'Paralysis, Hyperkalemic Periodic', source: 'Paralyses, Familial Periodic', value: '23' },
    { target: 'Pseudopseudohypoparathyroidism', source: 'Pseudohypoparathyroidism', value: '23' },
    { target: 'Acatalasia', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Adrenoleukodystrophy', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Chondrodysplasia Punctata, Rhizomelic', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Refsum Disease', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Refsum Disease, Infantile', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Zellweger Syndrome', source: 'Peroxisomal Disorders', value: '19' },
    { target: 'Dihydropyrimidine Dehydrogenase Deficiency', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Gout', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Lesch-Nyhan Syndrome', source: 'Purine-Pyrimidine Metabolism, Inborn Errors', value: '19' },
    { target: 'Arthritis, Gouty', source: 'Gout', value: '23' },
    { target: 'Acidosis, Renal Tubular', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Dent Disease', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Fanconi Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Gitelman Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Glycosuria, Renal', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Hypophosphatemia, Familial', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Liddle Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Oculocerebrorenal Syndrome', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Pseudohypoaldosteronism', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Renal Aminoacidurias', source: 'Renal Tubular Transport, Inborn Errors', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Cystinuria', source: 'Renal Aminoacidurias', value: '23' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Antley-Bixler Syndrome Phenotype', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Ichthyosis, X-Linked', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Mineralocorticoid Excess Syndrome, Apparent', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Smith-Lemli-Opitz Syndrome', source: 'Steroid Metabolism, Inborn Errors', value: '19' },
    { target: 'Carbamoyl-Phosphate Synthase I Deficiency Disease', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Cytochrome-c Oxidase Deficiency', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Friedreich Ataxia', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Kearns-Sayre Syndrome', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Leigh Disease', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Mitochondrial Myopathies', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Multiple Acyl Coenzyme A Dehydrogenase Deficiency', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Optic Atrophy, Autosomal Dominant', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Optic Atrophy, Hereditary, Leber', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Pyruvate Carboxylase Deficiency Disease', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Pyruvate Dehydrogenase Complex Deficiency Disease', source: 'Mitochondrial Diseases', value: '15' },
    { target: 'Mitochondrial Encephalomyopathies', source: 'Mitochondrial Myopathies', value: '19' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Mitochondrial Myopathies', value: '19' },
    { target: 'MELAS Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '23' },
    { target: 'MERRF Syndrome', source: 'Mitochondrial Encephalomyopathies', value: '23' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '23' },
    { target: 'Hyperphosphatemia', source: 'Phosphorus Metabolism Disorders', value: '15' },
    { target: 'Hypophosphatemia', source: 'Phosphorus Metabolism Disorders', value: '15' },
    { target: 'Hypophosphatemia, Familial', source: 'Hypophosphatemia', value: '19' },
    { target: 'Rickets, Hypophosphatemic', source: 'Hypophosphatemia', value: '19' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Hypophosphatemia, Familial', value: '23' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Rickets, Hypophosphatemic', value: '23' },
    { target: 'Porphyria, Erythropoietic', source: 'Porphyrias', value: '15' },
    { target: 'Porphyrias, Hepatic', source: 'Porphyrias', value: '15' },
    { target: 'Coproporphyria, Hereditary', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Acute Intermittent', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria Cutanea Tarda', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Hepatoerythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Porphyria, Variegate', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Protoporphyria, Erythropoietic', source: 'Porphyrias, Hepatic', value: '19' },
    { target: 'Amyloidosis', source: 'Proteostasis Deficiencies', value: '15' },
    { target: 'TDP-43 Proteinopathies', source: 'Proteostasis Deficiencies', value: '15' },
    { target: 'Amyloid Neuropathies', source: 'Amyloidosis', value: '19' },
    { target: 'Amyloidosis, Familial', source: 'Amyloidosis', value: '19' },
    { target: 'Cerebral Amyloid Angiopathy', source: 'Amyloidosis', value: '19' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Amyloid Neuropathies', value: '23' },
    { target: 'Amyloid Neuropathies, Familial', source: 'Amyloidosis, Familial', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Amyloidosis, Familial', value: '23' },
    { target: 'Cerebral Amyloid Angiopathy, Familial', source: 'Cerebral Amyloid Angiopathy', value: '23' },
    { target: 'Amyotrophic Lateral Sclerosis', source: 'TDP-43 Proteinopathies', value: '19' },
    { target: 'Frontotemporal Lobar Degeneration', source: 'TDP-43 Proteinopathies', value: '19' },
    { target: 'Frontotemporal Dementia', source: 'Frontotemporal Lobar Degeneration', value: '23' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Frontotemporal Lobar Degeneration', value: '23' },
    { target: 'Lipodystrophy', source: 'Skin Diseases, Metabolic', value: '15' },
    { target: 'Necrobiosis Lipoidica', source: 'Skin Diseases, Metabolic', value: '15' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Congenital Generalized', source: 'Lipodystrophy', value: '19' },
    { target: 'Lipodystrophy, Familial Partial', source: 'Lipodystrophy', value: '19' },
    { target: 'HIV Wasting Syndrome', source: 'Wasting Syndrome', value: '15' },
    { target: 'Dehydration', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hypercalcemia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hyperkalemia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hypernatremia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hypocalcemia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hypokalemia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Hyponatremia', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Inappropriate ADH Syndrome', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Water Intoxication', source: 'Water-Electrolyte Imbalance', value: '15' },
    { target: 'Child Nutrition Disorders', source: 'Nutrition Disorders', value: '11' },
    { target: 'Hypervitaminosis A', source: 'Nutrition Disorders', value: '11' },
    { target: 'Infant Nutrition Disorders', source: 'Nutrition Disorders', value: '11' },
    { target: 'Malnutrition', source: 'Nutrition Disorders', value: '11' },
    { target: 'Overnutrition', source: 'Nutrition Disorders', value: '11' },
    { target: 'Wasting Syndrome', source: 'Nutrition Disorders', value: '11' },
    { target: 'Vitamin K Deficiency Bleeding', source: 'Infant Nutrition Disorders', value: '15' },
    { target: 'Deficiency Diseases', source: 'Malnutrition', value: '15' },
    { target: 'Fetal Nutrition Disorders', source: 'Malnutrition', value: '15' },
    { target: 'Refeeding Syndrome', source: 'Malnutrition', value: '15' },
    { target: 'Severe Acute Malnutrition', source: 'Malnutrition', value: '15' },
    { target: 'Starvation', source: 'Malnutrition', value: '15' },
    { target: 'Avitaminosis', source: 'Deficiency Diseases', value: '19' },
    { target: 'Magnesium Deficiency', source: 'Deficiency Diseases', value: '19' },
    { target: 'Potassium Deficiency', source: 'Deficiency Diseases', value: '19' },
    { target: 'Protein Deficiency', source: 'Deficiency Diseases', value: '19' },
    { target: 'Swayback', source: 'Deficiency Diseases', value: '19' },
    { target: 'Ascorbic Acid Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Vitamin A Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Vitamin B Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Vitamin D Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Vitamin E Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Vitamin K Deficiency', source: 'Avitaminosis', value: '23' },
    { target: 'Scurvy', source: 'Ascorbic Acid Deficiency', value: '27' },
    { target: 'Choline Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Folic Acid Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Hyperhomocysteinemia', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Pellagra', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Riboflavin Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Thiamine Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Vitamin B 6 Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Vitamin B 12 Deficiency', source: 'Vitamin B Deficiency', value: '27' },
    { target: 'Beriberi', source: 'Thiamine Deficiency', value: '31' },
    { target: 'Wernicke Encephalopathy', source: 'Thiamine Deficiency', value: '31' },
    { target: 'Anemia, Pernicious', source: 'Vitamin B 12 Deficiency', value: '31' },
    { target: 'Subacute Combined Degeneration', source: 'Vitamin B 12 Deficiency', value: '31' },
    { target: 'Rickets', source: 'Vitamin D Deficiency', value: '27' },
    { target: 'Osteomalacia', source: 'Rickets', value: '31' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Rickets', value: '31' },
    { target: 'Rickets, Hypophosphatemic', source: 'Rickets', value: '31' },
    { target: 'Familial Hypophosphatemic Rickets', source: 'Rickets, Hypophosphatemic', value: '35' },
    { target: 'Steatitis', source: 'Vitamin E Deficiency', value: '27' },
    { target: 'Vitamin K Deficiency Bleeding', source: 'Vitamin K Deficiency', value: '27' },
    { target: 'Protein-Energy Malnutrition', source: 'Protein Deficiency', value: '23' },
    { target: 'Kwashiorkor', source: 'Severe Acute Malnutrition', value: '19' },
    { target: 'Obesity', source: 'Overnutrition', value: '15' },
    { target: 'Obesity Hypoventilation Syndrome', source: 'Obesity', value: '19' },
    { target: 'Obesity, Abdominal', source: 'Obesity', value: '19' },
    { target: 'Obesity, Metabolically Benign', source: 'Obesity', value: '19' },
    { target: 'Obesity, Morbid', source: 'Obesity', value: '19' },
    { target: 'Pediatric Obesity', source: 'Obesity', value: '19' },
    { target: 'Prader-Willi Syndrome', source: 'Obesity', value: '19' },
    { target: 'HIV Wasting Syndrome', source: 'Wasting Syndrome', value: '15' },
    { target: 'Adrenal Cortex Diseases', source: 'Adrenal Gland Diseases', value: '11' },
    { target: 'Adrenal Gland Neoplasms', source: 'Adrenal Gland Diseases', value: '11' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Adrenal Gland Diseases', value: '11' },
    { target: 'Adrenal Insufficiency', source: 'Adrenal Gland Diseases', value: '11' },
    { target: 'Adrenocortical Hyperfunction', source: 'Adrenal Gland Diseases', value: '11' },
    { target: 'Adrenal Cortex Neoplasms', source: 'Adrenal Cortex Diseases', value: '15' },
    { target: 'Adrenocortical Adenoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Adrenocortical Carcinoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Adrenal Cortex Neoplasms', source: 'Adrenal Gland Neoplasms', value: '15' },
    { target: 'Adrenocortical Adenoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Adrenocortical Carcinoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Addison Disease', source: 'Adrenal Insufficiency', value: '15' },
    { target: 'Adrenoleukodystrophy', source: 'Adrenal Insufficiency', value: '15' },
    { target: 'Hypoaldosteronism', source: 'Adrenal Insufficiency', value: '15' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Adrenal Insufficiency', value: '15' },
    { target: 'Cushing Syndrome', source: 'Adrenocortical Hyperfunction', value: '15' },
    { target: 'Hyperaldosteronism', source: 'Adrenocortical Hyperfunction', value: '15' },
    { target: 'Bartter Syndrome', source: 'Hyperaldosteronism', value: '19' },
    { target: 'Diabetes Complications', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Diabetes, Gestational', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Diabetes Mellitus, Experimental', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Diabetes Mellitus, Type 1', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Diabetes Mellitus, Type 2', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Donohue Syndrome', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Latent Autoimmune Diabetes in Adults', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Prediabetic State', source: 'Diabetes Mellitus', value: '11' },
    { target: 'Diabetic Angiopathies', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Cardiomyopathies', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Coma', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Ketoacidosis', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Nephropathies', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Neuropathies', source: 'Diabetes Complications', value: '15' },
    { target: 'Fetal Macrosomia', source: 'Diabetes Complications', value: '15' },
    { target: 'Diabetic Foot', source: 'Diabetic Angiopathies', value: '19' },
    { target: 'Diabetic Retinopathy', source: 'Diabetic Angiopathies', value: '19' },
    { target: 'Hyperglycemic Hyperosmolar Nonketotic Coma', source: 'Diabetic Coma', value: '19' },
    { target: 'Diabetic Foot', source: 'Diabetic Neuropathies', value: '19' },
    { target: 'Wolfram Syndrome', source: 'Diabetes Mellitus, Type 1', value: '15' },
    { target: 'Diabetes Mellitus, Lipoatrophic', source: 'Diabetes Mellitus, Type 2', value: '15' },
    { target: 'Congenital Hypothyroidism', source: 'Dwarfism', value: '11' },
    { target: 'Dwarfism, Pituitary', source: 'Dwarfism', value: '11' },
    { target: 'Laron Syndrome', source: 'Dwarfism', value: '11' },
    { target: 'Adrenal Gland Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Multiple Endocrine Neoplasia', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Ovarian Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Pancreatic Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Paraneoplastic Endocrine Syndromes', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Parathyroid Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Pituitary Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Testicular Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Thyroid Neoplasms', source: 'Endocrine Gland Neoplasms', value: '11' },
    { target: 'Adrenal Cortex Neoplasms', source: 'Adrenal Gland Neoplasms', value: '15' },
    { target: 'Adrenocortical Adenoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Adrenocortical Carcinoma', source: 'Adrenal Cortex Neoplasms', value: '19' },
    { target: 'Multiple Endocrine Neoplasia Type 1', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2a', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Multiple Endocrine Neoplasia Type 2b', source: 'Multiple Endocrine Neoplasia', value: '15' },
    { target: 'Granulosa Cell Tumor', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Luteoma', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Meigs Syndrome', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Thecoma', source: 'Ovarian Neoplasms', value: '15' },
    { target: 'Adenoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Islet Cell', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Carcinoma, Pancreatic Ductal', source: 'Pancreatic Neoplasms', value: '15' },
    { target: 'Insulinoma', source: 'Adenoma, Islet Cell', value: '19' },
    { target: 'Gastrinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Glucagonoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Somatostatinoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'Vipoma', source: 'Carcinoma, Islet Cell', value: '19' },
    { target: 'ACTH-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Growth Hormone-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Prolactinoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Nelson Syndrome', source: 'ACTH-Secreting Pituitary Adenoma', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '15' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '19' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '19' },
    { target: 'Thyroid Nodule', source: 'Thyroid Neoplasms', value: '15' },
    { target: 'Disorders of Sex Development', source: 'Gonadal Disorders', value: '11' },
    { target: 'Hypogonadism', source: 'Gonadal Disorders', value: '11' },
    { target: 'Ovarian Diseases', source: 'Gonadal Disorders', value: '11' },
    { target: 'Puberty, Delayed', source: 'Gonadal Disorders', value: '11' },
    { target: 'Puberty, Precocious', source: 'Gonadal Disorders', value: '11' },
    { target: 'Testicular Diseases', source: 'Gonadal Disorders', value: '11' },
    { target: '46, XX Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: '46, XY Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Adrenogenital Syndrome', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Gonadal Dysgenesis', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Ovotesticular Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: 'Sex Chromosome Disorders of Sex Development', source: 'Disorders of Sex Development', value: '15' },
    { target: '46, XX Testicular Disorders of Sex Development', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Hyperandrogenism', source: '46, XX Disorders of Sex Development', value: '19' },
    { target: 'Androgen-Insensitivity Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Denys-Drash Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Frasier Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Kallmann Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'WAGR Syndrome', source: '46, XY Disorders of Sex Development', value: '19' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '23' },
    { target: 'Adrenal Hyperplasia, Congenital', source: 'Adrenogenital Syndrome', value: '19' },
    { target: 'Hyperandrogenism', source: 'Adrenogenital Syndrome', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XX', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadal Dysgenesis, 46,XY', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Sexual Infantilism', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Turner Syndrome', source: 'Gonadal Dysgenesis', value: '19' },
    { target: 'Gonadoblastoma', source: 'Gonadal Dysgenesis, 46,XY', value: '23' },
    { target: 'Freemartinism', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Gonadal Dysgenesis, Mixed', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Klinefelter Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Turner Syndrome', source: 'Sex Chromosome Disorders of Sex Development', value: '19' },
    { target: 'Eunuchism', source: 'Hypogonadism', value: '15' },
    { target: 'Kallmann Syndrome', source: 'Hypogonadism', value: '15' },
    { target: 'Klinefelter Syndrome', source: 'Hypogonadism', value: '15' },
    { target: 'Sexual Infantilism', source: 'Hypogonadism', value: '15' },
    { target: 'Anovulation', source: 'Ovarian Diseases', value: '15' },
    { target: 'Oophoritis', source: 'Ovarian Diseases', value: '15' },
    { target: 'Ovarian Cysts', source: 'Ovarian Diseases', value: '15' },
    { target: 'Ovarian Hyperstimulation Syndrome', source: 'Ovarian Diseases', value: '15' },
    { target: 'Ovarian Neoplasms', source: 'Ovarian Diseases', value: '15' },
    { target: 'Primary Ovarian Insufficiency', source: 'Ovarian Diseases', value: '15' },
    { target: 'Polycystic Ovary Syndrome', source: 'Ovarian Cysts', value: '19' },
    { target: 'Brenner Tumor', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Carcinoma, Endometrioid', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Granulosa Cell Tumor', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Hereditary Breast and Ovarian Cancer Syndrome', souce: 'Ovarian Neoplasms', value: '19' },
    { target: 'Luteoma', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Meigs Syndrome', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Thecoma', source: 'Ovarian Neoplasms', value: '19' },
    { target: 'Cryptorchidism', source: 'Testicular Diseases', value: '15' },
    { target: 'Orchitis', source: 'Testicular Diseases', value: '15' },
    { target: 'Testicular Neoplasms', source: 'Testicular Diseases', value: '15' },
    { target: 'Sertoli-Leydig Cell Tumor', source: 'Testicular Neoplasms', value: '19' },
    { target: 'Leydig Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Sertoli Cell Tumor', source: 'Sertoli-Leydig Cell Tumor', value: '23' },
    { target: 'Hyperparathyroidism', source: 'Parathyroid Diseases', value: '11' },
    { target: 'Hypoparathyroidism', source: 'Parathyroid Diseases', value: '11' },
    { target: 'Parathyroid Neoplasms', source: 'Parathyroid Diseases', value: '11' },
    { target: 'Hyperparathyroidism, Primary', source: 'Hyperparathyroidism', value: '15' },
    { target: 'Hyperparathyroidism, Secondary', source: 'Hyperparathyroidism', value: '15' },
    { target: 'Chronic Kidney Disease-Mineral and Bone Disorder', source: 'Hyperparathyroidism, Secondary', value: '19' },
    { target: '22q11 Deletion Syndrome', source: 'Hypoparathyroidism', value: '15' },
    { target: 'DiGeorge Syndrome', source: '22q11 Deletion Syndrome', value: '19' },
    { target: 'Diabetes Insipidus', source: 'Pituitary Diseases', value: '11' },
    { target: 'Empty Sella Syndrome', source: 'Pituitary Diseases', value: '11' },
    { target: 'Hyperpituitarism', source: 'Pituitary Diseases', value: '11' },
    { target: 'Hypophysitis', source: 'Pituitary Diseases', value: '11' },
    { target: 'Hypopituitarism', source: 'Pituitary Diseases', value: '11' },
    { target: 'Inappropriate ADH Syndrome', source: 'Pituitary Diseases', value: '11' },
    { target: 'Pituitary Apoplexy', source: 'Pituitary Diseases', value: '11' },
    { target: 'Pituitary Neoplasms', source: 'Pituitary Diseases', value: '11' },
    { target: 'Diabetes Insipidus, Neurogenic', source: 'Diabetes Insipidus', value: '15' },
    { target: 'Wolfram Syndrome', source: 'Diabetes Insipidus', value: '15' },
    { target: 'Acromegaly', source: 'Hyperpituitarism', value: '15' },
    { target: 'Gigantism', source: 'Hyperpituitarism', value: '15' },
    { target: 'Hyperprolactinemia', source: 'Hyperpituitarism', value: '15' },
    { target: 'Pituitary ACTH Hypersecretion', source: 'Hyperpituitarism', value: '15' },
    { target: 'Autoimmune Hypophysitis', source: 'Hypophysitis', value: '15' },
    { target: 'Dwarfism, Pituitary', source: 'Hypopituitarism', value: '15' },
    { target: 'ACTH-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Growth Hormone-Secreting Pituitary Adenoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Prolactinoma', source: 'Pituitary Neoplasms', value: '15' },
    { target: 'Nelson Syndrome', source: 'ACTH-Secreting Pituitary Adenoma', value: '19' },
    { target: 'Euthyroid Sick Syndromes', source: 'Thyroid Diseases', value: '11' },
    { target: 'Goiter', source: 'Thyroid Diseases', value: '11' },
    { target: 'Hyperthyroidism', source: 'Thyroid Diseases', value: '11' },
    { target: 'Hyperthyroxinemia', source: 'Thyroid Diseases', value: '11' },
    { target: 'Hypothyroidism', source: 'Thyroid Diseases', value: '11' },
    { target: 'Thyroid Dysgenesis', source: 'Thyroid Diseases', value: '11' },
    { target: 'Thyroid Neoplasms', source: 'Thyroid Diseases', value: '11' },
    { target: 'Thyroiditis', source: 'Thyroid Diseases', value: '11' },
    { target: 'Goiter, Endemic', source: 'Goiter', value: '15' },
    { target: 'Goiter, Nodular', source: 'Goiter', value: '15' },
    { target: 'Goiter, Substernal', source: 'Goiter', value: '15' },
    { target: 'Graves Disease', source: 'Goiter', value: '15' },
    { target: 'Lingual Goiter', source: 'Goiter', value: '15' },
    { target: 'Graves Ophthalmopathy', source: 'Graves Disease', value: '19' },
    { target: 'Graves Disease', source: 'Hyperthyroidism', value: '15' },
    { target: 'Thyrotoxicosis', source: 'Hyperthyroidism', value: '15' },
    { target: 'Graves Ophthalmopathy', source: 'Graves Disease', value: '19' },
    { target: 'Thyroid Crisis', source: 'Thyrotoxicosis', value: '19' },
    { target: 'Hyperthyroxinemia, Familial Dysalbuminemic', source: 'Hyperthyroxinemia', value: '15' },
    { target: 'Thyroid Hormone Resistance Syndrome', source: 'Hyperthyroxinemia', value: '15' },
    { target: 'Congenital Hypothyroidism', source: 'Hypothyroidism', value: '15' },
    { target: 'Myxedema', source: 'Hypothyroidism', value: '15' },
    { target: 'Lingual Thyroid', source: 'Thyroid Dysgenesis', value: '15' },
    { target: 'Lingual Goiter', source: 'Lingual Thyroid', value: '19' },
    { target: 'Thyroid Nodule', source: 'Thyroid Neoplasms', value: '15' },
    { target: 'Thyroiditis, Autoimmune', source: 'Thyroiditis', value: '15' },
    { target: 'Thyroiditis, Subacute', source: 'Thyroiditis', value: '15' },
    { target: 'Thyroiditis, Suppurative', source: 'Thyroiditis', value: '15' },
    { target: 'Hashimoto Disease', source: 'Thyroiditis, Autoimmune', value: '19' },
    { target: 'Postpartum Thyroiditis', source: 'Thyroiditis, Autoimmune', value: '19' },
    { target: 'Addison Disease', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Anemia, Hemolytic, Autoimmune', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Anti-Glomerular Basement Membrane Disease', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Antiphospholipid Syndrome', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Arthritis, Juvenile', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Arthritis, Rheumatoid', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Autoimmune Diseases of the Nervous System', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Autoimmune Hypophysitis', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Autoimmune Lymphoproliferative Syndrome', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Dermatitis Herpetiformis', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Diabetes Mellitus, Type 1', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Glomerulonephritis, IGA', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Glomerulonephritis, Membranous', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Graves Disease', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Hepatitis, Autoimmune', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Latent Autoimmune Diabetes in Adults', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Linear IgA Bullous Dermatosis', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Lupus Erythematosus, Systemic', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Ophthalmia, Sympathetic', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Pemphigoid, Bullous', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Pemphigus', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Polyendocrinopathies, Autoimmune', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Thyroiditis, Autoimmune', source: 'Autoimmune Diseases', value: '11' },
    { target: 'Churg-Strauss Syndrome', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '15' },
    { target: 'Microscopic Polyangiitis', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '15' },
    { target: 'Granulomatosis with Polyangiitis', source: 'Anti-Neutrophil Cytoplasmic Antibody-Associated Vasculitis', value: '15' },
    { target: 'Felty Syndrome', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Rheumatoid Vasculitis', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Sjogren\'s Syndrome', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Still\'s Disease, Adult-Onset', source: 'Arthritis, Rheumatoid', value: '15' },
    { target: 'Anti-N-Methyl-D-Aspartate Receptor Encephalitis', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Demyelinating Autoimmune Diseases, CNS', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Lambert-Eaton Myasthenic Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Myasthenia Gravis', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Nervous System Autoimmune Disease, Experimental', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Polyradiculoneuropathy', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Stiff-Person Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Uveomeningoencephalitic Syndrome', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Vasculitis, Central Nervous System', source: 'Autoimmune Diseases of the Nervous System', value: '15' },
    { target: 'Diffuse Cerebral Sclerosis of Schilder', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Encephalomyelitis, Acute Disseminated', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Multiple Sclerosis', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Myelitis, Transverse', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Neuromyelitis Optica', source: 'Demyelinating Autoimmune Diseases, CNS', value: '19' },
    { target: 'Leukoencephalitis, Acute Hemorrhagic', source: 'Encephalomyelitis, Acute Disseminated', value: '23' },
    { target: 'Multiple Sclerosis, Chronic Progressive', source: 'Multiple Sclerosis', value: '23' },
    { target: 'Multiple Sclerosis, Relapsing-Remitting', source: 'Multiple Sclerosis', value: '23' },
    { target: 'Neuromyelitis Optica', source: 'Myelitis, Transverse', value: '23' },
    { target: 'Myasthenia Gravis, Autoimmune, Experimental', source: 'Myasthenia Gravis', value: '19' },
    { target: 'Myasthenia Gravis, Neonatal', source: 'Myasthenia Gravis', value: '19' },
    { target: 'Encephalomyelitis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '19' },
    { target: 'Myasthenia Gravis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '19' },
    { target: 'Neuritis, Autoimmune, Experimental', source: 'Nervous System Autoimmune Disease, Experimental', value: '19' },
    { target: 'Guillain-Barre Syndrome', source: 'Polyradiculoneuropathy', value: '19' },
    { target: 'Polyradiculoneuropathy, Chronic Inflammatory Demyelinating', source: 'Polyradiculoneuropathy', value: '19' },
    { target: 'Miller Fisher Syndrome', source: 'Guillain-Barre Syndrome', value: '23' },
    { target: 'Giant Cell Arteritis', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Vasculitis, Central Nervous System', value: '19' },
    { target: 'Graves Ophthalmopathy', source: 'Graves Disease', value: '15' },
    { target: 'Lupus Nephritis', source: 'Lupus Erythematosus, Systemic', value: '15' },
    { target: 'Lupus Vasculitis, Central Nervous System', source: 'Lupus Erythematosus, Systemic', value: '15' },
    { target: 'Hydrops Fetalis', source: 'Erythroblastosis, Fetal', value: '11' },
    { target: 'Kernicterus', source: 'Erythroblastosis, Fetal', value: '11' },
    { target: 'Drug Hypersensitivity', source: 'Hypersensitivity', value: '11' },
    { target: 'Environmental Illness', source: 'Hypersensitivity', value: '11' },
    { target: 'Hypersensitivity, Delayed', source: 'Hypersensitivity', value: '11' },
    { target: 'Hypersensitivity, Immediate', source: 'Hypersensitivity', value: '11' },
    { target: 'Immune Complex Diseases', source: 'Hypersensitivity', value: '11' },
    { target: 'Latex Hypersensitivity', source: 'Hypersensitivity', value: '11' },
    { target: 'Wissler\'s Syndrome', source: 'Hypersensitivity', value: '11' },
    { target: 'Asthma, Aspirin-Induced', source: 'Drug Hypersensitivity', value: '15' },
    { target: 'Drug Eruptions', source: 'Drug Hypersensitivity', value: '15' },
    { target: 'Acute Generalized Exanthematous Pustulosis', source: 'Drug Eruptions', value: '19' },
    { target: 'Drug Hypersensitivity Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Erythema Nodosum', source: 'Drug Eruptions', value: '19' },
    { target: 'Nicolau Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Serum Sickness', source: 'Drug Eruptions', value: '19' },
    { target: 'Stevens-Johnson Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Multiple Chemical Sensitivity', source: 'Environmental Illness', value: '15' },
    { target: 'Dermatitis, Allergic Contact', source: 'Hypersensitivity, Delayed', value: '15' },
    { target: 'Dermatitis, Photoallergic', source: 'Dermatitis, Allergic Contact', value: '19' },
    { target: 'Dermatitis, Toxicodendron', source: 'Dermatitis, Allergic Contact', value: '19' },
    { target: 'Anaphylaxis', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Asthma, Aspirin-Induced', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Conjunctivitis, Allergic', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Dermatitis, Atopic', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Eosinophilic Esophagitis', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Food Hypersensitivity', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Respiratory Hypersensitivity', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Urticaria', source: 'Hypersensitivity, Immediate', value: '15' },
    { target: 'Egg Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Milk Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Nut Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Peanut Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Shellfish Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Wheat Hypersensitivity', source: 'Food Hypersensitivity', value: '19' },
    { target: 'Alveolitis, Extrinsic Allergic', source: 'Respiratory Hypersensitivity', value: '19' },
    { target: 'Aspergillosis, Allergic Bronchopulmonary', source: 'Respiratory Hypersensitivity', value: '19' },
    { target: 'Asthma', source: 'Respiratory Hypersensitivity', value: '19' },
    { target: 'Rhinitis, Allergic', source: 'Respiratory Hypersensitivity', value: '19' },
    { target: 'Bird Fancier\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '23' },
    { target: 'Farmer\'s Lung', source: 'Alveolitis, Extrinsic Allergic', value: '23' },
    { target: 'Trichosporonosis', source: 'Alveolitis, Extrinsic Allergic', value: '23' },
    { target: 'Asthma, Exercise-Induced', source: 'Asthma', value: '23' },
    { target: 'Asthma, Occupational', source: 'Asthma', value: '23' },
    { target: 'Status Asthmaticus', source: 'Asthma', value: '23' },
    { target: 'Rhinitis, Allergic, Perennial', source: 'Rhinitis, Allergic', value: '23' },
    { target: 'Rhinitis, Allergic, Seasonal', source: 'Rhinitis, Allergic', value: '23' },
    { target: 'Angioedema', source: 'Urticaria', value: '19' },
    { target: 'Angioedemas, Hereditary', source: 'Angioedema', value: '23' },
    { target: 'Hereditary Angioedema Type III', source: 'Angioedemas, Hereditary', value: '27' },
    { target: 'Hereditary Angioedema Types I and II', source: 'Angioedemas, Hereditary', value: '27' },
    { target: 'Arthus Reaction', source: 'Immune Complex Diseases', value: '15' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Immune Complex Diseases', value: '15' },
    { target: 'Serum Sickness', source: 'Immune Complex Diseases', value: '15' },
    { target: 'Vasculitis, Leukocytoclastic, Cutaneous', source: 'Immune Complex Diseases', value: '15' },
    { target: 'Agammaglobulinemia', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Ataxia Telangiectasia', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Common Variable Immunodeficiency', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Dysgammaglobulinemia', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'HIV Infections', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Deltaretrovirus Infections', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Leukocyte-Adhesion Deficiency Syndrome', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Lymphopenia', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Phagocyte Bactericidal Dysfunction', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Severe Combined Immunodeficiency', source: 'Immunologic Deficiency Syndromes', value: '11' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome', source: 'Dysgammaglobulinemia', value: '15' },
    { target: 'IgA Deficiency', source: 'Dysgammaglobulinemia', value: '15' },
    { target: 'IgG Deficiency', source: 'Dysgammaglobulinemia', value: '15' },
    { target: 'Hyper-IgM Immunodeficiency Syndrome, Type 1', source: 'Hyper-IgM Immunodeficiency Syndrome', value: '19' },
    { target: 'Acquired Immunodeficiency Syndrome', source: 'HIV Infections', value: '15' },
    { target: 'Acute Retroviral Syndrome', source: 'HIV Infections', value: '15' },
    { target: 'AIDS Arteritis, Central Nervous System', source: 'HIV Infections', value: '15' },
    { target: 'AIDS-Associated Nephropathy', source: 'HIV Infections', value: '15' },
    { target: 'AIDS Dementia Complex', source: 'HIV Infections', value: '15' },
    { target: 'AIDS-Related Complex', source: 'HIV Infections', value: '15' },
    { target: 'AIDS-Related Opportunistic Infections', source: 'HIV Infections', value: '15' },
    { target: 'HIV-Associated Lipodystrophy Syndrome', source: 'HIV Infections', value: '15' },
    { target: 'HIV Enteropathy', source: 'HIV Infections', value: '15' },
    { target: 'HIV Seropositivity', source: 'HIV Infections', value: '15' },
    { target: 'HIV Wasting Syndrome', source: 'HIV Infections', value: '15' },
    { target: 'Enzootic Bovine Leukosis', source: 'Deltaretrovirus Infections', value: '15' },
    { target: 'HTLV-I Infections', source: 'Deltaretrovirus Infections', value: '15' },
    { target: 'HTLV-II Infections', source: 'Deltaretrovirus Infections', value: '15' },
    { target: 'T-Lymphocytopenia, Idiopathic CD4-Positive', source: 'Lymphopenia', value: '15' },
    { target: 'Wiskott-Aldrich Syndrome', source: 'Lymphopenia', value: '15' },
    { target: 'Chediak-Higashi Syndrome', source: 'Phagocyte Bactericidal Dysfunction', value: '15' },
    { target: 'Granulomatous Disease, Chronic', source: 'Phagocyte Bactericidal Dysfunction', value: '15' },
    { target: 'Job Syndrome', source: 'Phagocyte Bactericidal Dysfunction', value: '15' },
    { target: 'Aleutian Mink Disease', source: 'Chediak-Higashi Syndrome', value: '19' },
    { target: 'X-Linked Combined Immunodeficiency Diseases', source: 'Severe Combined Immunodeficiency', value: '15' },
    { target: 'Hypergammaglobulinemia', source: 'Immunoproliferative Disorders', value: '11' },
    { target: 'Lymphoproliferative Disorders', source: 'Immunoproliferative Disorders', value: '11' },
    { target: 'Paraproteinemias', source: 'Immunoproliferative Disorders', value: '11' },
    { target: 'Mevalonate Kinase Deficiency', source: 'Hypergammaglobulinemia', value: '15' },
    { target: 'Monoclonal Gammopathy of Undetermined Significance', source: 'Hypergammaglobulinemia', value: '15' },
    { target: 'Autoimmune Lymphoproliferative Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Giant Lymph Node Hyperplasia', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Immunoblastic Lymphadenopathy', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Infectious Mononucleosis', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Leukemia, Hairy Cell', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Leukemia, Lymphoid', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Lymphangiomyoma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Lymphoma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Macrophage Activation Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Marek Disease', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Multiple Myeloma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Plasmacytoma', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Sezary Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Tumor Lysis Syndrome', source: 'Lymphoproliferative Disorders', value: '15' },
    { target: 'Leukemia, B-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Biphenotypic, Acute', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Prolymphocytic', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, T-Cell', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', source: 'Leukemia, Lymphoid', value: '19' },
    { target: 'Leukemia, Lymphocytic, Chronic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, B-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, B-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, Prolymphocytic', value: '23' },
    { target: 'Leukemia, Large Granular Lymphocytic', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia-Lymphoma, Adult T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Leukemia, Prolymphocytic, T-Cell', source: 'Leukemia, T-Cell', value: '23' },
    { target: 'Precursor B-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Precursor T-Cell Lymphoblastic Leukemia-Lymphoma', source: 'Precursor Cell Lymphoblastic Leukemia-Lymphoma', value: '23' },
    { target: 'Lymphangioleiomyomatosis', source: 'Lymphangiomyoma', value: '19' },
    { target: 'Composite Lymphoma', source: 'Lymphoma', value: '19' },
    { target: 'Hodgkin Disease', source: 'Lymphoma', value: '19' },
    { target: 'Intraocular Lymphoma', source: 'Lymphoma', value: '19' },
    { target: 'Lymphoma, Non-Hodgkin', source: 'Lymphoma', value: '19' },
    { target: 'Lymphoma, B-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Follicular', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Large-Cell, Immunoblastic', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, Mantle-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Lymphoma, T-Cell', source: 'Lymphoma, Non-Hodgkin', value: '23' },
    { target: 'Burkitt Lymphoma', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, AIDS-Related', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, B-Cell, Marginal Zone', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, Large B-Cell, Diffuse', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphoma, Primary Effusion', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Lymphomatoid Granulomatosis', source: 'Lymphoma, B-Cell', value: '27' },
    { target: 'Plasmablastic Lymphoma', source: 'Lymphoma, Large B-Cell, Diffuse', value: '31' },
    { target: 'Enteropathy-Associated T-Cell Lymphoma', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, Large-Cell, Anaplastic', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, T-Cell, Cutaneous', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, T-Cell, Peripheral', source: 'Lymphoma, T-Cell', value: '27' },
    { target: 'Lymphoma, Primary Cutaneous Anaplastic Large Cell', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Lymphomatoid Papulosis', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Mycosis Fungoides', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Sezary Syndrome', source: 'Lymphoma, T-Cell, Cutaneous', value: '31' },
    { target: 'Pagetoid Reticulosis', source: 'Mycosis Fungoides', value: '35' },
    { target: 'Leukemia, Plasma Cell', source: 'Multiple Myeloma', value: '19' },
    { target: 'Cryoglobulinemia', source: 'Paraproteinemias', value: '15' },
    { target: 'Heavy Chain Disease', source: 'Paraproteinemias', value: '15' },
    { target: 'Monoclonal Gammopathy of Undetermined Significance', source: 'Paraproteinemias', value: '15' },
    { target: 'Multiple Myeloma', source: 'Paraproteinemias', value: '15' },
    { target: 'POEMS Syndrome', source: 'Paraproteinemias', value: '15' },
    { target: 'Waldenstrom Macroglobulinemia', source: 'Paraproteinemias', value: '15' },
    { target: 'Immunoproliferative Small Intestinal Disease', source: 'Heavy Chain Disease', value: '19' },
    { target: 'Schnitzler Syndrome', source: 'Monoclonal Gammopathy of Undetermined Significance', value: '19' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Purpura, Thrombocytopenic', value: '11' },
    { target: 'Multiple Chemical Sensitivity', source: 'Environmental Illness', value: '11' },
    { target: 'Brucellosis, Bovine', source: 'Abortion, Veterinary', value: '11' },
    { target: 'Avian Leukosis', source: 'Bird Diseases', value: '11' },
    { target: 'Fowlpox', source: 'Bird Diseases', value: '11' },
    { target: 'Influenza in Birds', source: 'Bird Diseases', value: '11' },
    { target: 'Malaria, Avian', source: 'Bird Diseases', value: '11' },
    { target: 'Marek Disease', source: 'Bird Diseases', value: '11' },
    { target: 'Newcastle Disease', source: 'Bird Diseases', value: '11' },
    { target: 'Poultry Diseases', source: 'Bird Diseases', value: '11' },
    { target: 'Reticuloendotheliosis, Avian', source: 'Bird Diseases', value: '11' },
    { target: 'Sarcoma, Avian', source: 'Bird Diseases', value: '11' },
    { target: 'Tuberculosis, Avian', source: 'Bird Diseases', value: '11' },
    { target: 'Enteritis, Transmissible, of Turkeys', source: 'Poultry Diseases', value: '15' },
    { target: 'Poult Enteritis Mortality Syndrome', source: 'Poultry Diseases', value: '15' },
    { target: 'Feline Acquired Immunodeficiency Syndrome', source: 'Cat Diseases', value: '11' },
    { target: 'Feline Infectious Peritonitis', source: 'Cat Diseases', value: '11' },
    { target: 'Feline Panleukopenia', source: 'Cat Diseases', value: '11' },
    { target: 'Leukemia, Feline', source: 'Cat Diseases', value: '11' },
    { target: 'Bovine Respiratory Disease Complex', source: 'Cattle Diseases', value: '11' },
    { target: 'Bovine Virus Diarrhea-Mucosal Disease', source: 'Cattle Diseases', value: '11' },
    { target: 'Brucellosis, Bovine', source: 'Cattle Diseases', value: '11' },
    { target: 'Encephalopathy, Bovine Spongiform', source: 'Cattle Diseases', value: '11' },
    { target: 'Enzootic Bovine Leukosis', source: 'Cattle Diseases', value: '11' },
    { target: 'Ephemeral Fever', source: 'Cattle Diseases', value: '11' },
    { target: 'Freemartinism', source: 'Cattle Diseases', value: '11' },
    { target: 'Hemorrhagic Syndrome, Bovine', source: 'Cattle Diseases', value: '11' },
    { target: 'Infectious Bovine Rhinotracheitis', source: 'Cattle Diseases', value: '11' },
    { target: 'Lumpy Skin Disease', source: 'Cattle Diseases', value: '11' },
    { target: 'Malignant Catarrh', source: 'Cattle Diseases', value: '11' },
    { target: 'Mastitis, Bovine', source: 'Cattle Diseases', value: '11' },
    { target: 'Theileriasis', source: 'Cattle Diseases', value: '11' },
    { target: 'Trypanosomiasis, Bovine', source: 'Cattle Diseases', value: '11' },
    { target: 'Tuberculosis, Bovine', source: 'Cattle Diseases', value: '11' },
    { target: 'White Heifer Disease', source: 'Cattle Diseases', value: '11' },
    { target: 'Pasteurellosis, Pneumonic', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Pneumonia, Atypical Interstitial, of Cattle', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Pneumonia of Calves, Enzootic', source: 'Bovine Respiratory Disease Complex', value: '15' },
    { target: 'Distemper', source: 'Dog Diseases', value: '11' },
    { target: 'Hepatitis, Infectious Canine', source: 'Dog Diseases', value: '11' },
    { target: 'Hip Dysplasia, Canine', source: 'Dog Diseases', value: '11' },
    { target: 'Swine Erysipelas', source: 'Erysipelothrix Infections', value: '11' },
    { target: 'Furunculosis', source: 'Fish Diseases', value: '11' },
    { target: 'Hemorrhagic Septicemia, Viral', source: 'Fish Diseases', value: '11' },
    { target: 'Hepatitis, Viral, Animal', source: 'Hepatitis, Animal', value: '11' },
    { target: 'Hepatitis, Infectious Canine', source: 'Hepatitis, Viral, Animal', value: '15' },
    { target: 'Rift Valley Fever', source: 'Hepatitis, Viral, Animal', value: '15' },
    { target: 'African Horse Sickness', source: 'Horse Diseases', value: '11' },
    { target: 'Equine Infectious Anemia', source: 'Horse Diseases', value: '11' },
    { target: 'Glanders', source: 'Horse Diseases', value: '11' },
    { target: 'Strongyle Infections, Equine', source: 'Horse Diseases', value: '11' },
    { target: 'White Muscle Disease', source: 'Muscular Dystrophy, Animal', value: '11' },
    { target: 'Helminthiasis, Animal', source: 'Parasitic Diseases, Animal', value: '11' },
    { target: 'Protozoan Infections, Animal', source: 'Parasitic Diseases, Animal', value: '11' },
    { target: 'Dictyocaulus Infections', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Dirofilariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Fascioloidiasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Monieziasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Setariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Strongyle Infections, Equine', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Toxocariasis', source: 'Helminthiasis, Animal', value: '15' },
    { target: 'Babesiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Cryptosporidiosis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Dourine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Theileriasis', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Toxoplasmosis, Animal', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Trypanosomiasis, Bovine', source: 'Protozoan Infections, Animal', value: '15' },
    { target: 'Ape Diseases', source: 'Primate Diseases', value: '11' },
    { target: 'Monkey Diseases', source: 'Primate Diseases', value: '11' },
    { target: 'Monkeypox', source: 'Primate Diseases', value: '11' },
    { target: 'Marburg Virus Disease', source: 'Monkey Diseases', value: '15' },
    { target: 'Simian Acquired Immunodeficiency Syndrome', source: 'Monkey Diseases', value: '15' },
    { target: 'Ectromelia, Infectious', source: 'Rodent Diseases', value: '11' },
    { target: 'Monkeypox', source: 'Rodent Diseases', value: '11' },
    { target: 'Murine Acquired Immunodeficiency Syndrome', source: 'Rodent Diseases', value: '11' },
    { target: 'Bluetongue', source: 'Sheep Diseases', value: '11' },
    { target: 'Border Disease', source: 'Sheep Diseases', value: '11' },
    { target: 'Ecthyma, Contagious', source: 'Sheep Diseases', value: '11' },
    { target: 'Louping Ill', source: 'Sheep Diseases', value: '11' },
    { target: 'Nairobi Sheep Disease', source: 'Sheep Diseases', value: '11' },
    { target: 'Pneumonia, Progressive Interstitial, of Sheep', source: 'Sheep Diseases', value: '11' },
    { target: 'Pulmonary Adenomatosis, Ovine', source: 'Sheep Diseases', value: '11' },
    { target: 'Scrapie', source: 'Sheep Diseases', value: '11' },
    { target: 'Swayback', source: 'Sheep Diseases', value: '11' },
    { target: 'Visna', source: 'Sheep Diseases', value: '11' },
    { target: 'African Swine Fever', source: 'Swine Diseases', value: '11' },
    { target: 'Classical Swine Fever', source: 'Swine Diseases', value: '11' },
    { target: 'Edema Disease of Swine', source: 'Swine Diseases', value: '11' },
    { target: 'Encephalomyelitis, Enzootic Porcine', source: 'Swine Diseases', value: '11' },
    { target: 'Epidermitis, Exudative, of Swine', source: 'Swine Diseases', value: '11' },
    { target: 'Gastroenteritis, Transmissible, of Swine', source: 'Swine Diseases', value: '11' },
    { target: 'Pneumonia of Swine, Mycoplasmal', source: 'Swine Diseases', value: '11' },
    { target: 'Porcine Postweaning Multisystemic Wasting Syndrome', source: 'Swine Diseases', value: '11' },
    { target: 'Porcine Reproductive and Respiratory Syndrome', source: 'Swine Diseases', value: '11' },
    { target: 'Swine Erysipelas', source: 'Swine Diseases', value: '11' },
    { target: 'Swine Vesicular Disease', source: 'Swine Diseases', value: '11' },
    { target: 'Vesicular Exanthema of Swine', source: 'Swine Diseases', value: '11' },
    { target: 'Adenocarcinoma in Situ', source: 'Morphological and Microscopic Findings', value: '11' },
    { target: 'Atypical Squamous Cells of the Cervix', source: 'Morphological and Microscopic Findings', value: '11' },
    { target: 'Margins of Excision', source: 'Morphological and Microscopic Findings', value: '11' },
    { target: 'Squamous Intraepithelial Lesions of the Cervix', source: 'Morphological and Microscopic Findings', value: '11' },
    { target: 'Agenesis of Corpus Callosum', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Airway Remodeling', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Alopecia', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Atrial Remodeling', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Atrophy', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Blister', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Calculi', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Accessory Atrioventricular Bundle', source: 'Pathologial Conditions, Anatomical', value: '11' },
    { target: 'Choristoma', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Constriction, Pathologic', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Cysts', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Dilatation, Pathologic', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Diverticulum', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Facial Asymmetry', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Fistula', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Hernia', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Hypertrophy', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Leg Length Inequality', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Leukoplakia', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Nails, Malformed', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Plaque, Amyloid', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Plaque, Atherosclerotic', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Polyps', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Prolapse', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Rupture, Spontaneous', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Spontaneous Perforation', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Tertiary Lymphoid Structures', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Torsion Abnormality', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Vascular Remodeling', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Ventricular Remodeling', source: 'Pathological Conditions, Anatomical', value: '11' },
    { target: 'Loose Anagen Hair Syndrome', source: 'Alopecia', value: '15' },
    { target: 'Muscular Atrophy', source: 'Atrophy', value: '15' },
    { target: 'Sarcopenia', source: 'Muscular Atrophy', value: '19' },
    { target: 'Dental Calculus', source: 'Calculi', value: '15' },
    { target: 'Gallstones', source: 'Calculi', value: '15' },
    { target: 'Salivary Calculi', source: 'Calculi', value: '15' },
    { target: 'Urinary Calculi', source: 'Calculi', value: '15' },
    { target: 'Salivary Duct Calculi', source: 'Salivary Calculi', value: '19' },
    { target: 'Salivary Gland Calculi', source: 'Salivary Calculi', value: '19' },
    { target: 'Kidney Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Ureteral Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Urinary Bladder Calculi', source: 'Urinary Calculi', value: '19' },
    { target: 'Staghorn Calculi', source: 'Kidney Calculi', value: '23' },
    { target: 'Parovarian Cyst', source: 'Cysts', value: '15' },
    { target: 'Spermatocele', source: 'Cysts', value: '15' },
    { target: 'Diverticulum, Colon', source: 'Diverticulum', value: '15' },
    { target: 'Diverticulum, Esophageal', source: 'Diverticulum', value: '15' },
    { target: 'Diverticulum, Stomach', source: 'Diverticulum', value: '15' },
    { target: 'Meckel Diverticulum', source: 'Diverticulum', value: '15' },
    { target: 'Zenker Diverticulum', source: 'Diverticulum, Esophageal', value: '19' },
    { target: 'Cutaneous Fistula', source: 'Fistula', value: '15' },
    { target: 'Digestive System Fistula', source: 'Fistula', value: '15' },
    { target: 'Oral Fistula', source: 'Fistula', value: '15' },
    { target: 'Respiratory Tract Fistula', source: 'Fistula', value: '15' },
    { target: 'Urinary Fistula', source: 'Fistula', value: '15' },
    { target: 'Vaginal Fistula', source: 'Fistula', value: '15' },
    { target: 'Vascular Fistula', source: 'Fistula', value: '15' },
    { target: 'Biliary Fistula', source: 'Digestive System Fistula', value: '19' },
    { target: 'Esophageal Fistula', source: 'Digestive System Fistula', value: '19' },
    { target: 'Gastric Fistula', source: 'Digestive System Fistula', value: '19' },
    { target: 'Intestinal Fistula', source: 'Digestive System Fistula', value: '19' },
    { target: 'Pancreatic Fistula', source: 'Digestive System Fistula', value: '19' },
    { target: 'Tracheoesophageal Fistula', source: 'Esophageal Fistula', value: '23' },
    { target: 'Rectal Fistula', source: 'Intestinal Fistula', value: '23' },
    { target: 'Rectovaginal Fistula', source: 'Rectal Fistula', value: '27' },
    { target: 'Dental Fistula', source: 'Oral Fistula', value: '19' },
    { target: 'Oroantral Fistula', source: 'Oral Fistula', value: '19' },
    { target: 'Salivary Gland Fistula', source: 'Oral Fistula', value: '19' },
    { target: 'Bronchial Fistula', source: 'Respiratory Tract Fistula', value: '19' },
    { target: 'Urinary Bladder Fistula', source: 'Urinary Fistula', value: '19' },
    { target: 'Vesicovaginal Fistula', source: 'Urinary Bladder Fistula', value: '23' },
    { target: 'Rectovaginal Fistula', source: 'Vaginal Fistula', value: '19' },
    { target: 'Vesicovaginal Fistula', source: 'Vaginal Fistula', value: '19' },
    { target: 'Arterio-Arterial Fistula', source: 'Vascular Fistula', value: '19' },
    { target: 'Arteriovenous Fistula', source: 'Vascular Fistula', value: '19' },
    { target: 'Bland White Garland Syndrome', source: 'Arterio-Arterial Fistula', value: '23' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Arteriovenous Fistula', value: '23' },
    { target: 'Encephalocele', source: 'Hernia', value: '15' },
    { target: 'Hernia, Abdominal', source: 'Hernia', value: '15' },
    { target: 'Hernia, Diaphragmatic', source: 'Hernia', value: '15' },
    { target: 'Hernia, Obturator', source: 'Hernia', value: '15' },
    { target: 'Incisional Hernia', source: 'Hernia', value: '15' },
    { target: 'Intervertebral Disc Displacement', source: 'Hernia', value: '15' },
    { target: 'Meningocele', source: 'Hernia', value: '15' },
    { target: 'Rectocele', source: 'Hernia', value: '15' },
    { target: 'Gastroschisis', source: 'Hernia, Abdominal', value: '19' },
    { target: 'Hernia, Femoral', source: 'Hernia, Abdominal', value: '19' },
    { target: 'Hernia, Inguinal', source: 'Hernia, Abdominal', value: '19' },
    { target: 'Hernia, Ventral', source: 'Hernia, Abdominal', value: '19' },
    { target: 'Hernia, Umbilical', source: 'Hernia, Ventral', value: '23' },
    { target: 'Hernias, Diaphragmatic, Congenital', source: 'Hernia, Diaphragmatic', value: '19' },
    { target: 'Hernia, Diaphragmatic, Traumatic', source: 'Hernia, Diaphragmatic', value: '19' },
    { target: 'Hernia, Hiatal', source: 'Hernia, Diaphragmatic', value: '19' },
    { target: 'Cardiomegaly', source: 'Hypertrophy', value: '15' },
    { target: 'Hepatomegaly', source: 'Hypertrophy', value: '15' },
    { target: 'Splenomegaly', source: 'Hypertrophy', value: '15' },
    { target: 'Hypertrophy, Left Ventricular', source: 'Cardiomegaly', value: '19' },
    { target: 'Hypertrophy, Right Ventricular', source: 'Cardiomegaly', value: '19' },
    { target: 'Leukoplakia, Oral', source: 'Leukoplakia', value: '15' },
    { target: 'Leukoplakia, Hairy', source: 'Leukoplakia, Oral', value: '19' },
    { target: 'Yellow Nail Syndrome', source: 'Nails, Malformed', value: '15' },
    { target: 'Intestinal Polyps', source: 'Polyps', value: '15' },
    { target: 'Nasal Polyps', source: 'Polyps', value: '15' },
    { target: 'Colonic Polyps', source: 'Intestinal Polyps', value: '19' },
    { target: 'Pelvic Organ Prolapse', source: 'Prolapse', value: '15' },
    { target: 'Cystocele', source: 'Pelvic Organ Prolapse', value: '19' },
    { target: 'Rectal Prolapse', source: 'Pelvic Organ Prolapse', value: '19' },
    { target: 'Uterine Prolapse', source: 'Pelvic Organ Prolapse', value: '19' },
    { target: 'Visceral Prolapse', source: 'Pelvic Organ Prolapse', value: '19' },
    { target: 'Bone Anteversion', source: 'Torsion Abnormality', value: '15' },
    { target: 'Bone Retroversion', source: 'Torsion Abnormality', value: '15' },
    { target: 'Intestinal Volvulus', source: 'Torsion Abnormality', value: '15' },
    { target: 'Uterine Retroversion', source: 'Torsion Abnormality', value: '15' },
    { target: 'Coxa Vara', source: 'Bone Anteversion', value: '19' },
    { target: 'Coxa Valga', source: 'Bone Retroversion', value: '19' },
    { target: 'Acantholysis', source: 'Pathologic Processes', value: '11' },
    { target: 'Arrhythmias, Cardiac', source: 'Pathologic Processes', value: '11' },
    { target: 'Ascites', source: 'Pathologic Processes', value: '11' },
    { target: 'Atrial Remodeling', source: 'Pathologic Processes', value: '11' },
    { target: 'Azotemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Cardiotoxicity', source: 'Pathologic Processes', value: '11' },
    { target: 'Channelopathies', source: 'Pathologic Processes', value: '11' },
    { target: 'Chromosome Aberrations', source: 'Pathologic Processes', value: '11' },
    { target: 'Death', source: 'Pathologic Processes', value: '11' },
    { target: 'Dehydration', source: 'Pathologic Processes', value: '11' },
    { target: 'Delayed Graft Function', source: 'Pathologic Processes', value: '11' },
    { target: 'Disease', source: 'Pathologic Processes', value: '11' },
    { target: 'Disease Attributes', source: 'Pathologic Processes', value: '11' },
    { target: 'Dysbiosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Emphysema', source: 'Pathologic Processes', value: '11' },
    { target: 'Extravasation of Diagnostic and Therapeutic Materials', source: 'Pathologic Processes', value: '11' },
    { target: 'Femoracetabular Impingement', source: 'Pathologic Processes', value: '11' },
    { target: 'Fibrosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Genomic Instability', source: 'Pathologic Processes', value: '11' },
    { target: 'Gliosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Granuloma', source: 'Pathologic Processes', value: '11' },
    { target: 'Granulomatosis, Orofacial', source: 'Pathologic Processes', value: '11' },
    { target: 'Growth Disorders', source: 'Pathologic Processes', value: '11' },
    { target: 'Hemolysis', source: 'Pathologic Processes', value: '11' },
    { target: 'Hemorrhage', source: 'Pathologic Processes', value: '11' },
    { target: 'Hyperammonemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Hyperamylasemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Hyperbilirubinemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Hyperplasia', source: 'Pathologic Processes', value: '11' },
    { target: 'Hyperuricemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Hypovolemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Inflammation', source: 'Pathologic Processes', value: '11' },
    { target: 'Intraoperative Complications', source: 'Pathologic Processes', value: '11' },
    { target: 'Ischemia', source: 'Pathologic Processes', value: '11' },
    { target: 'Leukoaraiosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Leukocytosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Lithiasis', source: 'Pathologic Processes', value: '11' },
    { target: 'Long Term Adverse Effects', source: 'Pathologic Processes', value: '11' },
    { target: 'Malacoplakia', source: 'Pathologic Processes', value: '11' },
    { target: 'Menstruation Disturbances', source: 'Pathologic Processes', value: '11' },
    { target: 'Metaplasia', source: 'Pathologic Processes', value: '11' },
    { target: 'Muscle Weakness', source: 'Pathologic Processes', value: '11' },
    { target: 'Necrosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Neointima', source: 'Pathologic Processes', value: '11' },
    { target: 'Neoplastic Processes', source: 'Pathologic Processes', value: '11' },
    { target: 'Nerve Degeneration', source: 'Pathologic Processes', value: '11' },
    { target: 'Ochronosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Ossification, Heterotopic', source: 'Pathologic Processes', value: '11' },
    { target: 'Pigmentation Disorders', source: 'Pathologic Processes', value: '11' },
    { target: 'Polydipsia', source: 'Pathologic Processes', value: '11' },
    { target: 'Postoperative Complications', source: 'Pathologic Processes', value: '11' },
    { target: 'Protein Aggregation, Pathological', source: 'Pathologic Processes', value: '11' },
    { target: 'Respiratory Aspiration', source: 'Pathologic Processes', value: '11' },
    { target: 'Retropneumoperitoneum', source: 'Pathologic Processes', value: '11' },
    { target: 'Sclerosis', source: 'Pathologic Processes', value: '11' },
    { target: 'Shock', source: 'Pathologic Processes', value: '11' },
    { target: 'Teratogenesis', source: 'Pathologic Processes', value: '11' },
    { target: 'Ulcer', source: 'Pathologic Processes', value: '11' },
    { target: 'Vascular Remodeling', source: 'Pathologic Processes', value: '11' },
    { target: 'Yang Deficiency', source: 'Pathologic Processes', value: '11' },
    { target: 'Yin Deficiency', source: 'Pathologic Processes', value: '11' },
    { target: 'Arrhythmia, Sinus', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Atrial Fibrillation', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Atrial Flutter', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Bradycardia', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Cardiac Complexes, Premature', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Heart Block', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Long QT Syndrome', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Parasystole', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Tachycardia', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Ventricular Fibrillation', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Ventricular Flutter', source: 'Arrhythmias, Cardiac', value: '15' },
    { target: 'Sick Sinus Syndrome', source: 'Arrhythmia, Sinus', value: '19' },
    { target: 'Sinus Arrest, Cardiac', source: 'Arrhythmia, Sinus', value: '19' },
    { target: 'Atrial Premature Complexes', source: 'Cardiac Complexes, Premature', value: '19' },
    { target: 'Ventricular Premature Complexes', source: 'Cardiac Complexes, Premature', value: '19' },
    { target: 'Adams-Stokes Syndrome', source: 'Heart Block', value: '19' },
    { target: 'Atrioventricular Block', source: 'Heart Block', value: '19' },
    { target: 'Bundle-Branch Block', source: 'Heart Block', value: '19' },
    { target: 'Sick Sinus Syndrome', source: 'Heart Block', value: '19' },
    { target: 'Sinoatrial Block', source: 'Heart Block', value: '19' },
    { target: 'Andersen Syndrome', source: 'Long QT Syndrome', value: '19' },
    { target: 'Romano-Ward Syndrome', source: 'Long QT Syndrome', value: '19' },
    { target: 'Tachycardia, Paroxysmal', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Reciprocating', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Supraventricular', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Ventricular', source: 'Tachycardia', value: '19' },
    { target: 'Tachycardia, Atrioventricular Nodal Reentry', source: 'Tachycardia, Reciprocating', value: '23' },
    { target: 'Tachycardia, Sinoatrial Nodal Reentry', source: 'Tachycardia, Reciprocating', value: '23' },
    { target: 'Tachycardia, Ectopic Atrial', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Tachycardia, Ectopic Junctional', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Tachycardia, Sinus', source: 'Tachycardia, Supraventricular', value: '23' },
    { target: 'Accelerated Idioventricular Rhythm', source: 'Tachycardia, Ventricular', value: '23' },
    { target: 'Torsades de Pointes', source: 'Tachycardia, Ventricular', value: '23' },
    { target: 'Abnormal Karyotype', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Aneuploidy', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Chromosomal Instability', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Chromosome Breakage', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Chromosome Duplication', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Chromosome Inversion', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Chromothripsis', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Isochromosomes', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Micronuclei, Chromosome-Defective', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Nondisjunction, Genetic', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Polyploidy', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Ring Chromosomes', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Sex Chromosome Aberrations', source: 'Chromosome Aberrations', value: '15' },
    { target: 'Translocation, Genetic', source: 'Chromosome Aberrations', value: '15' },
    { target: 'XYY Karyotype', source: 'Abnormal Karyotype', value: '19' },
    { target: 'Monosomy', source: 'Aneuploidy', value: '19' },
    { target: 'Tetrasomy', source: 'Aneuploidy', value: '19' },
    { target: 'Trisomy', source: 'Aneuploidy', value: '19' },
    { target: 'Chromosome Deletion', source: 'Monosomy', value: '23' },
    { target: 'Chromosome Fragility', source: 'Chromosomal Instability', value: '19' },
    { target: 'Tetrasomy', source: 'Chromosome Duplication', value: '19' },
    { target: 'Trisomy', source: 'Chromosome Duplication', value: '19' },
    { target: 'Uniparental Disomy', source: 'Nondisjunction, Genetic', value: '19' },
    { target: 'Tetraploidy', source: 'Polyploidy', value: '19' },
    { target: 'Triploidy', source: 'Polyploidy', value: '19' },
    { target: 'XYY Karyotype', source: 'Sex Chromosome Aberrations', value: '19' },
    { target: 'Philadelphia Chromosome', source: 'Translocation, Genetic', value: '19' },
    { target: 'Asphyxia', source: 'Death', value: '15' },
    { target: 'Body Remains', source: 'Death', value: '15' },
    { target: 'Brain Death', source: 'Death', value: '15' },
    { target: 'Cadaver', source: 'Death', value: '15' },
    { target: 'Death, Sudden', source: 'Death', value: '15' },
    { target: 'Drowning', source: 'Death', value: '15' },
    { target: 'Embryo Loss', source: 'Death', value: '15' },
    { target: 'Fetal Death', source: 'Death', value: '15' },
    { target: 'Infant Death', source: 'Death', value: '15' },
    { target: 'Parental Death', source: 'Death', value: '15' },
    { target: 'Perinatal Death', source: 'Death', value: '15' },
    { target: 'Corpse Dismemberment', source: 'Cadaver', value: '19' },
    { target: 'Postmortem Changes', source: 'Cadaver', value: '19' },
    { target: 'Autolysis', source: 'Postmortem Changes', value: '23' },
    { target: 'Rigor Mortis', source: 'Postmortem Changes', value: '23' },
    { target: 'Death, Sudden, Cardiac', source: 'Death, Sudden', value: '19' },
    { target: 'Sudden Infant Death', source: 'Death, Sudden', value: '19' },
    { target: 'Karoshi Death', source: 'Death, Sudden, Cardiac', value: '23' },
    { target: 'Fetal Resorption', source: 'Fetal Death', value: '19' },
    { target: 'Stillbirth', source: 'Fetal Death', value: '19' },
    { target: 'Sudden Infant Death', source: 'Infant Death', value: '19' },
    { target: 'Maternal Death', source: 'Parental Death', value: '19' },
    { target: 'Syndrome', source: 'Disease', value: '15' },
    { target: 'Acute Disease', source: 'Disease Attributes', value: '15' },
    { target: 'Asymptomatic Diseases', source: 'Disease Attributes', value: '15' },
    { target: 'Catastrophic Illness', source: 'Disease Attributes', value: '15' },
    { target: 'Chronic Disease', source: 'Disease Attributes', value: '15' },
    { target: 'Convalescence', source: 'Disease Attributes', value: '15' },
    { target: 'Critical Illness', source: 'Disease Attributes', value: '15' },
    { target: 'Disease Progression', source: 'Disease Attributes', value: '15' },
    { target: 'Disease Resistance', source: 'Disease Attributes', value: '15' },
    { target: 'Disease Susceptibility', source: 'Disease Attributes', value: '15' },
    { target: 'Diseases in Twins', source: 'Disease Attributes', value: '15' },
    { target: 'Emergencies', source: 'Disease Attributes', value: '15' },
    { target: 'Facies', source: 'Disease Attributes', value: '15' },
    { target: 'Iatrogenic Disease', source: 'Disease Attributes', value: '15' },
    { target: 'Late Onset Disorders', source: 'Disease Attributes', value: '15' },
    { target: 'Neglected Diseases', source: 'Disease Attributes', value: '15' },
    { target: 'Rare Diseases', source: 'Disease Attributes', value: '15' },
    { target: 'Recurrence', source: 'Disease Attributes', value: '15' },
    { target: 'Asymptomatic Infections', source: 'Asymptomatic Diseases', value: '19' },
    { target: 'Multiple Chronic Conditions', source: 'Chronic Disease', value: '19' },
    { target: 'Remission, Spontaneous', source: 'Disease Progression', value: '19' },
    { target: 'Genetic Predisposition to Disease', source: 'Disease Susceptibility', value: '19' },
    { target: 'Anticipation, Genetic', source: 'Genetic Predisposition to Disease', value: '23' },
    { target: 'Cross Infection', source: 'Iatrogenic Disease', value: '19' },
    { target: 'Symptom Flare Up', source: 'Recurrence', value: '19' },
    { target: 'Mediastinal Emphysema', source: 'Emphysema', value: '15' },
    { target: 'Subcutaneous Emphysema', source: 'Emphysema', value: '15' },
    { target: 'alpha 1-Antitrypsin Deficiency', source: 'Subcutaneous Emphysema', value: '19' },
    { target: 'Cicatrix', source: 'Fibrosis', value: '15' },
    { target: 'Nephrogenic Fibrosing Dermopathy', source: 'Fibrosis', value: '15' },
    { target: 'Peritoneal Fibrosis', source: 'Fibrosis', value: '15' },
    { target: 'Retroperitoneal Fibrosis', source: 'Fibrosis', value: '15' },
    { target: 'Cicatrix, Hypertrophic', source: 'Cicatrix', value: '19' },
    { target: 'Keloid', source: 'Cicatrix', value: '19' },
    { target: 'Tissue Adhesions', source: 'Cicatrix', value: '19' },
    { target: 'Chromosomal Instability', source: 'Genomic Instability', value: '15' },
    { target: 'Microsatellite Instability', source: 'Genomic Instability', value: '15' },
    { target: 'Chromosome Fragility', source: 'Chromosomal Instability', value: '19' },
    { target: 'Eosinophilic Granuloma', source: 'Granuloma', value: '15' },
    { target: 'Granuloma Annulare', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Foreign-Body', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Giant Cell', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Plasma Cell', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Pyogenic', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Respiratory Tract', source: 'Granuloma', value: '15' },
    { target: 'Necrobiotic Xanthogranuloma', source: 'Granuloma', value: '15' },
    { target: 'Granuloma, Laryngeal', source: 'Granuloma, Respiratory Tract', value: '19' },
    { target: 'Fetal Growth Retardation', source: 'Growth Disorders', value: '15' },
    { target: 'Blood Loss, Surgical', source: 'Hemorrhage', value: '15' },
    { target: 'Ecchymosis', source: 'Hemorrhage', value: '15' },
    { target: 'Epistaxis', source: 'Hemorrhage', value: '15' },
    { target: 'Exsanguination', source: 'Hemorrhage', value: '15' },
    { target: 'Eye Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Gastrointestinal Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Hemarthrosis', source: 'Hemorrhage', value: '15' },
    { target: 'Hematocele', source: 'Hemorrhage', value: '15' },
    { target: 'Hematoma', source: 'Hemorrhage', value: '15' },
    { target: 'Hematuria', source: 'Hemorrhage', value: '15' },
    { target: 'Hemobilia', source: 'Hemorrhage', value: '15' },
    { target: 'Hemoperitoneum', source: 'Hemorrhage', value: '15' },
    { target: 'Hemoptysis', source: 'Hemorrhage', value: '15' },
    { target: 'Hemothorax', source: 'Hemorrhage', value: '15' },
    { target: 'Intracranial Hemorrhages', source: 'Hemorrhage', value: '15' },
    { target: 'Oral Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Postoperative Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Purpura', source: 'Hemorrhage', value: '15' },
    { target: 'Retrobulbar Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Shock, Hemorrhagic', source: 'Hemorrhage', value: '15' },
    { target: 'Uterine Hemorrhage', source: 'Hemorrhage', value: '15' },
    { target: 'Choroid Hemorrhage', source: 'Eye Hemorrhage', value: '19' },
    { target: 'Hyphema', source: 'Eye Hemorrhage', value: '19' },
    { target: 'Retinal Hemorrhage', source: 'Eye Hemorrhage', value: '19' },
    { target: 'Vitreous Hemorrhage', source: 'Eye Hemorrhage', value: '19' },
    { target: 'Hematemesis', source: 'Gastrointestinal Hemorrhage', value: '19' },
    { target: 'Melena', source: 'Gastrointestinal Hemorrhage', value: '19' },
    { target: 'Peptic Ulcer Hemorrhage', source: 'Gastrointestinal Hemorrhage', value: '19' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Hematoma', value: '19' },
    { target: 'Hematoma, Epidural, Spinal', source: 'Hematoma', value: '19' },
    { target: 'Hematoma, Subdural', source: 'Hematoma', value: '19' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Spinal', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hemopneumothorax', source: 'Hemothorax', value: '19' },
    { target: 'Cerebral Hemorrhage', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Hematoma, Subdural', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Subarachnoid Hemorrhage', source: 'Intracranial Hemorrhages', value: '19' },
    { target: 'Basal Ganglia Hemorrhage', source: 'Cerebral Hemorrhage', value: '23' },
    { target: 'Putaminal Hemorrhage', source: 'Basal Ganglia Hemorrhage', value: '27' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Gingival Hemorrhage', source: 'Oral Hemorrhage', value: '19' },
    { target: 'Endoleak', source: 'Postoperative Hemorrhage', value: '19' },
    { target: 'Purpura Fulminans', source: 'Purpura', value: '19' },
    { target: 'Purpura, Hyperglobulinemic', source: 'Purpura', value: '19' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic', source: 'Purpura', value: '19' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Purpura, Thrombotic Thrombocytopenic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Metrorrhagia', source: 'Uterine Hemorrhage', value: '19' },
    { target: 'Postpartum Hemorrhage', source: 'Uterine Hemorrhage', value: '19' },
    { target: 'Hyperbilirubinemia, Neonatal', source: 'Hyperbilirubinemia', value: '15' },
    { target: 'Jaundice', source: 'Hyperbilirubinemia', value: '15' },
    { target: 'Kernicterus', source: 'Hyperbilirubinemia', value: '15' },
    { target: 'Jaundice, Neonatal', source: 'Hyperbilirubinemia, Neonatal', value: '19' },
    { target: 'Jaundice, Obstructive', source: 'Jaundice', value: '19' },
    { target: 'Acute-Phase Reaction', source: 'Inflammation', value: '15' },
    { target: 'Foreign-Body Reaction', source: 'Inflammation', value: '15' },
    { target: 'Neurogenic Inflammation', source: 'Inflammation', value: '15' },
    { target: 'Seroma', source: 'Inflammation', value: '15' },
    { target: 'Serositis', source: 'Inflammation', value: '15' },
    { target: 'Suppuration', source: 'Inflammation', value: '15' },
    { target: 'Systemic Inflammatory Response Syndrome', source: 'Inflammation', value: '15' },
    { target: 'Implant Capsular Contracture', source: 'Foreign-Body Reaction', value: '19' },
    { target: 'Abscess', source: 'Suppuration', value: '19' },
    { target: 'Cellulitis', source: 'Suppuration', value: '19' },
    { target: 'Empyema', source: 'Suppuration', value: '19' },
    { target: 'Empyema, Subdural', source: 'Empyema', value: '23' },
    { target: 'Sepsis', source: 'Systemic Inflammatory Response Syndrome', value: '19' },
    { target: 'Bacteremia', source: 'Sepsis', value: '23' },
    { target: 'Fungemia', source: 'Sepsis', value: '23' },
    { target: 'Neonatal Sepsis', source: 'Sepsis', value: '23' },
    { target: 'Parasitemia', source: 'Sepsis', value: '23' },
    { target: 'Shock, Septic', source: 'Sepsis', value: '23' },
    { target: 'Viremia', source: 'Sepsis', value: '23' },
    { target: 'Endotoxemia', source: 'Bacteremia', value: '27' },
    { target: 'Hemorrhagic Septicemia', source: 'Bacteremia', value: '27' },
    { target: 'Candidemia', source: 'Fungemia', value: '27' },
    { target: 'Hemorrhagic Septicemia, Viral', source: 'Viremia', value: '27' },
    { target: 'Blood Loss, Surgical', source: 'Intraoperative Complications', value: '15' },
    { target: 'Intraoperative Awareness', source: 'Intraoperative Complications', value: '15' },
    { target: 'Malignant Hyperthermia', source: 'Intraoperative Complications', value: '15' },
    { target: 'Infarction', source: 'Ischemia', value: '15' },
    { target: 'No-Reflow Phenomenon', source: 'Ischemia', value: '15' },
    { target: 'Amenorrhea', source: 'Menstruation Disturbances', value: '15' },
    { target: 'Dysmenorrhea', source: 'Menstruation Disturbances', value: '15' },
    { target: 'Menorrhagia', source: 'Menstruation Disturbances', value: '15' },
    { target: 'Oligomenorrhea', source: 'Menstruation Disturbances', value: '15' },
    { target: 'Premenstrual Syndrome', source: 'Menstruation Disturbances', value: '15' },
    { target: 'Premenstrual Dysphoric Disorder', source: 'Premenstrual Syndrome', value: '19' },
    { target: 'Neovascularization, Pathologic', source: 'Metaplasia', value: '15' },
    { target: 'Choroidal Neovascularization', source: 'Neovascularization, Pathologic', value: '19' },
    { target: 'Retinal Neovascularization', source: 'Neovascularization, Pathologic', value: '19' },
    { target: 'Dental Pulp Necrosis', source: 'Necrosis', value: '15' },
    { target: 'DNA Degradation, Necrotic', source: 'Necrosis', value: '15' },
    { target: 'Fat Necrosis', source: 'Necrosis', value: '15' },
    { target: 'Gangrene', source: 'Necrosis', value: '15' },
    { target: 'Infarction', source: 'Necrosis', value: '15' },
    { target: 'Osteonecrosis', source: 'Necrosis', value: '15' },
    { target: 'Bisphosphonate-Associated Osteonecrosis of the Jaw', source: 'Osteonecrosis', value: '19' },
    { target: 'Femur Head Necrosis', source: 'Osteonecrosis', value: '19' },
    { target: 'Anaplasia', source: 'Neoplastic Processes', value: '15' },
    { target: 'Carcinogenesis', source: 'Neoplastic Processes', value: '15' },
    { target: 'Neoplasm Invasiveness', source: 'Neoplastic Processes', value: '15' },
    { target: 'Neoplasm Metastasis', source: 'Neoplastic Processes', value: '15' },
    { target: 'Neoplasm Recurrence, Local', source: 'Neoplastic Processes', value: '15' },
    { target: 'Neoplasm Regression, Spontaneous', source: 'Neoplastic Processes', value: '15' },
    { target: 'Neoplasm, Residual', source: 'Neoplastic Processes', value: '15' },
    { target: 'Cell Transformation, Neoplastic', source: 'Carcinogenesis', value: '19' },
    { target: 'Cocarcinogenesis', source: 'Carcinogenesis', value: '19' },
    { target: 'Blast Crisis', source: 'Cell Transformation, Neoplastic', value: '23' },
    { target: 'Cell Transformation, Viral', source: 'Cell Transformation, Neoplastic', value: '23' },
    { target: 'Leukemic Infiltration', source: 'Neoplasm Invasiveness', value: '19' },
    { target: 'Lymphatic Metastasis', source: 'Neoplasm Metastasis', value: '19' },
    { target: 'Neoplasm Micrometastasis', source: 'Neoplasm Metastasis', value: '19' },
    { target: 'Neoplasm Seeding', source: 'Neoplasm Metastasis', value: '19' },
    { target: 'Neoplasms, Unknown Primary', source: 'Neoplasm Metastasis', value: '19' },
    { target: 'Neoplastic Cells, Circulating', source: 'Neoplasm Metastasis', value: '19' },
    { target: 'Retrograde Degeneration', source: 'Nerve Degeneration', value: '15' },
    { target: 'Subacute Combined Degeneration', source: 'Nerve Degeneration', value: '15' },
    { target: 'Wallerian Degeneration', source: 'Nerve Degeneration', value: '15' },
    { target: 'Ossification of Posterior Longitudinal Ligament', source: 'Ossification, Heterotopic', value: '15' },
    { target: 'Polydipsia, Psychogenic', source: 'Polydipsia', value: '15' },
    { target: 'Afferent Loop Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Anastomotic Leak', source: 'Postoperative Complications', value: '15' },
    { target: 'Breast Cancer Lymphedema', source: 'Postoperative Complications', value: '15' },
    { target: 'Corneal Endothelial Cell Loss', source: 'Postoperative Complications', value: '15' },
    { target: 'Coronary-Subclavian Steal Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Delayed Emergence from Anesthesia', source: 'Postoperative Complications', value: '15' },
    { target: 'Emergence Delirium', source: 'Postoperative Complications', value: '15' },
    { target: 'Failed Back Surgery Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Graft Occlusion, Vascular', source: 'Postoperative Complications', value: '15' },
    { target: 'Incisional Hernia', source: 'Postoperative Complications', value: '15' },
    { target: 'Malignant Hyperthermia', source: 'Postoperative Complications', value: '15' },
    { target: 'Pain, Postoperative', source: 'Postoperative Complications', value: '15' },
    { target: 'Postcholecystectomy Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Postgastrectomy Syndromes', source: 'Postoperative Complications', value: '15' },
    { target: 'Postoperative Hemorrhage', source: 'Postoperative Complications', value: '15' },
    { target: 'Postoperative Nausea and Vomiting', source: 'Postoperative Complications', value: '15' },
    { target: 'Postpericardiotomy Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Prosthesis Failure', source: 'Postoperative Complications', value: '15' },
    { target: 'Prosthesis-Related Infections', source: 'Postoperative Complications', value: '15' },
    { target: 'Reperfusion Injury', source: 'Postoperative Complications', value: '15' },
    { target: 'Shock, Surgical', source: 'Postoperative Complications', value: '15' },
    { target: 'Short Bowel Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Slit Ventricle Syndrome', source: 'Postoperative Complications', value: '15' },
    { target: 'Surgical Wound Dehiscence', source: 'Postoperative Complications', value: '15' },
    { target: 'Surgical Wound Infection', source: 'Postoperative Complications', value: '15' },
    { target: 'Vasoplegia', source: 'Postoperative Complications', value: '15' },
    { target: 'Phantom Limb', source: 'Pain, Postoperative', value: '19' },
    { target: 'Dumping Syndrome', source: 'Postgastrectomy Syndromes', value: '19' },
    { target: 'Endoleak', source: 'Postoperative Hemorrhage', value: '19' },
    { target: 'Implant Capsular Contracture', source: 'Prosthesis Failure', value: '19' },
    { target: 'Myocardial Reperfusion Injury', source: 'Reperfusion Injury', value: '19' },
    { target: 'Primary Graft Dysfunction', source: 'Reperfusion Injury', value: '19' },
    { target: 'Respiratory Aspiration of Gastric Contents', source: 'Respiratory Aspiration', value: '15' },
    { target: 'Multiple Organ Failure', source: 'Shock', value: '15' },
    { target: 'Shock, Cardiogenic', source: 'Shock', value: '15' },
    { target: 'Shock, Hemorrhagic', source: 'Shock', value: '15' },
    { target: 'Shock, Surgical', source: 'Shock', value: '15' },
    { target: 'Shock, Traumatic', source: 'Shock', value: '15' },
    { target: 'Systemic Inflammatory Response Syndrome', source: 'Shock', value: '15' },
    { target: 'Shock, Septic', source: 'Systemic Inflammatory Response Syndrome', value: '19' },
    { target: 'Aging, Premature', source: 'Signs and Symptoms', value: '11' },
    { target: 'Asthenia', source: 'Signs and Symptoms', value: '11' },
    { target: 'Body Temperature Changes', source: 'Signs and Symptoms', value: '11' },
    { target: 'Body Weight', source: 'Signs and Symptoms', value: '11' },
    { target: 'Cardiac Output, High', source: 'Signs and Symptoms', value: '11' },
    { target: 'Cardiac Output, Low', source: 'Signs and Symptoms', value: '11' },
    { target: 'Chills', source: 'Signs and Symptoms', value: '11' },
    { target: 'Cyanosis', source: 'Signs and Symptoms', value: '11' },
    { target: 'Edema', source: 'Signs and Symptoms', value: '11' },
    { target: 'Eye Manifestations', source: 'Signs and Symptoms', value: '11' },
    { target: 'Failure to Thrive', source: 'Signs and Symptoms', value: '11' },
    { target: 'Fatigue', source: 'Signs and Symptoms', value: '11' },
    { target: 'Feminization', source: 'Signs and Symptoms', value: '11' },
    { target: 'Fetal Distress', source: 'Signs and Symptoms', value: '11' },
    { target: 'Flushing', source: 'Signs and Symptoms', value: '11' },
    { target: 'Heart Murmurs', source: 'Signs and Symptoms', value: '11' },
    { target: 'Hot Flashes', source: 'Signs and Symptoms', value: '11' },
    { target: 'Hypergammaglobulinemia', source: 'Signs and Symptoms', value: '11' },
    { target: 'Hyperlactatemia', source: 'Signs and Symptoms', value: '11' },
    { target: 'Hypertriglyceridemic Waist', source: 'Signs and Symptoms', value: '11' },
    { target: 'Intermittent Claudication', source: 'Signs and Symptoms', value: '11' },
    { target: 'Medically Unexplained Symptoms', source: 'Signs and Symptoms', value: '11' },
    { target: 'Mobility Limitation', source: 'Signs and Symptoms', value: '11' },
    { target: 'Motion Sickness', source: 'Signs and Symptoms', value: '11' },
    { target: 'Myocardial Stunning', source: 'Signs and Symptoms', value: '11' },
    { target: 'Neurologic Manifestations', source: 'Signs and Symptoms', value: '11' },
    { target: 'Oral Manifestations', source: 'Signs and Symptoms', value: '11' },
    { target: 'Polydipsia', source: 'Signs and Symptoms', value: '11' },
    { target: 'Prodromal Symptoms', source: 'Signs and Symptoms', value: '11' },
    { target: 'Pseudophakia', source: 'Signs and Symptoms', value: '11' },
    { target: 'Renal Colic', source: 'Signs and Symptoms', value: '11' },
    { target: 'Reticulocytosis', source: 'Signs and Symptoms', value: '11' },
    { target: 'Signs and Symptoms, Digestive', source: 'Signs and Symptoms', value: '11' },
    { target: 'Signs and Symptoms, Respiratory', source: 'Signs and Symptoms', value: '11' },
    { target: 'Skin Manifestations', source: 'Signs and Symptoms', value: '11' },
    { target: 'Urological Manifestations', source: 'Signs and Symptoms', value: '11' },
    { target: 'Virilism', source: 'Signs and Symptoms', value: '11' },
    { target: 'Fever', source: 'Body Temperature Changes', value: '15' },
    { target: 'Hypothermia', source: 'Body Temperature Changes', value: '15' },
    { target: 'Fever of Unknown Origin', source: 'Fever', value: '19' },
    { target: 'Sweating Sickness', source: 'Fever', value: '19' },
    { target: 'Birth Weight', source: 'Body Weight', value: '15' },
    { target: 'Body Weight Changes', source: 'Body Weight', value: '15' },
    { target: 'Fetal Weight', source: 'Body Weight', value: '15' },
    { target: 'Overweight', source: 'Body Weight', value: '15' },
    { target: 'Thinness', source: 'Body Weight', value: '15' },
    { target: 'Fetal Macrosomia', source: 'Birth Weight', value: '19' },
    { target: 'Weight Gain', source: 'Body Weight Changes', value: '19' },
    { target: 'Weight Loss', source: 'Body Weight Changes', value: '19' },
    { target: 'Emaciation', source: 'Weight Loss', value: '23' },
    { target: 'Cachexia', source: 'Emaciation', value: '27' },
    { target: 'Obesity', source: 'Overweight', value: '19' },
    { target: 'Obesity, Metabolically Benign', source: 'Obesity', value: '23' },
    { target: 'Obesity, Morbid', source: 'Obesity', value: '23' },
    { target: 'Pediatric Obesity', source: 'Obesity', value: '23' },
    { target: 'Infantile Apparent Life-Threatening Event', source: 'Cyanosis', value: '15' },
    { target: 'Edema, Cardiac', source: 'Edema', value: '15' },
    { target: 'Hydrops Fetalis', source: 'Edema', value: '15' },
    { target: 'Eye Hemorrhage', source: 'Eye Manifestations', value: '15' },
    { target: 'Eye Pain', source: 'Eye Manifestations', value: '15' },
    { target: 'Susac Syndrome', source: 'Eye Manifestations', value: '15' },
    { target: 'Mental Fatigue', source: 'Fatigue', value: '15' },
    { target: 'Alert Fatigue, Health Personnel', source: 'Mental Fatigue', value: '19' },
    { target: 'Compassion Fatigue', source: 'Mental Fatigue', value: '19' },
    { target: 'Systolic Murmurs', source: 'Heart Murmurs', value: '15' },
    { target: 'Space Motion Sickness', source: 'Motion Sickness', value: '15' },
    { target: 'Bilateral Vestibulopathy', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Cerebrospinal Fluid Leak', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Decerebrate State', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Dyskinesias', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Gait Disorders, Neurologic', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Meningism', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Neurobehavioral Manifestations', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Neuromuscular Manifestations', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Orthostatic Intolerance', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Pain', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Paralysis', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Paresis', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Psychophysiologic Disorders', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Pupil Disorders', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Reflex, Abnormal', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Seizures', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Sensation Disorders', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Sleep Wake Disorders', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Susac Syndrome', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Urinary Bladder, Neurogenic', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Vertigo', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Voice Disorders', source: 'Neurologic Manifestations', value: '15' },
    { target: 'Cerebrospinal Fluid Otorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Cerebrospinal Fluid Rhinorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Ataxia', source: 'Dyskinesias', value: '19' },
    { target: 'Athetosis', source: 'Dyskinesias', value: '19' },
    { target: 'Catalepsy', source: 'Dyskinesias', value: '19' },
    { target: 'Chorea', source: 'Dyskinesias', value: '19' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Dyskinesias', value: '19' },
    { target: 'Dystonia', source: 'Dyskinesias', value: '19' },
    { target: 'Hyperkinesis', source: 'Dyskinesias', value: '19' },
    { target: 'Hypokinesia', source: 'Dyskinesias', value: '19' },
    { target: 'Myoclonus', source: 'Dyskinesias', value: '19' },
    { target: 'Psychomotor Agitation', source: 'Dyskinesias', value: '19' },
    { target: 'Synkinesis', source: 'Dyskinesias', value: '19' },
    { target: 'Tics', source: 'Dyskinesias', value: '19' },
    { target: 'Tremor', source: 'Dyskinesias', value: '19' },
    { target: 'Cerebellar Ataxia', source: 'Ataxia', value: '23' },
    { target: 'Gait Ataxia', source: 'Ataxia', value: '23' },
    { target: 'Tardive Dyskinesia', source: 'Dyskinesia, Drug-Induced', value: '23' },
    { target: 'Torticollis', source: 'Dystonia', value: '23' },
    { target: 'Akathisia, Drug-Induced', source: 'Psychomotor Agitation', value: '23' },
    { target: 'Gait Apraxia', source: 'Gait Disorders, Neurologic', value: '19' },
    { target: 'Gait Ataxia', source: 'Gait Disorders, Neurologic', value: '19' },
    { target: 'Anhedonia', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Catatonia', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Communication Disorders', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Confusion', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Consciousness Disorders', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Lethargy', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Memory Disorders', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Intellectual Disability', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Perceptual Disorders', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Psychomotor Disorders', source: 'Neurobehavioral Manifestations', value: '19' },
    { target: 'Language Disorders', source: 'Communication Disorders', value: '23' },
    { target: 'Learning Disorders', source: 'Communication Disorders', value: '23' },
    { target: 'Agraphia', source: 'Language Disorders', value: '27' },
    { target: 'Anomia', source: 'Language Disorders', value: '27' },
    { target: 'Dyslexia', source: 'Language Disorders', value: '27' },
    { target: 'Language Development Disorders', source: 'Language Disorders', value: '27' },
    { target: 'Speech Disorders', source: 'Language Disorders', value: '27' },
    { target: 'Dyslexia, Acquired', source: 'Dyslexia', value: '31' },
    { target: 'Alexia, Pure', source: 'Dyslexia, Acquired', value: '35' },
    { target: 'Aphasia', source: 'Speech Disorders', value: '31' },
    { target: 'Articulation Disorders', source: 'Speech Disorders', value: '31' },
    { target: 'Echolalia', source: 'Speech Disorders', value: '31' },
    { target: 'Mutism', source: 'Speech Disorders', value: '31' },
    { target: 'Stuttering', source: 'Speech Disorders', value: '31' },
    { target: 'Aphasia, Broca', source: 'Aphasia', value: '35' },
    { target: 'Aphasia, Conduction', source: 'Aphasia', value: '35' },
    { target: 'Aphasia, Primary Progressive', source: 'Aphasia', value: '35' },
    { target: 'Aphasia, Wernicke', source: 'Aphasia', value: '35' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Aphasia, Primary Progressive', value: '39' },
    { target: 'Dysarthria', source: 'Articulation Disorders', value: '35' },
    { target: 'Dyscalculia', source: 'Learning Disorders', value: '27' },
    { target: 'Dyslexia', source: 'Learning Disorders', value: '27' },
    { target: 'Dyslexia, Acquired', source: 'Dyslexia', value: '31' },
    { target: 'Delirium', source: 'Confusion', value: '23' },
    { target: 'Emergence Delirium', source: 'Delirium', value: '27' },
    { target: 'Unconsciousness', source: 'Consciousness Disorders', value: '23' },
    { target: 'Coma', source: 'Unconsciousness', value: '27' },
    { target: 'Persistent Vegetative State', source: 'Unconsciousness', value: '27' },
    { target: 'Stupor', source: 'Unconsciousness', value: '27' },
    { target: 'Syncope', source: 'Unconsciousness', value: '27' },
    { target: 'Syncope, Vasovagal', source: 'Syncope', value: '31' },
    { target: 'Amnesia', source: 'Memory Disorders', value: '23' },
    { target: 'Korsakoff Syndrome', source: 'Memory Disorders', value: '23' },
    { target: 'Amnesia, Anterograde', source: 'Amnesia', value: '27' },
    { target: 'Amnesia, Retrograde', source: 'Amnesia', value: '27' },
    { target: 'Amnesia, Transient Global', source: 'Amnesia', value: '27' },
    { target: 'Agnosia', source: 'Perceptual Disorders', value: '23' },
    { target: 'Alice in Wonderland Syndrome', source: 'Perceptual Disorders', value: '23' },
    { target: 'Allesthesia', source: 'Perceptual Disorders', value: '23' },
    { target: 'Auditory Perceptual Disorders', source: 'Perceptual Disorders', value: '23' },
    { target: 'Hallucinations', source: 'Perceptual Disorders', value: '23' },
    { target: 'Illusions', source: 'Perceptual Disorders', value: '23' },
    { target: 'Phantom Limb', source: 'Perceptual Disorders', value: '23' },
    { target: 'Gerstmann Syndrome', source: 'Agnosia', value: '27' },
    { target: 'Prosopagnosia', source: 'Agnosia', value: '27' },
    { target: 'Apraxias', source: 'Psychomotor Disorders', value: '23' },
    { target: 'Psychomotor Agitation', source: 'Psychomotor Disorders', value: '23' },
    { target: 'Alien Hand Syndrome', source: 'Apraxias', value: '27' },
    { target: 'Apraxia, Ideomotor', source: 'Apraxias', value: '27' },
    { target: 'Gait Apraxia', source: 'Apraxias', value: '27' },
    { target: 'Fasciculation', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscle Cramp', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscle Hypertonia', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscle Hypotonia', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscle Weakness', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscular Atrophy', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Myokymia', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Myotonia', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Spasm', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Tetany', source: 'Neuromuscular Manifestations', value: '19' },
    { target: 'Muscle Rigidity', source: 'Muscle Hypertonia', value: '23' },
    { target: 'Muscle Spasticity', source: 'Muscle Hypertonia', value: '23' },
    { target: 'Sarcopenia', source: 'Muscular Atrophy', value: '23' },
    { target: 'Hemifacial Spasm', source: 'Spasm', value: '23' },
    { target: 'Trismus', source: 'Spasm', value: '23' },
    { target: 'Abdominal Pain', source: 'Pain', value: '19' },
    { target: 'Acute Pain', source: 'Pain', value: '19' },
    { target: 'Arthralgia', source: 'Pain', value: '19' },
    { target: 'Back Pain', source: 'Pain', value: '19' },
    { target: 'Breakthrough Pain', source: 'Pain', value: '19' },
    { target: 'Cancer Pain', source: 'Pain', value: '19' },
    { target: 'Chest Pain', source: 'Pain', value: '19' },
    { target: 'Chronic Pain', source: 'Pain', value: '19' },
    { target: 'Earache', source: 'Pain', value: '19' },
    { target: 'Eye Pain', source: 'Pain', value: '19' },
    { target: 'Facial Pain', source: 'Pain', value: '19' },
    { target: 'Flank Pain', source: 'Pain', value: '19' },
    { target: 'Glossalgia', source: 'Pain', value: '19' },
    { target: 'Headache', source: 'Pain', value: '19' },
    { target: 'Labor Pain', source: 'Pain', value: '19' },
    { target: 'Mastodynia', source: 'Pain', value: '19' },
    { target: 'Metatarsalgia', source: 'Pain', value: '19' },
    { target: 'Musculoskeletal Pain', source: 'Pain', value: '19' },
    { target: 'Neck Pain', source: 'Pain', value: '19' },
    { target: 'Neuralgia', source: 'Pain', value: '19' },
    { target: 'Nociceptive Pain', source: 'Pain', value: '19' },
    { target: 'Pain, Intractable', source: 'Pain', value: '19' },
    { target: 'Pain, Postoperative', source: 'Pain', value: '19' },
    { target: 'Pain, Referred', source: 'Pain', value: '19' },
    { target: 'Pelvic Pain', source: 'Pain', value: '19' },
    { target: 'Renal Colic', source: 'Pain', value: '19' },
    { target: 'Abdomen, Acute', source: 'Abdominal Pain', value: '23' },
    { target: 'Shoulder Pain', source: 'Arthralgia', value: '23' },
    { target: 'Failed Back Surgery Syndrome', source: 'Back Pain', value: '23' },
    { target: 'Low Back Pain', source: 'Back Pain', value: '23' },
    { target: 'Angina Pectoris', source: 'Chest Pain', value: '23' },
    { target: 'Angina, Unstable', source: 'Angina Pectoris', value: '27' },
    { target: 'Angina, Stable', source: 'Angina Pectoris', value: '27' },
    { target: 'Angina Pectoris, Variant', source: 'Angina, Unstable', value: '31' },
    { target: 'Toothache', source: 'Facial Pain', value: '23' },
    { target: 'Slit Ventricle Syndrome', source: 'Headache', value: '23' },
    { target: 'Morton Neuroma', source: 'Metatarsalgia', value: '23' },
    { target: 'Myalgia', source: 'Musculoskeletal Pain', value: '23' },
    { target: 'Pelvic Girdle Pain', source: 'Musculoskeletal Pain', value: '23' },
    { target: 'Morton Neuroma', source: 'Neuralgia', value: '23' },
    { target: 'Neuralgia, Postherpetic', source: 'Neuralgia', value: '23' },
    { target: 'Piriformis Muscle Syndrome', source: 'Neuralgia', value: '23' },
    { target: 'Pudendal Neuralgia', source: 'Neuralgia', value: '23' },
    { target: 'Sciatica', source: 'Neuralgia', value: '23' },
    { target: 'Visceral Pain', source: 'Nociceptive Pain', value: '23' },
    { target: 'Phantom Limb', source: 'Pain, Postoperative', value: '23' },
    { target: 'Dysmenorrhea', source: 'Pelvic Pain', value: '23' },
    { target: 'Pelvic Girdle Pain', source: 'Pelvic Pain', value: '23' },
    { target: 'Piriformis Muscle Syndrome', source: 'Pelvic Pain', value: '23' },
    { target: 'Facial Paralysis', source: 'Paralysis', value: '19' },
    { target: 'Gastroparesis', source: 'Paralysis', value: '19' },
    { target: 'Hemiplegia', source: 'Paralysis', value: '19' },
    { target: 'Ophthalmoplegia', source: 'Paralysis', value: '19' },
    { target: 'Paraplegia', source: 'Paralysis', value: '19' },
    { target: 'Pseudobulbar Palsy', source: 'Paralysis', value: '19' },
    { target: 'Quadriplegia', source: 'Paralysis', value: '19' },
    { target: 'Respiratory Paralysis', source: 'Paralysis', value: '19' },
    { target: 'Vocal Cord Paralysis', source: 'Paralysis', value: '19' },
    { target: 'Ophthalmoplegia, Chronic Progressive External', source: 'Ophthalmoplegia', value: '23' },
    { target: 'Ophthalmoplegic Migraine', source: 'Ophthalmoplegia', value: '23' },
    { target: 'Supranuclear Palsy, Progressive', source: 'Ophthalmoplegia', value: '23' },
    { target: 'Kearns-Sayre Syndrome', source: 'Ophthalmoplegia, Chronic Progressive External', value: '27' },
    { target: 'Brown-Sequard Syndrome', source: 'Paraplegia', value: '23' },
    { target: 'Paraparesis', source: 'Paresis', value: '19' },
    { target: 'Paraparesis, Spastic', source: 'Paraparesis', value: '23' },
    { target: 'Anisocoria', source: 'Pupil Disorders', value: '19' },
    { target: 'Miosis', source: 'Pupil Disorders', value: '19' },
    { target: 'Tonic Pupil', source: 'Pupil Disorders', value: '19' },
    { target: 'Horner Syndrome', source: 'Miosis', value: '23' },
    { target: 'Dizziness', source: 'Sensation Disorders', value: '19' },
    { target: 'Hearing Disorders', source: 'Sensation Disorders', value: '19' },
    { target: 'Olfaction Disorders', source: 'Sensation Disorders', value: '19' },
    { target: 'Somatosensory Disorders', source: 'Sensation Disorders', value: '19' },
    { target: 'Taste Disorders', source: 'Sensation Disorders', value: '19' },
    { target: 'Vision Disorders', source: 'Sensation Disorders', value: '19' },
    { target: 'Hearing Loss', source: 'Hearing Disorders', value: '23' },
    { target: 'Hyperacusis', source: 'Hearing Disorders', value: '23' },
    { target: 'Tinnitus', source: 'Hearing Disorders', value: '23' },
    { target: 'Deafness', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Bilateral', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Conductive', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Functional', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, High-Frequency', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Mixed Conductive-Sensorineural', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Sensorineural', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Sudden', source: 'Hearing Loss', value: '27' },
    { target: 'Hearing Loss, Unilateral', source: 'Hearing Loss', value: '27' },
    { target: 'Deaf-Blind Disorders', source: 'Deafness', value: '31' },
    { target: 'Hearing Loss, Central', source: 'Hearing Loss, Sensorineural', value: '31' },
    { target: 'Hearing Loss, Noise-Induced', source: 'Hearing Loss, Sensorineural', value: '31' },
    { target: 'Presbycusis', source: 'Hearing Loss, Sensorineural', value: '31' },
    { target: 'Usher Syndromes', source: 'Hearing Loss, Sensorineural', value: '31' },
    { target: 'Hyperalgesia', source: 'Somatosensory Disorders', value: '23' },
    { target: 'Hyperesthesia', source: 'Somatosensory Disorders', value: '23' },
    { target: 'Hypesthesia', source: 'Somatosensory Disorders', value: '23' },
    { target: 'Paresthesia', source: 'Somatosensory Disorders', value: '23' },
    { target: 'Ageusia', source: 'Taste Disorders', value: '23' },
    { target: 'Dysgeusia', source: 'Taste Disorders', value: '23' },
    { target: 'Alice in Wonderland Syndrome', source: 'Vision Disorders', value: '23' },
    { target: 'Amblyopia', source: 'Vision Disorders', value: '23' },
    { target: 'Blindness', source: 'Vision Disorders', value: '23' },
    { target: 'Color Vision Defects', source: 'Vision Disorders', value: '23' },
    { target: 'Diplopia', source: 'Vision Disorders', value: '23' },
    { target: 'Hemianopsia', source: 'Vision Disorders', value: '23' },
    { target: 'Photophobia', source: 'Vision Disorders', value: '23' },
    { target: 'Scotoma', source: 'Vision Disorders', value: '23' },
    { target: 'Vision, Low', source: 'Vision Disorders', value: '23' },
    { target: 'Amaurosis Fugax', source: 'Blindness', value: '27' },
    { target: 'Blindness, Cortical', source: 'Blindness', value: '27' },
    { target: 'Deaf-Blind Disorders', source: 'Blindness', value: '27' },
    { target: 'Sleep Deprivation', source: 'Sleep Wake Disorders', value: '19' },
    { target: 'Benign Paroxysmal Positional Vertigo', source: 'Vertigo', value: '19' },
    { target: 'Aphonia', source: 'Voice Disorders', value: '19' },
    { target: 'Dysphonia', source: 'Voice Disorders', value: '19' },
    { target: 'Hoarseness', source: 'Voice Disorders', value: '19' },
    { target: 'Oral Hemorrhage', source: 'Oral Manifestations', value: '15' },
    { target: 'Polydipsia, Psychogenic', source: 'Polydipsia', value: '15' },
    { target: 'Abdominal Pain', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Aerophagy', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Anorexia', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Constipation', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Diarrhea', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Dyspepsia', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Encopresis', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Eructation', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Flatulence', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Gagging', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Halitosis', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Heartburn', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Hiccup', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Hyperphagia', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Nausea', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Pica', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Vomiting', source: 'Signs and Symptoms, Digestive', value: '15' },
    { target: 'Abdomen, Acute', source: 'Abdominal Pain', value: '19' },
    { target: 'Diarrhea, Infantile', source: 'Diarrhea', value: '19' },
    { target: 'Bulimia', source: 'Hyperphagia', value: '19' },
    { target: 'Postoperative Nausea and Vomiting', source: 'Nausea', value: '19' },
    { target: 'Hematemesis', source: 'Vomiting', value: '19' },
    { target: 'Morning Sickness', source: 'Vomiting', value: '19' },
    { target: 'Postoperative Nausea and Vomiting', source: 'Vomiting', value: '19' },
    { target: 'Vomiting, Anticipatory', source: 'Vomiting', value: '19' },
    { target: 'Hyperemesis Gravidarum', source: 'Morning Sickness', value: '23' },
    { target: 'Hypoxia', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Apnea', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Cheyne-Stokes Respiration', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Cough', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Dyspnea', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hemoptysis', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hoarseness', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hypercapnia', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hyperoxia', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hyperventilation', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hypocapnia', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Hypoventilation', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Infantile Apparent Life-Threatening Event', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Mouth Breathing', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Respiratory Sounds', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Sneezing', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Tachypnea', source: 'Signs and Symptoms, Respiratory', value: '15' },
    { target: 'Fetal Hypoxia', source: 'Hypoxia', value: '19' },
    { target: 'Dyspnea, Paroxysmal', source: 'Dyspnea', value: '19' },
    { target: 'Snoring', source: 'Respiratory Sounds', value: '19' },
    { target: 'Transient Tachypnea of the Newborn', source: 'Tachypnea', value: '19' },
    { target: 'Cafe-au-Lait Spots', source: 'Skin Manifestations', value: '15' },
    { target: 'Cellulite', source: 'Skin Manifestations', value: '15' },
    { target: 'Ecchymosis', source: 'Skin Manifestations', value: '15' },
    { target: 'Jaundice', source: 'Skin Manifestations', value: '15' },
    { target: 'Livedo Reticularis', source: 'Skin Manifestations', value: '15' },
    { target: 'Necrolytic Migratory Erythema', source: 'Skin Manifestations', value: '15' },
    { target: 'Pallor', source: 'Skin Manifestations', value: '15' },
    { target: 'Pruritus', source: 'Skin Manifestations', value: '15' },
    { target: 'Purpura', source: 'Skin Manifestations', value: '15' },
    { target: 'Striae Distensae', source: 'Skin Manifestations', value: '15' },
    { target: 'Jaundice, Obstructive', source: 'Jaundice', value: '19' },
    { target: 'Tinea Pedis', source: 'Pruritus', value: '19' },
    { target: 'Purpura Fulminans', source: 'Purpura', value: '19' },
    { target: 'Purpura, Hyperglobulinemic', source: 'Purpura', value: '19' },
    { target: 'Purpura, Schoenlein-Henoch', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic', source: 'Purpura', value: '19' },
    { target: 'Waterhouse-Friderichsen Syndrome', source: 'Purpura', value: '19' },
    { target: 'Purpura, Thrombocytopenic, Idiopathic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Purpura, Thrombotic Thrombocytopenic', source: 'Purpura, Thrombocytopenic', value: '23' },
    { target: 'Hypercalciuria', source: 'Urological Manifestations', value: '15' },
    { target: 'Lower Urinary Tract Symptoms', source: 'Urological Manifestations', value: '15' },
    { target: 'Oliguria', source: 'Urological Manifestations', value: '15' },
    { target: 'Polyuria', source: 'Urological Manifestations', value: '15' },
    { target: 'Proteinuria', source: 'Urological Manifestations', value: '15' },
    { target: 'Urinoma', source: 'Urological Manifestations', value: '15' },
    { target: 'Dysuria', source: 'Lower Urinary Tract Symptoms', value: '19' },
    { target: 'Nocturia', source: 'Lower Urinary Tract Symptoms', value: '19' },
    { target: 'Prostatism', source: 'Lower Urinary Tract Symptoms', value: '19' },
    { target: 'Urinary Bladder, Overactive', source: 'Lower Urinary Tract Symptoms', value: '19' },
    { target: 'Urinary Incontinence', source: 'Lower Urinary Tract Symptoms', value: '19' },
    { target: 'Urinary Incontinence, Stress', source: 'Urinary Incontinence', value: '23' },
    { target: 'Urinary Incontinence, Urge', source: 'Urinary Incontinence', value: '23' },
    { target: 'Albuminuria', source: 'Proteinuria', value: '19' },
    { target: 'Hemoglobinuria', source: 'Proteinuria', value: '19' },
    { target: 'Hirsutism', source: 'Virilism', value: '15' },
    { target: 'Farmer\'s Lung', source: 'Agricultural Workers\' Diseases', value: '11' },
    { target: 'Silo Filler\'s Disease', source: 'Agricultural Workers\' Diseases', value: '11' },
    { target: 'Asbestosis', source: 'Pneumoconiosis', value: '11' },
    { target: 'Berylliosis', source: 'Pneumoconiosis', value: '11' },
    { target: 'Byssinosis', source: 'Pneumoconiosis', value: '11' },
    { target: 'Caplan Syndrome', source: 'Pneumoconiosis', value: '11' },
    { target: 'Siderosis', source: 'Pneumoconiosis', value: '11' },
    { target: 'Silicosis', source: 'Pneumoconiosis', value: '11' },
    { target: 'Anthracosilicosis', source: 'Silicosis', value: '15' },
    { target: 'Silicotuberculosis', source: 'Silicosis', value: '15' },
    { target: 'Akathisia, Drug-Induced', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Anticholinergic Syndrome', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Cardiotoxicity', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Drug Hypersensitivity', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Chemical and Drug Induced Liver Injury', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Metabolic Side Effects of Drugs and Substances', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Propofol Infusion Syndrome', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Serotonin Syndrome', source: 'Drug-Related Side Effects and Adverse Reactions', value: '11' },
    { target: 'Asthma, Aspirin-Induced', source: 'Drug Hypersensitivity', value: '15' },
    { target: 'Drug Eruptions', source: 'Drug Hypersensitivity', value: '15' },
    { target: 'Acute Generalized Exanthematous Pustulosis', source: 'Drug Eruptions', value: '19' },
    { target: 'Drug Hypersensitivity Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Erythema Nodosum', source: 'Drug Eruptions', value: '19' },
    { target: 'Hand-Foot Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Nicolau Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Serum Sickness', source: 'Drug Eruptions', value: '19' },
    { target: 'Stevens-Johnson Syndrome', source: 'Drug Eruptions', value: '19' },
    { target: 'Drug-Induced Liver Injury, Chronic', source: 'Chemical and Drug Induced Liver Injury', value: '15' },
    { target: 'Argyria', source: 'Poisoning', value: '11' },
    { target: 'Arsenic Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Bites and Stings', source: 'Poisoning', value: '11' },
    { target: 'Cadmium Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Carbon Tetrachloride Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Chemical and Drug Induced Liver Injury', source: 'Poisoning', value: '11' },
    { target: 'Fluoride Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Foodborne Diseases', source: 'Poisoning', value: '11' },
    { target: 'Gas Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Lead Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Manganese Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Mercury Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Mycotoxicosis', source: 'Poisoning', value: '11' },
    { target: 'Neurotoxicity Syndromes', source: 'Poisoning', value: '11' },
    { target: 'Organophosphate Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Plant Poisoning', source: 'Poisoning', value: '11' },
    { target: 'Psychoses, Substance-Induced', source: 'Poisoning', value: '11' },
    { target: 'Water Intoxication', source: 'Poisoning', value: '11' },
    { target: 'Insect Bites and Stings', source: 'Bites and Stings', value: '15' },
    { target: 'Scorpion Stings', source: 'Bites and Stings', value: '15' },
    { target: 'Snake Bites', source: 'Bites and Stings', value: '15' },
    { target: 'Spider Bites', source: 'Bites and Stings', value: '15' },
    { target: 'Tick Bites', source: 'Bites and Stings', value: '15' },
    { target: 'Tick Toxicoses', source: 'Tick Bites', value: '19' },
    { target: 'Tick Paralysis', source: 'Tick Toxicoses', value: '23' },
    { target: 'Drug-Induced Liver Injury, Chronic', source: 'Chemical and Drug Induced Liver Injury', value: '15' },
    { target: 'Botulism', source: 'Foodborne Diseases', value: '15' },
    { target: 'Ciguatera Poisoning', source: 'Foodborne Diseases', value: '15' },
    { target: 'Favism', source: 'Foodborne Diseases', value: '15' },
    { target: 'Mushroom Poisoning', source: 'Foodborne Diseases', value: '15' },
    { target: 'Salmonella Food Poisoning', source: 'Foodborne Diseases', value: '15' },
    { target: 'Shellfish Poisoning', source: 'Foodborne Diseases', value: '15' },
    { target: 'Staphylococcal Food Poisoning', source: 'Foodborne Diseases', value: '15' },
    { target: 'Carbon Monoxide Poisoning', source: 'Gas Poisoning', value: '15' },
    { target: 'Inert Gas Narcosis', source: 'Gas Poisoning', value: '15' },
    { target: 'Lead Poisoning, Nervous System', source: 'Lead Poisoning', value: '15' },
    { target: 'Lead Poisoning, Nervous System, Adult', source: 'Lead Poisoning, Nervou System', value: '19' },
    { target: 'Lead Poisoning, Nervous System, Childhood', source: 'Lead Poisoning, Nervous System', value: '19' },
    { target: 'Mercury Poisoning, Nervous System', source: 'Mercury Poisoning', value: '15' },
    { target: 'Acrodynia', source: 'Mercury Poisoning, Nervous System', value: '19' },
    { target: 'Ergotism', source: 'Mycotoxicosis', value: '15' },
    { target: 'Mushroom Poisoning', source: 'Mycotoxicosis', value: '15' },
    { target: 'Akathisia, Drug-Induced', source: 'Neurotoxicity Syndromes', value: '15' },
    { target: 'Alcohol-Induced Disorders, Nervous System', source: 'Neurotoxicity Syndromes', value: '15' },
    { target: 'Dyskinesia, Drug-Induced', source: 'Neurotoxicity Syndromes', value: '15' },
    { target: 'MPTP Poisoning', source: 'Neurotoxicity Syndromes', value: '15' },
    { target: 'Neuroleptic Malignant Syndrome', source: 'Neurotoxicity Syndromes', value: '15' },
    { target: 'Alcohol Amnestic Disorder', source: 'Alcohol-Induced Disorders, Nervous System', value: '19' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Alcohol-Induced Disorders, Nervous System', value: '19' },
    { target: 'Alcohol Withdrawal Seizures', source: 'Alcohol-Induced Disorders, Nervous System', value: '19' },
    { target: 'Alcoholic Neuropathy', source: 'Alcohol-Induced Disorders, Nervous System', value: '19' },
    { target: 'Korsakoff Syndrome', source: 'Alcohol Amnestic Disorder', value: '23' },
    { target: 'Favism', source: 'Plant Poisoning', value: '15' },
    { target: 'Lathyrism', source: 'Plant Poisoning', value: '15' },
    { target: 'Psychoses, Alcoholic', source: 'Psychoses, Substance-Induced', value: '15' },
    { target: 'Alcohol-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Amphetamine-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Cocaine-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Drug Overdose', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Inhalant Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Marijuana Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Neonatal Abstinence Syndrome', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Opioid-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Phencyclidine Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Psychoses, Substance-Induced', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Substance Abuse, Intravenous', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Substance Withdrawal Syndrome', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Tobacco Use Disorder', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Alcohol-Induced Disorders', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcoholic Intoxication', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcoholism', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Binge Drinking', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Wernicke Encephalopathy', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcohol-Induced Disorders, Nervous System', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Cardiomyopathy, Alcoholic', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Fetal Alcohol Spectrum Disorders', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Liver Diseases, Alcoholic', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Pancreatitis, Alcoholic', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Psychoses, Alcoholic', source: 'Alcohol-Induced Disorders', value: '19' },
    { target: 'Alcohol Amnestic Disorder', source: 'Alcohol-Induced Disorders, Nervous System', value: '23' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Alcohol-Induced Disorders, Nervous System', value: '23' },
    { target: 'Alcohol Withdrawal Seizures', source: 'Alcohol-Induced Disorders, Nervous System', value: '23' },
    { target: 'Alcoholic Neuropathy', source: 'Alcohol-Induced Disorders, Nervous System', value: '23' },
    { target: 'Korsakoff Syndrome', source: 'Alcohol Amnestic Disorder', value: '27' },
    { target: 'Fatty Liver, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '23' },
    { target: 'Hepatitis, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '23' },
    { target: 'Liver Cirrhosis, Alcoholic', source: 'Liver Diseases, Alcoholic', value: '23' },
    { target: 'Heroin Dependence', source: 'Opioid-Related Disorders', value: '15' },
    { target: 'Morphine Dependence', source: 'Opioid-Related Disorders', value: '15' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Substance Withdrawal Syndrome', value: '15' },
    { target: 'Alcohol Withdrawal Seizures', source: 'Substance Withdrawal Syndrome', value: '15' },
    { target: 'Hernia, Diaphragmatic, Traumatic', source: 'Abdominal Injuries', value: '11' },
    { target: 'Splenic Rupture', source: 'Abdominal Injuries', value: '11' },
    { target: 'Stomach Rupture', source: 'Abdominal Injuries', value: '11' },
    { target: 'Splenosis', source: 'Splenic Rupture', value: '15' },
    { target: 'Forearm Injuries', source: 'Arm Injuries', value: '11' },
    { target: 'Humeral Fractures', source: 'Arm Injuries', value: '11' },
    { target: 'Tennis Elbow', source: 'Arm Injuries', value: '11' },
    { target: 'Wrist Injuries', source: 'Arm Injuries', value: '11' },
    { target: 'Radius Fractures', source: 'Forearm Injuries', value: '15' },
    { target: 'Ulna Fractures', source: 'Forearm Injuries', value: '15' },
    { target: 'Spinal Injuries', source: 'Back Injuries', value: '11' },
    { target: 'Spinal Fractures', source: 'Spinal Injuries', value: '15' },
    { target: 'Blast Injuries', source: 'Barotrauma', value: '11' },
    { target: 'Decompression Sickness', source: 'Barotrauma', value: '11' },
    { target: 'Paralysis, Obstetric', source: 'Birth Injuries', value: '11' },
    { target: 'Bites, Human', source: 'Bites and Stings', value: '11' },
    { target: 'Insect Bites and Stings', source: 'Bites and Stings', value: '11' },
    { target: 'Scorpion Stings', source: 'Bites and Stings', value: '11' },
    { target: 'Snake Bites', source: 'Bites and Stings', value: '11' },
    { target: 'Spider Bites', source: 'Bites and Stings', value: '11' },
    { target: 'Tick Bites', source: 'Bites and Stings', value: '11' },
    { target: 'Burns, Chemical', source: 'Burns', value: '11' },
    { target: 'Burns, Electric', source: 'Burns', value: '11' },
    { target: 'Burns, Inhalation', source: 'Burns', value: '11' },
    { target: 'Eye Burns', source: 'Burns', value: '11' },
    { target: 'Sunburn', source: 'Burns', value: '11' },
    { target: 'Smoke Inhalation Injury', source: 'Burns, Inhalation', value: '15' },
    { target: 'Frostbite', source: 'Cold Injury', value: '11' },
    { target: 'Chilblains', source: 'Frostbite', value: '15' },
    { target: 'Crush Syndrome', source: 'Crush Injuries', value: '11' },
    { target: 'Diastasis, Bone', source: 'Joint Dislocations', value: '11' },
    { target: 'Diastasis, Muscle', source: 'Joint Dislocations', value: '11' },
    { target: 'Fracture Dislocation', source: 'Joint Dislocations', value: '11' },
    { target: 'Hip Dislocation', source: 'Joint Dislocations', value: '11' },
    { target: 'Knee Dislocation', source: 'Joint Dislocations', value: '11' },
    { target: 'Patellar Dislocation', source: 'Joint Dislocations', value: '11' },
    { target: 'Shoulder Dislocation', source: 'Joint Dislocations', value: '11' },
    { target: 'Pubic Symphysis Diastasis', source: 'Diastasis, Bone', value: '15' },
    { target: 'Colles\' Fracture', source: 'Fracture Dislocation', value: '15' },
    { target: 'Monteggia\'s Fracture', source: 'Fracture Dislocation', value: '15' },
    { target: 'Salter-Harris Fractures', source: 'Fracture Dislocation', value: '15' },
    { target: 'Near Drowning', source: 'Drowning', value: '11' },
    { target: 'Burns, Electric', source: 'Electric Injuries', value: '11' },
    { target: 'Conducted Energy Weapon Injuries', source: 'Electric Injuries', value: '11' },
    { target: 'Lightning Injuries', source: 'Electric Injuries', value: '11' },
    { target: 'Bezoars', source: 'Foreign Bodies', value: '11' },
    { target: 'Eye Foreign Bodies', source: 'Foreign Bodies', value: '11' },
    { target: 'Foreign-Body Migration', source: 'Foreign Bodies', value: '11' },
    { target: 'Foreign-Body Reaction', source: 'Foreign Bodies', value: '11' },
    { target: 'Artificial Lens Implant Migration', source: 'Foreign-Body Migration', value: '15' },
    { target: 'Intrauterine Device Migration', source: 'Foreign-Body Migration', value: '15' },
    { target: 'Granuloma, Foreign-Body', source: 'Foreign-Body Reaction', value: '15' },
    { target: 'Ankle Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Fracture Dislocation', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Avulsion', source: 'Fractures, Bone', value: '11' },
    { target: 'Femoral Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Closed', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Comminuted', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Compression', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Malunited', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Multiple', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Open', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Spontaneous', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Stress', source: 'Fractures, Bone', value: '11' },
    { target: 'Fractures, Ununited', source: 'Fractures, Bone', value: '11' },
    { target: 'Humeral Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Intra-Articular Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Osteoporotic Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Periprosthetic Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Radius Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Rib Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Shoulder Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Skull Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Spinal Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Tibial Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Ulna Fractures', source: 'Fractures, Bone', value: '11' },
    { target: 'Salter-Harris Fractures', source: 'Fracture Dislocation', value: '15' },
    { target: 'Hip Fractures', source: 'Femoral Fractures', value: '15' },
    { target: 'Femoral Neck Fractures', source: 'Hip Fractures', value: '19' },
    { target: 'Pseudarthrosis', source: 'Fractures, Ununited', value: '15' },
    { target: 'Colles\' Fracture', source: 'Radius Fractures', value: '15' },
    { target: 'Bankart Lesions', source: 'Shoulder Fractures', value: '15' },
    { target: 'Jaw Fractures', source: 'Skull Fractures', value: '15' },
    { target: 'Orbital Fractures', source: 'Skull Fractures', value: '15' },
    { target: 'Skull Fracture, Basilar', source: 'Skull Fractures', value: '15' },
    { target: 'Skull Fracture, Depressed', source: 'Skull Fractures', value: '15' },
    { target: 'Zygomatic Fractures', source: 'Skull Fractures', value: '15' },
    { target: 'Mandibular Fractures', source: 'Jaw Fractures', value: '19' },
    { target: 'Maxillary Fractures', source: 'Jaw Fractures', value: '19' },
    { target: 'Monteggia\'s Fracture', source: 'Ulna Fractures', value: '15' },
    { target: 'Chilblains', source: 'Frostbite', value: '11' },
    { target: 'Finger Injuries', source: 'Hand Injuries', value: '11' },
    { target: 'Heat Exhaustion', source: 'Heat Stress Disorders', value: '11' },
    { target: 'Heat Stroke', source: 'Heat Stress Disorders', value: '11' },
    { target: 'Sunstroke', source: 'Heat Stroke', value: '15' },
    { target: 'Hip Dislocation', source: 'Hip Injuries', value: '11' },
    { target: 'Hip Fractures', source: 'Hip Injuries', value: '11' },
    { target: 'Femoral Neck Fractures', source: 'Hip Fractures', value: '15' },
    { target: 'Ankle Injuries', source: 'Leg Injuries', value: '11' },
    { target: 'Femoral Fractures', source: 'Leg Injuries', value: '11' },
    { target: 'Foot Injuries', source: 'Leg Injuries', value: '11' },
    { target: 'Knee Injuries', source: 'Leg Injuries', value: '11' },
    { target: 'Medial Tibial Stress Syndrome', source: 'Leg Injuries', value: '11' },
    { target: 'Tibial Meniscus Injuries', source: 'Leg Injuries', value: '11' },
    { target: 'Tibial Fractures', source: 'Leg Injuries', value: '11' },
    { target: 'Hip Fractures', source: 'Femoral Fractures', value: '15' },
    { target: 'Femoral Neck Fractures', source: 'Hip Fractures', value: '19' },
    { target: 'Hallux Limitus', source: 'Foot Injuries', value: '15' },
    { target: 'Anterior Cruciate Ligament Injuries', source: 'Knee Injuries', value: '15' },
    { target: 'Iliotibial Band Syndrome', source: 'Knee Injuries', value: '15' },
    { target: 'Knee Dislocation', source: 'Knee Injuries', value: '15' },
    { target: 'Patellar Dislocation', source: 'Knee Injuries', value: '15' },
    { target: 'Fractures, Multiple', source: 'Multiple Trauma', value: '11' },
    { target: 'Whiplash Injuries', source: 'Neck Injuries', value: '11' },
    { target: 'Abnormalities, Radiation-Induced', source: 'Radiation Injuries', value: '11' },
    { target: 'Acute Radiation Syndrome', source: 'Radiation Injuries', value: '11' },
    { target: 'Cardiotoxicity', source: 'Radiation Injuries', value: '11' },
    { target: 'Leukemia, Radiation-Induced', source: 'Radiation Injuries', value: '11' },
    { target: 'Neoplasms, Radiation-Induced', source: 'Radiation Injuries', value: '11' },
    { target: 'Osteoradionecrosis', source: 'Radiation Injuries', value: '11' },
    { target: 'Radiation Injuries, Experimental', source: 'Radiation Injuries', value: '11' },
    { target: 'Radiation Pneumonitis', source: 'Radiation Injuries', value: '11' },
    { target: 'Radiodermatitis', source: 'Radiation Injuries', value: '11' },
    { target: 'Aortic Rupture', source: 'Rupture', value: '11' },
    { target: 'Rotator Cuff Injuries', source: 'Rupture', value: '11' },
    { target: 'Splenic Rupture', source: 'Rupture', value: '11' },
    { target: 'Stomach Rupture', source: 'Rupture', value: '11' },
    { target: 'Uterine Rupture', source: 'Rupture', value: '11' },
    { target: 'Splenosis', source: 'Splenic Rupture', value: '15' },
    { target: 'Uterine Perforation', source: 'Uterine Rupture', value: '15' },
    { target: 'Crush Syndrome', source: 'Shock, Traumatic', value: '11' },
    { target: 'Rotator Cuff Injuries', source: 'Shoulder Injuries', value: '11' },
    { target: 'Shoulder Dislocation', source: 'Shoulder Injuries', value: '11' },
    { target: 'Shoulder Fractures', source: 'Shoulder Injuries', value: '11' },
    { target: 'Shoulder Impingement Syndrome', source: 'Shoulder Injuries', value: '11' },
    { target: 'Bankart Lesions', source: 'Shoulder Fractures', value: '15' },
    { target: 'Degloving Injuries', source: 'Soft Tissue Injuries', value: '11' },
    { target: 'Central Cord Syndrome', source: 'Spinal Cord Injuries', value: '11' },
    { target: 'Spinal Cord Compression', source: 'Spinal Cord Injuries', value: '11' },
    { target: 'Cumulative Trauma Disorders', source: 'Sprains and Strains', value: '11' },
    { target: 'Carpal Tunnel Syndrome', source: 'Cumulative Trauma Disorders', value: '15' },
    { target: 'Iliotibial Band Syndrome', source: 'Cumulative Trauma Disorders', value: '15' },
    { target: 'Ulnar Nerve Compression Syndromes', source: 'Cumulative Trauma Disorders', value: '15' },
    { target: 'Cubital Tunnel Syndrome', source: 'Ulnar Nerve Compression Syndromes', value: '19' },
    { target: 'Rotator Cuff Injuries', source: 'Tendon Injuries', value: '11' },
    { target: 'Tendinopathy', source: 'Tendon Injuries', value: '11' },
    { target: 'Elbow Tendinopathy', source: 'Tendinopathy', value: '15' },
    { target: 'Enthesopathy', source: 'Tendinopathy', value: '15' },
    { target: 'Tennis Elbow', source: 'Elbow Tendinopathy', value: '19' },
    { target: 'Flail Chest', source: 'Thoracic Injuries', value: '11' },
    { target: 'Heart Injuries', source: 'Thoracic Injuries', value: '11' },
    { target: 'Lung Injury', source: 'Thoracic Injuries', value: '11' },
    { target: 'Rib Fractures', source: 'Thoracic Injuries', value: '11' },
    { target: 'Myocardial Contusions', source: 'Heart Injuries', value: '15' },
    { target: 'Commotio Cordis', source: 'Myocardial Contusions', value: '19' },
    { target: 'Tooth Avulsion', source: 'Tooth Injuries', value: '11' },
    { target: 'Tooth Fractures', source: 'Tooth Injuries', value: '11' },
    { target: 'Cracked Tooth Syndrome', source: 'Tooth Fractures', value: '15' },
    { target: 'Cerebrovascular Trauma', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Craniocerebral Trauma', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Peripheral Nerve Injuries', source: 'Trauma, Nervous System', value: '11' },
    { target: 'Carotid Artery Injuries', source: 'Cerebrovascular Trauma', value: '15' },
    { target: 'Vertebral Artery Dissection', source: 'Cerebrovascular Trauma', value: '15' },
    { target: 'Carotid Artery, Internal, Dissection', source: 'Carotid Artery Injuries', value: '19' },
    { target: 'Carotid-Cavernous Sinus Fistula', source: 'Carotid Artery Injuries', value: '19' },
    { target: 'Brain Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Cerebrospinal Fluid Leak', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Coma, Post-Head Injury', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Cranial Nerve Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Facial Injuries', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Head Injuries, Closed', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Head Injuries, Penetrating', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Intracranial Hemorrhage, Traumatic', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Skull Fractures', source: 'Craniocerebral Trauma', value: '15' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Diffuse', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injuries, Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Injury, Chronic', source: 'Brain Injuries', value: '19' },
    { target: 'Epilepsy, Post-Traumatic', source: 'Brain Injuries', value: '19' },
    { target: 'Pneumocephalus', source: 'Brain Injuries', value: '19' },
    { target: 'Shaken Baby Syndrome', source: 'Brain Injuries', value: '19' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Diffuse Axonal Injury', source: 'Brain Injuries, Diffuse', value: '23' },
    { target: 'Brain Concussion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Brain Contusion', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injuries, Traumatic', value: '23' },
    { target: 'Chronic Traumatic Encephalopathy', source: 'Brain Injury, Chronic', value: '23' },
    { target: 'Cerebrospinal Fluid Otorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Cerebrospinal Fluid Rhinorrhea', source: 'Cerebrospinal Fluid Leak', value: '19' },
    { target: 'Abducens Nerve Injury', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Accessory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Facial Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Glossopharyngeal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Hypoglossal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Oculomotor Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Olfactory Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Optic Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Trigeminal Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Trochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Vagus Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Vestibulocochlear Nerve Injuries', source: 'Cranial Nerve Injuries', value: '19' },
    { target: 'Lingual Nerve Injuries', source: 'Trigeminal Nerve Injuries', value: '23' },
    { target: 'Laryngeal Nerve Injuries', source: 'Vagus Nerve Injuries', value: '23' },
    { target: 'Recurrent Laryngeal Nerve Injuries', source: 'Laryngeal Nerve Injuries', value: '27' },
    { target: 'Eye Injuries', source: 'Facial Injuries', value: '19' },
    { target: 'Maxillofacial Injuries', source: 'Facial Injuries', value: '19' },
    { target: 'Corneal Injuries', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Burns', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Foreign Bodies', source: 'Eye Injuries', value: '23' },
    { target: 'Eye Injuries, Penetrating', source: 'Eye Injuries', value: '23' },
    { target: 'Corneal Perforation', source: 'Corneal Injuries', value: '27' },
    { target: 'Jaw Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Mandibular Injuries', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Orbital Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Zygomatic Fractures', source: 'Maxillofacial Injuries', value: '23' },
    { target: 'Mandibular Fractures', source: 'Jaw Fractures', value: '27' },
    { target: 'Maxillary Fractures', source: 'Jaw Fractures', value: '27' },
    { target: 'Brain Concussion', source: 'Head Injuries, Closed', value: '19' },
    { target: 'Contrecoup Injury', source: 'Brain Concussion', value: '23' },
    { target: 'Post-Concussion Syndrome', source: 'Brain Concussion', value: '23' },
    { target: 'Brain Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Hematoma, Epidural, Cranial', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Hematoma, Subdural', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Subarachnoid Hemorrhage, Traumatic', source: 'Intracranial Hemorrhage, Traumatic', value: '19' },
    { target: 'Brain Stem Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Cerebral Hemorrhage, Traumatic', source: 'Brain Hemorrhage, Traumatic', value: '23' },
    { target: 'Hematoma, Subdural, Acute', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Chronic', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Hematoma, Subdural, Intracranial', source: 'Hematoma, Subdural', value: '23' },
    { target: 'Skull Fracture, Basilar', source: 'Skull Fractures', value: '19' },
    { target: 'Skull Fracture, Depressed', source: 'Skull Fractures', value: '19' },
    { target: 'Contusions', source: 'Wounds, Nonpenetrating', value: '11' },
    { target: 'Head Injuries, Closed', source: 'Wounds, Nonpenetrating', value: '11' },
    { target: 'Brain Contusion', source: 'Contusions', value: '15' },
    { target: 'Myocardial Contusions', source: 'Contusions', value: '15' },
    { target: 'Commotio Cordis', source: 'Myocardial Contusions', value: '19' },
    { target: 'Brain Concussion', source: 'Head Injuries, Closed', value: '15' },
    { target: 'Contrecoup Injury', source: 'Brain Concussion', value: '19' },
    { target: 'Post-Concussion Syndrome', source: 'Brain Concussion', value: '19' },
    { target: 'Corpse Dismemberment', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Decapitation', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Eye Injuries, Penetrating', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Head Injuries, Penetrating', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Wounds, Gunshot', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Wounds, Stab', source: 'Wounds, Penetrating', value: '11' },
    { target: 'Needlestick Injuries', source: 'Wounds, Stab', value: '15' },
    { target: 'Agoraphobia', source: 'Anxiety Disorders', value: '11' },
    { target: 'Anxiety, Separation', source: 'Anxiety Disorders', value: '11' },
    { target: 'Neurocirculatory Asthenia', source: 'Anxiety Disorders', value: '11' },
    { target: 'Neurotic Disorders', source: 'Anxiety Disorders', value: '11' },
    { target: 'Obsessive-Compulsive Disorder', source: 'Anxiety Disorders', value: '11' },
    { target: 'Panic Disorder', source: 'Anxiety Disorders', value: '11' },
    { target: 'Phobic Disorders', source: 'Anxiety Disorders', value: '11' },
    { target: 'Hoarding Disorder', source: 'Obsessive-Compulsive Disorder', value: '15' },
    { target: 'Phobia, Social', source: 'Phobic Disorders', value: '15' },
    { target: 'Bipolar Disorder', source: 'Bipolar and Related Disorders', value: '11' },
    { target: 'Firesetting Behavior', source: 'Disruptive, Impulse Control, and Conduct Disorders', value: '11' },
    { target: 'Gambling', source: 'Disruptive, Impulse Control, and Conduct Disorders', value: '11' },
    { target: 'Trichotillomania', source: 'Disruptive, Impulse Control, and Conduct Disorders', value: '11' },
    { target: 'Multiple Personality Disorder', source: 'Dissociative Disorders', value: '11' },
    { target: 'Encopresis', source: 'Elimination Disorders', value: '11' },
    { target: 'Enuresis', source: 'Elimination Disorders', value: '11' },
    { target: 'Diurnal Enuresis', source: 'Enuresis', value: '15' },
    { target: 'Nocturnal Enuresis', source: 'Enuresis', value: '15' },
    { target: 'Anorexia Nervosa', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Binge-Eating Disorder', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Bulimia Nervosa', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Feeding and Eating Disorders of Childhood', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Female Athlete Triad Syndrome', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Pica', source: 'Feeding and Eating Disorders', value: '11' },
    { target: 'Depressive Disorder', source: 'Mood Disorders', value: '11' },
    { target: 'Cyclothymic Disorder', source: 'Mood Disorders', value: '11' },
    { target: 'Depression, Postpartum', source: 'Depressive Disorder', value: '15' },
    { target: 'Depressive Disorder, Major', source: 'Depressive Disorder', value: '15' },
    { target: 'Depressive Disorder, Treatment-Resistant', source: 'Depressive Disorder', value: '15' },
    { target: 'Dysthymic Disorder', source: 'Depressive Disorder', value: '15' },
    { target: 'Premenstrual Dysphoric Disorder', source: 'Depressive Disorder', value: '15' },
    { target: 'Seasonal Affective Disorder', source: 'Depressive Disorder', value: '15' },
    { target: 'Amnesia', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Cognition Disorders', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Consciousness Disorders', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Delirium', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Dementia', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Dyslexia, Acquired', source: 'Neurocognitive Disorders', value: '11' },
    { target: 'Alcohol Amnestic Disorder', source: 'Amnesia', value: '15' },
    { target: 'Amnesia, Anterograde', source: 'Amnesia', value: '15' },
    { target: 'Amnesia, Retrograde', source: 'Amnesia', value: '15' },
    { target: 'Amnesia, Transient Global', source: 'Amnesia', value: '15' },
    { target: 'Korsakoff Syndrome', source: 'Alcohol Amnestic Disorder', value: '19' },
    { target: 'Auditory Perceptual Disorders', source: 'Cognition Disorders', value: '15' },
    { target: 'Huntington Disease', source: 'Cognition Disorders', value: '15' },
    { target: 'Cognitive Dysfunction', source: 'Cognition Disorders', value: '15' },
    { target: 'Emergence Delirium', source: 'Delirium', value: '15' },
    { target: 'AIDS Dementia Complex', source: 'Dementia', value: '15' },
    { target: 'Alzheimer Disease', source: 'Dementia', value: '15' },
    { target: 'Aphasia, Primary Progressive', source: 'Dementia', value: '15' },
    { target: 'Creutzfeldt-Jakob Syndrome', source: 'Dementia', value: '15' },
    { target: 'Dementia, Vascular', source: 'Dementia', value: '15' },
    { target: 'Diffuse Neurofibrillary Tangles with Calcification', source: 'Dementia', value: '15' },
    { target: 'Frontotemporal Lobar Degeneration', source: 'Dementia', value: '15' },
    { target: 'Huntington Disease', source: 'Dementia', value: '15' },
    { target: 'Kluver-Bucy Syndrome', source: 'Dementia', value: '15' },
    { target: 'Lewy Body Disease', source: 'Dementia', value: '15' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Aphasia, Primary Progressive', value: '19' },
    { target: 'Dementia, Multi-Infarct', source: 'Dementia, Vascular', value: '19' },
    { target: 'Frontotemporal Dementia', source: 'Frontotemporal Lobar Degeneration', value: '19' },
    { target: 'Primary Progressive Nonfluent Aphasia', source: 'Frontotemporal Lobar Degeneration', value: '19' },
    { target: 'Pick Disease of the Brain', source: 'Frontotemporal Dementia', value: '23' },
    { target: 'Alexia, Pure', source: 'Dyslexia, Acquired', value: '15' },
    { target: 'Anxiety, Separation', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Attention Deficit and Disruptive Behavior Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Child Behavior Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Child Development Disorders, Pervasive', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Communication Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Developmental Disabilities', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Intellectual Disability', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Learning Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Motor Skills Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Mutism', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Reactive Attachment Disorder', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Schizophrenia, Childhood', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Stereotypic Movement Disorder', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Tic Disorders', source: 'Neurodevelopmental Disorders', value: '11' },
    { target: 'Attention Deficit Disorder with Hyperactivity', source: 'Attention Deficit and Disruptive Behavior Disorders', value: '15' },
    { target: 'Conduct Disorder', source: 'Attention Deficit and Disruptive Behavior Disorders', value: '15' },
    { target: 'Asperger Syndrome', source: 'Child Development Disorders, Pervasive', value: '15' },
    { target: 'Autism Spectrum Disorder', source: 'Child Development Disorders, Pervasive', value: '15' },
    { target: 'Autistic Disorder', source: 'Child Development Disorders, Pervasive', value: '15' },
    { target: 'Childhood-Onset Fluency Disorder', source: 'Communication Disorders', value: '15' },
    { target: 'Social Communication Disorder', source: 'Communication Disorders', value: '15' },
    { target: 'Speech Sound Disorder', source: 'Communication Disorders', value: '15' },
    { target: 'Dyscalculia', source: 'Learning Disorders', value: '15' },
    { target: 'Dyslexia', source: 'Learning Disorders', value: '15' },
    { target: 'Specific Learning Disorder', source: 'Learning Disorders', value: '15' },
    { target: 'Dyslexia, Acquired', source: 'Dyslexia', value: '19' },
    { target: 'Tourette Syndrome', source: 'Tic Disorders', value: '15' },
    { target: 'Exhibitionism', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Fetishism (Psychiatric)', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Masochism', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Pedophilia', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Sadism', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Transvestism', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Voyeurism', source: 'Paraphilic Disorders', value: '11' },
    { target: 'Antisocial Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Borderline Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Compulsive Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Dependent Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Histrionic Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Paranoid Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Passive-Aggressive Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Schizoid Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Schizotypal Personality Disorder', source: 'Personality Disorders', value: '11' },
    { target: 'Hysteria', source: 'Histrionic Personality Disorder', value: '15' },
    { target: 'Affective Disorders, Psychotic', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Capgras Syndrome', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Delusional Parasitosis', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Morgellons Disease', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Paranoid Disorders', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Psychotic Disorders', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Schizophrenia', source: 'Schizophrenia Spectrum and Other Psychotic Disorders', value: '11' },
    { target: 'Psychoses, Substance-Induced', source: 'Psychotic Disorders', value: '15' },
    { target: 'Psychoses, Alcoholic', source: 'Psychoses, Substance-Induced', value: '19' },
    { target: 'Schizophrenia, Catatonic', source: 'Schizophrenia', value: '15' },
    { target: 'Schizophrenia, Disorganized', source: 'Schizophrenia', value: '15' },
    { target: 'Schizophrenia, Paranoid', source: 'Schizophrenia', value: '15' },
    { target: 'Shared Paranoid Disorder', source: 'Schizophrenia', value: '15' },
    { target: 'Dyspareunia', source: 'Sexual Dysunctions, Psychological', value: '11' },
    { target: 'Erectile Dysfunction', source: 'Sexual Dysfunctions, Psychological', value: '11' },
    { target: 'Gender Dysphoria', source: 'Sexual Dysfunctions, Psychological', value: '11' },
    { target: 'Premature Ejaculation', source: 'Sexual Dysfunctions, Psychological', value: '11' },
    { target: 'Sexual and Gender Disorders', source: 'Sexual Dysfunctions, Psychological', value: '11' },
    { target: 'Vaginismus', source: 'Sexual Dysfunctions, Psychological', value: '11' },
    { target: 'Dyssomnias', source: 'Sleep Wake Disorders', value: '11' },
    { target: 'Parasomnias', source: 'Sleep Wake Disorders', value: '11' },
    { target: 'Sleep Deprivation', source: 'Dyssomnias', value: '15' },
    { target: 'Sleep Disorders, Circadian Rhythm', source: 'Dyssomnias', value: '15' },
    { target: 'Sleep Disorders, Intrinsic', source: 'Dyssomnias', value: '15' },
    { target: 'Jet Lag Syndrome', source: 'Sleep Disorders, Circadian Rhythm', value: '19' },
    { target: 'Disorders of Excessive Somnolence', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Restless Legs Syndrome', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Sleep Initiation and Maintenance Disorders', source: 'Sleep Disorders, Intrinsic', value: '19' },
    { target: 'Hypersomnolence, Idiopathic', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Kleine-Levin Syndrome', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Narcolepsy', source: 'Disorders of Excessive Somnolence', value: '23' },
    { target: 'Cataplexy', source: 'Narcolepsy', value: '27' },
    { target: 'Nocturnal Paroxysmal Dystonia', source: 'Parasomnias', value: '15' },
    { target: 'REM Sleep Parasomnias', source: 'Parasomnias', value: '15' },
    { target: 'Restless Legs Syndrome', source: 'Parasomnias', value: '15' },
    { target: 'Sleep Arousal Disorders', source: 'Parasomnias', value: '15' },
    { target: 'Sleep Bruxism', source: 'Parasomnias', value: '15' },
    { target: 'Sleep-Wake Transition Disorders', source: 'Parasomnias', value: '15' },
    { target: 'REM Sleep Behavior Disorder', source: 'REM Sleep Parasomnias', value: '19' },
    { target: 'Sleep Paralysis', source: 'REM Sleep Parasomnias', value: '19' },
    { target: 'Night Terrors', source: 'Sleep Arousal Disorders', value: '19' },
    { target: 'Somnambulism', source: 'Sleep Arousal Disorders', value: '19' },
    { target: 'Body Dysmorphic Disorders', source: 'Somatoform Disorders', value: '11' },
    { target: 'Conversion Disorder', source: 'Somatoform Disorders', value: '11' },
    { target: 'Factitious Disorders', source: 'Somatoform Disorders', value: '11' },
    { target: 'Hypochondriasis', source: 'Somatoform Disorders', value: '11' },
    { target: 'Neurasthenia', source: 'Somatoform Disorders', value: '11' },
    { target: 'Munchausen Syndrome', source: 'Factitious Disorders', value: '15' },
    { target: 'Munchausen Syndrome by Proxy', source: 'Factitious Disorders', value: '15' },
    { target: 'Alcohol-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Amphetamine-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Cocaine-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Inhalant Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Marijuana Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Neonatal Abstinence Syndrome', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Opioid-Related Disorders', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Phencyclidine Abuse', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Psychoses, Substance-Induced', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Substance Abuse, Intravenous', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Substance Withdrawal Syndrome', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Tobacco Use Disorder', source: 'Substance-Related Disorders', value: '11' },
    { target: 'Alcohol Amnestic Disorder', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcoholic Intoxication', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Alcoholism', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Binge Drinking', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Psychoses, Alcoholic', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Wernicke Encephalopathy', source: 'Alcohol-Related Disorders', value: '15' },
    { target: 'Korsakoff Syndrome', source: 'Alcohol Amnestic Disorder', value: '19' },
    { target: 'Heroin Dependence', source: 'Opioid-Related Disorders', value: '15' },
    { target: 'Morphine Dependence', source: 'Opioid-Related Disorders', value: '15' },
    { target: 'Alcohol Withdrawal Delirium', source: 'Substance Withdrawal Syndrome', value: '15' },
    { target: 'Adjustment Disorders', source: 'Trauma and Stressor Related Disorders', value: '11' },
    { target: 'Stress Disorders, Traumatic', source: 'Trauma and Stressor Related Disorders', value: '11' },
    { target: 'Battered Child Syndrome', source: 'Stress Disorders, Traumatic', value: '15' },
    { target: 'Combat Disorders', source: 'Stress Disorders, Traumatic', value: '15' },
    { target: 'Psychological Trauma', source: 'Stress Disorders, Traumatic', value: '15' },
    { target: 'Stress Disorders, Post-Traumatic', source: 'Stress Disorders, Traumatic', value: '15' },
    { target: 'Stress Disorders, Traumatic, Acute', source: 'Stress Disorders, Traumatic', value: '15' },
    { target: 'Bacterial Infections', source: 'Bacterial Infections and Mycoses', value: '7' },
    { target: 'Central Nervous System Infections', source: 'Bacterial Infections and Mycoses', value: '7' },
    { target: 'Infection', source: 'Bacterial Infections and Mycoses', value: '7' },
    { target: 'Mycoses', source: 'Bacterial Infections and Mycoses', value: '7' },
    { target: 'Zoonoses', source: 'Bacterial Infections and Mycoses', value: '7' },
    { target: 'Arbovirus Infections', source: 'Virus Diseases', value: '7' },
    { target: 'Bronchiolitis, Viral', source: 'Virus Diseases', value: '7' },
    { target: 'Central Nervous System Viral Diseases', source: 'Virus Diseases', value: '7' },
    { target: 'Coinfection', source: 'Virus Diseases', value: '7' },
    { target: 'DNA Virus Infections', source: 'Virus Diseases', value: '7' },
    { target: 'Encephalitis, Viral', source: 'Virus Diseases', value: '7' },
    { target: 'Eye Infections, Viral', source: 'Virus Diseases', value: '7' },
    { target: 'Fatigue Syndrome, Chronic', source: 'Virus Diseases', value: '7' },
    { target: 'Hepatitis, Viral, Animal', source: 'Virus Diseases', value: '7' },
    { target: 'Hepatitis, Viral, Human', source: 'Virus Diseases', value: '7' },
    { target: 'Opportunistic Infections', source: 'Virus Diseases', value: '7' },
    { target: 'Pneumonia, Viral', source: 'Virus Diseases', value: '7' },
    { target: 'RNA Virus Infections', source: 'Virus Diseases', value: '7' },
    { target: 'Sexually Transmitted Diseases', source: 'Virus Diseases', value: '7' },
    { target: 'Skin Diseases, Viral', source: 'Virus Diseases', value: '7' },
    { target: 'Slow Virus Diseases', source: 'Virus Diseases', value: '7' },
    { target: 'Tumor Virus Infections', source: 'Virus Diseases', value: '7' },
    { target: 'Viremia', source: 'Virus Diseases', value: '7' },
    { target: 'Zoonoses', source: 'Virus Diseases', value: '7' },
    { target: 'Central Nervous System Parasitic Infections', source: 'Parasitic Diseases', value: '7' },
    { target: 'Coinfection', source: 'Parasitic Diseases', value: '7' },
    { target: 'Eye Infections, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Helminthiasis', source: 'Parasitic Diseases', value: '7' },
    { target: 'Intestinal Diseases, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Liver Diseases, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Lung Diseases, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Mesomycetozoea Infections', source: 'Parasitic Diseases', value: '7' },
    { target: 'Opportunistic Infections', source: 'Parasitic Diseases', value: '7' },
    { target: 'Parasitemia', source: 'Parasitic Diseases', value: '7' },
    { target: 'Parasitic Diseases, Animal', source: 'Parasitic Diseases', value: '7' },
    { target: 'Pregnancy Complications, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Protozoan Infections', source: 'Parasitic Diseases', value: '7' },
    { target: 'Skin Diseases, Parasitic', source: 'Parasitic Diseases', value: '7' },
    { target: 'Zoonoses', source: 'Parasitic Diseases', value: '7' },
    { target: 'Cysts', source: 'Neoplasms', value: '7' },
    { target: 'Hamartoma', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms by Histologic Type', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms by Site', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Experimental', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Hormone-Dependent', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Multiple Primary', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Post-Traumatic', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Radiation-Induced', source: 'Neoplasms', value: '7' },
    { target: 'Neoplasms, Second Primary', source: 'Neoplasms', value: '7' },
    { target: 'Neoplastic Processes', source: 'Neoplasms', value: '7' },
    { target: 'Neoplastic Syndromes, Hereditary', source: 'Neoplasms', value: '7' },
    { target: 'Paraneoplastic Syndromes', source: 'Neoplasms', value: '7' },
    { target: 'Precancerous Conditions', source: 'Neoplasms', value: '7' },
    { target: 'Pregnancy Complications, Neoplastic', source: 'Neoplasms', value: '7' },
    { target: 'Bone Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Cartilage Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Fasciitis', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Foot Deformities', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Foot Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Hand Deformities', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Jaw Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Joint Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Muscular Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Musculoskeletal Abnormalities', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Rheumatic Diseases', source: 'Musculoskeletal Diseases', value: '7' },
    { target: 'Biliary Tract Diseases', source: 'Digestive System Diseases', value: '7' },
    { target: 'Digestive System Abnormalities', source: 'Digestive System Diseases', value: '7' },
    { target: 'Digestive System Fistula', source: 'Digestive System Diseases', value: '7' },
    { target: 'Digestive System Neoplasms', source: 'Digestive System Diseases', value: '7' },
    { target: 'Gastrointestinal Diseases', source: 'Digestive System Diseases', value: '7' },
    { target: 'Liver Diseases', source: 'Digestive System Diseases', value: '7' },
    { target: 'Pancreatic Diseases', source: 'Digestive System Diseases', value: '7' },
    { target: 'Peritoneal Diseases', source: 'Digestive System Diseases', value: '7' },
    { target: 'Ankyloglossia', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Jaw Diseases', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Mouth Diseases', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Pharyngeal Diseases', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Stomatognathic System Abnormalities', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Temporomandibular Joint Disorders', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Tooth Diseases', source: 'Stomatognathic Diseases', value: '7' },
    { target: 'Bronchial Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Ciliary Motility Disorders', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Granuloma, Respiratory Tract', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Laryngeal Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Lung Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Nose Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Pleural Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiration Disorders', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiratory Hypersensitivity', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiratory System Abnormalities', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiratory Tract Fistula', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiratory Tract Infections', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Respiratory Tract Neoplasms', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Thoracic Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Tracheal Diseases', source: 'Respiratory Tract Diseases', value: '7' },
    { target: 'Ciliary Motility Disorders', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Ear Diseases', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Laryngeal Diseases', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Nose Diseases', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Otorhinolaryngologic Neoplasms', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Pharyngeal Diseases', source: 'Otorhinolaryngologic Diseases', value: '7' },
    { target: 'Autoimmune Diseases of the Nervous System', source: 'Nervous System Diseases', value: '7' },
    { target: 'Autonomic Nervous System Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Central Nervous System Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Chronobiology Disorders', source: 'Nervous System Diseases', value: '7' },
    { target: 'Cranial Nerve Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Demyelinating Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Nervous System Malformations', source: 'Nervous System Diseases', value: '7' },
    { target: 'Nervous System Neoplasms', source: 'Nervous System Diseases', value: '7' },
    { target: 'Neurocutaneous Syndromes', source: 'Nervous System Diseases', value: '7' },
    { target: 'Neurodegenerative Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Neurologic Manifestations', source: 'Nervous System Diseases', value: '7' },
    { target: 'Neuromuscular Diseases', source: 'Nervous System Diseases', value: '7' },
    { target: 'Neurotoxicity Syndromes', source: 'Nervous System Diseases', value: '7' },
    { target: 'Restless Legs Syndrome', source: 'Nervous System Diseases', value: '7' },
    { target: 'Sleep Wake Disorders', source: 'Nervous System Diseases', value: '7' },
    { target: 'Trauma, Nervous System', source: 'Nervous System Diseases', value: '7' },
    { target: 'Asthenopia', source: 'Eye Diseases', value: '7' },
    { target: 'Cogan Syndrome', source: 'Eye Diseases', value: '7' },
    { target: 'Conjunctival Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Corneal Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Abnormalities', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Diseases, Hereditary', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Hemorrhage', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Infections', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Injuries', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Manifestations', source: 'Eye Diseases', value: '7' },
    { target: 'Eye Neoplasms', source: 'Eye Diseases', value: '7' },
    { target: 'Eyelid Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Lacrimal Apparatus Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Lens Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Ocular Hypertension', source: 'Eye Diseases', value: '7' },
    { target: 'Ocular Hypotension', source: 'Eye Diseases', value: '7' },
    { target: 'Ocular Motility Disorders', source: 'Eye Diseases', value: '7' },
    { target: 'Optic Nerve Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Orbital Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Pupil Disorders', source: 'Eye Diseases', value: '7' },
    { target: 'Refractive Errors', source: 'Eye Diseases', value: '7' },
    { target: 'Retinal Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Scleral Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Uveal Diseases', source: 'Eye Diseases', value: '7' },
    { target: 'Vision Disorders', source: 'Eye Diseases', value: '7' },
    { target: 'Vitreous Detachment', source: 'Eye Diseases', value: '7' },
    { target: 'Genital Diseases, Male', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Pelvic Floor Disorders', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Tuberculosis, Urogenital', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Urogenital Abnormalities', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Urogenital Neoplasms', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Urologic Diseases', source: 'Male Urogenital Diseases', value: '7' },
    { target: 'Female Urogenital Diseases', source: 'Female Urogenital Diseases and Pregnancy Complications', value: '7' },
    { target: 'Pregnancy Complications', source: 'Female Urogenital Diseases and Pregnancy Complications', value: '7' },
    { target: 'Cardiovascular Abnormalities', source: 'Cardiovascular Diseases', value: '7' },
    { target: 'Cardiovascular Infections', source: 'Cardiovascular Diseases', value: '7' },
    { target: 'Heart Diseases', source: 'Cardiovascular Diseases', value: '7' },
    { target: 'Pregnancy Complications, Cardiovascular', source: 'Cardiovascular Diseases', value: '7' },
    { target: 'Vascular Diseases', source: 'Cardiovascular Diseases', value: '7' },
    { target: 'Hematologic Diseases', source: 'Hemic and Lymphatic Diseases', value: '7' },
    { target: 'Lymphatic Diseases', source: 'Hemic and Lymphatic Diseases', value: '7' },
    { target: 'Congenital Abnormalities', source: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', value: '7' },
    { target: 'Fetal Diseases', source: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', value: '7' },
    { target: 'Genetic Diseases, Inborn', source: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', value: '7' },
    { target: 'Infant, Newborn, Diseases', source: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', value: '7' },
    { target: 'Connective Tissue Diseases', source: 'Skin and Connective Tissue Diseases', value: '7' },
    { target: 'Skin Diseases', source: 'Skin and Connective Tissue Diseases', value: '7' },
    { target: 'Metabolic Diseases', source: 'Nutritional and Metabolic Diseases', value: '7' },
    { target: 'Nutrition Disorders', source: 'Nutritional and Metabolic Diseases', value: '7' },
    { target: 'Adrenal Gland Diseases', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Bone Diseases, Endocrine', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Diabetes Mellitus', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Dwarfism', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Endocrine Gland Neoplasms', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Female Athlete Triad Syndrome', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Gonadal Disorders', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Parathyroid Diseases', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Pituitary Diseases', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Polyendocrinopathies, Autoimmune', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Thyroid Diseases', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Tuberculosis, Endocrine', source: 'Endocrine System Diseases', value: '7' },
    { target: 'Autoimmune Diseases', source: 'Immune System Diseases', value: '7' },
    { target: 'Erythroblastosis, Fetal', source: 'Immune System Diseases', value: '7' },
    { target: 'Glomerulonephritis, Membranoproliferative', source: 'Immune System Diseases', value: '7' },
    { target: 'Graft vs Host Disease', source: 'Immune System Diseases', value: '7' },
    { target: 'Hypersensitivity', source: 'Immune System Diseases', value: '7' },
    { target: 'Immune Reconstitution Inflammatory Syndrome', source: 'Immune System Diseases', value: '7' },
    { target: 'Immunologic Deficiency Syndromes', source: 'Immune System Diseases', value: '7' },
    { target: 'Immunoproliferative Disorders', source: 'Immune System Diseases', value: '7' },
    { target: 'Purpura, Thrombocytopenic', source: 'Immune System Diseases', value: '7' },
    { target: 'Transfusion Reaction', source: 'Immune System Diseases', value: '7' },
    { target: 'Environmental Illness', source: 'Disorders of Environmental Origin', value: '7' },
    { target: 'Preconception Injuries', source: 'Disorders of Environmental Origin', value: '7' },
    { target: 'Abortion, Veterinary', source: 'Animal Diseases', value: '7' },
    { target: 'Actinobacillosis', source: 'Animal Diseases', value: '7' },
    { target: 'Aleutian Mink Disease', source: 'Animal Diseases', value: '7' },
    { target: 'Anal Gland Neoplasms', source: 'Animal Diseases', value: '7' },
    { target: 'Anaplasmosis', source: 'Animal Diseases', value: '7' },
    { target: 'Bird Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Borna Disease', source: 'Animal Diseases', value: '7' },
    { target: 'Cat Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Cattle Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Digital Dermatitis', source: 'Animal Diseases', value: '7' },
    { target: 'Disease Models, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Dog Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Enterotoxemia', source: 'Animal Diseases', value: '7' },
    { target: 'Erysipelothrix Infections', source: 'Animal Diseases', value: '7' },
    { target: 'Fish Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Foot-and-Mouth Disease', source: 'Animal Diseases', value: '7' },
    { target: 'Foot Rot', source: 'Animal Diseases', value: '7' },
    { target: 'Goat Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Heartwater Disease', source: 'Animal Diseases', value: '7' },
    { target: 'Hepatitis, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Horse Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Keratoconjunctivitis, Infectious', source: 'Animal Diseases', value: '7' },
    { target: 'Lameness, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Mammary Neoplasms, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Mink Viral Enteritis', source: 'Animal Diseases', value: '7' },
    { target: 'Muscular Dystrophy, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Myxomatosis, Infectious', source: 'Animal Diseases', value: '7' },
    { target: 'Parasitic Diseases, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Paratuberculosis', source: 'Animal Diseases', value: '7' },
    { target: 'Parturient Paresis', source: 'Animal Diseases', value: '7' },
    { target: 'Peste-des-Petits-Ruminants', source: 'Animal Diseases', value: '7' },
    { target: 'Pleuropneumonia, Contagious', source: 'Animal Diseases', value: '7' },
    { target: 'Primate Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Pseudorabies', source: 'Animal Diseases', value: '7' },
    { target: 'Pythiosis', source: 'Animal Diseases', value: '7' },
    { target: 'Rinderpest', source: 'Animal Diseases', value: '7' },
    { target: 'Rodent Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Salmonella Infections, Animal', source: 'Animal Diseases', value: '7' },
    { target: 'Sheep Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Steatitis', source: 'Animal Diseases', value: '7' },
    { target: 'Swine Diseases', source: 'Animal Diseases', value: '7' },
    { target: 'Venereal Tumors, Veterinary', source: 'Animal Diseases', value: '7' },
    { target: 'Vesicular Stomatitis', source: 'Animal Diseases', value: '7' },
    { target: 'Wasting Disease, Chronic', source: 'Animal Diseases', value: '7' },
    { target: 'Zoonoses', source: 'Animal Diseases', value: '7' },
    { target: 'Morphological and Microscopic Findings', source: 'Pathological Conditions, Signs and Symptoms', value: '7' },
    { target: 'Pathological Conditions, Anatomical', source: 'Pathological Conditions, Signs and Symptoms', value: '7' },
    { target: 'Pathologic Processes', source: 'Pathological Conditions, Signs and Symptoms', value: '7' },
    { target: 'Signs and Symptoms', source: 'Pathological Conditions, Signs and Symptoms', value: '7' },
    { target: 'Agricultural Workers\' Diseases', source: 'Occupational Diseases', value: '7' },
    { target: 'Asthma, Occupational', source: 'Occupational Diseases', value: '7' },
    { target: 'Bird Fancier\'s Lung', source: 'Occupational Diseases', value: '7' },
    { target: 'Burnout, Professional', source: 'Occupational Diseases', value: '7' },
    { target: 'Dermatitis, Occupational', source: 'Occupational Diseases', value: '7' },
    { target: 'Hand-Arm Vibration Syndrome', source: 'Occupational Diseases', value: '7' },
    { target: 'High Pressure Neurological Syndrome', source: 'Occupational Diseases', value: '7' },
    { target: 'Inert Gas Narcosis', source: 'Occupational Diseases', value: '7' },
    { target: 'Laboratory Infection', source: 'Occupational Diseases', value: '7' },
    { target: 'Persian Gulf Syndrome', source: 'Occupational Diseases', value: '7' },
    { target: 'Pneumoconiosis', source: 'Occupational Diseases', value: '7' },
    { target: 'Sleep Disorders, Circadian Rhythm', source: 'Occupational Diseases', value: '7' },
    { target: 'Drug-Related Side Effects and Adverse Reactions', source: 'Chemically-Induced Disorders', value: '7' },
    { target: 'Poisoning', source: 'Chemically-Induced Disorders', value: '7' },
    { target: 'Substance-Related Disorders', source: 'Chemically-Induced Disorders', value: '7' },
    { target: 'Abdominal Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Amputation, Traumatic', source: 'Wounds and Injuries', value: '7' },
    { target: 'Arm Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Asphyxia', source: 'Wounds and Injuries', value: '7' },
    { target: 'Athletic Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Back Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Barotrauma', source: 'Wounds and Injuries', value: '7' },
    { target: 'Battered Child Syndrome', source: 'Wounds and Injuries', value: '7' },
    { target: 'Birth Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Bites and Stings', source: 'Wounds and Injuries', value: '7' },
    { target: 'Burns', source: 'Wounds and Injuries', value: '7' },
    { target: 'Cold Injury', source: 'Wounds and Injuries', value: '7' },
    { target: 'Contrecoup Injury', source: 'Wounds and Injuries', value: '7' },
    { target: 'Crush Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Joint Dislocations', source: 'Wounds and Injuries', value: '7' },
    { target: 'Drowning', source: 'Wounds and Injuries', value: '7' },
    { target: 'Electric Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Esophageal Perforation', source: 'Wounds and Injuries', value: '7' },
    { target: 'Extravasation of Diagnostic and Therapeutic Materials', source: 'Wounds and Injuries', value: '7' },
    { target: 'Foreign Bodies', source: 'Wounds and Injuries', value: '7' },
    { target: 'Fractures, Bone', source: 'Wounds and Injuries', value: '7' },
    { target: 'Fractures, Cartilage', source: 'Wounds and Injuries', value: '7' },
    { target: 'Frostbite', source: 'Wounds and Injuries', value: '7' },
    { target: 'Hand Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Heat Stress Disorders', source: 'Wounds and Injuries', value: '7' },
    { target: 'Hip Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Lacerations', source: 'Wounds and Injuries', value: '7' },
    { target: 'Leg Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Microtrauma, Physical', source: 'Wounds and Injuries', value: '7' },
    { target: 'Multiple Trauma', source: 'Wounds and Injuries', value: '7' },
    { target: 'Nasal Septal Perforation', source: 'Wounds and Injuries', value: '7' },
    { target: 'Neck Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Occupational Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Radiation Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Retropneumoperitoneum', source: 'Wounds and Injuries', value: '7' },
    { target: 'Rupture', source: 'Wounds and Injuries', value: '7' },
    { target: 'Self Mutilation', source: 'Wounds and Injuries', value: '7' },
    { target: 'Shock, Traumatic', source: 'Wounds and Injuries', value: '7' },
    { target: 'Shoulder Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Soft Tissue Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Spinal Cord Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Sprains and Strains', source: 'Wounds and Injuries', value: '7' },
    { target: 'Surgical Wound', source: 'Wounds and Injuries', value: '7' },
    { target: 'Tendon Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Thoracic Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Tooth Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Trauma, Nervous System', source: 'Wounds and Injuries', value: '7' },
    { target: 'Tympanic Membrane Perforation', source: 'Wounds and Injuries', value: '7' },
    { target: 'Vascular System Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'War-Related Injuries', source: 'Wounds and Injuries', value: '7' },
    { target: 'Wounds, Nonpenetrating', source: 'Wounds and Injuries', value: '7' },
    { target: 'Wounds, Penetrating', source: 'Wounds and Injuries', value: '7' },
    { target: 'Anxiety Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Bipolar and Related Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Disruptive, Impulse Control, and Conduct Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Dissociative Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Elimination Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Feeding and Eating Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Mood Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Motor Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Neurocognitive Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Neurodevelopmental Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Neurotic Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Paraphilic Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Personality Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Schizophrenia Spectrum and Other Psychotic Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Sexual Dysfunctions, Psychological', source: 'Mental Disorders', value: '7' },
    { target: 'Sleep Wake Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Somatoform Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Substance-Related Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Trauma and Stressor Related Disorders', source: 'Mental Disorders', value: '7' },
    { target: 'Bacterial Infections and Mycoses', source: 'All diseases', value: '3' },
    { target: 'Virus Diseases', source: 'All diseases', value: '3' },
    { target: 'Parasitic Diseases', source: 'All diseases', value: '3' },
    { target: 'Neoplasms', source: 'All diseases', value: '3' },
    { target: 'Musculoskeletal Diseases', source: 'All diseases', value: '3' },
    { target: 'Digestive System Diseases', source: 'All diseases', value: '3' },
    { target: 'Stomatognathic Diseases', source: 'All diseases', value: '3' },
    { target: 'Respiratory Tract Diseases', source: 'All diseases', value: '3' },
    { target: 'Otorhinolaryngologic Diseases', source: 'All diseases', value: '3' },
    { target: 'Nervous System Diseases', source: 'All diseases', value: '3' },
    { target: 'Eye Diseases', source: 'All diseases', value: '3' },
    { target: 'Male Urogenital Diseases', source: 'All diseases', value: '3' },
    { target: 'Female Urogenital Diseases and Pregnancy Complications', source: 'All diseases', value: '3' },
    { target: 'Cardiovascular Diseases', source: 'All diseases', value: '3' },
    { target: 'Hemic and Lymphatic Diseases', source: 'All diseases', value: '3' },
    { target: 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', source: 'All diseases', value: '3' },
    { target: 'Skin and Connective Tissue Diseases', source: 'All diseases', value: '3' },
    { target: 'Nutritional and Metabolic Diseases', source: 'All diseases', value: '3' },
    { target: 'Endocrine System Diseases', source: 'All diseases', value: '3' },
    { target: 'Immune System Diseases', source: 'All diseases', value: '3' },
    { target: 'Disorders of Environmental Origin', source: 'All diseases', value: '3' },
    { target: 'Animal Diseases', source: 'All diseases', value: '3' },
    { target: 'Pathological Conditions, Signs and Symptoms', source: 'All diseases', value: '3' },
    { target: 'Occupational Diseases', source: 'All diseases', value: '3' },
    { target: 'Chemically-Induced Disorders', source: 'All diseases', value: '3' },
    { target: 'Wounds and Injuries', source: 'All diseases', value: '3' },
    { target: 'Mental Disorders', source: 'All diseases', value: '3' },
    { target: 'All diseases', source: 'All diseases', value: '3' }
  ]
};
