import {
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { Chart } from 'react-google-charts';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import NeedMore from 'src/components/overall/NeedMore';
import TopHospitalsGeneralTable from '../components/charts/TopHospitalsGeneralTable';

const ViewMap = () => {
  const dkw = useSelector((state) => state.dkw);
  const topHospList = useSelector((state) => state.topHospitals);
  const [displayData, setDisplayData] = useState([['city', []]]);
  const selectIndicator = useRef(null);
  const selectTopIndicator = useRef(null);

  // selection style
  const useStylesSelection = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));
  const classesSelection = useStylesSelection();

  // selection function for both selection bars.
  const generateDisplayData = (indicatorValue, selectsCount) => {
    // text display
    const tempData = [['City', 'Score', { role: 'tooltip', type: 'string' }]];
    // select Top/all Hospitals
    const mockHospitals = topHospList.sort((b, a) => (a.overallSCoreDisplay * 1 - b.overallSCoreDisplay * 1)).slice(0, selectsCount);
    // text display value input
    mockHospitals.forEach((hospital) => {
      tempData.push([hospital.geoCity, hospital[indicatorValue], hospital.name.concat(': ', hospital[indicatorValue])]);
    });
    setDisplayData([...tempData]);
  };

  useEffect(() => {
    // console.log('selectIndicator.current', selectIndicator.current.children[1].value);
    generateDisplayData(selectIndicator.current.children[1].value, selectTopIndicator.current.children[1].value);
  }, []);

  // dimension data selection function
  const handleChangeDimension = (event) => {
    generateDisplayData(event.target.value, selectTopIndicator.current.children[1].value);
  };
  // top data selection function
  const handleChangeTop = (event) => {
    generateDisplayData(selectIndicator.current.children[1].value, event.target.value);
  };

  return (
    <>
      <Helmet>
        <title>View Map | LINKMedicine</title>
      </Helmet>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '30px',
            marginTop: '70px',
            marginBottom: '40px'
          }}
        >
          Map America’s Best Hospitals for &nbsp;
          {dkw}
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`America’s top hospitals are distributed across the nation. View below for the 6 nationally top-rated 
          hospitals and 15 regional leaders identified for their expertise in this America’s Best Hospitals for 
          ${dkw} study.`}
          <br />
          <br />
          {`Zoom in to access the best expertise in your region. You may construct your own analysis to obtain 
          insights that may help you make better healthcare decisions.`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          color="primary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          View the top rated institutions
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="a"
          href="https://react-material-kit.devias.io"
          variant="outlined"
          sx={{ marginLeft: '20px' }}
        >
          Download full report
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '20px',
            marginBottom: '20px'
          }}
        >
          View Top Hospitals in Your Region
        </Typography>
        {/* selection part */}
        <Box
          textAlign="left"
          style={{
            marginBottom: '10px',
            marginLeft: '10px',
          }}
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FormControl variant="outlined" className={classesSelection.formControl}>
            <Select
              ref={selectIndicator}
              defaultValue="overallSCoreDisplay"
              onChange={handleChangeDimension}
              displayEmpty
              className={classesSelection.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="overallSCoreDisplay">Sort by Attribute</MenuItem>
              <MenuItem value="projectCount">Frontier Research</MenuItem>
              <MenuItem value="interverntionCount">Therapeutic Investigation</MenuItem>
              <MenuItem value="physicianCount">Physician Avtivites</MenuItem>
              <MenuItem value="funding"> Federal Support</MenuItem>
              <MenuItem value="piCount">Associated Experts</MenuItem>
              <MenuItem value="nctCount">Clinical Excellence</MenuItem>
            </Select>
          </FormControl>
          &nbsp;&nbsp;&nbsp;
          <FormControl variant="outlined" className={classesSelection.formControl}>
            <Select
              ref={selectTopIndicator}
              defaultValue="6"
              onChange={handleChangeTop}
              displayEmpty
              className={classesSelection.selectEmpty}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem value="6">Top Hospitals</MenuItem>
              <MenuItem value="100">All Major Hospitals</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Chart
          textAlign="center"
          width="100%"
          height="600px"
          chartType="GeoChart"
          data={displayData}
          options={{
            region: 'US',
            resolution: 'provinces',
            displayMode: 'markers',
            colorAxis: { colors: ['#f2f2f2', '#1EB0BC', '#f28dcd'] },
            backgroundColor: '#F4F6F8',
            datalessRegionColor: '#ebecf0',
          }}
          mapsApiKey="AIzaSyChLxtQM2oKlrIfboGL3bhw0sQOKTmPsvQ"
          rootProps={{ 'data-testid': '2' }}
        />
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <TopHospitalsGeneralTable topHospList={topHospList} />
          </Box>
        </Container>
        <NeedMore />
      </Box>
    </>
  );
};

export default ViewMap;
