import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import Flippy, { FrontSide, BackSide } from 'react-flippy';

const RankingDimension = (props) => {
  const {
    rankvalue, backvalue, topic
  } = props;
  return (
    <Card
      sx={{ height: '80%' }}
      {...props}
    >
      <CardContent>
        <Flippy
          flipDirection="horizontal"
        >
          <FrontSide style={{ boxShadow: 'none' }}>
            <Grid
              container
              spacing={1}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  marginTop="-20px"
                  marginLeft="-20px"
                >
                  #
                  {rankvalue}
                </Typography>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  marginLeft="-20px"
                >
                  {topic}
                </Typography>
              </Grid>
            </Grid>
          </FrontSide>
          <BackSide style={{ backgroundColor: '#white', boxShadow: 'none' }}>
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: 'space-between' }}
            >
              <Grid item>
                <Typography
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                  marginLeft="-10px"
                >
                  {backvalue}
                </Typography>
              </Grid>
            </Grid>
          </BackSide>
        </Flippy>
      </CardContent>
    </Card>
  );
};

export default RankingDimension;
