import { useState } from 'react';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import NavItem from './NavItem';

const NestedNavItem = ({
  href,
  icon,
  title,
  subNavs
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <NavItem
        href={href}
        title={title}
        key={title}
        icon={icon}
        onClick={handleClick}
        showExpand={open ? <ExpandLess sx={{ color: '#6b778c' }} /> : <ExpandMore sx={{ color: '#6b778c' }} />}
      />
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          sx={{
            py: 0
          }}
        >
          {subNavs.map((item) => (
            <NavItem
              sx={{ pl: 2, py: 0 }}
              href={item.href}
              key={item.title}
              title={item.title}
              icon={item.icon}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default NestedNavItem;
