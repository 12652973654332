import {
  // Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@material-ui/core';

const TopRatedInsitution = (props) => {
  const {
    hospitalname, hospitalocation, hospavatar
  } = props;
  return (
    <Card
      sx={{ height: '100%' }}
      style={{ backgroundColor: '#f4f6f8', boxShadow: 'none' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Grid item style={{ width: '100%' }}>
            <Grid
              item
              style={{
                height: '80px',
                width: '100%',
                position: 'relative',
                marginBottom: '60px'
              }}
            >
              <img
                alt="Under development"
                src={hospavatar}
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'inline-block',
                  position: 'absolute',
                  bottom: '0',
                  margin: '0 auto',
                  left: '0',
                  right: '0'
                }}
              />
            </Grid>
            <Typography
              color="#878e9c"
              variant="h5"
              textAlign="center"
              height="50px"
            >
              {hospitalname}
            </Typography>
            <Typography
              color="#99a1b0"
              variant="h6"
              marginBottom="-30px"
              textAlign="center"
              height="50px"
            >
              {hospitalocation}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                pt: 2
              }}
            >
              <Button
                sx={{ width: 200 }}
                // color="primary"
                component="a"
                href="https://react-material-kit.devias.io"
                variant="outlined"
              >
                View
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        />
      </CardContent>
    </Card>
  );
};

export default TopRatedInsitution;
