import {
  Box,
  Checkbox,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ChartTooltip, VennArc,
  VennDiagram, VennLabel,
  VennOuterLabel, VennSeries
} from 'reaviz';
import TopHospitalsGeneralTable from './TopHospitalsGeneralTable';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TopHospitalsVennChart = () => {
  const classes = useStyles();
  const topHospList = useSelector((state) => state.topHospitals);
  const [vennData, setVennData] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState(['projectCount', 'piCount', 'funding']);
  const [sectionHospitals, setSectionHospitals] = useState([]);
  const selectIndicator = useRef(null);
  const selectTopIndicator = useRef(null);

  const getIndicatorHospitals = (selectsDimension, selectsCount) => {
    const tempData = {};
    // selectsDimension is different attribution, selectsCount is top n hospital
    // forEach() 方法用于调用数组的每个元素，并将元素传递给回调函数
    selectsDimension.forEach((indicator) => {
      tempData[indicator] = topHospList.sort((b, a) => (a[indicator] * 1 - b[indicator] * 1)).slice(0, selectsCount);
    });
    console.log('tempData', tempData);
    return tempData;
  };
  // venn 图交集部分数据的function
  const getArrEqual = (arr1, arr2) => {
    const array3 = arr1.filter((v1) => arr2.some((v2) => v1.lmID === v2.lmID));
    return array3;
  };

  const generateVennDataItem = (subset, indicatorHospitals) => {
    let mergeSet = indicatorHospitals[subset[0]];
    for (let index = 1; index < subset.length; index++) {
      mergeSet = [...getArrEqual(mergeSet, indicatorHospitals[subset[index]])];
    }
    return {
      key: subset,
      hopitals: mergeSet,
      data: mergeSet.length
    };
  };

  // function to get subsets from data set.
  const generateAllVennData = (selectsDimension, selectsCount) => {
    const indicatorHospitals = getIndicatorHospitals(selectsDimension, selectsCount);
    const vennMockData = [];
    for (let i = 1; i < (2 ** selectsDimension.length); i++) {
      const subset = [];
      for (let j = 0; j < selectsDimension.length; j++) {
        if ((i & (1 << j)) > 0) {
          subset.push(selectsDimension[j]);
        }
      }
      vennMockData.push(generateVennDataItem(subset, indicatorHospitals));
    }
    // return vennMockData;
    setVennData([...vennMockData]);
    setSectionHospitals([]);
  };
  // add selected data in dataFromMultiSelect[]

  const names = [
    {
      index: 1,
      label: 'Frontier Research',
      value: 'projectCount'
    },
    {
      index: 2,
      label: 'Therapeutic Investigation',
      value: 'interverntionCount'
    },
    {
      index: 3,
      label: 'Physician Avtivites',
      value: 'physicianCount'
    },
    {
      index: 4,
      label: 'Federal Support',
      value: 'funding'
    },
    {
      index: 5,
      label: 'Associated Experts',
      value: 'piCount'
    },
    {
      index: 6,
      label: 'Clinical Excellence',
      value: 'nctCount'
    },
  ];

  useEffect(() => {
    generateAllVennData(selectIndicator.current.children[1].value.split(','), selectTopIndicator.current.children[1].value);
    console.log(sectionHospitals.length);
  }, []);

  // checkbox maxallowed = 5
  const shouldDisableCheckbox = (id) => {
    const maxAllowed = 5;
    return selectedIndicators.length >= maxAllowed && selectedIndicators.indexOf(id) === -1;
  };
  // attribution selection function
  const handleChange = (event) => {
    setSelectedIndicators(event.target.value);
    generateAllVennData(event.target.value, selectTopIndicator.current.children[1].value);
  };
  // Top hospital selection function
  const handleChangeTop = (event) => {
    generateAllVennData(selectIndicator.current.children[1].value.split(','), event.target.value);
  };
  console.log('selectIndicator', selectIndicator);

  return (
    <>
      <Box
        textAlign="left"
        style={{
          marginBottom: '30px',
          marginLeft: '-10px',
        }}
      >
        {/* dimension selection bar */}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            ref={selectIndicator}
            onChange={handleChange}
            multiple
            displayEmpty
            value={selectedIndicators}
            renderValue={(selected) => {
              const displaySelected = [];
              selected.forEach((indicator) => {
                displaySelected.push(names.find((item) => item.value === indicator).label);
              });
              return displaySelected.join(', ');
            }}
            MenuProps={MenuProps}
          >
            {names.map((name) => (
              <MenuItem key={name.label} value={name.value} disabled={shouldDisableCheckbox(name.value)}>
                <Checkbox
                  checked={selectedIndicators.indexOf(name.value) > -1}
                />
                <ListItemText primary={name.label} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        &nbsp;&nbsp;&nbsp;
        {/* top N hospitals selection bar */}
        <FormControl variant="outlined" className={classes.formControl}>
          <Select
            // ref={selectTopIndicator}
            defaultValue="5"
            onChange={handleChangeTop}
            ref={selectTopIndicator}
            displayEmpty
            className={classes.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="1">Top 1 Hospital</MenuItem>
            <MenuItem value="2">Top 2 Hospitals</MenuItem>
            <MenuItem value="3">Top 3 Hospitals</MenuItem>
            <MenuItem value="4">Top 4 Hospitals</MenuItem>
            <MenuItem value="5">Top 5 Hospitals</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <VennDiagram
        height={450}
        width="100%"
        type="starEuler"
        data={vennData}
        series={(
          <VennSeries
            // colorScheme={(data, index) => (index % 2 ? 'coral' : 'coral')}
            colorScheme={(data, index) => (index % 2 ? '#f28dcd' : '#40d6e3')}
            label={(
              <VennLabel
                format={(current) => current.data.size}
                showAll="true"
              />
            )}
            outerLabel={(
              <VennOuterLabel
                fill="#1EB0BC"
                format={(current) => names.find((item) => item.value === current.data.key).label}
              />
            )}
            arc={(
              <VennArc
                tooltip={<ChartTooltip format={(current) => current.data.size} />}
                onClick={(current) => {
                  const vItem = vennData.find((item) => item.key.toString() === current.value.sets.toString());
                  setSectionHospitals([...vItem.hopitals]);
                }}
              />
            )}
          />
        )}
      />
      <br />
      <br />
      <div style={{ display: sectionHospitals.length === 0 ? 'none' : 'block' }}>
        <TopHospitalsGeneralTable topHospList={sectionHospitals} hideFooterPagination height="370px" />
      </div>
    </>
  );
};
export default TopHospitalsVennChart;
