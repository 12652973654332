import {
  Box,
  Button,
  Grid,
  // Card,
  // CardContent,
  Typography
} from '@material-ui/core';

const NeedMore = () => (
  <Grid
    container
    spacing={3}
  >
    <Grid
      item
      lg={6}
      md={12}
      xl={4}
      xs={12}
    >
      <Box sx={{ sx: 2 }}>
        <Typography
          color="textPrimary"
          variant="h3"
          style={{
            marginLeft: '30px',
            marginTop: '70px',
            marginBottom: '40px'
          }}
        >
          Need further customized analysis?
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`Contact us to request for your 
          further personalized needs!`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          sx={{ width: 354 }}
          color="secondary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          Contact Us
        </Button>
      </Box>
    </Grid>
  </Grid>
);
export default NeedMore;
