import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import CompareWithOtherHospitals from 'src/components/overall/CompareWithOtherHospitals';
import HighlySelective from 'src/components/overall/HighlySelective';
import MostRecentUpdate from 'src/components/overall/MostRecentUpdate';
import CarouselHosp from 'src/components/carousel/CarouselHosp';
import NeedMore from 'src/components/overall/NeedMore';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBIcon } from 'mdbreact';

const mockTopNumber = [
  {
    lmId: 1,
    icon: <MDBIcon icon="book-medical" style={{ fontSize: 30 }} />,
    topic: 'Science',
    top: 'Top Rated: 100%',
    urlcolor: '#bdbdbd',
    others: 'Regional Leaders: 85%',
    all: 'Other Institutions: 75%'
  },
  {
    lmId: 2,
    icon: <MDBIcon icon="capsules" style={{ fontSize: 30 }} />,
    topic: 'Translational Medicine',
    top: 'Top Rated: 100%',
    urlcolor: '#bdbdbd',
    others: 'Regional Leaders: 85%',
    all: 'Other Institutions: 75%'
  },
  {
    lmId: 3,
    icon: <MDBIcon icon="user-md" style={{ fontSize: 30 }} />,
    topic: 'Clinical Capacity',
    top: 'Top Rated: 100%',
    urlcolor: '#bdbdbd',
    others: 'Regional Leaders: 85%',
    all: 'Other Institutions: 75%'
  }
];

const Overall = () => {
  const dkw = useSelector((state) => state.dkw);
  console.log('Overall');
  return (
    <>
      <Helmet>
        <title>Overall | Material Kit</title>
      </Helmet>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '30px',
            marginTop: '70px',
            marginBottom: '40px'
          }}
        >
          America’s Best Hospitals for &nbsp;
          {dkw.toUpperCase()}
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          // variant="h6"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`The study of America’s Best Hospitals for ${dkw} is based on LINKMedicine’s proprietary algorithm: the FACTS+ Medical Expertise Analytics. 
          The purpose of this study is to reveal the differential expertise associated with individual hospitals and empower patients to make better hospital 
          choices.`}
          <br />
          <br />
          {` In this study, a comprehensive evaluation was performed on 6,000 hospitals and their associated 20,500 research institutions, 300,000 research groups, 
          1.05 million doctors and physicians for domains of science, translational medicine, and clinical practice. They are the major institutions and expert base 
          for medical expertise for disease care in the United States. `}
          <br />
          <br />
          {`The result reveals that 48% of major hospitals pass the analysis baseline. There are 2,000 related research institutions, 1,500 experts, and 306 
          research projects related to Myelofibrosis. There are 414 clinical-stage studies designed for advanced treatment options; 109 studies are active or 
          currently recruiting.`}
          <br />
          <br />
          {`In total, this study identified 6 nationally top-rated hospitals and 15 regional leaders with the most expertise in ${dkw}. 
          View advanced analysis for further details.`}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          color="primary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          View top rated institutions
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="a"
          href="https://react-material-kit.devias.io"
          variant="outlined"
          sx={{ marginLeft: '20px' }}
        >
          Download full report
        </Button>
      </Box>
      <Typography
        color="textPrimary"
        variant="h2"
        style={{
          marginLeft: '20px',
          marginBottom: '40px'
        }}
      >
        Which hospitals are the top rated ones?
      </Typography>
      <CarouselHosp />
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h2"
          style={{
            marginLeft: '20px',
            marginTop: '40px',
            marginBottom: '40px'
          }}
        >
          How different are they?
        </Typography>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={6}
              md={12}
              xl={6}
              xs={12}
            >
              <HighlySelective />
            </Grid>
            <Grid
              item
              lg={6}
              md={12}
              xl={6}
              xs={12}
            >
              <Typography
                color="textPrimary"
                variant="body2"
                style={{
                  marginLeft: '30px',
                  marginBottom: '40px',
                  wordWrap: 'break-word'
                }}
              >
                <br />
                <br />
                {`We harness the power of award-winning technology to measure expertise from thousands of hospitals in this nation 
                  and compare them on one standard. `}
                <br />
                <br />
                {`The nationally top-rated hospitals are the upper 1 ‰ from the United States. They represent foremost for the care of the disease.
                  with best of the best expertise. `}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Typography
          color="textPrimary"
          variant="h2"
          style={{
            marginLeft: '20px',
            marginBottom: '40px'
          }}
        >
          Compare with other hospitals
        </Typography>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            {mockTopNumber.map((num) => (
              <Grid
                key={num.lmId}
                item
                lg={4}
                sm={6}
                xs={12}
              >
                <CompareWithOtherHospitals icon={num.icon} topic={num.topic} top={num.top} urlcolor={num.urlcolor} others={num.others} all={num.all} sx={{ height: '100%' }} />
              </Grid>
            ))}
          </Grid>
        </Container>
        <Typography
          color="textPrimary"
          variant="h2"
          style={{
            marginLeft: '20px',
            marginTop: '40px',
            marginBottom: '40px'
          }}
        >
          Most recent updates
        </Typography>
        <Container maxWidth={false}>
          <Grid
            container
            spacing={3}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <MostRecentUpdate sx={{ height: '100%' }} />
            </Grid>
          </Grid>
        </Container>
        <NeedMore />
      </Box>
    </>
  );
};

export default Overall;
