import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  Grid,
} from '@material-ui/core';
import Radar from 'src/components/hospitalsList/Radar';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const RadarModal = (props) => {
  const {
    selectedHospIds
  } = props;
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen('paper')}>Compare</Button>
      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        fullScreen
        maxWidth="xl"
      >
        <DialogContent>
          <DialogActions>
            <Button
              onClick={handleClose}
              color="primary"
              style={{
                marginTop: -25,
                marginBottom: -10,
                marginRight: -30,
                padding: 10
              }}
            >
              <HighlightOffIcon />
            </Button>
          </DialogActions>
          <Grid
            // container
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              item
              xs={12}
              lg={12}
            >
              <Radar selectedHospIds={selectedHospIds} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default RadarModal;
