import React from 'react';
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Button,
  Link,
  Box
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Link as RouterLink } from 'react-router-dom';

const RecentUpdatesContent = (props) => {
  const {
    topic, number, links, icon
  } = props;

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState('paper');

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Link
            component={RouterLink}
            to={links}
          >
            <Grid item>
              <Grid
                color="textPrimary"
                textAlign="left"
                height="150px"
                sx={{
                  marginTop: '50px',
                  marginLeft: '65px',
                }}
                onClick={handleClickOpen('paper')}
              >
                {icon}
              </Grid>
            </Grid>
          </Link>
          <Link
            component={RouterLink}
            to={links}
          >
            <Box
              sx={{
                width: 300,
                marginTop: '20px',
                marginLeft: '30px',
                marginBottom: '-10px'
              }}
            >
              <Typography
                color="textPrimary"
                variant="h1"
                style={{
                  marginTop: '50px',
                  marginLeft: '10px',
                  marginBottom: '20px'
                }}
                onClick={handleClickOpen('paper')}
              >
                {number}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h5"
                style={{
                  marginBottom: '20px',
                }}
                onClick={handleClickOpen('paper')}
              >
                {topic}
              </Typography>
            </Box>
          </Link>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll={scroll}
            fullScreen
            maxWidth="xl"
          >
            <DialogActions>
              <Button
                onClick={handleClose}
                color="primary"
                style={{
                  marginTop: -15,
                  marginBottom: -40,
                  marginRight: -20,
                  padding: 10
                }}
              >
                <HighlightOffIcon />
              </Button>
            </DialogActions>
            <DialogContent>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
              >
                <Grid
                  item
                  xs={12}
                  lg={12}
                >
                  {links}
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default RecentUpdatesContent;
