import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Button,
  Typography
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RecentUpdatesContent from 'src/components/updates/RecentUpdatesContent';
import { useSelector } from 'react-redux';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import { MDBIcon } from 'mdbreact';
import NeedMore from 'src/components/overall/NeedMore';
import RecentDrugsApproved from 'src/components/updates/RecentDrugsApproved';
import ClinicalTrialsActive from 'src/components/updates/ClinicalTrialsActive';
import Breakthroughs from 'src/components/updates/Breakthroughs';
import AnnouncementIcon from '@material-ui/icons/Announcement';

const mockTopNumber = [
  {
    lmId: 1,
    number: '10',
    topic: 'Recent Drugs Approved',
    link: <RecentDrugsApproved />,
    icon: <MDBIcon icon="pills" style={{ fontSize: 100 }} />,
  },
  {
    lmId: 2,
    number: '23',
    topic: 'Clinical Trials Active',
    link: <ClinicalTrialsActive />,
    icon: <MDBIcon icon="clinic-medical" style={{ fontSize: 100 }} />,

  },
  {
    lmId: 3,
    number: '15',
    topic: 'Breakthroughs from Major Hospitals',
    link: <Breakthroughs />,
    icon: <AnnouncementIcon style={{ fontSize: 100 }} />,

  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(65),
      height: theme.spacing(32),
    },
  },
}));
const MostRecentUpdates = () => {
  const dkw = useSelector((state) => state.dkw);
  console.log('dkw', dkw);
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Latest Updates | Material Kit</title>
      </Helmet>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '20px',
            marginTop: '50px',
            marginBottom: '20px'
          }}
        >
          Latest Updates for &nbsp;
          {dkw}
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`The frontier of medicine care has been continuously improved by 
            scientific discoveries, medical breakthroughs, and clinical practice.`}
          <br />
          <br />
          {`Here you will find the latest medical progress in the disease area related to Myelofibrosis. Click each analysis to view details, and 
            access the foremost of care for this disease. `}
          <br />
          <br />
          {`Stay on top of the medical expertise. You may construct your own analysis to obtain insights 
            that may help you make better healthcare decisions. `}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          color="primary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          View the top rated institutions
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="a"
          href="https://react-material-kit.devias.io"
          variant="outlined"
          sx={{ marginLeft: '20px' }}
        >
          Download full report
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
          >
            {mockTopNumber.map((num) => (
              <Grid
                key={num.lmId}
                item
                lg={6}
                md={12}
                xl={4}
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div className={classes.root}>
                  <RecentUpdatesContent number={num.number} topic={num.topic} links={num.link} icon={num.icon} sx={{ height: '100%' }} />
                </div>
              </Grid>
            ))}
          </Grid>
          <NeedMore />
        </Container>
      </Box>
    </>
  );
};

export default MostRecentUpdates;
