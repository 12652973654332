// import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Button,
  Card,
  CardHeader,
  // Chip,
  Table,
  TableBody,
  TableCell,
  // TableHead,
  TableRow,
  // TableSortLabel,
  // Tooltip,
  Typography,
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'react-router-dom';

const Updates = [
  {
    id: 1,
    date: '06/16/2021',
    name: 'New Cleveland Clinic Research Identifies Link Between Gut Microbes and Stroke'
  },
  {
    id: 2,
    date: '06/15/2021',
    name: 'Cleveland Clinic Commits to Expanding Supplier Diversity, Building Community Wealth'
  },
  {
    id: 3,
    date: '06/15/2021',
    name: 'Cleveland Clinic Children’s Ranked Among the Nation’s Best Children’s Hospitals by U.S. News & World Report �?Cleveland Clinic Newsroom'
  },
  {
    id: 4,
    date: '06/10/2021',
    name: 'Cleveland Clinic-led Study Identifies How COVID-19 Linked to Alzheimer’s Disease-like Cognitive Impairment'
  },
  {
    id: 5,
    date: '06/09/2021',
    name: 'Cleveland Clinic Statement on Previous COVID-19 Infection Research'
  },
];

const MostRecentUpdate = (props) => (
  <Card {...props}>
    <CardHeader />
    <PerfectScrollbar style={{ height: 'auto' }}>
      <Box sx={{ minWidth: 550 }}>
        <Table size="small" aria-label="a dense table">
          <TableBody>
            {Updates.map((Update) => (
              <TableRow
                hover
                key={Update.id}
              >
                <TableCell>
                  {Update.name}
                    &nbsp;
                    &nbsp;
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="h7"
                    style={{
                      marginBottom: '5px'
                    }}
                  >
                    {Update.date}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </PerfectScrollbar>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        p: 2
      }}
    >
      <Link to="/app/mostRecentUpdates">
        <Button
          color="primary"
          endIcon={<ArrowRightIcon />}
          size="small"
          variant="text"
        >
          View all
        </Button>
      </Link>
    </Box>
  </Card>
);

export default MostRecentUpdate;
