import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';

const columns = [
  {
    field: 'institution',
    headerClassName: 'super-app-theme--header',
    headerName: 'Institution',
    width: 400
  },
  {
    field: 'frontierResearch',
    headerClassName: 'super-app-theme--header',
    headerName: 'Frontier Research',
    width: 200
  },
  {
    field: 'associatedExpert',
    headerClassName: 'super-app-theme--header',
    headerName: 'Associated Expert',
    width: 200
  },
  {
    field: 'clinicalExcellence',
    headerClassName: 'super-app-theme--header',
    headerName: 'Clinical Excellence',
    width: 200
  },
  {
    field: 'therapeuticInvestigation',
    headerClassName: 'super-app-theme--header',
    headerName: 'Therapeutic Investigation',
    width: 220
  },
  {
    field: 'federalSupport',
    headerClassName: 'super-app-theme--header',
    headerName: 'Federal Support',
    width: 200
  }
];

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgba(30, 176, 188)',
      color: 'white'
    },
  },
  '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    overFlow: 'visible'
  }
});
const TopHospitalsGeneralTable = (props) => {
  const classes = useStyles();
  const rows = [];
  const { topHospList, hideFooterPagination, height } = props;
  let defaultHeight = '650px';
  if (height) {
    defaultHeight = height;
  }

  topHospList.forEach((hospital) => {
    rows.push(
      {
        id: hospital.lmID * 1,
        institution: hospital.name.toUpperCase(),
        frontierResearch: `${((hospital.projectCount / hospital.maxProjectCount) * 100).toFixed(2)}%`,
        associatedExpert: `${((hospital.piCount / hospital.maxPiCount) * 100).toFixed(2)}%`,
        clinicalExcellence: `${((hospital.nctCount / hospital.maxNctCount) * 100).toFixed(2)}%`,
        therapeuticInvestigation: `${((hospital.interverntionCount / hospital.maxInterverntionCount) * 100).toFixed(2)}%`,
        federalSupport: `${((hospital.funding / hospital.maxFunding) * 100).toFixed(2)}%`
      }
    );
  });
  return (
    <div style={{ height: defaultHeight, width: '100%' }} className={classes.root}>
      <DataGrid rows={rows} columns={columns} pageSize={10} hideFooterPagination={hideFooterPagination} />
    </div>
  );
};

export default TopHospitalsGeneralTable;
