import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  TextField,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Checkbox,
  ListItemText
} from '@material-ui/core';
import TopHospitalsList from 'src/components/hospitalsList/TopHospitalsList';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useEffect, useRef, useState } from 'react';
import { requestTopHospitals } from 'src/api';
import { saveDkw } from 'src/redux/actions/dkwAction';
import { saveDkwMeshId } from 'src/redux/actions/dkwMeshIdAction';
import { loadTopHospitals } from 'src/redux/actions/topHospitalsAction';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import NeedMore from 'src/components/overall/NeedMore';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 10.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TopHospitals = () => {
  const [loading, setLoading] = useState(false);// useState 内部
  const dkw = useSelector((state) => state.dkw);
  const topHospitals = useSelector((state) => state.topHospitals); // useSelector 全局
  const dispatch = useDispatch();
  const [selectRegionIndicatored, setSelectRegionIndicatored] = useState(['New England', 'Middle Atlantic', 'South Atlantic', 'East North Central', 'West North Central', 'East South Central', 'West South Central', 'Mountain', 'Pacific']);
  const [displayHospitals, setDisplayHospitals] = useState([]);
  const selectIndicator = useRef(null); // attribute
  const selectTopIndicator = useRef(null); // tophospital
  const selectRegionsIndicator = useRef(null); // regions

  // selection style

  const useStylesSelection = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 240,
      maxWidth: 240,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    }
  }));
  const classesSelection = useStylesSelection();

  const reloadTopHospitals = async (kword) => {
    setLoading(true);
    const result = await requestTopHospitals(kword);
    setLoading(false);
    // sort the topHospList before dispatch
    dispatch(loadTopHospitals(result.topHospList.sort((a, b) => a.overallRankDisplay * 1 - b.overallRankDisplay * 1)));
    // save dkw mesh id
    console.log(result.dkwMeshId);
    dispatch(saveDkwMeshId(result.dkwMeshId));
  };

  const generateDisplayData = (attributeValue, selectsRegionlist, selectsTopHospiatlCount) => {
    // select Top/all Hospitals
    const mockHospitals = topHospitals.sort((a, b) => (a.overallRankDisplay * 1 - b.overallRankDisplay * 1)).slice(0, selectsTopHospiatlCount);// 6所医院按照overall 排序
    // select attribution bar
    console.log('mockHospitals1', mockHospitals);
    mockHospitals.sort((a, b) => a[attributeValue] * 1 - b[attributeValue] * 1);
    const afterFilterData = mockHospitals.filter((hosp) => selectsRegionlist.includes(hosp.division));
    // const notInData = mockHospitals.filter((hosp) => !selectsRegionlist.has(hosp.division));
    console.log('selectsRegionlist', selectsRegionlist); // 有值
    console.log('afterFilterData', afterFilterData);
    // console.log('notInData', notInData);
    // console.log('mockHospitals2', mockHospitals);
    // // mockHospitals.filter((hosp) => hosp.division === selectsRegionlist);
    // // mockHospitals.filter((hosp) => selectsRegionlist.has(hosp.division));
    // console.log('selectsRegionlist2', selectsRegionlist); // 有值
    // selectsRegionlist.forEach((region) => { mockHospitals.filter((hosp) => hosp.division === region); });
    // // mockHospitals[a.division] === region;
    // // console.log('topHospitals.division', topHospitals.division);
    // // 过滤掉mockHospitals里 division 不在selectsRegionlist的
    // // select region loop
    setDisplayHospitals([...afterFilterData]);
  };
  const handleDkwChange = (e) => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      dispatch(saveDkw(e.target.value));
      reloadTopHospitals(e.target.value);
    }
  };
  const names = [
    'Middle Atlantic',
    'South Atlantic',
    'East North Central',
    'West North Central',
    'East South Central',
    'West South Central',
    'Mountain',
    'Pacific',
    'New England',
  ];
  // reloadTophospitals when first time mounted
  useEffect(() => {
    // console.log('selectIndicator.current.children[1].value', selectIndicator.current.children[1].value);
    // console.log('selectTopIndicator.current.children[1].value', selectTopIndicator.current.children[1].value);
    // console.log('selectRegionsIndicator.current.children[1].value', selectRegionsIndicator.current.children[1].value);
    if (topHospitals.length === 0) {
      reloadTopHospitals(dkw);
    }
  }, []);

  useEffect(() => {
    generateDisplayData(selectIndicator.current.children[1].value, selectRegionsIndicator.current.children[1].value, selectTopIndicator.current.children[1].value);
  }, [topHospitals]);

  // select attribution function
  const handleChange = (event) => {
    generateDisplayData(event.target.value, selectRegionsIndicator.current.children[1].value, selectTopIndicator.current.children[1].value);
  };
  // selection top/all function
  const handleChangeTop = (event) => {
    generateDisplayData(selectIndicator.current.children[1].value, selectRegionsIndicator.current.children[1].value, event.target.value);
  };

  const handleChangeRegion = (event) => {
    setSelectRegionIndicatored(event.target.value); // the value need display in select bar
    console.log('event.target.value', event.target.value);
    // const selectsRegionlist = [];
    // selectsRegionlist.push(event.target.value);
    // console.log('selectsRegionlist1', selectsRegionlist);
    generateDisplayData(selectIndicator.current.children[1].value, event.target.value, selectTopIndicator.current.children[1].value);
  };

  return (
    <>
      <Helmet>
        <title>Top Hospitals | LINKMedicine</title>
      </Helmet>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '30px',
            marginTop: '70px',
            marginBottom: '40px'
          }}
        >
          View Top Hospitals
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`As of 2021, there are 6,600 hospitals in the United States providing as many as 900 million outpatient
          visits and 36 million inpatient clinical service. America’s top hospitals are also key players driving medical
          innovation from benchside to bedside. `}
          <br />
          <br />
          {`The result reveals that 48% of major hospitals pass the analysis baseline. 6 nationally top-rated
          hospitals and 15 regional leaders are identified for their expertise in this America’s Best Hospitals for
          Myeloproliferation study. The top-rated hospitals are the upper 1 ‰ and they represent the foremost of care
          for this disease.`}
          <br />
          <br />
          {`View analysis results below and that help you
          make better healthcare decisions.`}

        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          color="primary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          View top rated institutions
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="a"
          href="https://react-material-kit.devias.io"
          variant="outlined"
          sx={{ marginLeft: '20px' }}
        >
          Download full report
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3,
          pb: 0
        }}
      >
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '20px',
            marginBottom: '20px'
          }}
        >
          Hospitals and Ratings
        </Typography>
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <TextField
              fullWidth
              label="DKW"
              variant="outlined"
              defaultValue={dkw.toUpperCase()}
              onKeyPress={handleDkwChange}
            />
            <br />
            <br />
          </Box>
          {
            loading
              ? <CircularProgress />
              : (
                <Box>
                  {/* selection attribution part */}
                  <Box
                    textAlign="left"
                  >
                    {/* selection Top hospital part */}
                    <FormControl
                      variant="outlined"
                      className={classesSelection.formControl}
                      sx={{ m: 1, width: 200 }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">Choose Rating</InputLabel>
                      <Select
                        ref={selectTopIndicator}
                        defaultValue="6"
                        onChange={handleChangeTop}
                        input={<OutlinedInput label="Choose Rating" />}
                        displayEmpty
                        className={classesSelection.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >
                        <MenuItem value="6">Top Rated Only</MenuItem>
                        <MenuItem value="100">All Major Hospitals</MenuItem>
                      </Select>
                    </FormControl>
                    &nbsp;&nbsp;&nbsp;
                    {/* selection region part */}
                    <FormControl
                      variant="outlined"
                      className={classesSelection.formControl}
                      sx={{ m: 1, width: 300 }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">Choose Region</InputLabel>
                      <Select
                        multiple
                        ref={selectRegionsIndicator}
                        value={selectRegionIndicatored}
                        onChange={handleChangeRegion}
                        maxMenuHeight="20"
                        input={<OutlinedInput label="Choose Region (all)" />}
                        displayEmpty
                        // renderValue={(selected) => selected.join(', ')}
                        renderValue={(selected) => {
                          const displaySelected = [];
                          selected.forEach((indicator) => {
                            // console.log('indicator', indicator);
                            displaySelected.push(indicator);
                          });
                          return displaySelected.join(', ');
                        }}
                        MenuProps={MenuProps}
                      >
                        {names.map((name) => (
                          <MenuItem key={name} value={name}>
                            <Checkbox checked={selectRegionIndicatored.indexOf(name) > -1} />
                            <ListItemText primary={name} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    &nbsp;&nbsp;&nbsp;
                    <FormControl
                      variant="outlined"
                      className={classesSelection.formControl}
                      style={{ float: 'right' }}
                      sx={{ m: 1, width: 150 }}
                    >
                      <InputLabel id="demo-multiple-checkbox-label">Sort by</InputLabel>
                      <Select
                        ref={selectIndicator}
                        defaultValue="overallRank"
                        onChange={handleChange}
                        input={<OutlinedInput label="Sort by" />}
                        displayEmpty
                        className={classesSelection.selectEmpty}
                        inputProps={{ 'aria-label': 'Without label' }}
                      >

                        <MenuItem value="overallRank">Overall Rank</MenuItem>
                        <MenuItem value="projectCountRank">Frontier Research</MenuItem>
                        <MenuItem value="treatCountRank">Therapeutic Investigation</MenuItem>
                        <MenuItem value="physicianCountRank">Physician Avtivites</MenuItem>
                        <MenuItem value="fundingRank"> Federal Support</MenuItem>
                        <MenuItem value="piCountRank">Associated Experts</MenuItem>
                        <MenuItem value="nctCountRank">Clinical Excellence</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Container maxWidth={false} disableGutters>
                    <Grid
                      container
                    >
                      <Container maxWidth={false} disableGutters>
                        <Box sx={{ pt: 3 }}>
                          <TopHospitalsList displayHospitals={displayHospitals} />
                        </Box>
                      </Container>
                    </Grid>
                  </Container>
                </Box>
              )
          }

        </Container>
        <NeedMore />
      </Box>
    </>
  );
};

export default TopHospitals;
