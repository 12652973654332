import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Grid,
  Button,
  // Paper,
  Typography
} from '@material-ui/core';
import AdvancedContent from 'src/components/charts/AdvancedContent';
import NeedMore from 'src/components/overall/NeedMore';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import WorkspacesIcon from '@material-ui/icons/Workspaces';
import BarChartIcon from '@material-ui/icons/BarChart';

const mockUpdates = [
  {
    lmId: 1,
    icon: <WorkspacesIcon style={{ fontSize: 130 }} />,
    topic: 'Venn',
    link: '/app/advanced/Venn',
    content: 'Comprehensive Analysis'
  },
  {
    lmId: 2,
    icon: <BubbleChartIcon style={{ fontSize: 130 }} />,
    topic: 'Bubble',
    link: '/app/advanced/Bubble',
    content: 'Comprehensive Comparison'
  },
  {
    lmId: 3,
    icon: <BarChartIcon style={{ fontSize: 130 }} />,
    topic: 'Histogram',
    link: '/app/advanced/Histogram',
    content: 'Hospital Expertise Distribution'
  },
  {
    lmId: 4,
    icon: <TimelineIcon style={{ fontSize: 130 }} />,
    topic: 'Line',
    link: '/app/advanced/Line',
    content: 'View the Trend'
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(65),
      height: theme.spacing(32),
    },
  },
}));

const Advanced = () => {
  const dkw = useSelector((state) => state.dkw);
  console.log('dkw', dkw);
  const classes = useStyles();
  return (
    <>
      <Helmet>
        <title>Advanced | Material Kit</title>
      </Helmet>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '30px',
            marginTop: '70px',
            marginBottom: '40px'
          }}
        >
          Advanced Analysis
        </Typography>
      </Box>
      <Box
        width="98%"
        sx={{ flexGrow: 1 }}
        justify="center"
      >
        <Typography
          color="textPrimary"
          variant="body2"
          style={{
            marginLeft: '30px',
            marginBottom: '40px',
            wordWrap: 'break-word'
          }}
        >
          {`The study of America’s Best Hospitals for ${dkw} identified 6 nationally top-rated 
          hospitals and 15 regional leaders are identified with the most expertise for this disease.`}
          <br />
          <br />
          {`The insights are distilled from our proprietary algorithm based on the broad data covering thousands of 
          hospitals, over million physicians and other providers and hundreds of millions of clinical encounters.`}
          <br />
          <br />
          {`Just as no two diseases are exactly alike, no two people have the same medical care needs. 
          In this section, you may utilize advanced data analysis tools to construct your own analysis, 
          uncover deeper insights and access expertise that meet your personalized needs. `}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'bottom',
          pt: 1,
          marginTop: '40px',
          marginLeft: '30px',
          marginBottom: '40px'
        }}
      >
        <Button
          color="primary"
          component="a"
          size="small"
          href="https://react-material-kit.devias.io"
          variant="contained"
        >
          View the top rated institutions
        </Button>
        &nbsp;
        <Button
          color="primary"
          component="a"
          href="https://react-material-kit.devias.io"
          variant="outlined"
          sx={{ marginLeft: '20px' }}
        >
          Download full report
        </Button>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            spacing={1}
          >
            {mockUpdates.map((Updates) => (
              <Grid
                key={Updates.lmId}
                item
                lg={6}
                sm={12}
                xl={4}
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <div className={classes.root}>
                  {/* <Paper variant="outlined" textAlign="center"> */}
                  <AdvancedContent icon={Updates.icon} topic={Updates.topic} link={Updates.link} content={Updates.content} sx={{ height: '100%' }} />
                  {/* </Paper> */}
                </div>
              </Grid>
            ))}
          </Grid>
          <NeedMore />
        </Container>
      </Box>
    </>
  );
};

export default Advanced;
