import {
  Card,
  CardContent,
  Grid,
  Typography,
  Link,
  Box
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const AdvancedContent = (props) => {
  const {
    topic, icon, link, content
  } = props;
  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Link
            component={RouterLink}
            to={link}
          >
            <Grid item>
              <Grid
                color="textPrimary"
                textAlign="left"
                height="150px"
                sx={{
                  marginTop: '40px',
                  marginLeft: '50px',
                }}
              >
                {icon}
              </Grid>
            </Grid>
          </Link>
          <Link
            component={RouterLink}
            to={link}
          >
            <Box
              sx={{
                width: 300,
                marginTop: '20px',
                marginLeft: '30px',
                marginBottom: '-10px'
              }}
            >
              <Typography
                color="textPrimary"
                gutterBottom
                variant="h1"
                style={{
                  marginTop: '50px',
                  marginRight: '15px',
                  marginBottom: '20px'
                }}
              >
                {topic}
              </Typography>
              <Typography
                color="textPrimary"
                variant="h5"
                style={{
                  marginBottom: '20px'
                }}
              >
                {content}
              </Typography>
            </Box>
          </Link>
          <Typography
            color="textPrimary"
            // gutterBottom
            variant="h5"
            textAlign="right"
            style={{
              marginTop: '10px',
              marginLeft: '-10px',
              // marginBottom: '20px'
            }}
          >
            ？
          </Typography>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AdvancedContent;
