import {
  Card,
  CardContent,
} from '@material-ui/core';
import ReactECharts from 'echarts-for-react';
import { useRef } from 'react';

const HighlySelective = (props) => {
  const option = {
    color: ['#FF69B4', '#71dfe8', '#45d5e1', '#1ea3bc', '#1e89bc'],
    title: {
      textStyle: {
        fontSize: 23,
        fontWeight: 'bold',
        fontFamily: 'sans-serif'
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: '{b}'
    },
    legend: {
      data: ['All', 'Good', 'Great', 'Best', 'Top Rated'],
      orient: 'vertical',
      right: 'right',
    },
    series: [
      {
        name: 'Hospitals',
        type: 'funnel',
        top: '10%',
        left: '5%',
        width: '80%',
        height: '60%',
        minSize: '0%',
        maxSize: '70%',
        sort: 'ascending',
        gap: 6,
        label: {
          normal: {
            formatter: '{b}'
          }
        },
        labelLine: {
          normal: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            borderColor: 'fff',
            borderWidth: 0,
            shadowBlur: 50,
            shadowOffsetX: 0,
            shadowOffsetY: 10,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        },
        data: [
          { value: 20, name: 'Top Rated: 0.1%' },
          { value: 40, name: 'Regional Leaders: 0.4%' },
          { value: 60, name: 'All Major Hospitals: 6.5%' },
          { value: 80, name: 'All Teaching Hospitals: 21.3%' },
          { value: 100, name: 'All Hospitals: 100%' }
        ]
      }
    ]
  };
  const instance = useRef(null);

  return (
    <Card
      sx={{ height: '80%' }}
      {...props}
    >
      <CardContent>
        <ReactECharts
          ref={instance}
          option={option}
        />
      </CardContent>
    </Card>
  );
};

export default HighlySelective;
