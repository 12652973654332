import {
  Box,
  // Button,
  Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestDrugs } from 'src/api';

const columns = [
  {
    field: '1',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    width: 100
  },
  {
    field: '2',
    headerName: 'Name',
    headerClassName: 'super-app-theme--header',
    width: 220
  },
  {
    field: '3',
    headerName: 'API',
    headerClassName: 'super-app-theme--header',
    width: 220
  },
  {
    field: '4',
    headerName: 'Approvel',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    minWidth: 220,
  },
  {
    field: '5',
    headerName: 'Date',
    headerClassName: 'super-app-theme--header',
    width: 150
  },
  {
    field: '6',
    headerName: 'Approvel Track',
    headerClassName: 'super-app-theme--header',
    width: 150
  },
];

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgba(30, 176, 188)',
      color: 'white'
    },
  },
});

const RecentDrugsApproved = () => {
  const dkw = useSelector((state) => state.dkw);
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const loadDrugs = async (kword) => {
    const result = await requestDrugs(kword);
    console.log(result.drugs);
    const drugsDisplay = [];
    result.drugs.forEach((theDrug, i) => {
      const rowIndex = i + 1;
      const date = new Date(theDrug.approveDate).toDateString();
      console.log('theDrug.approveDate', theDrug.approveDate);
      const MonthDay = date.slice(4, 10);
      const Year = date.slice(11, 15);
      console.log('date', date);
      drugsDisplay.push(
        {
          id: theDrug.trackerId,
          1: rowIndex,
          2: theDrug.drugName,
          3: theDrug.activeIngredient,
          4: theDrug.fdaDescription,
          5: `${MonthDay}, ${Year}`,
          6: theDrug.trackerName.charAt(0),
        }
      );
    });
    setRows([...drugsDisplay]);
  };
  useEffect(() => {
    loadDrugs(dkw);
  }, []);

  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h2"
          style={{
            marginLeft: '20px',
            marginTop: '10px',
            marginBottom: '-5px'
          }}
        >
          {rows.length}
          &nbsp;
          {` novel drugs or therapeutics approved for ${dkw} by FDA since 2015`}
        </Typography>
      </Box>
      <div style={{ height: 550, width: '100%' }} className={classes.root}>
        <DataGrid rows={rows} columns={columns} pageSize={10} />
      </div>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{
            marginTop: '10px',
          }}
        >
          Developing novel drugs is an important part of modern medicine. FDA approvals represent most recent therapeutic breakthroughs in their related disease areas. FDA approvals help set new standard of care and significantly advance patient treatments.
          <br />
          <br />
          N: Class 1 Novel Drug
          <br />
          O: Oncology(Cancer)/Hematologic Malignancies Approval
          <br />
          R: Rare Disease Approval / Orphan Drugs
        </Typography>
      </Box>
    </>
  );
};
export default RecentDrugsApproved;
