import {
  Box,
  // Button,
  Typography
} from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { requestTrials } from 'src/api';

const columns = [
  {
    field: '1',
    headerName: 'ID',
    headerClassName: 'super-app-theme--header',
    width: 50
  },
  {
    field: '2',
    headerName: 'Institution',
    headerClassName: 'super-app-theme--header',
    width: 200,
  },
  {
    field: '3',
    headerName: 'Drug/Intervention',
    headerClassName: 'super-app-theme--header',
    flex: 0.5,
    minWidth: 180,
  },
  {
    field: '4',
    headerName: 'Project Title',
    headerClassName: 'super-app-theme--header',
    flex: 1,
    minWidth: 250,
  },
  {
    field: '5',
    headerName: 'Start Date ',
    headerClassName: 'super-app-theme--header',
    width: 120
  },
];

const useStyles = makeStyles({
  root: {
    '& .super-app-theme--header': {
      backgroundColor: 'rgba(30, 176, 188)',
      color: 'white',
      flexWrap: 'wrap',
      overView: 'scroll'
    },
  },
});

const ClinicalTrialsActive = () => {
  const dkw = useSelector((state) => state.dkw);
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const loadDrugs = async (kword) => {
    const result = await requestTrials(kword);
    console.log(result.trials);
    const trialsDisplay = [];
    result.trials.forEach((thetrials, i) => {
      const rowIndex = i + 1;
      const datearray = thetrials.startDate.split('/');
      const newdate = `${datearray[2]}-${datearray[1]}-${datearray[0]}`;
      const date = new Date(newdate).toDateString();
      const MonthDay = date.slice(4, 10);
      const Year = date.slice(11, 15);
      trialsDisplay.push(
        {
          id: thetrials.trialId,
          1: rowIndex,
          2: thetrials.institution,
          3: thetrials.intervention,
          4: thetrials.title,
          5: `${MonthDay}, ${Year}`,
        }
      );
    });
    setRows([...trialsDisplay]);
  };
  useEffect(() => {
    loadDrugs(dkw);
  }, []);
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h2"
          style={{
            marginLeft: '20px',
            marginTop: '20px',
            marginBottom: '-5px'
          }}
        >
          {rows.length}
          &nbsp;
          {`clinical trials found for ${dkw}`}
        </Typography>
      </Box>
      <div style={{ height: 700, width: '100%' }} className={classes.root}>
        <DataGrid rows={rows} columns={columns} pagination pageSize={10} rowsPerPageOptions={[10]} />
      </div>
      <Box sx={{ pb: 2 }}>
        <Typography
          color="textPrimary"
          variant="h5"
          style={{
            marginTop: '10px',
          }}
        >
          Listing a trial does not mean enrolling or matching. Before participating in a study, talk to your health care provider and learn about the risks and potential benefits. Trials after 2021 are enlisted. Read our disclaimer for details.
        </Typography>
      </Box>
    </>
  );
};
export default ClinicalTrialsActive;
