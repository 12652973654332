import { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  // Button,
  Divider,
  Drawer,
  Hidden,
  List,
} from '@material-ui/core';
import {
  AlignJustify as AlignJustifyIcon,
  Map as MapIcon,
  // Settings as SettingsIcon,
  Award as AwardIcon,
  // UserPlus as UserPlusIcon,
  Loader as LoaderIcon,
  Box as BoxIcon
} from 'react-feather';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import MultilineChartIcon from '@material-ui/icons/MultilineChart';
import WorkspacesIcon from '@material-ui/icons/Workspaces';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimelineIcon from '@material-ui/icons/Timeline';
import NavItem from './NavItem';
import NestedNavItem from './NestedNavItem';

const items = [
  {
    href: '/app/overall',
    icon: AlignJustifyIcon,
    title: 'Overall'
  },
  {
    href: '/app/aboutTheDisease',
    icon: BoxIcon,
    title: 'About The Disease',
    subNavs: [
      {
        href: '/app/aboutTheDisease/mostRecentUpdates',
        icon: LoaderIcon,
        title: 'Latest Updates'
      },
    ]
  },
  {
    href: '/app/topHospitals',
    icon: AwardIcon,
    title: 'View Top Hospitals'
  },
  {
    href: '/app/viewMap',
    icon: MapIcon,
    title: 'View On Map'
  },
  {
    href: '/app/advanced',
    icon: MultilineChartIcon,
    title: 'Advanced',
    subNavs: [
      {
        href: '/app/advanced/Venn',
        icon: WorkspacesIcon,
        title: 'Venn'
      },
      {
        href: '/app/advanced/Bubble',
        icon: BubbleChartIcon,
        title: 'Bubble'
      },
      {
        href: '/app/advanced/Histogram',
        icon: BarChartIcon,
        title: 'Histogram'
      },
      {
        href: '/app/advanced/Line',
        icon: TimelineIcon,
        title: 'Line'
      }
    ]
  },
  {
    href: '/app/findHospitalNearYou',
    icon: MapIcon,
    title: 'FindHospitalNearYou'
  },
];

const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, []);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => {
            if (item.subNavs) {
              return (
                <NestedNavItem
                  href={item.href}
                  title={item.title}
                  key={item.title}
                  subNavs={item.subNavs}
                  icon={item.icon}
                />
              );
            }
            return (
              <NavItem
                href={item.href}
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            );
          })}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 100,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
