import { useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Grid,
  Container,
  Typography,
  styled,
  Chip,
  Paper,
  Divider,
  // Button
} from '@material-ui/core';
import TopHospitalContent from 'src/components/hospitalsList/TopHospitalContent';
import LinearProgress from '@material-ui/core/LinearProgress';
import RankingDimension from 'src/components/hospitalsList/RankingDimension';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import RadarModal from 'src/components/hospitalsList/RadarModal';

// chip style
const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 20,
    width: 20,
  },
  details: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(1, 2),
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const TopHospitalsList = (props) => {
  const {
    displayHospitals
  } = props; // +prop, topHospitals => displayhospital
  // const topHospitals = useSelector((state) => state.topHospitals);
  const [selectedHospIds, setSelectedHospIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  // checkbox function
  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedHospIds.indexOf(id);
    let newSelectedHospIds = [];

    if (selectedIndex === -1) {
      newSelectedHospIds = newSelectedHospIds.concat(selectedHospIds, id);
    } else if (selectedIndex === 0) {
      newSelectedHospIds = newSelectedHospIds.concat(selectedHospIds.slice(1));
    } else if (selectedIndex === selectedHospIds.length - 1) {
      newSelectedHospIds = newSelectedHospIds.concat(selectedHospIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedHospIds = newSelectedHospIds.concat(
        selectedHospIds.slice(0, selectedIndex),
        selectedHospIds.slice(selectedIndex + 1)
      );
    }

    setSelectedHospIds(newSelectedHospIds);
  };
  // checkbox maxallowed = 5
  const shouldDisableCheckbox = (id) => {
    const maxAllowed = 5;
    return selectedHospIds.length >= maxAllowed && selectedHospIds.indexOf(id) === -1;
  };

  const emptyRows = limit - Math.min(limit, displayHospitals.length - page * limit);

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const findNameByHospId = (hospId) => {
    const found = displayHospitals.find((hosp) => hosp.lmID === hospId);
    return found.name;
  };

  // CHIP handleRemoveOne
  const handleRemoveOne = (hospId) => () => {
    const removeIndex = selectedHospIds.indexOf(hospId);
    if (removeIndex >= 0) {
      selectedHospIds.splice(removeIndex, 1);
      setSelectedHospIds([...selectedHospIds]);
    }
  };

  const style = {
    display: 'flex',
    justifyContent: 'center'
  };

  return (
    <>
      <Card>
        <Box>
          <Table size="small">
            <TableBody>
              {(limit > 0
                ? displayHospitals.slice(page * limit, page * limit + limit)
                : displayHospitals
              ).map((hosp) => (
                <TableRow
                  key={hosp.lmID}
                >
                  <TableCell padding="checkbox">
                    <Container maxWidth={false}>
                      <Grid container pb={2}>
                        <Grid
                          // container
                          item
                          md={4}
                          xs={12}
                        >
                          <Checkbox
                            checked={selectedHospIds.indexOf(hosp.lmID) !== -1}
                            onChange={(event) => handleSelectOne(event, hosp.lmID)}
                            value="true"
                            tabIndex={-1}
                            disabled={shouldDisableCheckbox(hosp.lmID)}
                            disableRipple
                          />
                          <TopHospitalContent hospitalname={hosp.name} hospitalocation={hosp.geoCity} />
                        </Grid>
                        <Grid
                          item
                          md={8}
                          xs={12}
                        >
                          <Typography
                            variant="h7"
                          >
                            <Box
                              textAlign="right"
                              m={5}
                            >
                              Score:&nbsp;
                              {hosp.overallSCoreDisplay}
                              /100
                              <br />
                              OverRanking: #
                              {hosp.overallRankDisplay}
                            </Box>
                          </Typography>
                          <Box
                            textAlign="center"
                            m={1}
                            color="primary"
                          >
                            <LinearProgress variant="determinate" color="secondary" value={hosp.overallSCoreDisplay} sx={{ height: '15px' }} />
                          </Box>
                          <Container maxWidth={false}>
                            <Grid
                              container
                              spacing={1}
                            >
                              {/* RankingDimension */}
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.projectCountRankDisplay} backvalue={hosp.projectCount} topic="Frontier Research" />
                              </Grid>
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.interverntionCountRankDisplay} backvalue={hosp.interverntionCount} topic="Therapeutic Investigation" />
                              </Grid>
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.physicianCountRankDisplay} backvalue={hosp.physicianCount} topic="Physician Activites" />
                              </Grid>
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.fundingRankDisplay} backvalue={hosp.funding} topic="Federal Support" />
                              </Grid>
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.piCountRankDisplay} backvalue={hosp.piCount} topic="Associated Experts" />
                              </Grid>
                              <Grid
                                item
                                md={2}
                                sm={4}
                                xs={6}
                              >
                                <RankingDimension rankvalue={hosp.nctCountRankDisplay} backvalue={hosp.nctCount} topic="Clinical Excellence" />
                              </Grid>
                            </Grid>
                          </Container>
                        </Grid>
                      </Grid>
                    </Container>
                  </TableCell>
                </TableRow>
              ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Box>
        <TablePagination
          component="div"
          count={displayHospitals.length}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleLimitChange}
          page={page}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </Card>
      <br />
      {/* Chip */}
      <div
        className={classes.root}
        style={{
          display: selectedHospIds.length > 0 ? 'block' : 'none',
          position: 'sticky',
          bottom: 0,
          borderRadius: '0px',
          width: 'calc(100% + 48px)',
          marginLeft: '-24px'
        }}
      >
        {/* Accordion for chip */}
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1c-content"
            id="panel1c-header"
            sx={{ borderRadius: '0px' }}
          >
            <div className={classes.column}>
              <Typography className={classes.heading}>Compare Hospitals</Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails className={classes.details}>
            <Paper
              sx={{
                display: 'flex',
                justifyContent: 'left',
                flexWrap: 'wrap',
                listStyle: 'none',
                boxShadow: 'none'
              }}
              component="ul"
            >
              {selectedHospIds.map((hospId) => (
                <ListItem key={hospId}>
                  <Chip
                    label={findNameByHospId(hospId)}
                    onDelete={handleRemoveOne(hospId)}
                    sx={{ borderRadius: '0px', color: 'white' }}
                    style={{ backgroundColor: '#ffc933', fontWeight: '400' }}
                    color="primary"
                    deleteIcon={<ClearIcon />}
                  />
                </ListItem>
              ))}
            </Paper>
          </AccordionDetails>
          <Divider />
          <AccordionActions style={style}>
            <RadarModal selectedHospIds={selectedHospIds} />
          </AccordionActions>
        </Accordion>
      </div>
    </>
  );
};
export default TopHospitalsList;
