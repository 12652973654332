import {
  Grid,
  Button
} from '@material-ui/core';
import TopRatedInsitution from 'src/components/overall/TopRatedInsitution';
import Carousel from 'react-elastic-carousel';
import { useSelector } from 'react-redux';
import { useState } from 'react';

const CarouselHosp = () => {
  const topHospitals = useSelector((state) => state.topHospitals);
  const mockHospitals = topHospitals.slice(0, 6);
  const [showArrows, setShowArrows] = useState(true);
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === 'PREV' ? '<<' : '>>';
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 4 }
  ];
  const handleResize = (current) => {
    if (current.itemsToShow === 1) {
      setShowArrows(false);
    } else {
      setShowArrows(true);
    }
  };
  return (
    <Carousel pagination={false} breakPoints={breakPoints} renderArrow={myArrow} onResize={handleResize} showArrows={showArrows}>
      {mockHospitals.map((hosp) => (
        <Grid
          key={hosp.lmID}
          item
          xs={11}
        >
          <TopRatedInsitution hospitalname={hosp.name} hospavatar={`/static/images/hosp_logos/${hosp.logUrl}`} hospitalocation={hosp.geoCity} sx={{ height: '100%' }} />
        </Grid>
      ))}
    </Carousel>
  );
};

export default CarouselHosp;
