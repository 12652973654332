const Logo = (props) => (
  <img
    alt="Logo"
    src="/static/LM.jpg"
    style={{ width: '50px' }}
    {...props}
  />
);

export default Logo;
