import {
  Card,
  CardContent,
  Box,
  // Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { ThemeProvider } from 'styled-components';
import ReactECharts from 'echarts-for-react';
// import { useRef } from 'react';
import { useSelector } from 'react-redux';

const Radar = (props) => {
  const {
    selectedHospIds
  } = props;
  const topHospitals = useSelector((state) => state.topHospitals);
  // radar text const
  const findNameByHospId = (hospId) => {
    const found = topHospitals.find((hosp) => hosp.lmID === hospId);
    return found.name;
  };
  const findNameByHospAvatar = (hospId) => {
    const found = topHospitals.find((hosp) => hosp.lmID === hospId);
    return `/static/images/hosp_logos/${found.logUrl}`;
  };

  const findHospitalById = (hospId) => {
    const found = topHospitals.find((hosp) => hosp.lmID === hospId);
    return found;
  };

  const sortedSelectedHospIds = selectedHospIds.sort((hospId1, hospId2) => {
    const hosp1 = findHospitalById(hospId1);
    const hosp2 = findHospitalById(hospId2);
    return hosp1.overallRankDisplay - hosp2.overallRankDisplay;
  });

  let maxInterverntionCount = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.interverntionCount > maxInterverntionCount) {
      maxInterverntionCount = currentHosp.interverntionCount;
    }
    return maxInterverntionCount;
  });

  let maxProjectCount = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.projectCount > maxProjectCount) {
      maxProjectCount = currentHosp.projectCount;
    }
    return maxProjectCount;
  });

  let maxPhysicianCount = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.physicianCount > maxPhysicianCount) {
      maxPhysicianCount = currentHosp.physicianCount;
    }
    return maxPhysicianCount;
  });

  let maxNctCount = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.nctCount > maxNctCount) {
      maxNctCount = currentHosp.nctCount;
    }
    return maxNctCount;
  });

  let maxPiCount = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.piCount > maxPiCount) {
      maxPiCount = currentHosp.piCount;
    }
    return maxPiCount;
  });

  let maxFunding = 0;
  selectedHospIds.map((hospId) => {
    const currentHosp = findHospitalById(hospId);
    if (currentHosp.funding > maxFunding) {
      maxFunding = currentHosp.funding;
    }
    return maxFunding;
  });

  return (
    <ThemeProvider
      theme={{
        palette: {
          primary: {
            main: '#00cc44',
            dark: '#00802b',
          },
        },
      }}
    >
      <Card sx={{ boxShadow: 'none' }}>
        <CardContent>
          <Grid
            container
            // spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            {sortedSelectedHospIds.map((hospId) => {
              const currentHosp = findHospitalById(hospId);
              const option = {
                tooltip: {},
                legend: {
                  data: ['Radar for compared hospitals']
                },
                radar: {
                  // shape: 'circle',
                  name: {
                    textStyle: {
                      padding: [-10, -30]
                    },
                    formatter(name) {
                      return name.replace(' ', '\n');
                    },
                  },
                  indicator: [
                    { name: 'Therapeutic Investigation', max: 100 },
                    { name: 'Frontier Research', max: 100 },
                    { name: 'Physician Avtivites', max: 100 },
                    { name: 'Clinical Excellence', max: 100 },
                    { name: 'Associated Experts', max: 100 },
                    { name: 'Federal Support', max: 100 }
                  ]
                },
                series: [{
                  symbol: 'none',
                  itemStyle: {
                    color: 'rgba(242, 141, 205, 0.7)',
                    borderColor: '#ffffff',
                  },
                  type: 'radar',
                  areaStyle: {
                    color: 'rgba(242, 141, 205, 0.7)',
                  },
                  data: [
                    {
                      value: [((currentHosp.interverntionCount / maxInterverntionCount) * 100), ((currentHosp.projectCount / maxProjectCount) * 100), ((currentHosp.physicianCount / maxPhysicianCount) * 100), ((currentHosp.nctCount / maxNctCount) * 100), ((currentHosp.piCount / maxPiCount) * 100), ((currentHosp.funding / maxFunding) * 100)],
                    }
                  ]
                }]
              };
              return (
                <Grid
                  key={hospId}
                  item
                  lg={2}
                  xs={12}
                >
                  <Grid item style={{ width: '100%' }}>
                    <Grid
                      item
                      style={{
                        height: '100px',
                        width: '100%',
                        position: 'relative',
                      }}
                    >
                      <Box
                        sx={{
                          height: '120px',
                          bgcolor: 'primary.third',
                          margin: 'auto',
                        }}
                        m={1}
                      >
                        <br />
                        <img
                          alt="Under development"
                          src={findNameByHospAvatar(hospId)}
                          style={{
                            maxWidth: '100%',
                            maxHeight: '90%',
                            display: 'inline-block',
                            position: 'absolute',
                            bottom: '0',
                            margin: '0 auto',
                            left: '0',
                            right: '0',
                          }}
                        />
                      </Box>
                    </Grid>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                      textAlign="center"
                      height="60px"
                      marginTop="40px"
                    >
                      {findNameByHospId(hospId)}
                    </Typography>
                    <Box
                      sx={{
                        height: '70px',
                        bgcolor: 'primary.second',
                        margin: 'auto',
                        alignItems: 'center',
                        // marginTop: '20px'
                      }}
                      textAlign="center"
                      m={1}
                    >
                      <Typography
                        color="textPrimary"
                        variant="body1"
                        // textAlign="center"
                        marginTop="-15px"
                      >
                        <br />
                        {currentHosp.city}
                        ,
                        &nbsp;
                        {currentHosp.state}
                      </Typography>
                    </Box>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                      // marginTop="15px"
                      textAlign="center"
                    >
                      <br />
                      {currentHosp.year}
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      textAlign="center"
                    >
                      EST
                    </Typography>
                    <Box
                      sx={{
                        height: '80px',
                        bgcolor: 'primary.second',
                        margin: 'auto',
                        marginTop: '15px',
                        // alignItems: 'center',
                      }}
                    >
                      <Typography
                        color="textPrimary"
                        variant="h5"
                        // marginTop="15px"
                        textAlign="center"
                      >
                        <br />
                        {currentHosp.bedNum}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                        textAlign="center"
                      >
                        BED
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    // marginTop="20px"
                  >
                    {/* radar */}
                    <ReactECharts
                      option={option}
                    />
                  </Grid>
                  {/* table for ranking dimension */}
                  <Box
                    sx={{
                      height: '130px',
                      bgcolor: 'primary.main',
                      // margin: 'auto',
                      // alignItems: 'center',
                    }}
                    textAlign="center"
                    m={1}
                  >
                    <Typography
                      variant="h2"
                      marginLeft="-20px"
                      marginBottom="10px"
                      color="primary.contrastText"
                    >
                      <br />
                      #
                      {currentHosp.overallRankDisplay}
                    </Typography>
                    <Typography
                      color="primary.contrastText"
                      variant="h4"
                      marginLeft="-20px"
                      marginBottom="50px"
                    >
                      Score:&nbsp;
                      {currentHosp.overallSCoreDisplay}
                      %
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default Radar;
