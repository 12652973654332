import { useEffect, useState } from 'react';
import ReactECharts from 'echarts-for-react';
import { requestHospitalsAccumulatedTrend } from 'src/api';
import { Box } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const TopHospitalsLineChart = (props) => {
  const { dkw } = props;
  const [xAxisData, setXAxisData] = useState([]);
  // const [lineChartData, setLineChartData] = useState([]);
  const [barChartData, setBarChartData] = useState([]);
  const [lineChartDataPerYear, setlineChartDataPerYear] = useState([]);
  const [maxY, setMaxY] = useState([]);
  const [indicator, setIndicator] = useState([]);

  const useStylesSelection = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

  const reloadLineChartData = async (dkwX, indicatorX) => {
    const result = await requestHospitalsAccumulatedTrend(dkwX, indicatorX, '');
    const xAxisDataX = [];
    // const lineChartDataX = [];
    const barChartDataX = [];
    const lineChartDataXPerYear = [];
    let curentAccumulateValue = 0;

    result.yearlyTrendMap.All.forEach((item) => {
      console.log('item', item);
      xAxisDataX.push(item.rankId);
      curentAccumulateValue += item.rankCount * 1;
      // lineChartDataX.push(curentAccumulateValue);
      barChartDataX.push(curentAccumulateValue);
      lineChartDataXPerYear.push(item.rankCount);
    });
    setXAxisData([...xAxisDataX]);
    // setLineChartData([...lineChartDataX]);
    setlineChartDataPerYear([...lineChartDataXPerYear]);
    setBarChartData([...barChartDataX]);
    setMaxY(curentAccumulateValue);
  };

  const handleChange = (event) => {
    setIndicator(event.target.value);
    reloadLineChartData(dkw, event.target.value);
    console.log('event.target.value', event.target.value);
  };
  const classesSelection = useStylesSelection();

  useEffect(() => {
    reloadLineChartData(dkw, 'Project Count');
  }, []);

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#cc85c5'
        }
      }
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'All years',
        min: 0,
        max: maxY,
        axisLabel: {
          formatter: '{value}'
        }
      },
      {
        type: 'value',
        name: 'Each year',
        min: 0,
        max: 300,
        interval: 50,
        axisLabel: {
          formatter: '{value} '
        }
      }
    ],
    series: [
      // {
      //   name: indicator,
      //   type: 'line',
      //   data: lineChartData,
      //   itemStyle: {
      //     shadowBlur: 10,
      //     shadowColor: 'rgba(120, 36, 50, 0.5)',
      //     shadowOffsetY: 5,
      //     color: 'rgb(242, 141, 205, 0.8)'
      //   }
      // },
      {
        name: indicator,
        type: 'bar',
        data: barChartData,
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(120, 36, 50, 0.5)',
          shadowOffsetY: 5,
          color: 'rgb(64, 214, 227, 0.8)'
        }
      },
      {
        name: 'indicator2',
        type: 'line',
        yAxisIndex: 1,
        data: lineChartDataPerYear,
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(120, 36, 50, 0.5)',
          shadowOffsetY: 5,
          color: 'rgb(242, 141, 205, 0.8)'
        }
      }
    ]
  };

  return (
    <>
      <Box
        textAlign="right"
        style={{
          marginTop: '-40px'
        }}
      >
        <FormControl variant="outlined" className={classesSelection.formControl}>
          <Select
            defaultValue="Project Count"
            onChange={handleChange}
            displayEmpty
            className={classesSelection.selectEmpty}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="Project Count">Frontier Research</MenuItem>
            <MenuItem value="Treatment">Therapeutic Investigation</MenuItem>
            <MenuItem value="Physicians">Physician Avtivites</MenuItem>
            <MenuItem value="Funding"> Federal Support</MenuItem>
            <MenuItem value="PI Count">Associated Experts</MenuItem>
            <MenuItem value="Clinical Trial Count">Clinical Excellence</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <ReactECharts
        option={option}
        style={{ height: 500 }}
      />
    </>
  );
};

export default TopHospitalsLineChart;
