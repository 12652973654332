import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const CompareWithOtherHospitals = (props) => {
  const {
    topic, top, others, all,
  } = props;

  return (
    <Card
      sx={{ height: '100%' }}
      {...props}
    >
      <CardContent>
        <Grid
          container
          spacing={3}
          sx={{ justifyContent: 'space-between' }}
        >
          <Box
            sx={{
              width: 300,
              marginTop: '20px',
              marginLeft: '30px',
              marginBottom: '-10px'
            }}
          >
            <Typography
              color="textPrimary"
              // gutterBottom
              variant="h4"
              style={{
                // marginTop: '20px',
                marginLeft: '5px',
              }}
            >
              {topic}
            </Typography>
            {/* </Grid> */}
          </Box>
          <Grid
            item
            textAlign="right"
            sx={{ width: '100%' }}
          >
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {top}
              <Box>
                <LinearProgress variant="determinate" color="secondary" value={100} sx={{ height: '15px' }} />
              </Box>
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {others}
              <Box>
                <LinearProgress variant="determinate" color="primary" value={85} sx={{ height: '15px' }} />
              </Box>
            </Typography>
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {all}
            </Typography>
            <Box sx={{ width: 'auto' }}>
              <LinearProgress variant="determinate" color="third" value={75} sx={{ height: '15px' }} />
            </Box>
          </Grid>
        </Grid>
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        />
      </CardContent>
    </Card>
  );
};

export default CompareWithOtherHospitals;
