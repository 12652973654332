import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import {
  Box,
  Container,
  Typography,
  Button
} from '@material-ui/core';
import NeedMore from 'src/components/overall/NeedMore';
import TopHospitalsVennChart from '../components/charts/TopHospitalsVennChart';

const Venn = () => {
  const dkw = useSelector((state) => state.dkw);
  return (
    <>
      <Helmet>
        <title>Venn Chart | LINKMedicine</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Typography
          color="textPrimary"
          variant="h1"
          style={{
            marginLeft: '20px',
            marginTop: '50px',
            marginBottom: '20px'
          }}
        >
          Comprehensive Analysis of Expertise
        </Typography>
        <Box
          width="98%"
          sx={{ flexGrow: 1 }}
          justify="center"
        >
          <Typography
            color="textPrimary"
            variant="body2"
            style={{
              marginLeft: '30px',
              marginBottom: '40px',
              wordWrap: 'break-word'
            }}
          >
            {`The comprehensive analysis focuses on an institution's expertise by examining itemzed aspect for the care of DKW. 
            The similarities and differences bw hospitals were compared, contracted, and illustrated by a venn diagram`}
            <br />
            <br />
            {`Institutions accounted in circule (or stripe) represents the ones that are top rated for this perspective. 
            Institutions appearing in multile circules (or stripes) indicate they are top rated for all the overlapping 
            perspectives. The more overlapped indicates more perspectives are top rated. When it comes for the formost care 
            of DKW, these instituions have better chance to pocess higher order of expertise. `}
            <br />
            <br />
            {`We identified 6 nationally top-rated hospitals and 15 regional leaders with the most expertise in ${dkw}. 
            The result is presented below. You may select ratings and regions to construct your own analysis.`}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'bottom',
            pt: 1,
            marginTop: '40px',
            marginLeft: '30px',
            marginBottom: '40px'
          }}
        >
          <Button
            color="primary"
            component="a"
            size="small"
            href="https://react-material-kit.devias.io"
            variant="contained"
          >
            View Venn chart
          </Button>
          &nbsp;
          <Button
            color="primary"
            component="a"
            href="https://react-material-kit.devias.io"
            variant="outlined"
            sx={{ marginLeft: '20px' }}
          >
            Download full report
          </Button>
        </Box>

        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <TopHospitalsVennChart />
          </Box>
        </Container>
        <NeedMore />
      </Box>
    </>
  );
};

export default Venn;
