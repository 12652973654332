import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff', // white
      main: '#1EB0BC', // theme blue
      second: 'rgb(64, 214, 227, 0.1)', // light blue + transparent
      third: 'rgb(199, 195, 196, 0.2)' // grey+transparent
    },
    secondary: {
      contrastText: '#ffffff',
      main: '#f28dcd', // pink
    },
    third: {
      contrastText: '#ffffff',
      main: '#1e89bc',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    }
  },
  shadows,
  typography
});

export default theme;
