import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%'
    },
    body: {
      backgroundColor: '#f4f6f8',
      height: '100%',
      width: '100%'
    },
    a: {
      textDecoration: 'none'
    },
    '.fullscreen-enabled': {
      backgroundColor: '#f4f6f8'
    },
    '#root': {
      height: '100%',
      width: '100%'
    },
    '.MuiDataGrid-renderingZone': {
      maxHeight: 'none !important'
    },
    '.MuiDataGrid-row': {
      maxHeight: 'none !important'
    },
    '.MuiDataGrid-root .MuiDataGrid-cell': {
      lineHeight: 'unset !important',
      maxHeight: 'none !important',
      overflowWrap: 'break-word!important',
      overflow: 'scroll',
      whiteSpace: ' inherit !important'
    },
    '.diseaseSummary': {
      '& ul': {
        marginLeft: '1rem'
      }
    }
  }
}));

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;
