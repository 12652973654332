import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Card,
  CardMedia,
  Container,
  Grid,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import NeedMore from 'src/components/overall/NeedMore';
// import MedTherAndInter from 'src/components/aboutTheDisease/MedTherAndInter';
import { requestNetwordData, requestDiseaseInfo, requestNodeNeiboursData } from 'src/api';
// import { MDBIcon } from 'mdbreact';
import allNodes from 'src/__mocks__/network';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { MDBIcon } from 'mdbreact';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TopHospitalsLineChart from '../components/charts/TopHospitalsLineChart';

// selection style
const useStylesSelection = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240,
    maxWidth: 240,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const AboutTheDisease = () => {
  const dkwMeshId = useSelector((state) => state.dkwMeshId);
  const currentDkw = useSelector((state) => state.dkw);
  const [networkData, setNetworkData] = useState({});
  const [displayLabel, setDisplayLabel] = useState(true);
  const [zoomNumber, setZoomNumber] = useState();
  const [colorIndex, setColorIndex] = useState(0);
  const [dinfo, setDinfo] = useState({ diseaseInfoList: [] });
  const handle = useFullScreenHandle();
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState('paper');

  const colorsRepo = ['yellow', 'coral', 'aqua', '#FFF0F5', '#00FA9A'];

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const loadNetwordData = async (dkw, rule) => {
    const result = await requestNetwordData(dkw, rule);
    if (rule === 'phase2') {
      setNetworkData({ ...result });
    } else {
      const fatherNodeNames = [];
      result.links.forEach((linkItem) => {
        if (linkItem.target === dkw) {
          fatherNodeNames.push(linkItem.source);
        }
      });
      // console.log('fatherNodeNames', fatherNodeNames);
      const newNodes = result.nodes.map((nodeItem) => {
        let isFather = false;
        fatherNodeNames.forEach((fName) => {
          if (fName === nodeItem.name) {
            isFather = true;
          }
        });
        if (isFather) {
          return { ...nodeItem, category: 'father' };
        }
        if (dkw === nodeItem.name) {
          return { ...nodeItem, category: 'dkw' };
        }
        return { ...nodeItem, category: 'child' };
      });
      console.log('newNodes', newNodes);
      setNetworkData({ links: [...result.links], nodes: [...newNodes] });
    }
  };

  const loadNeibours = async (dkw) => {
    const result = await requestNodeNeiboursData(dkw);
    if (dkw === currentDkw) {
      setColorIndex(0);
      setNetworkData({ ...result });
    } else {
      setColorIndex(colorIndex + 1);
      const newNodes = [...networkData.nodes];
      const newLinks = [...networkData.links];
      // merge nodes
      console.log('result.nodes', result.nodes);
      result.nodes.forEach((curNode) => {
        let addNodeFlag = true;
        newNodes.forEach((newNode) => {
          if (curNode.id === newNode.id) {
            addNodeFlag = false;
          }
        });
        if (addNodeFlag) {
          newNodes.push({ ...curNode, itemStyle: { color: colorsRepo[colorIndex % 5] } });
        }
      });
      // merge links
      result.links.forEach((curLink) => {
        let addLinkFlag = true;
        newLinks.forEach((newLink) => {
          if (curLink.source === newLink.source && curLink.target === newLink.target) {
            addLinkFlag = false;
          }
        });
        if (addLinkFlag) {
          newLinks.push(curLink);
        }
      });
      setNetworkData({ nodes: newNodes, links: newLinks });
    }
  };

  const loadDiseaseInfo = async (dkw) => {
    const result = await requestDiseaseInfo(dkw);
    // console.log('loadDiseaseInfo', result);
    setDinfo({ ...result });
  };
  // selection function
  const classesSelection = useStylesSelection();

  const handleChange = (event) => {
    if (event.target.value === 'testx') {
      setDisplayLabel(true);
      setZoomNumber(1);
      loadNeibours(currentDkw);
    } else if (event.target.value === 'allNodes') {
      setNetworkData({ ...allNodes });
      setDisplayLabel(false);
      setZoomNumber(0.05);
    } else {
      setDisplayLabel(true);
      setZoomNumber(1);
      loadNetwordData(currentDkw, event.target.value);
    }
  };

  const handleChartClick = (event) => {
    loadNeibours(event.data.name);
  };

  useEffect(() => {
    console.log('allNodes', allNodes.nodes.length);
    loadDiseaseInfo(currentDkw);
    // load phase1 networkData when first time mounted
    loadNetwordData(currentDkw, 'phase2');
  }, []);

  const option = {
    legend: {
      data: ['Bacterial Infections and Mycoses', 'Virus Diseases', 'Parasitic Diseases', 'Neoplasms', 'Musculoskeletal Diseases', 'Digestive System Diseases', 'Stomatognathic Diseases', 'Respiratory Tract Diseases', 'Otorhinolaryngologic Diseases', 'Nervous System Diseases', 'Eye Diseases', 'Male Urogenital Diseases', 'Female Urogenital Diseases and Pregnancy Complications', 'Cardiovascular Diseases', 'Hemic and Lymphatic Diseases', 'Congenital, Hereditary, and Neonatal Diseases and Abnormalities', 'Skin and Connective Tissue Diseases', 'Nutritional and Metabolic Diseases', 'Endocrine System Diseases', 'Immune System Diseases', 'Disorders of Environmental Origin', 'Animal Diseases', 'Pathological Conditions, Signs and Symptoms', 'Occupational Diseases', 'Chemically-Induced Disorders', 'Wounds and Injuries', 'Mental Disorders']
    },
    series: [{
      type: 'graph',
      roam: true,
      zoom: zoomNumber,
      layout: 'force',
      symbolSize: 20,
      animation: false,
      label: {
        show: displayLabel,
        position: 'right',
        formatter: '{b}'
      },
      draggable: true,
      data: networkData.nodes,
      // categories: networkData.categories,
      categories: [
        { name: 'father', itemStyle: { normal: { color: 'dodgerblue' } } },
        { name: 'dkw', itemStyle: { normal: { color: 'deepskyblue' } } },
        { name: 'child', itemStyle: { normal: { color: '#F4F6F8' } } }
      ],
      force: {
        initLayout: 'circular',
        edgeLength: 80,
        repulsion: 1300, // distance between nodes
        gravity: 0.2,
        // layoutAnimation: false,
      },
      edges: networkData.links,
      edgeSymbol: ['', 'arrow'],
      focusNodeAdjacency: true,
      itemStyle: {
        normal: {
          borderWidth: 1,
          borderColor: '#aaa',
        }
      },
      lineStyle: {
        normal: {
          color: 'source',
          curveness: 0
        }
      },
      emphasis: {
        itemStyle: {
          color: '#f00'
        },
        lineStyle: {
          color: '#f00'
        }
      }
    }]
  };

  return (
    <>
      <Helmet>
        <title>About The Disease | LINKMedicine</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          {/* network */}
          <Box sx={{ pt: 3 }}>
            <Typography
              color="textPrimary"
              variant="h1"
              style={{
                marginLeft: '10px',
                marginTop: '30px',
                marginBottom: '40px'
              }}
            >
              About&nbsp;
              {currentDkw.toUpperCase()}
            </Typography>
          </Box>
          <Box
            width="98%"
            sx={{ flexGrow: 1 }}
            justify="center"
          >
            <Typography
              color="textPrimary"
              variant="body1"
              style={{
                marginTop: '40px',
                marginLeft: '10px',
                marginBottom: '40px',
                wordWrap: 'break-word'
              }}
            >
              {`A de novo ${currentDkw} arising from an abnormal stem cell. It is characterized by the replacement
                 of bone marrow by fibrous tissue, a process that is mediated by CYTOKINES arising from the abnormal clone`}
              <br />
              <br />
              {`${currentDkw} Syndromes belong to the disease area of Hemic and Lymphatic Diseases`}
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'bottom',
              pt: 1,
              marginTop: '40px',
              marginLeft: '10px',
              marginBottom: '40px'
            }}
          >
            <Button
              color="primary"
              component="a"
              href="https://react-material-kit.devias.io"
              variant="contained"
            >
              View disease details
            </Button>
            &nbsp;
            <Button
              color="primary"
              component="a"
              size="small"
              href="https://react-material-kit.devias.io"
              variant="outlined"
              sx={{ marginLeft: '20px' }}
            >
              Download full report
            </Button>
          </Box>
          <Box>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{
                marginLeft: '20px',
                marginTop: '10px',
                marginBottom: '20px'
              }}
            >
              Disease Network
            </Typography>
            {/* selecion part */}
            <Box
              textAlign="left"
              style={{
                marginTop: '20px',
                marginLeft: '20px'
              }}
            >
              <FormControl
                variant="outlined"
                className={classesSelection.formControl}
                sx={{ m: 1, width: 200 }}
              >
                <InputLabel id="demo-multiple-checkbox-label">Change view</InputLabel>
                <Select
                  input={<OutlinedInput label="Change view" />}
                  defaultValue="phase2"
                  onChange={handleChange}
                  displayEmpty
                  className={classesSelection.selectEmpty}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  <MenuItem value="phase1">Local</MenuItem>
                  <MenuItem value="phase2">Regional</MenuItem>
                  <MenuItem value="allNodes">Complete</MenuItem>
                  <MenuItem value="testx">Explore</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <FullScreen
              handle={handle}
              // onChange={setFull}
              style={{ backgroundColor: '#white' }}
            >
              <Box
                textAlign="right"
                style={{
                  marginRight: 30,
                  padding: 10
                }}
              >
                <Button onClick={handleClickOpen('body')} id="inforbutton">
                  <MDBIcon
                    icon="exclamation-circle"
                    style={{ fontSize: 16, color: '#1EB0BC' }}
                  />
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  scroll={scroll}
                  aria-labelledby="scroll-dialog-title"
                  aria-describedby="scroll-dialog-description"
                  container={document.getElementById('inforbutton')}
                >
                  <DialogTitle id="scroll-dialog-title">Subscribe</DialogTitle>
                  <DialogContent>
                    <DialogContentText
                      id="scroll-dialog-description"
                      ref={descriptionElementRef}
                      tabIndex={-1}
                    >
                      `xxxxx`
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>ok</Button>
                  </DialogActions>
                </Dialog>
                <Tooltip>
                  <MDBIcon
                    icon="arrows-alt"
                    style={{ fontSize: 16, color: '#1EB0BC' }}
                    onClick={() => {
                      // 點擊設置full為true，接着調用handle的enter方法，進入全屏模式
                      // setFull(true);
                      handle.enter();
                    }}
                  />
                </Tooltip>
                <Tooltip>
                  <MDBIcon
                    icon="compress-arrows-alt"
                    style={{ fontSize: 16, marginLeft: 16, color: '#1EB0BC' }}
                    // 退出全屏模式並把full設置為false
                    onClick={() => {
                      // setFull(false);
                      handle.exit();
                    }}
                  />
                </Tooltip>
              </Box>
              <ReactECharts
                option={option}
                style={{ minHeight: '500px', height: '100%', width: '100%' }}
                onEvents={{ click: handleChartClick }}
              />
            </FullScreen>
          </Box>
          {/* Related Disease and Disease Area(s) */}
          <Box>
            <Typography
              color="textPrimary"
              variant="h3"
              style={{
                marginLeft: '20px',
                marginTop: '10px',
                marginBottom: '20px'
              }}
            >
              Related disease and disease Area(s)
            </Typography>
            <Typography
              color="textPrimary"
              variant="body1"
              style={{
                marginLeft: '20px',
                marginBottom: '20px',
              }}
            >
              {dinfo.dOverview}
            </Typography>
            <Grid
              container
            >
              {dinfo.diseaseInfoList.map((daItem) => (
                <Card key={daItem.daId} sx={{ marginRight: '10px', marginTop: '10px' }}>
                  <CardMedia
                    sx={{ height: '140px', width: '140px' }}
                    image={`/static/images/disease_area_logos/${daItem.daId}.png`}
                    title={daItem.lsdaId}
                  />
                </Card>
              ))}
            </Grid>
          </Box>
          <Typography
            color="textPrimary"
            variant="h3"
            style={{
              marginLeft: '20px',
              marginTop: '40px',
              marginBottom: '20px'
            }}
          >
            Medical progress trend
          </Typography>
          <Box
            width="98%"
            sx={{ flexGrow: 1 }}
            justify="center"
          >
            <Typography
              color="textPrimary"
              variant="body1"
              style={{
                marginLeft: '20px',
                marginBottom: '40px',
                wordWrap: 'break-word'
              }}
            >
              {`The frontier of medicine is ever advancing. The trending chart shows the annual data related the 
            most recent adanvce of medicine for this disease. Choose differernt attribute to see different perspective. 
            Click to view the major progress. `}
            </Typography>
          </Box>
          <Container maxWidth={false}>
            <Box sx={{ pt: 3 }}>
              <TopHospitalsLineChart dkw={dkwMeshId} />
            </Box>
          </Container>
          {/* Medicines, Therapuitics, and Interventions */}
          {/* <Typography
            color="textPrimary"
            variant="h3"
            style={{
              marginLeft: '20px',
              marginTop: '40px',
              marginBottom: '20px'
            }}
          >
            Medicines, therapuitics, and interventions
          </Typography> */}
          {/* <Grid
            container
            spacing={4}
          >
            {mockMedTheInter.map((num) => (
              <Grid
                key={num.lmId}
                item
                lg={4}
                sm={6}
                xl={3}
                xs={12}
              >
                <MedTherAndInter icon={num.icon} topic={num.topic} newrelease={num.newrelease} urlcolor={num.urlcolor} all={num.all} sx={{ height: '100%' }} />
              </Grid>
            ))}
          </Grid> */}
          {/* More about this disease */}
          <Typography
            color="textPrimary"
            variant="h3"
            style={{
              marginLeft: '20px',
              marginTop: '40px',
              marginBottom: '20px'
            }}
          >
            More about this disease
          </Typography>
          <Box
            textAlign="auto"
            width="95%"
            sx={{ flexGrow: 3 }}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Typography
                color="textPrimary"
                // variant="body2"
                // fontWeight="900"
                dangerouslySetInnerHTML={{ __html: dinfo.dSummary }}
                className="diseaseSummary"
                style={{ fontWeight: 100, marginLeft: '1.3rem', marginRight: '1.3rem' }}
              />
            </Grid>
          </Box>
          <NeedMore />
        </Container>
      </Box>
    </>
  );
};

export default AboutTheDisease;
